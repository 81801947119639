import { Component, Input } from '@angular/core';
import { DsTooltipPosition, Icons, IconStyles } from '@levelaccess/design-system';
import { $severity } from '../../../../shared/constants/accessibility';
import { EapColors } from '../../shared/eap-colors';
import { AuditFindingQAStatus, AuditFindingQAStatusUnresolved } from '../../../../shared/constants/audit-finding';
import { Api } from '../../../../shared/constants/api';
import { EvaluationStandards } from '../../../../shared/constants/manual-evaluation';

@Component({
  selector: 'app-overdue-unresolved-findings-by-severity',
  templateUrl: './overdue-unresolved-findings-by-severity.component.html',
})
export class OverdueUnresolvedFindingsBySeverityComponent {
  @Input()
  public findingSeverity: $severity;
  @Input()
  public severityLabel: string;
  @Input()
  public agingIssues: number;
  @Input()
  public severityTimeInterval: number;
  @Input()
  public scanId: string;
  @Input()
  public standard: EvaluationStandards;

  public Icons: typeof Icons;
  public IconStyles: typeof IconStyles;
  public EapColors: typeof EapColors;
  public AuditFindingQAStatusUnresolved: ReadonlyArray<AuditFindingQAStatus>;
  public severity: typeof $severity;
  public DsTooltipPosition: typeof DsTooltipPosition;
  public Api: typeof Api;

  constructor() {
    this.Icons = Icons;
    this.IconStyles = IconStyles;
    this.EapColors = EapColors;
    this.AuditFindingQAStatusUnresolved = AuditFindingQAStatusUnresolved;
    this.DsTooltipPosition = DsTooltipPosition;
    this.severity = $severity;
    this.Api = Api;
  }
}
