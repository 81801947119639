import { Injectable } from '@angular/core';

import { NotificationService } from './notification.service';
import { TranslateService } from '../translate/translate.service';
import { NotificationPosition } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class ClipboardUtilityService {
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  public copyTextToClipboard(textToCopy: string): void {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        this.notificationService.success(this.translateService.instant('copied_to_clipboard'), NotificationPosition.Toast);
      },
      () => {
        this.notificationService.error(this.translateService.instant('copy_to_clipboard_failed'), NotificationPosition.Toast);
      },
    );
  }
}
