import { Api } from '../constants/api';
import { SharedCommonUtility } from '../utils/common.utility';

type Query = [string, string | number | boolean];

type PathBuilder<T> = {
  [P in keyof T]: PathBuilder<T> & ((...args: (string | number)[]) => PathBuilder<T>);
} & {
  build: () => string;
  query: (param: Query[0], value: Query[1]) => PathBuilder<T>;
};

type IApiPathBuilder = (host?: string, ...initialPath: string[]) => PathBuilder<typeof Api>;

/**
 * A proxy that builds a path based on the properties accessed.
 *
 * @example ApiPathBuilder().issue_integration_connection.issue_tracking.issue_integration_connection.build();
 * @example ApiPathBuilder().issue_integration_connection.issue_tracking('10001').issue_integration_connection.build();
 * @example ApiPathBuilder('https://blah.com').api.issue_tracking.active('1').home.build();
 * @example ApiPathBuilder().issue_integration_connection.issue_tracking.query('param', 'value').build();
 */
export const ApiPathBuilder: IApiPathBuilder = (host?: string, ...extraPaths: string[]): PathBuilder<typeof Api> => {
  const createProxy = (path: string[], query?: Query[]): PathBuilder<typeof Api> => {
    return new Proxy((): void => {}, {
      get(_: any, prop: string): any {
        if (prop === 'build' || prop === 'toString') {
          const queryStr: string = (query ?? []).map(([param, value]: Query): string => `${param}=${value}`).join('&');
          return (): string => `${host ? '' : '/'}${path.join('/')}${queryStr ? `?${queryStr}` : ''}`;
        }

        if (prop === 'query') {
          return (param: string, value: string | number | boolean): PathBuilder<typeof Api> => {
            if (query) {
              query.push([param, encodeURIComponent(value)]);
            }
            return createProxy(path, query ?? [[param, encodeURIComponent(value)]]);
          };
        }

        if (prop in Api) {
          path.push(Api[prop]);
        }

        return createProxy(path, query);
      },
      apply(_: any, __: any, args: any[]): any {
        if (args.length > 0) {
          path.push(...args.map(encodeURIComponent));
        }
        return createProxy(path, query);
      },
    });
  };

  return createProxy(host ? [SharedCommonUtility.removeTrailingSlash(host), ...extraPaths] : extraPaths);
};
