import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';
import { SuccessCriteriaIdentifier } from '../../../constants/audit-standard-success-criteria';

const notApplicable: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: 'NA',
    [$successCriteria.level]: '',
    [$successCriteria.num]: SuccessCriteriaIdentifier.NOT_APPLICABLE,
    [$successCriteria.title]: 'Not applicable',
    [$successCriteria.versions]: [],
    [$successCriteria.url]: '',
  },
];

export class NotApplicable implements ISuccessCriteriaIndex {
  public get(): ISuccessCriteria {
    return notApplicable[0];
  }

  public getAll(): ISuccessCriteria[] {
    return notApplicable;
  }
}
