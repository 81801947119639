import { Component, EventEmitter, OnInit, AfterViewInit, OnDestroy, Input, ElementRef, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import Croppie from 'croppie';

import { BusyIndicatorService } from '../../services/busy-indicator/busy-indicator.service';
import { CommonUtility } from '../../utility/common.utility';

const DEFAULT_IMG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss'],
})
export class CropImageComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription;
  private croppie: Croppie;

  private previewElm: HTMLImageElement;

  @Input() public file: EventEmitter<File>;
  @Input() public options: any;
  @Output() public onCroppedFile: EventEmitter<Blob>;

  public fileData: File;

  constructor(
    private element: ElementRef<Element>,
    private busyIndicatorService: BusyIndicatorService,
  ) {
    this.file = new EventEmitter();
    this.onCroppedFile = new EventEmitter();
    this.fileData = null;
    this.subscriptions = new Subscription();
  }

  private getElementsReferences(): void {
    const hostContainer = this.element.nativeElement;

    this.previewElm = hostContainer.querySelector('#croppieContainer');
  }

  private createImage(): void {
    const reader: FileReader = new FileReader();

    const processImage = (event: any): void => {
      this.croppie.bind({
        url: event.target.result,
      });

      reader.removeEventListener('load', processImage);
      this.busyIndicatorService.closeIndicator();
    };

    reader.addEventListener('load', processImage);
    reader.readAsDataURL(this.fileData);
  }

  private onIncomingFile(file: File): void {
    this.fileData = file;

    if (file === null) {
      return;
    }

    this.busyIndicatorService.openIndicator();

    CommonUtility.requestIdleCallback(this.createImage.bind(this));
  }

  private initCroppie(): void {
    this.croppie = new Croppie(this.previewElm, {
      viewport: { width: 100, height: 100, type: 'circle' },
      boundary: { width: 500, height: 300 },
      enableOrientation: true,
      showZoomer: true,
    });

    const preloadedImageUrl = DEFAULT_IMG;

    this.croppie.bind({
      url: preloadedImageUrl,
      zoom: 0,
    });
  }

  public onGetCroppedImage(): void {
    const saveResult = (data: Blob): void => {
      this.onCroppedFile.emit(data);
    };

    this.croppie
      .result({
        type: 'blob',
      })
      .then(saveResult);
  }

  public imageOnError(event: Event): void {
    console.error(event);
  }

  public ngOnInit(): void {
    const onIncomingFileSubscription: Subscription = this.file.subscribe(this.onIncomingFile.bind(this));

    this.subscriptions.add(onIncomingFileSubscription);
  }

  public ngAfterViewInit(): void {
    this.getElementsReferences();
    this.initCroppie();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.previewElm = null;
  }
}
