import { Component, Input } from '@angular/core';
import { IAuditRule, IAuditRuleBase, IAuditStandard } from '../../../../../shared/interfaces/audit-rule.interface';
import { AuditToolNames } from '../../../../../shared/constants/audit-tool';
import { ICustomAuditRuleWithStandards } from '../../../../../shared/interfaces/custom-audit-rule.interface';
import { $auditRule } from '../../../../../shared/constants/audit-rule';
import { CriteriaPreset, Separator, SuccessCriteriaFormatterService } from '../../../services/success-criteria-formatter.service';
import { AuditStandards } from '../../../../../shared/constants/audit-standard';
import { $successCriteria } from '../../../../../shared/audits/definitions/success-criteria/constants';

export interface ISharedAuditRule {
  libraryName: string;
  isAccessipediaRule: boolean;
}

export interface IAuditRuleWithLibraryName extends IAuditRule, ISharedAuditRule {}

export interface ICustomAuditRuleWithLibraryName extends ICustomAuditRuleWithStandards, ISharedAuditRule {}

type IRuleInput =
  | IAuditRuleWithLibraryName
  | (ICustomAuditRuleWithLibraryName &
      Pick<
        IAuditRuleBase,
        | $auditRule.compliantCodeExample
        | $auditRule.nonCompliantCodeExample
        | $auditRule.techniques
        | $auditRule.applicableElementsSelectors
      >);

@Component({
  selector: 'app-audit-rule-view',
  templateUrl: './audit-rule-view.component.html',
})
export class AuditRuleViewComponent {
  @Input() public rule: IRuleInput;

  public AuditToolNames: typeof AuditToolNames = AuditToolNames;

  constructor(private successCriteriaFormatterService: SuccessCriteriaFormatterService) {}

  public displayWCAGOrNACriteria(standard: IAuditStandard): string {
    return this.successCriteriaFormatterService.toDisplayCriterias({
      standards: [standard.id],
      identifiers: [
        standard?.[standard.id]?.[$successCriteria.num],
        standard?.[AuditStandards.notApplicable]?.[$successCriteria.num],
      ],
      separator: Separator.newLine,
      preset: CriteriaPreset.criteriaIdentifierWithLevel,
    });
  }

  public getStandardVersions(standard: IAuditStandard): string[] | undefined {
    return standard[standard.id]?.[$successCriteria.versions];
  }
}
