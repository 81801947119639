<div class="compound-navbar-container" *ngIf="{ show: showMobileNavVersion$ | async } as showMobileNavVersion">
  <ng-container *ngIf="isAuthenticated$ | async; else unauthenticated">
    <compound-top-navbar [showMobileNavVersion]="showMobileNavVersion.show"></compound-top-navbar>
    <compound-second-level-navbar [showMobileNavVersion]="showMobileNavVersion.show"></compound-second-level-navbar>
    <compound-third-level-navbar [showMobileNavVersion]="showMobileNavVersion.show"></compound-third-level-navbar>
  </ng-container>
  <ng-template #unauthenticated>
    <compound-top-navbar-unauthenticated [showMobileNavVersion]="showMobileNavVersion.show"></compound-top-navbar-unauthenticated>
  </ng-template>
</div>
