import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';

import { Api, ApiQueryOption } from '../../../../../../shared/constants/api';
import { $workspace } from '../../../../../../shared/constants/workspace';
import { IWorkspaceClient } from '../../../../../../shared/interfaces/workspace.interface';
import { WorkspaceService } from '../../../../services/workspace.service';
import { UserService } from '../../../../services/user.service';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../../shared/utils/linked-property.utility';
import { LoadErrorHandler } from '../../../../services/load-error-handler';
import { NavService } from '../../../../services/navigation/nav.service';
import { INavMenuItem, IWorkspacePermission, NavigationItem } from '../../../../services/navigation/nav-structure';
import { BusMessageChannels, BusMessageService } from '../../../../services/bus-message.service';
import { SharedSortUtility } from '../../../../../../shared/utils/sort.utility';
import { $sortingOrder } from '../../../../../../shared/constants/sort';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { IDigitalPropertyListItem } from '../../../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../../../../shared/constants/digital-properties';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';

@Component({
  selector: 'top-navbar-workspaces-dropdown',
  templateUrl: './top-navbar-workspaces-dropdown.component.html',
})
export class TopNavbarWorkspacesDropdownComponent implements OnInit, OnDestroy {
  public workspaces$: BehaviorSubject<IWorkspaceClient[]>;
  public activeMenuItem$: BehaviorSubject<INavMenuItem>;
  public workspacesMenuItem$: BehaviorSubject<INavMenuItem>;
  public selectedWorkspaceId$: Observable<string | undefined>;

  public readonly Api: typeof Api = Api;
  public readonly Icons: typeof Icons = Icons;
  public readonly $workspace: typeof $workspace = $workspace;
  public readonly ApiQueryOption: typeof ApiQueryOption = ApiQueryOption;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;

  private subscriptions: Subscription;
  private workspaceLocations: Map<string, string>;

  constructor(
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private busMessageService: BusMessageService,
    private loadErrorHandler: LoadErrorHandler,
    private navService: NavService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.subscriptions = new Subscription();
    this.workspaceLocations = new Map<string, string>();
    this.workspaces$ = new BehaviorSubject<IWorkspaceClient[]>([]);
    this.activeMenuItem$ = new BehaviorSubject<INavMenuItem>(null);
    this.workspacesMenuItem$ = new BehaviorSubject<INavMenuItem>(null);
  }

  public ngOnInit(): void {
    this.getActiveWorkspaces();
    this.subscriptions.add(
      this.busMessageService.from(BusMessageChannels.workspaceUpdated).subscribe({
        next: this.getActiveWorkspaces.bind(this),
      }),
    );

    const urlSelectedWorkspace$: Observable<ILinkedPropertyData> = this.activatedRoute.queryParams.pipe(
      map(LinkedPropertyUtility.fromLinkedPropertyQueryParam),
    );

    this.selectedWorkspaceId$ = combineLatest([urlSelectedWorkspace$, this.navService.activeTopLevelMenuItem$()]).pipe(
      map(([linkedPropertyData, activeMenuItem]: [ILinkedPropertyData, INavMenuItem]): string | undefined => {
        if (activeMenuItem?.id !== NavigationItem.workspace) {
          return undefined;
        }

        return linkedPropertyData?.[Api.workspaceId];
      }),
    );

    this.subscriptions.add(
      combineLatest([
        this.navService.getTopLevelMenuItems$([NavigationItem.workspace]),
        this.navService.activeMenuItemForWorkspaceSwitcher$(),
      ])
        .pipe(
          map(([menuItems, activeMenuItem]: [INavMenuItem[], INavMenuItem]): void => {
            this.workspacesMenuItem$.next(menuItems?.[0]);
            this.activeMenuItem$.next(activeMenuItem);
          }),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      combineLatest([
        this.workspaces$,
        this.activeMenuItem$.pipe(filter(SharedCommonUtility.notNullish)),
        this.workspacesMenuItem$.pipe(filter(SharedCommonUtility.notNullish)),
        this.userService.getFirstAvailableDigitalPropertyForWorkspaces(),
      ])
        .pipe(
          switchMap(
            ([workspaces, activeMenuItem, workspacesMenuItem, workspaceMap]: [
              IWorkspaceClient[],
              INavMenuItem,
              INavMenuItem,
              Map<string, IDigitalPropertyListItem>,
            ]): IWorkspacePermission[] => {
              return workspaces.map((workspace: IWorkspaceClient): IWorkspacePermission => {
                const workspaceId: string = workspace[$workspace._id];
                const digitalPropertyId: string = workspaceMap.get(workspaceId)?.[$digitalProperty._id];
                return {
                  activeMenuItem,
                  workspacesMenuItem,
                  workspaceId,
                  digitalPropertyId,
                };
              });
            },
          ),
          switchMap((workspacePermission: IWorkspacePermission): Observable<IWorkspacePermission> => {
            return this.navService
              .hasAccessToMenuItemInWorkspace$(
                workspacePermission.activeMenuItem,
                workspacePermission.workspaceId,
                workspacePermission.digitalPropertyId,
              )
              .pipe(
                map((hasAccess: boolean): IWorkspacePermission => {
                  return {
                    ...workspacePermission,
                    hasAccess,
                  };
                }),
              );
          }),
        )
        .subscribe({
          next: (workspacePermission: IWorkspacePermission): void => {
            this.workspaceLocations.set(
              workspacePermission.workspaceId,
              workspacePermission.hasAccess && SharedCommonUtility.notNullish(workspacePermission.activeMenuItem)
                ? workspacePermission.activeMenuItem.routerLink
                : workspacePermission.workspacesMenuItem.routerLink,
            );
          },
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getActiveWorkspaces(): void {
    const onGetWorkspacesError = (error: HttpErrorResponse): Observable<IWorkspaceClient[]> => {
      this.loadErrorHandler.handleError('error_while_getting_workspaces', error, TopNavbarWorkspacesDropdownComponent.name);
      return EMPTY;
    };
    const onGetActiveWorkspacesSuccess = (activeWorkspaces: IWorkspaceClient[]): void => {
      activeWorkspaces.sort(
        SharedSortUtility.functionSortObjectsByStringProperty($sortingOrder.asc, $workspace.name, {
          numeric: true,
          sensitivity: 'base',
        }),
      );

      this.workspaces$.next(activeWorkspaces);
    };

    const onAuthenticationChange = (status: boolean): void => {
      if (status) {
        const getActiveWorkspacesSubscription: Subscription = this.workspaceService.getActiveWorkspacesV2().subscribe({
          next: onGetActiveWorkspacesSuccess.bind(this),
          error: onGetWorkspacesError.bind(this),
        });
        this.subscriptions.add(getActiveWorkspacesSubscription);
      }
    };

    const getAuthUserSubscription: Subscription = this.userService.isAuthenticated$.subscribe(onAuthenticationChange.bind(this));
    this.subscriptions.add(getAuthUserSubscription);
  }

  public onWorkspaceSelected(workspace: IWorkspaceClient): void {
    const workspaceId: string = workspace[$workspace._id];
    const propertyId: string = workspace[$workspace.digitalProperties]?.[0]?._id;

    this.subscriptions.add(
      this.userService
        .switchUserToDigitalProperty(workspaceId, propertyId)
        .pipe(
          switchMap(() => {
            const routePath: string = this.workspaceLocations.get(workspaceId);

            return from(
              this.router
                .navigate([routePath], {
                  queryParams: LinkedPropertyUtility.getLinkedPropertyQueryParam(propertyId, workspaceId),
                  relativeTo: this.activatedRoute,
                  queryParamsHandling: 'merge',
                })
                .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService)),
            );
          }),
        )
        .subscribe(() => {
          this.busMessageService.to(BusMessageChannels.userSwitchedToWorkspace).next();
          this.busMessageService.to(BusMessageChannels.userSwitchedToDigitalProperty).next();
        }),
    );
  }
}
