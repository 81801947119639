import { Component, DoCheck, HostBinding, Input, OnInit } from '@angular/core';

import { TAB_ID_PREFIX } from '../constants';

@Component({
  selector: '[appStepHeader]',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss'],
})
export class StepHeaderComponent implements OnInit, DoCheck {
  @Input() public step: number = 0;
  @Input() public activeStep: number = -1;
  @Input() public label: string = '';
  @Input() public id: string = '';

  @HostBinding('attr.aria-current') ariaCurrent: string;
  @HostBinding('id') domId: string;

  constructor() {}

  private setHostAttributes(): void {
    this.ariaCurrent = this.active ? 'step' : 'false';
  }

  public get done(): boolean {
    return this.step < this.activeStep;
  }

  public get active(): boolean {
    return this.step === this.activeStep;
  }

  public ngOnInit(): void {
    this.setHostAttributes();

    this.domId = `${TAB_ID_PREFIX}${this.id}`;
  }

  public ngDoCheck(): void {
    this.setHostAttributes();
  }
}
