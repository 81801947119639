<label
  for="deprecated-scan-tag-selector"
  class="deprecated-scan-tag-selector-label margin-bottom-xs"
  [attr.aria-required]="true"
  [ngClass]="{ required: required }"
>
  {{ 'label_scan_tag' | translate }}<span class="color-error" *ngIf="required"> *</span>
</label>
<select
  id="deprecated-scan-tag-selector"
  class="w-100 form-element"
  [(ngModel)]="selectedTagId"
  (ngModelChange)="onChange($event)"
  [disabled]="disabled"
  [attr.required]="required ? required : null"
>
  <option [ngValue]="null" [disabled]="disabledUnselectedValue">{{ unselectedValueLabel | translate }}</option>
  <option *ngFor="let tag of availableTags" [ngValue]="tag[$scanTag._id]" [selected]="tag[$scanTag._id] === selectedTagId">
    {{ tag[$scanTag.tagName] }}
  </option>
</select>
