import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { VariationValue } from '@harnessio/ff-javascript-client-sdk';
import { LoaderService } from '../../services/loader.service';
import { RoutingService } from '../../services/routing.service';
import { config } from '../../../environments/config.shared';
import { FeatureFlagCollection } from '../../../../shared/interfaces/feature-flag.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { RequiredSecurities } from '../../../../shared/constants/required-securities';
import { UserAclService } from '../../services/user-acl.service';
import { AclSecurityAdapter } from '../../services/acl.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  public isLoading: boolean;

  public isHomePage: boolean;

  public showSkipToAiChatLink$: Observable<boolean>;

  @Input() public routerOutlet: TemplateRef<any>;

  constructor(
    private loaderService: LoaderService,
    private routingService: RoutingService,
    private featureFlagService: FeatureFlagService,
    public title: Title,
    private userAclService: UserAclService,
  ) {
    this.subscriptions = new Subscription();
    this.isHomePage = false;

    this.isLoading = true;
  }

  private setAppTitle(pageTitle: string): void {
    if (this.isHomePage) {
      this.title.setTitle(config.APP.NAME);
      return;
    }

    this.title.setTitle(pageTitle + ' | ' + config.APP.NAME);
  }

  private setIsHomePage(pageTitle: string): void {
    this.isHomePage = pageTitle === config.APP.NAME;
  }

  private onPageTitleChange(pageTitle: string): void {
    this.setIsHomePage(pageTitle);
    this.setAppTitle(pageTitle);
  }

  private setShowSkipToAiChatLink(): void {
    const aiChatFF$: Observable<boolean> = this.featureFlagService
      .variation$(FeatureFlagCollection.aiChat, false)
      .pipe(map((value: VariationValue): boolean => (SharedCommonUtility.notNullish(value) ? Boolean(value) : value)));

    const skipToAiChatFF$: Observable<boolean> = this.featureFlagService
      .variation$(FeatureFlagCollection.skipToAIChat, false)
      .pipe(map((value: VariationValue): boolean => (SharedCommonUtility.notNullish(value) ? Boolean(value) : value)));

    const aiChatPermission$: Observable<boolean> = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter.useFunctionalActions(RequiredSecurities.AI_CHAT_Create.functionalActions).check(),
        ),
      );

    this.showSkipToAiChatLink$ = combineLatest([aiChatFF$, skipToAiChatFF$, aiChatPermission$]).pipe(
      map((checks: boolean[]): boolean => checks.every((element: boolean) => element)),
    );
  }

  public ngOnInit(): void {
    const changePageTitleSubscription: Subscription = this.routingService.pageTitle$.subscribe(this.onPageTitleChange.bind(this));
    this.subscriptions.add(changePageTitleSubscription);

    this.listenToLoader();
    this.setShowSkipToAiChatLink();
  }

  private listenToLoader(): void {
    this.subscriptions.add(
      this.loaderService.loader.pipe(debounceTime(200)).subscribe((loading: boolean): void => {
        this.isLoading = loading;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
