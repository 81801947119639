import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { scanCreateRequest } from '../../../../shared/constants/scan-create-request';
import { CustomValidators } from '../../services/helpers/form-custom-validators';
import { $scanHeaders } from '../../../../shared/constants/scan';
import { IScanHeader } from '../../../../shared/interfaces/scan.interface';

@Component({
  selector: 'app-scan-extra-headers',
  templateUrl: './scan-extra-headers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanExtraHeadersComponent {
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public headersControlName: string;
  public isDisabled: boolean;
  public modifyHeaders: boolean;
  public $scanHeaders: typeof $scanHeaders;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.headersControlName = scanCreateRequest.headers;
    this.$scanHeaders = $scanHeaders;
  }

  @Input()
  public set disabled(value: boolean) {
    this.isDisabled = value;

    if (value) {
      this.headersForm?.disable({ emitEvent: false });
    } else {
      this.headersForm?.enable({ emitEvent: false });
    }

    this.changeDetectorRef.detectChanges();
  }

  public addControl(name: string = '', value: string = ''): void {
    this.headersForm.push(
      this.formBuilder.group({
        [$scanHeaders.name]: this.formBuilder.control(name, [CustomValidators.required, CustomValidators.validateIsEmpty]),
        [$scanHeaders.value]: this.formBuilder.control(value, [CustomValidators.required, CustomValidators.validateIsEmpty]),
      }),
    );

    this.changeDetectorRef.detectChanges();
  }

  @Input()
  public set headers(headers: IScanHeader[]) {
    if (Array.isArray(headers) && headers.length > 0) {
      if (this.form.contains(this.headersControlName) === false) {
        this.form.registerControl(this.headersControlName, this.formBuilder.array([]));
      } else {
        this.headersForm.clear();
      }

      this.modifyHeaders = true;

      for (const header of headers) {
        this.addControl(header[$scanHeaders.name], header[$scanHeaders.value]);
      }
    } else {
      this.modifyHeaders = false;
      this.onModifyHeaders(false);
    }
    this.changeDetectorRef.detectChanges();
  }

  public get headersForm(): UntypedFormArray {
    return this.form.get(this.headersControlName) as UntypedFormArray;
  }

  public get headersValues(): UntypedFormGroup[] {
    return this.headersForm.controls as UntypedFormGroup[];
  }

  public onModifyHeaders(value: boolean): void {
    if (value === false) {
      this.form.removeControl(this.headersControlName);
      this.changeDetectorRef.detectChanges();
      return;
    }

    if (this.form.contains(this.headersControlName) === false) {
      this.form.registerControl(this.headersControlName, this.formBuilder.array([]));
      this.addControl();
    }
  }

  public removeControl(index: number): void {
    this.headersForm.removeAt(index);
    this.changeDetectorRef.detectChanges();
  }
}
