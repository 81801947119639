import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CommonUtility } from '../../../utility/common.utility';

export enum $formFields {
  filterValue = 'filterValue',
}

@Component({
  selector: 'app-filtering-input',
  templateUrl: './filtering-input.component.html',
  styleUrls: ['./filtering-input.component.scss'],
})
export class FilteringInputComponent implements OnInit {
  public componentID: string;

  @Input() public inputName: string;
  @Input() public buttonName: string;
  @Output() public onValueChange: EventEmitter<string>;

  public form: UntypedFormGroup;

  public $formFields: typeof $formFields;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.$formFields = $formFields;
    this.onValueChange = new EventEmitter<string>();
    this.componentID = `${$formFields.filterValue}-${CommonUtility.createUniqueDOMId()}`;
  }

  private setUpForm(): void {
    const formConfig: any = {
      [$formFields.filterValue]: new UntypedFormControl(''),
    };

    this.form = this.formBuilder.group(formConfig);
  }

  public formSubmitted(): void {
    this.onValueChange.emit(this.form.get($formFields.filterValue).value);
  }

  public ngOnInit(): void {
    this.setUpForm();
  }
}
