import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, FormBuilder } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { $user } from '../../../../../shared/constants/user';
import { ITableConfig, ITableRow, SortEvent } from '../../table/ngb-table/utilities/ngb-table.interface';
import { ISecurityGroupWithEntityNames } from '../../../../../shared/interfaces/security-group.interface';
import { $sortingOrder } from '../../../../../shared/constants/sort';

@Directive()
export abstract class PermissionsTableComponent {
  protected pageSize: number = 10;

  public tableConfig: ITableConfig;
  public tableData$: Observable<ITableRow[]>;
  public sortOrder$: Observable<Record<string, $sortingOrder>>;
  public page$: BehaviorSubject<number>;
  public sortOption$: BehaviorSubject<SortEvent>;
  public total$: Observable<number>;

  @Input()
  public form: UntypedFormGroup;

  @Output()
  public onPermissionsLoaded: EventEmitter<ISecurityGroupWithEntityNames[]>;

  constructor(private formBuilder: FormBuilder) {
    this.page$ = new BehaviorSubject(1);
    this.sortOption$ = new BehaviorSubject<SortEvent>(null);
    this.onPermissionsLoaded = new EventEmitter<ISecurityGroupWithEntityNames[]>();
  }

  protected get formGroupIds(): UntypedFormArray {
    return this.form.get($user.groupIds) as UntypedFormArray;
  }

  protected onSort(sort: SortEvent): void {
    this.sortOption$.next(sort);
  }

  protected onPageChange(page: number): void {
    this.page$.next(page);
  }

  protected buildObservables(): void {
    this.sortOrder$ = this.sortOption$.pipe(
      map(
        (sortOption: SortEvent): Record<string, $sortingOrder> =>
          sortOption ? { [sortOption.column]: sortOption.direction } : {},
      ),
    );
  }

  public updateFormGroupIds(groupIds: string[]): void {
    this.formGroupIds.clear({ emitEvent: false });
    Array.from(new Set(groupIds)).forEach((groupId: string, index: number): void =>
      this.formGroupIds.push(this.formBuilder.control(groupId), { emitEvent: index === groupIds.length - 1 }),
    );
  }
}
