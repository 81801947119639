<app-modal-container cardClass="card-width-50vw">
  <span class="modal-header-content">
    {{ 'turn_off_monitoring' | translate }}
  </span>

  <div class="modal-body-content d-flex flex-column">
    <h2 class="h3">
      {{ 'label_turn_off_monitoring_confirmation_subtitle' | translate }}
    </h2>
    <p>
      <ng-container>
        {{ 'label_turn_off_monitoring_confirmation_message' | translate }}
      </ng-container>
    </p>
  </div>

  <div class="modal-footer-content">
    <div class="d-flex justify-content-end">
      <button class="btn btn-outline-primary" (click)="close()">
        {{ 'label_cancel' | translate }}
      </button>
      <button class="btn btn-primary margin-left" (click)="onConfirm()">
        {{ 'turn_off_monitoring' | translate }}
      </button>
    </div>
  </div>
</app-modal-container>
