import { Input, Component, ChangeDetectionStrategy, forwardRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  AbstractControl,
  ValidationErrors,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validators,
  AbstractControlOptions,
} from '@angular/forms';

import { IDateFieldParameters } from './interfaces/params.interface';
import { $dateParts } from '../../../../../shared/constants/date';
import { $formFields } from './constants/form-fields';
import { CommonUtility } from '../../../utility/common.utility';
import { DateFallbackFormUtils } from '../utils/form';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { SharedDateUtility } from '../../../../../shared/utils/date.utility';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateFieldComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateFieldComponent), multi: true },
  ],
})
export class DateFieldComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private resultDate: Date;
  private changeSubscription: Subscription = Subscription.EMPTY;

  @Input() public dateFieldParameters: IDateFieldParameters | undefined;
  @Input() public required: boolean = false;
  @Input() public errorFieldId: string;

  public $dateParts: typeof $dateParts;
  public $formFields: typeof $formFields;

  public form: UntypedFormGroup;
  public formId: string;
  public isDateInputSupported: boolean;
  public availableYears: number[];
  public availableDays: number[];
  public disabled: boolean;

  constructor(private formBuilder: UntypedFormBuilder) {
    if (typeof this.dateFieldParameters !== 'object') {
      this.dateFieldParameters = {
        max: null,
        min: null,
      };
    }
    if (typeof this.dateFieldParameters.nativeInputId !== 'string') {
      this.dateFieldParameters.nativeInputId = CommonUtility.createUniqueDOMId();
    }
    this.$dateParts = $dateParts;
    this.$formFields = $formFields;

    this.form = null;
    this.formId = CommonUtility.createUniqueDOMId();
    this.isDateInputSupported = CommonUtility.isInputTypeSupported('date');
    this.availableYears = [];
    this.availableDays = [];
    this.resultDate = null;

    this.setupForm();
  }

  private onChange: Function = () => null;
  private onTouched: Function = () => null;
  private onValidatorChange: () => void = () => void 0;

  private setupForm(): void {
    if (this.isDateInputSupported) {
      this.form = this.formBuilder.group({
        [$formFields.nativeInputDate]: new UntypedFormControl('', DateFieldComponent.defaultFormControlConfig()),
      });
      this.changeSubscription = this.form
        .get($formFields.nativeInputDate)
        .valueChanges.subscribe((value: string) => this.nativeDateChanged(value));
    } else {
      this.setUpFallbackForm();
    }
  }

  private setUpFallbackForm(): void {
    this.form = this.formBuilder.group({
      [$dateParts.year]: new UntypedFormControl(null, DateFieldComponent.defaultFormControlConfig()),
      [$dateParts.month]: new UntypedFormControl(null, DateFieldComponent.defaultFormControlConfig()),
      [$dateParts.day]: new UntypedFormControl(null, DateFieldComponent.defaultFormControlConfig()),
    });
    this.populateFormValues();
    if (SharedCommonUtility.isDateValid(this.resultDate)) {
      this.syncFormDate(this.resultDate);
    }
    this.changeSubscription = this.form.valueChanges.subscribe(() => this.fallbackDateChanged());
  }

  private emitChange(value: any): void {
    this.onTouched();
    this.onChange(value);
  }

  private populateFormValues(): void {
    this.populateYears();

    if (SharedCommonUtility.isDateValid(this.resultDate)) {
      this.populateDays(this.resultDate.getMonth());
      return;
    }

    this.populateDays(0);
  }

  private populateYears(): void {
    const formField: AbstractControl = this.form.get($dateParts.year);

    this.availableYears = DateFallbackFormUtils.populateYears(formField);

    if (SharedCommonUtility.isDateValid(this.resultDate) === false) {
      formField.setValue(null);
    }
  }

  private populateDays(month: number): void {
    const resultDateIsValid: boolean = SharedCommonUtility.isDateValid(this.resultDate);
    const date: Date = resultDateIsValid ? this.resultDate : new Date();

    const formField: AbstractControl = this.form.get($dateParts.day);
    const days: number[] = DateFallbackFormUtils.populateDays(formField, month, date);

    this.availableDays = days;

    if (days.length < date.getDate()) {
      this.form.get($dateParts.day).setValue(days.length);

      if (resultDateIsValid) {
        this.resultDate.setDate(days.length);
      }
    }
  }

  private setResultDate(receivedDate: Date): void {
    if (this.isDateInputSupported) {
      this.setNativeResultDate(receivedDate);
    } else {
      this.setFallbackResultDate(receivedDate);
    }

    this.emitChange(this.resultDate);
  }

  private setNativeResultDate(receivedDate: Date): void {
    const nativeInput: AbstractControl = this.form.get($formFields.nativeInputDate);

    this.resultDate = SharedCommonUtility.isDateValid(receivedDate) ? receivedDate : null;

    if (this.resultDate === null) {
      nativeInput.setValue('', { emitEvent: false });
    } else {
      const newValue: string = SharedDateUtility.getLocalISODate(this.resultDate);
      nativeInput.setValue(newValue, { emitEvent: false });
    }
  }

  private setFallbackResultDate(receivedDate: Date): void {
    if (SharedCommonUtility.isDateValid(receivedDate)) {
      this.resultDate = DateFallbackFormUtils.setResultDate(this.dateFieldParameters, receivedDate);
      this.populateDays(this.resultDate.getMonth());
    } else {
      this.resultDate = null;
    }
    this.syncFormDate(this.resultDate);
    this.triggerValidation();
  }

  private isFallbackFormFilledPartially(): boolean {
    const formValues: (string | null)[] = [
      this.form.get($dateParts.year).value,
      this.form.get($dateParts.month).value,
      this.form.get($dateParts.day).value,
    ];

    const nullOnly = (item: string | null): boolean => {
      return item === null;
    };

    const nullCount: number = formValues.filter(nullOnly).length;

    return nullCount > 0 && nullCount < formValues.length;
  }

  private syncFormDate(date: Date): void {
    let value: any = { [$dateParts.year]: null, [$dateParts.month]: null, [$dateParts.day]: null };
    if (SharedCommonUtility.isDateValid(date)) {
      value = { [$dateParts.year]: date.getFullYear(), [$dateParts.month]: date.getMonth(), [$dateParts.day]: date.getDate() };
    }
    this.form.setValue(value, { emitEvent: false });
  }

  private triggerValidation(): void {
    this.onValidatorChange();
  }

  private getDateFromFallbackForm(): Date | null {
    const yearValue: string = this.form.get($dateParts.year).value;
    const monthValue: string = this.form.get($dateParts.month).value;
    const dateValue: string = this.form.get($dateParts.day).value;

    const values: string[] = [yearValue, monthValue, dateValue];

    if (values.includes(null)) {
      return null;
    }

    const date: Date = SharedDateUtility.getLocalDateFromNumbers(Number(yearValue), Number(monthValue), Number(dateValue));
    return SharedCommonUtility.isDateValid(date) ? date : null;
  }

  private static defaultFormControlConfig(): AbstractControlOptions {
    return {
      validators: null,
      updateOn: 'blur',
    };
  }

  public nativeDateChanged(date: string): void {
    const newDate: Date = SharedDateUtility.getLocalDate(date);
    this.setResultDate(newDate);
  }

  public fallbackDateChanged(): void {
    if (this.isFallbackFormFilledPartially()) {
      this.resultDate = null;
    }
    this.triggerValidation();

    const formDate: Date = this.getDateFromFallbackForm();

    if (SharedCommonUtility.isDateValid(formDate) === false) {
      this.emitChange(null);
      return;
    }

    if (SharedCommonUtility.isDateValid(this.resultDate) === false) {
      this.resultDate = formDate;
    }

    this.setResultDate(formDate);
  }

  public nativeMinValue(): string {
    const minDate = this.dateFieldParameters.min;
    return SharedCommonUtility.getTypeOf(minDate) === 'date' ? SharedDateUtility.getLocalISODate(minDate) : '';
  }

  public nativeMaxValue(): string {
    const maxDate: Date = this.dateFieldParameters.max;
    return SharedCommonUtility.getTypeOf(maxDate) === 'date' ? SharedDateUtility.getLocalISODate(maxDate) : '';
  }

  public ngOnInit(): void {
    this.setResultDate(this.resultDate);
  }

  public writeValue(obj: any): void {
    if (
      obj === this.resultDate ||
      (SharedCommonUtility.isDateValid(obj) &&
        SharedCommonUtility.isDateValid(this.resultDate) &&
        this.resultDate.getTime() === obj.getDate())
    ) {
      return;
    }
    this.setResultDate(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public validate(control: AbstractControl): ValidationErrors {
    return Validators.compose([
      CustomValidators.isValidDate,
      CustomValidators.minDate(this.dateFieldParameters.min),
      CustomValidators.maxDate(this.dateFieldParameters.max),
    ])(control);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  public ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }
}
