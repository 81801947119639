<div class="form-check">
  <input
    type="checkbox"
    class="form-check-input"
    id="{{ context?.id }}"
    [ngModel]="value"
    [disabled]="disabled"
    [checked]="!indeterminate && value"
    [indeterminate]="indeterminate"
    (ngModelChange)="onValueChanged($event)"
    [attr.aria-describedby]="context?.describedBy"
  />
  <label class="form-check-label label" for="{{ context?.id }}">
    <ng-content select=".checkbox-label"></ng-content>
    <div class="margin-top-xs">
      <ng-content select=".checkbox-description"></ng-content>
    </div>
  </label>
</div>
