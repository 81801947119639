<div class="code-block-label-container d-flex flex-row align-items-center gap-3">
  <ng-content select="[app-code-block-label]"></ng-content>
  <div>
    <button
      ds-button
      (click)="copyCodeToClipboard()"
      [variant]="microButtonVariant"
      [microActionIcon]="copyIcon"
      [attr.aria-label]="copyButtonAriaLabel"
    >
      {{ 'copy' | translate }}
    </button>
  </div>
</div>
<pre><code class="bg-transparent">{{code}}</code></pre>
