import { Component, Input, OnInit } from '@angular/core';

import { RoutingService } from '../../../../../services/routing.service';
import { AppConfigService } from '../../../../../services/app-config.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
})
export class AccountSettingsComponent implements OnInit {
  @Input() displayHeader: boolean = true;
  public pageTitle: string;

  constructor(
    private routingService: RoutingService,
    private appConfigService: AppConfigService,
  ) {
    this.pageTitle = '';
  }

  public get isNotUsingExternalIdp(): boolean {
    return !this.appConfigService.isUsingExternalIdp();
  }

  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
  }
}
