import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  AuditToolCommonNames,
  AuditToolCommonNamesToAuditToolNames,
  AuditToolNames,
} from '../../../../../shared/constants/audit-tool';
import { $digitalPropertyMonitoring } from '../../../../../shared/constants/digital-properties';
import { $scanViewport } from '../../../../../shared/constants/scan-viewport';
import { scanRecurrenceEvery, standardNameAndLevel } from '../../../../../shared/constants/scanning';
import {
  IDigitalPropertyMonitoringDetails,
  IQuickMonitoringSetupRequest,
} from '../../../../../shared/interfaces/digital-property.interface';
import { IScanViewport } from '../../../../../shared/interfaces/scan-viewport.interface';
import { SharedDateUtility } from '../../../../../shared/utils/date.utility';
import { ScanViewport } from '../../../../../shared/utils/scan-viewport';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { EapColors } from '../../../shared/eap-colors';
import { Icons } from '../../../shared/eap-icons.component';
import { TranslateService } from '../../../translate/translate.service';
import { MonitoringDateUtils } from '../utils/monitoring-date-utils';

@Component({
  selector: 'app-monitoring-quick-setup',
  templateUrl: './monitoring-quick-setup.component.html',
  styleUrls: ['./monitoring-quick-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringQuickSetupComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription;
  public form: UntypedFormGroup;
  public recurrenceValues: { key: string; val: string }[];
  public $digitalPropertyMonitoring: typeof $digitalPropertyMonitoring;
  public Icons: typeof Icons;
  public EapColors: typeof EapColors;
  public conformanceLevel: standardNameAndLevel;

  @Input()
  public url: string;

  @Input()
  public toolName: AuditToolCommonNames;

  @Input()
  public monitoring: IDigitalPropertyMonitoringDetails;

  @Input()
  public formValidationRequest$: Subject<void>;

  @Output()
  public dataChanged: EventEmitter<void>;

  public get testingTool(): AuditToolNames {
    return AuditToolCommonNamesToAuditToolNames[this.toolName];
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {
    this.$digitalPropertyMonitoring = $digitalPropertyMonitoring;
    this.subscriptions = new Subscription();
    this.Icons = Icons;
    this.EapColors = EapColors;

    this.createForm();

    this.recurrenceValues = [scanRecurrenceEvery.week, scanRecurrenceEvery.biweekly, scanRecurrenceEvery.month].map(
      (val: string): { key: string; val: string } => ({
        key: this.translateService.instant('label_occurrence_' + val.toLowerCase()),
        val,
      }),
    );

    this.dataChanged = new EventEmitter<void>();
  }

  private createForm(): void {
    const minDate: string = SharedDateUtility.getLocalISODate(new Date());

    this.form = this.formBuilder.group({
      [$digitalPropertyMonitoring.occurrence]: this.formBuilder.control(scanRecurrenceEvery.biweekly),
      [$digitalPropertyMonitoring.startAt]: this.formBuilder.control(minDate, [CustomValidators.required]),
    });
  }

  private setStartAtFormValidator(): void {
    const currentLocalISODate: string = SharedDateUtility.getLocalISODate(new Date());
    if (
      this.monitoring?.[$digitalPropertyMonitoring.startAt] &&
      SharedDateUtility.getLocalISODate(this.monitoring?.[$digitalPropertyMonitoring.startAt]) < currentLocalISODate
    ) {
      this.subscriptions.add(
        this.form.controls[$digitalPropertyMonitoring.startAt].valueChanges
          .pipe(distinctUntilChanged())
          .subscribe((startDate: string) => {
            const requiredChange: boolean =
              startDate !== SharedDateUtility.getLocalISODate(this.monitoring?.[$digitalPropertyMonitoring.startAt]);
            if (this.form.controls[$digitalPropertyMonitoring.startAt].dirty && requiredChange) {
              this.form.controls[$digitalPropertyMonitoring.startAt].setValidators(
                CustomValidators.minDate(new Date(currentLocalISODate)),
              );
              this.form.controls[$digitalPropertyMonitoring.startAt].updateValueAndValidity();
            }
          }),
      );
    } else {
      this.form.controls[$digitalPropertyMonitoring.startAt].setValidators(
        CustomValidators.minDate(new Date(currentLocalISODate)),
      );
    }
  }

  private setConformanceLevel(): void {
    if (this.toolName === AuditToolCommonNames.access_engine) {
      this.conformanceLevel = standardNameAndLevel.WCAG_v22_AA;
    } else {
      this.conformanceLevel = standardNameAndLevel.WCAG_v21_AA;
    }
  }

  public get scanViewport(): string {
    const scanViewPort: IScanViewport = ScanViewport.getDesktopViewport();
    const viewportType: string = this.translateService.instant('scan_viewport_' + scanViewPort[$scanViewport.viewportType]);
    return this.translateService.instant('scan_viewport_value', [
      viewportType,
      scanViewPort.width.toString(),
      scanViewPort.height.toString(),
    ]);
  }

  public isValid(): boolean {
    this.formValidationRequest$.next();
    return this.form.valid;
  }

  public getData(): IQuickMonitoringSetupRequest {
    const startDate: Date = SharedDateUtility.getDateFromLocalInputDate(this.form.get($digitalPropertyMonitoring.startAt).value);
    SharedDateUtility.randomizeTime(startDate);

    return {
      [$digitalPropertyMonitoring.isQuickSetup]: true,
      [$digitalPropertyMonitoring.occurrence]: this.form.get($digitalPropertyMonitoring.occurrence).value,
      [$digitalPropertyMonitoring.startAt]: startDate.toISOString(),
      ...(startDate.toDateString() === new Date().toDateString()
        ? {
            [$digitalPropertyMonitoring.runFirstScanImmediately]: true,
          }
        : {}),
    };
  }

  public get scheduleInfo(): string {
    const startDate: Date = SharedDateUtility.getDateFromLocalInputDate(this.form.get($digitalPropertyMonitoring.startAt).value);
    const recurrence: scanRecurrenceEvery = this.form.get($digitalPropertyMonitoring.occurrence).value;
    return MonitoringDateUtils.getScheduleMessageString(this.translateService, true, recurrence, startDate);
  }

  public ngOnInit(): void {
    this.setConformanceLevel();
  }

  ngAfterViewInit(): void {
    if (this.monitoring) {
      this.form.get($digitalPropertyMonitoring.occurrence).setValue(this.monitoring[$digitalPropertyMonitoring.occurrence]);

      MonitoringDateUtils.setLocalInputDateFromDate(
        this.form.get($digitalPropertyMonitoring.startAt),
        this.monitoring[$digitalPropertyMonitoring.startAt],
      );
    }
    this.subscriptions.add(this.form.valueChanges.subscribe(() => this.dataChanged.emit()));

    this.setStartAtFormValidator();
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
