import { Component, Input } from '@angular/core';
import { IDocumentFileItem } from '../../../../document/document.interface';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IDocumentFilesCellConfig extends ICellConfig {
  attachments: IDocumentFileItem[];
}

@Component({
  selector: 'table-cell-document-attachment',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <ul #ref class="document-files-cell">
      <li *ngFor="let attachment of config.attachments">
        <a
          [href]="attachment.href"
          appDownloadLink
          [download]="attachment.fileName"
          [title]="('action_download' | translate) + ' ' + attachment.fileName"
        >
          <em aria-hidden="true" class="file-icon file-icon-{{ attachment.fileExtension }}">
            <i aria-hidden="true">{{ attachment.fileExtension }}</i>
          </em>
        </a>
      </li>
    </ul>
  `,
})
export class DocumentFilesCellComponent extends BaseCellComponent {
  @Input() public config: IDocumentFilesCellConfig;
}
