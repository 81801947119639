import { Component, EventEmitter, Input, Output } from '@angular/core';
import { auditIssuesViewStatus } from '../../../../shared/constants/audit-issues-view';

@Component({
  selector: 'app-rule-filter',
  templateUrl: './rule-filter.component.html',
  styleUrls: ['./rule-filter.component.scss'],
})
export class RuleFilterComponent {
  @Input() public status: auditIssuesViewStatus;

  @Input() public failedRulesCount: number;
  @Input() public reviewRulesCount: number;
  @Input() public passedRulesCount: number;
  @Input() public hidePassedRules: boolean;

  @Output() public onFilterChanged: EventEmitter<auditIssuesViewStatus> = new EventEmitter<auditIssuesViewStatus>();

  public issueStatus: typeof auditIssuesViewStatus;

  constructor() {
    this.issueStatus = auditIssuesViewStatus;
  }

  public onFilterSelect(event: auditIssuesViewStatus): void {
    this.onFilterChanged.emit(event);
  }
}
