import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { $checkboxSelect, IFilterOption, IFilterOutput } from '../../filter.interface';
import { CommonUtility } from '../../../../utility/common.utility';

const defaultValue = [];

@Component({
  selector: 'app-filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  styleUrls: ['./filter-checkbox.component.scss'],
})
export class FilterCheckboxComponent<T> implements OnChanges {
  @Input() public isInMenu: boolean = true;
  @Input() public options: IFilterOption<T>[] | null;
  @Input() public label: string;
  @Input() public field: string;
  @Input() public value: T[];
  @Input() public set disabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output() public valueChange: EventEmitter<IFilterOutput<T>>;

  public output: IFilterOutput<T>;

  public form: UntypedFormGroup;
  public $checkboxSelect: typeof $checkboxSelect;
  public domId: string;

  public constructor(private formBuilder: UntypedFormBuilder) {
    this.label = '';
    this.options = defaultValue;
    this.value = [];
    this.$checkboxSelect = $checkboxSelect;
    this.valueChange = new EventEmitter<IFilterOutput<T>>();
    this.domId = this.createDomID(999);

    this.createForm();
  }

  private get optionsFormArray(): UntypedFormArray {
    return this.form.controls.options as UntypedFormArray;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      options: new UntypedFormArray([]),
    });
  }

  private addOptionsToForm(): void {
    (this.options ?? []).forEach((option: IFilterOption<T>, index: number) => {
      if (this.optionsFormArray.at(index)) {
        this.optionsFormArray.removeAt(index);
      }
      this.optionsFormArray.push(
        new UntypedFormControl({ value: this.value.includes(option.value), disabled: this.form.disabled }),
      );
    });
  }

  public resetToInitialValue(): void {
    (this.options ?? []).forEach((option: IFilterOption<T>, index: number) => {
      const control: AbstractControl = this.optionsFormArray.at(index);
      if (control !== undefined) {
        control.setValue(this.value.includes(option.value));
      }
    });
  }

  public get values(): Array<T> {
    return this.form
      .get('options')
      .value.map((checked: boolean, i: number) => (checked ? this.options[i].value : null))
      .filter((value: T | null) => value !== null);
  }

  public onFormChange(selectType: $checkboxSelect = null): void {
    const valueToEmit: IFilterOutput<T> = {
      field: String(this.field),
      values: [],
    };
    if (selectType) {
      let checkValue: boolean;
      if (selectType === $checkboxSelect.unselectAll) {
        checkValue = false;
      } else if (selectType === $checkboxSelect.selectAll) {
        valueToEmit.values = this.options.map((option: IFilterOption<T>) => option.value);
        checkValue = true;
      }
      this.optionsFormArray.controls.forEach((control: UntypedFormControl) => {
        control.patchValue(checkValue);
      });
    } else {
      valueToEmit.values = this.values;
    }
    this.valueChange.emit(valueToEmit);
  }

  public createDomID(i: number): string {
    return `${i}_${CommonUtility.createUniqueDOMId()}`;
  }

  public get controls(): AbstractControl[] {
    return (this.form.get('options') as UntypedFormArray).controls;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.addOptionsToForm();
    }

    if (changes.value) {
      this.resetToInitialValue();
    }
  }
}
