import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DsButtonVariants, Icons, IconScale, IconStyles } from '@levelaccess/design-system';

import { UserService } from '../../../services/user.service';
import { Api, ApiQueryOption } from '../../../../../shared/constants/api';
import { IUserEmailResponse } from '../../../../../shared/interfaces/user.interface';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { AppConfigService } from '../../../services/app-config.service';
import { IUserSignupResponse } from '../../../../../shared/interfaces/user-authentication.interface';
import { PackageNames } from '../../../../../shared/constants/packaging';
import { TranslateService } from '../../../translate/translate.service';
import { $tenant } from '../../../../../shared/constants/tenant';
import { TenantPackageService } from '../../../services/tenant-package.service';

export enum SignupState {
  TOKEN_VALIDATION = 'TOKEN_VALIDATION',
  TOKEN_INVALID = 'TOKEN_INVALID',
  SIGNUP = 'SIGNUP',
  PLATFORM_USAGE = 'PLATFORM_USAGE',
  OVERVIEW = 'OVERVIEW',
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  public readonly Icons: typeof Icons = Icons;
  public readonly IconStyles: typeof IconStyles = IconStyles;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly IconScale: typeof IconScale = IconScale;
  public readonly SignupState: typeof SignupState = SignupState;

  private subscriptions: Subscription = new Subscription();

  public currentSignupStep: number = 1;
  public totalSignupSteps: number = 3;

  public signupState: BehaviorSubject<SignupState> = new BehaviorSubject(SignupState.TOKEN_VALIDATION);
  public submit: Subject<void> = new Subject();
  public email: string;
  public confirmEmailToken: string;
  public tenantName: string;
  public showStep: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private appConfigService: AppConfigService,
    private translateService: TranslateService,
    private tenantPackageService: TenantPackageService,
  ) {
    this.showStep = this.appConfigService.isTenantEnabled() && this.tenantPackageService.tenantPackage !== PackageNames.Audit;
  }

  public get title(): string {
    switch (this.signupState.value) {
      case SignupState.TOKEN_VALIDATION:
      case SignupState.SIGNUP:
      default:
        return this.translateService.instant('sign_up');
      case SignupState.PLATFORM_USAGE:
        return this.translateService.instant('sign_up_platform_usage');
      case SignupState.OVERVIEW:
        return this.translateService.instant('sign_up_welcome_to_organization', this.tenantName);
    }
  }

  public onSignupSuccess(response: IUserSignupResponse): void {
    if (this.tenantPackageService.tenantPackage === PackageNames.Audit) {
      this.navigate([`/${Api.portfolio}`]);
      return;
    }

    if (this.appConfigService.isTenantEnabled() === false) {
      this.navigate([`/${Api.home}`]);
      return;
    }

    this.signupState.next(SignupState.PLATFORM_USAGE);
    this.currentSignupStep = 2;
    this.tenantName = response.tenantInfo?.[$tenant.name];
  }

  public onPlatformUsageSubmitSuccess(): void {
    this.signupState.next(SignupState.OVERVIEW);
    this.currentSignupStep = 3;
  }

  public onOverviewSuccess(): void {
    this.navigate([`/${Api.home}`]);
  }

  public ngOnInit(): void {
    if (this.userService.isAuthenticated()) {
      this.navigate([`/${Api.home}`]);
      return;
    }

    this.confirmEmailToken = this.activatedRoute.snapshot.queryParams[ApiQueryOption.confirmEmailToken];

    this.subscriptions.add(
      this.userService.getEmailByToken(this.confirmEmailToken).subscribe({
        next: (response: IUserEmailResponse) => {
          this.email = response.email;
          this.signupState.next(SignupState.SIGNUP);
        },
        error: () => {
          this.signupState.next(SignupState.TOKEN_INVALID);
        },
      }),
    );
  }

  private navigate(path: string[]): void {
    this.router.navigate(path).catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
