<div>
  <h4>{{ stepNumber + 1 }}. {{ 'review' | translate }}</h4>

  <div class="margin-bottom-lg">
    <ng-container *ngTemplateOutlet="stepHeading; context: { step: $advancedSetupStep.scope, header: 'scope' }"> </ng-container>

    <div class="d-flex margin-bottom page-discovery">
      <div class="d-flex flex-column margin-right-lg">
        <label class="d-block">{{ 'page_discovery_method' | translate }}</label>
        <span class="d-block section-description">{{ 'label_page_option_' + discoveryMethod.toLowerCase() | translate }}</span>
      </div>
      <ng-container [ngSwitch]="discoveryMethod">
        <ng-container *ngSwitchCase="MonitoringDiscoveryMethod.crawl">
          <div class="d-flex flex-column margin-right-lg">
            <label class="d-block">{{ 'start_page' | translate }}</label>
            <span class="d-block section-description">
              {{ monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.url] }}
            </span>
          </div>
          <div class="d-flex flex-column margin-right-lg">
            <label class="d-block">{{ 'label_maximum_number_of_pages' | translate }}</label>
            <span class="d-block section-description">
              {{ monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.limitCrawlingPagesTo] }}
            </span>
          </div>
          <div class="d-flex flex-column margin-right-lg">
            <label class="d-block">{{ 'label_crawl_depth' | translate }}</label>
            <span class="d-block section-description">
              {{ monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.crawlDeepLevel] }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="MonitoringDiscoveryMethod.sitemap">
          <div class="d-flex flex-column margin-right-lg">
            <label class="d-block">{{ 'label_dp_sitemap_url' | translate }}</label>
            <span class="d-block section-description">
              {{ monitoring[$digitalPropertyMonitoring.crawler]?.[$digitalPropertyMonitoring.sitemapUrl] }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="MonitoringDiscoveryMethod.listOfPages">
          <div class="d-flex flex-column margin-right-lg">
            <label class="d-block">{{ 'list_of_pages' | translate }}</label>
            <ul class="list-unstyled">
              <li *ngFor="let page of monitoring[$digitalPropertyMonitoring.pagesToMonitor]">
                {{ page }}
              </li>
            </ul>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="hasIgnoreUrlEndings" class="d-flex margin-bottom url-endings">
      <div class="d-flex flex-column margin-right-lg">
        <label class="d-block">{{ 'ignore_url_endings' | translate }}</label>
        <span class="d-block section-description">{{ ignoredEndings }}</span>
      </div>
    </div>

    <div *ngIf="hasExcludedPages" class="d-flex margin-bottom url-endings">
      <div class="d-flex flex-column margin-right-lg">
        <label class="d-block">{{ 'exclude_pages' | translate }}</label>
        <span class="d-block section-description">{{ excludedPages }}</span>
      </div>
    </div>

    <div *ngIf="hasAuthentication" class="d-flex margin-bottom url-endings">
      <div class="d-flex flex-column margin-right-lg">
        <label class="d-block">{{ 'select_scan_authentication_type' | translate }}</label>
        <span class="d-block section-description">
          {{ 'scan_authentication_type_' + monitoring[$digitalPropertyMonitoring.scanAuthenticationType] | translate }}
        </span>
      </div>
    </div>

    <div *ngIf="hasPopupButtonSelector" class="d-flex margin-bottom url-endings">
      <div class="d-flex flex-column margin-right-lg" >
        <label class="d-block">{{ 'close_popup_button_selector' | translate }}</label>
        <span class="d-block section-description">{{ popupButtonSelector }}</span>
      </div>
    </div>

    <div *ngIf="hasWaitTime" class="d-flex margin-bottom url-endings">
      <div class="d-flex flex-column margin-right-lg" >
        <label class="d-block">{{ 'wait_time_label' | translate }}</label>
        <span class="d-block section-description">{{ waitTime }}</span>
      </div>
    </div>

    <ng-container [ngSwitch]="monitoring[$digitalPropertyMonitoring.scanAuthenticationType]">
      <ng-container *ngSwitchCase="scanAuthenticationType.basic_authentication">
        <ng-container *ngTemplateOutlet="basicAuthentication"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="scanAuthenticationType.secure_web_authentication">
        <ng-container *ngTemplateOutlet="swaAuthentication"></ng-container>
      </ng-container>
    </ng-container>

    <div *ngIf="hasHeaders" class="d-flex margin-bottom url-endings">
      <div class="d-flex flex-column margin-right-lg">
        <label class="d-block">{{ 'label_header' | translate }}</label>
        <span *ngFor="let header of monitoring['headers']" class="d-block section-description">
          {{ header.name }}
        </span>
      </div>
      <div class="d-flex flex-column margin-right">
        <label class="d-block">{{ 'label_value' | translate }}</label>
        <span *ngFor="let header of monitoring[$digitalPropertyMonitoring.headers]" class="d-block section-description">
          {{ header.value }}
        </span>
      </div>
    </div>
  </div>

  <div class="margin-bottom-lg">
    <ng-container *ngTemplateOutlet="stepHeading; context: { step: $advancedSetupStep.schedule, header: 'schedule' }">
    </ng-container>
    <div>{{ scheduleInfo }}</div>
  </div>

  <div class="margin-bottom-lg">
    <ng-container *ngTemplateOutlet="stepHeading; context: { step: $advancedSetupStep.scanSettings, header: 'scan_settings' }">
    </ng-container>

    <div class="d-flex margin-bottom">
      <div class="d-flex flex-column margin-right">
        <label class="d-block">{{ 'testing_tool' | translate }}</label>
        <span class="d-block section-description">{{ 'scan_results_tool_option_' + testingTool | translate }}</span>
      </div>
      <div class="d-flex flex-column margin-right">
        <label class="d-block">{{ 'digital_default_conformance_level_label' | translate }}</label>
        <span class="d-block section-description">{{ conformanceLevel | translate }}</span>
      </div>
      <div class="d-flex flex-column margin-right">
        <label class="d-block">{{ 'scan_viewport_label' | translate }}</label>
        <span class="d-block section-description">{{ scanViewport }}</span>
      </div>
    </div>
  </div>

  <div class="margin-bottom-lg">
    <ng-container *ngTemplateOutlet="stepHeading; context: { step: $advancedSetupStep.alerts, header: 'email_alerts' }">
    </ng-container>
    <app-monitoring-alert-table
      *ngIf="containsEmailAlerts; else noEmailAlerts"
      [alerts]="monitoring?.alerts"
    ></app-monitoring-alert-table>
    <ng-template #noEmailAlerts>
      <span class="d-block section-description no-email-alerts">{{ 'no_email_alerts' | translate }}</span>
    </ng-template>
  </div>

  <app-common-info-note>
    {{ 'monitoring_ea_allow_list_info' | translate }}
    <a href="https://wcag.zendesk.com/hc/en-us/articles/9249741469847" target="_blank">
      {{ 'monitoring_ea_allow_list_info_link_label' | translate }}
      <app-icon [icon]="Icons.ExternalLinkIcon" [style.color]="EapColors.BlueInteraction"></app-icon>
    </a>
  </app-common-info-note>
</div>

<ng-template #stepHeading let-step="step" let-header="header">
  <div class="d-flex align-items-center">
    <h5 class="d-inline me-2">{{ header | translate }}</h5>
    <button class="btn btn-link" (click)="onEditStep(step)">
      <svg aria-hidden="true" focusable="false" viewBox="0 0 15 15" class="edit-icon">
        <use xlink:href="#pencil"></use>
      </svg>
      <span>{{ 'action_edit' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #basicAuthentication>
  <div class="d-flex margin-bottom">
    <div class="d-flex flex-column margin-right">
      <label class="d-block">{{ 'scan_authentication_user_name' | translate }}</label>
      <span class="d-block section-description">
        {{ monitoring[$digitalPropertyMonitoring.scanAuthenticationUserName] }}
      </span>
    </div>
    <div class="d-flex flex-column margin-right">
      <label class="d-block">{{ 'scan_authentication_password' | translate }}</label>
      <span class="d-block section-description">
        {{ '********' }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #swaAuthentication>
  <div class="d-flex margin-bottom">
    <div class="d-flex flex-column margin-right">
      <label class="d-block">{{ 'scan_authentication_login_url' | translate }}</label>
      <span class="d-block section-description">
        {{ monitoring[$digitalPropertyMonitoring.secureWebAuthentication][$scan.swaLoginPageURL] }}
      </span>
    </div>
  </div>

  <div class="row margin-bottom">
    <div class="col flex-column margin-right">
      <label class="d-block">{{ 'scan_authentication_user_name' | translate }}</label>
      <span class="d-block section-description">
        {{ monitoring[$digitalPropertyMonitoring.secureWebAuthentication][$scan.swaUserName] }}
      </span>
    </div>
    <div class="col flex-column margin-right">
      <label class="d-block">{{ 'username_field_css_selector' | translate }}</label>
      <span class="d-block section-description">
        {{ monitoring[$digitalPropertyMonitoring.secureWebAuthentication][$scan.swaUserNameFieldId] }}
      </span>
    </div>
  </div>

  <div class="row margin-bottom">
    <div class="col flex-column margin-right">
      <label class="d-block">{{ 'scan_authentication_password' | translate }}</label>
      <span class="d-block section-description">
        {{ '********' }}
      </span>
    </div>
    <div class="col flex-column margin-right">
      <label class="d-block">{{ 'password_field_css_selector' | translate }}</label>
      <span class="d-block section-description">
        {{ monitoring[$digitalPropertyMonitoring.secureWebAuthentication][$scan.swaPasswordFieldId] }}
      </span>
    </div>
  </div>

  <div class="row margin-bottom">
    <div class="col flex-column margin-right">
      <label class="d-block">{{ 'submit_button_css_selector' | translate }}</label>
      <span class="d-block section-description">
        {{ monitoring[$digitalPropertyMonitoring.secureWebAuthentication][$scan.swaSubmitFieldId] }}
      </span>
    </div>
    <div class="col flex-column margin-right">
      <label class="d-block">{{ 'success_element_css_selector' | translate }}</label>
      <span class="d-block section-description">
        {{ monitoring[$digitalPropertyMonitoring.secureWebAuthentication][$scan.swaSubmitSuccessId] }}
      </span>
    </div>
  </div>
</ng-template>
