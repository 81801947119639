import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  $digitalPropertyMonitoringAlertType,
  $digitalPropertyMonitoringAlerts,
} from '../../../../../../../../shared/constants/digital-properties';
import { $user, accountStatus } from '../../../../../../../../shared/constants/user';
import { $workspace } from '../../../../../../../../shared/constants/workspace';
import { WorkspaceUserSearchFields } from '../../../../../../../../shared/constants/workspace-user.constants';
import {
  IDigitalPropertyMonitoringUserAlertsWithEmail,
  IDigitalPropertyWorkspace,
} from '../../../../../../../../shared/interfaces/digital-property.interface';
import { IUser } from '../../../../../../../../shared/interfaces/user.interface';
import { SharedCommonUtility } from '../../../../../../../../shared/utils/common.utility';
import { ErrorMessageService } from '../../../../../../services/error-message.service';
import { CustomValidators } from '../../../../../../services/helpers/form-custom-validators';
import { LoadErrorHandler } from '../../../../../../services/load-error-handler';
import { WorkspaceService } from '../../../../../../services/workspace.service';

enum FormFields {
  email = 'email',
  scanCompletion = 'scanCompletion',
  scoreDropByPercent = 'scoreDropByPercent',
  scoreDropByPercentThreshold = 'scoreDropByPercentThreshold',
  scoreDropByPercentFromPrevious = 'scoreDropByPercentFromPrevious',
  scoreDropByPercentFromPreviousThreshold = 'scoreDropByPercentFromPreviousThreshold',
  atLeastOneAlertSelected = 'atLeastOneAlertSelected',
}

@Component({
  selector: 'app-monitoring-alerts-editor',
  templateUrl: './monitoring-alerts-editor.component.html',
  styleUrls: ['monitoring-alerts-editor.component.scss'],
})
export class MonitoringAlertsEditorComponent implements AfterViewInit, OnDestroy, OnInit {
  private userAlerts$: ReplaySubject<IDigitalPropertyMonitoringUserAlertsWithEmail>;
  private _userAlerts: IDigitalPropertyMonitoringUserAlertsWithEmail;
  private userIdsMap: Record<string, string>;
  private subscriptions: Subscription;
  public notMonitoringSettingsUpdate: string;

  @Input()
  public set userAlerts(value: IDigitalPropertyMonitoringUserAlertsWithEmail) {
    this.userAlerts$.next(value);
  }

  @Input()
  public allAlertEmails: string[];

  @Input()
  public workspace: IDigitalPropertyWorkspace;

  @Input()
  public formValidationRequest$: Subject<void>;

  @Output()
  public onCancel: EventEmitter<void>;

  @Output()
  public onSave: EventEmitter<IDigitalPropertyMonitoringUserAlertsWithEmail>;

  public scoreDropSelected: boolean;
  public scoreDropFromPreviousSelected: boolean;

  public alertEditorForm: UntypedFormGroup;
  public formFields: typeof FormFields;
  constructor(
    private element: ElementRef<HTMLElement>,
    private formBuilder: UntypedFormBuilder,
    private errorMessageService: ErrorMessageService,
    private workspaceService: WorkspaceService,
    private loadErrorHandler: LoadErrorHandler,
  ) {
    this.formFields = FormFields;
    this.onCancel = new EventEmitter<void>();
    this.onSave = new EventEmitter<IDigitalPropertyMonitoringUserAlertsWithEmail>();
    this.userAlerts$ = new ReplaySubject<IDigitalPropertyMonitoringUserAlertsWithEmail>(1);
    this.userIdsMap = {};
    this.subscriptions = new Subscription();
    this.scoreDropSelected = false;
    this.scoreDropFromPreviousSelected = false;
    this.createForm();
  }
  private validateOneAlertSelected(control: AbstractControl): ValidationErrors | null {
    const atLeastOneSelected: boolean =
      control.value[FormFields.scanCompletion] === true ||
      control.value[FormFields.scoreDropByPercent] === true ||
      control.value[FormFields.scoreDropByPercentFromPrevious] === true;

    return atLeastOneSelected ? null : { noSelection: true };
  }

  private validateAlertEmail(control: AbstractControl): ValidationErrors | null {
    const email: string = control?.value.trim().toLowerCase();
    if (SharedCommonUtility.isValidEmail(email) && email !== this._userAlerts[$digitalPropertyMonitoringAlerts.email]) {
      if (this.allAlertEmails.includes(email)) {
        return { notUniqueEmail: true };
      }
      if (SharedCommonUtility.isNullish(this.userIdsMap[email])) {
        return { notPlatformEmail: true };
      }
    }
    return null;
  }

  private createForm(): void {
    this.alertEditorForm = this.formBuilder.group(
      {
        [FormFields.email]: this.formBuilder.control('', [
          CustomValidators.required,
          CustomValidators.validateEmail,
          this.validateAlertEmail.bind(this),
        ]),
        [FormFields.scanCompletion]: this.formBuilder.control(false),
        [FormFields.scoreDropByPercent]: this.formBuilder.control(false),
        [FormFields.scoreDropByPercentFromPrevious]: this.formBuilder.control(false),
      },

      { validators: [this.validateOneAlertSelected] },
    );
  }

  private getWorkspaceUsers(): void {
    this.subscriptions.add(
      this.workspaceService
        .getUsers(this.workspace[$workspace._id], {
          [WorkspaceUserSearchFields.status]: [accountStatus.active],
          [WorkspaceUserSearchFields.includeStaffUsers]: true,
        })
        .subscribe({
          next: this.onGetUsersSuccess.bind(this),
          error: this.onGetUsersError.bind(this),
        }),
    );
  }

  private onGetUsersSuccess(users: IUser[]): void {
    this.userIdsMap = users.reduce((acc: Record<string, string>, user: IUser) => {
      acc[user[$user.email]] = user[$user._id];
      return acc;
    }, {});
  }

  private onGetUsersError(error: HttpErrorResponse): void {
    this.loadErrorHandler.handleError('unable_to_get_all_workspace_users', error, MonitoringAlertsEditorComponent.name);
  }

  public isValid(): boolean {
    this.formValidationRequest$.next();
    if (!this.alertEditorForm.valid) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return false;
    }
    return true;
  }

  private updateScoreDropSelected(selected: boolean): void {
    this.scoreDropSelected = selected;
    if (this.scoreDropSelected) {
      this.alertEditorForm.addControl(
        FormFields.scoreDropByPercentThreshold,
        this.formBuilder.control(this._userAlerts?.[$digitalPropertyMonitoringAlerts.alerts].scoreDropByPercent?.threshold, [
          CustomValidators.validateInteger,
          CustomValidators.min(1),
          CustomValidators.max(100),
        ]),
      );
    } else {
      this.alertEditorForm.removeControl(FormFields.scoreDropByPercentThreshold);
    }
  }

  private updateScoreDropFromPreviousSelected(selected: boolean): void {
    this.scoreDropFromPreviousSelected = selected;
    if (this.scoreDropFromPreviousSelected) {
      this.alertEditorForm.addControl(
        FormFields.scoreDropByPercentFromPreviousThreshold,
        this.formBuilder.control(
          this._userAlerts?.[$digitalPropertyMonitoringAlerts.alerts].scoreDropByPercentFromPrevious?.threshold,
          [CustomValidators.required, CustomValidators.validateInteger, CustomValidators.min(1), CustomValidators.max(100)],
        ),
      );
    } else {
      this.alertEditorForm.removeControl(FormFields.scoreDropByPercentFromPreviousThreshold);
    }
  }

  private initValueFromData(userAlerts: IDigitalPropertyMonitoringUserAlertsWithEmail | null): void {
    this._userAlerts = userAlerts;

    if (!userAlerts) {
      return;
    }

    this.alertEditorForm.get(FormFields.email).setValue(userAlerts[$digitalPropertyMonitoringAlerts.email]);
    this.alertEditorForm
      .get(FormFields.scanCompletion)
      .setValue(userAlerts[$digitalPropertyMonitoringAlerts.alerts].scanCompletion?.set);

    this.alertEditorForm
      .get(FormFields.scoreDropByPercent)
      .setValue(userAlerts[$digitalPropertyMonitoringAlerts.alerts].scoreDropByPercent?.set);
    this.updateScoreDropSelected(userAlerts[$digitalPropertyMonitoringAlerts.alerts].scoreDropByPercent?.set ?? false);

    this.alertEditorForm
      .get(FormFields.scoreDropByPercentFromPrevious)
      .setValue(userAlerts[$digitalPropertyMonitoringAlerts.alerts].scoreDropByPercentFromPrevious?.set);
    this.updateScoreDropFromPreviousSelected(
      userAlerts[$digitalPropertyMonitoringAlerts.alerts].scoreDropByPercentFromPrevious?.set ?? false,
    );
  }

  private getThresholdValue(field: string): number | undefined {
    const rawValue: string | number | undefined = this.alertEditorForm.get(field)?.value;
    if (typeof rawValue === 'number') {
      return Math.floor(rawValue);
    }
    if (typeof rawValue === 'string') {
      return rawValue.trim() ? Math.floor(Number(rawValue)) : undefined;
    }

    return undefined;
  }

  private getValue(): IDigitalPropertyMonitoringUserAlertsWithEmail {
    const email: string = this.alertEditorForm.get(FormFields.email).value.trim().toLowerCase();
    return {
      [$digitalPropertyMonitoringAlerts.userId]: this.userIdsMap[email],
      [$digitalPropertyMonitoringAlerts.email]: email,
      [$digitalPropertyMonitoringAlerts.alerts]: {
        [$digitalPropertyMonitoringAlertType.scanCompletion]: {
          set: this.alertEditorForm.get(FormFields.scanCompletion).value,
        },
        [$digitalPropertyMonitoringAlertType.scoreDropByPercent]: {
          set: this.alertEditorForm.get(FormFields.scoreDropByPercent).value,
          threshold: this.getThresholdValue(FormFields.scoreDropByPercentThreshold),
        },
        [$digitalPropertyMonitoringAlertType.scoreDropByPercentFromPrevious]: {
          set: this.alertEditorForm.get(FormFields.scoreDropByPercentFromPrevious).value,
          threshold: this.getThresholdValue(FormFields.scoreDropByPercentFromPreviousThreshold),
        },
      },
    };
  }

  public onSaveClicked(): void {
    if (!this.isValid()) {
      return;
    }
    this.onSave.next(this.getValue());
  }

  public onCancelClicked(): void {
    this.onCancel.next();
  }

  public onAlertSelectionChanged(field: FormFields): void {
    switch (field) {
      case FormFields.scoreDropByPercent:
        this.updateScoreDropSelected(this.alertEditorForm.get(field).value);
        break;
      case FormFields.scoreDropByPercentFromPrevious:
        this.updateScoreDropFromPreviousSelected(this.alertEditorForm.get(field).value);
        break;
      default:
        break;
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.userAlerts$
        .pipe(
          map((value: IDigitalPropertyMonitoringUserAlertsWithEmail) => {
            this.initValueFromData(value);
          }),
        )
        .subscribe(),
    );
  }

  ngOnInit(): void {
    this.getWorkspaceUsers();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
