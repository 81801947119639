import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { $digitalPropertyMonitoring } from '../../../../../../../shared/constants/digital-properties';
import { scanRecurrenceEvery } from '../../../../../../../shared/constants/scanning';
import { IDigitalPropertyMonitoringDetails } from '../../../../../../../shared/interfaces/digital-property.interface';
import { SharedDateUtility } from '../../../../../../../shared/utils/date.utility';
import { CustomValidators } from '../../../../../services/helpers/form-custom-validators';
import { TranslateService } from '../../../../../translate/translate.service';
import { MonitoringDateUtils } from '../../../utils/monitoring-date-utils';

@Component({
  selector: 'app-monitoring-schedule-step',
  templateUrl: './monitoring-schedule-step.component.html',
})
export class MonitoringScheduleStepComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  private subscriptions: Subscription;
  public RECURRENCE_OPTIONS: { key: string; value: scanRecurrenceEvery }[];
  public $digitalPropertyMonitoring: typeof $digitalPropertyMonitoring;

  @Input() public stepNumber: number;
  @Input() public monitoring: IDigitalPropertyMonitoringDetails;
  @Input() public form: UntypedFormGroup;

  @Input() public formValidationRequest$: Subject<void>;

  constructor(
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.RECURRENCE_OPTIONS = [scanRecurrenceEvery.week, scanRecurrenceEvery.biweekly, scanRecurrenceEvery.month].map(
      (option: scanRecurrenceEvery) => ({
        key: this.translateService.instant(`label_occurrence_${option.toLowerCase()}`),
        value: option,
      }),
    );
    this.$digitalPropertyMonitoring = $digitalPropertyMonitoring;
    this.subscriptions = new Subscription();
  }

  private setStartAtFormValidator(): void {
    const currentLocalISODate: string = SharedDateUtility.getLocalISODate(new Date());
    if (
      this.monitoring?.[$digitalPropertyMonitoring.startAt] &&
      SharedDateUtility.getLocalISODate(this.monitoring?.[$digitalPropertyMonitoring.startAt]) < currentLocalISODate
    ) {
      this.subscriptions.add(
        this.form.controls[$digitalPropertyMonitoring.startAt].valueChanges
          .pipe(distinctUntilChanged())
          .subscribe((startDate: string) => {
            const requiredChange: boolean =
              startDate !== SharedDateUtility.getLocalISODate(this.monitoring?.[$digitalPropertyMonitoring.startAt]);
            if (this.form.controls[$digitalPropertyMonitoring.startAt].dirty && requiredChange) {
              this.form.controls[$digitalPropertyMonitoring.startAt].setValidators([
                CustomValidators.required,
                CustomValidators.minDate(new Date(currentLocalISODate)),
              ]);
              this.form.controls[$digitalPropertyMonitoring.startAt].updateValueAndValidity();
            }
          }),
      );
    } else {
      this.form.controls[$digitalPropertyMonitoring.startAt].setValidators([
        CustomValidators.required,
        CustomValidators.minDate(new Date(currentLocalISODate)),
      ]);
    }
  }

  public get scheduleSet(): string {
    const startDate: Date = SharedDateUtility.getDateFromLocalInputDate(this.form.get($digitalPropertyMonitoring.startAt).value);
    const recurrence: scanRecurrenceEvery = this.form.get($digitalPropertyMonitoring.occurrence).value;

    return MonitoringDateUtils.getScheduleMessageString(this.translateService, true, recurrence, startDate);
  }

  public ngAfterViewInit(): void {
    if (this.monitoring?.[$digitalPropertyMonitoring.occurrence]) {
      this.form.get($digitalPropertyMonitoring.occurrence).setValue(this.monitoring[$digitalPropertyMonitoring.occurrence]);
    }
    if (this.monitoring?.[$digitalPropertyMonitoring.startAt]) {
      MonitoringDateUtils.setLocalInputDateFromDate(
        this.form.get($digitalPropertyMonitoring.startAt),
        this.monitoring[$digitalPropertyMonitoring.startAt],
      );
    }

    this.setStartAtFormValidator();
  }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
