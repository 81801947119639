import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { IBreadcrumbsResolver } from '../../components/breadcrumbs/breadcrumbs.shared';
import { IPostProcessBreadcrumb } from './breadcrumbs.config';

export abstract class AbstractBreadcrumbsResolver implements IBreadcrumbsResolver {
  public abstract resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): IPostProcessBreadcrumb;

  public getFullPath(route: ActivatedRouteSnapshot): string {
    const relativePath = (segments: UrlSegment[]): string =>
      segments.reduce((path: string, urlSegment: UrlSegment): string => `${path}/${urlSegment.path}`, '');

    const fullPath = (routes: ActivatedRouteSnapshot[]): string =>
      routes.reduce((url: string, snapshot: ActivatedRouteSnapshot): string => url + relativePath(snapshot.url), '');

    return fullPath(route.pathFromRoot);
  }
}
