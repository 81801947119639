import { Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IImageCellConfig extends ICellConfig {
  src: string;
  alt: string;
}

@Component({
  selector: 'table-cell-image',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <img
      #ref
      [src]="config.src"
      [alt]="config.alt"
      [style]="{ 'max-width': config.styles.maxWidth, 'max-height': config.styles.maxHeight }"
    />
  `,
})
export class ImageCellComponent extends BaseCellComponent {
  @Input() public config: IImageCellConfig;
}
