import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';

import { ErrorMessageService } from './error-message.service';
import { A11yService } from './a11y.service';
import { NotificationService } from './notification.service';
import { TranslateService } from '../translate/translate.service';
import { NotificationPosition } from '../models/notification.model';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Injectable({
  providedIn: 'root',
})
export class LoadErrorHandler {
  constructor(
    private errorMessageService: ErrorMessageService,
    private a11yService: A11yService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  private logAndDisplayError(
    errorMessage: string,
    appMessage: string,
    response: HttpErrorResponse,
    componentName: string,
    logMessageInResponse: boolean = false,
  ): void {
    const fullMessage: string = `${appMessage}: ${errorMessage}`;
    this.a11yService.setMessage(fullMessage);
    this.notificationService.error(logMessageInResponse ? fullMessage : appMessage, NotificationPosition.Toast, true);

    console.error(`[${componentName}.handleError: ` + appMessage + ']', response, errorMessage);
  }

  private getErrorMessage(response: HttpErrorResponse): string {
    return SharedCommonUtility.isNullish(response) ? '' : this.errorMessageService.getGlobalErrorResponse(response);
  }

  public handleError(
    message: string,
    response: HttpErrorResponse,
    componentName: string,
    logMessageInResponse: boolean = false,
  ): void {
    const errorMessage: string = this.getErrorMessage(response);
    const appMessage: string = this.translateService.instant(message || errorMessage);
    this.logAndDisplayError(errorMessage, appMessage, response, componentName, logMessageInResponse);
  }

  public handleErrorBindable(message: string, componentName: string, response: HttpErrorResponse): void {
    this.handleError(message, response, componentName);
  }

  public handleErrorObservable(message: string, componentName: string, response: HttpErrorResponse): Observable<never> {
    this.handleError(message, response, componentName);
    return EMPTY;
  }

  public handleErrorWithCustomAppMessage(
    message: string,
    appMessage: string,
    response: HttpErrorResponse,
    componentName: string,
  ): void {
    const errorMessage: string = this.getErrorMessage(response);
    this.logAndDisplayError(errorMessage, appMessage, response, componentName);
  }
}
