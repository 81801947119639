import { Component, OnInit, Input } from '@angular/core';
import { ITrendConfig } from '../../../../common-trend/common-trend.component';
import { ICellConfig } from '../base-cell/base-cell.component';

export interface IDataPointCellConfig extends ICellConfig {
  value: number;
  text?: string;
  isIncreaseImprovement?: boolean;
  previousValue?: number;
  showNoChange?: boolean;
}

@Component({
  selector: 'table-cell-data-point',
  template: `
    <span class="me-1">
      {{ config.text ?? config.value }}
    </span>
    <ng-container *ngIf="hasPreviousData">
      <app-common-trend [config]="trendConfig"></app-common-trend>
    </ng-container>
  `,
})
export class DataPointCellComponent implements OnInit {
  public trendConfig: ITrendConfig;

  @Input() public config: IDataPointCellConfig;

  public get hasPreviousData(): boolean {
    return typeof this.config.previousValue === 'number';
  }

  public ngOnInit(): void {
    if (this.hasPreviousData) {
      this.buildTrendConfig();
    }
  }

  private buildTrendConfig(): void {
    this.trendConfig = {
      currentValue: this.config.value,
      previousValue: this.config.previousValue,
      isIncreaseImprovement: this.config.isIncreaseImprovement,
      showNoChange: this.config.showNoChange,
    };
  }
}
