import { OnInit, OnDestroy, Directive } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription, Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { delay, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Icons, IconStyles, DsButtonVariants } from '@levelaccess/design-system';
import _ from 'lodash';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../translate/translate.service';
import { A11yService } from '../../../services/a11y.service';
import { FormService } from '../../../services/form.service';
import { NotificationService } from '../../../services/notification.service';
import { NotificationPosition } from '../../../models/notification.model';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { alertType } from '../../../constants/alert.constants';
import { $user, minPasswordLength } from '../../../../../shared/constants/user';
import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { AngularUtility } from '../../../utility/angular.utility';
import { CommonUtility } from '../../../utility/common.utility';
import { errorMessagesNames } from '../../../../../shared/constants/errors';
import { PASSWORD_ERRORS } from '../password-errors.constants';
import { DEBOUNCE_TIME } from '../../../shared/constants';

const mapPasswordErrorToTranslation: Record<PASSWORD_ERRORS, string> = {
  [PASSWORD_ERRORS.minlength]: 'at_least_X_characters',
  [PASSWORD_ERRORS.noLowercase]: 'at_least_1_lowercase_letter',
  [PASSWORD_ERRORS.noUppercase]: 'at_least_1_uppercase_letter',
  [PASSWORD_ERRORS.noNumberOrSpecial]: 'at_least_1_number_or_special_character',
  [PASSWORD_ERRORS.beenUsed]: 'never_been_used_before',
};

type IPasswordChecklist = Record<PASSWORD_ERRORS, boolean>;
interface IPasswordCheckItem {
  translation: string;
  valid: boolean;
}

/**
 * @abstract AbstractChangePasswordComponent
 * @prop { boolean } currentPasswordRequired includes a current password input field
 * @prop { boolean } independentForm includes an independent form with controls, a submit button, and an "every field is required" label
 * @prop { PASSWORD_ERRORS[] } errorsToCheck is a list of errors to check for
 * @prop { string } submitButtonLabel pre-translated string for the submit button. This is also used for the error message which is hardcoded translation template
 */
@Directive()
export abstract class AbstractChangePasswordComponent implements OnInit, OnDestroy {
  private asyncValidation$: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);
  protected formValidationRequest: Subject<void>;
  protected subscriptions: Subscription;
  public formValidationRequest$: Observable<void>;
  public formChangePassword: UntypedFormGroup;
  public errorResponseMessages: Array<string>;
  public alertType: typeof alertType;
  public user: IUserServerResponse;
  public formChecklist$: Observable<IPasswordCheckItem[]>;
  public serverErrorInfoBox: boolean = false;
  public serverErrorInfoBoxReason: string;
  public formErrorInfoBox: boolean = false;
  public formErrorInfoBoxReason: string;
  public Icons: typeof Icons = Icons;
  public IconStyles: typeof IconStyles = IconStyles;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;

  public abstract currentPasswordRequired: boolean;
  // only set if currentPasswordRequired is false
  public emailToken: string = undefined;
  public abstract independentForm: boolean;
  public abstract submitButtonLabel: string;
  public abstract syncErrorsToCheck: PASSWORD_ERRORS[];
  public abstract asyncErrorsToCheck: PASSWORD_ERRORS[];

  protected skipLoader: boolean;

  protected constructor(
    protected userService: UserService,
    protected formBuilder: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected a11yService: A11yService,
    protected formService: FormService,
    protected notificationService: NotificationService,
  ) {
    this.alertType = alertType;
    this.subscriptions = new Subscription();
    this.skipLoader = false;
  }

  private validateHasNeverBeenUsed(control: UntypedFormControl): Observable<ValidationErrors | null> {
    return of(control.value).pipe(
      delay(DEBOUNCE_TIME),
      switchMap((name: string): Observable<boolean> => {
        if (this.currentPasswordRequired) {
          return this.userService.verifyPreviousPassword(name);
        }
        return this.userService.verifyPreviousPasswordVisitor(name, this.emailToken, this.skipLoader);
      }),
      map((verification: boolean): ValidationErrors | null => (verification ? null : { beenUsed: true })),
      tap((): void => {
        setTimeout((): void => {
          this.asyncValidation$.next();
        });
      }),
    );
  }

  protected createForm(): void {
    this.formValidationRequest = new Subject<void>();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();

    const formConfig: Record<string, UntypedFormControl> = {
      ...(this.currentPasswordRequired
        ? {
            [$user.currentPassword]: new UntypedFormControl(null, {
              validators: [Validators.required],
            }),
          }
        : {}),
      [$user.newPassword]: new UntypedFormControl(null, {
        validators: [
          Validators.minLength(minPasswordLength),
          CustomValidators.validateHasUppercase,
          CustomValidators.validateHasLowercase,
          CustomValidators.validateHasNumberOrSpecial,
          Validators.required,
        ],
        asyncValidators: [this.validateHasNeverBeenUsed.bind(this)],
      }),
    };
    this.formChangePassword = this.formBuilder.group(formConfig);
  }

  private closeBothErrorInfoBox(): void {
    this.formErrorInfoBox = false;
    this.serverErrorInfoBox = false;
  }

  protected onSaveUserSettingsSuccess(): void {
    const message: string = this.translateService.instant('success_updating_profile');
    this.closeBothErrorInfoBox();
    this.formChangePassword.reset();
    this.formChangePassword.enable();
    this.formChangePassword.markAsPristine();
    this.asyncValidation$.next();
    this.notificationService.success(message, NotificationPosition.Toast, true);
  }

  protected onSaveUserSettingsError(response: HttpErrorResponse): void {
    this.formChangePassword.enable();

    if (CommonUtility.extractHTTPErrorName(response) === errorMessagesNames.InvalidCurrentPassword) {
      this.serverErrorInfoBoxReason = this.translateService.instant('error_invalid_current_password');
    } else {
      this.serverErrorInfoBoxReason = this.translateService.instant('error_action_cant_be_performed');
    }
    this.serverErrorInfoBox = true;

    console.error('onSaveProfileError', response);
  }

  public isFieldValid(field: string): boolean {
    return this.formService.isFieldValid(field, this.formChangePassword);
  }

  public validatePassword(): boolean {
    if (this.formChangePassword.get($user.newPassword).valid === false || this.formChangePassword.dirty === false) {
      this.formErrorInfoBoxReason = this.translateService.instant(
        `fix_all_the_missing_requirements_and_activate_the_${this.submitButtonLabel}_button`,
      );
      this.formErrorInfoBox = true;
      return false;
    }
    return true;
  }

  public onSubmitPasswordChange(): void {
    this.formValidationRequest.next();

    if (!this.validatePassword()) {
      return;
    }

    this.formChangePassword.disable();

    this.a11yService.setMessage(this.translateService.instant('saving_your_profile_data'));

    this.submit();
  }

  protected submit(): void {
    if (this.independentForm) {
      throw Error('[AbstractChangePasswordComponent] expects `submit` to be defined');
    }
  }

  public closeServerInfoBox(): void {
    this.serverErrorInfoBox = false;
  }

  public closeFormInfoBox(): void {
    this.formErrorInfoBox = false;
  }

  public ngOnInit(): void {
    const syncFormChecklist$: Observable<IPasswordChecklist> = this.formChangePassword.valueChanges.pipe(
      startWith([
        _.pick(
          {
            [PASSWORD_ERRORS.minlength]: true,
            [PASSWORD_ERRORS.noLowercase]: true,
            [PASSWORD_ERRORS.noUppercase]: true,
            [PASSWORD_ERRORS.noNumberOrSpecial]: true,
          },
          this.syncErrorsToCheck,
        ),
      ]),
      map((newValue: UntypedFormControl): IPasswordChecklist => {
        const fieldControl: AbstractControl = this.formChangePassword.controls[$user.newPassword];
        return this.syncErrorsToCheck.reduce(
          (previousValue: Partial<IPasswordChecklist>, currentValue: PASSWORD_ERRORS): Partial<IPasswordChecklist> => {
            return {
              ...previousValue,
              [currentValue]: newValue[$user.newPassword]?.length > 0 ? fieldControl.hasError(currentValue) : true,
            };
          },
          {},
        ) as IPasswordChecklist;
      }),
      AngularUtility.shareRef(),
    );

    // this async observable fetches LatestFrom the sync validators
    // and short circuits to being invalid if any sync validator is false
    const asyncFormChecklist$: Observable<IPasswordChecklist> = this.asyncValidation$.pipe(
      startWith([
        _.pick(
          {
            [PASSWORD_ERRORS.beenUsed]: false,
          },
          this.asyncErrorsToCheck,
        ),
      ]),
      withLatestFrom(syncFormChecklist$),
      map(([__, syncChecklist]: [void, IPasswordChecklist]): IPasswordChecklist => {
        const fieldControl: AbstractControl = this.formChangePassword.controls[$user.newPassword];

        const shortCircuit: boolean = Object.values(syncChecklist).some((v: boolean) => v);

        const isInvalid = (currentValue: PASSWORD_ERRORS): boolean => {
          if (shortCircuit) {
            return true;
          }
          return fieldControl.value?.length > 0 ? fieldControl.hasError(currentValue) : true;
        };

        return this.asyncErrorsToCheck.reduce(
          (previousValue: Partial<IPasswordChecklist>, currentValue: PASSWORD_ERRORS): Partial<IPasswordChecklist> => {
            return {
              ...previousValue,
              [currentValue]: isInvalid(currentValue),
            };
          },
          {},
        ) as IPasswordChecklist;
      }),
    );

    this.formChecklist$ = combineLatest([syncFormChecklist$, asyncFormChecklist$]).pipe(
      map(([syncPasswordChecklist, asyncPasswordChecklist]: [IPasswordChecklist, IPasswordChecklist]): IPasswordCheckItem[] => {
        const passwordChecklist: IPasswordChecklist = {
          ...syncPasswordChecklist,
          ...asyncPasswordChecklist,
        };
        return Object.keys(passwordChecklist).map((errorKey: PASSWORD_ERRORS): IPasswordCheckItem => {
          return {
            translation: this.translateService.instant(mapPasswordErrorToTranslation[errorKey], minPasswordLength),
            valid: !passwordChecklist[errorKey],
          };
        });
      }),
      AngularUtility.shareRef(),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
