import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  public confirm(message: string): boolean {
    // eslint-disable-next-line no-alert
    return window.confirm(message);
  }

  /**
   * Uses the window object to create a MediaQueryList whose query is `(max-width: ${maxWidth}px)`. Then observes its
   * "change" event and maps the resulting observable by returning the "matches" property of the MediaQueryList.
   *
   * @param maxWidth Max width to be used in the media query added to the window object.
   */
  public observeBreakpoint(maxWidth: number): Observable<boolean> {
    const mediaQuery: MediaQueryList = window.matchMedia(`(max-width: ${maxWidth}px)`);

    return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map((mediaQueryList: MediaQueryList): boolean => mediaQueryList.matches),
    );
  }
}
