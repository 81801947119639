import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { RestService } from './rest.service';
import { AppConfigService } from './app-config.service';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

export enum TestAutomationTools {
  'cucumberjs' = 'cucumberjs',
  'cucumberjvm' = 'cucumberjvm',
  'jest' = 'jest',
  'mocha' = 'mocha',
  'cypress' = 'cypress',
  'webdriverio' = 'webdriverio',
  'extension_chrome_labe' = 'labe-browser-extension',
  'extension_chrome_accessibility_checker' = 'accessibility-checker-browser-extension',
  'extension_firefox' = 'ff-browser-extension',
  'figma' = 'figma',
  'dca' = 'dca',
}

@Injectable({ providedIn: 'root' })
export class TestAutomationService {
  constructor(
    private restService: RestService,
    private configService: AppConfigService,
    private httpClient: HttpClient,
  ) {}

  public download(toolId: TestAutomationTools): Observable<void> {
    return this.getToolUrl(toolId).pipe(
      switchMap((toolUrl: string): Observable<void> => {
        return this.restService.download(toolUrl);
      }),
    );
  }

  public getToolUrl(toolId: TestAutomationTools): Observable<string> {
    const downloadToolsUrl: string = this.configService.getDownloadToolsUrl();
    const downloadToolsIndexPath: string = this.configService.getDownloadToolsIndexPath();

    if (toolId === TestAutomationTools.dca) {
      const downloadToolsDcaPath: string = this.configService.getDownloadToolsDcaPath();
      if (SharedCommonUtility.isNullish(downloadToolsDcaPath)) {
        throw new Error('Unknown tool path for tool: ' + toolId);
      }

      return of(`${downloadToolsUrl}${downloadToolsDcaPath}`);
    }

    return this.httpClient.get<Record<TestAutomationTools, string>>(`${downloadToolsUrl}/${downloadToolsIndexPath}`).pipe(
      map((response: Record<TestAutomationTools, string>): string => {
        const toolPath: string = response[toolId];

        if (SharedCommonUtility.isNullishOrEmpty(toolPath)) {
          throw new Error('Unknown tool path for tool: ' + toolId);
        }
        return `${downloadToolsUrl}/${toolPath}`;
      }),
    );
  }
}
