import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-info-note',
  templateUrl: './common-info-note.component.html',
  styleUrls: ['./common-info-note.component.scss'],
})
export class CommonInfoNoteComponent {
  @Input()
  public noteText: string;
}
