import { ICustomSeverity } from '../interfaces/tenant.interface';
import { $severity } from './accessibility';

export const subdomainPattern: RegExp = new RegExp(/^[a-z]([a-z]|[0-9]|-)*$/);

export enum $tenant {
  _id = '_id',
  academyConfig = 'academyConfig',
  cloudsmithToken = 'cloudsmithToken',
  createdAt = 'createdAt',
  description = 'description',
  digitalPropertyAutomatedFlowLimit = 'digitalPropertyAutomatedFlowLimit',
  digitalPropertyLimit = 'digitalPropertyLimit',
  domain = 'domain',
  maxPdfTests = 'maxPdfTests',
  monitoringPagesPerDigitalPropertyLimit = 'monitoringPagesPerDigitalPropertyLimit',
  name = 'name',
  pdfPagesTestLimit = 'pdfPagesTestLimit',
  salesforceAccount = 'salesforceAccount',
  salesforceAccountId = 'salesforceAccountId',
  salesforceAccountName = 'salesforceAccountName',
  scimEnabled = 'scimEnabled',
  oldMonitoringPagesPerDigitalPropertyLimit = 'oldMonitoringPagesPerDigitalPropertyLimit',
  userWayWebsiteLimit = 'userWayWebsiteLimit',
  ssoConfig = 'ssoConfig',
  status = 'status',
  usersCount = 'usersCount',
  userWayOrganizationId = 'userWayOrganizationId',
  userWayOrganizationState = 'userWayOrganizationState',
  userWayOrganizationAccountCode = 'userWayOrganizationAccountCode',
  customSeverities = 'customSeverities',
}

export enum $tenantMetadata {
  packageId = 'packageId',
  packageData = 'packageData',
  packageAddonIds = 'packageAddonIds',
}

export enum $tenants {
  domains = 'domains',
  tenants = 'tenants',
  packages = 'packages',
  _total = '_total',
}

export enum TenantStatus {
  active = 'active',
  archived = 'archived',
}

export enum $tenantUser {
  userId = 'userId',
  tenantId = 'tenantId',
  integrations = 'integrations',
  user = 'user',
}

export enum $tenantUserIntegrations {
  academy = 'academy',
}

export enum $tenantSsoHistory {
  _id = '_id',
  tenantId = 'tenantId',
  userId = 'userId',
  action = 'action',
  performedAt = 'performedAt',
  reason = 'reason',
}

export enum TenantSsoAction {
  SET = 'SET',
  REMOVE = 'REMOVE',
}

export enum $customSeverity {
  label = 'label',
  description = 'description',
  weight = 'weight',
  rank = 'rank',
  system = 'system',
}

export const CHAR_LIMITS: Readonly<Record<string, number>> = {
  [$tenant.name]: 100,
  [$tenant.description]: 100,
  [$tenant.domain]: 40,
};
export const SSO_REMOVAL_REASON_CHAR_LIMIT: number = 200;
export const MONITORING_PAGES_PER_DP_LIMIT_MIN: number = 1;

export const SeverityRankWeightMap: Map<number, number> = new Map([
  [5, 10],
  [4, 6],
  [3, 5],
  [2, 3],
  [1, 2],
]);

export const DefaultTenantSeverities: Partial<Record<$severity, ICustomSeverity>> = {
  [$severity.critical]: {
    [$customSeverity.label]: 'Critical',
    [$customSeverity.description]:
      'An issue that prevents users with disabilities from accessing essential content or functionality, rendering a part or the entire application unusable for them.',
    [$customSeverity.weight]: SeverityRankWeightMap.get(5),
    [$customSeverity.rank]: 5,
    [$customSeverity.system]: true,
  },
  [$severity.high]: {
    [$customSeverity.label]: 'High',
    [$customSeverity.description]:
      'An issue that significantly impairs the ability of users with disabilities to access key content or functionality, causing major difficulties but not completely blocking access.',
    [$customSeverity.weight]: SeverityRankWeightMap.get(4),
    [$customSeverity.rank]: 4,
    [$customSeverity.system]: true,
  },
  [$severity.low]: {
    [$customSeverity.label]: 'Low',
    [$customSeverity.description]:
      'An issue that causes minor inconveniences or visual inconsistencies for users with disabilities but does not significantly impede their ability to access content or functionality.',
    [$customSeverity.weight]: SeverityRankWeightMap.get(1),
    [$customSeverity.rank]: 1,
    [$customSeverity.system]: true,
  },
};

/*
export const DefaultTenantSeverities: Map<$severity, ICustomSeverity> = {
  [$severity.critical]: {
    [$customSeverity.label]: 'Critical',
    [$customSeverity.description]:
      'An issue that prevents users with disabilities from accessing essential content or functionality, rendering a part or the entire application unusable for them.',
    [$customSeverity.weight]: 10,
    [$customSeverity.rank]: 5,
    [$customSeverity.system]: true,
  },
  [$severity.high]: {
    [$customSeverity.label]: 'High',
    [$customSeverity.description]:
      'An issue that significantly impairs the ability of users with disabilities to access key content or functionality, causing major difficulties but not completely blocking access.',
    [$customSeverity.weight]: 6,
    [$customSeverity.rank]: 4,
    [$customSeverity.system]: true,
  },
  [$severity.low]: {
    [$customSeverity.label]: 'Low',
    [$customSeverity.description]:
      'An issue that causes minor inconveniences or visual inconsistencies for users with disabilities but does not significantly impede their ability to access content or functionality.',
    [$customSeverity.weight]: 2,
    [$customSeverity.rank]: 1,
    [$customSeverity.system]: true,
  },
};
*/

export const CUSTOM_SEVERITY_MAX_CHAR_COUNT: Readonly<Partial<Record<$customSeverity, number>>> = {
  [$customSeverity.label]: 15,
  [$customSeverity.description]: 280,
};

// Only allows alphanumeric characters, &, -, _ and spaces
export const CUSTOM_SEVERITY_LABEL_ALLOWED_CHARS: RegExp = /^[\w\s&\-_]+$/;
