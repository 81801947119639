export enum $flaw {
  _id = '_id',
  issueId = 'issueId',
  flawPageId = 'flawPageId',
  ruleId = 'ruleId',
  toolId = 'toolId',
  firstFound = 'firstFound',
  lastFound = 'lastFound',
  data = 'data',
  embeddings = 'embeddings',
  status = 'status',
  statusHistory = 'statusHistory',
  taskId = 'taskId',
  viewed = 'viewed',
  tenantId = 'tenantId',
}

export enum $flawPage {
  _id = '_id',
  digitalPropertyId = 'digitalPropertyId',
  url = 'url',
  urlHash = 'urlHash',
  firstScan = 'firstScan',
  lastScan = 'lastScan',
  lastMonitoredScan = 'lastMonitoredScan',
  date = 'date',
  scanId = 'scanId',
  flawsCount = 'flawsCount',
  selected = 'selected',
  title = 'title',
  important = 'important',
  pageId = 'pageId',
}

export enum flawStatus {
  closed = 'closed',
  dismissed = 'dismissed',
  open = 'open',
  reOpen = 'reOpen',
}

export enum changedByType {
  user = 'user',
  scan = 'scan',
}

export enum $flawCreationMetadata {
  idPrefix = 'idPrefix',
  tenantId = 'tenantId',
  digitalPropertyId = 'digitalPropertyId',
  flawPageId = 'flawPageId',
  toolId = 'toolId',
  scanId = 'scanId',
  date = 'date',
}

export enum $flawEmbeddings {
  flawEmbeddingV1 = 'flawEmbeddingV1',
}

export enum $flawDataCreateRequest {
  ruleId = 'ruleId',
  data = 'data',
  embeddings = 'embeddings',
}

export enum $flawDataUpdateRequest {
  tenantId = 'tenantId',
  flawId = 'flawId',
  data = 'data',
  existingFlawData = 'existingFlawData',
  embeddings = 'embeddings',
}

export const linkableIssueStatus: ReadonlyArray<flawStatus> = Object.freeze([flawStatus.open, flawStatus.reOpen]);
