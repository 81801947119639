import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

import { StatusService } from './status.service';
import { TranslateService } from '../translate/translate.service';
import { CommonUtility } from '../utility/common.utility';
import { IMenuItem } from '../interfaces/menu.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private featuresConfig: any;

  constructor(
    private httpClient: HttpClient,
    private statusService: StatusService,
    private translateService: TranslateService,
  ) {
    this.featuresConfig = null;
  }

  private translateMenuItems(items: IMenuItem): IMenuItem {
    for (const item in items) {
      if (items.hasOwnProperty(item) && typeof items[item] === 'object') {
        this.translateMenuItems(items[item]);
      } else if (item === 'name') {
        items[item] = this.translateService.instant(items[item]);
      } else if (item === 'id') {
        items[item] = CommonUtility.createUniqueDOMId(items[item]);
      }
    }

    return items;
  }

  public async fetchFeaturesConfig(): Promise<void> {
    const resolveFeatures = (resolve: any, reject: any): void => {
      const saveFeaturesConfig = (featuresConfig: any): void => {
        this.featuresConfig = featuresConfig;
      };

      const translateMenuItems = (): void => {
        this.setFeaturesConfig(this.translateMenuItems(this.featuresConfig));
      };

      const checkTranslations = (): void => {
        if (this.statusService.getAreTranslationsReady() === false || this.featuresConfig === null) {
          return;
        }

        areTranslationsReadySubscription.unsubscribe();
        translateMenuItems();
        resolve();
      };

      const areTranslationsReadySubscription: Subscription = this.statusService.$areTranslationsReady.pipe(skip(1)).subscribe({
        next: checkTranslations,
      });

      const httpParams: HttpParams = new HttpParams({
        fromString: `c=${SharedCommonUtility.getRandomInsecureString()}`,
      });

      this.httpClient
        .get('environments/features.config.json', { params: httpParams })
        .toPromise()
        .then(saveFeaturesConfig)
        .then(checkTranslations)
        .catch(reject);
    };

    return new Promise(resolveFeatures);
  }

  public getFeaturesConfig(): any {
    return this.featuresConfig;
  }

  public setFeaturesConfig(featuresConfig: any): void {
    this.featuresConfig = featuresConfig;
  }

  public removeFeaturesConfig(): void {
    this.featuresConfig = null;
  }
}
