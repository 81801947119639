import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { has, isEqual, sortBy } from 'lodash';

import { CommonUtility } from '../../../../utility/common.utility';
import { IUploadClient } from '../../../../../../shared/interfaces/uploads.interface';
import { NotificationPosition } from '../../../../models/notification.model';
import { TranslateService } from '../../../../translate/translate.service';
import { NotificationService } from '../../../../services/notification.service';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { CustomValidators } from '../../../../services/helpers/form-custom-validators';
import {
  IDclConfig,
  ITableColumn,
  ITableConfig,
  ITableRow,
  SortEvent,
} from '../../../table/ngb-table/utilities/ngb-table.interface';
import { $uploads } from '../../../../../../shared/constants/uploads';
import { SharedDateUtility } from '../../../../../../shared/utils/date.utility';
import { NgbTableUtilities } from '../../../table/ngb-table/utilities/ngb-table.utilities';
import { $sortingOrder } from '../../../../../../shared/constants/sort';
import { ICellConfig } from '../../../table/ngb-table/cells/base-cell/base-cell.component';

enum TableColumn {
  Name = 'Name',
  DateAdded = 'DateAdded',
  FileSize = 'FileSize',
  Action = 'Action',
}

export interface IFileDragAndDropContext {
  /**
   * Translated form field label.
   */
  label?: string;
  field: string;
  acceptedFileExtensions: string[];
  /**
   * Required if form control validation is desired.
   */
  acceptedMimeTypes?: string[];
  /**
   * Max size per file.
   */
  maxFileSize: number;
  /**
   * Max size across all files. If not provided then defaults to maxFileSize * maxTotalFileQuantity.
   */
  maxTotalFileSize?: number;
  /**
   * Maximum number of allowed files. Defaults to 1. Existing uploads are included in the limit.
   */
  maxTotalFileQuantity?: number;
  /**
   * Defaults to true.
   */
  required?: boolean;
  /**
   * Custom error message for form error.
   */
  customMessage?: string;
  /**
   * Translated title for file table. If provided then a table will be displayed listing uploaded files and attachments.
   * Table is also displayed if removableFiles is set to true.
   */
  tableTitle?: string;
  /**
   * Used to override error messages in the form field error.
   */
  overrideErrors?: Record<string, string>;
}

function isUpload(attachment: File | IUploadClient): attachment is IUploadClient {
  return has(attachment, $uploads.fileName);
}

@Component({
  selector: 'app-file-drag-and-drop',
  templateUrl: './file-drag-and-drop.component.html',
  styleUrls: ['./file-drag-and-drop.component.scss'],
})
export class FileDragAndDropComponent implements OnInit, OnDestroy, OnChanges {
  private subscription: Subscription = new Subscription();
  private originalTableData: ITableRow<File | IUploadClient>[] = [];

  public id: string;
  public dragActive: boolean = false;
  public _files: File[] = [];
  public tableConfig: ITableConfig;
  public tableData: ITableRow<File | IUploadClient>[] = [];

  public set files(_files: File[]) {
    this._files = _files;
    this.updateInputFiles();
  }

  public get files(): File[] {
    return this._files;
  }

  @ViewChild('nativeInput')
  public nativeInput: ElementRef<HTMLInputElement>;

  @Input()
  public context: IFileDragAndDropContext;
  @Input()
  public removableFiles: boolean = true;
  @Input()
  public existingUploads: IUploadClient[] = [];
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public formValidationRequest$: Subject<void> = new Subject();
  @Input()
  public disabled: boolean = false;
  @Input()
  public showDateAdded: boolean = true;
  @Input()
  public ariaLabel: string = 'select_or_drag_and_drop_files';

  @Output()
  public existingUploadsChange: EventEmitter<IUploadClient[]> = new EventEmitter<IUploadClient[]>();
  @Output()
  public fileChange: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output()
  public attachmentDeleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {}

  /**
   * Notifies user if number of attached files exceeds allowed quantity.
   *
   * @param files Files attached via input[type="file"]
   * @param warningMessage
   * @private
   */
  private validateFileQuantity(files: File[], warningMessage: string): void {
    if (this.files.length + files.length > this.maxFileQuantity) {
      const exceedsLimitMessage: string = this.translateService.instant(
        'form-validation-attachment-quantity-exceeds-limit',
        this.maxFileQuantity,
      );
      this.notificationService.warn(`${warningMessage}: ${exceedsLimitMessage}`, NotificationPosition.Toast);
    }
  }

  private validateIndividualFileSize(files: File[], warningMessage: string): File[] {
    const filesBelowSizeReq: File[] = files.filter((file: File) => file.size <= this.context.maxFileSize);
    if (filesBelowSizeReq.length !== files.length) {
      const exceedsLimitMessage: string = this.translateService.instant(
        'form-validation-files-that-exceeds-limit-were-not-attached',
        this.getFormattedFileSize(this.context.maxFileSize),
      );
      this.notificationService.warn(`${warningMessage}: ${exceedsLimitMessage}`, NotificationPosition.Toast);
    }

    return filesBelowSizeReq;
  }

  private get canValidateFormControl(): boolean {
    return (
      !SharedCommonUtility.isNullishOrEmpty(this.context.acceptedFileExtensions) &&
      !SharedCommonUtility.isNullishOrEmpty(this.context.acceptedMimeTypes)
    );
  }

  private validateFormControl(): boolean {
    if (!this.canValidateFormControl) {
      return true;
    }

    const control: AbstractControl = this.form.get(this.context.field);

    control.setValidators([
      CustomValidators.validateAttachmentFileType(
        this.files,
        this.context.acceptedMimeTypes,
        this.context.acceptedFileExtensions,
      ),
      CustomValidators.validateAttachmentTotalSize([...this.existingUploads, ...this.files], this.maxTotalFileSize),
    ]);

    control.updateValueAndValidity();
    this.formValidationRequest$.next();

    return control.valid;
  }

  private createTableConfig(): void {
    const columns: Record<string, ITableColumn> = {
      [TableColumn.Name]: {
        translationKey: 'table_column_name',
        sortingEnabled: true,
        styles: {
          maxWidth: '50%',
          width: '50%',
        },
      },
      [TableColumn.DateAdded]: {
        translationKey: 'table_column_date_added',
        sortingEnabled: true,
        styles: {
          maxWidth: '20%',
          width: '20%',
        },
      },
      [TableColumn.FileSize]: {
        translationKey: 'table_column_file_size',
        sortingEnabled: true,
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      },
    };

    if (!this.showDateAdded) {
      delete columns[TableColumn.DateAdded];
    }

    if (this.removableFiles) {
      columns[TableColumn.Action] = {
        translationKey: 'table_header_action',
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      };
    }

    this.tableConfig = {
      columns: columns,
      caption: this.translateService.instant('selected_files_to_upload'),
    };
  }

  private toTableRow(entry: File | IUploadClient): ITableRow<File | IUploadClient> {
    let name: string;
    let dateAdded: Date;
    let fileSize: number;

    if (isUpload(entry)) {
      name = entry[$uploads.fileOriginalName];
      dateAdded = new Date(entry[$uploads.createdAt]);
      fileSize = entry[$uploads.fileSize];
    } else {
      name = entry.name;
      dateAdded = new Date();
      fileSize = entry.size;
    }

    const rowData: Record<string, IDclConfig<ICellConfig>> = {
      [TableColumn.Name]: NgbTableUtilities.textCell({
        text: name,
      }),
      [TableColumn.DateAdded]: NgbTableUtilities.textCell({
        text: SharedDateUtility.getLocalISODate(dateAdded),
      }),
      [TableColumn.FileSize]: NgbTableUtilities.textCell({
        text: this.getFormattedFileSize(fileSize),
      }),
    };

    if (!this.showDateAdded) {
      delete rowData[TableColumn.DateAdded];
    }

    if (this.removableFiles) {
      rowData[TableColumn.Action] = NgbTableUtilities.buttonCell({
        text: this.translateService.instant('action_delete'),
        classes: ['btn-link', 'font-weight-500', 'py-0'],
        listeners: {
          click: this.removeEntry.bind(this, entry),
        },
      });
    }

    return { data: rowData, originalData: entry };
  }

  private generateTableData(): void {
    this.originalTableData = [...this.existingUploads, ...this.files].map(this.toTableRow.bind(this));
    this.tableData = [...this.originalTableData];
  }

  public removeEntry(entry: File | IUploadClient): void {
    if (isUpload(entry)) {
      this.existingUploads = this.existingUploads.filter((upload: IUploadClient): boolean => !isEqual(upload, entry));
      this.existingUploadsChange.emit(this.existingUploads);
    } else {
      this.files = this.files.filter((file: File): boolean => !isEqual(file, entry));
      this.fileChange.emit(this.files);
      this.attachmentDeleted.emit();
    }

    this.tableData = this.tableData.filter((row: ITableRow): boolean => !isEqual(row.originalData, entry));

    this.validateFormControl();
  }

  private getComparable = (column: string, rawData: File | IUploadClient): string | number => {
    switch (column) {
      case TableColumn.Name:
        return isUpload(rawData) ? rawData[$uploads.fileOriginalName] : rawData.name;
      case TableColumn.FileSize:
        return isUpload(rawData) ? rawData[$uploads.fileSize] : rawData.size;
      case TableColumn.DateAdded:
        return SharedDateUtility.getLocalISODate(isUpload(rawData) ? rawData[$uploads.createdAt] : new Date());
      default:
        return '';
    }
  };

  private sort(data: (File | IUploadClient)[], { column, direction }: SortEvent): (File | IUploadClient)[] {
    const sortFn = (entry: File | IUploadClient): string | number => this.getComparable(column, entry);
    const sortedData: (File | IUploadClient)[] = sortBy(data, sortFn);
    if (direction === $sortingOrder.asc) {
      return sortedData;
    }
    return sortedData.reverse();
  }

  /**
   * Syncs input.files with this.files
   * Without it browser doesn't allow to upload the same file
   * after it was remowed.
   */
  private updateInputFiles(): void {
    const dataTransfer: DataTransfer = new DataTransfer();
    for (const file of this._files) {
      dataTransfer.items.add(file);
    }
    if (this?.nativeInput?.nativeElement) {
      this.nativeInput.nativeElement.files = dataTransfer.files;
    }
  }

  /**
   * Max file quantity after existing uploads have been factored in.
   */
  public get maxFileQuantity(): number {
    if (SharedCommonUtility.notNullish(this.context.maxTotalFileQuantity)) {
      return Math.max(this.context.maxTotalFileQuantity - this.existingUploads.length, 0);
    }

    return Math.max(1 - this.existingUploads.length, 0);
  }

  public get maxTotalFileQuantity(): number {
    return this.context.maxTotalFileQuantity ?? 1;
  }

  public get acceptedExtensions(): string {
    return this.context.acceptedFileExtensions.join(',');
  }

  public get displayTable(): boolean {
    return this.removableFiles || !SharedCommonUtility.isNullishOrEmpty(this.context.tableTitle);
  }

  public get multiple(): boolean {
    return this.maxFileQuantity > 1;
  }

  public get uploadDisabled(): boolean {
    return (
      this.files.length + this.existingUploads.length === this.maxTotalFileQuantity ||
      this.maxTotalFileSizeExceeded ||
      this.disabled
    );
  }

  public get required(): boolean {
    return this.context.required ?? true;
  }

  public get maxTotalFileSize(): number {
    return this.context.maxTotalFileSize ?? this.context.maxFileSize * this.maxTotalFileQuantity;
  }

  public get totalFileSize(): number {
    const totalExistingUploadSize: number = this.existingUploads
      .map((value: IUploadClient): number => value.fileSize)
      .reduce((acc: number, curr: number): number => acc + curr, 0);

    const totalNewFileSize: number = this.files
      .map((file: File) => file.size)
      .reduce((acc: number, curr: number): number => acc + curr, 0);

    return totalExistingUploadSize + totalNewFileSize;
  }

  public get maxTotalFileSizeExceeded(): boolean {
    return this.totalFileSize >= this.maxTotalFileSize;
  }

  public get errorHighlight(): boolean {
    const field: AbstractControl = this.form.get(this.context.field);
    return field.invalid && field.touched && !field.pristine;
  }

  public getFormattedFileSize(size: number): string {
    return SharedCommonUtility.formatBytes(size);
  }

  public sortTable({ column, direction }: SortEvent): void {
    if (direction === $sortingOrder.all) {
      this.tableData = [...this.originalTableData];
    } else {
      const originalData: (File | IUploadClient)[] = this.originalTableData.map(
        (row: ITableRow<File | IUploadClient>) => row.originalData,
      );
      const sortedData: (File | IUploadClient)[] = this.sort(originalData, { column, direction });
      this.tableData = sortedData.map(this.toTableRow.bind(this));
    }
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.dragActive = true;
  }

  public onDragLeave(event: Event): void {
    event.preventDefault();
    this.dragActive = false;
  }

  /**
   * Handles file uploads via drag and drop.
   *
   * @param event
   */
  public onDragDrop(event: DragEvent): void {
    event.preventDefault();
    this.dragActive = false;
    this.onFileChange(Array.from(event.dataTransfer.files));
  }

  /**
   * Handles direct file uploads via clicking and selecting a file.
   *
   * @param event
   */
  public onChange(event: Event): void {
    event.preventDefault();
    const element: HTMLInputElement = event.currentTarget as HTMLInputElement;
    this.onFileChange(Array.from(element.files));
  }

  /**
   * Handles validations and displaying file table if applicable.
   *
   * @param files
   */
  public onFileChange(files: File[]): void {
    const numFilesBefore: number = this.files.length;

    const warningMessage: string = this.translateService.instant('warning');
    this.validateFileQuantity(files, warningMessage);

    const filesBeforeChange: File[] = this.files;
    const filesBelowSizeReq: File[] = this.validateIndividualFileSize(files, warningMessage);
    this.files = this.files.concat(...filesBelowSizeReq).slice(0, this.maxFileQuantity);

    if (
      SharedCommonUtility.notNullish(this.form) &&
      !SharedCommonUtility.isNullishOrEmpty(this.context.field) &&
      !this.validateFormControl()
    ) {
      this.files = filesBeforeChange;
      return;
    }

    if (this.displayTable) {
      this.generateTableData();
    }

    if (this.files.length > numFilesBefore) {
      this.fileChange.emit(this.files);
    }
  }

  public ngOnInit(): void {
    this.id = CommonUtility.createUniqueDOMId(this.context.field);

    this.subscription.add(
      this.formValidationRequest$.subscribe(() => {
        const control: AbstractControl = this.form.get(this.context.field);
        control?.markAsDirty();
        control?.markAsTouched();
      }),
    );

    if (this.displayTable) {
      this.createTableConfig();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      SharedCommonUtility.notNullish(changes.existingUploads) &&
      SharedCommonUtility.notNullishOrEmpty(changes.existingUploads.currentValue) &&
      !isEqual(changes.existingUploads.currentValue, changes.existingUploads.previousValue)
    ) {
      this.generateTableData();
      this.validateFormControl();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
