import { Component, Input } from '@angular/core';

import { IAuditRuleWithLibraryName, ICustomAuditRuleWithLibraryName } from '../audit-rule-view/audit-rule-view.component';
import { IAuditStandard } from '../../../../../shared/interfaces/audit-rule.interface';
import { CriteriaPreset, Separator, SuccessCriteriaFormatterService } from '../../../services/success-criteria-formatter.service';
import { AuditStandards } from '../../../../../shared/constants/audit-standard';
import { $successCriteria } from '../../../../../shared/audits/definitions/success-criteria/constants';

@Component({
  selector: 'app-audit-delete-rule-modal-content',
  templateUrl: './audit-delete-rule-modal-content.component.html',
})
export class AuditDeleteRuleModalContentComponent {
  @Input() public rule: ICustomAuditRuleWithLibraryName | IAuditRuleWithLibraryName;

  constructor(private successCriteriaFormatterService: SuccessCriteriaFormatterService) {}

  public displayWCAGOrNACriteria(standard: IAuditStandard): string {
    return this.successCriteriaFormatterService.toDisplayCriterias({
      identifiers: [
        standard?.[AuditStandards.wcag]?.[$successCriteria.num],
        standard?.[AuditStandards.notApplicable]?.[$successCriteria.num],
      ],
      separator: Separator.newLine,
      preset: CriteriaPreset.criteriaIdentifierWithLevel,
    });
  }
}
