import { IHighlight, IImageScale, IImageSize, IPoint } from '../../interfaces/image-highlight.interface';
import {
  DEFAULT_HIGHLIGHT_HEIGHT,
  DEFAULT_HIGHLIGHT_MARGIN,
  DEFAULT_HIGHLIGHT_POSITION,
  DEFAULT_HIGHLIGHT_WIDTH,
  MIN_HIGHLIGHT_SIZE,
} from '../../constants/image-highlight.constants';
import { IBoundingBox } from '../../../../shared/interfaces/design-review.interface';
import { $boundingBox } from '../../../../shared/constants/design-review';

export class HighlightImgHelper {
  constructor(private img: HTMLImageElement) {}

  private addMarginToPoint(mousePos: IPoint, imgBoundingBox: IBoundingBox, margin: number = DEFAULT_HIGHLIGHT_MARGIN): IPoint {
    const neededMarginX: number = mousePos.x - imgBoundingBox.x < margin ? margin - (mousePos.x - imgBoundingBox.x) : 0;
    const neededMarginY: number = mousePos.y - imgBoundingBox.y < margin ? margin - (mousePos.y - imgBoundingBox.y) : 0;

    return {
      x: mousePos.x + neededMarginX,
      y: mousePos.y + neededMarginY,
    };
  }

  private pointIntersectsBox(point: IPoint, box: IBoundingBox): boolean {
    const xIntersects: boolean = box.x <= point.x && point.x <= box.x + box.width;
    const yIntersects: boolean = box.y <= point.y && point.y <= box.y + box.height;
    return xIntersects && yIntersects;
  }

  private imgCanFitBox(
    mousePos: IPoint,
    imgBoundingBox: IBoundingBox,
    desiredWidth: number = DEFAULT_HIGHLIGHT_WIDTH,
    desiredHeight: number = DEFAULT_HIGHLIGHT_HEIGHT,
    margin: number = DEFAULT_HIGHLIGHT_MARGIN,
  ): boolean {
    const canFitWidth: boolean = mousePos.x + desiredWidth <= imgBoundingBox.x + imgBoundingBox.width - margin;
    const canFitHeight: boolean = mousePos.y + desiredHeight <= imgBoundingBox.y + imgBoundingBox.height - margin;
    return canFitWidth && canFitHeight && this.pointIntersectsBox(mousePos, imgBoundingBox);
  }

  private adjustPosToFitBox(
    mousePos: IPoint,
    imgBoundingBox: IBoundingBox,
    desiredWidth: number = DEFAULT_HIGHLIGHT_WIDTH,
    desiredHeight: number = DEFAULT_HIGHLIGHT_HEIGHT,
    margin: number = DEFAULT_HIGHLIGHT_MARGIN,
  ): IPoint {
    const availableSpaceXAxis: number = imgBoundingBox.x + imgBoundingBox.width - margin - mousePos.x;
    const availableSpaceYAxis: number = imgBoundingBox.y + imgBoundingBox.height - margin - mousePos.y;
    return {
      x: availableSpaceXAxis < desiredWidth ? mousePos.x - (desiredWidth - availableSpaceXAxis) : mousePos.x,
      y: availableSpaceYAxis < desiredHeight ? mousePos.y - (desiredHeight - availableSpaceYAxis) : mousePos.y,
    };
  }

  public getImageBoundingBox(): IBoundingBox {
    const imgBoundingRect: DOMRect = this.img.getBoundingClientRect();
    const bodyBoundingRect: DOMRect = document.body.getBoundingClientRect();
    return {
      [$boundingBox.x]: imgBoundingRect.x,
      [$boundingBox.y]: imgBoundingRect.y - bodyBoundingRect.y, // get Y pos relative to top of page
      [$boundingBox.width]: imgBoundingRect.width,
      [$boundingBox.height]: imgBoundingRect.height,
    };
  }

  public getImageSize(): IImageSize {
    const boundingBox: DOMRect = this.img.getBoundingClientRect();

    return {
      original: {
        width: this.img.naturalWidth,
        height: this.img.naturalHeight,
      },
      actual: {
        width: boundingBox.width,
        height: boundingBox.height,
      },
    };
  }

  public getImageScale(): IImageScale {
    const imgSize: IImageSize = this.getImageSize();
    return this.computeCoefficients(imgSize);
  }

  public computeCoefficients(imgSize: IImageSize): IImageScale {
    return {
      heightCoefficient: imgSize.actual.height / imgSize.original.height,
      widthCoefficient: imgSize.actual.width / imgSize.original.width,
    };
  }

  public isBoxValid(box: IBoundingBox): boolean {
    const imgSize: IImageSize = this.getImageSize();

    const isValidSize: boolean = box.width >= MIN_HIGHLIGHT_SIZE && box.height >= MIN_HIGHLIGHT_SIZE;

    const isInsideImageBounds: boolean =
      box.x >= 0 && box.x + box.width <= imgSize.actual.width && box.y >= 0 && box.y + box.height <= imgSize.actual.height;

    return isValidSize && isInsideImageBounds;
  }

  public computePossibleBoundingBoxFromPoint(mousePos: IPoint): IBoundingBox {
    const box: IBoundingBox = {
      x: DEFAULT_HIGHLIGHT_POSITION.x,
      y: DEFAULT_HIGHLIGHT_POSITION.y,
      width: DEFAULT_HIGHLIGHT_WIDTH,
      height: DEFAULT_HIGHLIGHT_HEIGHT,
    };
    const imgBoundingBox: IBoundingBox = this.getImageBoundingBox();

    if (!this.pointIntersectsBox(mousePos, imgBoundingBox)) {
      return box;
    }

    let candidatePoint: IPoint = this.addMarginToPoint(mousePos, imgBoundingBox);
    if (this.imgCanFitBox(candidatePoint, imgBoundingBox)) {
      box.x = candidatePoint.x - imgBoundingBox.x;
      box.y = candidatePoint.y - imgBoundingBox.y;
    } else {
      candidatePoint = this.adjustPosToFitBox(candidatePoint, imgBoundingBox);

      if (candidatePoint.x - imgBoundingBox.x < 0) {
        candidatePoint.x = DEFAULT_HIGHLIGHT_POSITION.x;
      }

      if (candidatePoint.y - imgBoundingBox.y < 0) {
        candidatePoint.y = DEFAULT_HIGHLIGHT_POSITION.y;
      }

      candidatePoint = this.addMarginToPoint(candidatePoint, imgBoundingBox);
      box.x = candidatePoint.x - imgBoundingBox.x;
      box.y = candidatePoint.y - imgBoundingBox.y;

      if (candidatePoint.x + DEFAULT_HIGHLIGHT_WIDTH >= imgBoundingBox.x + imgBoundingBox.width) {
        box.width = imgBoundingBox.width - 2 * DEFAULT_HIGHLIGHT_MARGIN;
      }

      if (candidatePoint.y + DEFAULT_HIGHLIGHT_HEIGHT >= imgBoundingBox.y + imgBoundingBox.height) {
        box.height = imgBoundingBox.height - 2 * DEFAULT_HIGHLIGHT_MARGIN;
      }
    }

    return box;
  }

  public getAllowedCoordinates({ point, highlight }: { point: IPoint; highlight: IHighlight<any> }): IPoint {
    const offsetRight: number = this.getImageSize().actual.width - (point.x + highlight.width);
    const offsetBottom: number = this.getImageSize().actual.height - (point.y + highlight.height);
    const safeLimitRight: number = this.getImageSize().actual.width - highlight.width;
    const safeLimitBottom: number = this.getImageSize().actual.height - highlight.height;

    if (point.x < 0) {
      point.x = 0;
    }

    if (offsetRight < 0) {
      point.x = safeLimitRight;
    }

    if (offsetBottom < 0) {
      point.y = safeLimitBottom;
    }

    if (point.y < 0) {
      point.y = 0;
    }

    return point;
  }

  public getMouseAllowedCoordinates(
    currentMousePoint: IPoint,
    boxRelativeDistanceToMouseInitialPosition: IPoint,
    selectedBox: IHighlight<any>,
  ): IPoint {
    const point: IPoint = {
      x: currentMousePoint.x - boxRelativeDistanceToMouseInitialPosition.x,
      y: currentMousePoint.y - boxRelativeDistanceToMouseInitialPosition.y,
    };
    return this.getAllowedCoordinates({ point, highlight: selectedBox });
  }

  public getKeyboardAllowedCoordinates(movedHighlight: IHighlight<any>, highlight: IHighlight<any>): IPoint {
    if (movedHighlight && this.isBoxValid(movedHighlight)) {
      return movedHighlight;
    }

    const point: IPoint = {
      x: movedHighlight.x,
      y: movedHighlight.y,
    };

    return this.getAllowedCoordinates({ point, highlight });
  }
}
