import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { $digitalPropertyMonitoring } from '../../../../../../../shared/constants/digital-properties';
import {
  AuditToolCommonNames,
  AuditToolCommonNamesToAuditToolNames,
  AuditToolNames,
} from '../../../../../../../shared/constants/audit-tool';
import { wcagNameAndLevel } from '../../../../../../../shared/constants/scanning';
import { TranslateService } from '../../../../../translate/translate.service';
import { IDigitalPropertyMonitoringDetails } from '../../../../../../../shared/interfaces/digital-property.interface';

@Component({
  selector: 'app-monitoring-scan-settings-step',
  templateUrl: './monitoring-scan-settings-step.component.html',
  styleUrls: ['./monitoring-scan-settings-step.component.scss'],
})
export class MonitoringScanSettingsStepComponent implements OnInit, AfterViewInit {
  public conformanceLevelSelectOptions: { key: string; value: wcagNameAndLevel }[];
  public $digitalPropertyMonitoring: typeof $digitalPropertyMonitoring;

  @Input() public stepNumber: number;
  @Input() public form: UntypedFormGroup;
  @Input() public formValidationRequest$: Subject<void>;
  @Input() public toolName: AuditToolCommonNames;

  public get testingTool(): AuditToolNames {
    return AuditToolCommonNamesToAuditToolNames[this.toolName];
  }

  @Input() public monitoring: IDigitalPropertyMonitoringDetails;

  constructor(private translateService: TranslateService) {
    this.$digitalPropertyMonitoring = $digitalPropertyMonitoring;
  }

  private initializeForm(): void {
    if (this.monitoring?.[$digitalPropertyMonitoring.conformanceLevel]) {
      this.form
        .get($digitalPropertyMonitoring.conformanceLevel)
        .setValue(this.monitoring[$digitalPropertyMonitoring.conformanceLevel]);
    }
    if (this.monitoring?.[$digitalPropertyMonitoring.viewport]) {
      this.form.get($digitalPropertyMonitoring.viewport).setValue(this.monitoring[$digitalPropertyMonitoring.viewport]);
    }
  }

  private setupConformanceLevel(): void {
    this.conformanceLevelSelectOptions = Object.values(wcagNameAndLevel).map((conformanceLevel: wcagNameAndLevel) => ({
      key: this.translateService.instant(conformanceLevel),
      value: conformanceLevel,
    }));
  }

  public ngOnInit(): void {
    this.setupConformanceLevel();
  }

  ngAfterViewInit(): void {
    this.initializeForm();
  }
}
