import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestService } from './rest.service';
import { IJobTitlesServerResponse } from '../../../shared/interfaces/job-title.interface';

@Injectable({
  providedIn: 'root',
})
export class JobTitleService {
  constructor(private restService: RestService) {}

  public getJobTitles(limit: number = 50, skip: number = 0): Observable<IJobTitlesServerResponse> {
    return this.restService.getJobTitles(limit, skip);
  }
}
