import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import * as KeyCode from 'keycode-js';

@Directive({
  selector: '[appTrapFocus]',
  exportAs: 'appTrapFocus',
})
export class TrapFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.trapFocus(this.el.nativeElement);
  }

  trapFocus(element: HTMLElement): void {
    const focusableElements =
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select';
    const focusableNodeList = element.querySelectorAll(focusableElements);
    const focusableContent = Array.from(focusableNodeList).filter((el: any) => !el.disabled);
    const firstFocusableElement: any = focusableContent[0];
    const lastFocusableElement: any = focusableContent[focusableContent.length - 1];

    element.addEventListener('keydown', function (e: KeyboardEvent) {
      const isTabPressed = e.keyCode === KeyCode.KEY_TAB;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          /* shift + tab */
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else if (document.activeElement === lastFocusableElement) {
        /* tab */
        firstFocusableElement.focus();
        e.preventDefault();
      }
    });
  }
}
