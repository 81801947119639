import { ChangeDetectorRef, Directive, ElementRef, HostListener, NgZone, OnDestroy } from '@angular/core';
import { Options as FocusTrapOptions } from 'focus-trap';
import { isNull } from 'lodash';

import { CommonUtility } from '../../utility/common.utility';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Directive({
  selector: 'details[appCloseActionMenu]',
})
export class CloseActionMenuDirective implements OnDestroy {
  private menuItemSelector: string = '*[role="menu"] > *[role="menuitem"] > *:not([disabled], [tabindex="-1"])';

  constructor(
    private elRef: ElementRef<HTMLDetailsElement>,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
  ) {}

  @HostListener('document:keydown.escape')
  public closeDropdown(): void {
    this.elRef.nativeElement.open = false;
  }

  @HostListener('document:click', ['$event'])
  public handleOutsideClick(event: MouseEvent): void {
    if (!this.elRef.nativeElement.contains(event.target as Node)) {
      this.closeDropdown();
    }
  }

  @HostListener('keydown.arrowUp', ['$event'])
  public handleArrowUp(event: KeyboardEvent): void {
    this.handleArrowKeys(event, (activeElementIndex: number, menuItemsLength: number) =>
      activeElementIndex === 0 ? menuItemsLength - 1 : activeElementIndex - 1,
    );
  }

  @HostListener('keydown.arrowDown', ['$event'])
  public handleArrowDown(event: KeyboardEvent): void {
    this.handleArrowKeys(event, (activeElementIndex: number, menuItemsLength: number) =>
      activeElementIndex === menuItemsLength - 1 ? 0 : activeElementIndex + 1,
    );
  }

  @HostListener('toggle', ['$event'])
  public toggle(): void {
    if (this.isOpen) {
      this.zone.runOutsideAngular(() => {
        const options: FocusTrapOptions = { allowOutsideClick: true };

        if (this.initialFocusExistsOnPage()) {
          options.initialFocus = this.menuItemSelector;
        }

        setTimeout(() => {
          CommonUtility.activateFocusTrap(this.elRef.nativeElement, options);
        }, 0);
      });
    } else {
      CommonUtility.deactivateFocusTrap();
    }

    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    CommonUtility.deactivateFocusTrap();
  }

  private get isOpen(): boolean {
    return this.elRef.nativeElement.open;
  }

  private initialFocusExistsOnPage(): boolean {
    return !isNull(this.elRef.nativeElement.querySelector(this.menuItemSelector));
  }

  private handleArrowKeys(
    event: KeyboardEvent,
    indexToFocusThunk: (activeElementIndex: number, menuItemsLength: number) => number,
  ): void {
    if (this.isOpen) {
      event.preventDefault();

      if (SharedCommonUtility.notNullish(document.activeElement)) {
        const menuItems: Element[] = Array.from(this.elRef.nativeElement.querySelectorAll(this.menuItemSelector));
        const activeElIndex: number = menuItems.indexOf(document.activeElement);

        if (menuItems.length > 0 && activeElIndex > -1) {
          const itemToFocusIndex: number = indexToFocusThunk(activeElIndex, menuItems.length);
          const itemToFocus: HTMLElement = menuItems[itemToFocusIndex] as HTMLElement;
          CommonUtility.setFocusToElement(null, itemToFocus);
        }

        this.cdr.detectChanges();
      }
    }
  }
}
