<div class="lap-accordion-item">
  <div class="accordion-item-header">
    <button
      type="button"
      [attr.aria-expanded]="!collapsed"
      [attr.aria-controls]="contentId"
      class="btn btn-flat d-flex accordion-item-header-btn"
      (click)="toggleCollapsed()"
    >
      <span [hidden]="collapsed" data-e2e-label="collapse">
        <svg class="accordion-button-icon" role="img" aria-hidden="true">
          <use xlink:href="#chevron-bottom-thin"></use>
        </svg>
      </span>
      <span [hidden]="collapsed === false" data-e2e-label="expand">
        <svg class="accordion-button-icon" role="img" aria-hidden="true">
          <use xlink:href="#chevron-right-thin"></use>
        </svg>
      </span>
      <span *ngIf="header; else useHeaderContent" class="accordion-item-header-text">{{ header }}</span>
      <ng-template #useHeaderContent>
        <ng-content select=".accordion-item-header-content"></ng-content>
      </ng-template>
    </button>
    <ng-content select=".accordion-item-header-unclickable-content"></ng-content>
  </div>
  <div [id]="contentId" class="accordion-item-body" [hidden]="collapsed">
    <ng-content select=".accordion-item-body-content"></ng-content>
  </div>
</div>
