<button
  type="button"
  class="btn btn-link fw-bold copy-to-clipboard"
  [id]="'copy-to-clipboard' + (this.inputId ? '-' + this.inputId : '')"
  [attr.data-clipboard-target]="this.inputId ? '#' + this.inputId : null"
  [attr.data-clipboard-text]="this.textValue"
  [appTooltip]="this.tooltip ? translateService.instant('copy_to_clipboard') : null"
>
  <span class="me-1">{{ buttonText }}</span>
  <svg viewBox="0 0 512 512" aria-hidden="true" focusable="false">
    <use xlink:href="#copy-to-clipboard"></use>
  </svg>
</button>
