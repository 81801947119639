import { Component, Input, OnChanges } from '@angular/core';
import { DsTooltipPosition, Icons, IconStyles } from '@levelaccess/design-system';

import { TranslateService } from '../../translate/translate.service';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { DateUtility } from '../../utility/date.utility';
import { EapColors } from '../../shared/eap-colors';

interface IProgressPercentage {
  percentage: number;
  variation: number;
  absVariation: number;
  previousDate: string;
}

@Component({
  selector: 'app-resolved-findings-progress',
  templateUrl: './resolved-findings-progress.component.html',
  styleUrls: ['./resolved-findings-progress.component.scss'],
})
export class ResolvedFindingsProgressComponent implements OnChanges {
  @Input()
  public resolvedCount: number;
  @Input()
  public totalCount: number;
  @Input()
  public previousResolvedCount: number;
  @Input()
  public previousTotalCount: number;
  @Input()
  public previousComputedDate: Date;
  @Input()
  public percentageThreshold: number;

  public Icons: typeof Icons;
  public EapColors: typeof EapColors;
  public IconStyles: typeof IconStyles;
  public DsTooltipPosition: typeof DsTooltipPosition;
  public progressPercentage: IProgressPercentage;

  constructor(private translateService: TranslateService) {
    this.Icons = Icons;
    this.IconStyles = IconStyles;
    this.EapColors = EapColors;
    this.DsTooltipPosition = DsTooltipPosition;
  }

  public get formattedResolvedCount(): string {
    return SharedCommonUtility.formatCompactNumber(this.resolvedCount);
  }

  public get isPositiveVariation(): boolean {
    return this.progressPercentage?.variation > 0;
  }

  public get isNegativeVariation(): boolean {
    return this.progressPercentage?.variation < 0;
  }

  public get isUnchangedVariation(): boolean {
    return this.progressPercentage?.variation === 0;
  }

  public get isPolicyViolated(): boolean {
    return this.progressPercentage ? this.progressPercentage.percentage < this.percentageThreshold : false;
  }

  public get formattedVariation(): string {
    if (this.isUnchangedVariation) {
      return this.translateService.instant('no_change');
    }
    const sign: string = this.isPositiveVariation ? '+' : '';
    const variationPercent: string = (this.progressPercentage.variation / 100).toLocaleString([], {
      style: 'percent',
    });
    return `${sign}${variationPercent}`;
  }

  private buildProgressPercentage(): void {
    const currentPercentage: number = SharedCommonUtility.percent(this.resolvedCount, this.totalCount);
    this.progressPercentage = {
      percentage: currentPercentage,
      variation: null,
      absVariation: null,
      previousDate: null,
    };

    if (this.previousTotalCount > 0 && SharedCommonUtility.notNullish(this.previousComputedDate)) {
      const previousPercentage: number = SharedCommonUtility.percent(this.previousResolvedCount, this.previousTotalCount);
      const variation: number = currentPercentage - previousPercentage || 0;
      this.progressPercentage.variation = variation;
      this.progressPercentage.absVariation = Math.abs(variation);
      this.progressPercentage.previousDate = DateUtility.prettifyDate(this.previousComputedDate, {
        month: 'short',
        day: 'numeric',
      });
    }
  }

  public ngOnChanges(): void {
    this.buildProgressPercentage();
  }
}
