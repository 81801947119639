export enum $shortLink {
  _id = '_id',
  link = 'link',
  linkType = 'linkType',
  context = 'context',
  createdAt = 'createdAt',
  tenantId = 'tenantId',
}

export enum shortLinkType {
  findingUrl = 'findingUrl',
  auditFinding = 'auditFinding',
  auditFindingAttachment = 'auditFindingAttachment',
  shareFinding = 'shareFindingUrl',
}
