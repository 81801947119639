export enum $navigationCustomState {
  scrollToTop = 'scrollToTop',
  captureNavFocus = 'captureNavFocus',
  userEmails = 'userEmails',
  mobileScanScreen = 'mobileScanScreen',
}

export interface NavigationCustomState {
  // if false, then AppComponent.handleRouterScroller will not scroll the page to top automatically on route change
  [$navigationCustomState.scrollToTop]?: boolean;
  [$navigationCustomState.captureNavFocus]?: boolean;
  [$navigationCustomState.userEmails]?: string[];
  [$navigationCustomState.mobileScanScreen]?: string;
}
