import { Component } from '@angular/core';
import { ImageCellComponent } from '../image-cell/image-cell.component';

@Component({
  selector: 'table-cell-secure-image',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <img
      #ref
      [appSecureResourceId]="config.src"
      [alt]="config.alt"
      [style]="{ 'max-width': config.styles.maxWidth, 'max-height': config.styles.maxHeight }"
    />
  `,
})
export class SecureImageCellComponent extends ImageCellComponent {}
