import { Pipe, PipeTransform } from '@angular/core';
import { DateUtility } from '../utility/date.utility';

@Pipe({
  name: 'prettifyDate',
})
export class PrettifyDatePipe implements PipeTransform {
  public transform(value: string | Date): string {
    return DateUtility.prettifyDate(value instanceof Date ? value : new Date(value));
  }
}
