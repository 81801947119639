import { Component, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { TranslateService } from '../../../../translate/translate.service';
import { UserService } from '../../../../services/user.service';
import { FormService } from '../../../../services/form.service';
import { CustomValidators } from '../../../../services/helpers/form-custom-validators';
import { ErrorMessageService } from '../../../../services/error-message.service';
import { IMfaLoginRequest } from '../../../../../../shared/interfaces/user.interface';
import { InputFieldContext } from '../../../../components/common-input/common-input.component';

@Component({
  selector: 'app-mfa-login',
  templateUrl: './mfa-login.component.html',
  styleUrls: ['../login.component.scss'],
})
export class MfaLoginComponent implements OnDestroy {
  private subscriptions: Subscription;
  private formValidationRequest: Subject<void>;
  private readonly tokenField: string;

  public form: UntypedFormGroup;
  public formValidationRequest$: Observable<void>;

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private formService: FormService,
    private errorMessageService: ErrorMessageService,
  ) {
    this.subscriptions = new Subscription();
    this.formValidationRequest = new Subject<any>();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();

    this.tokenField = 'token';
    const fb: UntypedFormBuilder = new UntypedFormBuilder();
    this.form = fb.group({
      [this.tokenField]: fb.control('', [CustomValidators.required, CustomValidators.validateIsEmpty]),
    });
  }

  get formToken(): AbstractControl {
    return this.form.get(this.tokenField);
  }

  get formContext(): InputFieldContext {
    return {
      field: this.tokenField,
      noLabel: true,
      label: this.tokenField,
      autocomplete: 'off',
      customMessage: this.formToken.errors?.server?.message,
      placeholder: this.translateService.instant('mfa_enter_code_placeholder'),
    };
  }

  private onMfaAuthenticationError(error: HttpErrorResponse): void {
    const messageKey: string | null = this.errorMessageService.getAppErrorResponse(error);
    const message: string =
      messageKey === null ? this.errorMessageService.getGlobalErrorResponse(error) : this.translateService.instant(messageKey);

    this.formToken.setErrors({ server: { message } });
  }

  public onSubmit(): void {
    const request: IMfaLoginRequest = {
      token: this.form.value.token,
    };

    this.subscriptions.add(
      this.userService
        .mfaLogin(request)
        .pipe(take(1))
        .subscribe({
          error: this.onMfaAuthenticationError.bind(this),
        }),
    );
  }

  public isFieldValid(field: string): boolean {
    return this.formService.isFieldValid(field, this.form);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
