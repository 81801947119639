import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Component({
  selector: 'app-form-field-line-count',
  templateUrl: './form-field-line-count.component.html',
})
export class FormFieldLineCountComponent implements OnInit, OnChanges {
  @Input() public lineLimit: number;
  @Input() public fieldValue: string;
  @Input() public limitLabel?: string;

  public lineCount: number;

  constructor() {
    this.lineLimit = 100;
    this.lineCount = 0;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const lines: string[] = changes.fieldValue.currentValue.split('\n').filter((line: string) => line.length > 0);
    this.lineCount = lines.length;
  }

  public ngOnInit(): void {
    if (SharedCommonUtility.isNullish(this.limitLabel)) {
      this.limitLabel = 'label_lines_count';
    }
  }
}
