import { Directive, ElementRef } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Directive({
  selector: '[appStepPrevious]',
})
export class StepPreviousDirective {
  public click$: Observable<void>;

  constructor(private host: ElementRef) {
    this.click$ = fromEvent(this.host.nativeElement, 'click');
  }
}
