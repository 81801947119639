import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import * as KeyCode from 'keycode-js';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

export interface MultiSelectContext {
  noSelectionText?: string;
  label?: (selectedValues: string[]) => string;
}

const valuesControlArray: string = 'values';

@Component({
  selector: 'app-common-multi-select',
  templateUrl: './common-multi-select.component.html',
  styleUrls: ['./common-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonMultiSelectComponent {
  private _availableValues: string[];
  private _selectedValues: Set<string>;
  public readonly valuesControlArray: string;

  @ViewChild('toggleButton')
  public toggleButton: ElementRef<HTMLTextAreaElement>;

  @Input()
  public context: MultiSelectContext;
  @Input()
  public set availableValues(value: string[]) {
    if (SharedCommonUtility.notNullishOrEmpty(value) && value !== this._availableValues) {
      this._availableValues = value;
      this.updateAvailableValues();
    }
  }
  public get availableValues(): string[] {
    return this._availableValues;
  }

  @Input()
  public set selectedValues(value: Set<string>) {
    if (value !== this._selectedValues) {
      this._selectedValues = value;
      this.updateSelectedValues();
    }
  }
  public get selectedValues(): Set<string> {
    return this._selectedValues;
  }
  @Output()
  public selectedValuesChange: EventEmitter<Set<string>>;
  @Input()
  public toggled: boolean;
  @Output()
  public toggledChange: EventEmitter<boolean>;
  @Input()
  public disabledValues: Set<string>;

  public valuesArray: UntypedFormArray;
  public form: UntypedFormGroup;
  public selectedText$: BehaviorSubject<string>;
  public label$: BehaviorSubject<string>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    this.selectedValuesChange = new EventEmitter<Set<string>>();
    this.toggledChange = new EventEmitter<boolean>();
    this.toggled = false;
    this._selectedValues = new Set();
    this._availableValues = [];
    this.selectedText$ = new BehaviorSubject('');
    this.valuesControlArray = valuesControlArray;
    this.label$ = new BehaviorSubject<string>('');

    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({ [valuesControlArray]: this.formBuilder.array([]) });
    this.valuesArray = this.form.get(valuesControlArray) as UntypedFormArray;
  }

  private addCheckbox(value: string): void {
    this.valuesArray.push(this.formBuilder.control(this.selectedValues.has(value)));
  }

  private updateAvailableValues(): void {
    this.valuesArray.clear();
    this.availableValues.forEach(this.addCheckbox.bind(this));
  }

  private updateSelectedValues(): void {
    this.valuesArray.setValue(this._availableValues.map((value: string): boolean => this._selectedValues.has(value)));
    this.updateSelectedText();
  }

  private updateSelectedText(): void {
    this.selectedText$.next((Array.from(this.selectedValues).join(', ') || this.context?.noSelectionText) ?? '');
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent): void {
    if (this.toggled === false) {
      return;
    }

    if (this.elementRef.nativeElement.contains(event.target as Node) === false) {
      this.toggled = false;
    }
  }

  public get label(): string {
    return this.context?.label?.(Array.from(this.selectedValues)) ?? this.selectedText$.value;
  }

  public checkBoxSelectionChanged(_idx: number): void {
    const selection: boolean[] = this.valuesArray.value;
    const selectedValues: Set<string> = new Set();
    selection.forEach((selected: boolean, index: number): void => {
      if (selected) {
        selectedValues.add(this.availableValues[index]);
      }
    });
    this._selectedValues = selectedValues;
    this.updateSelectedText();
    this.selectedValuesChange.emit(selectedValues);
  }

  public toggle(): void {
    this.toggled = !this.toggled;
    this.toggledChange.emit(this.toggled);
  }

  public focusToggleButton(): void {
    this.toggleButton.nativeElement.focus();
  }

  public isDisabled(index: number): boolean {
    if (SharedCommonUtility.notNullish(this.disabledValues)) {
      return this.disabledValues.has(this.availableValues[index]);
    }
    return false;
  }

  public isLastActiveIndex(index: number): boolean {
    return !this.valuesArray.controls
      .slice(index + 1)
      .some((value: AbstractControl, subIndex: number) => !this.isDisabled(index + subIndex));
  }

  public handleInputKeyDown(event: KeyboardEvent, index: number): void {
    const isTabKeyDownInLastElement: boolean =
      event.keyCode === KeyCode.KEY_TAB && !event.shiftKey && this.isLastActiveIndex(index) && this.toggled;
    const isEscapeKeyDown: boolean = event.keyCode === KeyCode.KEY_ESCAPE && this.toggled;
    if (isTabKeyDownInLastElement || isEscapeKeyDown) {
      this.toggle();
      if (isEscapeKeyDown) {
        this.focusToggleButton();
      }
    }
  }

  public handleButtonKeyDown(event: KeyboardEvent): void {
    const isShiftTabKeyDownOnToggled: boolean = event.keyCode === KeyCode.KEY_TAB && event.shiftKey && this.toggled;
    const isEscapeKeyDown = event.keyCode === KeyCode.KEY_ESCAPE && this.toggled;
    if (isShiftTabKeyDownOnToggled || isEscapeKeyDown) {
      this.toggle();
      if (isEscapeKeyDown) {
        this.focusToggleButton();
      }
    }
  }
}
