import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CommonControlsViewMode } from '../../constants/common-controls-view-mode';
import { FormService } from '../../services/form.service';

export interface TextareaFieldContext {
  customMessage?: string;
  label: string;
  field: string;
  id?: string;
  placeholder: string;
  required?: boolean;
  description?: string;
  rows?: number;
  lineLimit?: number;
  limitLabel?: string;
  noLabel?: boolean;
  overrideErrors?: Record<string, string>;
}

@Component({
  selector: 'app-common-textarea',
  templateUrl: './common-textarea.component.html',
  styleUrls: ['./common-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonTextareaComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public context: TextareaFieldContext;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public view: CommonControlsViewMode;

  @Output()
  public valueChanged$: EventEmitter<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formService: FormService,
  ) {
    this.view = 'col-view';
    this.subscriptions = new Subscription();
    this.valueChanged$ = new EventEmitter<any>();
  }

  get domId(): string {
    return this.context.id ?? this.context.field;
  }

  get errorDomId(): string {
    return `${this.domId}-error`;
  }

  get required(): boolean {
    return this.formService.isFieldRequired(this.form, this.context);
  }

  public ngOnInit(): void {
    if (this.formValidationRequest$) {
      this.subscriptions.add(
        this.formValidationRequest$.subscribe(() => {
          this.form.get(this.context.field).updateValueAndValidity();
        }),
      );
    }
    this.subscriptions.add(
      this.form.valueChanges.subscribe(() => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public onChange(): void {
    this.valueChanged$.emit(this.form.get(this.context.field).value);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
