<div
  [ngClass]="{
    active: isVisibleByButton || isVisibleByWindow,
    inactive: isVisibleByButton === false && isVisibleByWindow === false,
  }"
  [ngStyle]="{
    'top.px': top,
    'left.px': left,
    'z-index': zIndex,
  }"
  [id]="tooltipId"
  role="tooltip"
  class="tooltip-inner"
>
  <div
    class="arrow"
    [ngClass]="{
      'top-arrow': arrowPositionVertical === 'top',
      'bottom-arrow': arrowPositionVertical === 'bottom',
    }"
    [ngStyle]="{
      'left.px': arrowOffset,
    }"
  ></div>
  {{ text }}
</div>
