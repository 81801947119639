import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Component({
  selector: 'app-common-file-dropbox',
  templateUrl: './common-file-dropbox.component.html',
  styleUrls: ['./common-file-dropbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonFileDropboxComponent {
  private MAX_FILES: number = 1;
  private MULTIPLE_FILE_ERROR: string = 'error_only_one_file_allowed_for_upload';

  @Input()
  public acceptedFileTypes: string[];

  @Input()
  public acceptedFileError: string;

  @Input()
  public fileDataError: string;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public fileValid: boolean | undefined;

  @Input()
  public fileUploaded: boolean | undefined;

  @Input()
  public fileUploadedMessage: string;

  @Output()
  public emitFile: EventEmitter<File>;

  @Output()
  public clickedValidate: EventEmitter<void>;

  @Output()
  public rebuildForm: EventEmitter<void>;

  public fileDropError: string | undefined;
  public file: File;

  constructor() {
    this.emitFile = new EventEmitter<File>();
    this.clickedValidate = new EventEmitter<void>();
    this.rebuildForm = new EventEmitter<void>();
  }

  private isFileTypeValid(file: File): boolean {
    const type: string = this.acceptedFileTypes.find(
      (acceptedType: string): boolean =>
        acceptedType.toLocaleLowerCase() === file.type.toLocaleLowerCase() ||
        file.name.toLocaleLowerCase().endsWith(acceptedType.toLocaleLowerCase()),
    );
    return !SharedCommonUtility.isNullish(type);
  }

  public filesDropped(event: File[]): void {
    if (event.length !== this.MAX_FILES) {
      this.fileDropError = this.MULTIPLE_FILE_ERROR;
      return;
    }
    const file: File = event[0];
    if (!this.isFileTypeValid(file)) {
      this.fileDropError = this.acceptedFileError;
      return;
    }

    this.file = file;
    this.emitFile.emit(file);
    this.fileUploaded = false;
    this.fileDropError = undefined;
  }

  public filesChosen(event: Event): void {
    const bulkUploadElement: HTMLInputElement = event.target as HTMLInputElement;
    if (Array.from(bulkUploadElement.files).length !== this.MAX_FILES) {
      return;
    }
    const file: File = Array.from(bulkUploadElement.files)[0];
    if (!this.isFileTypeValid(file)) {
      this.fileDropError = this.acceptedFileError;
      return;
    }
    this.file = file;
    this.emitFile.emit(file);
    this.fileUploaded = false;
    this.fileDropError = undefined;
  }

  public cancelFile(): void {
    this.file = undefined;
    this.fileUploaded = undefined;
    this.rebuildForm.emit();
    this.emitFile.emit(null);
  }

  public validate(event: Event): void {
    event.preventDefault();
    this.clickedValidate.emit();
  }
}
