import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, zipAll } from 'rxjs/operators';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { DEBOUNCE_TIME } from '../../shared/constants';
import { MimeTypes, MimeTypeToFileExtension } from '../../../../shared/constants/mime-type';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { FileUtility } from '../../utility/file.utility';
import { validationError } from '../../constants/form.constants';

export class CustomAsyncValidators {
  public static validateNameUniqueness(
    nameToSkip: () => string = (): string => '',
    getter: (name: string) => Observable<any>,
  ): (control: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (
        SharedCommonUtility.isNullishOrEmpty(control.value?.trim()) ||
        control.value.trim().toLowerCase() === nameToSkip().trim().toLowerCase()
      ) {
        return of(null);
      }

      return of(control.value).pipe(
        delay(DEBOUNCE_TIME),
        switchMap((name: string) => getter(name.trim())),
        map(() => ({ unique: true })),
        catchError((error: HttpErrorResponse) => (error.status === HttpStatusCode.NotFound ? of(null) : throwError(error))),
      );
    };
  }

  /**
   * Checks if every JSON file has an valid format. Only JSON files are validated and other files are ignored.
   */
  public static validateJsonFile(control: AbstractControl): Observable<ValidationErrors | null> {
    if (SharedCommonUtility.isNullish(control.value) || CustomAsyncValidators.isFileArray(control.value) === false) {
      return of(null);
    }

    const jsonFiles: File[] = control.value.filter(
      (file: File) => SharedCommonUtility.getFileExtension(file.name) === MimeTypeToFileExtension[MimeTypes.JSON],
    );

    if (jsonFiles.length === 0) {
      return of(null);
    }

    return of(jsonFiles).pipe(
      switchMap((files: File[]) => files.map((file: File) => FileUtility.readFileAsText(file))),
      zipAll(),
      map((textList: string[]) =>
        textList.every((text: string) => SharedCommonUtility.isValidJSON(text)) ? null : { [validationError.invalidJSON]: true },
      ),
    );
  }

  private static isFileArray(array: any): array is File[] {
    return Array.isArray(array) && array.every((value: any) => value instanceof File);
  }
}
