import { Component, Input, OnDestroy, EventEmitter, Output, ComponentRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import { DsButtonVariants, Icons, IconStyles } from '@levelaccess/design-system';

import { IMfaStatusResponse } from '../../../../shared/interfaces/user.interface';
import { $mfaStatus } from '../../../../shared/constants/user';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { CommonConfirmationComponent } from '../common-confirmation-modal/common-confirmation.component';
import { CommonConfirmationModalOptions } from '../../interfaces/modal-dialog.interface';
import { ModalService } from '../../services/modal.service';
import { EapColors } from '../../shared/eap-colors';

@Component({
  selector: 'app-user-mfa',
  styleUrls: ['./user-mfa.component.scss'],
  templateUrl: './user-mfa.component.html',
})
export class UserMfaComponent implements OnDestroy {
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly Icons: typeof Icons = Icons;
  public readonly IconStyles: typeof IconStyles = IconStyles;
  public readonly EapColors: typeof EapColors = EapColors;
  public $mfaStatus: typeof $mfaStatus;

  private subscriptions: Subscription;

  @Input()
  public mfaStatusResponse$: Observable<IMfaStatusResponse>;

  @Input()
  public disableMfaConfirmationMessage: string = 'mfa_disable_confirmation_message';

  @Output()
  public enableMfa: EventEmitter<void>;

  @Output()
  public disableMfa: EventEmitter<void>;

  constructor(private modalService: ModalService) {
    this.subscriptions = new Subscription();

    this.$mfaStatus = $mfaStatus;
    this.enableMfa = new EventEmitter<void>();
    this.disableMfa = new EventEmitter<void>();
  }

  private closeDisableMfaModal(isConfirmedFn: () => boolean): void {
    if (!isConfirmedFn()) {
      return;
    }

    this.disableMfa.emit();
  }

  public get mfaIsConfigurable$(): Observable<boolean> {
    return this.mfaStatusResponse$.pipe(
      filter((response: IMfaStatusResponse): boolean => SharedCommonUtility.notNullish(response)),
      map((response: IMfaStatusResponse): boolean => response.isConfigurable),
    );
  }

  public get mfaStatus$(): Observable<$mfaStatus> {
    return this.mfaStatusResponse$.pipe(
      filter((response: IMfaStatusResponse): boolean => SharedCommonUtility.notNullish(response)),
      map((response: IMfaStatusResponse): $mfaStatus => response.isConfigurable && response.status),
    );
  }

  public get canEnableMfa(): boolean {
    return this.enableMfa.observers.length > 0;
  }

  public openDisableMfaModal(): void {
    const modalComponent: ComponentRef<CommonConfirmationComponent> = this.modalService.open(CommonConfirmationComponent);
    const modalOptions: CommonConfirmationModalOptions = {
      messages: [],
      description: this.disableMfaConfirmationMessage,
      header: 'mfa_disable_confirmation_title',
      labelAction: 'label_ok',
      labelClose: 'label_cancel',
    };
    modalComponent.instance.onCreate(modalOptions);

    const isConfirmedFn = (): boolean => modalComponent.instance.confirmed;
    this.subscriptions.add(
      modalComponent.instance.container.onClose$.pipe(take(1)).subscribe(this.closeDisableMfaModal.bind(this, isConfirmedFn)),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
