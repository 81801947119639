import { CommonUtility } from './common.utility';

export class DomUtility {
  public static getIFrameDocument(frame: Element): Document | null {
    if (CommonUtility.isHostObjectProperty(frame, 'contentDocument')) {
      try {
        if ((frame as HTMLIFrameElement).contentDocument) {
          return (frame as HTMLIFrameElement).contentDocument;
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    }

    if (CommonUtility.isHostObjectProperty(frame, 'contentWindow')) {
      try {
        if ((frame as HTMLIFrameElement).contentWindow.document) {
          return (frame as HTMLIFrameElement).contentWindow.document;
        }
      } catch (e) {
        console.debug(e);
        return null;
      }
    }

    return null;
  }

  /**
   * checks if 'cssSelector' would pass as a valid css selector. also accepts nullish strings as valid.
   *
   * @param cssSelector the string to test
   * @returns true if the 'cssSelector' is either a valid css selector string, or nullish. false otherwise.
   */
  public static isCssSelectorValid(cssSelector: string): boolean {
    try {
      document.querySelector(cssSelector);
    } catch (e: any) {
      return false;
    }

    return true;
  }
}
