import { Component, HostBinding } from '@angular/core';

import { config } from '../../environments/config.shared';

@Component({
  selector: 'app-svg-images',
  templateUrl: '../../assets/images/svg.html',
})
export class SvgImagesComponent {
  @HostBinding('style.display') public none: string = 'none';

  public config: any;

  constructor() {
    this.config = config;
  }
}
