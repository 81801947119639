import { Directive, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DsButtonVariants, DsInputStates, DsModalColorVariant, Icons } from '@levelaccess/design-system';

import { ErrorMessageService } from '../../../../services/error-message.service';
import { PageService } from '../../../../services/page.service';
import { $page, $pageImage, PAGE_CHAR_LIMITS } from '../../../../../../shared/constants/page';
import { CustomValidators } from '../../../../services/helpers/form-custom-validators';
import { FormService } from '../../../../services/form.service';
import { TranslateService } from '../../../../translate/translate.service';
import { validationError } from '../../../../constants/form.constants';
import { LoadErrorHandler } from '../../../../services/load-error-handler';
import { BannerType } from '../../../../components/users/edit-user/edit-permissions-banner/edit-permissions-banner.component';
import { NotificationService } from '../../../../services/notification.service';
import {
  FileDragAndDropComponent,
  IFileDragAndDropContext,
} from '../../../../components/form-field/file-upload/file-drag-and-drop/file-drag-and-drop.component';
import { config } from '../../../../../environments/config.shared';
import {
  SupportedPageScreenshotExtensions,
  SupportedPageScreenshotMimeTypes,
} from '../../../../../../shared/constants/mime-type';
import { DigitalPropertyService } from '../../../../services/digital-property.service';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { UserPropertyService } from '../../../../services/user-property.service';
import { FeatureFlagService } from '../../../../services/feature-flag/feature-flag.service';

export enum PageModalType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum ScreenshotOrigin {
  NEW = 'NEW',
  UPLOADED = 'UPLOADED',
}

export enum FormField {
  URL = 'url',
  TITLE = 'title',
  DESCRIPTION = 'description',
  SCREENSHOT = 'screenshot',
  ALT_TEXT = 'alt_text',
}

@Directive()
export abstract class AbstractPageModalComponent implements OnDestroy {
  @ViewChild(FileDragAndDropComponent)
  public fileDragAndDropComponent: FileDragAndDropComponent;

  public backNavLabel: string | undefined;
  public requireURL: boolean;

  public readonly DsModalColorVariant: typeof DsModalColorVariant = DsModalColorVariant;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly Icons: typeof Icons = Icons;
  public readonly BannerType: typeof BannerType = BannerType;
  public readonly FormField: typeof FormField = FormField;
  public readonly ScreenshotOrigin: typeof ScreenshotOrigin = ScreenshotOrigin;
  public readonly urlCharLimit: number = PAGE_CHAR_LIMITS[$page.url];
  public readonly titleCharLimit: number = PAGE_CHAR_LIMITS[$page.name];
  public readonly descriptionCharLimit: number = PAGE_CHAR_LIMITS[$page.description];
  public readonly altTextCharLimit: number = PAGE_CHAR_LIMITS[$pageImage.altText];

  protected subscription: Subscription = new Subscription();

  public form: UntypedFormGroup;
  public showUrlWarning: boolean = false;
  public urlError$: Observable<string>;
  public titleError$: Observable<string>;
  public descriptionError$: Observable<string>;
  public altTextError$: Observable<string>;
  public formValidationRequest$: Subject<void> = new Subject<void>();

  public screenshotOrigin: ScreenshotOrigin | null = null;
  public screenshotData: string | ArrayBuffer = null;
  public screenshotFile: File = null;

  public abstract readonly modalType: PageModalType;

  public abstract submit(): void;

  public bannerText$: Observable<string | undefined> = of(undefined);

  protected constructor(
    public modal: NgbActiveOffcanvas,
    protected formBuilder: UntypedFormBuilder,
    protected formService: FormService,
    protected digitalPropertyService: DigitalPropertyService,
    protected element: ElementRef<Element>,
    protected pageService: PageService,
    protected errorMessageService: ErrorMessageService,
    protected loadErrorHandler: LoadErrorHandler,
    protected translateService: TranslateService,
    protected notificationService: NotificationService,
    protected userPropertyService: UserPropertyService,
    protected featureFlagService: FeatureFlagService,
  ) {}

  public get fileUploadContext(): IFileDragAndDropContext {
    return {
      field: FormField.SCREENSHOT,
      maxTotalFileQuantity: this.maxUploadQuantity,
      maxFileSize: this.maxFileUploadSize,
      maxTotalFileSize: this.maxUploadSize,
      acceptedFileExtensions: SupportedPageScreenshotExtensions,
      acceptedMimeTypes: SupportedPageScreenshotMimeTypes,
    };
  }

  public get maxUploadQuantity(): number {
    return config.pageScreenshot.maxUploadQuantity;
  }

  public get maxUploadSize(): number {
    return config.pageScreenshot.maxUploadSize;
  }

  public get maxFileUploadSize(): number {
    return config.pageScreenshot.maxFileUploadSize;
  }

  public onFileUpload(files: File[]): void {
    if (files.length === 0 || SharedCommonUtility.isNullish(files[0])) {
      return;
    }

    this.screenshotFile = files[0];

    const reader: FileReader = new FileReader();
    reader.onload = (): void => {
      this.screenshotData = reader.result;
      this.screenshotOrigin = ScreenshotOrigin.NEW;
    };
    reader.readAsDataURL(this.screenshotFile);

    const control: AbstractControl = this.form.get(FormField.ALT_TEXT);
    control.addValidators(this.altTextValidators());
  }

  public removeScreenshot(): void {
    this.screenshotFile = null;
    this.screenshotData = null;
    this.screenshotOrigin = null;

    this.form.get(FormField.SCREENSHOT).reset();
    this.form.get(FormField.ALT_TEXT).reset('');

    const control: AbstractControl = this.form.get(FormField.ALT_TEXT);
    control.removeValidators(this.altTextValidators());
  }

  protected altTextValidators(): ValidatorFn[] {
    return [CustomValidators.required, CustomValidators.validateIsEmpty, CustomValidators.maxLength(this.altTextCharLimit)];
  }

  protected buildTitleFormControl(defaultValue?: string): UntypedFormControl {
    const control: UntypedFormControl = this.formBuilder.control(defaultValue ?? '', [
      CustomValidators.validateIsEmpty,
      CustomValidators.maxLength(this.titleCharLimit),
    ]);

    this.buildTitleErrorObservable(control);

    return control;
  }

  private buildTitleErrorObservable(control: UntypedFormControl): void {
    this.titleError$ = control.statusChanges.pipe(
      map(() =>
        this.formService.getErrorMessageForField(control, FormField.TITLE, '', '', {
          [validationError.unique]: this.translateService.instant('upsert_page_duplicate_name_error'),
        }),
      ),
    );
  }

  protected buildUrlFormControl(defaultValue?: string): UntypedFormControl {
    const control: UntypedFormControl = this.formBuilder.control(defaultValue ?? '', [
      ...(this.requireURL ? [CustomValidators.validateIsEmpty] : []),
      CustomValidators.isValidUrl,
      CustomValidators.maxLength(this.urlCharLimit),
    ]);

    this.buildUrlErrorObservable(control);

    return control;
  }

  private buildUrlErrorObservable(control: UntypedFormControl): void {
    this.urlError$ = control.statusChanges.pipe(
      map(() =>
        this.formService.getErrorMessageForField(control, FormField.URL, '', '', {
          isDomainPartOfSubscription: this.translateService.instant('url_not_licensed_domain'),
        }),
      ),
    );
  }

  protected buildDescriptionFormControl(defaultValue?: string): UntypedFormControl {
    const control: UntypedFormControl = this.formBuilder.control(defaultValue ?? '', [
      CustomValidators.maxLength(this.descriptionCharLimit),
    ]);

    this.buildDescriptionErrorObservable(control);

    return control;
  }

  private buildDescriptionErrorObservable(control: UntypedFormControl): void {
    this.descriptionError$ = control.statusChanges.pipe(
      map(() => this.formService.getErrorMessageForField(control, FormField.DESCRIPTION)),
    );
  }

  protected buildScreenshotFormControl(defaultValue?: string): UntypedFormControl {
    return this.formBuilder.control(defaultValue);
  }

  protected buildAltTextFormControl(defaultValue?: string): UntypedFormControl {
    const control: UntypedFormControl = this.formBuilder.control(defaultValue ?? '', [
      CustomValidators.maxLength(this.altTextCharLimit),
    ]);

    this.buildAltTextErrorObservable(control);

    return control;
  }

  private buildAltTextErrorObservable(control: UntypedFormControl): void {
    this.altTextError$ = control.statusChanges.pipe(
      map(() => this.formService.getErrorMessageForField(control, FormField.ALT_TEXT, '', 'upsert_page_alt_text')),
    );
  }

  protected addDuplicateUrlWarningValidator(existingUrls: string[]): void {
    const existingUrlSet: Set<string> = new Set(
      existingUrls.filter(SharedCommonUtility.notNullishOrEmpty).map((url: string) => url.toLowerCase()),
    );

    this.subscription.add(
      this.form
        .get(FormField.URL)
        .valueChanges.pipe(
          filter(SharedCommonUtility.notNullishOrEmpty),
          map((url: string) => existingUrlSet.has(url.toLowerCase().trim())),
        )
        .subscribe((show: boolean) => {
          this.showUrlWarning = show;
        }),
    );
  }

  protected addTitleUniquenessValidator(existingNames: string[]): void {
    const existingNameSet: Set<string> = new Set(existingNames.map((url: string) => url.toLowerCase()));
    const control: AbstractControl = this.form.get(FormField.TITLE);
    control.addValidators(CustomValidators.uniqueValidator(existingNameSet, true, true));
  }

  public hideUrlWarning(): void {
    this.showUrlWarning = false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public cancel(): void {
    this.modal.dismiss();
  }

  public get pageTitle(): string {
    return this.modalType === PageModalType.ADD ? 'add_page' : 'edit_page';
  }

  public get urlFieldState(): DsInputStates {
    return this.modalType === PageModalType.ADD ? DsInputStates.active : DsInputStates.nonEditable;
  }

  public get submitButtonLabel(): string {
    return this.modalType === PageModalType.ADD ? 'add_page' : 'edit_page';
  }
}
