import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';

import { PageApi } from './rest/page.api';
import { DEBOUNCE_TIME } from '../shared/constants';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { IExistsResponse } from '../../../shared/interfaces/common.interface';
import { IPage, IPagesResponse } from '../../../shared/interfaces/page.interface';
import { $page, $pageImage, DEFAULT_PAGE_SCREENSHOT_ALT_TEXT } from '../../../shared/constants/page';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private validateUniqueNameSubject$: BehaviorSubject<null> = new BehaviorSubject<null>(null);

  public constructor(private pageApi: PageApi) {}

  public getPages(
    workspaceId: string,
    digitalPropertyId: string,
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
    mustContainUrl: boolean = false,
  ): Observable<IPagesResponse> {
    const pagesResponse: Observable<IPagesResponse> = this.pageApi.getPages(
      workspaceId,
      digitalPropertyId,
      skip,
      limit,
      term,
      sortBy,
      direction,
      mustContainUrl,
    );

    return pagesResponse.pipe(
      map((response: IPagesResponse): IPagesResponse => {
        return { ...response, pages: response.pages?.map(this.fillDefaultAltText.bind(this)) };
      }),
    );
  }

  public getPage(workspaceId: string, digitalPropertyId: string, pageId: string): Observable<IPage> {
    const pageResponse: Observable<IPage> = this.pageApi.getPage(workspaceId, digitalPropertyId, pageId);
    return pageResponse.pipe(map(this.fillDefaultAltText));
  }

  public createPage(workspaceId: string, digitalPropertyId: string, formData: FormData): Observable<IPage> {
    return this.pageApi.createPage(workspaceId, digitalPropertyId, formData);
  }

  public updatePage(workspaceId: string, digitalPropertyId: string, pageId: string, updateData: FormData): Observable<IPage> {
    return this.pageApi.updatePage(workspaceId, digitalPropertyId, pageId, updateData);
  }

  public pageExists(
    workspaceId: string,
    digitalPropertyId: string,
    pageName: string,
    options: { skipLoader: boolean } = { skipLoader: false },
  ): Observable<IExistsResponse> {
    return this.pageApi.pageExists(workspaceId, digitalPropertyId, pageName, options);
  }

  public pageExistsByURL(workspaceId: string, digitalPropertyId: string, pageURL: string): Observable<IExistsResponse> {
    return this.pageApi.pageExistsByURL(workspaceId, digitalPropertyId, pageURL);
  }

  public validateNameUniqueness(
    workspaceId: string,
    dpId: string,
    exceptionThunk: () => string = (): string => '',
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (SharedCommonUtility.isNullishOrEmpty(control.value)) {
        return of(null);
      }

      if (control.value?.trim() === exceptionThunk()) {
        return of(null);
      }

      const result: Observable<ValidationErrors | null> = this.validateUniqueNameSubject$.pipe(
        debounceTime(DEBOUNCE_TIME),
        take(1),
        switchMap(this.pageExists.bind(this, workspaceId, dpId, control.value?.trim(), { skipLoader: true })),
        map((response: IExistsResponse): ValidationErrors | null => {
          return response.exists === true ? { unique: true } : null;
        }),
      );

      this.validateUniqueNameSubject$.next(null);
      return result;
    };
  }

  private fillDefaultAltText(page: IPage): IPage {
    if (SharedCommonUtility.isNullish(page[$page.screenshot])) {
      return page;
    }

    return {
      ...page,
      screenshot: {
        ...page[$page.screenshot],
        [$pageImage.altText]: page[$page.screenshot][$pageImage.altText] || DEFAULT_PAGE_SCREENSHOT_ALT_TEXT,
      },
    };
  }
}
