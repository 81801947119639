import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import {
  IUpsertCustomSeverityRequest,
  ISwapCustomSeveritiesRanksRequest,
  ICustomSeveritiesObj,
  ICustomSeverity,
} from '../../../../shared/interfaces/tenant.interface';
import { $severity, CustomSeverityKeyType } from '../../../../shared/constants/accessibility';

@Injectable({
  providedIn: 'root',
})
export class TenantSeveritiesApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /severities
  public getAll(): Observable<ICustomSeveritiesObj> {
    const url: string = this.restBuilder.create().customSeverities().getApiUrl();

    return this.httpClient.get<ICustomSeveritiesObj>(url);
  }

  // POST /severities
  public create(createSeveritiesData: IUpsertCustomSeverityRequest): Observable<ICustomSeverity> {
    const url: string = this.restBuilder.create().customSeverities().getApiUrl();

    return this.httpClient.post<ICustomSeverity>(url, createSeveritiesData);
  }

  // PUT /severities/:severityKey
  public update(severityKey: $severity, updateSeveritiesData: IUpsertCustomSeverityRequest): Observable<ICustomSeverity> {
    const url: string = this.restBuilder.create().customSeverities(severityKey).getApiUrl();

    return this.httpClient.put<ICustomSeverity>(url, updateSeveritiesData);
  }

  // PUT /severities/swap-ranks
  public swapRanks(updateSeveritiesData: ISwapCustomSeveritiesRanksRequest): Observable<void> {
    const url: string = this.restBuilder.create().customSeverities().swapRanks().getApiUrl();

    return this.httpClient.put<void>(url, updateSeveritiesData);
  }

  // POST /severities/:severityKey/delete-and-reassign
  public deleteAndReassignSeverity(toDelete: CustomSeverityKeyType, reassignTo: $severity): Observable<void> {
    const url: string = this.restBuilder.create().customSeverities(toDelete).deleteAndReassign().getApiUrl();

    return this.httpClient.post<void>(url, { reassignTo });
  }
}
