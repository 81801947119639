import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Navigation, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserService } from '../../../services/user.service';
import { alertType } from '../../../constants/alert.constants';
import { Api } from '../../../../../shared/constants/api';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-activation-link',
  templateUrl: './activation-link.component.html',
})
export class ResendActivationLinkComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public email: string;
  public isResendDone: boolean;
  public hasResendFailed: boolean;
  public errorMessage: string;
  public alertType: typeof alertType;
  public isAuthenticated: boolean;
  public Api: typeof Api;
  public pageTitle: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private routingService: RoutingService,
  ) {
    this.subscription = new Subscription();

    // use these values as the defaults
    this.hasResendFailed = false;
    this.isResendDone = false;
    this.errorMessage = '';

    const routerCurrentNavigation: Navigation = this.router.getCurrentNavigation();

    // set these values only if routerCurrentNavigation extras state exists
    if (routerCurrentNavigation && routerCurrentNavigation.extras && routerCurrentNavigation.extras.state) {
      this.hasResendFailed = Boolean(routerCurrentNavigation.extras.state.hasResendFailed);
      this.isResendDone = Boolean(routerCurrentNavigation.extras.state.isResendDone);
      this.errorMessage = String(routerCurrentNavigation.extras.state.errorMessage);
    } else if (history.state) {
      // use history if routerCurrentNavigation is null (login -> non-login page causes constructor to be called twice)
      this.hasResendFailed = Boolean(history.state.hasResendFailed);
      this.isResendDone = Boolean(history.state.isResendDone);
      this.errorMessage = String(history.state.errorMessage);
    }

    this.isAuthenticated = this.userService.isAuthenticated();
    this.alertType = alertType;
    this.Api = Api;
    this.pageTitle = '';
  }

  public resendActivationLink(): void {
    const onSuccess = (): void => {
      this.isResendDone = true;
      this.hasResendFailed = false;
    };

    const onError = (response: HttpErrorResponse): void => {
      this.isResendDone = false;
      this.hasResendFailed = true;
      this.errorMessage = response.error.message;
    };

    if (this.isAuthenticated) {
      this.subscription.add(this.userService.resendActivationLink(this.email).subscribe(onSuccess, onError));
    } else {
      this.subscription.add(this.userService.resendVisitorActivationLink(this.email).subscribe(onSuccess, onError));
    }
  }

  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
