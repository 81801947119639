<ds-modal class="modal-container" [modal]="modal" [title]="'customize_chart' | translate">
  <form [formGroup]="form">
    <ds-radio-group
      [label]="'frequency' | translate"
      [formControlName]="FormFields.frequency"
      
    >
      <ds-radio [value]="DashboardDataFrequency.weekly" [label]="'label_occurrence_week' | translate"></ds-radio>
      <ds-radio [value]="DashboardDataFrequency.monthly" [label]="'label_occurrence_month' | translate"></ds-radio>
      <ds-radio [value]="DashboardDataFrequency.quarterly" [label]="'label_occurrence_quarter' | translate"></ds-radio>
    </ds-radio-group>
  </form>

  <ng-template ds-modal-slide-out-footer>
    <ds-button-group
      [primaryAction]="{ label: 'action_save' | translate }"
      [secondAction]="{ label: 'form_cancel' | translate, variant: DsButtonVariants.secondary }"
      (primaryActionClicked)="submit()"
      (secondActionClicked)="modalService.closeAllModals()"
    >
    </ds-button-group>
  </ng-template>
</ds-modal>
