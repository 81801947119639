<h3>{{ 'workspaces' | translate }}</h3>

<div class="d-flex mb-3" [formGroup]="searchForm">
  <app-searchbox
    [label]="'search_by_workspace' | translate"
    [placeholder]="'form_search' | translate"
    [formControlName]="SearchFormFields.workspaceSearch"
  ></app-searchbox>
</div>

<app-ngb-table [config]="tableConfig" [data]="tableData$ | async" [sortingOrder]="sortOrder$ | async" (onSort)="onSort($event)">
</app-ngb-table>

<ds-pagination
  [ariaLabel]="'workspaces_permissions_table_pagination' | translate"
  [page]="page$ | async"
  [collectionSize]="total$"
  (pageChange)="onPageChange($event)"
>
</ds-pagination>
