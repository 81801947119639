import { Component, EventEmitter, Input, Output } from '@angular/core';

let counter: number = 0;

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent {
  @Input()
  public collapsed: boolean;
  @Input()
  public contentDescription: string;
  @Input()
  public header: string;
  @Output()
  public collapsedChange: EventEmitter<boolean>;
  @Output()
  public onClose: EventEmitter<void>;

  public id: string;
  public contentId: string;

  constructor() {
    this.id = `accordion-item-${(counter += 1)}`;
    this.contentId = `${this.id}-body`;
    this.collapsed = true;
    this.collapsedChange = new EventEmitter<boolean>();
    this.onClose = new EventEmitter<void>();
  }

  public toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
    if (this.collapsed) {
      this.onClose.emit();
    }
  }
}
