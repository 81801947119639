import { Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface ITitleCellConfig extends ICellConfig {
  title: string;
  details: string;
}

@Component({
  selector: 'table-cell-title',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <span #ref>{{ config.title }}</span>
    <br />
    <span #ref>{{ config.details }}</span>
  `,
})
export class TitleCellComponent extends BaseCellComponent {
  @Input() public config: ITitleCellConfig;
}
