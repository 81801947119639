import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  public isLoading: boolean;

  constructor(private loaderService: LoaderService) {
    this.subscriptions = new Subscription();
    this.isLoading = true;
  }

  private listenToLoader(): void {
    this.subscriptions.add(
      this.loaderService.loader.pipe(debounceTime(200)).subscribe((loading: boolean): void => {
        this.isLoading = loading;
      }),
    );
  }

  public ngOnInit(): void {
    this.listenToLoader();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
