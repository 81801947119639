import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ResourceUtility } from '../../utility/resource.utility';
import { NotificationPosition } from '../../models/notification.model';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '../../translate/translate.service';

@Directive({
  selector: '[appDownloadLink]',
})
export class DownloadLinkDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  // Determines whether the directive is active and will intercept download links to the secure download APIs
  // Empty string is the default value, and it corresponds to being true.
  @Input() public appDownloadLink: boolean | '';

  constructor(
    private element: ElementRef<HTMLAnchorElement>,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.subscription = new Subscription();
  }

  /**
   * Returns a relative URL.
   */
  private get url(): string {
    return this.element.nativeElement.pathname;
  }

  private secureDownload(event: MouseEvent): void {
    if (this.appDownloadLink === false) {
      return;
    }
    event.preventDefault();

    this.subscription.add(
      this.httpClient
        .get(this.url, {
          observe: 'response',
          responseType: 'arraybuffer',
        })
        .subscribe({
          next: (response: HttpResponse<ArrayBuffer>) => {
            ResourceUtility.downloadResponse(response);
          },
          error: () => {
            const errorMessage: string = this.translateService.instant('failed_download_file');
            this.notificationService.error(errorMessage, NotificationPosition.Toast);
          },
        }),
    );
  }

  public ngOnInit(): void {
    this.element.nativeElement.addEventListener('click', this.secureDownload.bind(this));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
