export enum DeviceType {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

export enum OperatingSystem {
  WINDOWS = 'WINDOWS',
  MACOSX = 'MACOSX',
  WINDOWS_PHONE = 'WINDOWS_PHONE',
  WINDOWS_RT = 'WINDOWS_RT',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  LINUX = 'LINUX',
  UNKNOWN = 'UNKNOWN',
}

export const OPERATING_SYSTEMS: { [key in OperatingSystem]: string } = {
  WINDOWS: 'Windows',
  MACOSX: 'Mac OS X',
  WINDOWS_PHONE: 'Windows Phone',
  WINDOWS_RT: 'Windows RT',
  IOS: 'iOS',
  ANDROID: 'Android',
  LINUX: 'Linux',
  UNKNOWN: 'Unknown',
};
