<div class="typeahead-wrapper">
  <input
    type="text"
    class="w-100 form-element z-depth-1"
    autocomplete="off"
    id="{{ id }}"
    tabindex="0"
    role="combobox"
    [attr.aria-expanded]="false"
    [attr.aria-autocomplete]="'list'"
    [attr.aria-owns]="id + '__results-list'"
    [(ngModel)]="searchQuery"
    (focus)="onSearchInputFocused()"
    (keydown)="onSearchInputKeyDown($event)"
    (keyup)="onSearchInputKeyUp($event)"
    #searchInput
  />
  <div
    *ngIf="searchQuery && isInputFocused"
    id="{{ id + '__results-list' }}"
    class="typeahead-results"
    role="listbox"
    #resultsList
  >
    <div
      *ngFor="let item of data; let i = index"
      [ngClass]="{ hover: hoverItemIndex === i }"
      (click)="onResultsSelected(item)"
      (mouseenter)="onResultsMouseEnter(i)"
      (mouseleave)="onResultsMouseLeave(i)"
    >
      <ng-container *ngTemplateOutlet="resultRowTpl; context: { $implicit: item }"></ng-container>
    </div>
    <div *ngIf="data && data.length === 0" class="no-results">
      {{ 'no_results_found' | translate }}
    </div>
  </div>
</div>
