import { AuditFindingQAStatus } from '../../../shared/constants/audit-finding';

export enum severityColors {
  critical = '#b10606',
  high = '#b85000',
  low = '#0552b8',
}

export enum scoreRangeLabels {
  low = 'low',
  mid = 'mid',
  high = 'high',
}

export const ScoreRanges: Readonly<Record<scoreRangeLabels, [number, number]>> = Object.freeze({
  [scoreRangeLabels.low]: [0, 49],
  [scoreRangeLabels.mid]: [50, 79],
  [scoreRangeLabels.high]: [80, 100],
});

export const ScoreRangeColors: Readonly<Record<scoreRangeLabels, string>> = Object.freeze({
  [scoreRangeLabels.low]: '#b10606',
  [scoreRangeLabels.mid]: '#b85000',
  [scoreRangeLabels.high]: '#20652a',
});

export const RemediationChartColors: Readonly<Partial<Record<AuditFindingQAStatus, string>>> = Object.freeze({
  [AuditFindingQAStatus.dismissed]: '#B10606',
  [AuditFindingQAStatus.fixed]: '#298335',
  [AuditFindingQAStatus.notFixed]: '#B0008E',
  [AuditFindingQAStatus.open]: '#555555',
  [AuditFindingQAStatus.partiallyFixed]: '#002F57',
});

export enum findingsGraphSectionLabels {
  pass = 'pass',
  fail = 'fail',
  warn = 'warn',
  notChecked = 'notChecked',
}

export const ReportFindingsChartColors: Readonly<Partial<Record<findingsGraphSectionLabels, string>>> = Object.freeze({
  [findingsGraphSectionLabels.pass]: '#298335',
  [findingsGraphSectionLabels.fail]: '#B0008E',
  [findingsGraphSectionLabels.warn]: '#002f57',
  [findingsGraphSectionLabels.notChecked]: '#555',
});

export const DECAL_COLOR: string = 'rgba(255, 255, 255, 0.8)';
export const TOOLTIP_BACKGROUND_COLOR: string = '#000000';
export const TOOLTIP_TEXT_COLOR: string = '#ffffff';
