import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import { ISalesforceAccount } from '../../../../shared/interfaces/salesforce.interface';

@Injectable({ providedIn: 'root' })
export class SalesforceApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /admin/salesforce/accounts
  public getSalesforceAccounts(): Observable<ISalesforceAccount[]> {
    const url: string = this.restBuilder.create().admin().salesforce().accounts().getApiUrl();

    return this.httpClient.get<ISalesforceAccount[]>(url);
  }
}
