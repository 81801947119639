import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';

import { UserService } from '../../../services/user.service';
import { alertType } from '../../../constants/alert.constants';
import { RoutingService } from '../../../services/routing.service';
import { ErrorMessageService } from '../../../services/error-message.service';
import { CommonUtility } from '../../../utility/common.utility';
import { TranslateService } from '../../../translate/translate.service';
import { errorMessagesNames } from '../../../../../shared/constants/errors';
import { Api } from '../../../../../shared/constants/api';
import { ErrorHandlerService } from '../../../services/error-handler.service';

import { BannerType } from '../../../components/users/edit-user/edit-permissions-banner/edit-permissions-banner.component';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public email: string;
  public isResetDone: boolean;
  public hasResetFailed: boolean;
  public isEmailUnverifiedError: boolean;
  public errorHeader: string;
  public errorMessage: string;
  public alertType: typeof alertType;
  public pageTitle: string;
  public BannerType: typeof BannerType = BannerType;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public Icons: typeof Icons = Icons;

  constructor(
    private userService: UserService,
    private routingService: RoutingService,
    private router: Router,
    private errorMessageService: ErrorMessageService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
  ) {
    this.subscription = new Subscription();
    this.hasResetFailed = false;
    this.isResetDone = false;
    this.alertType = alertType;
    this.pageTitle = '';
    this.isEmailUnverifiedError = false;
  }

  public onUserUnverifiedEmailBannerClose(): void {
    this.hasResetFailed = false;
    this.isEmailUnverifiedError = false;
  }

  public onResendActivationLink(): void {
    const onSuccess = (): void => {
      const extras: NavigationExtras = {
        state: {
          isResendDone: true,
          hasResendFailed: false,
        },
      };

      this.router
        .navigate([`/${Api.activation_link}`], extras)
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    };

    const onError = (response: HttpErrorResponse): void => {
      const extras: NavigationExtras = {
        state: {
          isResendDone: false,
          hasResendFailed: true,
          errorMessage: response.error.message,
        },
      };

      this.router
        .navigate([`/${Api.activation_link}`], extras)
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    };

    this.subscription.add(this.userService.resendVisitorActivationLink(this.email).subscribe(onSuccess, onError));
  }

  public resetPassword(): void {
    const onSuccess = (): void => {
      this.isResetDone = true;
      this.hasResetFailed = false;
    };

    const onError = (response: HttpErrorResponse): void => {
      if (CommonUtility.extractHTTPErrorName(response) === errorMessagesNames.ErrorUserEmailUnverified) {
        this.isEmailUnverifiedError = true;
        this.errorHeader = '';
        this.errorMessage = `${this.translateService.instant('admin_recently_changed_email')}\n${this.translateService.instant(
          'check_your_inbox_verification_email',
        )}`;
      } else {
        this.isEmailUnverifiedError = false;
        this.errorHeader = this.translateService.instant('reset_failed_message');
        this.errorMessage = this.errorMessageService.getGlobalErrorResponse(response);
      }

      this.isResetDone = false;
      this.hasResetFailed = true;

      CommonUtility.setFocusToElement('email');
    };

    this.subscription.add(this.userService.resetPassword(this.email).subscribe(onSuccess, onError));
  }

  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
