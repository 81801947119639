import { ChangeDetectorRef, Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

import { MessageBusChannels, MessageBusService } from '../../services/message-bus.service';
import { tooltipId } from './tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective {
  @HostBinding('attr.tabIndex') tabIndex: string = '0';
  private _describedBy: string = null;
  public set describedBy(value: string) {
    this._describedBy = value;
    if (value === null) {
      this.elementRef.nativeElement.removeAttribute('aria-describedby');
    } else {
      this.elementRef.nativeElement.setAttribute('aria-describedby', tooltipId);
    }
  }
  public get describedBy(): string {
    return this._describedBy;
  }

  private tooltipValue: string;

  @Input() public set appTooltip(text: string) {
    this.tooltipValue = text;
  }

  constructor(
    private messageBusService: MessageBusService,
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {}

  @HostListener('focus', ['$event'])
  @HostListener('mouseenter', ['$event'])
  public onActive(event: MouseEvent | KeyboardEvent): void {
    this.describedBy = tooltipId;
    this.messageBusService.to(MessageBusChannels.tooltipShow, { text: this.tooltipValue, event: event });
    this.cd.detectChanges();
    // console.log('>>> aria-describedby added')
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('document:keydown.escape')
  public onDeactive(): void {
    this.describedBy = null;
    this.messageBusService.to(MessageBusChannels.tooltipHide, '');
    this.cd.detectChanges();
    // console.log('>>> aria-describedby removed')
  }
}
