export enum $scanOptions {
  _id = '_id',
  isDefaultOptions = 'isDefaultOptions',
  accessibility = 'accessibility',
  crawlDeepLevel = 'crawlDeepLevel',
  includeUrls = 'includeUrls',
  excludeUrls = 'excludeUrls',
  excludeUrlOperations = 'excludeUrlOperations',
  headers = 'headers',
  languageForScanning = 'languageForScanning',
  limitCrawlingPagesTo = 'limitCrawlingPagesTo',
  name = 'name',
  repeatEvery = 'repeatEvery',
  scanningType = 'scanningType',
  scanRecurrenceType = 'scanRecurrenceType',
  scanRecurrenceEvery = 'scanRecurrenceEvery',
  scanRecurrenceSelectedDay = 'scanRecurrenceSelectedDay',
  scanMonthlyOnDay = 'scanMonthlyOnDay',
  sendEmail = 'sendEmail',
  startScanAtDate = 'startScanAtDate',
  stopScanAtDate = 'stopScanAtDate',
  tag = 'tag',
  url = 'url',
  userId = 'userId',
  skipHashes = 'skipHashes',
  skipQueryParams = 'skipQueryParams',
  unlimitedCrawlDeepLevel = 'unlimitedCrawlDeepLevel',
  unlimitedCrawlingPagesTo = 'unlimitedCrawlingPagesTo',
  userAgent = 'userAgent',
  defaultScanOptions = 'defaultScanOptions',
  colorBlindEmulation = 'colorBlindEmulation',
  essentialBestPractice = 'essentialBestPractice',
  selectedScanOptionsId = 'selectedScanOptionsId',
  conformanceLevel = 'conformanceLevel',
}

export enum scanOptionsFormFields {
  $defaultScanOptions = 'defaultScanOptions',
  $addScanOptions = 'addScanOptions',
}
