<div *ngIf="form" [formGroup]="form">
  <div class="row" *ngIf="context.label">
    <div class="col-12">
      <label [for]="id" [class.required]="required" class="mb-1 text-nowrap">
        {{ context.label }}
      </label>
      <ng-content select=".uploader-warning"></ng-content>
    </div>
  </div>
  <div class="row">
    <div class="col-12 center">
      <div
        class="drag-area"
        [class.drag-active]="dragActive"
        [attr.required]="context.required ? true : null"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDragDrop($event)"
        [class.error-highlight]="errorHighlight"
      >
        <button
          type="button"
          class="uploader-button"
          [disabled]="uploadDisabled"
          [attr.required]="context.required ? true : null"
          [attr.aria-controls]="id"
          [attr.aria-label]="ariaLabel | translate"
          (click)="nativeInput.click()"
        >
          {{ (multiple ? 'select_or_drag_and_drop_files' : 'select_or_drag_and_drop_file') | translate }}
        </button>
      </div>
      <input
        #nativeInput
        type="file"
        class="form-element d-none"
        [id]="id"
        [multiple]="multiple"
        [accept]="acceptedExtensions"
        [formControlName]="context.field"
        (change)="onChange($event)"
      />
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-12">
      <ng-content select=".uploader-description"></ng-content>
    </div>
  </div>
  <div aria-live="polite">
    <div class="row flex-column mt-3" *ngIf="displayTable && tableConfig && tableData.length > 0">
      <div class="col-12">
        <span class="label mb-1">
          {{ context.tableTitle }}
        </span>
      </div>
      <div class="col-12">
        <app-ngb-table [config]="tableConfig" [data]="tableData" (onSort)="sortTable($event)" class="files-table">
        </app-ngb-table>
      </div>
      <div class="col-12">
        <div *ngIf="maxTotalFileSizeExceeded" class="d-flex justify-content-between alert-danger px-3 py-2">
          <span>
            {{ 'document_total_file_size' | translate }}:
            {{ getFormattedFileSize(totalFileSize) }}
          </span>
          <span>
            {{ '> ' + getFormattedFileSize(maxTotalFileSize) }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div aria-live="polite" role="status" [id]="id + '_error'" class="error-container">
        <app-form-field-error
          [formValidationRequest$]="formValidationRequest$"
          [field]="form.get(context.field)"
          [name]="context.field"
          [label]="context.label"
          [customMessage]="context.customMessage"
          [overrideErrors]="context.overrideErrors"
          [distinctChanges]="false"
        ></app-form-field-error>
      </div>
    </div>
  </div>
</div>
