import { Observable, of, from } from 'rxjs';
import { Params, QueryParamsHandling, ResolveFn } from '@angular/router';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type BreadcrumbParamsHandling = QueryParamsHandling | 'none';

export interface IBreadcrumb {
  text: string;
  textTranslated?: string;
  path: string;
  isLinkToPageAvailable: boolean;
  queryParamsHandling: BreadcrumbParamsHandling;
  queryParams: Params;
}

// Breadcrumbs resolvers should implement this interface
export interface IBreadcrumbsResolver {
  resolve: ResolveFn<IBreadcrumb[]>;
}

export function isPromise(value: any): boolean {
  return value && typeof value.then === 'function';
}

export function wrapIntoObservable<T>(value: T | Promise<T> | Observable<T>): Observable<T> {
  if (value instanceof Observable) {
    return value;
  }

  if (isPromise(value)) {
    return from(Promise.resolve(value));
  }

  return of(value as T);
}
