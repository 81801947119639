import { Component, Input, Renderer2 } from '@angular/core';
import { CommonUtility } from '../../../../../utility/common.utility';
import { BaseCellComponent, ICellConfig, IIconConfig } from '../base-cell/base-cell.component';

export interface ITextWithIconCellConfig extends ICellConfig {
  icon?: IIconConfig;
  iconText?: string;
  iconTextStyles?: Partial<Record<keyof CSSStyleDeclaration, string>>;
}

@Component({
  selector: 'table-cell-text-with-icon-text',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <ng-template #icon>
      <svg
        [attr.aria-hidden]="!config.icon.iconAriaLabelText"
        [attr.width]="config.icon.iconWidth"
        [attr.height]="config.icon.iconHeight"
        [ngStyle]="config.icon.iconStyles"
        [attr.aria-labelledby]="!!config.icon.iconAriaLabelText ? iconTitleId : null"
        #svgTemplate
      >
        <title *ngIf="!!config.icon.iconAriaLabelText" [id]="iconTitleId">
          {{ config.icon.iconAriaLabelText }}
        </title>
        <use [attr.xlink:href]="'#' + config.icon.iconName"></use>
      </svg>
    </ng-template>
    <span #ref [ngStyle]="config?.styles">{{ config.text }}</span>
    <div class="text-with-icon-cell">
      <ng-container *ngIf="config.icon && config.icon.iconBeforeText">
        <ng-template [ngTemplateOutlet]="icon"></ng-template>
      </ng-container>
      <span #ref [ngStyle]="config?.iconTextStyles">{{ config.iconText }}</span>
      <ng-container *ngIf="config.icon && !config.icon.iconBeforeText">
        <ng-template [ngTemplateOutlet]="icon"></ng-template>
      </ng-container>
    </div>
  `,
})
export class TextWithIconCellComponent extends BaseCellComponent {
  @Input() public config: ITextWithIconCellConfig;
  public iconTitleId: string;

  public constructor(renderer: Renderer2) {
    super(renderer);
    this.iconTitleId = CommonUtility.createUniqueDOMId('iconTitle');
  }
}
