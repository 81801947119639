import { Injectable } from '@angular/core';

import { WindowRef } from './window.ref';

@Injectable()
export class CookieService {
  private isCookieEnabled: boolean;

  constructor(private windowRef: WindowRef) {
    this.isCookieEnabled = this.isEnabled();
  }

  public set(name: string, value: string, expires?: any, domain?: string, path?: string): void {
    let cookieData: string =
      this.windowRef.windowObject.encodeURIComponent(name) + '=' + this.windowRef.windowObject.encodeURIComponent(value);
    let dateExpires: Date = new Date();

    if (expires && typeof expires === 'number') {
      dateExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
    } else {
      dateExpires = new Date();
      dateExpires.setFullYear(dateExpires.getFullYear() + 10);
    }

    cookieData += '; expires=' + dateExpires.toUTCString();

    if (typeof path === 'string') {
      cookieData += '; path=' + path;
    } else {
      cookieData += '; path=/;';
    }

    if (typeof domain === 'string') {
      cookieData += '; domain=' + domain + ';';
    }

    this.windowRef.windowObject.document.cookie = cookieData;
  }

  public get(name: string): string | null {
    const cookieName = this.windowRef.windowObject.encodeURIComponent(name);
    const nameLength = cookieName.length + 1;

    const trimValue = (str: string): string => {
      return str.trim();
    };

    const myCookie = (cookie: string): boolean => {
      return cookie.substring(0, nameLength) === `${cookieName}=`;
    };

    const getCookie = (cookie: string): string => {
      return this.windowRef.windowObject.decodeURIComponent(cookie.substring(nameLength));
    };

    const value = this.windowRef.windowObject.document.cookie.split(';').map(trimValue).filter(myCookie).map(getCookie);

    return value[0] || null;
  }

  public remove(name: string): void {
    this.set(name, '', -1);
  }

  public isEnabled(): boolean {
    if (typeof this.isCookieEnabled === 'boolean') {
      return this.isCookieEnabled;
    }

    const TEST_VALUE = '1';

    this.set('_cookietest', TEST_VALUE);
    this.isCookieEnabled = this.get('_cookietest') === TEST_VALUE;
    this.remove('_cookietest');
    // TODO - change line to `return this.isCookieEnabled;`
    return undefined;
  }

  public deleteAll(): void {
    const cookies = this.windowRef.windowObject.document.cookie.split(';');

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this.windowRef.windowObject.document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
}
