import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Observable, Subscription } from 'rxjs';

import { A11yService } from '../../services/a11y.service';
import { ModalService } from '../../services/modal.service';
import { ErrorMessageService } from '../../services/error-message.service';
import { TranslateService } from '../../translate/translate.service';
import { AuditRuleService } from '../../services/audit-rule.service';
import { CustomValidators } from '../../services/helpers/form-custom-validators';
import { IModalDialogInputData } from '../../interfaces/modal-dialog.interface';
import { IRuleAuditHistoryLineItem } from '../../../../shared/interfaces/rule-audit-history.interface';
import { IModal } from '../modals/modal.interface';
import { ModalContainerComponent } from '../modals/modal-container.component';
import { errorMessagesNames } from '../../../../shared/constants/errors';

@Component({
  selector: 'app-rule-status-change',
  templateUrl: './rule-status-change.component.html',
  styleUrls: ['./rule-status-change.component.scss'],
})
export class RuleStatusChangeComponent implements IModal, OnDestroy {
  private subscription: Subscription;
  private formValidationRequest: Subject<void>;

  @Input() public modalInputData: IModalDialogInputData;

  @Output() public onSave: EventEmitter<IRuleAuditHistoryLineItem>;
  @Output() public onCancel: EventEmitter<void>;

  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  public form: UntypedFormGroup;
  public formValidationRequest$: Observable<void>;

  constructor(
    private a11yService: A11yService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService,
    private auditRuleService: AuditRuleService,
    private element: ElementRef<Element>,
  ) {
    this.subscription = new Subscription();
    this.formValidationRequest = new Subject<void>();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();

    this.onSave = new EventEmitter<IRuleAuditHistoryLineItem>();
    this.onCancel = new EventEmitter<void>();

    this.createForm();
  }

  private createForm(): void {
    const formConfig = {
      comment: new UntypedFormControl('', {
        validators: [CustomValidators.validateIsEmpty, Validators.required],
        updateOn: 'blur',
      }),
    };
    this.form = this.formBuilder.group(formConfig);
  }

  private handleError(message: string, response: HttpErrorResponse): void {
    const errorMessage: string = this.errorMessageService.getAppErrorResponse(response);
    const appMessage: string = this.translateService.instant(message);

    this.a11yService.setMessage(appMessage);

    console.error('[RuleStatusChange.handleError] ' + appMessage, response, errorMessage);
  }

  private onChangeRuleStatusError(response: HttpErrorResponse): void {
    this.handleError('error_action_cant_be_performed', response);

    if (response?.error?.app?.name === errorMessagesNames.UserRoleNotDefined) {
      this.modalService.closeModal();
    }
  }

  public isBulkOperation(ruleId?: string | string[]): boolean {
    return Array.isArray(ruleId);
  }

  public closeModal(res?: IRuleAuditHistoryLineItem): void {
    if (typeof res !== 'undefined') {
      this.onSave.emit(res);
    } else {
      this.onCancel.emit();
    }
    this.modalService.closeModal();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async changeRuleStatus(): Promise<void> {
    this.formValidationRequest.next();

    if (this.form.valid) {
      this.subscription.add(
        this.auditRuleService
          .toggleAuditRule(
            this.isBulkOperation(this.modalInputData.data.ruleId)
              ? this.modalInputData.data.ruleId.join(',')
              : this.modalInputData.data.ruleId,
            this.modalInputData.data.enabled,
            this.form.get('comment').value,
            this.isBulkOperation(this.modalInputData.data.ruleId),
          )
          .subscribe({
            next: this.closeModal.bind(this),
            error: this.onChangeRuleStatusError.bind(this),
          }),
      );
    } else {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
    }
  }
}
