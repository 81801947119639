<div *ngIf="showSignupErrorBanner" class="mb-4">
  <app-edit-permissions-banner
    [bannerType]="BannerType.error"
    [scrollInto]="true"
    (onClose)="closeSignupErrorBanner()"
    
  >
    <ng-container *ngIf="signUpErrorMessage; else messageWithLoginLink">
      {{ signUpErrorMessage }}
    </ng-container>
    <ng-template #messageWithLoginLink>
      {{ 'account_with_email_already_exists' | translate }}
      <ds-link [rtLink]="'/' + Api.login">{{ 'login_to_your_account' | translate }}</ds-link>
    </ng-template>
  </app-edit-permissions-banner>
</div>

<form [formGroup]="form">
  <fieldset>
    <legend class="visuallyhidden">{{ 'sign_up_form' | translate }}</legend>
    <div class="mb-4">
      {{ 'form-field-marked-as-required' | translate }}
    </div>

    <div class="row mb-4">
      <div class="col-md-6 mb-4 mb-md-0">
        <ds-input
          [label]="FormField.FIRST_NAME | translate"
          [errorMessage]="firstNameError$ | async"
          [errorState]="(firstNameError$ | async)?.length > 0"
          [required]="true"
          
        >
          <input ds-input-field type="text" [formControlName]="FormField.FIRST_NAME" />
        </ds-input>
      </div>
      <div class="col-md-6">
        <ds-input
          [label]="FormField.LAST_NAME | translate"
          [errorMessage]="lastNameError$ | async"
          [errorState]="(lastNameError$ | async)?.length > 0"
          [required]="true"
          
        >
          <input ds-input-field type="text" [formControlName]="FormField.LAST_NAME" />
        </ds-input>
      </div>
    </div>

    <div class="mb-4">
      <ds-input [label]="FormField.EMAIL | translate" [state]="emailInputState" >
        <input ds-input-field type="email" [formControlName]="FormField.EMAIL" />
      </ds-input>
    </div>

    <div class="mb-4">
      <ds-input
        [label]="'sign_up_role_label' | translate"
        [errorMessage]="roleError$ | async"
        [errorState]="(roleError$ | async)?.length > 0"
        [required]="true"
        
      >
        <select ds-input-field [formControlName]="FormField.ROLE">
          <option value="" disabled selected>{{ 'select_a_role' | translate }}</option>
          <option *ngFor="let userRole of userRoles$ | async" [value]="userRole._id">
            {{ userRole.translationKey | translate }}
          </option>
        </select>
      </ds-input>
    </div>

    <ng-container *ngIf="externalAuthEnabled === false">
      <div class="mb-4">
        <app-change-password-from-signup
          [emailToken]="confirmEmailToken"
          [formChangePassword]="form"
        ></app-change-password-from-signup>
      </div>
    </ng-container>

    <div>
      <button type="button" ds-button [variant]="DsButtonVariants.primary" (click)="onSubmit()" >
        {{ 'sign_up' | translate }}
      </button>
    </div>
  </fieldset>
</form>
