import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IFilterOption, IFilterOutput } from '../filter.interface';
import { CommonUtility } from '../../../utility/common.utility';

const initialValue: unknown = {};

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
})
export class FilterSelectComponent<T> implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  private _value: T;
  @Input() public label: string;
  @Input() public field: string;
  @Input() public options: IFilterOption<T>[] | null;
  @Input() public form: UntypedFormGroup;
  @Output() public valueChange: EventEmitter<IFilterOutput<T>>;
  public output: IFilterOutput<T>;
  public domID: string;

  @Input() public set value(val: T) {
    this._value = val;
    this.initForm(val);
    this.form.get(this.field).setValue(val);
  }

  public constructor() {
    this.label = '';
    this.field = '';
    this._value = initialValue as T;
    this.valueChange = new EventEmitter<IFilterOutput<T>>();
    this.domID = `${CommonUtility.createUniqueDOMId()}`;
    this.subscriptions = new Subscription();
  }

  public ngOnInit(): void {
    this.initForm(initialValue as T);
    this.subscriptions.add(
      this.form.get(this.field).valueChanges.subscribe((val: T): void => {
        this._value = val;
        this.output = { field: this.field, values: val };
        this.valueChange.emit(this.output);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initForm(val: T): void {
    if (!this.form) {
      this.form = new UntypedFormGroup({
        [this.field]: new UntypedFormControl({ value: val === initialValue ? undefined : this._value }),
      });
    } else if (val !== initialValue) {
      this.form.get(this.field).setValue(this._value);
    }
  }
}
