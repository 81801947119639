<div class="row">
  <div class="col-12 col-md-auto" *ngIf="isShareable">
    <button
      ds-button
      [variant]="DsButtonVariants.microActionHorizontalLayout"
      [microActionIcon]="Icons.ShareNodes"
      (click)="onShareReportRequest()"
      
    >
      {{ 'share_report' | translate }}
    </button>
  </div>
  <div class="col-12 col-md-auto mt-md-0">
    <ds-dropdown>
      <ds-dropdown-toggle
        [variant]="DsButtonVariants.microActionHorizontalLayout"
        [icon]="Icons.ArrowUpFromBracket"
        label="{{ 'action_export' | translate }}"
      >
      </ds-dropdown-toggle>
      <div ds-dropdown-menu>
        <ds-dropdown-item
          [icon]="FileTypeIcons[fileExtension]"
          *ngFor="let fileExtension of fileExtensions"
          
          (selected)="onDownloadReportRequest(fileExtension)"
          [value]="fileExtension"
          label="{{ 'download_report_as' | translate: fileExtension }}"
        >
        </ds-dropdown-item>
      </div>
    </ds-dropdown>
  </div>
</div>
