import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonUtility } from '../../../utility/common.utility';

const APP_ACCEPT_FILES_DEFAULT: string = `.xlsx,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;

@Component({
  selector: 'app-custom-file-input',
  templateUrl: './custom-file-input.component.html',
  styleUrls: ['./custom-file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFileInputComponent),
      multi: true,
    },
  ],
})
export class CustomFileInputComponent implements OnInit, ControlValueAccessor {
  private onChange: Function = () => {};
  private onTouched: Function = () => {};

  public disabled: boolean;
  public id: string;
  public selectedFiles: File[];
  public fileName: string;
  public filePath: string;

  @Input()
  public accept: string;
  @Input()
  public btnClass: string;
  @ViewChild('nativeInput') public nativeInputElement: ElementRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    this.accept = APP_ACCEPT_FILES_DEFAULT;
    this.fileName = '';
    this.filePath = '';
  }

  private reset(): void {
    this.fileName = '';
    this.filePath = '';
  }

  public ngOnInit(): void {
    const id = CommonUtility.createUniqueDOMId();
    this.id = `file_input_${id}`;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @Input('disabled')
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  public writeValue(obj: File[]): void {
    if (obj === null) {
      this.reset();
    }
    this.selectedFiles = obj;
    this.changeDetectorRef.detectChanges();
  }

  public onFilesSelected(event: Event | InputEvent): void {
    const files: FileList = (event.target as HTMLInputElement).files;
    this.setFiles(Array.from(files));
  }

  public setFiles(files: File[]): void {
    if (files.length > 0) {
      this.selectedFiles = files;
      this.fileName = this.selectedFiles[0].name;
      this.onTouched();
      this.onChange(this.selectedFiles);
      this.changeDetectorRef.detectChanges();
    } else {
      this.reset();
    }
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    this.setFiles(Array.from(files));
  }

  @HostListener('keydown.enter', ['$event'])
  public onEnterKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.renderer.selectRootElement(`#${this.id}`, true).click();
  }
}
