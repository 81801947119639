export enum $workspaceRole {
  _id = '_id',
  createdAt = 'createdAt',
  description = 'description',
  name = 'name',
  permissions = 'permissions',
  roleOrigin = 'roleOrigin',
  updatedAt = 'updatedAt',
  workspace = 'workspace',
  workspacesUserId = 'workspacesUserId',
}

export enum workspaceRoleOrigin {
  custom = 'custom',
  predefined = 'predefined',
  deprecated = 'deprecated',
}
