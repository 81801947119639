import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from '../services/form.service';

@Pipe({
  name: 'fieldInvalid',
  pure: false,
})
export class FieldInvalidPipe implements PipeTransform {
  public constructor(private formService: FormService) {}

  public transform(value: UntypedFormGroup, field: string): boolean {
    return this.formService.isFieldValid(field, value) === false;
  }
}
