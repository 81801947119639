<ng-container *ngIf="shortLinkNotFound">
  <div class="row">
    <div class="col-12">
      <div class="card short-link-block">
        <div class="card-body my-5">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center">
              <svg aria-hidden="true" width="113" height="80" fill="none">
                <use xlink:href="#error-icon"></use>
              </svg>
              <h2>{{ 'incorrect_or_expired_short_link' | translate }}</h2>
              <span>{{ 'check_web_address' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
