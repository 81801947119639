import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { catchError, filter, map } from 'rxjs/operators';
import { DigitalPropertyService } from '../../digital-property.service';
import { Api } from '../../../../../shared/constants/api';
import { IDigitalPropertyListItem, IDigitalPropertyType } from '../../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty, $digitalPropertyType } from '../../../../../shared/constants/digital-properties';
import { UserService } from '../../user.service';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';
import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { $user } from '../../../../../shared/constants/user';

export const WebDigitalPropertyGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
  const digitalPropertyService: DigitalPropertyService = inject(DigitalPropertyService);
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);
  const forbiddenPage: UrlTree = router.parseUrl(Api.forbidden);

  // property data should be defined by LinkedPropertyDataService during NavigationStart event
  const propertyData: ILinkedPropertyData = LinkedPropertyUtility.fromLinkedPropertyQueryParam(route.queryParams);

  return combineLatest([
    digitalPropertyService.getDigitalPropertyTypes(),
    userService.userDataChanged$.pipe(filter(SharedCommonUtility.notNullish)),
  ]).pipe(
    map(([digitalPropertyTypes, user]: [IDigitalPropertyType[], IUserServerResponse]) => {
      const digitalProperty: IDigitalPropertyListItem = user[$user.digitalProperties].find(
        (property: IDigitalPropertyType) => property[$digitalProperty._id] === propertyData[Api.digitalPropertyId],
      );

      if (SharedCommonUtility.isNullish(digitalProperty)) {
        return forbiddenPage;
      }

      const digitalPropertyType: IDigitalPropertyType = digitalPropertyTypes.find(
        (type: IDigitalPropertyType) => type[$digitalPropertyType._id] === digitalProperty[$digitalProperty.typeId],
      );

      return digitalPropertyService.isWebsiteType(digitalPropertyType) || forbiddenPage;
    }),
    catchError(() => of(forbiddenPage)),
  );
};
