import { Component, Input, ViewChild } from '@angular/core';
import { InfoModalOptions } from '../../../interfaces/modal-dialog.interface';
import { ModalContainerComponent } from '../modal-container.component';
import { IModal } from '../modal.interface';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements IModal {
  @ViewChild(ModalContainerComponent, { static: true })
  container: ModalContainerComponent;

  @Input() public options: InfoModalOptions;

  public init(options: InfoModalOptions): void {
    const defaultOptions: Partial<InfoModalOptions> = {
      buttonKey: 'label_close',
    };
    this.options = {
      ...defaultOptions,
      ...options,
    };
  }
}
