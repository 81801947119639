import { EChartsOption } from 'echarts/types/dist/shared';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';

import { CommonUtility } from '../../utility/common.utility';

type ScoreLevel = 'high' | 'low' | 'medium';

@Component({
  selector: 'app-scan-score-circle',
  templateUrl: './scan-score-circle.component.html',
  styleUrls: ['./scan-score-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanScoreCircleComponent implements AfterViewInit {
  private pieChart: echarts.EChartsType;

  @Input()
  public score: number;
  public uid: string;

  constructor() {
    this.uid = CommonUtility.createUniqueDOMId();
  }

  public drawScanScoreChart(): void {
    const option: EChartsOption = {
      series: {
        type: 'pie',
        radius: [18, 26],
        left: 'center',
        animation: false,
        silent: true,
        itemStyle: {
          borderColor: '#FFFFFF',
          borderWidth: 0,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: this.score,
          },
          {
            value: 100 - this.score,
          },
        ],
      },
    };

    echarts.use([PieChart, SVGRenderer]);
    const elChart: HTMLDivElement = document.getElementById(this.uid) as HTMLDivElement;
    this.pieChart = echarts.init(elChart);
    this.pieChart.setOption(option);
  }

  public get scoreLevel(): ScoreLevel {
    if (this.score < 50) {
      return 'low';
    }
    if (this.score < 80) {
      return 'medium';
    }
    return 'high';
  }

  public ngAfterViewInit(): void {
    this.drawScanScoreChart();
  }
}
