<div class="row justify-content-center">
  <div class="col-12">
    <span class="visually-hidden" id="riskManagementStatusChartAriaLabel">
      {{ 'websites_apps_by_risk_management_status_description' | translate }}
    </span>
    <ds-link
      standalone="true"
      class="d-inline-flex"
      href="#websites-apps-by-risk-management-status-table"
      
      (click)="openTableModal($event)"
    >
      <span class="visually-hidden">{{ 'view_websites_apps_by_risk_management_status_table' | translate }}</span>
      <span aria-hidden="true">{{ 'view_table_link' | translate }}</span>
    </ds-link>
    <div id="riskManagementStatusChart" aria-hidden="true" class="graph w-100" (window:resize)="chart.resize()"></div>
  </div>
</div>
