import { Directive, Input, EventEmitter, ElementRef, Inject, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Directive({
  selector: '[appFocus]',
})
export class FocusDirective implements OnInit, OnDestroy {
  @Input('appFocus') focusEvent: EventEmitter<void>;

  private subscriptions: Subscription;

  constructor(@Inject(ElementRef) private element: ElementRef) {
    this.subscriptions = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.focusEvent?.pipe(delay(0)).subscribe((): void => {
        this.element.nativeElement.focus();
      }),
    );
  }
}
