function getRandomCharSecure(method: (buffer: ArrayBufferView) => ArrayBufferView): string {
  const legalCharacters: string = '1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
  const characterRange: number = legalCharacters.length;
  const MAX_UNSIGNED_32_BIT_INT: number = 2 ** 32 - 1;
  const quotient: number = Math.floor(MAX_UNSIGNED_32_BIT_INT / characterRange);
  const modulo: number = MAX_UNSIGNED_32_BIT_INT % characterRange;
  const randomNumber: number = method(new Uint32Array(1))[0];
  if (randomNumber >= MAX_UNSIGNED_32_BIT_INT - modulo) {
    return getRandomCharSecure(method);
  }
  return legalCharacters[Math.floor(randomNumber / quotient)];
}
/*
 * Math.random() is not secure. See EAP-9923 or look up PRNGs vs CSPRNG
 * Node has CSPRNGs implemented in their crypto module, while the browser has the crypto module in window.crypto
 * So this is a helper method that uses one of these two implementations to create a random case-sensitive alphanumeric
 */
export function _getRandomSecureString(method: (buffer: ArrayBufferView) => ArrayBufferView, length: number = 16): string {
  return [...Array(length)].map(getRandomCharSecure.bind(this, method)).join('');
}
