import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private skip: number = 0;
  private loaderMap: Map<string, boolean> = new Map<string, boolean>();

  public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  public skipNext(skip: number = 1): void {
    this.skip = skip;
  }

  public setKeyLoading(loading: boolean, key: string): void {
    if (loading && this.skip > 0) {
      this.skip = this.skip - 1;
      return;
    }

    if (SharedCommonUtility.isNullishOrEmpty(key?.trim())) {
      console.error('[LoaderService] key not provided to LoaderService.setKeyLoading');
      return;
    }

    if (loading === true) {
      this.loaderMap.set(key, loading);
      this.loader.next(true);
    } else if (loading === false || this.loaderMap.has(key)) {
      this.loaderMap.delete(key);
    }

    if (this.loaderMap.size === 0) {
      this.loader.next(false);
    }
  }
}
