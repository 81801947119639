import { Component, Input } from '@angular/core';
import { ITableColumn, ITableConfig, ITableRow } from '../table/ngb-table/utilities/ngb-table.interface';
import { NgbTableUtilities } from '../table/ngb-table/utilities/ngb-table.utilities';
import { TranslateService } from '../../translate/translate.service';
import { IDigitalPropertyMonitoringUserAlertsWithEmail } from '../../../../shared/interfaces/digital-property.interface';
import {
  $digitalPropertyMonitoringAlerts,
  $digitalPropertyMonitoringAlertType,
} from '../../../../shared/constants/digital-properties';

enum $alertTableFields {
  recipient = 'recipient',
  alerts = 'alerts',
}

@Component({
  selector: 'app-monitoring-alert-table',
  templateUrl: './monitoring-alert-table.component.html',
})
export class MonitoringAlertTableComponent {
  @Input() public alerts: IDigitalPropertyMonitoringUserAlertsWithEmail[] = [];

  public alertsTableConfig: ITableConfig;

  constructor(private translateService: TranslateService) {
    this.getAlertsTableConfig();
  }

  private getAlertsTableConfig(): void {
    const columns: Record<$alertTableFields, ITableColumn> = {
      [$alertTableFields.recipient]: {
        translationKey: 'recipient_s_email',
        sortingEnabled: false,
      },
      [$alertTableFields.alerts]: {
        translationKey: 'label_monitoring_alerts',
        sortingEnabled: false,
      },
    };

    this.alertsTableConfig = {
      columns,
      caption: this.translateService.instant('current_alerts'),
    };
  }

  private getSelectedAlertsText(alert: IDigitalPropertyMonitoringUserAlertsWithEmail): string {
    return Object.keys($digitalPropertyMonitoringAlertType)
      .filter(
        (alertType: $digitalPropertyMonitoringAlertType): boolean =>
          alert[$digitalPropertyMonitoringAlerts.alerts][alertType]?.set,
      )
      .map((alertType: $digitalPropertyMonitoringAlertType): string => this.translateService.instant(alertType))
      .join(', ');
  }

  public get alertsTableData(): ITableRow[] {
    if (Array.isArray(this.alerts)) {
      return this.alerts.map(
        (value: IDigitalPropertyMonitoringUserAlertsWithEmail): ITableRow => ({
          data: {
            [$alertTableFields.recipient]: NgbTableUtilities.textCell({
              text: value[$digitalPropertyMonitoringAlerts.email],
            }),
            [$alertTableFields.alerts]: NgbTableUtilities.textCell({ text: this.getSelectedAlertsText(value) }),
          },
        }),
      );
    }

    return [];
  }
}
