import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { VariationValue } from '@harnessio/ff-javascript-client-sdk';

import { FeatureFlagService } from './feature-flag.service';
import { ActivatedRouteData } from '../../shared/constants';
import { FeatureFlagCollection } from '../../../../shared/interfaces/feature-flag.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

interface IRedirectionConfig {
  featureFlag: FeatureFlagCollection;
  route: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagRedirectionGuard {
  /**
   * This class is used to apply redirections to new routes based on feature flags.
   * If the provided feature flag serves true the router will redirect to the provided route.
   *
   * @param featureFlagService
   * @param router
   */
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<UrlTree | boolean> | boolean {
    const redirectionConfig: IRedirectionConfig = route.data[ActivatedRouteData.redirectTo];

    if (!SharedCommonUtility.elementsNotNullish([redirectionConfig?.featureFlag, redirectionConfig?.route])) {
      console.warn('[FeatureFlagRedirectionGuard.canActivate] A feature flag and a redirect url must be provided.');
      return true;
    }

    return this.featureFlagService.variation$(redirectionConfig.featureFlag, false).pipe(
      map((value: VariationValue): UrlTree | boolean => {
        if (value) {
          const activatedRoute: string = route.url.toString();
          const completeUrl: string = routerState.url;
          const newRedirectUrl: string =
            redirectionConfig.route + completeUrl.substring(completeUrl.indexOf(activatedRoute) + activatedRoute.length);

          return this.router.parseUrl(newRedirectUrl);
        }

        return true;
      }),
    );
  }
}
