import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { $ruleAuditHistory, RuleAuditHistoryType } from '../../../../../shared/constants/rule-audit-history';
import { IRuleAuditHistoryLineItem } from '../../../../../shared/interfaces/rule-audit-history.interface';
import { RuleAuditHistoryService } from '../../../services/rule-audit-history.service';
import { AuditToolNames } from '../../../../../shared/constants/audit-tool';

@Component({
  selector: 'app-view-rule-history',
  templateUrl: './view-singular-rule-history.component.html',
  styleUrls: ['./view-singular-rule-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSingularRuleHistoryComponent {
  private type: RuleAuditHistoryType;
  @Input()
  public rule: string;
  @Input()
  public ruleTitle: string;
  @Input()
  public url: string;
  @Input()
  public selector: string;
  @Input()
  public toolName: AuditToolNames;
  @Input()
  public historyInput: IRuleAuditHistoryLineItem[];
  @Input()
  public scanId: string;
  public history: IRuleAuditHistoryLineItem[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private service: RuleAuditHistoryService,
  ) {
    this.history = [];
    this.type = RuleAuditHistoryType.rule;
  }

  public async loadHistory(): Promise<void> {
    this.history = await (Array.isArray(this.historyInput)
      ? Promise.resolve(this.historyInput)
      : this.service
          .getHistory(this.toolName, this.rule, this.type, {
            [$ruleAuditHistory.url]: this.url,
            [$ruleAuditHistory.selectors]: this.selector,
            [$ruleAuditHistory.dataId]: this.scanId,
          })
          .toPromise());

    this.changeDetectorRef.detectChanges();
  }
}
