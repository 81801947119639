import { inject } from '@angular/core';
import { Router, CanActivateFn, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Api } from '../../../../../shared/constants/api';
import { AppConfigService } from '../../app-config.service';

export const TenantGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const appConfigService: AppConfigService = inject(AppConfigService);

  const hasAccess: boolean = appConfigService.isTenantEnabled();
  return of(hasAccess || router.parseUrl(Api.forbidden));
};
