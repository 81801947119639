<nav [attr.aria-label]="'label_breadcrumbs' | translate">
  <ol *ngIf="crumbs.length > 1" class="breadcrumb">
    <li *ngFor="let crumb of crumbs; let last = last" [ngClass]="{ active: last }" class="breadcrumb-item">
      <a
        *ngIf="!last && crumb.isLinkToPageAvailable"
        [routerLink]="crumb.path"
        [queryParams]="crumb.queryParams"
        [queryParamsHandling]="crumb.queryParamsHandling"
      >
        <ng-template
          [ngTemplateOutlet]="crumb.textTranslated ? textTranslated : text"
          [ngTemplateOutletContext]="{ crumb: crumb }"
        >
        </ng-template>
      </a>
      <span *ngIf="last || !crumb.isLinkToPageAvailable" [attr.aria-current]="last ? 'page' : null">
        <ng-template
          [ngTemplateOutlet]="crumb.textTranslated ? textTranslated : text"
          [ngTemplateOutletContext]="{ crumb: crumb }"
        >
        </ng-template>
      </span>
    </li>
  </ol>

  <div *ngIf="crumbs.length === 1" [attr.aria-hidden]="true" class="breadcrumb">
    <div class="breadcrumb-item active">
      <span>
        <ng-template
          [ngTemplateOutlet]="crumbs[0].textTranslated ? textTranslated : text"
          [ngTemplateOutletContext]="{ crumb: crumbs[0] }"
        >
        </ng-template>
      </span>
    </div>
  </div>

  <ng-template #textTranslated let-crumb="crumb">
    <ng-container *ngIf="crumb.textTranslated; else text">
      {{ crumb.textTranslated }}
    </ng-container>
  </ng-template>
  <ng-template #text let-crumb="crumb">
    <ng-container *ngIf="crumb.text"> {{ crumb.text | translate }} </ng-container>
  </ng-template>
</nav>
