import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { customHeaders } from '../../../../shared/constants/custom-headers';
import { TenantPackageService } from '../tenant-package.service';
import { PackageNames } from '../../../../shared/constants/packaging';

@Injectable()
export class TenantPackageInterceptor implements HttpInterceptor {
  constructor(private tenantPackageService: TenantPackageService) {}

  private handleTenantPackage(response: HttpResponse<any>): void {
    if (response.headers?.has(customHeaders.x_tenant_package)) {
      this.tenantPackageService.tenantPackage = response.headers.get(customHeaders.x_tenant_package) as PackageNames;
    }
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((response: HttpResponse<any>) => this.handleTenantPackage(response)));
  }
}
