import { Renderer2, Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';

export interface ITagConfig {
  tagLabel?: string;
  tagColor?: string;
  tagTooltip?: string;
}

export type IElementStyle = Partial<Record<keyof CSSStyleDeclaration, string>>;

export interface IElementConfig {
  classes?: string[];
  styles?: IElementStyle;
  wrapperStyles?: IElementStyle;
  attributes?: { [attribute: string]: any };
  listeners?: { [event: string]: (...args: any[]) => any };
}

export interface ICellConfig extends IElementConfig {
  text?: string;
  subText?: string;
  colSpan?: number;
  translate?: boolean;
}

export interface IIconConfig extends IElementConfig {
  iconName: string;
  iconWidth: string;
  iconHeight: string;
  iconStyles?: IElementStyle;
  iconBeforeText?: boolean;
  iconAriaLabelText?: string;
}

export function setupCell(renderer: Renderer2, config: IElementConfig, element: HTMLElement): void {
  if (config.attributes) {
    for (const attr of Object.keys(config.attributes)) {
      element.setAttribute(attr, config.attributes[attr]);
    }
  }

  if (config.classes) {
    for (const cssClass of config.classes) {
      renderer.addClass(element, cssClass);
    }
  }

  if (config.listeners && Object.keys(config.listeners).length > 0) {
    for (const event of Object.keys(config.listeners)) {
      element.addEventListener(event, config.listeners[event]);
    }
  }
}

@Component({
  selector: 'table-cell-base',
  template: '',
  styleUrls: ['./base-cell.component.scss'],
})
export class BaseCellComponent implements AfterViewInit {
  @ViewChild('ref')
  public element: ElementRef<HTMLElement>;

  @Input() public config: ICellConfig;

  constructor(protected renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    setupCell(this.renderer, this.config, this.element.nativeElement);
  }
}
