import { $severity, wcagSeverityWeight } from './accessibility';

export enum $designRule {
  _id = '_id',
  ruleId = 'ruleId',
  category = 'category',
  appliesTo = 'appliesTo',
  finding = 'finding',
  userExperienceDesignerWhatToDo = 'userExperienceDesignerWhatToDo',
  userInterfaceDesignerWhatToDo = 'userInterfaceDesignerWhatToDo',
  contentDesignerWhatToDo = 'contentDesignerWhatToDo',
  mediaDesignerWhatToDo = 'mediaDesignerWhatToDo',
  documentationBestPractices = 'documentationBestPractices',
  description = 'description',
  severity = 'severity',
  successCriteria = 'successCriteria',
  successCriterionShortDescription = 'successCriterionShortDescription',
  version = 'version',
  ruleLibrary = 'ruleLibrary',
  issueTypes = 'issueTypes',
  documentationRecommendation = 'documentationRecommendation',
  developmentRecommendation = 'developmentRecommendation',
  contentRecommendation = 'contentRecommendation',
  designFlaw = 'designFlaw',
  notDesigned = 'notDesigned',
  bestPractices = 'bestPractices',
  disabled = 'disabled',
  revision = 'revision',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  oldRevision = 'oldRevision',
}

export enum $designRuleRevision {
  ruleOid = 'ruleOid',
}

export enum DesignRuleLibrary {
  design = 'design',
}

export enum $designRulesResponse {
  designRules = 'designRules',
  _total = '_total',
}

export enum $designRuleIssueTypes {
  documentationRecommendation = 'documentationRecommendation',
  developmentRecommendation = 'developmentRecommendation',
  contentRecommendation = 'contentRecommendation',
  designFlaw = 'designFlaw',
  notDesigned = 'notDesigned',
  bestPractices = 'bestPractices',
}

export interface IIssueType {
  _id: string;
  name: string;
}

export enum $designRuleSearch {
  ruleIdOrKeyword = 'ruleIdOrKeyword',
  category = 'category',
}

export const DESIGN_RULE_NUMBER_OF_VERSION_SEGMENTS: number = 4;
export const DESIGN_RULE_ID_PATTERN: RegExp = /^(DE|DBP)_\d+\.\d+\.\d+\.\d+$/;
export const DESIGN_RULE_DISALLOWED_CHARS: RegExp = /\p{Extended_Pictographic}/gu;

export const MAX_CHAR_COUNT: Readonly<Partial<Record<$designRule, number>>> = {
  [$designRule.ruleId]: 25,
  [$designRule.successCriterionShortDescription]: 500,
  [$designRule.appliesTo]: 1000,
  [$designRule.finding]: 500,
  [$designRule.userExperienceDesignerWhatToDo]: 1000,
  [$designRule.userInterfaceDesignerWhatToDo]: 1000,
  [$designRule.contentDesignerWhatToDo]: 1000,
  [$designRule.mediaDesignerWhatToDo]: 1000,
  [$designRule.documentationBestPractices]: 1000,
};

export const MIN_CHAR_COUNT: Readonly<Partial<Record<$designRule, number>>> = {
  [$designRule.ruleId]: 10,
  [$designRule.successCriterionShortDescription]: 2,
  [$designRule.appliesTo]: 2,
  [$designRule.finding]: 2,
  [$designRule.userExperienceDesignerWhatToDo]: 2,
  [$designRule.userInterfaceDesignerWhatToDo]: 2,
  [$designRule.contentDesignerWhatToDo]: 2,
  [$designRule.mediaDesignerWhatToDo]: 2,
  [$designRule.documentationBestPractices]: 2,
};

export const DesignRuleIssueTypeLabels: { [key in keyof typeof $designRuleIssueTypes]: string } = Object.freeze({
  [$designRuleIssueTypes.documentationRecommendation]: 'documentation_recommendation',
  [$designRuleIssueTypes.developmentRecommendation]: 'development_recommendation',
  [$designRuleIssueTypes.contentRecommendation]: 'content_recommendation',
  [$designRuleIssueTypes.designFlaw]: 'design_flaw',
  [$designRuleIssueTypes.notDesigned]: 'not_designed',
  [$designRuleIssueTypes.bestPractices]: 'best_practices',
});

export const DesignRuleSeveritySortOrder: Partial<Record<$severity, number>> = wcagSeverityWeight;
