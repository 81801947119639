import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { VariationValue } from '@harnessio/ff-javascript-client-sdk';

import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { Api } from '../../../../shared/constants/api';
import { FeatureFlagService } from './feature-flag.service';
import { ActivatedRouteData } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  /**
   * This class is used to evaluate a flag against a specific route.
   *
   * @param featureFlagService
   * @param router
   * @param errorHandlerService
   */
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> | UrlTree {
    const featureFlag: string = route.data[ActivatedRouteData.featureFlag];
    const forbiddenPage: UrlTree = this.router.parseUrl(Api.forbidden);

    if (SharedCommonUtility.isNullish(featureFlag)) {
      console.warn('[FeatureFlagGuard.canActivate] A feature flag id must be provided.');
      return forbiddenPage;
    }

    return this.featureFlagService.variation$(featureFlag).pipe(
      map((value: VariationValue): UrlTree | boolean => {
        if (value) {
          return true;
        }

        return forbiddenPage;
      }),
    );
  }
}
