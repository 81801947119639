<button
  class="activity-log-opener btn btn-link"
  [ngbPopover]="popoverBody"
  [popoverTitle]="popoverTitle"
  #popover="ngbPopover"
  container="body"
  (shown)="loadHistory()"
  [autoClose]="'outside'"
  [popoverClass]="'history-popover-wrap'"
>
  <svg class="align-items-start icon-comment-bubble">
    <use xlink:href="#comment-bubble-ellipsis"></use>
  </svg>
  <span class="visuallyhidden">{{ 'label_show_rule_audit_history' | translate: ruleTitle }}</span>
</button>

<ng-template #popoverBody>
  <div class="history-timeline">
    <div *ngFor="let item of history">
      <app-view-rule-history-item [historyItem]="item" [expanded]="true"></app-view-rule-history-item>
    </div>
  </div>
</ng-template>

<ng-template #popoverTitle>
  <div class="history-timeline-title py-3">
    <h2 class="mb-0">
      {{ 'label_activity_log' | translate }}
    </h2>
    <button class="btn btn-link p-0" (click)="popover.close()">
      <svg aria-hidden="true" focusable="false" viewBox="0 0 8 8"><use href="#cross-solid"></use></svg>
      <span class="visuallyhidden">{{ 'close_modal_dialog' | translate }}</span>
    </button>
  </div>
</ng-template>
