import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayReverse',
})
export class ArrayReversePipe implements PipeTransform {
  public transform(value: any[], condition: boolean = true): any[] {
    if (Array.isArray(value)) {
      return condition ? value.slice().reverse() : value;
    }
    return [];
  }
}
