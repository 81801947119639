<div class="mb-4">
  <div >
    {{ 'websites_apps_by_risk_management_status_details_description' | translate }}
  </div>
  <div >{{ computedDateDescription$ | async }}</div>
</div>

<ds-tabs (activeIdChange)="tabIdChange($event)" [tablistLabelledBy]="titleId">
  <ng-container *ngFor="let level of riskScoreLevels">
    <ds-tab [label]="tabLabels[level]" [heading]="tabLabels[level]">
      <ds-pagination
        *ngIf="collectionSize$ | async"
        class="d-block mb-3"
        [hidePaginationNavigation]="true"
        [resultsName]="'websites_apps' | translate"
        [collectionSize]="collectionSize$"
        [page]="page"
      >
      </ds-pagination>
      <app-ngb-table
        id="websites-apps-by-risk-management-status-details-table"
        (onSort)="sortTableData($event)"
        [config]="tableConfig"
        [data]="tableData"
      ></app-ngb-table>
      <ds-pagination
        *ngIf="collectionSize$ | async"
        (pageChange)="changePage($event)"
        [resultsName]="'websites_apps' | translate"
        [collectionSize]="collectionSize$"
        [page]="page"
      >
      </ds-pagination>
    </ds-tab>
  </ng-container>
</ds-tabs>
