import { Component, Input, OnInit } from '@angular/core';

import { DsButtonVariants, Icons } from '@levelaccess/design-system';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRiskMetricsChartConfig, IRiskMetricsChartData } from '../../interfaces/risk-metrics-chart.interface';
import { DashboardDataFrequency } from '../../../../shared/interfaces/ws-dashboard.interface';
import { $riskMetricsChartConfig } from '../../constants/risk-metrics-chart';
import { ITableEmptyState } from '../table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../../translate/translate.service';

@Component({
  selector: 'app-websites-apps-by-risk-management-status',
  templateUrl: './websites-apps-by-risk-management-status.component.html',
})
export class WebsitesAppsByRiskManagementStatusComponent implements OnInit {
  @Input()
  public set chartData(data: IRiskMetricsChartData[]) {
    this.chartData$.next(data);
  }

  @Input()
  public set frequency(freq: DashboardDataFrequency) {
    this.frequency$.next(freq);
  }

  @Input()
  public showHeader: boolean;

  public Icons: typeof Icons = Icons;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public chartConfig$: Observable<IRiskMetricsChartConfig>;
  public emptyStateConfig$: Observable<ITableEmptyState>;

  private chartData$: BehaviorSubject<IRiskMetricsChartData[]> = new BehaviorSubject<IRiskMetricsChartData[]>(null);
  private frequency$: BehaviorSubject<DashboardDataFrequency> = new BehaviorSubject<DashboardDataFrequency>(null);

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.chartConfig$ = combineLatest([this.chartData$, this.frequency$]).pipe(
      map(([data, frequency]: [IRiskMetricsChartData[], DashboardDataFrequency]) => {
        return {
          [$riskMetricsChartConfig.data]: data,
          [$riskMetricsChartConfig.frequency]: frequency ?? DashboardDataFrequency.weekly,
        };
      }),
    );

    this.emptyStateConfig$ = this.frequency$.pipe(
      map((frequency: DashboardDataFrequency) => {
        return {
          iconId: 'empty-box',
          title: this.translateService.instant('websites_apps_by_risk_management_status_empty_state_title'),
          subtitle: this.getEmptyStateTranslationDescription(frequency),
        };
      }),
    );
  }

  private getEmptyStateTranslationDescription(frequency: DashboardDataFrequency): string {
    const key: string =
      frequency === DashboardDataFrequency.weekly
        ? 'websites_apps_by_risk_management_status_empty_state_subtitle'
        : 'websites_apps_by_risk_management_status_empty_state_frequency_subtitle';
    return this.translateService.instant(key);
  }
}
