<ds-modal
  [modal]="modal"
  [colorVariant]="DsModalColorVariant.Default"
  [title]="pageTitle | translate"
  [backNavLabel]="backNavLabel"
>
  <form [formGroup]="form">
    <div class="row mt-4">
      <div class="col-12">
        <div class="mb-3" *ngIf="bannerText$ | async as text">
          <app-banner  [type]="BannerType.info" [message]="text"></app-banner>
        </div>
        <ds-input
          [label]="'upsert_page_title' | translate"
          [required]="true"
          [errorMessage]="titleError$ | async"
          [errorState]="(titleError$ | async)?.length > 0"
        >
          <input
            ds-input-field
            [formControlName]="FormField.TITLE"
            [maxlength]="titleCharLimit"
            
          />
        </ds-input>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <ds-input
          [label]="'upsert_page_url' | translate"
          [instructions]="'upsert_page_url_instruction' | translate"
          [required]="requireURL"
          [errorMessage]="urlError$ | async"
          [errorState]="(urlError$ | async)?.length > 0"
          [state]="urlFieldState"
        >
          <input ds-input-field [formControlName]="FormField.URL"  />
        </ds-input>
        <div *ngIf="showUrlWarning" class="mt-2" >
          <app-edit-permissions-banner [bannerType]="BannerType.info" (onClose)="hideUrlWarning()">
            {{ 'upsert_page_duplicate_url_warning' | translate }}
          </app-edit-permissions-banner>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <ds-input
          [label]="'upsert_page_description' | translate"
          [required]="false"
          [errorMessage]="descriptionError$ | async"
          [errorState]="(descriptionError$ | async)?.length > 0"
        >
          <textarea
            ds-input-field
            [formControlName]="FormField.DESCRIPTION"
            [maxlength]="descriptionCharLimit"
            
          >
          </textarea>
        </ds-input>
      </div>
    </div>

    <ng-container *ngIf="screenshotOrigin; else screenshotNotAvailable">
      <div class="row mt-4">
        <div class="col-auto">
          <label for="upsert-page-remove-screenshot"  class="label text-nowrap">
            {{ 'screenshot' | translate }}
          </label>
        </div>
      </div>

      <div class="row mt-1" [ngSwitch]="screenshotOrigin">
        <div class="col-12" *ngSwitchCase="ScreenshotOrigin.UPLOADED">
          <img
            [appSecureResourceId]="screenshotData.toString()"
            [alt]="'screenshot_preview_alt' | translate"
            class="full-width"
          />
        </div>
        <div class="col-12" *ngSwitchCase="ScreenshotOrigin.NEW">
          <img [src]="screenshotData" [alt]="'screenshot_preview_alt' | translate" class="full-width" />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <button
            id="upsert-page-remove-screenshot"
            ds-button
            type="button"
            [variant]="DsButtonVariants.microAction"
            [microActionIcon]="Icons.TrashCan"
            (click)="removeScreenshot()"
          >
            {{ 'action_remove' | translate }}
          </button>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <ds-input
            [label]="'upsert_page_alt_text' | translate"
            [required]="true"
            [errorMessage]="altTextError$ | async"
            [errorState]="(altTextError$ | async)?.length > 0"
          >
            <input
              ds-input-field
              [formControlName]="FormField.ALT_TEXT"
              [maxlength]="altTextCharLimit"
              
            />
          </ds-input>
        </div>
      </div>
    </ng-container>

    <ng-template #screenshotNotAvailable>
      <div class="row mt-4">
        <div class="col-12">
          <label for="upsert-page-screenshot"  class="label text-nowrap">
            {{ 'screenshot' | translate }}
          </label>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <app-file-drag-and-drop
            id="upsert-page-screenshot"
            (fileChange)="onFileUpload($event)"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="fileUploadContext"
            [showDateAdded]="false"
            ariaLabel="select_or_drag_and_drop_file_to_upload_screenshot"
          ></app-file-drag-and-drop>
        </div>
      </div>
    </ng-template>
  </form>
  <!--
    HACKING the width to be about 50 rem
  -->
  <div style="max-width: 50rem">
    <div style="width: 50rem"></div>
  </div>

  <ng-template ds-modal-slide-out-footer>
    <ds-button-group
      [primaryAction]="{ label: submitButtonLabel | translate }"
      [secondAction]="{ label: 'form_cancel' | translate, variant: DsButtonVariants.secondary }"
      (primaryActionClicked)="submit()"
      (secondActionClicked)="cancel()"
    >
    </ds-button-group>
  </ng-template>
</ds-modal>
