import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RestBuilder } from '../helpers/rest.builder';
import {
  IUpsertSecurityGroup,
  ISecurityGroup,
  ISecurityGroupsResponse,
  ISecurityGroupWithUserIds,
  ISecurityGroupWithUsers,
} from '../../../../shared/interfaces/security-group.interface';
import { ApiQueryOption } from '../../../../shared/constants/api';
import { SecurityEntityLevel, SecurityGroupOrigin } from '../../../../shared/constants/security-group';
import { ISecurityGroupsQuery } from '../../../../shared/interfaces/security.interface';

@Injectable({
  providedIn: 'root',
})
export class SecurityGroupsApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /admin/security/groups
  public findSecurityGroups(
    limit: number,
    skip: number,
    origin?: SecurityGroupOrigin,
    entityLevel?: SecurityEntityLevel | SecurityEntityLevel[],
  ): Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> {
    const originParam = {
      [ApiQueryOption.origin]: origin,
    };
    const entityLevelParam = {
      [ApiQueryOption.entityLevel]: entityLevel,
    };
    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        ...(origin ? originParam : {}),
        ...(entityLevel ? entityLevelParam : {}),
      },
    });

    const url: string = this.restBuilder.create().admin().security().groups().query(queryParams).getApiUrl();

    return this.httpClient.get<ISecurityGroupsResponse<ISecurityGroupWithUserIds>>(url, {
      params: this.restBuilder.paginationParams(skip, limit),
    });
  }

  // GET /admin/security/:tenantId/groups
  public findSecurityGroupsByTenantId(
    tenantId: string,
    limit: number,
    skip: number,
    origin?: SecurityGroupOrigin,
    entityLevel?: SecurityEntityLevel | SecurityEntityLevel[],
  ): Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> {
    const originParam = {
      [ApiQueryOption.origin]: origin,
    };
    const entityLevelParam = {
      [ApiQueryOption.entityLevel]: entityLevel,
    };
    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        ...(origin ? originParam : {}),
        ...(entityLevel ? entityLevelParam : {}),
      },
    });

    const url: string = this.restBuilder.create().admin().security().tenant(tenantId).groups().query(queryParams).getApiUrl();

    return this.httpClient.get<ISecurityGroupsResponse<ISecurityGroupWithUserIds>>(url, {
      params: this.restBuilder.paginationParams(skip, limit),
    });
  }

  // GET /admin/security/staff-groups
  public findStaffGroupsByTenantId(limit: number, skip: number): Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> {
    const url: string = this.restBuilder.create().admin().security().staffGroups().getApiUrl();

    return this.httpClient.get<ISecurityGroupsResponse<ISecurityGroupWithUserIds>>(url, {
      params: this.restBuilder.paginationParams(skip, limit),
    });
  }

  // POST /admin/security/groups
  public createSecurityGroup(group: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    const url: string = this.restBuilder.create().admin().security().groups().getApiUrl();
    return this.httpClient.post<ISecurityGroup>(url, group);
  }

  // POST /admin/security/staff-groups
  public createStaffGroup(group: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    const url: string = this.restBuilder.create().admin().security().staffGroups().getApiUrl();
    return this.httpClient.post<ISecurityGroup>(url, group);
  }

  // GET /admin/security/groups/:groupId
  public getSecurityGroup(groupId: string): Observable<ISecurityGroupWithUsers> {
    const url: string = this.restBuilder.create().admin().security().groups(groupId).getApiUrl();

    return this.httpClient.get<ISecurityGroupWithUsers>(url);
  }

  // GET /admin/security/staff-groups/:groupId
  public getStaffGroup(groupId: string): Observable<ISecurityGroupWithUsers> {
    const url: string = this.restBuilder.create().admin().security().staffGroups(groupId).getApiUrl();
    return this.httpClient.get<ISecurityGroupWithUsers>(url);
  }

  // PUT /admin/security/groups/:groupId
  public updateSecurityGroup(groupId: string, update: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    const url: string = this.restBuilder.create().admin().security().groups(groupId).getApiUrl();
    return this.httpClient.put<ISecurityGroup>(url, update);
  }

  // PUT /admin/security/staff-groups/:groupId
  public updateStaffGroup(groupId: string, update: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    const url: string = this.restBuilder.create().admin().security().staffGroups(groupId).getApiUrl();
    return this.httpClient.put<ISecurityGroup>(url, update);
  }

  // DELETE /admin/security/groups/:groupId
  public deleteSecurityGroup(groupId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().groups(groupId).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // DELETE /admin/security/staff-groups/:groupId
  public deleteStaffGroup(groupId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().staffGroups(groupId).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // GET /admin/security/groups/user-management/all
  public findSecurityGroupsForUserManagement(options?: {
    limit?: number;
    skip?: number;
    includeStaffGroups?: boolean;
    origin?: SecurityGroupOrigin;
    entityLevel?: SecurityEntityLevel | SecurityEntityLevel[];
    skipOrganizationAdmin?: boolean;
  }): Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> {
    const originParam = {
      [ApiQueryOption.origin]: options?.origin,
    };
    const entityLevelParam = {
      [ApiQueryOption.entityLevel]: options?.entityLevel,
    };
    const skipOrganizationAdmin = {
      [ApiQueryOption.skipOrganizationAdmin]: options?.skipOrganizationAdmin,
    };
    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        [ApiQueryOption.includeStaffGroups]: options?.includeStaffGroups ?? false,
        ...(options?.origin ? originParam : {}),
        ...(options?.entityLevel ? entityLevelParam : {}),
        ...(options?.skipOrganizationAdmin ? skipOrganizationAdmin : {}),
      },
    });

    const url: string = this.restBuilder
      .create()
      .admin()
      .security()
      .groups()
      .userManagement()
      .all()
      .query(queryParams)
      .getApiUrl();

    return this.httpClient.get<ISecurityGroupsResponse<ISecurityGroupWithUserIds>>(url, {
      params: this.restBuilder.paginationParams(options?.skip, options?.limit),
    });
  }

  // GET /admin/security/groups/users/:userId
  public findAllSecurityGroupsByUserIdAndTenantId(
    userId: string,
    options: {
      includeStaffGroups?: boolean;
      entityLevel?: SecurityEntityLevel | SecurityEntityLevel[];
    },
  ): Observable<ISecurityGroupsResponse> {
    const entityLevelParam: ISecurityGroupsQuery = {
      [ApiQueryOption.entityLevel]: options.entityLevel,
    };
    const includeStaffGroups: Record<string, boolean> = {
      [ApiQueryOption.includeStaffGroups]: options.includeStaffGroups,
    };
    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        ...(options.includeStaffGroups ? includeStaffGroups : {}),
        ...(options.entityLevel ? entityLevelParam : {}),
      },
    });
    const url: string = this.restBuilder.create().admin().security().groups().users(userId).query(queryParams).getApiUrl();
    return this.httpClient.get<ISecurityGroupsResponse>(url);
  }
}
