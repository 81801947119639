<div class="modal-overlay" (click)="onOverlayClick()">
  <div [ngClass]="{ show: isActive }" class="modal-container-content fade">
    <div
      id="modalContainer"
      class="card"
      role="dialog"
      tabindex="-1"
      aria-modal="true"
      aria-labelledby="modalHeader"
      (click)="onContainerClick($event)"
      [ngClass]="[cardClass ?? '', scrollable ? 'modal-container-scrollable' : '']"
    >
      <div *ngIf="displayHeader" class="card-header bg-white padding-top-lg">
        <div class="d-flex margin-bottom">
          <h2 id="modalHeader" class="h1"><ng-content select=".modal-header-content"></ng-content></h2>
          <button
            type="button"
            [attr.aria-label]="'close_modal' | translate"
            [attr.aria-describedby]="'modalTitle'"
            class="btn-close-modal"
            (click)="closeModal()"
            *ngIf="displayXButton"
          >
            <svg aria-hidden="true" focusable="false" viewBox="0 0 8 8"><use href="#cross-solid"></use></svg>
            <span id="modalTitle" class="visuallyhidden">{{ title }}</span>
          </button>
        </div>
        <div class="divider"></div>
      </div>

      <div class="card-body" [ngClass]="cardBodyClass">
        <ng-content select=".modal-body-content"></ng-content>
      </div>

      <div *ngIf="displayFooter" class="card-footer bg-white">
        <div class="divider margin-bottom-md"></div>
        <div class="margin-bottom">
          <div #customFooter>
            <ng-content select=".modal-footer-content"></ng-content>
          </div>
          <div class="d-flex justify-content-end" *ngIf="customFooter.children.length === 0">
            <button class="btn btn-outline-primary margin-right-md" (click)="closeModal()">
              {{ closeLabel | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
