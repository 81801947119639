import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DsBreakpoints } from '@levelaccess/design-system';

import { WindowService } from '../../services/window.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'compound-navbar',
  templateUrl: './compound-navbar.component.html',
  styleUrls: ['./compound-navbar.component.scss'],
})
export class CompoundNavbarComponent implements OnInit {
  public showMobileNavVersion$: Observable<boolean>;
  public isAuthenticated$: Observable<boolean>;

  constructor(
    private windowService: WindowService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.showMobileNavVersion$ = this.windowService.observeBreakpoint(DsBreakpoints.Medium);
    this.isAuthenticated$ = this.userService.isAuthenticated$;
  }
}
