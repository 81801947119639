<div class="common-textarea" [ngClass]="view" [formGroup]="form">
  <div class="d-flex w-100">
    <label *ngIf="!context.noLabel" for="{{ context.field }}" class="label text-nowrap" [ngClass]="{ required: required }">
      {{ context.label | translate }}<span *ngIf="required" class="color-error"> *</span>
    </label>
    <app-form-field-line-count
      class="ms-auto me-0"
      *ngIf="context.lineLimit"
      [fieldValue]="form.get(context.field).value"
      [lineLimit]="context.lineLimit"
      [limitLabel]="context.limitLabel"
    ></app-form-field-line-count>
  </div>
  <span class="margin-bottom-xs"> {{ context.description | translate }} </span>
  <textarea
    appCtrlKey
    (change)="onChange()"
    (input)="onChange()"
    [attr.aria-describedby]="errorDomId"
    [attr.aria-invalid]="form | fieldInvalid: context.field"
    [attr.aria-required]="required"
    [formControlName]="context.field"
    [name]="context.field"
    [placeholder]="context.placeholder | translate"
    [rows]="context.rows || 8"
    class="form-element w-100"
    id="{{ domId }}"
    [attr.required]="context.required ? '' : null"
  ></textarea>
  <div [id]="errorDomId" class="error-container">
    <app-form-field-error
      [formValidationRequest$]="formValidationRequest$"
      [field]="form.get(context.field)"
      [name]="context.field"
      [label]="context.label"
      [customMessage]="context.customMessage"
      [overrideErrors]="context.overrideErrors"
    ></app-form-field-error>
  </div>
</div>
