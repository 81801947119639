<ds-modal
  class="modal-container"
  [modal]="modal"
  [title]="'create_project' | translate"
  [backNavLabel]="'back_to_create_task' | translate"
>
  <form [formGroup]="form">
    <div class="mb-3">{{ 'form-field-marked-as-required' | translate }}</div>

    <ds-input
      [label]="'project_name' | translate"
      
      required="true"
      [instructions]="'characters_maximum' | translate: [CHAR_LIMITS[$project.name]]"
      [errorMessage]="formFieldErrors[FormFields.name]"
      [errorState]="formFieldErrors[FormFields.name]?.length > 0"
      class="mb-3"
    >
      <input ds-input-field type="text" [formControlName]="FormFields.name" />
    </ds-input>

    <ds-input
      [label]="'project_key' | translate"
      
      required="true"
      [instructions]="'characters_maximum' | translate: [CHAR_LIMITS[$project.key]]"
      [errorMessage]="formFieldErrors[FormFields.key]"
      [errorState]="formFieldErrors[FormFields.key]?.length > 0"
      class="mb-3"
    >
      <input ds-input-field type="text" [formControlName]="FormFields.key" />
    </ds-input>

    <ds-input
      [label]="'project_owner' | translate"
      
      required="true"
      [errorMessage]="formFieldErrors[FormFields.owner]"
      [errorState]="formFieldErrors[FormFields.owner]?.length > 0"
      class="mb-3"
    >
      <input
        ds-input-field
        type="text"
        [formControlName]="FormFields.owner"
        [ngbTypeahead]="searchFunction"
        [inputFormatter]="formatUser"
        [resultFormatter]="formatUser"
        (selectItem)="onSelectUser($event)"
        (blur)="onBlurOwner()"
      />
    </ds-input>
  </form>

  <app-banner #errorBanner class="mb-3" [type]="BannerType.error" [message]="errorMessage"></app-banner>

  <ng-template ds-modal-slide-out-footer>
    <ds-button-group
      [primaryAction]="{ label: 'create_project' | translate }"
      [secondAction]="{ label: 'form_cancel' | translate, variant: DsButtonVariants.secondary }"
      (primaryActionClicked)="submit()"
      (secondActionClicked)="modalService.previousModal()"
    >
    </ds-button-group>
  </ng-template>
</ds-modal>
