<div
  *ngIf="invalidFormControls.length > 0"
  class="alert alert-danger"
  role="group"
  [attr.aria-labelledby]="componentId + '-header'"
  tabindex="-1"
  id="{{ componentId }}"
>
  <h2 [ngClass]="invalidFormControls.length > 3 ? 'visuallyhidden' : ''" id="{{ componentId }}-header" [innerHTML]="header"></h2>

  <ul *ngIf="invalidFormControls.length > 3">
    <li *ngFor="let error of formErrorsWithNames">
      <a [routerLink]="[]" fragment="{{ error.controlName }}" href="" (click)="navigateToFormControl($event)">{{
        error.controlText
      }}</a>
      -
      {{ error.message }}
    </li>
  </ul>

  <p *ngIf="invalidFormControls.length <= 3">{{ 'form_validation_errors' | translate }}</p>
</div>
