import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';

import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IDigitalPropertyMonitoring,
  IDigitalPropertyMonitoringUserAlerts,
} from '../../../../shared/interfaces/digital-property.interface';
import {
  $digitalPropertyMonitoring,
  $digitalPropertyMonitoringAlerts,
  $digitalPropertyMonitoringAlertType,
} from '../../../../shared/constants/digital-properties';
import { IMonitoringLabel } from './monitoring-label.interface';
import { Api } from '../../../../shared/constants/api';
import { $user } from '../../../../shared/constants/user';
import { IUserServerResponse } from '../../../../shared/interfaces/user.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { TranslateService } from '../../translate/translate.service';
import { TenantWorkspacesTableComponent } from '../../admin/tenants/tenant-details/tenant-workspaces-table/tenant-workspaces-table.component';
import { LoadErrorHandler } from '../../services/load-error-handler';
import { UserService } from '../../services/user.service';
import { LinkedPropertyUtility } from '../../../../shared/utils/linked-property.utility';

@Component({
  selector: 'app-monitoring-label',
  templateUrl: './monitoring-label.component.html',
  styleUrls: ['./monitoring-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringLabelComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  private userId: string;

  @Input()
  public data: IMonitoringLabel;
  public $digitalPropertyMonitoring: typeof $digitalPropertyMonitoring;
  public Api: typeof Api;

  constructor(
    private translateService: TranslateService,
    private loadErrorHandler: LoadErrorHandler,
    private userService: UserService,
  ) {
    this.$digitalPropertyMonitoring = $digitalPropertyMonitoring;
    this.Api = Api;
    this.subscriptions = new Subscription();
    this.userId = '';
  }

  public getMonitoringOccurrenceLabel(monitoring: IDigitalPropertyMonitoring): string {
    return `label_occurrence_${monitoring[$digitalPropertyMonitoring.occurrence].toLowerCase()}`;
  }

  public get queryParams(): Params {
    return this.data ? LinkedPropertyUtility.getLinkedPropertyQueryParam(this.data.digitalPropertyId, this.data.workspaceId) : {};
  }

  public get hasMonitoring(): boolean {
    return this.data?.monitoring?.[$digitalPropertyMonitoring.enabled];
  }

  public get hasAccess(): boolean {
    if (SharedCommonUtility.isNullish(this.data?.userCanSetupMonitoring)) {
      return true;
    }

    return this.data?.userCanSetupMonitoring;
  }

  public get nextSchedule(): string | null {
    return this.data?.monitoring?.[$digitalPropertyMonitoring.nextScheduledAt];
  }

  public get hasNextSchedule(): boolean {
    return typeof this.nextSchedule === 'string';
  }

  public get alerts(): string[] | undefined {
    const alert: IDigitalPropertyMonitoringUserAlerts[] = this.data?.monitoring?.[$digitalPropertyMonitoring.alerts];

    if (Array.isArray(alert)) {
      const alerts: string[] = [];
      for (const userAlerts of alert) {
        if (userAlerts[$digitalPropertyMonitoringAlerts.userId] === this.userId) {
          const userAlert: Partial<Record<$digitalPropertyMonitoringAlertType, { set: boolean; threshold?: number }>> =
            userAlerts[$digitalPropertyMonitoringAlerts.alerts];

          if (userAlert.scanCompletion?.set) {
            alerts.push(this.translateService.instant('scan_completed'));
          }

          if (userAlert.scoreDropByPercent?.set) {
            alerts.push(this.translateService.instant('score_drop_below_value', [userAlert.scoreDropByPercent.threshold]));
          }

          if (userAlert.scoreDropByPercentFromPrevious?.set) {
            alerts.push(
              this.translateService.instant('score_drop_below_previous_score_by_value', [
                userAlert.scoreDropByPercentFromPrevious.threshold,
              ]),
            );
          }
        }
      }

      if (alerts.length === 0) {
        return undefined;
      }
      return alerts;
    }

    return alert;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    const onGetUserSuccess = (user: IUserServerResponse): void => {
      this.userId = user[$user._id];
    };

    const onGetUserError = (error: HttpErrorResponse): void => {
      this.loadErrorHandler.handleError('error_getting_me_profile', error, TenantWorkspacesTableComponent.name);
    };

    this.subscriptions.add(
      this.userService.getAuthenticatedUserProfile().subscribe({
        next: onGetUserSuccess,
        error: onGetUserError,
      }),
    );
  }
}
