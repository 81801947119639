import { DeviceType, OperatingSystem, OPERATING_SYSTEMS } from '../../../shared/constants/os-devices';
import { CommonUtility } from './common.utility';
import { viewportSize } from '../shared/constants';

export class DetectUtility {
  private static userAgent: string = navigator.userAgent;
  private static BROWSERS: any = {
    MSIE: 'MSIE',
    CHROME: 'Chrome',
    FIREFOX: 'Firefox',
    SAFARI: 'Safari',
    EDGE: 'Edge',
    PHANTOMJS: 'PhantomJS',
    OPERA: 'OPERA',
    UNKNOWN: 'Unknown',
  };

  public static getUserAgent(): string {
    return this.userAgent;
  }

  public static setUserAgent(ua: any): any {
    this.userAgent = ua;

    return ua;
  }

  public static hasUserAgent(str: string): any {
    return this.getUserAgent().indexOf(str) > -1;
  }

  public static isOpera(): any {
    return this.hasUserAgent('OPR/');
  }

  public static isIE(): any {
    return this.hasUserAgent('Trident');
  }

  public static getBrowserName(): any {
    if (this.isOpera()) {
      return this.BROWSERS.OPERA;
    }

    if (this.hasUserAgent(this.BROWSERS.PHANTOMJS)) {
      return this.BROWSERS.PHANTOMJS;
    }

    if (this.hasUserAgent(this.BROWSERS.EDGE)) {
      return this.BROWSERS.EDGE;
    }

    if (this.hasUserAgent(this.BROWSERS.CHROME)) {
      return this.BROWSERS.CHROME;
    }

    if (this.hasUserAgent(this.BROWSERS.FIREFOX)) {
      return this.BROWSERS.FIREFOX;
    }

    if (this.hasUserAgent(this.BROWSERS.SAFARI)) {
      return this.BROWSERS.SAFARI;
    }

    if (this.isIE()) {
      return this.BROWSERS.MSIE;
    }

    return this.BROWSERS.UNKNOWN;
  }

  public static getBrowserVersion(): any {
    const versionPattern: string = '([\\d,.]+)';
    let versionString: string;

    const getIEVersion = (): any => {
      const classicIeVersionMatches: any = this.userAgent.match(this.BROWSERS.MSIE + ' ' + versionPattern);
      const ieVersionMatches: any = this.userAgent.match('rv:' + versionPattern);

      if (classicIeVersionMatches) {
        return classicIeVersionMatches[1];
      }
      if (ieVersionMatches) {
        return ieVersionMatches[1];
      }

      return null;
    };

    const getOtherVersion = (browserName: string): any => {
      let browserNameStr: string = browserName;

      if (this.isOpera()) {
        browserNameStr = 'OPR';
      }

      if (browserName === this.BROWSERS.SAFARI) {
        browserNameStr = 'Version';
      }

      const matches: any = this.userAgent.match(browserNameStr + '/' + versionPattern);

      if (matches) {
        return matches[1];
      }

      return null;
    };

    if (this.isIE()) {
      versionString = getIEVersion();
    } else {
      versionString = getOtherVersion(this.getBrowserName());
    }

    return parseFloat(versionString);
  }

  public static getDeviceType(): DeviceType | undefined {
    const mobile: RegExp =
      /(android|ipad|ipod|windows phone|wpdesktop|windows ce|blackberry\w*|meego|webos|palm|symbian|pda|\w*?mobile\w*?|\w*?phone\w*?)/i;
    const tablet: RegExp = /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i;
    let device: DeviceType;

    if (this.userAgent.match(tablet)) {
      // Note: Currently IE on PCs with touch devices returns 'Tablet PC' in user agent. The string on MS surface is actually same.
      // If we use this as reporting we will send all touch desktop PCs as tablets (in IE)
      device = DeviceType.Tablet;
    } else if (this.userAgent.match(mobile)) {
      device = DeviceType.Mobile;
    } else {
      device = DeviceType.Desktop;
    }

    return device;
  }

  // Note: extend this to handle window.resize
  public static getViewportSize(): viewportSize {
    return CommonUtility.getStyle(document.body, 'content', ':before').replace(/["']/g, '') as viewportSize;
  }

  public static isWindows(): boolean {
    return this.hasUserAgent(OPERATING_SYSTEMS[OperatingSystem.WINDOWS]);
  }
}
