<div *ngIf="workspaces && workspaces.length" class="workspaces-select">
  <ds-input [label]="'change_workspace' | translate">
    <select
      ds-input-field
      
      [(ngModel)]="selectedWorkspaceId"
      (ngModelChange)="selectWorkspace($event)"
    >
      <option *ngFor="let workspace of workspaces" [value]="workspace[$workspace._id]">
        {{ workspace[$workspace.name] }}
      </option>
    </select>
  </ds-input>
</div>
