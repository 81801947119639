import { Component, OnInit } from '@angular/core';

import { config } from '../../../environments/config.shared';
import version from '../../../version.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public footerTitle: string;
  public appVersion: { publishDate: string; version: string } = version;

  constructor() {}

  public ngOnInit(): void {
    this.footerTitle = `${config.APP.COPYRIGHT_YEAR} - ${new Date().getFullYear()} ${config.company.name}`;
  }
}
