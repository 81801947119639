import { Injectable } from '@angular/core';
import ClipboardJS from 'clipboard';

import { NotificationService } from '../services/notification.service';
import { NotificationPosition } from '../models/notification.model';
import { TranslateService } from '../translate/translate.service';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  private textValueCopyButtonSelector: string = '.copy-to-clipboard';
  public clipboard: Map<string, ClipboardJS>;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.clipboard = new Map();
  }

  private onCopySuccess(): void {
    this.notificationService.success(this.translateService.instant('copied_to_clipboard'), NotificationPosition.Toast);
  }

  public init(options: ClipboardJS.Options = {}, inputId: string = null): string {
    let clipboardIdentifier: string = inputId;
    if (SharedCommonUtility.isNullish(clipboardIdentifier)) {
      clipboardIdentifier = this.textValueCopyButtonSelector;
    }

    let newClipboard: ClipboardJS;
    if (SharedCommonUtility.notNullish(inputId)) {
      const btn: HTMLElement = document.querySelector('#copy-to-clipboard-' + inputId);
      newClipboard = new ClipboardJS(btn, options);
    } else {
      newClipboard = new ClipboardJS(this.textValueCopyButtonSelector, options);
    }

    if (!this.clipboard.has(clipboardIdentifier)) {
      newClipboard.on('success', this.onCopySuccess.bind(this));
      this.clipboard.set(clipboardIdentifier, newClipboard);
    }
    return clipboardIdentifier;
  }

  public destroyClipboardService(clipboardId?: string): void {
    if (this.clipboard.size === 0) {
      return;
    }

    if (SharedCommonUtility.notNullish(clipboardId)) {
      this.clipboard.get(clipboardId).destroy();
      this.clipboard.delete(clipboardId);
      return;
    }

    this.clipboard.forEach((clipboard: ClipboardJS, id: string): void => {
      clipboard.destroy();
      this.clipboard.delete(id);
    });
  }
}
