import { cloneDeep } from 'lodash';

import { $securityRole, $securitySet, FunctionalArea, SecureAction } from '../constants/security';
import { FunctionalActionsSet, ISecurityEntityLevels, ISecuritySet } from '../interfaces/security-set.interface';
import { SharedCommonUtility } from './common.utility';
import { ISecurityEntity, ISecurityGroup } from '../interfaces/security-group.interface';
import { $securityEntity, $securityGroup, SecurityEntityLevel } from '../constants/security-group';
import { FunctionalActions, ISecurityRole } from '../interfaces/security-role.interface';
import { FlattenedFunctionalAreaHierarchy } from '../constants/functional-area-hierarchy';
import { IRequiredSecurity } from '../interfaces/security.interface';

export class SecurityUtility {
  private static hasAccessInternal(
    set: FunctionalActions,
    area: FunctionalArea,
    action?: SecureAction | ReadonlyArray<SecureAction>,
  ): boolean {
    if (SharedCommonUtility.isNullish(set[area])) {
      return false;
    }

    const actions: ReadonlyArray<SecureAction> = set[area];
    if (SharedCommonUtility.isNullish(actions)) {
      return false;
    }

    if (SharedCommonUtility.isNullishOrEmpty(action)) {
      return true;
    }

    const requestedActions: SecureAction[] = Array.isArray(action) ? action : [action];
    return requestedActions.some((requestedAction: SecureAction): boolean => actions.includes(requestedAction));
  }

  private static hasFunctionalActionsIntersection(
    userFunctionalActions: FunctionalActions,
    requiredFunctionalActions: FunctionalActions,
  ): boolean {
    for (const functionalArea of this.getFunctionalAreas(requiredFunctionalActions)) {
      const secureActions: ReadonlyArray<SecureAction> = requiredFunctionalActions[functionalArea];
      if (this.hasAccessByFunctionalActions(userFunctionalActions, functionalArea, secureActions)) {
        return true;
      }
    }
    return false;
  }

  public static getFunctionalAreas(functionalActions: FunctionalActions | FunctionalActionsSet): FunctionalArea[] {
    return Object.getOwnPropertyNames(functionalActions) as FunctionalArea[];
  }

  public static getEntityIdByLevel(entity: ISecurityEntity, level: SecurityEntityLevel): string {
    switch (level) {
      case SecurityEntityLevel.admin:
        return SecurityEntityLevel.admin;
      case SecurityEntityLevel.tenant:
        return entity[$securityEntity.tenantId];
      case SecurityEntityLevel.workspace:
        return entity[$securityEntity.workspaceId];
      case SecurityEntityLevel.digitalProperty:
        return entity[$securityEntity.digitalPropertyId];
      default:
        return undefined;
    }
  }

  public static createSecurityEntity(tenantId?: string, workspaceId?: string, digitalPropertyId?: string): ISecurityEntity {
    const topLevel: SecurityEntityLevel = SharedCommonUtility.isNullishOrEmpty(tenantId)
      ? SecurityEntityLevel.admin
      : SecurityEntityLevel.tenant;

    let level: SecurityEntityLevel;
    if (SharedCommonUtility.isNullishOrEmpty(workspaceId)) {
      level = topLevel;
    } else if (SharedCommonUtility.isNullishOrEmpty(digitalPropertyId)) {
      level = SecurityEntityLevel.workspace;
    } else {
      level = SecurityEntityLevel.digitalProperty;
    }

    return {
      [$securityEntity.level]: level,
      [$securityEntity.tenantId]: tenantId,
      [$securityEntity.workspaceId]: workspaceId,
      [$securityEntity.digitalPropertyId]: digitalPropertyId,
    };
  }

  /**
   *
   * @param set
   * @param area
   * @param action
   */
  public static hasAccessByFunctionalActions(
    set: FunctionalActions,
    area: FunctionalArea,
    action?: SecureAction | ReadonlyArray<SecureAction>,
  ): boolean {
    const endFunctionalAreas: FunctionalArea[] = FlattenedFunctionalAreaHierarchy[area];

    if (SharedCommonUtility.isNullish(endFunctionalAreas)) {
      return false;
    }

    if (endFunctionalAreas.length === 0) {
      return this.hasAccessInternal(set, area, action);
    }

    for (const functionalArea of endFunctionalAreas) {
      const hasAccess: boolean = this.hasAccessInternal(set, functionalArea, action);
      if (hasAccess) {
        return true;
      }
    }

    return false;
  }

  public static hasAccessBySecurityEntity(
    set: ISecurityEntityLevels,
    entity: ISecurityEntity,
    area?: FunctionalArea,
    action?: SecureAction | ReadonlyArray<SecureAction>,
  ): boolean {
    const isAdminEntityLevel: boolean = entity[$securityEntity.level] === SecurityEntityLevel.admin;
    const securityEntityLevelsOrder: SecurityEntityLevel[] = isAdminEntityLevel
      ? [SecurityEntityLevel.admin]
      : [SecurityEntityLevel.tenant, SecurityEntityLevel.workspace, SecurityEntityLevel.digitalProperty];

    const currentOrder: number = securityEntityLevelsOrder.indexOf(entity[$securityEntity.level]);

    const isInvalidEntityLevel: boolean = currentOrder < 0;
    if (isInvalidEntityLevel) {
      return false;
    }

    for (let order = currentOrder; order >= 0; order--) {
      const level: SecurityEntityLevel = securityEntityLevelsOrder[order];
      if (SharedCommonUtility.isNullish(set[level])) {
        continue;
      }

      const functionalActionsByEntities: Record<string, FunctionalActions> = set[level];
      const entityId: string = this.getEntityIdByLevel(entity, level);
      if (SharedCommonUtility.isNullishOrEmpty(entityId)) {
        continue;
      }

      const entityFunctionalActions: FunctionalActions = functionalActionsByEntities[entityId];
      if (SharedCommonUtility.isNullish(entityFunctionalActions)) {
        continue;
      } else if (SharedCommonUtility.isNullish(area)) {
        return true;
      }

      if (this.hasAccessByFunctionalActions(entityFunctionalActions, area, action)) {
        return true;
      }
    }

    return false;
  }

  public static hasAccess(
    userSecuritySet: ISecuritySet,
    requiredFunctionalActions?: FunctionalActions,
    securityEntity?: ISecurityEntity,
  ): boolean {
    const checkByFunctionalActionsOnly = (): boolean =>
      SharedCommonUtility.notNullish(requiredFunctionalActions) && SharedCommonUtility.isNullish(securityEntity);

    const checkByEntityAccessOnly = (): boolean =>
      SharedCommonUtility.isNullish(requiredFunctionalActions) && SharedCommonUtility.notNullish(securityEntity);

    const checkByFunctionalActionsAndEntityAccess = (): boolean =>
      SharedCommonUtility.notNullish(requiredFunctionalActions) && SharedCommonUtility.notNullish(securityEntity);

    if (checkByFunctionalActionsOnly()) {
      const userFunctionalActions: FunctionalActions = userSecuritySet[$securitySet.byFunctionalActions];
      return this.hasFunctionalActionsIntersection(userFunctionalActions, requiredFunctionalActions);
    }

    if (checkByEntityAccessOnly()) {
      const userEntityLevelsSet: ISecurityEntityLevels = userSecuritySet[$securitySet.byEntityLevels];
      return this.hasAccessBySecurityEntity(userEntityLevelsSet, securityEntity);
    }

    if (checkByFunctionalActionsAndEntityAccess()) {
      const userEntityLevelsSet: ISecurityEntityLevels = userSecuritySet[$securitySet.byEntityLevels];
      for (const functionalArea of this.getFunctionalAreas(requiredFunctionalActions)) {
        const secureActions: ReadonlyArray<SecureAction> = requiredFunctionalActions[functionalArea];
        if (this.hasAccessBySecurityEntity(userEntityLevelsSet, securityEntity, functionalArea, secureActions)) {
          return true;
        }
      }
      return false;
    }

    return true;
  }

  public static getEntityIdsFromSecuritySet(
    userEntityLevelsSet: ISecurityEntityLevels,
    level: SecurityEntityLevel,
    requiredFunctionalActions?: FunctionalActions,
  ): string[] {
    const entityLevelSet: Record<string, FunctionalActions> = userEntityLevelsSet[level] ?? {};

    const entityHasRequiredFunctionalActions = (entityId: string): boolean =>
      this.hasFunctionalActionsIntersection(entityLevelSet[entityId], requiredFunctionalActions);

    const entityIds: string[] = Object.getOwnPropertyNames(entityLevelSet);

    if (SharedCommonUtility.isNullish(requiredFunctionalActions)) {
      return entityIds;
    }

    return entityIds.filter(entityHasRequiredFunctionalActions);
  }

  private static securityRolesContainFunctionalAction(
    securityRoles: ISecurityRole[],
    requiredFunctionalActions: FunctionalActions,
  ): boolean {
    return securityRoles
      .map((role: ISecurityRole): FunctionalActions => role[$securityRole.functionalActions])
      .some((functionalAction: FunctionalActions) => {
        return this.hasFunctionalActionsIntersection(functionalAction, requiredFunctionalActions);
      });
  }

  public static securityGroupsContainFunctionalAction(
    securityGroups: ISecurityGroup[],
    requiredFunctionalActions: FunctionalActions,
  ): boolean {
    return this.securityRolesContainFunctionalAction(
      securityGroups.flatMap((group: ISecurityGroup): ISecurityRole[] => group[$securityGroup.roles]),
      requiredFunctionalActions,
    );
  }

  /**
   * Merges required securities together, namely their functional actions
   * This DOES NOT hand entityLevel or allowAdminAccess and will default to the first element's properties
   * @param requiredSecurities
   * @returns the first required security but with all of the subsequent securities' functional actions merged in
   */
  public static mergeRequiredSecurities(requiredSecurities: IRequiredSecurity[]): IRequiredSecurity {
    return requiredSecurities.reduce((prev: IRequiredSecurity, curr: IRequiredSecurity): IRequiredSecurity => {
      const accumulator: IRequiredSecurity = cloneDeep(prev);
      Object.keys(curr.functionalActions).forEach((currArea: string) => {
        if (SharedCommonUtility.isNullish(accumulator.functionalActions[currArea])) {
          accumulator.functionalActions[currArea] = curr.functionalActions[currArea];
        } else {
          accumulator.functionalActions[currArea] = Array.from(
            new Set(accumulator.functionalActions[currArea].concat(curr.functionalActions[currArea])),
          );
        }
      });

      return accumulator;
    });
  }
}
