import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from './rest.service';
import { IUserRole } from '../../../shared/interfaces/user-role.interface';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  constructor(private restService: RestService) {}

  public getUserRoles(): Observable<IUserRole[]> {
    return this.restService.getUserRoles();
  }
}
