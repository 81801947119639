import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  public transform<T>(items: T[], searchText: string, fieldName: string): T[] {
    if (typeof items === 'undefined' || items === null) {
      return [];
    }

    if (typeof searchText === 'undefined' || searchText === null || searchText.length === 0) {
      return items;
    }

    const toSearch: string = searchText.toLowerCase();

    const filter = (item: any): boolean | undefined => {
      if (item === null || typeof item === 'undefined') {
        return undefined;
      }
      const deepValue: any = fieldName.split('.').reduce((prev: any, current: string) => prev[current], item);
      if (typeof deepValue !== 'undefined' && deepValue !== null) {
        return deepValue.toString().toLowerCase().includes(toSearch);
      }
      return false;
    };
    return items.filter(filter);
  }
}
