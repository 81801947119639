import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from './translate.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: string, args?: Array<string | number> | string | number): any {
    return this.translateService.instant(value, args as string[]);
  }
}
