import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IRuleAuditHistoryLineItem } from '../../../../../shared/interfaces/rule-audit-history.interface';
import { $ruleAuditHistory } from '../../../../../shared/constants/rule-audit-history';
import { RuleAuditHistoryService } from '../../../services/rule-audit-history.service';

@Component({
  selector: 'app-view-rule-history-item',
  templateUrl: './view-rule-history-item.component.html',
  styleUrls: ['./view-rule-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewRuleHistoryItemComponent {
  @Input()
  public expanded: boolean;
  @Input()
  public historyItem: IRuleAuditHistoryLineItem;
  public $ruleAuditHistory: typeof $ruleAuditHistory;

  constructor(private ruleAuditHistoryService: RuleAuditHistoryService) {
    this.expanded = true;
    this.$ruleAuditHistory = $ruleAuditHistory;
  }

  public getUserAndTimestamp(): string {
    return this.ruleAuditHistoryService.getStatusLine(
      new Date(this.historyItem[$ruleAuditHistory.createdAt]),
      this.historyItem[$ruleAuditHistory.user],
    );
  }
}
