import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { PipeTransform, Pipe, SecurityContext } from '@angular/core';
import DOMPurify from 'isomorphic-dompurify';

@Pipe({ name: 'safeContent' })
export class SanitizerPipe implements PipeTransform {
  constructor(private readonly domSanitizer: DomSanitizer) {}

  private bypassSecurityTrust(context: SecurityContext, purifiedValue: string): SafeValue | null {
    switch (context) {
      case SecurityContext.HTML:
        return this.domSanitizer.bypassSecurityTrustHtml(purifiedValue);
      case SecurityContext.STYLE:
        return this.domSanitizer.bypassSecurityTrustStyle(purifiedValue);
      case SecurityContext.SCRIPT:
        return this.domSanitizer.bypassSecurityTrustScript(purifiedValue);
      case SecurityContext.URL:
        return this.domSanitizer.bypassSecurityTrustUrl(purifiedValue);
      case SecurityContext.RESOURCE_URL:
        return this.domSanitizer.bypassSecurityTrustResourceUrl(purifiedValue);
      default:
        return null;
    }
  }

  public transform(value: string | Node, context: SecurityContext = SecurityContext.HTML): SafeValue | null {
    return this.bypassSecurityTrust(context, DOMPurify.sanitize(value));
  }
}
