import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DsButtonVariants, DsInputStates } from '@levelaccess/design-system';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { IUserRole } from '../../../../../../shared/interfaces/user-role.interface';
import { UserService } from '../../../../services/user.service';
import { ErrorMessageService } from '../../../../services/error-message.service';
import { A11yService } from '../../../../services/a11y.service';
import { TranslateService } from '../../../../translate/translate.service';
import { FormService } from '../../../../services/form.service';
import { UserRoleService } from '../../../../services/user-role.service';
import { $user, minPasswordLength } from '../../../../../../shared/constants/user';
import { CustomValidators } from '../../../../services/helpers/form-custom-validators';
import { IUserSignupResponse, IUserSignUpRequest } from '../../../../../../shared/interfaces/user-authentication.interface';
import { CommonUtility } from '../../../../utility/common.utility';
import { DateUtility } from '../../../../utility/date.utility';
import { ChangePasswordFromSignupComponent } from '../../../../components/change-password/from-signup/change-password-from-signup.component';
import { AppConfigService } from '../../../../services/app-config.service';
import { LoadErrorHandler } from '../../../../services/load-error-handler';
import { validationError } from '../../../../constants/form.constants';
import { AngularUtility } from '../../../../utility/angular.utility';
import { BannerType } from '../../../../components/users/edit-user/edit-permissions-banner/edit-permissions-banner.component';
import { Api } from '../../../../../../shared/constants/api';
import { errorMessagesNames } from '../../../../../../shared/constants/errors';

export enum SignupFormField {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  ROLE = 'role',
}

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
})
export class SignupFormComponent implements OnInit, OnDestroy {
  public readonly Api: typeof Api = Api;
  public readonly FormField: typeof SignupFormField = SignupFormField;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly BannerType: typeof BannerType = BannerType;

  private subscription: Subscription = new Subscription();

  public emailInputState: DsInputStates = DsInputStates.inactive;
  public form: UntypedFormGroup;
  public showSignupErrorBanner: boolean;
  public signUpErrorMessage: string;
  public userRoles$: Observable<IUserRole[]>;
  public firstNameError$: Observable<string>;
  public lastNameError$: Observable<string>;
  public roleError$: Observable<string>;
  public externalAuthEnabled: boolean;

  @Input() public confirmEmailToken: string;
  @Input() public email: string;

  @Output() public onSuccess: EventEmitter<IUserSignupResponse> = new EventEmitter();

  @ViewChild(ChangePasswordFromSignupComponent)
  public changePasswordInput: ChangePasswordFromSignupComponent;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private errorMessageService: ErrorMessageService,
    private a11yService: A11yService,
    private translateService: TranslateService,
    private formService: FormService,
    private userRoleService: UserRoleService,
    private appConfigService: AppConfigService,
    private loadErrorHandler: LoadErrorHandler,
  ) {
    this.externalAuthEnabled = this.appConfigService.isUsingExternalIdp();

    this.form = this.buildFormGroup();
    this.buildErrorObservables();
    this.userRoles$ = this.getUserRoles$();
  }

  private buildFormGroup(): UntypedFormGroup {
    const form: UntypedFormGroup = this.formBuilder.group({
      [SignupFormField.FIRST_NAME]: this.formBuilder.control(null, [Validators.required]),
      [SignupFormField.LAST_NAME]: this.formBuilder.control(null, [Validators.required]),
      [SignupFormField.EMAIL]: this.formBuilder.control(null),
      [SignupFormField.ROLE]: this.formBuilder.control('', [Validators.required]),
    });

    if (this.externalAuthEnabled === false) {
      form.addControl(
        $user.newPassword,
        this.formBuilder.control(null, [
          Validators.minLength(minPasswordLength),
          CustomValidators.validateHasUppercase,
          CustomValidators.validateHasLowercase,
          CustomValidators.validateHasNumberOrSpecial,
          Validators.required,
        ]),
      );
    }

    return form;
  }

  private buildErrorObservables(): void {
    this.firstNameError$ = this.formService
      .buildFormControlErrorMessage$(this.form.get(SignupFormField.FIRST_NAME), SignupFormField.FIRST_NAME)
      .pipe(AngularUtility.shareRef());

    this.lastNameError$ = this.formService
      .buildFormControlErrorMessage$(this.form.get(SignupFormField.LAST_NAME), SignupFormField.LAST_NAME)
      .pipe(AngularUtility.shareRef());

    this.roleError$ = this.formService
      .buildFormControlErrorMessage$(this.form.get(SignupFormField.ROLE), '', {
        [validationError.required]: this.translateService.instant('sign_up_role_required_error'),
      })
      .pipe(AngularUtility.shareRef());
  }

  private getUserRoles$(): Observable<IUserRole[]> {
    return this.userRoleService
      .getUserRoles()
      .pipe(
        catchError(
          this.loadErrorHandler.handleErrorObservable.bind(
            this.loadErrorHandler,
            'error_loading_user_roles',
            SignupFormComponent.name,
          ),
        ),
      );
  }

  public ngOnInit(): void {
    this.form.get(SignupFormField.EMAIL).setValue(this.email);
    this.emailInputState = DsInputStates.nonEditable;
  }

  public onSubmit(): void {
    this.showSignupErrorBanner = false;

    Object.values(this.form.controls).forEach((control: AbstractControl) => control.updateValueAndValidity());
    this.changePasswordInput?.validatePassword();

    if (this.form.valid) {
      this.signUp();
    }
  }

  private signUp(): void {
    const request: IUserSignUpRequest = this.buildRequest();

    this.subscription.add(
      this.userService.signup(request).subscribe({
        next: (response: IUserSignupResponse) => this.onSuccess.next(response),
        error: (response: HttpErrorResponse) => this.onSignUpError(response),
      }),
    );
  }

  private buildRequest(): IUserSignUpRequest {
    const request: IUserSignUpRequest = {
      firstName: this.form.get(SignupFormField.FIRST_NAME).value.trim(),
      lastName: this.form.get(SignupFormField.LAST_NAME).value.trim(),
      roleId: this.form.get(SignupFormField.ROLE).value,
      userLanguage: CommonUtility.getPreferredAvailableLanguageAndRegion(),
      timezone: DateUtility.getTimezoneValueFromUtc(DateUtility.determineUserTimeZone()),
      timezoneOffset: new Date().getTimezoneOffset(),
      confirmEmailToken: this.confirmEmailToken,
    };

    if (this.externalAuthEnabled === false) {
      request.password = this.form.get($user.newPassword).value;
    }

    return request;
  }

  private onSignUpError(response: HttpErrorResponse): void {
    this.showSignupErrorBanner = true;

    const defaultErrorMessage: string =
      this.errorMessageService.getGlobalErrorResponse(response) || this.translateService.instant('login_unable_to_sign_up');

    if (CommonUtility.extractHTTPErrorName(response) === errorMessagesNames.ErrorMultipleAccountSetUpsNotAllowed) {
      this.signUpErrorMessage = null;
      this.a11yService.setMessage('account_with_email_already_exists_login_to_your_account');
    } else {
      this.signUpErrorMessage = defaultErrorMessage;
      this.a11yService.setMessage(defaultErrorMessage);
    }
  }

  public closeSignupErrorBanner(): void {
    this.showSignupErrorBanner = false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
