import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { scanCreateRequest } from '../../../../shared/constants/scan-create-request';
import { Api } from '../../../../shared/constants/api';
import { ApiPathBuilder } from '../../../../shared/services/api-path-builder';
import { AutomatedUserFlowService } from '../../services/automated-user-flow.service';
import { AutomatedFlowType } from '../../../../shared/constants/automated-user-flow';
import { LoadErrorHandler } from '../../services/load-error-handler';
import { IAutomatedFlowShortItem } from '../../../../shared/interfaces/automated-user-flow.interface';

@Component({
  selector: 'app-scan-pre-scan-flow',
  templateUrl: './scan-pre-scan-flow.component.html',
  styleUrl: './scan-pre-scan-flow.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanPreScanFlowComponent implements OnInit, OnDestroy {
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public showTitle: boolean = true;

  protected readonly scanCreateRequest: typeof scanCreateRequest = scanCreateRequest;
  protected readonly Api: typeof Api = Api;
  protected preScanFlows$: Observable<IAutomatedFlowShortItem[]>;

  constructor(
    private formBuilder: FormBuilder,
    private userFlowService: AutomatedUserFlowService,
    private loadErrorHandler: LoadErrorHandler,
  ) {
    this.preScanFlows$ = this.userFlowService
      .getByType(AutomatedFlowType.preScan)
      .pipe(
        catchError(
          this.loadErrorHandler.handleErrorObservable.bind(
            this.loadErrorHandler,
            'automated_scan_user_flow_load_failed',
            ScanPreScanFlowComponent.name,
          ),
        ),
      );
  }

  protected get createFlowLink(): string {
    return ApiPathBuilder('/').websitesAndApps.scans.user_flows.automated_user_flows.create.build();
  }

  ngOnInit(): void {
    this.form.addControl(scanCreateRequest.preScanFlowId, this.formBuilder.control(null));
  }

  ngOnDestroy(): void {
    this.form.removeControl(scanCreateRequest.preScanFlowId);
  }
}
