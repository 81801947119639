import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DsButtonVariants, Icons, DsTooltipPosition } from '@levelaccess/design-system';

import { NavService } from '../../../../services/navigation/nav.service';
import { INavMenuItem, NavigationItem } from '../../../../services/navigation/nav-structure';

@Component({
  selector: 'top-navbar-launcher-dropdown',
  templateUrl: './top-navbar-launcher-dropdown.component.html',
})
export class TopNavbarLauncherDropdownComponent implements OnInit {
  public Icons: typeof Icons;
  public DsButtonVariants: typeof DsButtonVariants;
  public DsTooltipPosition: typeof DsTooltipPosition;

  public menuItems$: Observable<INavMenuItem[]>;
  public currentActiveMenuItem$: Observable<INavMenuItem>;

  constructor(private navService: NavService) {
    this.Icons = Icons;
    this.DsButtonVariants = DsButtonVariants;
    this.DsTooltipPosition = DsTooltipPosition;
  }

  ngOnInit(): void {
    this.menuItems$ = this.navService
      .getTopLevelMenuItems$([
        NavigationItem.evaluations,
        NavigationItem.organization_settings,
        NavigationItem.tools_and_integrations,
        NavigationItem.administration,
        NavigationItem.internal_use_only,
      ])
      .pipe(map((items: INavMenuItem[]): INavMenuItem[] => items?.filter((item: INavMenuItem) => item?.isAvailable)));

    this.currentActiveMenuItem$ = this.navService.activeTopLevelMenuItem$();
  }
}
