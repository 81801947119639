import { Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IUserCellConfig extends ICellConfig {
  user: { accountStatus?: string };
}

@Component({
  selector: 'table-cell-user-status',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `<app-status #ref [value]="config.user.accountStatus"></app-status> `,
})
export class UserAccountStatusCellComponent extends BaseCellComponent {
  @Input() public config: IUserCellConfig;
}
