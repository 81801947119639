import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DsButtonVariants, DsModalColorVariant, DsModalService } from '@levelaccess/design-system';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IModal } from '../../../../../components/modals/modal.interface';
import { ModalContainerComponent } from '../../../../../components/modals/modal-container.component';
import { BannerType } from '../../../../../components/banner/banner.component';
import { ErrorMessageService } from '../../../../../services/error-message.service';
import {
  IUserPreference,
  IUserPreferencesServerResponse,
  IUserPreferenceParamMapping,
} from '../../../../../../../shared/interfaces/user-preference.interface';
import { CustomValidators } from '../../../../../services/helpers/form-custom-validators';
import { UserService } from '../../../../../services/user.service';
import {
  $userPreference,
  userPreferenceScope,
  userPreferenceScopeParam,
} from '../../../../../../../shared/constants/user-preferences';
import { DashboardDataFrequency } from '../../../../../../../shared/interfaces/ws-dashboard.interface';
import { A11yService } from '../../../../../services/a11y.service';
import { NotificationService } from '../../../../../services/notification.service';
import { TranslateService } from '../../../../../translate/translate.service';
import { NotificationPosition } from '../../../../../models/notification.model';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';

enum FormFields {
  frequency = 'frequency',
}

@Component({
  selector: 'app-user-preferences-slideout',
  templateUrl: './user-preferences-slideout.component.html',
})
export class DashboardUserPreferencesSlideoutComponent implements IModal, OnDestroy {
  @ViewChild(ModalContainerComponent, { static: false })
  public container: ModalContainerComponent;

  @Input() public set frequency(value: string) {
    this.frequency$.next(value);
  }

  public get frequency(): string {
    return this.frequency$.value;
  }

  @Output() public onPreferenceUpdated: EventEmitter<IUserPreferenceParamMapping>;

  protected form: UntypedFormGroup;

  protected readonly FormFields: typeof FormFields = FormFields;
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  protected readonly DsModalColorVariant: typeof DsModalColorVariant = DsModalColorVariant;
  protected readonly BannerType: typeof BannerType = BannerType;
  protected readonly DashboardDataFrequency: typeof DashboardDataFrequency = DashboardDataFrequency;
  protected readonly FrequencyOptions: DashboardDataFrequency[] = [
    DashboardDataFrequency.weekly,
    DashboardDataFrequency.monthly,
    DashboardDataFrequency.quarterly,
  ];

  private frequency$: BehaviorSubject<string> = new BehaviorSubject<string>(DashboardDataFrequency.weekly);
  private subscriptions: Subscription;
  protected formFieldErrors: Record<string, string> = {};

  constructor(
    protected modal: NgbActiveOffcanvas,
    protected modalService: DsModalService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private a11yService: A11yService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private errorMessageService: ErrorMessageService,
    private element: ElementRef<Element>,
  ) {
    this.subscriptions = new Subscription();
    this.onPreferenceUpdated = new EventEmitter<IUserPreferenceParamMapping>();
    this.setupForm();
  }

  private setupForm(): void {
    this.form = this.formBuilder.group({
      [FormFields.frequency]: this.formBuilder.control(DashboardDataFrequency.weekly, [CustomValidators.required]),
    });

    this.subscriptions.add(
      this.frequency$
        .pipe(
          filter((freq: DashboardDataFrequency) => SharedCommonUtility.notNullishOrEmpty(freq)),
          map((freq: DashboardDataFrequency) => {
            this.form.controls[FormFields.frequency].setValue(freq);
          }),
        )
        .subscribe(),
    );
  }

  private findPreference(preferences: IUserPreferencesServerResponse, param: userPreferenceScopeParam): IUserPreference {
    const preference: IUserPreference = preferences.userPreferences.find((value: IUserPreference) => {
      return value[$userPreference.param] === param;
    });

    return preference || null;
  }

  private onUpdatePreferencesSuccess(response: IUserPreferencesServerResponse): void {
    this.onPreferenceUpdated.emit({
      [userPreferenceScopeParam.frequency]: this.findPreference(response, userPreferenceScopeParam.frequency),
    });
    this.modalService.closeAllModals();
  }

  private onUpdatePreferencesError(error: any): void {
    const message =
      this.translateService.instant('error_while_saving_user_preferences') +
      ': ' +
      this.errorMessageService.getGlobalErrorResponse(error);
    this.a11yService.setMessage(message);
    this.notificationService.error(message, NotificationPosition.Toast, true);
  }

  public submit(): void {
    if (!this.form.valid) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }
    const body: { [key: string]: any } = {
      [userPreferenceScopeParam.frequency]: this.form.get(FormFields.frequency).value,
    };

    this.subscriptions.add(
      this.userService.updateUserPreferences(userPreferenceScope.programDash, body).subscribe({
        next: this.onUpdatePreferencesSuccess.bind(this),
        error: this.onUpdatePreferencesError.bind(this),
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
