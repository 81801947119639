export enum EapColors {
  BlueInteraction = '#0852ba',
  BlueHover = '#06367a',
  BlueLink = '#0665d0',
  BlueInactive = '#83a8dd',
  BlueBackground = '#f3f6fc',
  BlueInfo = '#0098C7',
  BlueText = '#063E8D',
  BluePrimary = '#074EB0',
  BlueHoverBackground = '#f3f6fc',
  GreenSeverityLow = '#4b9e24',
  GreenCheckmark = '#20652a',
  GreyCopy = '#333',
  GreyStrokeInactive = '#8c8c8c',
  GreyPageBackground = '#f5f5f5',
  GreenStatusPassed = '#569e14',
  PurpleFocus = '#a30083',
  RedError = '#b10606',
  RedStatusFailed = '#ea3349',
  RedSeverityCritical = '#f4364c',
  OrangeSeverityHigh = '#db6e00',
  OrangeStatusReview = '#d36d00',
  White = '#fff',
  SeverityRank5 = '#f4364c',
  SeverityRank4 = '#f4364c',
  SeverityRank3 = '#db6e00',
  SeverityRank2 = '#0098c7',
  SeverityRank1 = '#074eb0',
  BarTextureSeverityRank4 = '#ffdfe4',
  BarTextureSeverityRank3 = '#fff2e4',
  BarTextureSeverityRank2 = '#e7f9ff',
  BarTextureSeverityRank1 = '#d1dcf2',
}

export const SeverityRankColourMap: Map<number, EapColors> = new Map([
  [5, EapColors.SeverityRank5],
  [4, EapColors.SeverityRank4],
  [3, EapColors.SeverityRank3],
  [2, EapColors.SeverityRank2],
  [1, EapColors.SeverityRank1],
]);
