<div [formGroup]="form" class="scan-extra-headers">
  <app-common-checkbox
    [(ngModel)]="modifyHeaders"
    [disabled]="isDisabled"
    (ngModelChange)="onModifyHeaders($event)"
    [ngModelOptions]="{ standalone: true }"
  >
    <span class="checkbox-label">{{ 'label_modify_headers' | translate }}</span>
  </app-common-checkbox>

  <div [formArrayName]="headersControlName" *ngIf="modifyHeaders" class="margin-left">
    <div class="alert alert-info margin-left margin-right">
      {{ 'label_modify_headers_description' | translate }}
    </div>

    <div *ngFor="let control of headersValues; let index = index; let first = first" class="d-flex flex-column flex-xl-row">
      <app-common-input
        [form]="control"
        [context]="{ label: 'label_header', field: $scanHeaders.name, required: true }"
        [formValidationRequest$]="formValidationRequest$"
        class="margin-bottom margin-right margin-left w-100"
      >
      </app-common-input>
      <app-common-input
        [form]="control"
        [context]="{ label: 'label_value', field: $scanHeaders.value, required: true }"
        [formValidationRequest$]="formValidationRequest$"
        class="margin-bottom margin-right margin-left w-100"
      >
      </app-common-input>
      <button
        *ngIf="headersValues.length > 1"
        (click)="removeControl(index)"
        [disabled]="isDisabled"
        class="btn btn-link margin-bottom fw-bold"
      >
        {{ 'action_delete' | translate }}
      </button>
    </div>
    <button (click)="addControl()" [disabled]="isDisabled" class="btn btn-link fw-bold" >
      {{ 'label_add_pair' | translate }}
    </button>
  </div>
</div>
