<ng-container *ngIf="childrenRoutes$ | async as childrenRoutes">
  <nav
    *ngIf="childrenRoutes.length > 0"
    [class.compound-second-level-navbar]="!showMobileNavVersion"
    [class.compound-second-level-navbar-mobile]="showMobileNavVersion"
    [attr.aria-label]="'second_level_navbar_label' | translate"
  >
    <div class="container g-5 py-2 d-flex">
      <ng-container *ngTemplateOutlet="showMobileNavVersion ? mobileVersion : desktopVersion"></ng-container>
      <ng-container *featureFlag="FeatureFlagCollection.aiChat">
        <div class="ms-auto d-flex" *appHasPermissions="RequiredSecurities.AI_CHAT_Create">
          <button id="ai-chat-button" class="btn btn-link ai-chat my-1" (click)="openAiChatModal()">
            <ds-icon [icon]="Icons.Sparkles" [iconStyle]="IconStyles.Solid"></ds-icon>
            <span>&nbsp;{{ 'ai_chat' | translate }}</span>
          </button>
        </div>
      </ng-container>
    </div>
  </nav>

  <ng-template #desktopVersion>
    <compound-navbar-link
      *ngFor="let childRoute of childrenRoutes"
      [navLink]="childRoute.routerLink"
      [isActive]="childRoute.isActive"
      [hasChildren]="childRoute.hasChildren"
    >
      {{ childRoute.label | translate }}
    </compound-navbar-link>
  </ng-template>

  <ng-template #mobileVersion>
    <ng-container *ngIf="parentMenuItem$ | async as parentMenuItem">
      <button
        ds-button
        [variant]="DsButtonVariants.microActionHorizontalLayout"
        [microActionIcon]="parentMenuItem.iconName"
        (click)="openNavModal(parentMenuItem)"
        
      >
        {{ parentMenuItem.label | translate }}
      </button>
    </ng-container>
  </ng-template>
</ng-container>
