import {
  CHANGE_USER_EMAILS_MAX_FILE_SIZE,
  CHANGE_USER_EMAILS_MAX_NUMBER_OF_FILES,
} from '../../shared/constants/change-user-emails';
import {
  DESIGN_REVIEW_FINDINGS_MAX_NUMBER,
  DESIGN_REVIEW_NAME_EXCLUDED_CHARACTERS,
  DESIGN_REVIEW_NAME_MAX_LENGTH,
  DESIGN_REVIEW_NAME_MIN_LENGTH,
  DESIGN_REVIEW_SCREENS_MAX_FILE_SIZE,
  DESIGN_REVIEW_SCREENS_MAX_NUMBER_OF_FILES,
} from '../../shared/constants/design-review';
import { PAGE_SCREENSHOT_MAX_FILE_SIZE, PAGE_SCREENSHOT_MAX_NUMBER_OF_FILES } from '../../shared/constants/page';
import { SCANNABLE_DOCUMENT_MAX_UPLOAD_QUANTITY, SIZE_20_MB } from '../../shared/constants/uploads';

export const config = Object.freeze({
  APP: {
    COPYRIGHT_YEAR: '2019',
    ID: 'ada',
    NAME: 'Level Access Platform',
  },
  appUrl: window.location.protocol + '//' + window.location.host,
  company: {
    emailSupport: 'support@levelaccess.com',
    name: 'Level Access',
  },
  externalAppConfig: {
    chromeExtensionUrl: {
      labe: 'https://chrome.google.com/webstore/detail/level-access-extension/kgbmnemfaellbfabmkmmilchbhiigpdi',
      accessibility_checker: 'https://chrome.google.com/webstore/detail/accessibility-checker/lpmhdadpnpgcjabgeehgogpobempedce',
    },
    figmaPluginUrl: 'https://www.figma.com/community/plugin/1268557036921715308/level-access-figma-plugin',
  },
  deployUrl: 'static/',
  features: {
    HTTP_CACHE: false,
  },
  files: {
    maxUploadSize: 250 * 1024 * 1024,
    screenshots: {
      path: 'files/screenshots',
    },
    taskMaxUploadSize: 100 * 1024 * 1024,
  },
  changeUserEmails: {
    maxUploadQuantity: CHANGE_USER_EMAILS_MAX_NUMBER_OF_FILES,
    maxFileUploadSize: CHANGE_USER_EMAILS_MAX_FILE_SIZE,
    maxUploadSize: CHANGE_USER_EMAILS_MAX_FILE_SIZE * CHANGE_USER_EMAILS_MAX_NUMBER_OF_FILES,
  },
  designReviewScreen: {
    maxUploadQuantity: DESIGN_REVIEW_SCREENS_MAX_NUMBER_OF_FILES,
    maxFileUploadSize: DESIGN_REVIEW_SCREENS_MAX_FILE_SIZE,
    maxUploadSize: DESIGN_REVIEW_SCREENS_MAX_FILE_SIZE * DESIGN_REVIEW_SCREENS_MAX_NUMBER_OF_FILES,
    minNameLength: DESIGN_REVIEW_NAME_MIN_LENGTH,
    maxNameLength: DESIGN_REVIEW_NAME_MAX_LENGTH,
    nameExcludeCharacters: DESIGN_REVIEW_NAME_EXCLUDED_CHARACTERS,
    maxFindingQuantity: DESIGN_REVIEW_FINDINGS_MAX_NUMBER,
  },
  pageScreenshot: {
    maxUploadQuantity: PAGE_SCREENSHOT_MAX_NUMBER_OF_FILES,
    maxFileUploadSize: PAGE_SCREENSHOT_MAX_FILE_SIZE,
    maxUploadSize: PAGE_SCREENSHOT_MAX_FILE_SIZE * PAGE_SCREENSHOT_MAX_NUMBER_OF_FILES,
  },
  scannableDocument: {
    maxUploadSize: SIZE_20_MB * SCANNABLE_DOCUMENT_MAX_UPLOAD_QUANTITY,
    maxUploadQuantity: SCANNABLE_DOCUMENT_MAX_UPLOAD_QUANTITY,
    maxFileUploadSize: SIZE_20_MB,
  },
  email: {
    privacy: 'privacy@essentialaccessibility.com',
  },
  locale: {
    default: 'en-us',
  },
  mainContainerId: 'mainContainer',
  path: {
    staticAssets: 'static/assets',
  },
  restApiUrl: '/api',
  token: {
    access: {
      header: 'x-access-token',
      id: 'accessToken',
    },
    refresh: {
      header: 'x-refresh-token',
      id: 'refreshToken',
    },
  },
});
