<div *ngIf="image" class="d-flex flex-column align-items-center margin-xl tool-item">
  <img [src]="'static/assets/images/' + image.src" alt="" [width]="image.width" />
  <h3>{{ label | translate }}</h3>
  <button
    *ngIf="downloadType === DownloadType.button"
    type="button"
    ds-button
    [variant]="buttonVariant"
    (click)="download(tool)"
    [disabled]="disabled"
  >
    {{ buttonName | translate }}
  </button>
  <button
    ds-button
    [variant]="buttonVariant"
    *ngIf="downloadType === DownloadType.internal_link"
    type="button"
    [routerLink]="url"
    [disabled]="disabled"
  >
    {{ buttonName | translate }}
  </button>
  <a
    *ngIf="downloadType === DownloadType.external_link"
    [href]="url"
    target="_blank"
    rel="noopener noreferrer"
    class="btn btn-primary btn-lg"
    
  >
    <span class="margin-right-sm">{{ buttonName | translate }}</span>
    <svg aria-hidden="true"><use xlink:href="#external-link"></use></svg>
  </a>
  <button
    *ngIf="downloadType === DownloadType.install"
    type="button"
    (click)="install(tool)"
    ds-button
    [variant]="buttonVariant"
    [disabled]="disabled"
  >
    {{ buttonName | translate }}
  </button>
  <div *ngIf="downloadType === DownloadType.dropdown" ngbDropdown class="d-inline-block" >
    <button
      [id]="'dropdown_' + dropdownOption[0].tool"
      ds-button
      [variant]="buttonVariant"
      ngbDropdownToggle
      [disabled]="disabled"
    >
      {{ buttonName | translate }}
    </button>
    <div ngbDropdownMenu [attr.aria-labelledby]="'dropdown_' + dropdownOption[0].tool" role="menu">
      <button
        ngbDropdownItem
        *ngFor="let toolItem of dropdownOption; let index = index"
        (click)="handleDropdownClick(toolItem)"
        role="menuitem"
      >
        {{ dropdownOption[index].label | translate }}
      </button>
    </div>
  </div>
</div>
