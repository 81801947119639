import { Pipe, PipeTransform } from '@angular/core';
import { Options } from 'linkifyjs/lib/linkify';
import linkifyHtml from 'linkifyjs/lib/linkify-html';

const defaultOptions: Partial<Options> = {
  target: '_system',
};

@Pipe({
  name: 'linkifyHtml',
})
export class LinkifyHtmlPipe implements PipeTransform {
  transform(value: string, options?: Partial<Options>): string {
    return value ? linkifyHtml(value, Object.assign({}, defaultOptions, options)) : '';
  }
}
