import { Component, ViewChild } from '@angular/core';
import { ModalContainerComponent } from '../modals/modal-container.component';
import { IModal } from '../modals/modal.interface';

@Component({
  selector: 'app-server-version-change',
  templateUrl: './server-version-change-modal.component.html',
})
export class ServerVersionChangeModalComponent implements IModal {
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  public reload(): void {
    location.reload();
  }
}
