import { Directive, ElementRef, OnInit } from '@angular/core';

/**
 * This directive was created to address
 * https://github.com/ng-bootstrap/ng-bootstrap/issues/4181
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[ngbTypeahead]',
})
export class TypeaheadDirective implements OnInit {
  constructor(private elRef: ElementRef) {}

  public ngOnInit(): void {
    this.elRef.nativeElement.ariaMultiLine = null;
  }
}
