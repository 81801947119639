<ng-container *ngIf="{ count: notificationNumber$ | async } as notificationNum">
  <a
    (focus)="this.isFocusing = true"
    (blur)="this.isFocusing = false"
    (mouseenter)="this.isHovering = true"
    (mouseleave)="this.isHovering = false"
    id="nav-link"
    [ds-tooltip]="'notifications' | translate"
    [routerLink]="notificationLink"
    [attr.aria-label]="'unread_number_of_notifications' | translate: notificationNum.count"
  >
    <ds-icon [icon]="Icon" [iconStyle]="IconStyle"></ds-icon>
    <span>{{ notificationNum.count }}</span>
  </a>
</ng-container>
