import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { RestService } from './rest.service';
import { IScanOptions } from '../../../shared/interfaces/scan-options.interface';
import { scanAuthenticationType } from '../../../shared/constants/scanning';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { scanCreateRequest } from '../../../shared/constants/scan-create-request';

@Injectable({
  providedIn: 'root',
})
export class ScanOptionsService implements OnDestroy {
  private subscription: Subscription;
  public currentScanOptions: IScanOptions;
  public scanOptionsChange: Subject<IScanOptions> = new Subject<IScanOptions>();

  constructor(private restService: RestService) {
    this.subscription = new Subscription();

    this.subscription.add(
      this.scanOptionsChange.subscribe((scanOptions: IScanOptions) => {
        this.currentScanOptions = this.mapScanCreateRequest(scanOptions);
      }),
    );
  }

  private mapScanCreateRequest(options: IScanOptions): IScanOptions {
    const incomingData: IScanOptions = {
      [scanCreateRequest.url]: options[scanCreateRequest.url],
      [scanCreateRequest.tag]: options[scanCreateRequest.tag],
      [scanCreateRequest.scanningType]: options[scanCreateRequest.scanningType],
      [scanCreateRequest.scanRecurrenceType]: options[scanCreateRequest.scanRecurrenceType],
      [scanCreateRequest.scanRecurrenceEvery]: options[scanCreateRequest.scanRecurrenceEvery],
      [scanCreateRequest.scanRecurrenceSelectedDay]: options[scanCreateRequest.scanRecurrenceSelectedDay],
      [scanCreateRequest.repeatEvery]: options[scanCreateRequest.repeatEvery],
      [scanCreateRequest.scanAuthenticationType]: options[scanCreateRequest.scanAuthenticationType],
      [scanCreateRequest.scanAuthenticationUserName]: options[scanCreateRequest.scanAuthenticationUserName],
      [scanCreateRequest.scanAuthenticationPassword]: options[scanCreateRequest.scanAuthenticationPassword],
      [scanCreateRequest.scanMonthlyOnDay]: options[scanCreateRequest.scanMonthlyOnDay],
      [scanCreateRequest.limitCrawlingPagesTo]: options[scanCreateRequest.limitCrawlingPagesTo]
        ? options[scanCreateRequest.limitCrawlingPagesTo]
        : 0,
      [scanCreateRequest.crawlDeepLevel]: options[scanCreateRequest.crawlDeepLevel]
        ? options[scanCreateRequest.crawlDeepLevel]
        : 0,
      [scanCreateRequest.includeUrls]: options[scanCreateRequest.includeUrls] ? options[scanCreateRequest.includeUrls] : [],
      [scanCreateRequest.excludeUrls]: options[scanCreateRequest.excludeUrls] ? options[scanCreateRequest.excludeUrls] : [],
      [scanCreateRequest.sendEmail]: options[scanCreateRequest.sendEmail],
      [scanCreateRequest.startScanAtDate]: options[scanCreateRequest.startScanAtDate]
        ? options[scanCreateRequest.startScanAtDate]
        : new Date().toISOString(),
      [scanCreateRequest.stopScanAtDate]: options[scanCreateRequest.stopScanAtDate]
        ? options[scanCreateRequest.stopScanAtDate]
        : new Date().toISOString(),
      [scanCreateRequest.languageForScanning]: options[scanCreateRequest.languageForScanning]
        ? options[scanCreateRequest.languageForScanning]
        : 'en',
      [scanCreateRequest.saveScanOptionsName]: null,
      [scanCreateRequest.selectedScanOptionsId]: null,
      [scanCreateRequest.skipHashes]: options[scanCreateRequest.skipHashes] ? options[scanCreateRequest.skipHashes] : false,
      [scanCreateRequest.skipQueryParams]: options[scanCreateRequest.skipQueryParams]
        ? options[scanCreateRequest.skipQueryParams]
        : false,
      [scanCreateRequest.unlimitedCrawlingPagesTo]: options[scanCreateRequest.skipQueryParams]
        ? options[scanCreateRequest.skipQueryParams]
        : false,
      [scanCreateRequest.unlimitedCrawlDeepLevel]: options[scanCreateRequest.skipQueryParams]
        ? options[scanCreateRequest.skipQueryParams]
        : false,
      [scanCreateRequest.userAgent]: null,
      [scanCreateRequest.viewport]: options[scanCreateRequest.viewport],
      [scanCreateRequest.accessibility]: options[scanCreateRequest.accessibility],
      [scanCreateRequest.essentialBestPractice]: options[scanCreateRequest.essentialBestPractice],
      [scanCreateRequest.colorBlindEmulation]: options[scanCreateRequest.colorBlindEmulation],
      [scanCreateRequest.conformanceLevel]: options[scanCreateRequest.conformanceLevel],
      [scanCreateRequest.headers]: options[scanCreateRequest.headers],
      [scanCreateRequest.handlePopupsSelector]: options[scanCreateRequest.handlePopupsSelector],
    };

    if (incomingData[scanCreateRequest.scanAuthenticationType] === scanAuthenticationType.secure_web_authentication) {
      incomingData[scanCreateRequest.secureWebAuthentication] = SharedCommonUtility.pick(
        options,
        scanCreateRequest.swaUserName,
        scanCreateRequest.swaUserNameFieldId,
        scanCreateRequest.swaPassword,
        scanCreateRequest.swaPasswordFieldId,
        scanCreateRequest.swaSubmitFieldId,
        scanCreateRequest.swaSubmitUsernameFieldId,
        scanCreateRequest.swaSubmitSuccessId,
        scanCreateRequest.swaLoginPageURL,
      );
    }

    return incomingData;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public saveScanOptions(formData: IScanOptions): Observable<IScanOptions> {
    return this.restService.saveScanOptions(formData);
  }

  public updateScanOptions(formData: IScanOptions): Observable<IScanOptions> {
    return this.restService.updateScanOptions(formData);
  }

  public getScanOptionsList(): Observable<IScanOptions[]> {
    return this.restService.getScanOptionsList();
  }

  public setUserDefaultScanOptions(scanOptionsId: string): Observable<IScanOptions> {
    return this.restService.setDefaultScanOptions(scanOptionsId);
  }

  public removeScanOptions(scanId: string): Observable<IScanOptions> {
    return this.restService.removeScanOptions(scanId);
  }

  public setCurrentScanOptions(scanOptions: IScanOptions): void {
    this.scanOptionsChange.next(scanOptions);
  }
}
