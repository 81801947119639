export enum $severity {
  critical = 'critical',
  high = 'high',
  custom1 = 'custom1',
  custom2 = 'custom2',
  low = 'low',
  info = 'info',
}

export enum conformanceLevel {
  a = 'A',
  aa = 'AA',
  aaa = 'AAA',
}

export enum $accessibilityAudit {
  category = 'category',
  description = 'description',
  error = 'error',
  errorFalsePositive = 'errorFalsePositive',
  isMarkedAsFalsePositive = 'isMarkedAsFalsePositive',
  isSelectedForScanning = 'isSelectedForScanning',
  num = 'num',
  passed = 'passed',
  resources = 'resources',
  rule = 'rule',
  score = 'score',
  severity = 'severity',
  standards = 'standards',
  title = 'title',
  url = 'url',
  warning = 'warning',
}

// For custom severities, do not rely on this variable for weights. Instead, use SeverityRankWeightMap from shared/constants/tenant.ts.
export const wcagSeverityWeight: Record<$severity, number> = {
  [$severity.critical]: 10,
  [$severity.high]: 6,
  [$severity.custom2]: 5,
  [$severity.custom1]: 5,
  [$severity.low]: 2,
  [$severity.info]: 0,
};

export const severityToComparable: Record<$severity, string> = Object.freeze({
  [$severity.critical]: '600',
  [$severity.high]: '500',
  [$severity.custom2]: '400',
  [$severity.custom1]: '300',
  [$severity.low]: '200',
  [$severity.info]: '100',
});

export const systemSeverities: readonly $severity[] = [$severity.critical, $severity.high, $severity.low];
export const customSeverities = [$severity.custom1, $severity.custom2] as const;

export type CustomSeverityKeyType = (typeof customSeverities)[number];
