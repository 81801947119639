import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { CommonControlsViewMode } from '../../constants/common-controls-view-mode';
import { config } from '../../../environments/config.shared';
import { IUploadClient } from '../../../../shared/interfaces/uploads.interface';
import { IHTMLInputEvent } from '../../interfaces/form.interface';
import { MimeTypes } from '../../../../shared/constants/mime-type';
import { ITableColumn, ITableConfig, ITableRow, SortEvent } from '../table/ngb-table/utilities/ngb-table.interface';
import { NgbTableUtilities } from '../table/ngb-table/utilities/ngb-table.utilities';
import { $sortingOrder } from '../../../../shared/constants/sort';
import { $uploads } from '../../../../shared/constants/uploads';
import { TranslateService } from '../../translate/translate.service';
import { NotificationPosition } from '../../models/notification.model';
import { NotificationService } from '../../services/notification.service';
import { SharedDateUtility } from '../../../../shared/utils/date.utility';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

export interface CommonDragAndDropFilesFieldContext {
  customMessage?: string;
  label: string;
  tableLabel: string;
  field: string;
  acceptedTypes: string;
  required?: boolean;
  description?: string;
  overrideErrors?: Record<string, string>;
}

export enum defaultDragNDropTableFields {
  name = 'name',
  dateAdded = 'dateAdded',
  fileSize = 'fileSize',
}

export function isUpload(attachment: File | IUploadClient): attachment is IUploadClient {
  return SharedCommonUtility.hasKey(attachment, $uploads.fileName);
}

function defaultTableConfig(translate: TranslateService): ITableConfig {
  const columns: Record<string, ITableColumn> = {
    [defaultDragNDropTableFields.name]: {
      translationKey: 'table_column_name',
      sortingEnabled: true,
    },
    [defaultDragNDropTableFields.dateAdded]: {
      translationKey: 'table_column_date_added',
      sortingEnabled: true,
      styles: {
        maxWidth: '20%',
        width: '20%',
      },
    },
    [defaultDragNDropTableFields.fileSize]: {
      translationKey: 'table_column_file_size',
      sortingEnabled: true,
      styles: {
        maxWidth: '20%',
        width: '20%',
      },
    },
  };

  return {
    columns: columns,
    caption: translate.instant('selected_files_to_upload'),
  };
}

function defaultTableDataMapping(entry: File | IUploadClient): ITableRow {
  if (isUpload(entry)) {
    const upload: IUploadClient = entry as IUploadClient;

    return {
      data: {
        [defaultDragNDropTableFields.dateAdded]: NgbTableUtilities.textCell({
          text: upload[$uploads.createdAt] ? SharedDateUtility.getLocalISODate(upload[$uploads.createdAt]) : '',
        }),
        [defaultDragNDropTableFields.name]: NgbTableUtilities.textCell({
          text: upload[$uploads.fileOriginalName],
        }),
        [defaultDragNDropTableFields.fileSize]: NgbTableUtilities.textCell({
          text: SharedCommonUtility.formatBytes(upload[$uploads.fileSize]),
        }),
      },
    };
  }

  return {
    data: {
      [defaultDragNDropTableFields.dateAdded]: NgbTableUtilities.textCell({
        text: SharedDateUtility.getLocalISODate(new Date()),
      }),
      [defaultDragNDropTableFields.name]: NgbTableUtilities.textCell({
        text: entry.name,
      }),
      [defaultDragNDropTableFields.fileSize]: NgbTableUtilities.textCell({
        text: SharedCommonUtility.formatBytes(entry.size),
      }),
    },
  };
}

/**
 * @deprecated Deprecated. See FileDragAndDropComponent.
 */
@Component({
  selector: 'app-common-drag-and-drop-files',
  templateUrl: './common-drag-and-drop-files.component.html',
  styleUrls: ['./common-drag-and-drop-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonDragAndDropFilesComponent implements OnInit, OnDestroy, OnChanges {
  private subscription: Subscription;
  private _multiple: boolean;

  @ViewChild('nativeInput') public nativeInput: ElementRef<HTMLInputElement>;

  @Input()
  public filesRemovable: boolean;
  @Input()
  public set multiple(isMultiple: boolean) {
    this._multiple = isMultiple;

    if (isMultiple === false) {
      this.maxUploadQuantity = 1;
    } else {
      this.maxUploadQuantity = Number.POSITIVE_INFINITY;
    }
  }

  public get multiple(): boolean {
    return this._multiple;
  }

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public context: CommonDragAndDropFilesFieldContext;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public view: CommonControlsViewMode;
  @Input()
  public tableConfig: ITableConfig;
  @Input()
  public tableDataMapping: (value: File | IUploadClient, index?: number, array?: File[]) => ITableRow;
  @Input()
  public existingAttachments: IUploadClient[];
  @Input()
  public maxUploadSize: number;
  @Input()
  public maxUploadQuantity: number;
  @Input()
  public disabled$: BehaviorSubject<boolean>;
  @Input()
  public uploadDescription: string;
  @Input()
  public displayTable: boolean = true;

  public tableData: ITableRow[];
  public mimeType: typeof MimeTypes;
  public isDragAreaActive: boolean;
  public selectedAttachments: File[];

  @Output()
  public updateSelectedAttachments: EventEmitter<File[]>;
  @Output() existingAttachmentsChange: EventEmitter<IUploadClient[]>;
  @Output()
  public attachmentDeleted: EventEmitter<void>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.view = 'col-view';

    this.mimeType = MimeTypes;
    this.isDragAreaActive = false;
    this.selectedAttachments = [];
    this.existingAttachments = [];
    this.tableData = [];
    this.updateSelectedAttachments = new EventEmitter<File[]>();
    this.existingAttachmentsChange = new EventEmitter<IUploadClient[]>();
    this.attachmentDeleted = new EventEmitter<void>();
    this.maxUploadSize = config.files.maxUploadSize;
    this.tableDataMapping = defaultTableDataMapping;
    this.tableConfig = defaultTableConfig(this.translateService);
    this.disabled$ = new BehaviorSubject<boolean>(false);
    this.maxUploadQuantity = Number.POSITIVE_INFINITY;
    this.multiple = true;
  }

  get required(): boolean {
    return typeof this.context.required === 'boolean' ? this.context.required : true;
  }

  private mergeToExistingFiles(oldFiles: File[], newFiles: File[]): File[] {
    const warningMessage: string = this.translateService.instant('warning');
    const belowSizeLimit = (file: File): boolean => file.size <= this.maxUploadSize;
    const notDuplicated = (file: File): boolean => {
      return newFiles.some((checkFile: File): boolean => checkFile.name === file.name) === false;
    };
    const maxFilesAmount: number = this.maxUploadQuantity - this.existingAttachments.length;

    if (oldFiles.length + newFiles.length > maxFilesAmount) {
      const exceedsLimitMessage: string = this.translateService.instant(
        'form-validation-attachment-quantity-exceeds-limit',
        this.maxUploadQuantity,
      );
      this.notificationService.warn(`${warningMessage}: ${exceedsLimitMessage}`, NotificationPosition.Toast);
    }

    if (newFiles.filter(belowSizeLimit).length !== newFiles.length) {
      const exceedsLimitMessage: string = this.translateService.instant(
        'form-validation-files-that-exceeds-limit-were-not-attached',
        SharedCommonUtility.formatBytes(this.maxUploadSize),
      );
      this.notificationService.warn(`${warningMessage}: ${exceedsLimitMessage}`, NotificationPosition.Toast);
    }

    return [...oldFiles.filter(notDuplicated), ...newFiles.filter(belowSizeLimit)].slice(0, maxFilesAmount);
  }

  private constructTableData(): void {
    if (this.filesRemovable) {
      this.tableData = [...this.existingAttachments, ...this.selectedAttachments].map(
        (entry: File | IUploadClient): ITableRow => {
          const row: ITableRow = this.tableDataMapping(entry);

          row.data['action'] = NgbTableUtilities.buttonCell({
            text: this.translateService.instant('action_delete'),
            classes: ['btn-link', 'font-weight-500', 'p-0'],
            listeners: {
              click: (): void =>
                isUpload(entry)
                  ? this.removeExistingFileFromUploadList(entry as IUploadClient)
                  : this.removeFileFromUploadList(entry as File),
            },
          });

          return row;
        },
      );
    } else {
      this.tableData = [...this.existingAttachments, ...this.selectedAttachments].map(this.tableDataMapping);
    }
    this.changeDetectorRef.detectChanges();
  }

  private sort(attachments: ITableRow[], { column, direction }: SortEvent): ITableRow[] {
    const sortFunction = (a: ITableRow, b: ITableRow): number => {
      const aValue: string = a.data[column].config.text;
      const bValue: string = b.data[column].config.text;

      return direction === $sortingOrder.asc
        ? aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' })
        : bValue.localeCompare(aValue, undefined, { numeric: true, sensitivity: 'base' });
    };

    return attachments.sort(sortFunction);
  }

  public triggerDialog(): void {
    if (this.nativeInput?.nativeElement) {
      this.nativeInput.nativeElement.click();
    }
  }

  public getFormattedFileSize(size: number): string {
    return SharedCommonUtility.formatBytes(size);
  }

  public onTableSort({ column, direction }: SortEvent): void {
    if (direction !== $sortingOrder.all) {
      this.tableData = this.sort([...this.tableData], { column, direction });
    }
  }

  public getSelectedFilesTotalSize(): number {
    let size: number = this.existingAttachments
      .map((value: IUploadClient): number => value.fileSize)
      .reduce((total: number, current: number): number => total + current, 0);

    if (Array.isArray(this.selectedAttachments) === false || this.selectedAttachments.length === 0) {
      return size;
    }

    const addFileSize = (file: File): void => {
      size += file.size;
    };

    this.selectedAttachments.forEach(addFileSize);

    return size;
  }

  public sizeToUploadExceedsLimits(): boolean {
    return this.getSelectedFilesTotalSize() > this.maxUploadSize;
  }

  public onDragEnterOrOver(event: DragEvent): void {
    this.isDragAreaActive = true;

    event.preventDefault();
  }

  public onDragLeave(event: DragEvent): void {
    this.isDragAreaActive = false;

    event.preventDefault();
  }

  public onDropFiles(event: DragEvent): void {
    const files: FileList = event.dataTransfer.files;
    const eventTarget: HTMLElement = event.target || (event.srcElement as any);
    const uploadButtonWrapper: Element = eventTarget.closest('.upload-button');
    const htmlInputElement: HTMLInputElement = uploadButtonWrapper.querySelector('input[type=file]');

    this.selectedAttachments = this.mergeToExistingFiles(this.selectedAttachments, Array.from(files));
    this.updateSelectedAttachments.emit(this.selectedAttachments);

    this.isDragAreaActive = false;

    htmlInputElement.dataset.selectedFilesLength = String(this.selectedAttachments.length);

    this.constructTableData();
    event.preventDefault();
  }

  public removeFileFromUploadList(file: File): void {
    this.selectedAttachments = this.selectedAttachments.filter((_file: File): boolean => file !== _file);
    this.updateSelectedAttachments.emit(this.selectedAttachments);
    this.attachmentDeleted.emit();
    this.constructTableData();
  }

  public removeExistingFileFromUploadList(upload: IUploadClient): void {
    this.existingAttachments = this.existingAttachments.filter((_upload: IUploadClient): boolean => _upload !== upload);
    this.existingAttachmentsChange.emit(this.existingAttachments);
    this.constructTableData();
  }

  public uploadFilesEvent(event: Event): void {
    const htmlEvent: IHTMLInputEvent = event as IHTMLInputEvent;
    const files: FileList = htmlEvent.target.files;

    this.uploadFiles(Array.from(files));

    // Ensures onChange hook is called even on file reupload
    htmlEvent.target.value = '';
  }

  public uploadFiles(files: File[]): void {
    this.selectedAttachments = this.mergeToExistingFiles(this.selectedAttachments, files);
    this.updateSelectedAttachments.emit(this.selectedAttachments);
    this.constructTableData();
  }

  public ngOnInit(): void {
    if (this.formValidationRequest$) {
      this.subscription = this.formValidationRequest$.subscribe(() => {
        this.form.get(this.context.field).markAsDirty();
        this.form.get(this.context.field).markAsTouched();
        this.changeDetectorRef.detectChanges();
      });
    }

    if (this.filesRemovable) {
      this.tableConfig = { ...this.tableConfig };
      this.tableConfig.columns['action'] = {
        translationKey: 'table_header_action',
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      };
      this.changeDetectorRef.detectChanges();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const areAttachmentsEqual = (prev: IUploadClient[], curr: IUploadClient[]): boolean => {
      if (Array.isArray(curr) && !Array.isArray(prev)) {
        return false;
      } else if (!Array.isArray(curr) && !Array.isArray(prev)) {
        return true;
      }
      return (
        prev.every((prevUpload: IUploadClient) =>
          curr.some((currUpload: IUploadClient) => prevUpload[$uploads._id] === currUpload[$uploads._id]),
        ) &&
        curr.every((currUpload: IUploadClient) =>
          prev.some((prevUpload: IUploadClient) => prevUpload[$uploads._id] === currUpload[$uploads._id]),
        )
      );
    };
    if (!areAttachmentsEqual(changes.existingAttachments?.previousValue, changes.existingAttachments?.currentValue)) {
      this.constructTableData();
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
