<form *ngIf="independentForm" (ngSubmit)="onSubmitPasswordChange()" [formGroup]="formChangePassword">
  <p>{{ 'all_fields_are_required' | translate }}</p>
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</form>
<ng-container *ngIf="!independentForm">
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</ng-container>

<ng-template #mainContent>
  <div class="row">
    <div class="col-12">
      <app-alert
        *ngIf="serverErrorInfoBox"
        id="serverErrorInfoBox"
        [type]="alertType.danger"
        [header]="'error_updating_profile' | translate"
        [message]="serverErrorInfoBoxReason"
        (onClose)="closeServerInfoBox()"
      ></app-alert>
      <ng-container *ngIf="currentPasswordRequired">
        <label id="current-password-label">{{ 'current_password' | translate }} *</label>
        <div class="row">
          <div class="col-auto" [formGroup]="formChangePassword">
            <input
              type="password"
              required="true"
              id="currentPassword"
              formControlName="currentPassword"
              [attr.aria-invalid]="!isFieldValid('currentPassword')"
              autocomplete="current-password"
              class="form-element my-2"
              aria-labelledby="current-password-label"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- ### -->

  <div class="row form-row mt-1">
    <div class="col" [formGroup]="formChangePassword">
      <p>{{ 'your_password_needs_to_meet_following_requirements' | translate }}</p>

      <app-alert
        *ngIf="formErrorInfoBox"
        id="formErrorInfoBox"
        [type]="alertType.danger"
        [header]="'error_updating_profile' | translate"
        [message]="formErrorInfoBoxReason"
        (onClose)="closeFormInfoBox()"
      ></app-alert>

      <ng-container *ngIf="formChecklist$ | async as formChecklist">
        <ul>
          <li [style.list-style-type]="'none'" *ngFor="let error of formChecklist" class="ms-2 my-2">
            <ds-icon *ngIf="!error.valid" [icon]="Icons.CircleExclamation" class="error"></ds-icon>
            <ds-icon *ngIf="error.valid" [iconStyle]="IconStyles.Solid" [icon]="Icons.SquareCheck" class="valid"></ds-icon>
            {{ error.translation }}
          </li>
        </ul>
      </ng-container>
      <div class="d-flex flex-column">
        <label id="new-password-label" class="my-2">{{ 'password' | translate }} *</label>
        <input
          type="password"
          id="newPassword"
          formControlName="newPassword"
          [attr.aria-invalid]="!isFieldValid('newPassword')"
          autocomplete="new-password"
          class="form-element mt-1 mb-2"
          aria-labelledby="new-password-label"
        />
      </div>
    </div>
  </div>

  <!-- ### -->

  <div class="row form-row" *ngIf="independentForm">
    <div class="col-12">
      <button type="submit" ds-button [variant]="DsButtonVariants.primary" [disabled]="formChangePassword.disabled">
        {{ submitButtonLabel | translate }}
      </button>
    </div>
  </div>
</ng-template>
