<form name="form" (ngSubmit)="onSubmit()" [formGroup]="form">
  <fieldset>
    <legend class="visuallyhidden">{{ 'mfa' | translate }}</legend>

    <div class="row form-row">
      <div class="col-md-12">
        <h1 id="login">{{ 'mfa' | translate }}</h1>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-md-12 align-items-center d-flex margin-bottom-xs">
        <label aria-required="true">{{ 'mfa_enter_code_description' | translate }}</label>
      </div>
      <div class="col-md-12">
        <app-common-input [form]="form" [context]="formContext"></app-common-input>
      </div>
    </div>

    <div class="row margin-bottom">
      <div class="col-md-12 text-end">
        <button type="submit" class="btn-login btn-lg w-100 justify-content-center" [disabled]="form.disabled || form.invalid">
          {{ 'button_login' | translate }}
        </button>
      </div>
    </div>
  </fieldset>
</form>
