import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IHttpQueryOptions } from '../../../shared/interfaces/http.query.interface';
import { AdminRestAPI } from './rest/admin.api';
import {
  IManualAudit,
  IManualEvalCreateRequest,
  IManualAuditServerResponse,
  IPopulatedManualAudit,
  IManualAuditUpdateRequest,
} from '../../../shared/interfaces/manual-audit.interface';
import { RestBuilder } from './helpers/rest.builder';
import { RestService } from './rest.service';
import { ITestGuideSimpleServerResponse } from '../../../shared/interfaces/test-guide.interface';
import { IManualScanEdit } from '../../../shared/interfaces/scan.interface';
import { TestGuidesApi } from './rest/test-guides.api';
import { WorkspacesRestAPI } from './rest/workspaces.api';

@Injectable({
  providedIn: 'root',
})
export class ManualAuditService {
  private restBuilder: RestBuilder;

  constructor(
    private adminRestAPI: AdminRestAPI,
    private restService: RestService,
    private testGuidesApi: TestGuidesApi,
    private workspacesApi: WorkspacesRestAPI,
  ) {
    this.restBuilder = new RestBuilder();
  }

  public createManualAuditWithFile(workspaceId: string, digitalPropertyId: string, formData: FormData): Observable<IManualAudit> {
    return this.adminRestAPI.createManualAuditWithFile(workspaceId, digitalPropertyId, formData);
  }

  public createEmptyEvaluation(
    workspaceId: string,
    digitalPropertyId: string,
    request: IManualEvalCreateRequest,
    options: { useAdminApi: boolean } = { useAdminApi: true },
  ): Observable<IManualAudit> {
    if (options.useAdminApi) {
      return this.adminRestAPI.createManualAudit(workspaceId, digitalPropertyId, request);
    }
    return this.workspacesApi.createEmptyEvaluation(workspaceId, digitalPropertyId, request);
  }

  public getEvaluation(
    workspaceId: string,
    digitalPropertyId: string,
    manualEvaluationId: string,
    options: { useAdminApi: boolean } = { useAdminApi: true },
  ): Observable<IManualScanEdit> {
    return options.useAdminApi
      ? this.adminRestAPI.getManualAuditWithPages(workspaceId, digitalPropertyId, manualEvaluationId)
      : this.workspacesApi.getEvaluationMetadata(workspaceId, digitalPropertyId, manualEvaluationId);
  }

  public patchEvaluation(
    workspaceId: string,
    digitalPropertyId: string,
    manualEvaluationId: string,
    request: IManualEvalCreateRequest,
    options: { useAdminApi: boolean } = { useAdminApi: true },
  ): Observable<IManualAudit> {
    return options.useAdminApi
      ? this.adminRestAPI.patchManualAudit(workspaceId, digitalPropertyId, manualEvaluationId, request)
      : this.workspacesApi.patchEvaluationMetadata(workspaceId, digitalPropertyId, manualEvaluationId, request);
  }

  public archiveEvaluation(
    workspaceId: string,
    digitalPropertyId: string,
    manualEvaluationId: string,
    options: { useAdminApi: boolean } = { useAdminApi: true },
  ): Observable<IPopulatedManualAudit> {
    return options.useAdminApi
      ? this.adminRestAPI.archiveManualAudit(workspaceId, digitalPropertyId, manualEvaluationId)
      : this.workspacesApi.archiveEvaluationMetadata(workspaceId, digitalPropertyId, manualEvaluationId);
  }

  public getAllManualAudits(queryOptions: IHttpQueryOptions): Observable<IManualAuditServerResponse> {
    return this.adminRestAPI.getAllManualAudits(queryOptions);
  }

  public getManualAuditDownload(resourceId: string): string {
    return this.restBuilder.create().secureResources(resourceId).getApiUrl();
  }

  public manualAuditDownloadFile(resourceId: string): Observable<void> {
    const url: string = this.getManualAuditDownload(resourceId);
    return this.restService.download(url);
  }

  public manualUploadFile(
    workspaceId: string,
    digitalPropertyId: string,
    existingManualAuditId: string,
    formData: FormData,
  ): Observable<IManualAudit> {
    return this.adminRestAPI.manualUploadFile(workspaceId, digitalPropertyId, existingManualAuditId, formData);
  }

  public getManualAuditsForDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IPopulatedManualAudit[]> {
    return this.adminRestAPI.getAllManualAuditsForDigitalProperty(workspaceId, digitalPropertyId);
  }

  public updateManualAudit(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    updateRequest: IManualAuditUpdateRequest,
  ): Observable<IPopulatedManualAudit> {
    return this.adminRestAPI.updateManualAudit(workspaceId, digitalPropertyId, scanId, updateRequest);
  }

  public getTestGuideNames(dpId?: string, filterLegacyEA: boolean = false): Observable<ITestGuideSimpleServerResponse> {
    return this.testGuidesApi.getTestGuideNames(dpId, filterLegacyEA);
  }
}
