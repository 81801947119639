import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';

import { IScanOptions } from '../../../../../../shared/interfaces/scan-options.interface';
import { scanOptionsFormFields } from '../../../../../../shared/constants/scan-options';
import { scanCreateRequest } from '../../../../../../shared/constants/scan-create-request';
import { scanningType } from '../../../../../../shared/constants/scanning';

@Injectable({
  providedIn: 'root',
})
export class AutomatedScanAdvancedService {
  private scanOptionsSubject: BehaviorSubject<IScanOptions>;
  private scanningTypeSubject: BehaviorSubject<scanningType> = new BehaviorSubject<scanningType>(scanningType.singlePage);

  constructor() {
    this.scanOptionsSubject = new BehaviorSubject<IScanOptions>({
      _id: scanOptionsFormFields.$defaultScanOptions,
    } as IScanOptions);
  }

  public setScanOptions(options: IScanOptions): void {
    this.scanOptionsSubject.next(options);
  }

  public patchScanOptions(options: Partial<IScanOptions>): void {
    const currentValue: IScanOptions = this.scanOptionsSubject.getValue();
    this.scanOptionsSubject.next({
      ...currentValue,
      ...options,
    });
  }

  public onScanOptionsChanged(): Observable<IScanOptions> {
    return this.scanOptionsSubject.asObservable().pipe(delay(0));
  }

  public assignScanOptions(
    form: UntypedFormGroup,
    values: Partial<Record<scanCreateRequest, any>>,
    defaults?: Partial<Record<scanCreateRequest, any>>,
  ): void {
    form.patchValue(Object.assign({}, values, defaults));
    form.markAsUntouched();
    Object.keys(values).forEach((key: scanCreateRequest): void => form.get(key)?.markAsUntouched());
  }

  public getScanningType(): scanningType {
    return this.scanningTypeSubject.getValue();
  }

  public getScanOptions(): IScanOptions {
    return this.scanOptionsSubject.getValue();
  }

  public setScanningType(type: scanningType): void {
    this.scanningTypeSubject.next(type);
  }

  public getScanningType$(): Observable<scanningType> {
    return this.scanningTypeSubject.asObservable();
  }
}
