import { SCREENSHOT_MAX_FILE_SIZE } from './screenshot';

export enum $page {
  _id = '_id',
  name = 'name',
  digitalPropertyId = 'digitalPropertyId',
  description = 'description',
  screenshot = 'screenshot',
  url = 'url',
}

export enum $pageImage {
  src = 'src',
  altText = 'altText',
}

export const DEFAULT_PAGE_SCREENSHOT_ALT_TEXT: string = 'Screenshot of a page';

export const PAGE_CHAR_LIMITS: Readonly<Record<string, number>> = {
  [$page.name]: 100,
  [$page.description]: 250,
  [$page.url]: 2048,
  [$pageImage.altText]: 100,
};

export const PAGE_SCREENSHOT_MAX_NUMBER_OF_FILES: number = 1;
export const PAGE_SCREENSHOT_MAX_FILE_SIZE: number = SCREENSHOT_MAX_FILE_SIZE;
