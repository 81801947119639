import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, skipWhile, timeout, withLatestFrom, filter } from 'rxjs/operators';
import { VariationValue } from '@harnessio/ff-javascript-client-sdk';

import { UserService } from '../../user.service';
import { AppConfigService } from '../../app-config.service';
import { Api } from '../../../../../shared/constants/api';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../../../shared/interfaces/feature-flag.interface';
import { AclSecurityAdapter } from '../../acl.service';
import { RequiredSecurities } from '../../../../../shared/constants/required-securities';
import { UserAclService } from '../../user-acl.service';
import { AngularUtility } from '../../../utility/angular.utility';
import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { $user } from '../../../../../shared/constants/user';
import { ISecurityGroup } from '../../../../../shared/interfaces/security-group.interface';
import { $securityGroup, SecurityGroupTemplateNames, SecurityGroupOrigin } from '../../../../../shared/constants/security-group';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';

/**
 * Allows the user to continue navigation to the home page.
 * If they are on new security and admin env then they will be redirected to an alternate home page (currently the profile page).
 * If they are on new security, old navigation and tenant env then they will be redirected to the dashboard page.
 * If they are on new navigation and tenant env then they will be redirected to the landing page that their permissions allow.
 * If they are on old security and an admin or have permissions to run scans then they will be redirected to the dashboard page.
 * Else they will be redirected to the profile page.
 * Should only be used on routes denoting a home page.
 */
@Injectable()
export class HomePageRedirectGuard {
  constructor(
    private userService: UserService,
    private userAclService: UserAclService,
    private appConfigService: AppConfigService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> {
    if (!this.userService.isAuthenticated()) {
      const extras: NavigationExtras = {
        queryParams: {
          returnTo: `/${Api.home}`,
        },
      };
      return of(this.router.createUrlTree(['/', `${Api.login}`], extras));
    }

    const ffClientInitialized$: Observable<boolean> = this.featureFlagService.isClientInitialized$.pipe(
      timeout(1000),
      skipWhile((initialized: boolean): boolean => initialized === false),
      AngularUtility.shareRef(),
    );

    const hasAccessToAdminOrganizations$: Observable<boolean> = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useFunctionalActions(RequiredSecurities.Tenants_Read.functionalActions)
            .useOptions({ requireFunctionalActions: true })
            .useRequiredSecurity(RequiredSecurities.Tenants_Read, true)
            .check(),
        ),
      );

    const hasAccessToOverview$: Observable<boolean> = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useFunctionalActions(RequiredSecurities.GR_Governance_Read.functionalActions)
            .useOptions({ requireFunctionalActions: true })
            .useRequiredSecurity(RequiredSecurities.GR_Governance_Read, false)
            .check(),
        ),
      );

    const hasAccessToPortfolio$: Observable<boolean> = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useFunctionalActions(RequiredSecurities.Workspaces_Read_Digital_Properties_Read.functionalActions)
            .useOptions({ requireFunctionalActions: true })
            .useRequiredSecurity(RequiredSecurities.Workspaces_Read_Digital_Properties_Read, false)
            .check(),
        ),
      );

    const hasAccessToDashboard$: Observable<boolean> = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter
            .useFunctionalActions(RequiredSecurities.GR_Dashboard_Read.functionalActions)
            .useOptions({ requireFunctionalActions: true })
            .useRequiredSecurity(RequiredSecurities.GR_Dashboard_Read, false)
            .check(),
        ),
      );

    const organizationPortfolioEnabled$: Observable<boolean> = ffClientInitialized$.pipe(
      withLatestFrom(this.featureFlagService.variation$(FeatureFlagCollection.organizationPortfolio, false)),
      map(([_, value]: [boolean, VariationValue]): boolean => Boolean(value)),
      catchError(() => of(false)),
    );

    const isOrganizationAdmin$: Observable<boolean> = this.userService.userDataChanged$.pipe(
      filter(SharedCommonUtility.notNullish),
      map((user: IUserServerResponse): boolean =>
        user[$user.groups]?.some(
          (group: ISecurityGroup): boolean =>
            group[$securityGroup.origin] === SecurityGroupOrigin.predefined &&
            group[$securityGroup.name] === SecurityGroupTemplateNames.organization_administrator,
        ),
      ),
    );

    return combineLatest([
      hasAccessToAdminOrganizations$,
      hasAccessToOverview$,
      hasAccessToPortfolio$,
      hasAccessToDashboard$,
      organizationPortfolioEnabled$,
      isOrganizationAdmin$,
    ]).pipe(
      map(
        ([
          hasAccessToAdminOrganizations,
          hasAccessToOverview,
          hasAccessToPortfolio,
          hasAccessToDashboard,
          organizationPortfolioEnabled,
          isOrganizationAdmin,
        ]: [boolean, boolean, boolean, boolean, boolean, boolean]): UrlTree => {
          const onTenantEnv: boolean = this.appConfigService.isTenantEnabled();

          const getRoute = (): string => {
            const defaultRoute: string = `/${Api.settings}/${Api.profile}`;
            if (!onTenantEnv) {
              return hasAccessToAdminOrganizations ? `/${Api.admin}/${Api.organizations}` : defaultRoute;
            }
            if (organizationPortfolioEnabled && isOrganizationAdmin) {
              return `/${Api.organization}/${Api.portfolio}`;
            }
            if (hasAccessToOverview) {
              return `/${Api.overview}`;
            }
            if (hasAccessToPortfolio) {
              return `/${Api.portfolio}`;
            }
            if (hasAccessToDashboard) {
              return `/${Api.dashboard}`;
            }
            return defaultRoute;
          };

          return this.router.parseUrl(getRoute());
        },
      ),
    );
  }
}
