export enum $workspaceUser {
  _id = '_id',
  createdAt = 'createdAt',
  email = 'email',
  entriesPerPage = 'entriesPerPage',
  invitationDate = 'invitationDate',
  roles = 'roles',
  updatedAt = 'updatedAt',
  user = 'user',
  workspace = 'workspace',
  invitationStatus = 'invitationStatus',
}

export enum $invitationStatus {
  pending = 'pending',
  expired = 'expired',
  completed = 'completed',
}

export enum WorkspaceUserSearchFields {
  excludeForPredefinedRoles = 'excludeForPredefinedRoles',
  userDisplayName = 'userDisplayName',
  workspaceRolesIds = 'workspaceRolesIds',
  status = 'status',
  includeStaffUsers = 'includeStaffUsers',
}
