import { Pipe, PipeTransform } from '@angular/core';
import { DateUtility } from '../utility/date.utility';

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  public transform(value: string | Date, ...args: unknown[]): string {
    return DateUtility.getFormattedISODate(value instanceof Date ? value.toISOString() : value);
  }
}
