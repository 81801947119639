import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { CommonUtility } from '../../utility/common.utility';
import { KEYS } from '../../../../shared/constants/keys';

@Directive({
  selector: '[appNgbNav]',
  exportAs: 'appNgbNav',
})
export class NgbNavDirective implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription;

  constructor(
    private element: ElementRef,
    private route: ActivatedRoute,
  ) {
    this.subscriptions = new Subscription();
  }

  private resetTabs(): void {
    const tabsList: HTMLElement = this.element.nativeElement;
    const allTabs: NodeListOf<HTMLAnchorElement> = Array.prototype.slice.call(tabsList.querySelectorAll('a'), 0);

    const resetTab = (tab: HTMLAnchorElement): void => {
      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-selected', 'false');
      tab.setAttribute('aria-expanded', 'false');
    };

    allTabs.forEach(resetTab);
  }

  private setActiveTab(tab: HTMLAnchorElement): void {
    tab.setAttribute('tabindex', '0');
    tab.setAttribute('aria-selected', 'true');
    tab.setAttribute('aria-expanded', 'true');
  }

  ngAfterViewInit(): void {
    const tabsList: HTMLElement = this.element.nativeElement;
    const allTabs: HTMLAnchorElement[] = Array.prototype.slice.call(tabsList.querySelectorAll('a'), 0);

    const applyTabChange = (selectedId: string): void => {
      if (typeof selectedId === 'string' && selectedId.length > 0) {
        const findTab = (tab: HTMLAnchorElement): boolean => {
          return tab.hash.replace('#', '') === selectedId;
        };

        const foundTabIndex: number = allTabs.findIndex(findTab);

        if (foundTabIndex === -1) {
          return;
        }

        this.resetTabs();
        this.setActiveTab(allTabs[foundTabIndex]);
      } else {
        const selected: HTMLAnchorElement = allTabs.find((a: HTMLAnchorElement) => a.getAttribute('aria-selected') === 'true');
        if (typeof selected === 'undefined') {
          return;
        }
        this.resetTabs();
        this.setActiveTab(selected);
      }
    };

    if (this.route.fragment) {
      this.subscriptions.add(this.route.fragment.subscribe(applyTabChange));
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private goToPreviousTab(tab: HTMLAnchorElement): void {
    const previous: Element = tab.closest('a').previousElementSibling;

    if (previous === null) {
      return;
    }

    this.resetTabs();
    this.setActiveTab(previous as HTMLAnchorElement);
    CommonUtility.setFocusToElement(null, previous as HTMLAnchorElement);
  }

  private goToNextTab(tab: HTMLAnchorElement): void {
    const next: Element = tab.closest('a').nextElementSibling;

    if (next === null) {
      return;
    }

    this.resetTabs();
    this.setActiveTab(next as HTMLAnchorElement);
    CommonUtility.setFocusToElement(null, next as HTMLAnchorElement);
  }

  @HostListener('keydown', ['$event'])
  public keydownEvent(event: Event): void {
    const target: HTMLElement = (event.target as HTMLElement) || (event.srcElement as any);

    if (event.type === 'keydown') {
      if ((event as KeyboardEvent).keyCode === KEYS.LEFT) {
        this.goToPreviousTab(target as HTMLAnchorElement);
        event.preventDefault();
      } else if ((event as KeyboardEvent).keyCode === KEYS.RIGHT) {
        this.goToNextTab(target as HTMLAnchorElement);
        event.preventDefault();
      } else if ((event as KeyboardEvent).keyCode === KEYS.SPACE) {
        target.click();
        event.preventDefault();
      }

      return;
    }
  }
}
