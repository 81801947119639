import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { Icons, DsButtonVariants, IconStyles } from '@levelaccess/design-system';
import { EapColors } from '../../../../shared/eap-colors';

export enum BannerType {
  info = 'info',
  error = 'error',
  check = 'check',
}

/**
 * TODO DS-186: get rid of this component once alternative is added to the
 * design system components
 */
@Component({
  selector: 'app-edit-permissions-banner',
  templateUrl: './edit-permissions-banner.component.html',
  styleUrls: ['./edit-permissions-banner.component.scss'],
})
export class EditPermissionsBannerComponent implements OnInit {
  public Icons: typeof Icons = Icons;
  public EapColors: typeof EapColors = EapColors;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public IconStyles: typeof IconStyles = IconStyles;
  public BannerType: typeof BannerType = BannerType;

  @Input() public scrollInto: boolean = false;
  @Input() public bannerType: BannerType = BannerType.info;
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    if (this.scrollInto) {
      this.elementRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
}
