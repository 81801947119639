import { Component, Input } from '@angular/core';
import { Icons } from '../../shared/eap-icons.component';

@Component({
  selector: 'app-icon',
  styles: [':host { line-height: 16px; color: #333; }'],
  template: `
    <svg aria-hidden="true" width="16px" height="16px" viewBox="0 0 16 16">
      <use [attr.href]="'#' + icon"></use>
    </svg>
  `,
})
export class IconComponent {
  /**
   * Icon colour is inherited from parent 'color' attribute.
   */
  @Input() icon: Icons;
}
