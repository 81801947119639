<div class="dropdown">
  <button
    class="btn btn-dropdown-toggle d-flex justify-content-between align-items-center"
    type="button"
    data-bs-toggle="dropdown"
    [attr.aria-expanded]="toggled"
    [attr.aria-label]="label"
    aria-haspopup="true"
    [disabled]="!(availableValues?.length > 0)"
    (click)="toggle()"
    (keydown)="handleButtonKeyDown($event)"
    #toggleButton
  >
    <span>{{ selectedText$.value }}</span>
    <svg aria-hidden="true"><use xlink:href="#caret-down-fill"></use></svg>
  </button>
  <div class="dropdown-menu" [class.show]="toggled">
    <form [formGroup]="form">
      <ul [formArrayName]="valuesControlArray" class="value-items">
        <li *ngFor="let ctrl of valuesArray.controls; let i = index" class="value-item">
          <label>
            <input
              type="checkbox"
              [formControlName]="i"
              (change)="checkBoxSelectionChanged(i)"
              [attr.disabled]="isDisabled(i) ? true : null"
              (keydown)="handleInputKeyDown($event, i)"
            />
            <span class="checkbox-label">{{ availableValues[i] }}</span>
          </label>
        </li>
      </ul>
    </form>
  </div>
</div>
