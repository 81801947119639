import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { has } from 'lodash';

import { CommonUtility } from '../utility/common.utility';
import { DEFAULT_SCROLL_HEIGHT, DEFAULT_VALIDATION_BOUNCE_TIME, RESPONSE_ERROR_MESSAGES } from '../shared/constants';
import { IErrorPageScreenshot } from '../../../shared/interfaces/error.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

const SCROLL_TO_ERROR_FACTOR: number = 1.7;
const ERROR_MESSAGE_SELECTOR: string = '.form-control-validation-error.error-active,div.ds-input-error-message';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  private errorResponseMessages: Array<string>;

  public errorResponseMessages$: BehaviorSubject<any>;

  constructor() {
    this.errorResponseMessages$ = new BehaviorSubject<any>([]);
    this.errorResponseMessages = [];
  }

  public addErrorResponseMessage(error: string): void {
    this.errorResponseMessages.push(error);
    this.errorResponseMessages$.next(this.errorResponseMessages);
  }

  public setFocusOnFirstError(component: Element, bounceTime: number = DEFAULT_VALIDATION_BOUNCE_TIME): void {
    const setFocus = (): void => {
      const header: HTMLElement = document.getElementById('app-header');
      let scrollHeight: number = DEFAULT_SCROLL_HEIGHT;

      if (SharedCommonUtility.notNullish(header)) {
        scrollHeight = header.getBoundingClientRect().height;
      }

      const element: HTMLElement = component.querySelector(ERROR_MESSAGE_SELECTOR);
      const scrollTop: number = -(scrollHeight * SCROLL_TO_ERROR_FACTOR);

      if (element === null) {
        console.warn(
          '[ErrorMessageService.setFocusOnFirstError] Trying to set a focus on element that does not exists',
          component,
        );
        return;
      }

      const fieldId: string = element.classList.contains('ds-input-error-message')
        ? element.id
        : element.parentElement?.parentElement?.getAttribute('id');

      const fieldElement: HTMLElement =
        (fieldId && component.querySelector(`[aria-describedby~="${fieldId}"],[aria-labelledby~="${fieldId}"]`)) || null;

      CommonUtility.scrollElementIntoView(element);
      if (SharedCommonUtility.isNullish(fieldElement)) {
        document.addEventListener('scrollend', () => window.scrollBy({ top: scrollTop }), { once: true });
      }

      if (fieldElement) {
        fieldElement.focus();
      } else {
        element.focus();
      }
    };

    window.setTimeout(setFocus, bounceTime);
  }

  public getTranslateKey(errorName: string): string | null {
    let key: string | null = null;

    if (typeof RESPONSE_ERROR_MESSAGES[errorName] === 'string') {
      key = RESPONSE_ERROR_MESSAGES[errorName];
    }

    return key;
  }

  public getAppErrorResponse(response: HttpErrorResponse): string | null {
    let key: string | null = null;

    if (has(response, 'error.app.name')) {
      key = this.getTranslateKey(response.error.app.name);
    }

    return key;
  }

  public getGlobalErrorResponse(response: HttpErrorResponse): string {
    let error: any = '';

    if (has(response, 'error.app.description')) {
      error = response.error.app.description;
    } else if (has(response, 'message')) {
      error = response.message;
    }

    if (has(response, 'error.error.code')) {
      error += '\n\nCode: ' + response.error.error.code;
    }

    if (has(response, 'error.error.errmsg')) {
      error += '\n\nErrmsg: ' + response.error.error.errmsg;
    }

    if (has(response, 'error.error.description')) {
      error += '\n\nDescription: ' + response.error.error.description;
    }

    if (has(response, 'error.error.message')) {
      error += '\n\nMessage: ' + response.error.error.message;
    }

    return error;
  }

  public getPageScreenshotData(response: HttpErrorResponse): IErrorPageScreenshot {
    return response.error.app.details;
  }

  public isErrorScreenshotAvailable(response: HttpErrorResponse): boolean {
    return has(response, 'error.app.details');
  }
}
