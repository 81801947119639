<div class="d-flex mb-2 align-items-center">
  <span class="h1 m-0" >
    {{ 'percent_formatter' | translate: [progressPercentage.percentage] }}
  </span>
  <ds-icon
    *ngIf="isPolicyViolated"
    class="mx-2 my-1 policy-violated-icon"
    
    tabindex="0"
    [icon]="Icons.Flag"
    [iconStyle]="IconStyles.Solid"
    [tooltipPlacement]="DsTooltipPosition.top"
    [ds-tooltip]="'policy_violated' | translate"
    [attr.aria-label]="'policy_violated' | translate"
  ></ds-icon>
</div>

<div class="progress mt-2 mb-3" [style.height]="'1.5rem'">
  <div
    class="progress-bar"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    [class.violated]="isPolicyViolated"
    [style.width]="progressPercentage.percentage + '%'"
    [attr.aria-label]="'resolved_findings_percentage' | translate"
    [attr.aria-valuenow]="progressPercentage.percentage"
  ></div>
</div>

<div >
  {{ 'resolved_findings_count' | translate: [formattedResolvedCount] }}
</div>

<div *ngIf="progressPercentage.previousDate" class="mt-2" >
  <ds-icon
    *ngIf="isPositiveVariation"
    [icon]="Icons.ArrowTrendUp"
    [iconStyle]="IconStyles.Regular"
    [style.color]="EapColors.GreenSeverityLow"
    class="me-1"
  ></ds-icon>
  <ds-icon
    *ngIf="isNegativeVariation"
    [icon]="Icons.ArrowTrendDown"
    [iconStyle]="IconStyles.Regular"
    [style.color]="EapColors.RedError"
    class="me-1"
  ></ds-icon>
  <span aria-hidden="true">
    {{ 'resolved_findings_variation' | translate: [formattedVariation, progressPercentage.previousDate] }}
  </span>
  <span class="visuallyhidden" >
    <ng-container *ngIf="isUnchangedVariation">
      {{ 'resolved_findings_percentage_unchanged' | translate: [progressPercentage.previousDate] }}
    </ng-container>
    <ng-container *ngIf="isPositiveVariation">
      {{
        'resolved_findings_percentage_increase' | translate: [progressPercentage.absVariation, progressPercentage.previousDate]
      }}
    </ng-container>
    <ng-container *ngIf="isNegativeVariation">
      {{
        'resolved_findings_percentage_decrease' | translate: [progressPercentage.absVariation, progressPercentage.previousDate]
      }}
    </ng-container>
  </span>
</div>
