export interface ISendChatMessageRequest {
  message: string;
  sessionId?: string;
}

export interface IGatewayRequest {
  userId: string;
}

export interface ISendChatMessageGatewayRequest extends ISendChatMessageRequest, IGatewayRequest {}

export interface ISetChatMessageRatingRequest {
  rating: number;
}

export interface ISetChatMessageRatingGatewayRequest extends ISetChatMessageRatingRequest, IGatewayRequest {}

export type IUnsetChatMessageRatingGatewayRequest = IGatewayRequest;

export enum IChatMessageStatus {
  pending = 'pending',
  processing = 'processing',
  done = 'done',
  error = 'error',
}

export interface ISendChatMessageResponse {
  _id: string;
  message: string;
  userId: string;
  sessionId?: string;
  status: IChatMessageStatus;
  response?: string;
  createdAt: string;
  startedAt?: string;
  endedAt?: string;
  error?: string;
  rating?: number;
}
