<ds-input
  class="mb-4"
  [label]="'label_scan_tag' | translate"
  [required]="required"
  [errorState]="errorState"
  [errorMessage]="errorMessage"
>
  <select ds-input-field class="mw-100" id="scan-tag-selector" (change)="onScanTagChange($event)" [disabled]="disabled">
    <option [value]="null" [disabled]="disabledUnselectedValue">{{ 'label_select_a_scan_tag' | translate }}</option>
    <option *ngFor="let tag of availableTags" [value]="tag[$scanTag._id]" [selected]="tag[$scanTag._id] === selectedTagId">
      {{ tag[$scanTag.tagName] }}
    </option>
  </select>
</ds-input>
