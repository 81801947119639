import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, finalize, switchMap, take, tap } from 'rxjs/operators';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { trainingVideoAllowedHostnames } from '../../../shared/constants/training-video';
import { $user } from '../../../shared/constants/user';
import { WorkspaceTypes } from '../../../shared/constants/workspace';
import { IGetAvailableWorkspacesQuery } from '../../../shared/interfaces/admin.interface';
import { IHttpQueryOptions } from '../../../shared/interfaces/http.query.interface';
import { IUser, IUserServerResponse } from '../../../shared/interfaces/user.interface';
import { IWorkspaceRoleClient } from '../../../shared/interfaces/workspace-role.interface';
import { IGetWorkspaceUsersFilterRequest } from '../../../shared/interfaces/workspace-user.interface';
import {
  ICreateWorkspaceClientRequest,
  IGetWorkspacesResponse,
  IValidateWorkspaceNameResponse,
  IWorkspace,
  IWorkspaceClient,
  IWorkspaceSummary,
  IWorkspaceUpdateRequest,
  ICreateWorkspacePlusDpRequest,
  ICreateBulkWorkspacesResponse,
} from '../../../shared/interfaces/workspace.interface';
import { BusMessageChannels, BusMessageService } from './bus-message.service';
import { AdminRestAPI } from './rest/admin.api';
import { WorkspacesRestAPI } from './rest/workspaces.api';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { DEBOUNCE_TIME } from '../shared/constants';
import { IAsyncReportResponse } from '../../../shared/interfaces/async-report.interface';
import { IWorkspaceMetrics } from '../../../shared/interfaces/workspace-metrics.interface';
import { IValidateBulkUpload } from '../../../shared/interfaces/digital-property.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService implements OnDestroy {
  private subscription: Subscription;
  private validateUniqueNameSubject$: BehaviorSubject<null>;
  private validateUniqueNameDebouncer$: Observable<ValidationErrors | null>;

  constructor(
    private workspacesRestAPI: WorkspacesRestAPI,
    private adminRestAPI: AdminRestAPI,
    private busMessageService: BusMessageService,
  ) {
    this.subscription = new Subscription();
    this.validateUniqueNameSubject$ = new BehaviorSubject<null>(null);
    this.validateUniqueNameDebouncer$ = this.validateUniqueNameSubject$.pipe(debounceTime(DEBOUNCE_TIME));
  }

  public downloadCreateBulkWorkspacesTemplate(): Observable<void> {
    return this.workspacesRestAPI.downloadCreateBulkWorkspacesTemplate();
  }

  private publishEventWorkspacesChanged(workspace: IWorkspace): Observable<IWorkspace> {
    this.busMessageService.to(BusMessageChannels.workspaceUpdated).next();

    return of(workspace);
  }

  private publishEventWorkspaceCreated(workspace: IWorkspace): Observable<IWorkspace> {
    this.busMessageService.to(BusMessageChannels.workspaceCreated).next();

    return of(workspace);
  }

  public static isTrainingVideoDomainAllowed(hostname: string): boolean {
    return trainingVideoAllowedHostnames.includes(hostname);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getUsers(workspaceId: string, filters?: IGetWorkspaceUsersFilterRequest): Observable<IUser[]> {
    return this.workspacesRestAPI.getUsers(workspaceId, filters);
  }

  public getAllWorkspaces(queryOptions: IHttpQueryOptions): Observable<IGetWorkspacesResponse> {
    return this.adminRestAPI.getAllWorkspaces(queryOptions);
  }

  public validateWorkspaceBulkUpload(uploadForm: FormData): Observable<IValidateBulkUpload> {
    return this.adminRestAPI.validateWorkspaceBulkUpload(uploadForm);
  }

  public workspaceBulkUpload(uploadForm: FormData): Observable<ICreateBulkWorkspacesResponse> {
    return this.adminRestAPI.workspaceBulkUpload(uploadForm);
  }

  public migrateWorkspaceToTenant(workspacesIds: string[], tenantId: string): Observable<void> {
    return this.adminRestAPI.migrateWorkspaceToTenant(workspacesIds, tenantId);
  }

  public getUserAvailableWorkspaces(
    userId: string,
    queryOptions: IGetAvailableWorkspacesQuery,
  ): Observable<IGetWorkspacesResponse> {
    return this.adminRestAPI.getUserAvailableWorkspaces(userId, queryOptions);
  }

  public getAllUserWorkspaces(queryOptions: IHttpQueryOptions): Observable<IGetWorkspacesResponse> {
    return this.workspacesRestAPI.getAllUserWorkspaces(queryOptions);
  }

  public getWorkspace(id: string): Observable<IWorkspace> {
    return this.workspacesRestAPI.getWorkspace(id);
  }

  public removeWorkspace(id: string): Observable<void> {
    return this.workspacesRestAPI.removeWorkspace(id);
  }

  public createWorkspacePlusDp(data: ICreateWorkspacePlusDpRequest): Observable<IWorkspace> {
    return this.workspacesRestAPI.createWorkspacePlusDp(data).pipe(tap(this.publishEventWorkspaceCreated.bind(this)));
  }

  public createWorkspace(data: ICreateWorkspaceClientRequest): Observable<IWorkspace> {
    return this.workspacesRestAPI.createWorkspace(data).pipe(finalize(this.publishEventWorkspaceCreated.bind(this)));
  }

  public updateWorkspace(workspaceId: string, data: IWorkspaceUpdateRequest): Observable<IWorkspace> {
    return this.workspacesRestAPI
      .updateWorkspace(workspaceId, data)
      .pipe(finalize(this.publishEventWorkspacesChanged.bind(this)));
  }

  public updateWorkspaceAsAdmin(workspaceId: string, data: IWorkspaceUpdateRequest): Observable<IWorkspace> {
    return this.workspacesRestAPI.updateWorkspaceAsAdmin(workspaceId, data);
  }

  public exportWorkspaces(queryOptions: IHttpQueryOptions): Observable<void> {
    return this.workspacesRestAPI.exportWorkspaces(queryOptions);
  }

  public getUserWorkspaceRoles(
    workspaceId: string,
    userId: string,
    isAdminRequest: boolean = false,
  ): Observable<IWorkspaceRoleClient[]> {
    return this.workspacesRestAPI.getUserWorkspaceRoles(workspaceId, userId, isAdminRequest);
  }

  public isUserInWorkspace(myProfile: IUserServerResponse, workspace: IWorkspace): boolean {
    const findWorkspace = (_workspace: IWorkspace): boolean => {
      return _workspace._id === workspace._id;
    };

    return myProfile[$user.workspaces].findIndex(findWorkspace) !== -1;
  }

  public isUserPrivateWorkspace(myProfile: IUserServerResponse, workspace: IWorkspace): boolean {
    if (this.isUserInWorkspace(myProfile, workspace) === false) {
      return false;
    }

    return workspace.type === WorkspaceTypes.private;
  }

  public getActiveWorkspaces(): Observable<IWorkspaceClient[]> {
    return this.workspacesRestAPI.getActiveWorkspaces();
  }

  /**
   * Return active workspaces for the user, including the workspace where the user has DP entity-level access.
   * Child digital properties will be populated for each workspace.
   */
  public getActiveWorkspacesV2(): Observable<IWorkspaceClient[]> {
    return this.workspacesRestAPI.getActiveWorkspacesV2();
  }

  public getMyActiveWorkspacesWithNotifications(): Observable<IWorkspaceClient[]> {
    return this.workspacesRestAPI.getMyActiveWorkspacesWithNotifications();
  }

  public getWorkspaceSummary(workspaceId: string): Observable<IWorkspaceSummary> {
    return this.workspacesRestAPI.getSummary(workspaceId);
  }

  public downloadPortfolioReport(wsId: string): Observable<IAsyncReportResponse> {
    return this.workspacesRestAPI.downloadPortfolioReport(wsId);
  }

  public generatePortfolioReport(wsId: string): Observable<IAsyncReportResponse> {
    return this.workspacesRestAPI.generatePortfolioReport(wsId);
  }

  public getWorkspaceMetrics(wsId: string, toolName: string): Observable<IWorkspaceMetrics[]> {
    return this.workspacesRestAPI.getWorkspaceMetrics(wsId, toolName);
  }

  public validateNameUniqueness(
    tenantId: string,
    exceptionThunk: () => string = (): string => '',
  ): (c: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (SharedCommonUtility.isNullishOrEmpty(control.value)) {
        return of(null);
      }

      if (control.value === exceptionThunk()) {
        return of(null);
      }

      const result: Observable<ValidationErrors | null> = this.validateUniqueNameDebouncer$.pipe(
        take(1),
        switchMap(
          (): Observable<IValidateWorkspaceNameResponse> =>
            this.workspacesRestAPI.validateNameUniqueness(control.value, tenantId),
        ),
        switchMap((val: IValidateWorkspaceNameResponse): Observable<ValidationErrors | null> => {
          if (val?.exists === false) {
            return of(null);
          }
          return of({ unique: true });
        }),
      );
      this.validateUniqueNameSubject$.next(null);
      return result;
    };
  }
}
