import { Component, Input, OnChanges } from '@angular/core';

import { MarkdownUtility } from '../../../../shared/utils/markdown.utility';

@Component({
  selector: 'app-markdown-view',
  template: ` <div [innerHTML]="convertedData | safeContent"></div> `,
})
export class MarkdownViewComponent implements OnChanges {
  @Input() public value: string;

  public convertedData: string;

  public ngOnChanges(): void {
    this.convertedData = MarkdownUtility.render(this.value || '');
  }
}
