export enum $project {
  _id = '_id',
  name = 'name',
  key = 'key',
  ownerId = 'ownerId',
  startDate = 'startDate',
  workspaceId = 'workspaceId',
  endDate = 'endDate',
  description = 'description',
  issueIntegrationConnectionId = 'issueIntegrationConnectionId',
  issueIntegrationStatusMappingId = 'issueIntegrationStatusMappingId',
  issueIntegrationConfiguredFieldsId = 'issueIntegrationConfiguredFieldsId',
  issueIntegrationConfiguredFields = 'issueIntegrationConfiguredFields',
  issueIntegrationProjectName = 'issueIntegrationProjectName',
  issueIntegrationProjectKey = 'issueIntegrationProjectKey',
  issueIntegrationProjectId = 'issueIntegrationProjectId',
  issueIntegrationProjectInstance = 'issueIntegrationProjectInstance',
  issueIntegrationProject = 'issueIntegrationProject',
  status = 'status',
  issueIntegrationConnection = 'issueIntegrationConnection',
}

export enum $createProjectPagePath {
  pagePath = 'pagePath',
}

export enum ProjectStatus {
  active = 'active',
  archived = 'archived',
}

export enum ProjectAction {
  archive = 'archive',
  delete = 'delete',
  delete_with_linked_task = 'delete_with_linked_task',
  restore = 'restore',
}

export enum $projectWithOwner {
  owner = 'owner',
}

export enum $projectWithStatusMapping {
  issueIntegrationStatusMapping = 'issueIntegrationStatusMapping',
}

export const CHAR_LIMITS: Readonly<Partial<Record<$project | 'keyMin', number>>> = Object.freeze({
  [$project.name]: 100,
  [$project.description]: 255,
  [$project.key]: 5,
  keyMin: 2,
});

export const ProjectIssueTrackingProperties: ($project | $projectWithStatusMapping)[] = [
  $project.issueIntegrationConnectionId,
  $project.issueIntegrationConnection,
  $project.issueIntegrationProject,
  $project.issueIntegrationConfiguredFieldsId,
  $project.issueIntegrationConfiguredFields,
  $project.issueIntegrationStatusMappingId,
  $projectWithStatusMapping.issueIntegrationStatusMapping,
];
