import { Component, Input } from '@angular/core';
import { ICellConfig, BaseCellComponent } from '../base-cell/base-cell.component';

export interface IAttachmentData {
  iconId: string;
  href: string;
}

export interface IAttachmentCellConfig extends ICellConfig {
  attachments: IAttachmentData[];
}

@Component({
  selector: 'table-cell-attachments',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div #ref class="attachments-container">
      <a
        *ngFor="let attachment of config?.attachments"
        [href]="attachment.href"
        appDownloadLink
        rel="noreferrer noopener"
        target="_blank"
        class="pe-2"
      >
        <svg>
          <use [attr.xlink:href]="'#' + attachment.iconId"></use>
        </svg>
        <i aria-hidden="true"></i>
      </a>
    </div>
  `,
})
export class AttachmentsCellComponent extends BaseCellComponent {
  @Input() public config: IAttachmentCellConfig;
}
