import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  IAutomatedFlowShortItem,
  IAutomatedUserFlow,
  IAutomatedUserFlowCreateRequest,
  IAutomatedUserFlowResponse,
  IAutomatedUserFlowUpdateRequest,
} from '../../../../shared/interfaces/automated-user-flow.interface';
import { RestBuilder } from '../helpers/rest.builder';
import { ApiQueryOption } from '../../../../shared/constants/api';
import { AutomatedFlowType } from '../../../../shared/constants/automated-user-flow';

@Injectable({
  providedIn: 'root',
})
export class AutomatedUserFlowApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // POST /workspaces/:workspaceId/digital-properties/:digitalPropertyId/automated-user-flows
  public createAutomatedUserFlow(
    workspaceId: string,
    dpId: string,
    userFlow: IAutomatedUserFlowCreateRequest,
  ): Observable<IAutomatedUserFlow> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(dpId)
      .automatedUserFlows()
      .getApiUrl();

    return this.httpClient.post<IAutomatedUserFlow>(url, userFlow);
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/automated-user-flow
  public getAll(
    workspaceId: string,
    digitalPropertyId: string,
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
  ): Observable<IAutomatedUserFlowResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .automatedUserFlows()
      .getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params[ApiQueryOption.limit] = String(limit);
    }

    if (typeof skip === 'number') {
      params[ApiQueryOption.skip] = String(skip);
    }

    if (typeof sortBy === 'string') {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    if (typeof direction === 'string') {
      params[ApiQueryOption.direction] = direction;
    }

    if (term?.length > 0) {
      params[ApiQueryOption.term] = window.encodeURIComponent(term.trim());
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IAutomatedUserFlowResponse>(url, { params: httpParams });
  }

  public getByType(
    workspaceId: string,
    digitalPropertyId: string,
    flowType: AutomatedFlowType,
  ): Observable<IAutomatedFlowShortItem[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .automatedUserFlows()
      .types(flowType)
      .getApiUrl();

    return this.httpClient.get<IAutomatedFlowShortItem[]>(url);
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/automated-user-flows/:id
  public getAutomatedUserFlow(userFlowId: string, workspaceId: string, dpId: string): Observable<IAutomatedUserFlow> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(dpId)
      .automatedUserFlows(userFlowId)
      .getApiUrl();

    return this.httpClient.get<IAutomatedUserFlow>(url);
  }

  // PUT /workspaces/:workspaceId/digital-properties/:digitalPropertyId/automated-user-flows/:id
  public updateAutomatedUserFlow(
    userFlowId: string,
    wsId: string,
    dpId: string,
    userFlowUpdate: IAutomatedUserFlowUpdateRequest,
  ): Observable<IAutomatedUserFlow> {
    const url: string = this.restBuilder
      .create()
      .workspaces(wsId)
      .digitalProperties(dpId)
      .automatedUserFlows(userFlowId)
      .getApiUrl();

    return this.httpClient.put<IAutomatedUserFlow>(url, userFlowUpdate);
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/automated-user-flows/names/:userFlowTemplateName
  public findByName(workspaceId: string, dpId: string, userFlowTemplateName: string): Observable<IAutomatedUserFlow> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(dpId)
      .automatedUserFlows()
      .names(userFlowTemplateName)
      .getApiUrl();

    return this.httpClient.get<IAutomatedUserFlow>(url);
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/user-flow-recordings/:id
  public getUserFlowRecording(recordingId: string, workspaceId: string, dpId: string): Observable<IAutomatedUserFlow> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(dpId)
      .automatedUserFlowRecordings(recordingId)
      .getApiUrl();

    return this.httpClient.get<IAutomatedUserFlow>(url);
  }

  // DELETE /workspaces/:workspaceId/digital-properties/:digitalPropertyId/user-flow-recordings/:id
  public deleteUserFlowRecording(recordingId: string, workspaceId: string, dpId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(dpId)
      .automatedUserFlowRecordings(recordingId)
      .getApiUrl();

    return this.httpClient.delete<void>(url);
  }
}
