import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DsButtonVariants,
  DsModalColorVariant,
  DsModalRef,
  DsModalService,
  DsModalVariant,
  Icons,
} from '@levelaccess/design-system';

import { NavLink } from '../compound-second-level-navbar.component';
import { INavMenuItem, NavigationItem } from '../../../../services/navigation/nav-structure';
import { $workspace } from '../../../../../../shared/constants/workspace';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';

@Component({
  selector: 'app-mobile-second-navbar-menu-modal',
  templateUrl: './mobile-second-navbar-menu-modal.component.html',
  styleUrls: ['./mobile-second-navbar-menu-modal.component.scss'],
})
export class MobileSecondNavbarMenuModalComponent implements OnDestroy {
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly Icons: typeof Icons = Icons;
  public readonly $workspace: typeof $workspace = $workspace;

  public childrenRoutes: NavLink[];

  private _subscription: Subscription;
  private _parentMenuItem: INavMenuItem;

  @ViewChild('topLevelModal') public topNavModal: TemplateRef<any>;

  @Input() public set parentMenuItem(value: INavMenuItem) {
    this._parentMenuItem = value;
    this.handleParentMenuItemChanged();
  }

  constructor(
    public modal: NgbActiveModal,
    private modalService: DsModalService,
  ) {
    this._subscription = new Subscription();
  }

  private handleParentMenuItemChanged(): void {
    const children: INavMenuItem[] = this._parentMenuItem?.children ?? [];

    this.childrenRoutes = children
      .filter((child: INavMenuItem): boolean => child.isAvailable && !child.isHidden)
      .map(
        (child: INavMenuItem): NavLink => ({
          id: child.id,
          routerLink: child.routerLink,
          externalLink: child.externalLink,
          label: child.label,
          isActive: child.isActive,
          hasChildren:
            child.children?.length > 0 &&
            child.children.some(
              (childNav: INavMenuItem): boolean =>
                childNav.isAvailable && !childNav.isHidden && !childNav.displayedOutsideOfNavbar,
            ),
        }),
      );
  }

  public openChildNavbarMenuItemsModal(navLink: NavLink): void {
    const children: INavMenuItem[] = this._parentMenuItem?.children ?? [];
    const selectedNavItem: INavMenuItem = children.find((navMenuItem: INavMenuItem): boolean => navMenuItem.id === navLink.id);

    const modalRef: DsModalRef | null = this.modalService.open(MobileSecondNavbarMenuModalComponent, DsModalVariant.fullScreen);

    if (SharedCommonUtility.notNullish(modalRef)) {
      const childModal: MobileSecondNavbarMenuModalComponent = modalRef.componentInstance;
      childModal.parentMenuItem = selectedNavItem;
    }
  }

  public onModalClose(): void {
    this.modalService.closeAllModals();
  }

  public get parentMenuItemLabel(): string | undefined {
    return this._parentMenuItem?.label;
  }

  public get backNavLabel(): string | undefined {
    if (this.modalService.hasPreviousModal()) {
      return SharedCommonUtility.isNullish(this._parentMenuItem?.parent) ? 'menu' : this._parentMenuItem?.parent?.label;
    }

    return undefined;
  }

  public get modalColorVariant(): DsModalColorVariant {
    return this._parentMenuItem?.mobileModalColorVariant || DsModalColorVariant.Default;
  }

  public get isModalTitleInline(): boolean {
    return this.modalColorVariant !== DsModalColorVariant.Default;
  }

  public get showWebAppSelector(): boolean {
    return this._parentMenuItem?.id === NavigationItem.websites_and_apps;
  }

  public get showWorkspacesSelector(): boolean {
    return this._parentMenuItem?.id === NavigationItem.workspace;
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
