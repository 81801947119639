import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbsService } from './breadcrumbs.service';
import { IBreadcrumb } from './breadcrumbs.shared';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  public crumbs: IBreadcrumb[];

  public subscriptions: Subscription[];

  constructor(public breadcrumbsService: BreadcrumbsService) {
    this.subscriptions = [];
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.breadcrumbsService.crumbs$.subscribe((breadcrumbs: IBreadcrumb[]) => {
        this.crumbs = breadcrumbs;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
