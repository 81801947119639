export interface IFilterOutput<T> {
  field: string;
  values: T | Array<T>;
}

export interface IFilterOption<T> {
  label: string;
  value: T;
  translateLabel?: boolean; // default: true
}

export enum $checkboxSelect {
  selectAll = 'selectAll',
  unselectAll = 'unSelectAll',
}

export enum filterType {
  keyword = 'keyword',
  checkbox = 'checkbox',
  multiselect = 'multiselect',
  number = 'number',
  none = 'none',
  select = 'select',
  /**
   * Filters that are populated from the query params but don't display a control to users.
   */
  hidden = 'hidden',
}
