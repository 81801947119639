<ngb-toast
  id="{{ toast.id }}"
  *ngFor="let toast of toasts"
  [class]="cssClass(toast)"
  [header]="toast.header"
  [autohide]="toast.autoHide !== false"
  [delay]="timeout"
  (hidden)="remove(toast)"
  (keydown.escape)="toastTmpl.hide()"
  [attr.data-focus-trap]="toast.shouldTrapFocus"
  #toastTmpl
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <div class="text-toast">
      <span class="text-content">
        <ng-template [ngTemplateOutlet]="toast.message"></ng-template>
        <ng-container *ngTemplateOutlet="actionLink"></ng-container>
      </span>
      <ng-container *ngTemplateOutlet="closeButton"></ng-container>
    </div>
  </ng-template>

  <ng-template #text>
    <div class="text-toast">
      <span class="text-content">
        <span class="me-1" [class.d-inline]="toast.actionConfig" [innerHTML]="toast.message"></span>
        <ng-container *ngTemplateOutlet="actionLink"></ng-container>
      </span>
      <ng-container *ngTemplateOutlet="closeButton"></ng-container>
    </div>
  </ng-template>

  <ng-template #closeButton>
    <button type="button" class="btn-close-toast" (click)="toastTmpl.hide()" [attr.tabindex]="toast.shouldTrapFocus ? '' : '0'">
      <svg aria-hidden="true" focusable="false" viewBox="0 0 8 8"><use href="#cross-solid"></use></svg>
      <span class="visuallyhidden">{{ 'close_toast' | translate }}</span>
    </button>
  </ng-template>

  <ng-template #actionLink>
    <ng-container *ngIf="toast.actionConfig">
      <a href (click)="$event.preventDefault(); toast.actionConfig.onClick()">{{ toast.actionConfig.label | translate }}</a>
    </ng-container>
  </ng-template>
</ngb-toast>
