import { Component, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorMessageService } from '../../../../../services/error-message.service';
import { PageService } from '../../../../../services/page.service';
import { CustomValidators } from '../../../../../services/helpers/form-custom-validators';
import { FormService } from '../../../../../services/form.service';
import { TranslateService } from '../../../../../translate/translate.service';
import { LoadErrorHandler } from '../../../../../services/load-error-handler';
import { NotificationService } from '../../../../../services/notification.service';
import { DigitalPropertyService } from '../../../../../services/digital-property.service';
import { AbstractPageModalComponent, FormField, PageModalType } from '../abstract-page-modal.component';
import { $page, $pageImage } from '../../../../../../../shared/constants/page';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { NotificationPosition } from '../../../../../models/notification.model';
import { IPage, IPagesResponse } from '../../../../../../../shared/interfaces/page.interface';
import { IDigitalPropertyDetails } from '../../../../../../../shared/interfaces/digital-property.interface';
import { UserPropertyService } from '../../../../../services/user-property.service';
import { ICurrentSelectedProperty } from '../../../../../services/user.service';
import { FeatureFlagService } from '../../../../../services/feature-flag/feature-flag.service';

export interface IAddPageModalContext {
  workspaceId: string;
  digitalPropertyId: string;
  backNavLabel?: string;
  // defaults false
  requireURLField?: boolean;
  defaultURL?: string;
}

@Component({
  selector: 'app-add-page-modal',
  templateUrl: '../upsert-page-modal.component.html',
})
export class AddPageModalComponent extends AbstractPageModalComponent {
  public readonly modalType: PageModalType = PageModalType.ADD;
  private context: IAddPageModalContext;

  constructor(
    modal: NgbActiveOffcanvas,
    formBuilder: UntypedFormBuilder,
    formService: FormService,
    digitalPropertyService: DigitalPropertyService,
    element: ElementRef<Element>,
    pageService: PageService,
    errorMessageService: ErrorMessageService,
    loadErrorHandler: LoadErrorHandler,
    translateService: TranslateService,
    notificationService: NotificationService,
    userPropertyService: UserPropertyService,
    featureFlagService: FeatureFlagService,
  ) {
    super(
      modal,
      formBuilder,
      formService,
      digitalPropertyService,
      element,
      pageService,
      errorMessageService,
      loadErrorHandler,
      translateService,
      notificationService,
      userPropertyService,
      featureFlagService,
    );
  }

  public init(context: IAddPageModalContext): void {
    this.context = context;
    this.backNavLabel = context.backNavLabel;
    this.requireURL = context.requireURLField;
    this.form = this.buildForm();
    this.addValidators();
    if (SharedCommonUtility.notNullishOrEmpty(context.defaultURL)) {
      this.form.get(FormField.URL).patchValue(context.defaultURL);
      this.bannerText$ = this.userPropertyService.currentSelectedProperty().pipe(
        map(({ digitalPropertyName }: ICurrentSelectedProperty): string => {
          return this.translateService.instant('add_pages_QOL_save_banner', digitalPropertyName);
        }),
      );
    }
    return undefined;
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      [FormField.TITLE]: this.buildTitleFormControl(),
      [FormField.URL]: this.buildUrlFormControl(),
      [FormField.DESCRIPTION]: this.buildDescriptionFormControl(),
      [FormField.SCREENSHOT]: this.buildScreenshotFormControl(),
      [FormField.ALT_TEXT]: this.buildAltTextFormControl(),
    });
  }

  private addValidators(): void {
    this.subscription.add(
      this.pageService
        .getPages(this.context.workspaceId, this.context.digitalPropertyId)
        .subscribe((response: IPagesResponse): void => {
          this.addDuplicateUrlWarningValidator(response.pages.map((page: IPage) => page[$page.url]));
          this.addTitleUniquenessValidator(response.pages.map((page: IPage) => page[$page.name]));
        }),
    );

    this.subscription.add(
      this.digitalPropertyService
        .getDigitalProperty(this.context.workspaceId, this.context.digitalPropertyId)
        .subscribe((response: IDigitalPropertyDetails): void => {
          this.addUrlAuthorizedDomainValidator(
            response.authorizedDomains.map((authorizedDomain: string) =>
              SharedCommonUtility.urlPatternToRegexp(authorizedDomain),
            ),
          );
        }),
    );
  }

  private addUrlAuthorizedDomainValidator(domainRestrictionsRegExp: RegExp[]): void {
    const control: AbstractControl = this.form.get(FormField.URL);
    control.addValidators(CustomValidators.domainRestrictionsValidator(domainRestrictionsRegExp));
  }

  public submit(): void {
    Object.values(this.form.controls).forEach((control: AbstractControl) => control.updateValueAndValidity());

    if (this.form.valid === false) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }

    this.subscription.add(
      this.pageService.createPage(this.context.workspaceId, this.context.digitalPropertyId, this.getFormData()).subscribe({
        next: (createdPage: IPage) => this.onPageCreated(createdPage),
        error: (err: HttpErrorResponse) => this.loadErrorHandler.handleError('add_page_failed', err, AddPageModalComponent.name),
      }),
    );
  }

  private getFormData(): FormData {
    const formData: FormData = new FormData();
    formData.set($page.url, this.form.get(FormField.URL).value.trim());
    formData.set($page.name, this.form.get(FormField.TITLE).value.trim());

    const description: string = this.form.get(FormField.DESCRIPTION).value?.trim();
    if (SharedCommonUtility.notNullishOrEmpty(description)) {
      formData.set($page.description, description);
    }

    if (SharedCommonUtility.notNullish(this.screenshotFile)) {
      formData.set($page.screenshot, this.screenshotFile, this.screenshotFile.name);
    }

    const altText: string = this.form.get(FormField.ALT_TEXT).value?.trim();
    if (SharedCommonUtility.notNullishOrEmpty(altText)) {
      formData.set($pageImage.altText, altText);
    }

    return formData;
  }

  private onPageCreated(createdPage: IPage): void {
    const message: string = this.translateService.instant('add_page_success');
    this.notificationService.success(message, NotificationPosition.Toast, false, AddPageModalComponent.name);
    this.modal.close(createdPage);
  }
}
