import { sortBy } from 'lodash';
import { $designReviewFinding } from '../constants/design-review';
import { IBoundingBox } from '../interfaces/design-review.interface';

export const highlightPrimaryColor = '#002f57';
export const highlightSecondaryColor = '#fff';
export const highlightstrokeWidth = 2;
export const highlightActionWidth: number = 44;
export const highlightNumberSize: number = 24;
export const highlightNumberDistanceFromHighlight: number = 5;
export const highlightNumberRectStrokeWidth: number = 2;
export const highlightNumberCornerRadius: number = 4;
export const inlineFocusOffset: number = 3;
export const highlightNumberFont: string = '18px Roboto';

export const MAX_IMAGE_LENGTH: number = 31000;

export const distanceBetweenNumberCenters: number = highlightNumberSize + highlightNumberRectStrokeWidth + 3;

const getHighlightNumberX = (box: IBoundingBox, numberSize: number): number => {
  return box.x + box.width / 2 - numberSize / 2 - highlightNumberRectStrokeWidth / 2;
};

export const isBoxTooCloseToTop = (box: IBoundingBox, numberSize: number = highlightNumberSize): boolean => {
  return box.y <= numberSize + highlightNumberDistanceFromHighlight + highlightNumberRectStrokeWidth;
};

const getHighlightNumberY = (box: IBoundingBox, numberSize: number): number => {
  if (isBoxTooCloseToTop(box, numberSize)) {
    return box.y + highlightNumberDistanceFromHighlight + box.height;
  }

  return box.y - highlightNumberDistanceFromHighlight - numberSize - highlightNumberRectStrokeWidth;
};

const getHighlightNumberWidth = (numberSize: number): number => {
  return numberSize + highlightNumberRectStrokeWidth;
};

const getHighlightNumberHeight = (numberSize: number): number => {
  return numberSize + highlightNumberRectStrokeWidth;
};

export const getHighlightNumberBox = (highlight: IBoundingBox, numberSize: number = highlightNumberSize): IBoundingBox => {
  return {
    x: getHighlightNumberX(highlight, numberSize),
    y: getHighlightNumberY(highlight, numberSize),
    width: getHighlightNumberWidth(numberSize),
    height: getHighlightNumberHeight(numberSize),
  };
};

export const getFindingNumberBox = (
  highlight: IBoundingBox,
  findingsLength: number,
  index: number,
  imageWidth?: number,
): IBoundingBox => {
  const x: number = getHighlightNumberX(highlight, highlightNumberSize);
  const offsetX: number = getHighlightNumberOffsetX(highlight, findingsLength, imageWidth);

  return {
    x: x + distanceBetweenNumberCenters * index - offsetX,
    y: getHighlightNumberY(highlight, highlightNumberSize),
    width: getHighlightNumberWidth(highlightNumberSize),
    height: getHighlightNumberHeight(highlightNumberSize),
  };
};

export const getActionBox = (
  highlight: IBoundingBox,
  findingsLength: number,
  index: number,
  imageWidth?: number,
): IBoundingBox => {
  const x: number = getHighlightNumberX(highlight, highlightNumberSize);
  const offsetX: number = getHighlightNumberOffsetX(highlight, findingsLength, imageWidth);

  return {
    x: x + distanceBetweenNumberCenters * index + distanceBetweenNumberCenters - offsetX,
    y: getHighlightNumberY(highlight, highlightNumberSize),
    width: getHighlightNumberWidth(highlightActionWidth),
    height: getHighlightNumberHeight(highlightNumberSize),
  };
};

/**
 * Gets offset x for highlight numbers based on a number of findings.
 * The offset will be adjusted if it causes highlight numbers to be outside an image.
 */
const getHighlightNumberOffsetX = (highlight: IBoundingBox, findingsLength: number, imageWidth?: number): number => {
  const defaultOffset: number = (distanceBetweenNumberCenters * findingsLength) / 2;
  const padding: number = distanceBetweenNumberCenters / 2;

  const firstHighlightNumberX: number = highlight.x + highlight.width / 2 - defaultOffset;
  if (firstHighlightNumberX < 0) {
    return defaultOffset + firstHighlightNumberX - padding;
  }

  const lastHighlightNumberX: number = firstHighlightNumberX + distanceBetweenNumberCenters * findingsLength;
  if (lastHighlightNumberX > imageWidth) {
    return defaultOffset + lastHighlightNumberX - imageWidth - padding;
  }

  return defaultOffset - padding;
};

export const sortByTopLeftPosition = (boxes: IBoundingBox[]): IBoundingBox[] => {
  return sortBy(boxes, [(box: IBoundingBox): number => box.y, (box: IBoundingBox): number => box.x]);
};

interface IWithBoundingBox {
  boundingBox?: IBoundingBox;
}

export const sortFindingsByTopLeftPosition = <T extends IWithBoundingBox>(findings: T[]): T[] => {
  return sortBy(findings, [
    (finding: T): number => finding[$designReviewFinding.boundingBox]?.y,
    (finding: T): number => finding[$designReviewFinding.boundingBox]?.x,
  ]);
};
