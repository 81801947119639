import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DsModalService, DsModalVariant } from '@levelaccess/design-system';
import { NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';

import { FormService } from '../../../../../services/form.service';
import { $task } from '../../../../../../../shared/constants/task';
import { IProject } from '../../../../../../../shared/interfaces/project.interface';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { $project } from '../../../../../../../shared/constants/project';
import { AclSecurityAdapter } from '../../../../../services/acl.service';
import { RequiredSecurities } from '../../../../../../../shared/constants/required-securities';
import { UserAclService } from '../../../../../services/user-acl.service';
import { CreateProjectSlideoutComponent } from '../../create-project-slideout/create-project-slideout.component';

@Injectable()
export class UpsertTaskHelper {
  constructor(
    private formService: FormService,
    private userAclService: UserAclService,
    private modalService: DsModalService,
  ) {}

  public updateErrorMessages(form: FormGroup): Record<string, string> {
    if (form.valid) {
      return {};
    }

    const formFieldErrors: Record<string, string> = {};

    [$task.name, $task.projectId, $task.issueIntegrationType]
      .filter((key: $task): boolean => form.contains(key))
      .forEach((fieldName: $task): void => {
        const control: AbstractControl<string> = form.get(fieldName);
        if (fieldName === $task.name && (control.dirty || control.touched)) {
          formFieldErrors[fieldName] = this.formService.getErrorMessageForField(control, fieldName, '', 'label_task_form_name');
        } else if (fieldName === $task.projectId && (control.dirty || control.touched)) {
          formFieldErrors[fieldName] = this.formService.getErrorMessageForField(control, fieldName, '', 'project');
        } else if (control && fieldName === $task.issueIntegrationType && (control.dirty || control.touched)) {
          formFieldErrors[fieldName] = this.formService.getErrorMessageForField(control, fieldName, '', 'push_task_as');
        }
      });

    return formFieldErrors;
  }

  public canPushToIssueTracking$(form: FormGroup): Observable<boolean> {
    const selectedProject: IProject = form.get($task.projectId)?.value;
    const hasIntegrationProject: boolean = !SharedCommonUtility.isNullish(selectedProject?.[$project.issueIntegrationProject]);
    return this.userAclService.createCheckAccessForCurrentUser().pipe(
      map((adapter: AclSecurityAdapter): boolean =>
        adapter
          .useWorkspaceFromUser()
          .useFunctionalActions(RequiredSecurities.TI_Issue_Tracking_Basic_Create.functionalActions)
          .check(),
      ),
      map((hasAccess: boolean): boolean => hasAccess && hasIntegrationProject),
    );
  }

  public createNewProject(workspaceId: string): Observable<IProject> {
    const modalRef: NgbOffcanvasRef = this.modalService.open(
      CreateProjectSlideoutComponent,
      DsModalVariant.slideOut,
    ) as NgbOffcanvasRef;

    const component: CreateProjectSlideoutComponent = modalRef.componentInstance;
    component.workspaceId = workspaceId;
    return component.onProjectCreated;
  }
}
