<div *ngIf="showSignupSuccessBanner" class="mb-4">
  <app-edit-permissions-banner [bannerType]="BannerType.check" (onClose)="closeSignupSuccessBanner()">
    {{ 'sign_up_success' | translate }}
  </app-edit-permissions-banner>
</div>

<div class="mb-4">
  {{ 'sign_up_platform_usage_description' | translate }}
</div>

<div class="mb-4">
  <ds-checkbox-group
    #platFormActionCheckboxGroup
    [label]="'sign_up_platform_usage_checkbox_label' | translate"
    [instructions]="'sign_up_platform_usage_checkbox_instruction' | translate"
    [hideSelectAll]="true"
    
  >
    <ds-checkbox
      *ngFor="let platformAction of platformActions"
      [checkboxId]="platformAction.value"
      [value]="platformAction.value"
      [label]="platformAction.labelKey | translate"
      
    ></ds-checkbox>
  </ds-checkbox-group>
</div>

<div>
  <button type="button" ds-button [variant]="DsButtonVariants.primary" (click)="onSubmit()" >
    {{ 'label_next' | translate }}
  </button>
</div>
