import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[appCtrlKey]',
})
export class CtrlKeyDirective implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  @Output() ctrlV: EventEmitter<void> = new EventEmitter();
  @Output() ctrlC: EventEmitter<void> = new EventEmitter();
  @Output() ctrlS: EventEmitter<void> = new EventEmitter();
  @Output() ctrlZ: EventEmitter<void> = new EventEmitter();
  @Output() ctrlY: EventEmitter<void> = new EventEmitter();
  @Input() public document: boolean = false;
  @Input() public stopPropagation: boolean = true;

  private callEvent(event: KeyboardEvent): void {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }

    const methodName: string = `onCtrl${event.key.toUpperCase()}`;

    if (typeof this[methodName] !== 'function') {
      return;
    }

    (this[methodName] as Function).call(this, event);
  }

  private prepareEvent(event: KeyboardEvent): void {
    event.preventDefault();
    if (this.stopPropagation) {
      event.stopPropagation();
    }
  }

  public onCtrlV(event: KeyboardEvent): void {
    if (this.ctrlV.observers.length === 0) {
      return;
    }
    this.prepareEvent(event);
    this.ctrlV.emit();
  }

  public onCtrlC(event: KeyboardEvent): void {
    if (this.ctrlC.observers.length === 0) {
      return;
    }
    this.prepareEvent(event);
    this.ctrlC.emit();
  }

  public onCtrlS(event: KeyboardEvent): void {
    if (this.ctrlS.observers.length === 0) {
      return;
    }
    this.prepareEvent(event);
    this.ctrlS.emit();
  }

  public onCtrlZ(event: KeyboardEvent): void {
    if (this.ctrlZ.observers.length === 0) {
      return;
    }
    this.prepareEvent(event);
    this.ctrlZ.emit();
  }

  public onCtrlY(event: KeyboardEvent): void {
    if (this.ctrlY.observers.length === 0) {
      return;
    }
    this.prepareEvent(event);
    this.ctrlY.emit();
  }

  constructor(private el: ElementRef<HTMLElement>) {
    this.subscriptions = new Subscription();
  }

  public ngOnInit(): void {
    const el: HTMLElement | Document = this.document ? document : this.el.nativeElement;

    this.subscriptions.add(fromEvent(el, 'keydown').subscribe({ next: this.callEvent.bind(this) }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
