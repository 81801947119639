<form *ngIf="innerForm" [formGroup]="innerForm">
  <label
    *ngIf="!noLabel"
    [for]="domID"
    class="margin-bottom-sm"
    [attr.aria-required]="required"
    [ngClass]="{ required: required, 'fw-bold': !required }"
  >
    {{ label | translate }}<span *ngIf="required" class="color-error"> *</span>
  </label>
  <input
    #inputElement
    type="text"
    class="w-100 form-control form-element"
    [formControlName]="innerInputName"
    [id]="domID"
    [ngbTypeahead]="search"
    (selectItem)="pushOnItemSelected($event)"
    (keyup.enter)="pushOnEnter()"
    [class.ng-invalid]="form?.get(formControlName).invalid"
    [attr.required]="required"
    [attr.aria-describedby]="formControlName + '_error'"
  />
  <div aria-live="polite" role="status" [id]="formControlName + '_error'" class="error-container" *ngIf="form">
    <app-form-field-error
      [formValidationRequest$]="formValidationRequest$"
      [field]="form.get(formControlName)"
      [name]="formControlName"
      [label]="label"
    ></app-form-field-error>
  </div>
  <div>
    <div class="margin-bottom margin-top">
      <div *ngIf="selectedLabel">
        <label class="fw-bold">{{ selectedLabel | translate }}</label>
      </div>
      <div class="d-flex flex-wrap">
        <div *ngFor="let selectedValue of selectedValues; let i = index" [attr.id]="'selectedValue_' + i" class="value-button">
          {{ valueToSelectedItemLabelFunction(selectedValue) }}
          <button
            type="button"
            class="my-auto remove-value-btn"
            [attr.aria-describedby]="'selectedValue_' + i"
            (click)="removeValue(selectedValue)"
          >
            <span class="visuallyhidden">{{ 'remove_selected_value' | translate }}</span>
            <svg>
              <use xlink:href="#circle-x"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
