<app-accordion-item class="w-100" [contentDescription]="'filters' | translate" [(collapsed)]="filtersCollapsed">
  <div class="accordion-item-header-content w-100 row align-items-center filters-header">
    <div class="col-12 col-lg-auto ms-2 ms-lg-1">
      <button type="button" class="btn btn-link fw-bold text-underline no-padding">
        <svg aria-hidden="true" width="17" height="17" class="margin-right-xs">
          <use xlink:href="#fa-filter"></use>
        </svg>
        <span>{{ 'label_filters_with_number' | translate: filterLabels.length }}</span>
      </button>
    </div>
    <div id="filter-labels" class="col-12 col-lg-auto row pt-3 pt-lg-1">
      <ng-container *ngIf="filtersApplied">
        <div *ngFor="let label of filterLabels" class="col-auto me-2 me-lg-0 pe-0 pb-2 pb-lg-0">
          <span class="badge bg-primary">
            {{ label | translate }}
          </span>
        </div>
      </ng-container>
    </div>
    <div class="col-12 col-lg-auto" id="clear-all-filters">
      <button
        type="button"
        class="btn btn-link text-underline fw-bold no-padding ms-0 ms-lg-4"
        (click)="$event.stopPropagation(); clearAllFilters()"
      >
        {{ 'label_clear_all_filters' | translate }}
      </button>
    </div>
  </div>
  <div class="accordion-item-body-content w-100 filters">
    <div class="row">
      <div class="col-md-12 d-flex flex-column">
        <div class="col-md-12 border-top-black p-4 d-flex flex-wrap">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <div class="border-top-black padding-top-md padding-bottom-md">
      <div class="row align-items-center">
        <div class="col-12 col-lg-4 margin-bottom-sm">
          <button type="button" class="btn btn-link text-underline fw-bold margin-left-sm" (click)="clearAllFilters()">
            {{ 'label_clear_all_filters' | translate }}
          </button>
        </div>
        <div class="col-12 col-lg-8">
          <div class="d-flex justify-content-end flex-column flex-lg-row">
            <div *ngIf="filtersApplied === false" class="text-black margin-left-md align-items-center d-flex">
              {{ 'label_unsaved_filter_changes' | translate }}
            </div>
            <div class="d-flex align-items-center">
              <button type="button" class="btn btn-outline-primary fw-bold margin-left-md" (click)="closeFilters()">
                {{ 'label_close' | translate }}
              </button>
              <button
                type="submit"
                class="btn btn-primary margin-left-md margin-right-md fw-bold justify-content-center"
                (click)="applyFilters()"
              >
                {{ 'label_apply_filters' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-accordion-item>
