import { Component, HostBinding } from '@angular/core';

// Note: template icons are from node_modules/open-iconic/sprite/sprite.svg

@Component({
  selector: 'app-svg-icons',
  templateUrl: '../../assets/images/icons.html',
})
export class SvgIconsComponent {
  @HostBinding('style.display') public none: string = 'none';
}
