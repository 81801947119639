import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate/translate.service';

@Pipe({ name: 'languageName' })
export class LanguageNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(langCode: string): string {
    try {
      const language: string = this.translateService.currentLang;

      // @ts-ignore
      const languageNames: Intl.DisplayNames = new Intl.DisplayNames([language], {
        type: 'language',
      });

      return languageNames.of(langCode) !== 'null' ? languageNames.of(langCode) : '';
    } catch (error) {
      return langCode;
    }
  }
}
