import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppConfigService } from './app-config.service';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../shared/interfaces/feature-flag.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

const SCRIPT_ID: string = 'userway-widget';

@Injectable({
  providedIn: 'root',
})
export class UserWayWidgetService {
  private subscription: Subscription;

  constructor(
    private appConfigService: AppConfigService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.subscription = new Subscription();
  }

  private get userWayAccount(): string {
    return this.appConfigService.getUserWayAccount();
  }

  private isUserWayConfigured(): boolean {
    return !SharedCommonUtility.isStringEmpty(this.userWayAccount);
  }

  public getWidgetScript(): HTMLScriptElement {
    const script: HTMLScriptElement = document.createElement('script');
    script.id = SCRIPT_ID;
    script.src = 'https://cdn.userway.org/widget.js';
    script.async = true;
    script.setAttribute('data-account', this.userWayAccount);
    /* uncomment the following line to override default position*/
    /* script.setAttribute("data-position", 3);*/
    /* uncomment the following line to override default size (values: small, large)*/
    /* script.setAttribute("data-size", "small");*/
    /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
    /* script.setAttribute("data-language", "language");*/
    /* uncomment the following line to override color set via widget (e.g., #053f67)*/
    /* script.setAttribute("data-color", "#053e67");*/
    /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
    /* script.setAtribute("data-type", "1");*/
    /* script.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
    /* script.setAttribute("data-statement_url", "http://www.example.com/accessibility")";*/
    /* uncomment the following line to override support on mobile devices*/
    /* script.setAttribute("data-mobile", true);*/
    /* uncomment the following line to set custom trigger action for accessibility menu*/
    /* script.setAttribute("data-trigger", "triggerId")*/
    return script;
  }

  public get widgetInjected(): boolean {
    return !SharedCommonUtility.isNullish(document.querySelector(`script#${SCRIPT_ID}`));
  }

  private initializeWidget(): void {
    if (!this.isUserWayConfigured() || this.widgetInjected) {
      return;
    }

    document.head.appendChild(this.getWidgetScript());
  }

  public initialize(): void {
    this.subscription.add(
      this.featureFlagService.variation$(FeatureFlagCollection.userWayWidget, false).subscribe((widgetEnabled: boolean) => {
        if (widgetEnabled) {
          this.initializeWidget();
        }
      }),
    );
  }
}
