export class WindowRef {
  private _window: Window = window;

  public setwindowObjectMock(obj: any): void {
    this._window = obj;
  }

  public get windowObject(): any {
    return this._window;
  }
}
