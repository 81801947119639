import { $severity } from './accessibility';
import { $digitalProperty } from './digital-properties';
import { $findingCounts } from './finding-counts';

export enum $dpMetrics {
  _id = '_id',
  riskScore = 'riskScore',
  tenantId = 'tenantId',
  workspaceId = 'workspaceId',
  digitalProperty = 'digitalProperty',
  toolName = 'toolName',
  computedAt = 'computedAt',
  scheduledAt = 'scheduledAt',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  findingsMetrics = 'findingsMetrics',
  previousSnapshotId = 'previousSnapshotId',
  previousSnapshot = 'previousSnapshot',
}

export enum $wsMetrics {
  _id = '_id',
  tenantId = 'tenantId',
  workspaceId = 'workspaceId',
  toolName = 'toolName',
  riskScore = 'riskScore',
  dpCountsByRiskLevel = 'dpCountsByRiskLevel',
  dpCount = 'dpCount',
  findingsMetrics = 'findingsMetrics',
  computedAt = 'computedAt',
  scheduledAt = 'scheduledAt',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum $tenantMetrics {
  _id = '_id',
  tenantId = 'tenantId',
  toolName = 'toolName',
  riskScore = 'riskScore',
  computedAt = 'computedAt',
  scheduledAt = 'scheduledAt',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  dpCountsByRiskLevel = 'dpCountsByRiskLevel',
  mostViolatedSuccessCriteria = 'mostViolatedSuccessCriteria',
  mostViolatedCategories = 'mostViolatedCategories',
  mostResolvedDps = 'mostResolvedDps',
  workspaceCount = 'workspaceCount',
  dpCount = 'dpCount',
  findingsMetrics = 'findingsMetrics',
}

export enum $findingsMetrics {
  allFindings = 'allFindings',
  resolvedFindings = 'resolvedFindings',
  percentageResolved = 'percentageResolved',
  unresolvedFindingsBySeverity = 'unresolvedFindingsBySeverity',
  defaultPolicyOverdueFindingsBySeverity = 'defaultPolicyOverdueFindingsBySeverity',
  userPolicyOverdueFindingsBySeverity = 'userPolicyOverdueFindingsBySeverity',
}

export enum $mostResolvedDp {
  workspaceId = 'workspaceId',
  digitalPropertyId = 'digitalPropertyId',
  digitalProperty = 'digitalProperty',
  percentageResolved = 'percentageResolved',
}

export enum $metricsComputingRequest {
  archive = 'archive',
  digitalPropertyId = 'digitalPropertyId',
  scheduledAt = 'scheduledAt',
  tenantId = 'tenantId',
}

export enum $riskScoreLevels {
  behind = 'behind',
  needsAttention = 'needsAttention',
  onTrack = 'onTrack',
  excelling = 'excelling',
}

export const RiskScoreLevelThresholds: Readonly<Record<$riskScoreLevels, number>> = Object.freeze({
  [$riskScoreLevels.behind]: 50,
  [$riskScoreLevels.needsAttention]: 70,
  [$riskScoreLevels.onTrack]: 90,
  [$riskScoreLevels.excelling]: 100,
});

export const DEFAULT_METRICS_LIST_LIMIT: Readonly<number> = 10;

export enum $dpMetricsSortKeys {
  digitalPropertyName = 'digitalPropertyName',
  autoResolvedFindings = 'autoResolvedFindings',
  manualResolvedFindings = 'manualResolvedFindings',
  totalResolvedFindings = 'totalResolvedFindings',
  autoPercentageResolved = 'autoPercentageResolved',
  manualPercentageResolved = 'manualPercentageResolved',
  totalPercentageResolved = 'totalPercentageResolved',
  autoCriticalOverdue = 'autoCriticalOverdue',
  autoHighOverdue = 'autoHighOverdue',
  autoLowOverdue = 'autoLowOverdue',
  manualCriticalOverdue = 'manualCriticalOverdue',
  manualHighOverdue = 'manualHighOverdue',
  manualLowOverdue = 'manualLowOverdue',
  totalCriticalOverdue = 'totalCriticalOverdue',
  totalHighOverdue = 'totalHighOverdue',
  totalLowOverdue = 'totalLowOverdue',
  userPolicyAutoCriticalOverdue = 'userPolicyAutoCriticalOverdue',
  userPolicyAutoHighOverdue = 'userPolicyAutoHighOverdue',
  userPolicyAutoLowOverdue = 'userPolicyAutoLowOverdue',
  userPolicyManualCriticalOverdue = 'userPolicyManualCriticalOverdue',
  userPolicyManualHighOverdue = 'userPolicyManualHighOverdue',
  userPolicyManualLowOverdue = 'userPolicyManualLowOverdue',
  userPolicyTotalCriticalOverdue = 'userPolicyTotalCriticalOverdue',
  userPolicyTotalHighOverdue = 'userPolicyTotalHighOverdue',
  userPolicyTotalLowOverdue = 'userPolicyTotalLowOverdue',
}

export const DpMetricsSortableFields: Readonly<Record<$dpMetricsSortKeys, string>> = Object.freeze({
  [$dpMetricsSortKeys.digitalPropertyName]: `${$dpMetrics.digitalProperty}.${$digitalProperty.name}`,
  [$dpMetricsSortKeys.autoResolvedFindings]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.resolvedFindings}.${$findingCounts.auto}`,
  [$dpMetricsSortKeys.manualResolvedFindings]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.resolvedFindings}.${$findingCounts.manual}`,
  [$dpMetricsSortKeys.totalResolvedFindings]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.resolvedFindings}.${$findingCounts.total}`,
  [$dpMetricsSortKeys.autoPercentageResolved]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.percentageResolved}.${$findingCounts.auto}`,
  [$dpMetricsSortKeys.manualPercentageResolved]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.percentageResolved}.${$findingCounts.manual}`,
  [$dpMetricsSortKeys.totalPercentageResolved]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.percentageResolved}.${$findingCounts.total}`,
  [$dpMetricsSortKeys.autoCriticalOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.auto}.${$severity.critical}`,
  [$dpMetricsSortKeys.autoHighOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.auto}.${$severity.high}`,
  [$dpMetricsSortKeys.autoLowOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.auto}.${$severity.low}`,
  [$dpMetricsSortKeys.manualCriticalOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.manual}.${$severity.critical}`,
  [$dpMetricsSortKeys.manualHighOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.manual}.${$severity.high}`,
  [$dpMetricsSortKeys.manualLowOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.manual}.${$severity.low}`,
  [$dpMetricsSortKeys.totalCriticalOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.total}.${$severity.critical}`,
  [$dpMetricsSortKeys.totalHighOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.total}.${$severity.high}`,
  [$dpMetricsSortKeys.totalLowOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.defaultPolicyOverdueFindingsBySeverity}.${$findingCounts.total}.${$severity.low}`,
  [$dpMetricsSortKeys.userPolicyAutoCriticalOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.auto}.${$severity.critical}`,
  [$dpMetricsSortKeys.userPolicyAutoHighOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.auto}.${$severity.high}`,
  [$dpMetricsSortKeys.userPolicyAutoLowOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.auto}.${$severity.low}`,
  [$dpMetricsSortKeys.userPolicyManualCriticalOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.manual}.${$severity.critical}`,
  [$dpMetricsSortKeys.userPolicyManualHighOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.manual}.${$severity.high}`,
  [$dpMetricsSortKeys.userPolicyManualLowOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.manual}.${$severity.low}`,
  [$dpMetricsSortKeys.userPolicyTotalCriticalOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.total}.${$severity.critical}`,
  [$dpMetricsSortKeys.userPolicyTotalHighOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.total}.${$severity.high}`,
  [$dpMetricsSortKeys.userPolicyTotalLowOverdue]: `${$dpMetrics.findingsMetrics}.${$findingsMetrics.userPolicyOverdueFindingsBySeverity}.${$findingCounts.total}.${$severity.low}`,
});
