import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'compound-top-navbar-unauthenticated',
  templateUrl: './compound-top-navbar-unauthenticated.component.html',
  styleUrls: ['./compound-top-navbar.component.scss'],
})
export class CompoundTopNavbarUnauthenticatedComponent {
  public isAuthenticated$: Observable<boolean>;

  @Input() public showMobileNavVersion: boolean;

  constructor(private userService: UserService) {
    this.isAuthenticated$ = this.userService.isAuthenticated$;
  }
}
