import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ReportFormat } from '../../../../shared/constants/report-formats';
import { IReportFormat } from '../../../../shared/interfaces/report-format.interface';
import { ReportService } from '../../services/report.service';
import { A11yService } from '../../services/a11y.service';
import { TranslateService } from '../../translate/translate.service';
import { scanningJobType, scanningType } from '../../../../shared/constants/scanning';
import { NotificationService } from '../../services/notification.service';
import { NotificationPosition } from '../../models/notification.model';
import { IonDownloadReportError } from '../../interfaces/error.interface';
import { CommonUtility } from '../../utility/common.utility';
import { ApiQueryOption } from '../../../../shared/constants/api';
import { AccessibilityAuditToolNames } from '../../../../shared/constants/audit-tool';
import { IAutomatedScanIssue } from '../../../../shared/interfaces/scan-issues.interface';
import { FileExtension } from '../../../../shared/constants/file-extension';
import { errorMessagesNames } from '../../../../shared/constants/errors';
import { ModalService } from '../../services/modal.service';
import { InfoModalComponent } from '../modals/info-modal/info-modal.component';

@Component({
  selector: 'app-download-report-new',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss'],
})
export class DownloadReportNewComponent implements OnDestroy {
  private subscriptions: Subscription;

  @Input() public scanId: string;
  @Input() public ruleId: string;
  @Input() public disabled: boolean;
  @Input() public auditTool: AccessibilityAuditToolNames;
  @Input() public selectedIssues: IAutomatedScanIssue[];
  @Output() public onDownloadReportError: EventEmitter<IonDownloadReportError>;

  public reportFormats: IReportFormat[];
  public selectedFileExtension: FileExtension;
  public scanningType: typeof scanningType;
  public scanningJobType: typeof scanningJobType;
  public errorResponseMessages: string[];
  public selectLabelId: string;
  public ReportFormat: { [key: string]: IReportFormat };

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private a11yService: A11yService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private modalService: ModalService,
  ) {
    this.subscriptions = new Subscription();
    this.scanId = '';
    this.disabled = false;
    this.onDownloadReportError = new EventEmitter();
    this.reportFormats = Object.values(ReportFormat);
    this.scanningJobType = scanningJobType;
    this.scanningType = scanningType;
    this.errorResponseMessages = [];
    this.selectLabelId = CommonUtility.createUniqueDOMId();
    this.ReportFormat = ReportFormat;
  }

  private handleError(message: string, response: HttpErrorResponse): void {
    this.onDownloadReportError.emit({
      message: message,
      response: response,
    });

    console.error(`[${DownloadReportNewComponent.name}.handleError]`, message, response);
  }

  private onGetReportSuccess(): void {
    this.notificationService.success(
      this.translateService.instant('download_report_created_successfully'),
      NotificationPosition.Toast,
    );
  }

  private onGetReportError(response: HttpErrorResponse): void {
    const convertedError: HttpErrorResponse = CommonUtility.convertArrayBufferHTTPErrorToObject(response);
    if (CommonUtility.extractHTTPErrorName(convertedError) === errorMessagesNames.ErrorScanPagesExceededDownloadLimit) {
      const modal: InfoModalComponent = this.modalService.open(InfoModalComponent).instance;
      const message: string = this.translateService.instant(
        'sorry_this_scan_contains_too_many_pages',
        convertedError.error.app.params[0],
      );
      modal.init({
        headerKey: 'cannot_download_report',
        bodyText: message,
      });
      this.onDownloadReportError.emit({
        message: message,
        response: response,
      });
    } else {
      this.handleError('error_download_scan_report', response);
    }
  }

  public onDownloadReportRequest(_fileExtension: FileExtension): void {
    this.selectedFileExtension = _fileExtension;
    this.a11yService.setMessage(this.translateService.instant('activating_download_report_request'));

    const scanUrl: string = this.activatedRoute.snapshot.queryParams[ApiQueryOption.url];

    const extraQueryParams: Record<string, any> = {
      [ApiQueryOption.auditTool]: this.auditTool,
    };

    if (Array.isArray(this.selectedIssues)) {
      extraQueryParams[ApiQueryOption.issueIds] = this.selectedIssues
        .map((i: IAutomatedScanIssue): string => i.rawFindingId)
        .join(',');
    }

    const downloadReportSubscription: Subscription = this.reportService
      .downloadReportNew(this.scanId, _fileExtension, scanUrl, this.ruleId, extraQueryParams)
      .subscribe({ next: this.onGetReportSuccess.bind(this), error: this.onGetReportError.bind(this) });

    this.subscriptions.add(downloadReportSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
