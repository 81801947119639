export enum $uploads {
  _id = '_id',
  altText = 'altText',
  downloadToken = 'downloadToken',
  fileOriginalName = 'fileOriginalName',
  fileName = 'fileName',
  filePath = 'filePath',
  fileRelativePath = 'fileRelativePath',
  fileSize = 'fileSize',
  userId = 'userId',
  thumbnailId = 'thumbnailId',
  thumbnail = 'thumbnail',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  user = 'user',
}

// Note: see Mime Types in server/core/shared/utils/mime-types.utility.ts to keep consistency
export enum FileType {
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  json = 'application/json',
}

export enum ReadableFileType {
  xlsx = 'XLSX',
}

export const SIZE_1_MB: number = 1024 * 1024;
export const SIZE_4_MB: number = 4 * SIZE_1_MB;
export const SIZE_5_MB: number = 5 * SIZE_1_MB;
export const SIZE_20_MB: number = 20 * SIZE_1_MB;
export const SIZE_60_MB: number = 60 * SIZE_1_MB;
export const SIZE_200_MB: number = 200 * SIZE_1_MB;

export const SIZE_1_GB: number = 1024 * SIZE_1_MB;
export const SIZE_4_GB: number = 4 * SIZE_1_GB;

export const SCANNABLE_DOCUMENT_MAX_UPLOAD_QUANTITY: number = 10;
