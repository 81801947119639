<div class="item-wrapper">
  <div class="item-header py-2">
    <ng-template [ngTemplateOutlet]="collapseButton"></ng-template>
    <span class="fw-bold">
      {{ 'view_flaw_status_change_new_status_' + historyItem.status | translate }}
    </span>
  </div>
  <div [class.collapsed]="expanded === false">
    <div class="item-description">
      <span>{{ getUserAndTimestamp() }}</span>
    </div>
    <div class="item-comment mt-2" *ngIf="historyItem.comment" [innerHTML]="historyItem.comment | linkify"></div>
  </div>
</div>

<ng-template #collapseButton>
  <button
    type="button"
    class="btn btn-sm p-0 btn-link btn-flat collapse-button"
    [attr.aria-expanded]="expanded"
    (click)="expanded = !expanded"
  >
    <ng-container *ngIf="expanded">
      <svg class="align-items-start"><use xlink:href="#chevron-bottom-thin"></use></svg>
    </ng-container>
    <ng-container *ngIf="expanded === false">
      <svg class="align-items-start"><use xlink:href="#chevron-right-thin"></use></svg>
    </ng-container>
  </button>
</ng-template>
