<div class="attachment-thumbnail" [class.bg-white]="!fileThumbnailPath">
  <ng-container *ngIf="fileThumbnailPath; else notThumbnailPath">
    <a href="{{ fileRelativePath }}" rel="noreferrer noopener" target="_blank">
      <img class="mw-100 mh-100" src="{{ fileThumbnailPath }}" [alt]="altText" />
      <i aria-hidden="true"></i>
    </a>
  </ng-container>
  <ng-template #notThumbnailPath>
    <div [ngSwitch]="fileExtension" class="icon attachment-thumbnail--fallback-thumbnail">
      <a href="{{ fileDownloadLink }}" rel="noreferrer noopener" target="_blank" [attr.aria-label]="'open_file' | translate">
        <!-- xlsx icon -->
        <svg
          class="icon attachment-thumbnail--fallback-thumbnail-icon"
          viewBox="0 0 38 50"
          *ngSwitchCase="$allowedManualAuditAttachmentExt.xlsx"
        >
          <use xlink:href="#xlsx-logo"></use>
        </svg>

        <!-- csv icon -->
        <svg
          class="icon attachment-thumbnail--fallback-thumbnail-icon"
          viewBox="0 0 50 50"
          *ngSwitchCase="$allowedManualAuditAttachmentExt.csv"
        >
          <use xlink:href="#csv-logo"></use>
        </svg>

        <!-- image icon -->
        <svg class="icon attachment-thumbnail--fallback-thumbnail-icon" viewBox="0 0 50 50" *ngSwitchDefault>
          <use xlink:href="#picture-logo"></use>
        </svg>
        <i aria-hidden="true"></i>
      </a>
    </div>
  </ng-template>
  <div class="bg-white attachment-thumbnail--description">
    <div class="attachment-thumbnail-name text-truncate">{{ fileName }}</div>
    <div class="attachment-thumbnail-warning" *ngIf="displayMissingAltTextWarning">
      <app-icon class="me-1" [icon]="Icons.WarningTriangleIcon" [style.color]="EapColors.RedError"></app-icon>
      {{ 'missing_alt_text' | translate }}
    </div>
    <div class="d-flex justify-content-between">
      <a
        class="btn-link d-flex align-items-center attachment-thumbnail--action-button"
        href="{{ fileDownloadLink }}"
        [appDownloadLink]="isInternal"
        rel="noreferrer noopener"
        target="_blank"
      >
        <svg viewBox="0 0 16 16" class="icon attachment-thumbnail--action-button-icon">
          <use xlink:href="#data-transfer-download"></use>
        </svg>
        <span>{{ 'action_download' | translate }}</span>
        <i aria-hidden="true"></i>
      </a>
      <details appCloseActionMenu class="more-action" *ngIf="isEditable || isRemovable">
        <summary [attr.aria-label]="'show_options_for' | translate: fileName">
          <span></span>
        </summary>
        <div class="dropdown-action-menu">
          <ul role="menu">
            <li *ngIf="isEditable" role="menuitem">
              <button type="button" class="button" title="{{ 'upload_action_edit_file' | translate }}" (click)="edit()">
                {{ 'action_edit' | translate }}
              </button>
            </li>
            <li *ngIf="isRemovable" role="menuitem">
              <button type="button" class="button" title="{{ 'upload_action_remove_file' | translate }}" (click)="remove()">
                {{ 'action_delete' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </details>
    </div>
  </div>
</div>
