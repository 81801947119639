<ng-container *ngIf="nodes.length > 0; else emptyTree">
  <app-checkbox-tree-node
    *ngFor="let node of nodes; let index = index"
    [level]="0"
    [index]="index"
    [treeId]="id"
    [node]="node"
    [config]="config"
    (nodeChanged)="onTreeChanged()"
  >
  </app-checkbox-tree-node>
</ng-container>
<ng-template #emptyTree>
  <app-checkbox-tree-node *ngIf="emptyTreeNode" [level]="0" [index]="0" [treeId]="id" [node]="emptyTreeNode" [config]="config">
  </app-checkbox-tree-node>
</ng-template>
