import { Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

@Component({
  selector: 'table-cell-pending',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div class="row" #ref>
      <div class="col-12">
        <span class="spinner-border icon pending-icon" role="status">
          <span class="visually-hidden">{{ 'loading_spinner_text' | translate }}</span>
        </span>
        <span *ngIf="config.text">{{ config.text }}</span>
        <span *ngIf="!config.text">{{ 'status_pending' | translate }}</span>
      </div>
    </div>
  `,
})
export class PendingCellComponent extends BaseCellComponent {
  @Input() public config: ICellConfig;
}
