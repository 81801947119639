import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TenantSeveritiesApi } from './rest/tenant-severities.api';
import {
  IUpsertCustomSeverityRequest,
  ISwapCustomSeveritiesRanksRequest,
  ICustomSeveritiesMap,
  ICustomSeveritiesObj,
  ICustomSeverity,
  ICustomSeverityWithSeverityKey,
} from '../../../shared/interfaces/tenant.interface';
import { $severity, CustomSeverityKeyType, systemSeverities } from '../../../shared/constants/accessibility';
import { $customSeverity } from '../../../shared/constants/tenant';

@Injectable({
  providedIn: 'root',
})
export class TenantSeveritiesService {
  public constructor(private tenantSeveritiesApi: TenantSeveritiesApi) {}

  public getSeverityKeysSortedByRank(severitiesMap: ICustomSeveritiesMap): $severity[] {
    const severityArray: ICustomSeverityWithSeverityKey[] = [];

    severitiesMap.forEach((value: ICustomSeverity, key: $severity): void => {
      severityArray.push({ ...value, severityKey: key });
    });

    severityArray.sort(
      (a: ICustomSeverityWithSeverityKey, b: ICustomSeverityWithSeverityKey): number =>
        b[$customSeverity.rank] - a[$customSeverity.rank],
    );

    return severityArray.map((item: ICustomSeverityWithSeverityKey) => item.severityKey);
  }

  public getCustomSeverityKeysSortedByRank(severitiesMap: ICustomSeveritiesMap): $severity[] {
    return this.getSeverityKeysSortedByRank(severitiesMap).filter(
      (severity: $severity): boolean => !systemSeverities.includes(severity),
    );
  }

  public getAll(): Observable<ICustomSeveritiesMap> {
    return this.tenantSeveritiesApi
      .getAll()
      .pipe(map((customSeverities: ICustomSeveritiesObj) => new Map(Object.entries(customSeverities)) as ICustomSeveritiesMap));
  }

  public create(createSeveritiesData: IUpsertCustomSeverityRequest): Observable<ICustomSeverity> {
    return this.tenantSeveritiesApi.create(createSeveritiesData);
  }

  public update(severityKey: $severity, updateSeveritiesData: IUpsertCustomSeverityRequest): Observable<ICustomSeverity> {
    return this.tenantSeveritiesApi.update(severityKey, updateSeveritiesData);
  }

  public swapRanks(updateSeveritiesData: ISwapCustomSeveritiesRanksRequest): Observable<void> {
    return this.tenantSeveritiesApi.swapRanks(updateSeveritiesData);
  }

  public deleteAndReassignSeverity(toDelete: CustomSeverityKeyType, reassignTo: $severity): Observable<void> {
    return this.tenantSeveritiesApi.deleteAndReassignSeverity(toDelete, reassignTo);
  }
}
