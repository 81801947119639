import { AfterViewInit, Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { $navigationCustomState, NavigationCustomState } from '../../../interfaces/navigation-custom-state.interface';

@Component({
  selector: 'compound-navbar-link',
  templateUrl: './compound-navbar-link.component.html',
  styleUrls: ['./compound-navbar-link.component.scss'],
})
export class CompoundNavbarLinkComponent implements AfterViewInit {
  private _isActive: boolean;
  protected readonly captureFocusNavState: NavigationCustomState = { [$navigationCustomState.captureNavFocus]: true };

  @Input() navLink: string = '';

  @Input() set isActive(value: boolean) {
    this._isActive = value;
    this.setFocus();
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  @Input() hasChildren: boolean;

  @ViewChild('anchor') anchorElement: ElementRef;

  constructor(
    private zone: NgZone,
    private location: Location,
  ) {}

  public get ariaCurrent(): string | null {
    if (this.isActive && !this.hasChildren) {
      return 'page';
    }

    return null;
  }

  private setFocus(): void {
    // the 'captureFocus' state variable is only set if the navigation was triggered by clicking a nav menu item
    // for accessibility reasons in such case the navigation item clicked should then be focused on reload
    // it should, however, not be focused if the navigation happened for any other, random reason (like sorting a table
    // and storing the sort order in the query params). That was the root cause of EAP-24977.
    const focusCaptureRequested: boolean = this.location.getState()?.[$navigationCustomState.captureNavFocus] === true;
    const shouldSetFocus: boolean =
      this.isActive && focusCaptureRequested && SharedCommonUtility.notNullish(this.anchorElement?.nativeElement);

    if (shouldSetFocus) {
      this.zone.runOutsideAngular(() =>
        setTimeout(() => {
          this.anchorElement.nativeElement.focus();
        }, 0),
      );
    }
  }

  public ngAfterViewInit(): void {
    this.setFocus();
  }
}
