<div class="common-input" [ngClass]="view" [class.full-width-input]="fullWidthInput" [formGroup]="form">
  <div>
    <label
      *ngIf="!context.noLabel"
      for="{{ context.customLabelFormatter ? undefined : context.id ?? context.field }}"
      [attr.aria-hidden]="context.customLabelFormatter ? true : false"
      id="{{ labelId }}"
      class="label mb-1 text-nowrap"
      [ngClass]="{ required: required }"
      [class.visuallyhidden]="context.hiddenLabel"
    >
      {{ context.label | translate }}<span *ngIf="required" class="color-error"> *</span>
    </label>
    <button
      ds-button
      *ngIf="context.tooltip"
      [variant]="DsButtonVariants.microAction"
      [microActionIcon]="Icons.CircleQuestion"
      class="btn margin-left-sm"
      
      [ds-tooltip]="context.tooltip | translate"
      [tooltipPlacement]="DsTooltipPosition.top"
    ></button>
  </div>
  <span id="{{ subLabelId }}" *ngIf="context.subLabel" class="text-black">
    {{ context.subLabel | translate }}
  </span>
  <div
    class="flex-container"
    [class.description-bottom]="context.descriptionLocation === 'bottom'"
    [class.description-right]="context.descriptionLocation === 'right'"
  >
    <span
      *ngIf="context.description"
      class="description"
      [id]="descriptionId"
      [attr.aria-hidden]="context.customLabelFormatter ? true : false"
    >
      {{ context.description | translate }}
    </span>
    <input
      [type]="type"
      id="{{ context.id ?? context.field }}"
      class="form-element"
      [attr.autocomplete]="context.autocomplete"
      [class.w-100]="fullWidthInput"
      [ngStyle]="context.width ? { width: context.width } : {}"
      [placeholder]="context.placeholder ? (context.placeholder | translate) : ''"
      [name]="context.field"
      [formControlName]="context.field"
      [attr.aria-labelledby]="
        context.customLabelFormatter && context.customLabelFormatter(labelId, context.id ?? context.field, descriptionId)
      "
      [attr.data-lpignore]="context.autocomplete === 'off'"
      [attr.aria-describedby]="
        (context.id ?? context.field) +
        '_error' +
        (context.describedby ? ' ' + context.describedby : '') +
        (context.subLabel ? ' ' + subLabelId : '')
      "
      [attr.aria-invalid]="form | fieldInvalid: context.field"
      [attr.aria-required]="required"
      [attr.required]="context.required ? '' : null"
      [readOnly]="readonly"
    />
  </div>
  <div [id]="(context.id ?? context.field) + '_error'" class="error-container">
    <app-form-field-error
      [formValidationRequest$]="formValidationRequest$"
      [field]="form.get(context.field)"
      [name]="context.field"
      [label]="context.label"
      [customMessage]="context.customMessage"
      [overrideErrors]="context.overrideErrors"
    ></app-form-field-error>
  </div>
</div>
