<div class="filter-input text-nowrap">
  <form [formGroup]="form" (submit)="formSubmitted()">
    <div class="row">
      <div class="col col-auto d-flex mb-2 pe-0 align-items-center">
        <label for="{{ componentID }}">{{ inputName }}</label>
      </div>
      <div class="col col-auto d-flex mb-2 ps-0 align-items-center">
        <input type="text" id="{{ componentID }}" [formControlName]="$formFields.filterValue" class="margin-left-xs" />
        <button type="submit" class="btn btn-outline-primary fw-bold margin-left-xs">{{ buttonName }}</button>
      </div>
    </div>
  </form>
</div>
