import { AbstractControl } from '@angular/forms';
import { scanRecurrenceEvery } from '../../../../../shared/constants/scanning';
import { SharedDateUtility } from '../../../../../shared/utils/date.utility';
import { TranslateService } from '../../../translate/translate.service';

export class MonitoringDateUtils {
  /**
   * Sets the value of the date input field with the local date of the date
   * passed in parameter.
   *
   * @param dateControl the date form input control
   * @param date the date to show
   */
  public static setLocalInputDateFromDate(dateControl: AbstractControl, date: Date | string): void {
    dateControl.setValue(SharedDateUtility.getLocalISODate(date));
  }

  public static getScheduleMessageString(
    translateService: TranslateService,
    monitoringLabel: boolean,
    recurrence: scanRecurrenceEvery,
    startAt: Date | string,
  ): string {
    let dayOn: string;
    let translateKey: string;

    const startDate: Date = new Date(startAt);
    const startDateString: string = SharedDateUtility.getDateString(startDate);

    const formatDays = (date: Date): string => {
      return translateService.instant(`days_${date.getDay()}`);
    };

    switch (recurrence) {
      case scanRecurrenceEvery.biweekly:
        translateKey = 'bi_weekly_scan_schedule';
        dayOn = formatDays(startDate);
        break;
      case scanRecurrenceEvery.week:
        translateKey = 'weekly_scan_schedule';
        dayOn = formatDays(startDate);
        break;
      case scanRecurrenceEvery.month:
        translateKey = 'monthly_scan_schedule';
        dayOn = String(startDate.getDate());
        break;
      default:
        return '';
    }

    if (monitoringLabel) {
      translateKey = `monitoring_${translateKey}`;
    }

    return translateService.instant(translateKey, [dayOn, startDateString]);
  }
}
