<h3>{{ 'website_apps' | translate }}</h3>

<div class="d-flex justify-content-between mb-3" [formGroup]="searchAndFilterForm">
  <div>
    <!--    TODO DS-471: replace to Design System component once it is ready-->
    <app-searchbox
      [label]="'search_by_website_or_app' | translate"
      [placeholder]="'form_search' | translate"
      [formControlName]="SearchAndFilterFormFields.digitalPropertySearch"
    ></app-searchbox>
  </div>
  <div class="flex-auto">
    <ds-input [label]="'select_a_workspace' | translate">
      <select
        ds-input-field
        [formControlName]="SearchAndFilterFormFields.workspaceFilter"
        (keydown.enter)="onWorkspaceFilterEnter($event)"
      >
        <option value="">{{ 'select_a_workspace_to_filter' | translate }}</option>
        <option *ngFor="let option of workspaceFilterOptions" [value]="option.workspaceId">
          {{ option.workspaceName }}
        </option>
      </select>
    </ds-input>
  </div>
</div>

<app-ngb-table [config]="tableConfig" [data]="tableData$ | async" [sortingOrder]="sortOrder$ | async" (onSort)="onSort($event)">
</app-ngb-table>

<ds-pagination
  [ariaLabel]="'digital_properties_permissions_table_pagination' | translate"
  [page]="page$ | async"
  [collectionSize]="total$"
  (pageChange)="onPageChange($event)"
>
</ds-pagination>
