import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-filter',
  templateUrl: './accordion-filter.component.html',
  styleUrls: ['./accordion-filter.component.scss'],
})
export class AccordionFilterComponent {
  public filtersCollapsed: boolean;

  @Output()
  public onClose: EventEmitter<void>;
  @Output()
  public onApply: EventEmitter<void>;
  @Output()
  public onClear: EventEmitter<void>;
  @Input()
  public filtersApplied: boolean;
  @Input()
  public filterLabels: string[];

  constructor() {
    this.onClose = new EventEmitter();
    this.onApply = new EventEmitter();
    this.onClear = new EventEmitter();
    this.filtersCollapsed = true;
    this.filtersApplied = false;
    this.filterLabels = [];
  }

  public clearAllFilters(): void {
    this.onClear.emit();
  }

  public closeFilters(): void {
    this.filtersCollapsed = true;
    this.onClose.emit();
  }

  public applyFilters(): void {
    this.filtersCollapsed = true;
    this.onApply.emit();
  }
}
