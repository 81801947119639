import { SharedCommonUtility } from '../utils/common.utility';
import { SharedObjectUtility } from '../utils/object.utility';
import { IFunctionalAreaHierarchy } from '../interfaces/security-role.interface';
import { FunctionalArea } from './security';

export const FunctionalAreaHierarchy: IFunctionalAreaHierarchy = SharedCommonUtility.freezeDeep<IFunctionalAreaHierarchy>({
  [FunctionalArea.application]: {
    [FunctionalArea.pdf]: {
      [FunctionalArea.pdf_testing]: {},
      [FunctionalArea.pdf_remediation]: {},
      [FunctionalArea.pdf_administration]: {},
    },
    [FunctionalArea.governance_and_reporting]: {
      [FunctionalArea.gr_dashboard]: {},
      [FunctionalArea.gr_governance]: {},
      [FunctionalArea.gr_monitoring]: {},
      [FunctionalArea.gr_organization_dashboard]: {},
      [FunctionalArea.summary_snapshot_api]: {},
    },
    [FunctionalArea.design_evaluations]: {
      [FunctionalArea.de_administration]: {},
      [FunctionalArea.de_results]: {},
    },
    [FunctionalArea.tools_and_integrations]: {
      [FunctionalArea.ti_tools_and_extensions]: {},
      [FunctionalArea.ti_issue_tracking_basic]: {},
      [FunctionalArea.ti_issue_tracking_administration]: {},
      [FunctionalArea.ti_issue_tracking_configuration]: {},
      [FunctionalArea.ti_issue_tracking_configuration_additional_fields]: {},
      [FunctionalArea.ti_scim_config]: {},
      [FunctionalArea.webhook]: {},
    },
    [FunctionalArea.sdk]: {},
    [FunctionalArea.api]: {
      [FunctionalArea.api_keys]: {},
      [FunctionalArea.api_general_apis]: {},
      [FunctionalArea.api_automated_scans]: {},
      [FunctionalArea.api_manual_evaluations]: {},
      [FunctionalArea.api_user_flows]: {},
      [FunctionalArea.api_tenant_admin]: {},
      [FunctionalArea.api_pages]: {},
      [FunctionalArea.api_scim]: {},
    },
    [FunctionalArea.translations]: {},
    [FunctionalArea.evaluation_rules]: {},
    [FunctionalArea.master_library]: {},
    [FunctionalArea.scan_tags]: {},
    [FunctionalArea.active_scans]: {},
    [FunctionalArea.project_management]: {
      [FunctionalArea.pm_projects]: {},
      [FunctionalArea.pm_tasks]: {},
      [FunctionalArea.pm_task_comments]: {},
      [FunctionalArea.pm_task_admin]: {},
    },
    [FunctionalArea.pages]: {},
    [FunctionalArea.automated_flows]: {},
    [FunctionalArea.manual_testing]: {
      [FunctionalArea.mt_manual_evaluations]: {},
      [FunctionalArea.mt_manual_audits]: {},
      [FunctionalArea.mt_comments]: {},
      [FunctionalArea.mt_comments_admin]: {},
    },
    [FunctionalArea.documents]: {},
    [FunctionalArea.automated_testing]: {
      [FunctionalArea.at_scans]: {},
      [FunctionalArea.at_run_automated_scans]: {},
      [FunctionalArea.at_mobile_scans]: {},
    },
    [FunctionalArea.component_matching]: {},
    [FunctionalArea.groups]: {
      [FunctionalArea.user_groups]: {},
      [FunctionalArea.staff_groups]: {},
    },
    [FunctionalArea.tenant_switcher]: {},
    [FunctionalArea.browser_extension]: {
      [FunctionalArea.be_automated_test]: {},
      [FunctionalArea.be_manual_test]: {},
      [FunctionalArea.be_user_flows_api]: {},
    },
    [FunctionalArea.global_users]: {
      [FunctionalArea.gu_global_users]: {},
      [FunctionalArea.gu_all_tenant_admin]: {},
    },
    [FunctionalArea.notifications]: {},
    [FunctionalArea.user_management]: {
      [FunctionalArea.um_user_administration]: {},
      [FunctionalArea.um_organization_administration]: {},
    },
    [FunctionalArea.tenants]: {},
    [FunctionalArea.workspaces]: {},
    [FunctionalArea.digital_properties]: {},
    [FunctionalArea.academy]: {},
    [FunctionalArea.service]: {
      [FunctionalArea.service_support]: {},
      [FunctionalArea.validation_testing]: {},
      [FunctionalArea.ai_chat]: {},
      [FunctionalArea.request_manual_evaluation]: {},
    },
    [FunctionalArea.preprod_testing]: {
      [FunctionalArea.preprod_testing_env_settings]: {},
      [FunctionalArea.preprod_testing_reporting]: {},
      [FunctionalArea.preprod_testing_scan_results]: {},
    },
    [FunctionalArea.tenant_rule_libraries]: {
      [FunctionalArea.trl_custom_rule_library_management]: {},
    },
    [FunctionalArea.organization_portfolio]: {},

    [FunctionalArea.userway]: {
      [FunctionalArea.userway_integration]: {},
    },

    [FunctionalArea.severities]: {
      [FunctionalArea.custom_severities]: {},
    },
  },

  invalid: {},
});

export const FlattenedFunctionalAreaHierarchy: Record<FunctionalArea, FunctionalArea[]> = SharedCommonUtility.freezeDeep(
  SharedObjectUtility.flattenWithLeafProperties(FunctionalAreaHierarchy),
);
