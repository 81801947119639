export enum $scan {
  _id = '_id',
  audits = 'audits',
  auditsId = 'auditsId',
  authPassword = 'authPassword',
  authType = 'authType',
  authUser = 'authUser',
  brokenLinks = 'brokenLinks',
  scannableDocuments = 'scannableDocuments',
  discoverScannableDocuments = 'discoverScannableDocuments',
  conformanceLevel = 'conformanceLevel',
  contentType = 'contentType',
  context = 'context',
  contextDescription = 'contextDescription',
  crawlDeepLevel = 'crawlDeepLevel',
  crawled = 'crawled',
  createdAt = 'createdAt',
  createdAtTimezoneOffset = 'createdAtTimezoneOffset',
  customFiles = 'customFiles',
  customLinks = 'customLinks',
  digitalProperty = 'digitalProperty',
  emails = 'emails',
  error = 'error',
  excludeUrls = 'excludeUrls',
  excludeUrlOperations = 'excludeUrlOperations',
  errorReason = 'errorReason',
  findingsArchival = 'findingsArchival',
  findingsArchivalDate = 'findingsArchivalDate',
  headers = 'headers',
  includeUrls = 'includeUrls',
  listOfUrls = 'listOfUrls',
  languageForScanning = 'languageForScanning',
  limitCrawlingPagesTo = 'limitCrawlingPagesTo',
  manualUploadFiles = 'manualUploadFiles',
  manualAuditStatus = 'manualAuditStatus',
  pageTitle = 'pageTitle',
  previousScans = 'previousScans',
  preScanFlowId = 'preScanFlowId',
  pagesWithErrors = 'pagesWithErrors',
  processingUrls = 'processingUrls',
  processingEndedAt = 'processingEndedAt',
  processingStartedAt = 'processingStartedAt',
  repeatEvery = 'repeatEvery',
  reportFormats = 'reportFormats',
  resolvedUrl = 'resolvedUrl',
  resourceType = 'resourceType',
  rule = 'rule',
  scanAuthenticationType = 'scanAuthenticationType',
  scanAuthenticationPassword = 'scanAuthenticationPassword',
  scanAuthenticationUserName = 'scanAuthenticationUserName',
  scanMonthlyOnDay = 'scanMonthlyOnDay',
  scanRecurrenceEvery = 'scanRecurrenceEvery',
  scanRecurrenceSelectedDay = 'scanRecurrenceSelectedDay',
  scanRecurrenceType = 'scanRecurrenceType',
  scanningType = 'scanningType',
  scheduleId = 'scheduleId',
  scheduledAt = 'scheduledAt',
  secureWebAuthentication = 'secureWebAuthentication',
  sendEmail = 'sendEmail',
  emailsToNotify = 'emailsToNotify',
  selectedAudits = 'selectedAudits',
  skipHashes = 'skipHashes',
  skipQueryParams = 'skipQueryParams',
  skippedPages = 'skippedPages',
  startScanAtDate = 'startScanAtDate',
  status = 'status',
  crawlingStatus = 'crawlingStatus',
  stopScanAtDate = 'stopScanAtDate',
  swaLoginPageURL = 'swaLoginPageURL',
  swaUserName = 'swaUserName',
  swaUserNameFieldId = 'swaUserNameFieldId',
  swaPassword = 'swaPassword',
  swaPasswordFieldId = 'swaPasswordFieldId',
  swaSubmitFieldId = 'swaSubmitFieldId',
  swaSubmitUsernameFieldId = 'swaSubmitUsernameFieldId',
  swaSubmitSuccessId = 'swaSubmitSuccessId',
  tag = 'tag',
  title = 'title',
  totalCrawledPages = 'totalCrawledPages',
  totalScannedPages = 'totalScannedPages',
  isArchived = 'isArchived',
  archivedBy = 'archivedBy',
  unlimitedCrawlDeepLevel = 'unlimitedCrawlDeepLevel',
  unlimitedCrawlingPagesTo = 'unlimitedCrawlingPagesTo',
  updatedAt = 'updatedAt',
  url = 'url',
  urlResolved = 'urlResolved',
  user = 'user',
  userAgent = 'userAgent',
  userId = 'userId',
  viewport = 'viewport',
  workspace = 'workspace',
  scanOrigin = 'scanOrigin',
  isQuickMonitoring = 'isQuickMonitoring',
  automatedUserFlowId = 'automatedUserFlowId',
  automatedUserFlowError = 'automatedUserFlowError',
  score = 'score',
  scope = 'scope',
  handlePopupsSelector = 'handlePopupsSelector',
  mobile = 'mobile',
  includeIframes = 'includeIframes',
  origin = 'origin',
  waitTimeSec = 'waitTimeSec',
}

export enum $automatedUserFlowError {
  actionId = 'actionId',
  errorMessage = 'errorMessage',
  actionType = 'actionType',
  actionValue = 'actionValue',
  actionCssSelector = 'actionCssSelector',
}

export enum $scanSearch {
  createdAt = 'createdAt',
  entriesAmount = 'entriesAmount',
  limit = 'limit',
  scanningType = 'scanningType',
  searchTerm = 'searchTerm',
  skip = 'skip',
  sortByName = 'sortByName',
  status = 'status',
  url = 'url',
  tag = 'tag',
  title = 'title',
  tool = 'tool',
  mobileAppVersion = 'mobileAppVersion',
  mobilePlatformName = 'mobilePlatformName',
  mobilePlatformVersion = 'mobilePlatformVersion',
}

export enum $rerunScan {
  pages = 'pages',
}

export enum $scanScope {
  testGuideId = 'testGuideId',
  testGuide = 'testGuide',
  pages = 'pages',
  standards = 'standards',
  medium = 'medium',
}

export enum $pageWithComponents {
  pageId = 'pageId',
  componentIds = 'componentIds',
  page = 'page',
  components = 'components',
}

export enum $scanAutomatedOptions {
  scanningType = 'scanningType',
  title = 'title',
  viewport = 'viewport',
  conformanceLevel = 'conformanceLevel',
  url = 'url',
  tag = 'tag',
}

export enum $scanSchedule {
  _id = '_id',
  data = 'data',
  startTime = 'startTime',
  endTime = 'endTime',
  interval = 'interval',
  userTimezoneOffset = 'userTimezoneOffset',
}

export enum $scanHeaders {
  name = 'name',
  value = 'value',
}

export enum $brokenLinkData {
  url = 'url',
  responseCode = 'responseCode',
}

export const $thumbnailSuffix: string = '_thumbnail';
export const $screenshotPrefix: string = 'screenshot';

export const SCAN_URLS_SIZE_LIMIT: number = 100;

export enum $scanMobile {
  platformName = 'platformName',
  platformVersion = 'platformVersion',
  deviceManufacturer = 'deviceManufacturer',
  deviceModel = 'deviceModel',
  deviceName = 'deviceName',
  deviceType = 'deviceType',
  appIdentifier = 'appIdentifier',
  appVersion = 'appVersion',
  continuumSdkVersion = 'continuumSdkVersion',
  appiumAutomationName = 'appiumAutomationName',
}

export enum $scanMobilePlatformName {
  android = 'android',
  ios = 'ios',
}

export enum $scanMobileDeviceType {
  unknown = 'unknown',
  device = 'device',
  emulator = 'emulator',
  simulator = 'simulator',
}
