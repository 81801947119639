import { Subscription } from 'rxjs';
import { Directive, OnInit, OnDestroy, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { VariationValue } from '@harnessio/ff-javascript-client-sdk';

import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { FeatureFlagCollection } from '../../../../shared/interfaces/feature-flag.interface';

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
  /**
   * This class directive can be used to evaluate a feature flag in the template level.
   */
  private _flag: string;
  private _negate: boolean;
  private isHidden: boolean;
  private subscriptions: Subscription;

  constructor(
    private featureFlagService: FeatureFlagService,
    private tplRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.subscriptions = new Subscription();
    this.isHidden = true;
    this._negate = false;
  }

  @Input()
  public set featureFlag(flag: string) {
    if (SharedCommonUtility.isNullishOrEmpty(flag)) {
      throw new Error('Feature flag key is required.');
    }

    const isNegatedFlag: boolean = flag[0] === '!';
    if (isNegatedFlag) {
      const flagKey: string = flag.slice(1);
      if (!Object.values(FeatureFlagCollection).includes(flagKey as FeatureFlagCollection)) {
        throw new Error(`${flagKey} is not a valid feature flag`);
      }
      this._negate = true;
      this._flag = flagKey;
    } else {
      if (!Object.values(FeatureFlagCollection).includes(flag as FeatureFlagCollection)) {
        throw new Error(`${flag} is not a valid feature flag`);
      }

      this._flag = flag;
    }
  }

  private updateView(val: VariationValue): void {
    if (!val) {
      this.viewContainerRef.clear();
      this.isHidden = true;
      return;
    }

    if (this.isHidden) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.tplRef);
      this.isHidden = false;
    }
  }

  private checkValidity(): void {
    this.subscriptions.add(
      this.featureFlagService.variation$(this._flag, false).subscribe({
        next: (val: VariationValue) => this.updateView(this._negate ? !val : val),
      }),
    );
  }

  public ngOnInit(): void {
    this.checkValidity();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
