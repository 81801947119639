<nav
  [class.compound-top-navbar]="!showMobileNavVersion"
  [class.compound-top-navbar-mobile]="showMobileNavVersion"
  [attr.aria-label]="'top_level_navbar_label' | translate"
>
  <div class="container g-5">
    <div class="compound-top-navbar-left">
      <ds-link queryParamsHandling="preserve" [standalone]="true"  class="top-nav-logo-link">
        <img
          class="top-navbar-logo-img"
          src="/static/assets/images/level-access-logo-new.png"
          srcset="/static/assets/images/level-access-logo-new.png 1x, /static/assets/images/level-access-logo-new@2x.png 2x"
          alt="{{ 'level_access_home' | translate }}"
        />
      </ds-link>
    </div>

    <div class="compound-top-navbar-middle"></div>

    <div class="compound-top-navbar-right"></div>
  </div>
</nav>
