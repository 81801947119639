import { Pipe, PipeTransform } from '@angular/core';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Pipe({
  name: 'arraytostring',
})
export class ArrayToStringPipe implements PipeTransform {
  public transform(value: any[]): string {
    if (SharedCommonUtility.isNullishOrEmpty(value)) {
      return '';
    }
    return value.join(', ');
  }
}
