<div
  class="w-100 h-100 image-highlighter-container"
  (document:keydown.meta.v)="pasteFinding($event)"
  (document:keydown.control.v)="pasteFinding($event)"
  (document:mousemove)="updateAbsoluteMousePosition($event)"
>
  <div class="w-100 h-100 position-relative image-container p-0">
    <img
      #img
      (load)="onImageLoaded()"
      [src]="screenshotPath$ | async"
      width="100%"
      height="100%"
      alt=""
      class="page-screenshot"
    />
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      (window:resize)="redraw()"
      (mousedown)="onMouseDown($event)"
      (mouseup)="onMouseUp($event)"
      (mousemove)="onMouseMove($event)"
      (mouseleave)="onMouseLeave($event)"
      [class.mode-add]="mode === imageHighlightModes.add"
      [class.mode-edit]="mode === imageHighlightModes.edit"
      [attr.aria-label]="getScreenLabel(highlights, findingsLength)"
      role="list"
      #highlightRects
    >
      <ng-container *ngFor="let highlight of highlights; trackBy: highlightTrackBy; let indexOfelement = index">
        <g class="highlight" [attr.id]="highlight._id" role="listitem">
          <ng-container *ngIf="highlight.isFocused">
            <rect
              class="focus-border-inline"
              overflow="visible"
              [attr.x]="highlight.x - 4"
              [attr.y]="highlight.y - 4"
              [attr.height]="highlight.height + highlightDisplayOptions.outlineBorderOffset * 2"
              [attr.width]="highlight.width + highlightDisplayOptions.outlineBorderOffset * 2"
              [attr.rx]="4"
              [attr.stroke-width]="4"
            ></rect>
            <rect
              class="focus-border-outline"
              overflow="visible"
              [attr.x]="highlight.x - 4"
              [attr.y]="highlight.y - 4"
              [attr.height]="highlight.height + highlightDisplayOptions.outlineBorderOffset * 2"
              [attr.width]="highlight.width + highlightDisplayOptions.outlineBorderOffset * 2"
              [attr.rx]="4"
              [attr.stroke-width]="2"
            ></rect>
          </ng-container>

          <ng-container *ngIf="highlight.mode === highlightModes.view">
            <ng-container *ngTemplateOutlet="highlightView"></ng-container>
          </ng-container>

          <ng-container *ngIf="highlight.mode === highlightModes.edit">
            <ng-container *ngTemplateOutlet="highlightEdit"></ng-container>
          </ng-container>

          <rect
            [ngClass]="{ 'hover-pointer': mode !== $imageHighlightModes.add && highlight.isSelected === false }"
            class="clickable"
            role="button"
            [attr.aria-label]="
              highlight.findings.length === 1
                ? ('highlight_with_single_finding' | translate)
                : ('highlight_with_n_findings' | translate: highlight.findings.length)
            "
            (mousedown)="onHighlightMouseDown($event, highlight)"
            [attr.x]="highlight.x"
            [attr.y]="highlight.y"
            [attr.height]="highlight.height"
            [attr.width]="highlight.width"
            appCtrlKey=""
            (ctrlS)="saveSelectedHighlight()"
            (ctrlC)="copySelectedHighlight()"
            (ctrlV)="pasteSelectedHighlight()"
            (click)="triggerHighlightSelected(highlight)"
            (keyup)="resetOtherDirection($event.key)"
            (keydown)="setOtherDirection($event.key)"
            (keydown.arrowUp)="onKeyboardMove($event, highlight, MovementDirection.ArrowUp, MovementSpeed.slow)"
            (keydown.arrowDown)="onKeyboardMove($event, highlight, MovementDirection.ArrowDown, MovementSpeed.slow)"
            (keydown.arrowLeft)="onKeyboardMove($event, highlight, MovementDirection.ArrowLeft, MovementSpeed.slow)"
            (keydown.arrowRight)="onKeyboardMove($event, highlight, MovementDirection.ArrowRight, MovementSpeed.slow)"
            (keydown.shift.arrowUp)="onKeyboardMove($event, highlight, MovementDirection.ArrowUp, MovementSpeed.fast)"
            (keydown.shift.arrowDown)="onKeyboardMove($event, highlight, MovementDirection.ArrowDown, MovementSpeed.fast)"
            (keydown.shift.arrowLeft)="onKeyboardMove($event, highlight, MovementDirection.ArrowLeft, MovementSpeed.fast)"
            (keydown.shift.arrowRight)="onKeyboardMove($event, highlight, MovementDirection.ArrowRight, MovementSpeed.fast)"
            (keydown.control.arrowUp)="onKeyboardResize($event, highlight, MovementDirection.ArrowUp, MovementSpeed.slow)"
            (keydown.control.arrowRight)="onKeyboardResize($event, highlight, MovementDirection.ArrowRight, MovementSpeed.slow)"
            (keydown.control.arrowDown)="onKeyboardResize($event, highlight, MovementDirection.ArrowDown, MovementSpeed.slow)"
            (keydown.control.arrowLeft)="onKeyboardResize($event, highlight, MovementDirection.ArrowLeft, MovementSpeed.slow)"
            (keydown.control.shift.arrowUp)="onKeyboardResize($event, highlight, MovementDirection.ArrowUp, MovementSpeed.fast)"
            (keydown.control.shift.arrowRight)="
              onKeyboardResize($event, highlight, MovementDirection.ArrowRight, MovementSpeed.fast)
            "
            (keydown.control.shift.arrowDown)="
              onKeyboardResize($event, highlight, MovementDirection.ArrowDown, MovementSpeed.fast)
            "
            (keydown.control.shift.arrowLeft)="
              onKeyboardResize($event, highlight, MovementDirection.ArrowLeft, MovementSpeed.fast)
            "
            (keydown.meta.arrowUp)="onKeyboardResize($event, highlight, MovementDirection.ArrowUp, MovementSpeed.slow)"
            (keydown.meta.arrowRight)="onKeyboardResize($event, highlight, MovementDirection.ArrowRight, MovementSpeed.slow)"
            (keydown.meta.arrowDown)="onKeyboardResize($event, highlight, MovementDirection.ArrowDown, MovementSpeed.slow)"
            (keydown.meta.arrowLeft)="onKeyboardResize($event, highlight, MovementDirection.ArrowLeft, MovementSpeed.slow)"
            (keydown.meta.shift.arrowUp)="onKeyboardResize($event, highlight, MovementDirection.ArrowUp, MovementSpeed.fast)"
            (keydown.meta.shift.arrowRight)="
              onKeyboardResize($event, highlight, MovementDirection.ArrowRight, MovementSpeed.fast)
            "
            (keydown.meta.shift.arrowDown)="onKeyboardResize($event, highlight, MovementDirection.ArrowDown, MovementSpeed.fast)"
            (keydown.meta.shift.arrowLeft)="onKeyboardResize($event, highlight, MovementDirection.ArrowLeft, MovementSpeed.fast)"
            (keydown.enter)="handleHighlightKeyEnterEvent($event, highlight)"
            (keydown.space)="handleHighlightKeyEnterEvent($event, highlight)"
            (keydown.escape)="onHighlightDeselect(highlight)"
            (keydown.shift.tab)="onHighlightReverseTab(highlight)"
            (focus)="onHighlightFocused($event, highlight)"
            (blur)="onHighlightBlur(highlight)"
            tabindex="0"
          ></rect>

          <ng-container *ngFor="let finding of highlight.findings; trackBy: findingTrackBy; let indexOfelement = index">
            <ng-container
              *ngTemplateOutlet="highlightNumberBox; context: { finding: finding, highlight: highlight }"
            ></ng-container>
          </ng-container>

          <ng-container
            *ngTemplateOutlet="
              actionBox;
              context: { highlight: highlight, highlightIsActionHighlight: isActionHighlight(highlight) }
            "
          ></ng-container>
        </g>

        <ng-template #highlightView>
          <rect
            class="highlight-outer-border"
            [attr.x]="highlight.x"
            [attr.y]="highlight.y"
            [attr.height]="highlight.height"
            [attr.width]="highlight.width"
          ></rect>
          <rect
            class="highlight"
            [attr.x]="highlight.x"
            [attr.y]="highlight.y"
            [attr.height]="highlight.height"
            [attr.width]="highlight.width"
          ></rect>
        </ng-template>

        <ng-template #highlightEdit>
          <rect
            class="highlight-outer-border"
            [attr.x]="highlight.x"
            [attr.y]="highlight.y"
            [attr.height]="highlight.height"
            [attr.width]="highlight.width"
          ></rect>
          <rect
            class="highlight"
            [attr.x]="highlight.x"
            [attr.y]="highlight.y"
            [attr.height]="highlight.height"
            [attr.width]="highlight.width"
          ></rect>
          <rect
            [ngClass]="{ 'top-left': mode !== $imageHighlightModes.add }"
            class="resize"
            [attr.x]="getResizeBoxLeftX(highlight)"
            [attr.y]="getResizeBoxTopY(highlight)"
            [attr.width]="highlightDisplayOptions.resizeRectSize"
            [attr.height]="highlightDisplayOptions.resizeRectSize"
            (mousedown)="onHighlightMouseDown($event, highlight)"
          ></rect>
          <rect
            [ngClass]="{ 'top-right': mode !== $imageHighlightModes.add }"
            class="resize"
            [attr.x]="getResizeBoxRightX(highlight)"
            [attr.y]="getResizeBoxTopY(highlight)"
            [attr.width]="highlightDisplayOptions.resizeRectSize"
            [attr.height]="highlightDisplayOptions.resizeRectSize"
            (mousedown)="onHighlightMouseDown($event, highlight)"
          ></rect>
          <rect
            [ngClass]="{ 'bottom-left': mode !== $imageHighlightModes.add }"
            class="resize"
            [attr.x]="getResizeBoxLeftX(highlight)"
            [attr.y]="getResizeBoxBottomY(highlight)"
            [attr.width]="highlightDisplayOptions.resizeRectSize"
            [attr.height]="highlightDisplayOptions.resizeRectSize"
            (mousedown)="onHighlightMouseDown($event, highlight)"
          ></rect>
          <rect
            [ngClass]="{ 'bottom-right': mode !== $imageHighlightModes.add }"
            class="resize"
            [attr.x]="getResizeBoxRightX(highlight)"
            [attr.y]="getResizeBoxBottomY(highlight)"
            [attr.width]="highlightDisplayOptions.resizeRectSize"
            [attr.height]="highlightDisplayOptions.resizeRectSize"
            (mousedown)="onHighlightMouseDown($event, highlight)"
          ></rect>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="newBox as box">
        <rect
          class="highlight-outer-border"
          [attr.x]="box.x"
          [attr.y]="box.y"
          [attr.height]="box.height"
          [attr.width]="box.width"
        ></rect>
        <rect class="highlight" [attr.x]="box.x" [attr.y]="box.y" [attr.height]="box.height" [attr.width]="box.width"></rect>
      </ng-container>

      <ng-template #highlightNumberBox let-finding="finding" let-highlight="highlight">
        <g
          class="highlight-number finding"
          [class.selected]="finding.isSelected"
          overflow="visible"
          [attr.aria-label]="('finding_number_n' | translate: finding.index + 1) + '. ' + finding.description"
          [attr.id]="finding._id"
          [attr.transform]="'translate(' + finding.numberBox.x + ',' + finding.numberBox.y + ')'"
        >
          <ng-container *ngIf="finding.isFocused">
            <rect
              class="focus-border-inline"
              overflow="visible"
              [attr.x]="-2"
              [attr.y]="-2"
              [attr.height]="finding.numberBox.height + 4"
              [attr.width]="finding.numberBox.width + 4"
              [attr.rx]="4"
              [attr.stroke-width]="4"
            ></rect>
            <rect
              class="focus-border-outline"
              overflow="visible"
              [attr.x]="-2"
              [attr.y]="-2"
              [attr.height]="finding.numberBox.height + 4"
              [attr.width]="finding.numberBox.width + 4"
              [attr.rx]="4"
              [attr.stroke-width]="2"
            ></rect>
          </ng-container>
          <rect
            class="outer-rect"
            [attr.x]="0"
            [attr.y]="0"
            [attr.height]="finding.numberBox.height"
            [attr.width]="finding.numberBox.width"
            [attr.rx]="highlightDisplayOptions.highlightNumberCornerRadius"
            [attr.stroke-width]="highlightDisplayOptions.highlightNumberRectStrokeWidth"
          ></rect>
          <rect
            class="inner-rect"
            [attr.x]="0"
            [attr.y]="0"
            [attr.height]="finding.numberBox.height"
            [attr.width]="finding.numberBox.width"
            [attr.rx]="highlightDisplayOptions.highlightNumberCornerRadius"
            [attr.stroke-width]="highlightDisplayOptions.highlightNumberRectStrokeWidth / 2"
          ></rect>
          <text
            [attr.x]="finding.numberBox.width / 2"
            [attr.y]="finding.numberBox.height / 2"
            dominant-baseline="middle"
            text-anchor="middle"
          >
            {{ finding.index + 1 }}
          </text>

          <rect
            class="clickable hover-pointer"
            role="button"
            [attr.x]="0"
            [attr.y]="0"
            [attr.height]="finding.numberBox.height"
            [attr.width]="finding.numberBox.width"
            [ngbPopover]="popContent"
            (shown)="selectInput()"
            #popover="ngbPopover"
            #element
            [popoverClass]="'highlight-popover-wrap'"
            triggers="manual"
            container="body"
            [placement]="tooltipPlacement"
            appCtrlKey=""
            (ctrlC)="copyFinding(finding)"
            (ctrlV)="pasteFinding($event)"
            (mousedown)="onFindingMouseDown($event, finding)"
            (click)="triggerFindingSelected(finding, popover); onFindingFocused($event, finding, highlight)"
            (keydown.enter)="handleFindingKeyEnterEvent($event, finding, popover); onFindingFocused($event, finding, highlight)"
            (keydown.space)="handleFindingKeyEnterEvent($event, finding, popover); onFindingFocused($event, finding, highlight)"
            (keydown.shift.tab)="onFindingReverseTab(finding, highlight)"
            (document:keydown.escape)="onFindingDeselect($event, finding, highlight)"
            (blur)="onFindingBlur(finding)"
            (focus)="onFindingFocused($event, finding, highlight)"
            tabindex="0"
          ></rect>

          <ng-template #popContent>
            <ng-container *ngTemplateOutlet="highlightPopover; context: { $implicit: finding, popover: popover }"></ng-container>
          </ng-template>
        </g>
      </ng-template>

      <ng-template #actionBox let-highlight="highlight">
        <ng-container *ngIf="highlight.isAction && mode !== 'view'">
          <g
            class="highlight-number add-finding"
            [class.selected]="actionSelected(highlight)"
            overflow="visible"
            [attr.aria-label]="'add_finding_action' | translate"
            [attr.id]="highlight._id + '_action_button'"
            [attr.transform]="'translate(' + highlight.action.box.x + ',' + highlight.action.box.y + ')'"
          >
            <ng-container *ngIf="highlight.action.isFocused">
              <rect
                class="focus-border-inline"
                overflow="visible"
                [attr.x]="-2"
                [attr.y]="-2"
                [attr.height]="highlight.action.box.height + 4"
                [attr.width]="highlight.action.box.width + 4"
                [attr.rx]="4"
                [attr.stroke-width]="4"
              ></rect>
              <rect
                class="focus-border-outline"
                overflow="visible"
                [attr.x]="-2"
                [attr.y]="-2"
                [attr.height]="highlight.action.box.height + 4"
                [attr.width]="highlight.action.box.width + 4"
                [attr.rx]="4"
                [attr.stroke-width]="2"
              ></rect>
            </ng-container>
            <rect
              class="outer-rect"
              [attr.x]="0"
              [attr.y]="0"
              [attr.height]="highlight.action.box.height"
              [attr.width]="highlight.action.box.width"
              [attr.rx]="highlightDisplayOptions.highlightNumberCornerRadius"
              [attr.stroke-width]="highlightDisplayOptions.highlightNumberRectStrokeWidth"
            ></rect>
            <rect
              class="inner-rect"
              [attr.x]="0"
              [attr.y]="0"
              [attr.height]="highlight.action.box.height"
              [attr.width]="highlight.action.box.width"
              [attr.rx]="highlightDisplayOptions.highlightNumberCornerRadius"
              [attr.stroke-width]="highlightDisplayOptions.highlightNumberRectStrokeWidth / 2"
            ></rect>
            <text
              [attr.x]="highlight.action.box.width / 2"
              [attr.y]="highlight.action.box.height / 2"
              dominant-baseline="middle"
              text-anchor="middle"
            >
              {{ 'add_finding_action' | translate }}
            </text>
            <rect
              class="clickable hover-pointer"
              role="button"
              [attr.x]="0"
              [attr.y]="0"
              [attr.height]="highlight.action.box.height"
              [attr.width]="highlight.action.box.width"
              appCtrlKey=""
              (click)="triggerAction($event, highlight)"
              (keydown.enter)="handleActionKeyEnterEvent($event, highlight)"
              (keydown.space)="handleActionKeyEnterEvent($event, highlight)"
              (blur)="onActionBlur(highlight)"
              (focus)="onActionFocused($event, highlight)"
              tabindex="0"
            ></rect>
          </g>
        </ng-container>
      </ng-template>
    </svg>
  </div>
</div>

<ng-template #highlightPopover let-box let-popover="popover">
  <div class="popover-container" appTrapFocus (document:keydown.escape)="onFindingPopoverEscape($event, box, popover)">
    <div *ngIf="box.title" class="d-flex popover-body pt-2 justify-content-between" id="popover-title">
      <a
        class="popover-link text-underline pb-0"
        [style.white-space]="'pre-line'"
        [routerLink]="box.link"
        [innerHtml]="box.title"
      >
      </a>
      <ng-container *ngTemplateOutlet="closeButton"></ng-container>
    </div>
    <span *ngIf="box.data.globalFinding" class="popover-body blue-notif-info" [style.display]="'inline-flex'">
      {{ 'global_finding' | translate }}
    </span>
    <div class="d-flex popover-body popover-content" id="popover-content">
      <div [style.white-space]="'pre-line'" [innerHtml]="box.description"></div>
      <ng-container *ngIf="!box.title">
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
      </ng-container>
    </div>
    <div class="button-tray d-flex pt-2 pb-2 m-0" *ngIf="showPopoverButtonTray">
      <button
        *ngIf="showPopoverEditButton"
        type="button"
        class="edit btn btn-link border-0 bg-transparent"
        [disabled]="!canEdit(box)"
        (click)="onPopoverEditButtonClick()"
      >
        <span class="me-2">{{ 'edit_finding_design_review' | translate }}</span>
        <svg aria-hidden="true" focusable="false" width="1rem" height="1rem" class="edit-icon">
          <use xlink:href="#pencil-square"></use>
        </svg>
      </button>
      <button
        *ngIf="showPopoverRemoveButton"
        type="button"
        class="remove btn btn-outline-primary align-self-center border-0 bg-transparent delete-button"
        (click)="onPopoverDeleteButtonClick()"
      >
        <span class="me-2">{{ 'remove_finding' | translate }}</span>
        <svg aria-hidden="true" focusable="false" width="1rem" height="1rem" class="delete-icon">
          <use xlink:href="#trash-solid"></use>
        </svg>
      </button>
    </div>
  </div>

  <ng-template #closeButton>
    <button
      type="button"
      class="btn-close-popover"
      [appTooltip]="'label_close' | translate"
      (click)="close(popover); focusOnFinding(box)"
      [appFocus]="focusSettingEventEmitter"
    >
      <app-icon aria-hidden="true" [icon]="Icons.CloseIcon"></app-icon>
      <span class="visuallyhidden">{{ 'close_popover' | translate }}</span>
    </button>
  </ng-template>
</ng-template>
