import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { startWith, map as mapRx, tap, distinctUntilChanged } from 'rxjs/operators';
import { chain, orderBy } from 'lodash';

import { TranslateService } from '../../../../translate/translate.service';
import { ITableRow, ITableColumn, SortEvent } from '../../../table/ngb-table/utilities/ngb-table.interface';
import {
  ISecurityGroupWithEntityNames,
  ISecurityGroupsResponse,
  ISecurityGroup,
} from '../../../../../../shared/interfaces/security-group.interface';
import {
  SecurityEntityLevel,
  SecurityGroupOrigin,
  $securityGroup,
  $securityEntity,
  SecurityGroupTemplateNames,
} from '../../../../../../shared/constants/security-group';
import { SecurityGroupService } from '../../../../services/security-group.service';
import { NgbTableUtilities } from '../../../table/ngb-table/utilities/ngb-table.utilities';
import { PermissionsTableComponent } from '../permissions-table.component';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { $sortingOrder } from '../../../../../../shared/constants/sort';
import { AngularUtility } from '../../../../utility/angular.utility';

interface IWorkspaceWithUserAndAdminGroups {
  workspaceName: string;
  workspaceAdminGroups: string[];
  workspaceUserGroups: string[];
}

interface ISearchForm {
  [SearchFormFields.workspaceSearch]: FormControl<string>;
}

export enum TableColumns {
  workspace = 'workspace',
  isWorkspaceAdmin = 'is-workspace-admin',
  isWorkspaceUser = 'is-workspace-user',
}

export enum SearchFormFields {
  workspaceSearch = 'workspace-search',
}

@Component({
  selector: 'app-workspace-permissions-table',
  templateUrl: './workspace-permissions-table.component.html',
  styleUrls: ['./workspace-permissions-table.component.scss'],
})
export class WorkspacePermissionsTableComponent extends PermissionsTableComponent implements OnInit {
  public searchForm: FormGroup<ISearchForm>;

  public SearchFormFields: typeof SearchFormFields = SearchFormFields;

  constructor(
    formBuilder: FormBuilder,
    private translateService: TranslateService,
    private securityGroupService: SecurityGroupService,
  ) {
    super(formBuilder);

    this.searchForm = new FormGroup<ISearchForm>({
      [SearchFormFields.workspaceSearch]: new FormControl<string>(''),
    });

    const columns: Record<string, ITableColumn> = {
      [TableColumns.workspace]: {
        translationKey: 'label_workspace',
        sortingEnabled: true,
        styles: {
          width: '15rem',
        },
      },
      [TableColumns.isWorkspaceAdmin]: {
        translationKey: 'workspace_admin',
        styles: {
          width: '18rem',
        },
      },
      [TableColumns.isWorkspaceUser]: {
        translationKey: 'workspace_user',
      },
    };

    this.tableConfig = {
      columns,
      emptyState: {
        iconId: 'magnifying-glass-2',
        title: this.translateService.instant('no_workspaces_found_with_that_name'),
        subtitle: this.translateService.instant('try_searching_different_name'),
      },
      caption: this.translateService.instant('table_caption_workspaces_permissions'),
    };
  }

  protected get workspaceSearch(): FormControl<string> {
    return this.searchForm.get(SearchFormFields.workspaceSearch) as FormControl<string>;
  }

  protected buildObservables(): void {
    super.buildObservables();

    const filterWorkspaceAdminGroups = (group: ISecurityGroup): boolean =>
      group[$securityGroup.name] === SecurityGroupTemplateNames.workspace_administrator;

    const filterWorkspaceUserGroups = (group: ISecurityGroup): boolean =>
      group[$securityGroup.name] === SecurityGroupTemplateNames.workspace_user;

    const mapGroupId = (group: ISecurityGroup): string => group[$securityGroup._id];

    const workspaceGroups$: Observable<ISecurityGroupsResponse<ISecurityGroupWithEntityNames>> = this.securityGroupService
      .groupsWithEntityNames({
        skip: 0,
        limit: 9999,
        includeStaffGroups: false,
        entityLevel: SecurityEntityLevel.workspace,
        origin: SecurityGroupOrigin.predefined,
        skipOrganizationAdmin: true,
      })
      .pipe(
        tap(({ groups }: ISecurityGroupsResponse<ISecurityGroupWithEntityNames>): void =>
          this.onPermissionsLoaded.emit([
            ...groups.filter(filterWorkspaceAdminGroups),
            ...groups.filter(filterWorkspaceUserGroups),
          ]),
        ),
      );

    const flattenedGroups$: Observable<IWorkspaceWithUserAndAdminGroups[]> = workspaceGroups$.pipe(
      mapRx(({ groups }: ISecurityGroupsResponse<ISecurityGroupWithEntityNames>): IWorkspaceWithUserAndAdminGroups[] => {
        const workspaceNames: Record<string, string> = chain(groups)
          .keyBy((group: ISecurityGroupWithEntityNames): string => group[$securityGroup.entities][0][$securityEntity.workspaceId])
          .mapValues((group: ISecurityGroupWithEntityNames): string => group.workspaceNames.join())
          .value();

        return chain(groups)
          .groupBy((group: ISecurityGroup): string => group[$securityGroup.entities][0][$securityEntity.workspaceId])
          .mapValues(
            (wsGroups: ISecurityGroupWithEntityNames[], workspaceId: string): IWorkspaceWithUserAndAdminGroups => ({
              workspaceName: workspaceNames[workspaceId],
              workspaceAdminGroups: wsGroups.filter(filterWorkspaceAdminGroups).map(mapGroupId),
              workspaceUserGroups: wsGroups.filter(filterWorkspaceUserGroups).map(mapGroupId),
            }),
          )
          .values()
          .value();
      }),
    );

    const workspaceSearch$: Observable<string> = this.workspaceSearch.valueChanges.pipe(
      startWith(this.workspaceSearch.value),
      distinctUntilChanged(),
      tap(() => this.page$.next(1)),
    );

    const searchedGroups$: Observable<IWorkspaceWithUserAndAdminGroups[]> = combineLatest([
      flattenedGroups$,
      workspaceSearch$,
    ]).pipe(
      mapRx(([groups, workspaceName]: [IWorkspaceWithUserAndAdminGroups[], string]): IWorkspaceWithUserAndAdminGroups[] => {
        if (SharedCommonUtility.isNullishOrEmpty(workspaceName?.trim())) {
          return groups;
        }

        return groups.filter((group: IWorkspaceWithUserAndAdminGroups): boolean =>
          group.workspaceName.toLowerCase().includes(workspaceName.trim().toLowerCase()),
        );
      }),
      AngularUtility.shareRef(),
    );

    this.total$ = searchedGroups$.pipe(mapRx((workspaces: IWorkspaceWithUserAndAdminGroups[]): number => workspaces.length));

    const orderedGroups$: Observable<IWorkspaceWithUserAndAdminGroups[]> = combineLatest([
      searchedGroups$,
      this.sortOption$,
    ]).pipe(
      mapRx(([groups, sortOption]: [IWorkspaceWithUserAndAdminGroups[], SortEvent]): IWorkspaceWithUserAndAdminGroups[] => {
        if (
          SharedCommonUtility.isNullish(sortOption) ||
          sortOption.column !== TableColumns.workspace ||
          sortOption.direction === $sortingOrder.all
        ) {
          return groups;
        }

        return orderBy(
          groups,
          (group: IWorkspaceWithUserAndAdminGroups) => group.workspaceName.toLowerCase(),
          sortOption.direction,
        );
      }),
    );

    const paginatedGroups$: Observable<IWorkspaceWithUserAndAdminGroups[]> = combineLatest([orderedGroups$, this.page$]).pipe(
      mapRx(([groups, page]: [IWorkspaceWithUserAndAdminGroups[], number]): IWorkspaceWithUserAndAdminGroups[] => {
        return groups.slice((page - 1) * this.pageSize, page * this.pageSize);
      }),
    );

    const userGroupIds$: Observable<string[]> = this.formGroupIds.valueChanges.pipe(startWith([...this.formGroupIds.value]));

    this.tableData$ = combineLatest([paginatedGroups$, userGroupIds$]).pipe(
      mapRx(([groups, userGroupIds]: [IWorkspaceWithUserAndAdminGroups[], string[]]): ITableRow[] => {
        const isUserInGroups = (groupIds: string[]): boolean =>
          groupIds.some((groupId: string): boolean => userGroupIds.includes(groupId));

        return groups.map(
          (workspace: IWorkspaceWithUserAndAdminGroups): ITableRow => ({
            data: {
              [TableColumns.workspace]: NgbTableUtilities.textCell({ text: workspace.workspaceName }),
              [TableColumns.isWorkspaceAdmin]: NgbTableUtilities.checkboxCell({
                checked: isUserInGroups(workspace.workspaceAdminGroups),
                styles: {
                  marginTop: '5px',
                },
                attributes: {
                  'aria-label': this.translateService.instant('adds_user_to_workspace_admin_group', workspace.workspaceName),
                },
                listeners: {
                  click: ($event: MouseEvent): void => {
                    $event.stopPropagation();
                    if (($event.target as HTMLInputElement).checked) {
                      this.updateFormGroupIds([...this.formGroupIds.value, ...workspace.workspaceAdminGroups]);
                    } else {
                      const filterUncheckedGroupIds = (groupId: string): boolean =>
                        !workspace.workspaceAdminGroups.includes(groupId);
                      this.updateFormGroupIds(this.formGroupIds.value.filter(filterUncheckedGroupIds));
                    }
                  },
                },
              }),
              [TableColumns.isWorkspaceUser]: NgbTableUtilities.checkboxCell({
                checked: isUserInGroups(workspace.workspaceUserGroups),
                styles: {
                  marginTop: '5px',
                },
                attributes: {
                  'aria-label': this.translateService.instant('adds_user_to_workspace_user_group', workspace.workspaceName),
                },
                listeners: {
                  click: ($event: MouseEvent): void => {
                    $event.stopPropagation();
                    if (($event.target as HTMLInputElement).checked) {
                      this.updateFormGroupIds([...this.formGroupIds.value, ...workspace.workspaceUserGroups]);
                    } else {
                      const filterUncheckedGroupIds = (groupId: string): boolean =>
                        !workspace.workspaceUserGroups.includes(groupId);
                      this.updateFormGroupIds(this.formGroupIds.value.filter(filterUncheckedGroupIds));
                    }
                  },
                },
              }),
            },
          }),
        );
      }),
    );
  }

  public ngOnInit(): void {
    this.buildObservables();
  }
}
