import {
  $task,
  $taskComment,
  $taskSupport,
  $taskWithProject,
  $taskWithTargetStates,
  TaskCommentType,
  TaskPriority,
  TaskStatus,
} from '../constants/task';
import { IUser } from './user.interface';
import { $auditFinding, AuditFindingQAStatus } from '../constants/audit-finding';
import { $auditedDocument } from '../constants/audited-document';
import { IFlawEx, IFlaw } from './flaw.interface';
import { IUploadWithThumbnail } from './uploads.interface';
import { ITimestamps } from './mongoose-serializer.interface';
import { IProjectWithConnection } from './project.interface';
import { $user } from '../constants/user';
import { $issueIntegrationType } from '../constants/issue-tracking';
import { IDesignReviewFinding } from './design-review.interface';
import { $scan } from '../constants/scan';
import { $digitalProperty } from '../constants/digital-properties';
import { IDigitalPropertyType } from './digital-property.interface';

export enum IssueTrackingSyncStatus {
  none = 'none',
  pendingCreation = 'pendingCreation',
  linking = 'linking',
  created = 'created',
  error = 'error',
}

export interface ITask {
  [$task._id]?: string;
  [$task.name]: string;
  [$task.description]?: string;
  [$task.priority]: TaskPriority;
  [$task.attachments]?: string[];
  [$task.attachmentFileOriginNames]?: string[];
  [$task.auditFindingIds]?: string[];
  [$task.designReviewFindingIds]?: string[];
  [$task.status]: TaskStatus;
  [$task.projectId]: string;
  [$task.creatorId]: string;
  [$task.taskProjectId]: string;
  [$task.taskIssueIntegration]?: ITaskIssueIntegration;
  [$task.issueIntegrationSyncStatus]?: IssueTrackingSyncStatus;
  [$task.assigneeId]?: string;
  [$task.assignee]?: IUser;
  [$task.asanaWebhookSecret]?: string;
  /**
   * The user who initiated issue integration for this task
   */
  [$task.integrationRequestedBy]?: string;
}

interface IAttachments {
  [$task.attachments]: IUploadWithThumbnail[];
}

interface IFindingsWithDocument {
  [$task.auditFindings]: ITaskLinkedManualFindings[];
  [$task.designReviewFindings]: IDesignReviewFinding[];
  [$task.linkedFlaws]?: IFlawEx[];

  hasFindingsPermissions?: boolean;
}

export type ITaskClient = ITask & ITimestamps;

export type ITaskWithLinkedFindingsAndAttachments = ITask & IFindingsWithDocument & IAttachments;

export interface ITaskLinkedManualFindings {
  [$auditFinding.elementReference]: string;
  [$auditFinding.element]: string;
  [$auditFinding.actualResult]: string;
  [$auditFinding.documentId]: {
    [$auditedDocument.title]: string;
    [$auditedDocument.reference]: string;
    // populate
    [$auditedDocument.scanId]?: { [$scan.digitalProperty]: string };
  };

  // manual audit
  [$auditFinding.summary]?: string;
  [$auditFinding.screen]?: string;
  [$auditFinding.issueFrequency]?: string;
  [$auditFinding.stepsToReproduce]?: string;
  [$auditFinding.QAStatus]?: AuditFindingQAStatus;
}

export interface ITaskFindingReference {
  scanId: string;
  issueId: string;
  dpKey: string;
  dpId: string;
}

export interface ITaskWithFindingReference extends ITask {
  [$task.auditFindings]: ITaskFindingReference[];
}

export interface ITaskWithTargetStates extends ITask, ITimestamps {
  [$taskWithTargetStates.targetStates]: TaskStatus[];
}

export type ITaskWithTargetStatesAndFindings = ITaskWithTargetStates &
  ITaskWithFindingReference & { [$task.linkedFlaws]?: IFlaw[] };

export interface ITaskWithProject extends ITask, ITimestamps {
  [$taskWithProject.project]: IProjectWithConnection;
}

export interface IPartialDigitalProperty {
  [$digitalProperty._id]?: string;
  [$digitalProperty.name]: string;
  [$digitalProperty.type]?: IDigitalPropertyType;
}

export interface ITaskWithProjectAndDigitalProperty extends ITaskWithProject {
  digitalProperty?: IPartialDigitalProperty;
}

export interface ITasksSummaryByDp {
  dpName: string;
  dpType: string;
  tasksRequested: number;
}

export interface IUserTask extends ITaskWithProject {
  [$taskWithTargetStates.targetStates]: TaskStatus[];
}

export interface ITotalUserTasks {
  totalCount: number;
  externalStatuses: string[];
}

export interface IGetUserTasks extends ITotalUserTasks {
  tasks: IUserTask[];
}

export interface IBaseTaskComment {
  [$taskComment._id]?: string;
  [$taskComment.taskId]: string;
  [$taskComment.author]: IUser;
  [$taskComment.createdAt]?: Date | string;
  [$taskComment.updatedAt]?: Date | string;
}

export interface ITaskUserCommentSpecific {
  [$taskComment.content]: string;
  [$taskComment.issueIntegrationId]?: string;
  [$taskComment.externalAuthorName]?: string;
  [$taskComment.externalDelete]?: boolean;
  [$taskComment.integrationType]?: $issueIntegrationType;
}

export interface ITaskUserComment extends IBaseTaskComment, ITaskUserCommentSpecific {}

export interface ITaskAssignedCommentSpecific {
  [$taskComment.newAssignee]: Pick<IUser, $user._id | $user.displayName>;
  [$taskComment.previousAssignee]?: Pick<IUser, $user._id | $user.displayName>;
}

export interface ITaskAssignedComment extends IBaseTaskComment, ITaskAssignedCommentSpecific {
  [$taskComment.commentType]: TaskCommentType.taskAssigned;
}

export interface ITaskUnassignedCommentSpecific {
  [$taskComment.previousAssignee]: Pick<IUser, $user._id | $user.displayName>;
}

export interface ITaskUnassignedComment extends IBaseTaskComment, ITaskUnassignedCommentSpecific {
  [$taskComment.commentType]: TaskCommentType.taskUnassigned;
}

export interface ITaskErrorCommentSpecific {
  [$taskComment.error]: string;
}

export interface ITaskDeletedCommentSpecific {
  [$taskComment.issueIntegrationKey]: string;
}

export interface ITaskErrorComment extends IBaseTaskComment, ITaskErrorCommentSpecific {
  [$taskComment.commentType]: TaskCommentType.error;
}

export interface ITaskDeletedComment extends IBaseTaskComment, ITaskDeletedCommentSpecific {
  [$taskComment.commentType]: TaskCommentType.taskDeleted;
}

export type ITaskComment =
  | ITaskUserComment
  | ITaskAssignedComment
  | ITaskUnassignedComment
  | ITaskErrorComment
  | ITaskDeletedComment;

export enum TaskSearchFields {
  name = 'name',
  progress = 'progress',
  externalStatus = 'externalStatus',
  taskIds = 'taskIds',
  onlyAssigned = 'onlyAssigned',
  taskIssueIntegrated = 'taskIssueIntegrated',
  populateDigitalProperty = 'populateDigitalProperty',
}

export interface ITaskIssueIntegration {
  [$task.issueIntegrationKey]: string;
  [$task.issueIntegrationUrl]: string;
  [$task.issueIntegrationStatus]: string;
  [$task.issueIntegrationSummary]?: string;
  [$task.issueIntegrationAssignee]?: string;
  [$task.issueIntegrationPriority]?: string;
  [$task.issueIntegrationType]?: string;
  [$task.lastSynchronized]: Date;
  [$task.unsuccessfullyLinkedIssueIntegrationKey]?: string;
}

export interface ITaskSupportRequestServer {
  [$taskSupport._id]?: string;
  [$taskSupport.attachments]: string[];
  [$taskSupport.digitalPropertyId]: string;
  [$taskSupport.message]: string;
  [$taskSupport.taskId]: string;
  [$taskSupport.tenantId]: string;
  [$taskSupport.userId]: string;
  [$taskSupport.userEmail]: string;
  [$taskSupport.workspaceId]: string;
}

export type IFailedTask = Pick<ITask, $task._id | $task.taskProjectId>;
