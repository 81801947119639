import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITableColumnSelectorColumnGroup } from '../../interfaces/table_column_selector.interface';

@Component({
  selector: 'app-table-column-selector',
  templateUrl: './table-column-selector.component.html',
  styleUrls: ['./table-column-selector.component.scss'],
})
export class TableColumnSelectorComponent {
  public columnSelectorOpened: boolean;
  @Input()
  public selectedColumns: string[] = [];
  @Output()
  public selectedColumnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input()
  public columnsDefinition: ITableColumnSelectorColumnGroup[] = [];

  public toggleColumnSelector(): void {
    this.columnSelectorOpened = !this.columnSelectorOpened;
  }

  public onCancel(): void {
    this.columnSelectorOpened = false;
  }

  public onSelectedColumnsChange(columns: string[]): void {
    this.columnSelectorOpened = false;
    this.selectedColumns = columns;
    this.selectedColumnsChange.emit(columns);
  }
}
