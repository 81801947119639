import { TestGuideCodeName } from './test-guide';
import { RuleLibraryMediaType } from './rule-library';
import { standardNameAndLevel } from './scanning';
import { AuditStandards } from './audit-standard';

export enum EvaluationMediums {
  web = RuleLibraryMediaType.web,
  android = RuleLibraryMediaType.android,
  iOS = RuleLibraryMediaType.iOS,
  hardware = RuleLibraryMediaType.hardware,
}

export const EVALUATION_MEDIUMS: Readonly<EvaluationMediums[]> = Object.freeze(
  Object.values(EvaluationMediums) as EvaluationMediums[],
);

export const EVALUATION_MEDIUM_TO_TEST_GUIDE: Readonly<Record<EvaluationMediums, TestGuideCodeName>> = {
  [EvaluationMediums.web]: TestGuideCodeName.WCAG22,
  [EvaluationMediums.android]: TestGuideCodeName.Android22_LA,
  [EvaluationMediums.iOS]: TestGuideCodeName.IOS22_LA,
  [EvaluationMediums.hardware]: TestGuideCodeName.Hardware_Section508,
};

export enum EvaluationStandards {
  WCAG_v20_A = standardNameAndLevel.WCAG_v20_A,
  WCAG_v20_AA = standardNameAndLevel.WCAG_v20_AA,
  WCAG_v20_AAA = standardNameAndLevel.WCAG_v20_AAA,
  WCAG_v21_A = standardNameAndLevel.WCAG_v21_A,
  WCAG_v21_AA = standardNameAndLevel.WCAG_v21_AA,
  WCAG_v21_AAA = standardNameAndLevel.WCAG_v21_AAA,
  WCAG_v22_A = standardNameAndLevel.WCAG_v22_A,
  WCAG_v22_AA = standardNameAndLevel.WCAG_v22_AA,
  WCAG_v22_AAA = standardNameAndLevel.WCAG_v22_AAA,
  en301549 = AuditStandards.en301549,
  section508 = AuditStandards.section508,
}

export const EVALUATION_STANDARDS: Readonly<EvaluationStandards[]> = Object.freeze(
  Object.values(EvaluationStandards) as EvaluationStandards[],
);
