<div class="item-wrapper">
  <div class="item-header py-2">
    <ng-template [ngTemplateOutlet]="collapseButton"></ng-template>
    <span *ngIf="historyItem[$ruleAuditHistory.type] !== 'rule'" class="fw-bold">
      {{
        'view_rule_history_item_title_' + historyItem[$ruleAuditHistory.type] + '_' + historyItem[$ruleAuditHistory.action]
          | translate
      }}
    </span>
    <span *ngIf="historyItem[$ruleAuditHistory.type] === 'rule'" class="fw-bold text-black">
      <span>{{ 'evaluation_rules_rule_status' | translate }}</span>
      <span class="ms-2 px-1 py-1 action-label" [ngClass]="{ 'action-on': historyItem[$ruleAuditHistory.action] === 'on' }">
        {{ (historyItem[$ruleAuditHistory.action] === 'on' ? 'label_on' : 'label_off') | translate }}
      </span>
    </span>
  </div>
  <div [class.collapsed]="expanded === false">
    <div class="item-description">
      <span>{{ getUserAndTimestamp() }}</span>
    </div>
    <div
      class="item-comment mt-2"
      *ngIf="historyItem[$ruleAuditHistory.reason]"
      [innerHTML]="historyItem[$ruleAuditHistory.reason] | linkify"
    ></div>
  </div>
</div>

<ng-template #collapseButton>
  <button
    type="button"
    class="btn btn-sm p-0 btn-link btn-flat collapse-button"
    [attr.aria-expanded]="expanded"
    (click)="expanded = !expanded"
  >
    <ng-container *ngIf="expanded">
      <svg class="align-items-start"><use xlink:href="#chevron-bottom-thin"></use></svg>
    </ng-container>
    <ng-container *ngIf="expanded === false">
      <svg class="align-items-start"><use xlink:href="#chevron-right-thin"></use></svg>
    </ng-container>
  </button>
</ng-template>
