export enum $analyticsResultItem {
  scanDate = 'scanDate',
  scanHealth = 'scanHealth',
  scanStatus = 'scanStatus',
  scanId = 'scanId',
  toolVersion = 'toolVersion',
  toolName = 'toolName',
  numOfIssues = 'numOfIssues',
  numOfPages = 'numOfPages',
  severityCount = 'severityCount',
  includeStandard = 'includeStandard',
  successCriteriaWithFindings = 'successCriteriaWithFindings',
  totalSuccessCriteria = 'totalSuccessCriteria',
  componentsWithFindings = 'componentsWithFindings',
  ruleStatusCount = 'ruleStatusCount',
}

export enum $analyticsByRuleResultItem {
  ruleSummary = 'ruleSummary',
  ruleId = 'ruleId',
  componentsAffected = 'componentsAffected',
  pagesAffected = 'pagesAffected',
  numFindings = 'numFindings',
  changeInFindings = 'changeInFindings',
  severity = 'severity',
  successCriteriaIdentifier = 'successCriteriaIdentifier',
  title = 'title',
}

export enum $analyticsByRuleResult {
  toolName = 'toolName',
  latestScanId = 'latestScanId',
  items = 'items',
}

export enum $pageAffectedByRuleAnalytics {
  url = 'url',
  title = 'title',
  numFindings = 'numFindings',
  changeInFindings = 'changeInFindings',
  documentId = 'documentId',
}
