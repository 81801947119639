import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input } from '@angular/core';
import { $digitalPropertyMonitoringAlerts } from '../../../../shared/constants/digital-properties';

@Component({
  selector: 'app-monitoring-alert-icon',
  templateUrl: './monitoring-alert-icon.component.html',
  styleUrls: ['./monitoring-alert-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringAlertIconComponent {
  @Input()
  public alert: string[];
  public displayTooltip: boolean;
  public $alerts: typeof $digitalPropertyMonitoringAlerts;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.$alerts = $digitalPropertyMonitoringAlerts;
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent): void {
    if (this.displayTooltip === false) {
      return;
    }

    if (this.elementRef.nativeElement.contains(event.target as Node) === false) {
      this.displayTooltip = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  public showAlertTooltip(): void {
    this.displayTooltip = true;
    this.changeDetectorRef.detectChanges();
  }

  public get isAlertArray(): boolean {
    return Array.isArray(this.alert);
  }

  public get alerts(): string[] {
    if (Array.isArray(this.alert)) {
      return this.alert as unknown as string[];
    }
    return [];
  }
}
