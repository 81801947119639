<div class="col-12 col-md-auto d-flex align-items-center mb-3">
  <div class="btn-group" role="radiogroup" [attr.aria-label]="'issues_filter_table_options' | translate">
    <input
      type="radio"
      class="btn-check"
      id="failed-btn"
      [(ngModel)]="status"
      [value]="issueStatus.failed"
      (ngModelChange)="onFilterSelect($event)"
      
    />
    <label class="btn btn-failed me-3" for="failed-btn">
      {{ 'issues_filter_status_failed' | translate }}
      <span class="badge">{{ failedRulesCount }}</span>
    </label>

    <input
      type="radio"
      class="btn-check"
      id="review-btn"
      [(ngModel)]="status"
      [value]="issueStatus.review"
      (ngModelChange)="onFilterSelect($event)"
      
    />
    <label class="btn btn-review me-3" for="review-btn">
      {{ 'issues_filter_status_flagged_for_review' | translate }}
      <span class="badge">{{ reviewRulesCount }}</span>
    </label>

    <div *ngIf="!hidePassedRules">
      <input
        type="radio"
        class="btn-check"
        id="passed-btn"
        [(ngModel)]="status"
        [value]="issueStatus.passed"
        (ngModelChange)="onFilterSelect($event)"
        
      />
      <label class="btn btn-passed me-3" for="passed-btn">
        {{ 'issues_filter_status_passed' | translate }}
        <span class="badge">{{ passedRulesCount }}</span>
      </label>
    </div>
  </div>
</div>
