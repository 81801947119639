import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { $scanViewport, ScanViewportType } from '../../../../shared/constants/scan-viewport';
import { TranslateService } from '../../translate/translate.service';
import { IScanViewport } from '../../../../shared/interfaces/scan-viewport.interface';
import { ScanViewport } from '../../../../shared/utils/scan-viewport';

const DEFAULT_HEIGHT: number = 600;
const MIN_VALUE: number = 100;
const MAX_HEIGHT: number = 5000;
const DEFAULT_WIDTH: number = 800;
const MAX_WIDTH: number = 1920;

@Component({
  selector: 'app-viewport-select',
  templateUrl: './viewport-select.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ViewportSelectComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewportSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private onChange: Function = () => {};
  private onTouched: Function = () => {};
  private subscription: Subscription;

  public disabled: boolean;
  public viewportType: typeof ScanViewportType;
  public $scanViewport: typeof $scanViewport;
  public form: UntypedFormGroup;
  public viewports: IScanViewport[];
  @Input()
  public formValidationRequest$: Observable<any>;

  constructor(
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private builder: UntypedFormBuilder,
  ) {
    this.viewportType = ScanViewportType;
    this.$scanViewport = $scanViewport;
    this.subscription = new Subscription();

    this.form = this.builder.group({
      [$scanViewport.viewportType]: this.builder.control(''),
      [$scanViewport.width]: this.builder.control(null),
      [$scanViewport.height]: this.builder.control(null),
    });

    this.viewports = [
      ScanViewport.getMobileViewport(),
      ScanViewport.getTabletViewport(),
      ScanViewport.getDesktopViewport(),
      new ScanViewport(ScanViewportType.custom, DEFAULT_HEIGHT, DEFAULT_WIDTH),
    ];
  }

  // eslint-disable-next-line consistent-return
  private getViewportFromType(value: IScanViewport): IScanViewport {
    switch (value[$scanViewport.viewportType]) {
      case ScanViewportType.desktop:
        return ScanViewport.getDesktopViewport();
      case ScanViewportType.tablet:
        return ScanViewport.getTabletViewport();
      case ScanViewportType.mobile:
        return ScanViewport.getMobileViewport();
      case ScanViewportType.custom:
        return new ScanViewport(ScanViewportType.custom, value[$scanViewport.height], value[$scanViewport.width]);
      // no default
    }
  }

  private setupCustomControls(value: ScanViewportType): void {
    if (value === ScanViewportType.custom) {
      const heightValidators: ValidatorFn[] = [Validators.required, Validators.min(MIN_VALUE), Validators.max(MAX_HEIGHT)];
      const widthValidators: ValidatorFn[] = [Validators.required, Validators.min(MIN_VALUE), Validators.max(MAX_WIDTH)];

      if (this.form.contains($scanViewport.height)) {
        this.form.get($scanViewport.height).setValue(DEFAULT_HEIGHT);
      } else {
        this.form.registerControl($scanViewport.height, this.builder.control(DEFAULT_HEIGHT, heightValidators));
      }

      if (this.form.contains($scanViewport.width)) {
        this.form.get($scanViewport.width).setValue(DEFAULT_WIDTH);
      } else {
        this.form.registerControl($scanViewport.width, this.builder.control(DEFAULT_WIDTH, widthValidators));
      }

      this.form.updateValueAndValidity();
    } else {
      this.form.removeControl($scanViewport.height);
      this.form.removeControl($scanViewport.width);
    }

    this.changeDetectorRef.detectChanges();
  }

  private onViewportValueChange(value: IScanViewport): void {
    const type: IScanViewport = this.getViewportFromType(value);

    this.onTouched();
    this.onChange(type);
    this.changeDetectorRef.detectChanges();
  }

  public writeValue(obj: IScanViewport): void {
    this.form.patchValue(obj, { emitEvent: false });
    this.changeDetectorRef.detectChanges();
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    Object.values(this.form.controls).forEach((value: AbstractControl): void =>
      this.disabled ? value.disable({ emitEvent: false }) : value.enable({ emitEvent: false }),
    );
  }

  public getViewportLabel(): (scanViewPort: IScanViewport) => string {
    return (scanViewPort: IScanViewport): string => {
      const viewportType: string = this.translateService.instant('scan_viewport_' + scanViewPort[$scanViewport.viewportType]);

      if (scanViewPort[$scanViewport.viewportType] === ScanViewportType.custom) {
        return viewportType;
      }

      return this.translateService.instant('scan_viewport_value', [
        viewportType,
        scanViewPort.width.toString(),
        scanViewPort.height.toString(),
      ]);
    };
  }

  public ngOnInit(): void {
    this.subscription.add(this.form.get($scanViewport.viewportType).valueChanges.subscribe(this.setupCustomControls.bind(this)));
    this.subscription.add(this.form.valueChanges.subscribe(this.onViewportValueChange.bind(this)));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
