import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import ClipboardJS from 'clipboard';

import { ClipboardService } from '../../services/clipboard.service';
import { TranslateService } from '../../translate/translate.service';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent implements OnInit, OnDestroy, AfterViewInit {
  /*
   * The ID of the HTMLElement you are copying the text from
   * Do not set both: inputId and textValue
   */
  @Input() public inputId: string;
  /*
   * Raw text to copy
   * Do not set both: inputId and textValue
   */
  @Input() public textValue: string;
  @Input() public buttonText: string;
  @Input() public tooltip: boolean;
  @Input() public clipboardOptions: ClipboardJS.Options;

  constructor(
    private clipboardService: ClipboardService,
    public translateService: TranslateService,
  ) {
    this.tooltip = true;
  }

  public ngOnInit(): void {
    if (SharedCommonUtility.isNullish(this.buttonText)) {
      this.buttonText = this.translateService.instant('copy');
    }
  }

  public ngAfterViewInit(): void {
    try {
      this.clipboardService.init(this.clipboardOptions, this.inputId);
    } catch (e) {
      console.error('Cannot init the clipboard component', e);
    }
  }

  public ngOnDestroy(): void {
    try {
      this.clipboardService.destroyClipboardService(this.inputId);
    } catch (e) {
      console.error('Cannot destroy the clipboard component', e);
    }
  }
}
