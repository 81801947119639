<button
  type="button"
  class="btn btn-outline-primary share-btn d-flex align-items-center fw-bold share-btn"
  title="{{ 'action_share' | translate }}"
  (click)="openShareIssueModal()"
>
  <svg width="17" height="17" viewBox="0 0 17 17" class="share-icon">
    <use xlink:href="#paper-plane"></use>
  </svg>
  <span class="margin-left-sm">{{ 'action_share' | translate }}</span>
</button>
