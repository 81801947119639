import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin, combineLatest } from 'rxjs';
import { filter, switchMap, map as mapRx, take, startWith, tap } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { map } from 'lodash';

import { ITableRow, ITableColumn } from '../../../table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../../../../translate/translate.service';
import { TenantService } from '../../../../services/tenant.service';
import { UserService } from '../../../../services/user.service';
import { IUserServerResponse } from '../../../../../../shared/interfaces/user.interface';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { ITenantInfoWithPackageInfo } from '../../../../../../shared/interfaces/tenant.interface';
import { $user } from '../../../../../../shared/constants/user';
import { SecurityGroupService } from '../../../../services/security-group.service';
import { SecurityEntityLevel, SecurityGroupOrigin, $securityGroup } from '../../../../../../shared/constants/security-group';
import {
  ISecurityGroupsResponse,
  ISecurityGroupWithEntityNames,
} from '../../../../../../shared/interfaces/security-group.interface';
import { NgbTableUtilities } from '../../../table/ngb-table/utilities/ngb-table.utilities';
import { PermissionsTableComponent } from '../permissions-table.component';

interface ITenantInfoAndOrgAdminGroups {
  tenantInfo: ITenantInfoWithPackageInfo;
  orgAdminGroups: ISecurityGroupWithEntityNames[];
}

enum TableColumns {
  organization = 'organization',
  package = 'package',
  isOrganizationAdmin = 'is-organization-admin',
}

@Component({
  selector: 'app-organization-permissions-table',
  templateUrl: './organization-permissions-table.component.html',
  styleUrls: ['./organization-permissions-table.component.scss'],
})
export class OrganizationPermissionsTableComponent extends PermissionsTableComponent implements OnInit {
  constructor(
    formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UserService,
    private tenantService: TenantService,
    private securityGroupService: SecurityGroupService,
  ) {
    super(formBuilder);

    const columns: Record<string, ITableColumn> = {
      [TableColumns.organization]: {
        translationKey: 'organization',
        styles: {
          width: '15rem',
        },
      },
      [TableColumns.package]: {
        translationKey: 'label_package',
        styles: {
          width: '10rem',
        },
      },
      [TableColumns.isOrganizationAdmin]: {
        translationKey: 'organization_admin',
      },
    };

    this.tableConfig = {
      columns,
      caption: this.translateService.instant('table_caption_organization_permissions'),
    };
  }

  protected buildObservables(): void {
    super.buildObservables();

    const tenantInfo$: Observable<ITenantInfoWithPackageInfo> = this.userService.userDataChanged$.pipe(
      filter((user: IUserServerResponse): boolean => SharedCommonUtility.notNullish(user)),
      take(1),
      switchMap(
        (user: IUserServerResponse): Observable<ITenantInfoWithPackageInfo> =>
          this.tenantService.getTenantFromTenantedScope(user[$user.currentTenantId]),
      ),
    );

    const orgAdminGroups$: Observable<ISecurityGroupWithEntityNames[]> = this.securityGroupService
      .groupsWithEntityNames({
        skip: 0,
        limit: 9999,
        includeStaffGroups: false,
        entityLevel: SecurityEntityLevel.tenant,
        origin: SecurityGroupOrigin.predefined,
        skipOrganizationAdmin: false,
      })
      .pipe(
        mapRx(
          (response: ISecurityGroupsResponse<ISecurityGroupWithEntityNames>): ISecurityGroupWithEntityNames[] => response.groups,
        ),
        tap((orgAdminGroups: ISecurityGroupWithEntityNames[]): void => {
          this.onPermissionsLoaded.emit(orgAdminGroups);
        }),
      );

    const tenantInfoAndOrgAdminGroups$: Observable<ITenantInfoAndOrgAdminGroups> = forkJoin([tenantInfo$, orgAdminGroups$]).pipe(
      mapRx(
        ([tenantInfo, orgAdminGroups]: [
          ITenantInfoWithPackageInfo,
          ISecurityGroupWithEntityNames[],
        ]): ITenantInfoAndOrgAdminGroups => ({
          tenantInfo,
          orgAdminGroups,
        }),
      ),
    );

    const formGroupsIds$: Observable<string[]> = this.formGroupIds.valueChanges.pipe(startWith([...this.formGroupIds.value]));

    this.tableData$ = combineLatest([tenantInfoAndOrgAdminGroups$, formGroupsIds$]).pipe(
      mapRx(([{ tenantInfo, orgAdminGroups }, userGroupIds]: [ITenantInfoAndOrgAdminGroups, string[]]): ITableRow[] => {
        const orgAdminGroupIds: string[] = map(orgAdminGroups, $securityGroup._id);
        const isUserInOrgAdminGroup = (): boolean =>
          orgAdminGroupIds.length > 0 && orgAdminGroupIds.every(userGroupIds.includes.bind(userGroupIds));

        return [
          {
            data: {
              [TableColumns.organization]: NgbTableUtilities.textCell({ text: tenantInfo.name }),
              [TableColumns.package]: NgbTableUtilities.textCell({ text: tenantInfo.packageName }),
              [TableColumns.isOrganizationAdmin]: NgbTableUtilities.checkboxCell({
                checked: isUserInOrgAdminGroup(),
                disabled: orgAdminGroupIds.length === 0,
                styles: {
                  marginTop: '5px',
                },
                attributes: {
                  'aria-label': this.translateService.instant('adds_user_to_organization_admin_group', tenantInfo.name),
                },
                listeners: {
                  click: ($event: MouseEvent): void => {
                    $event.stopPropagation();
                    if (($event.target as HTMLInputElement).checked) {
                      this.updateFormGroupIds([...this.formGroupIds.value, ...orgAdminGroupIds]);
                    } else {
                      const filterOrgAdminGroups = (groupId: string): boolean => !orgAdminGroupIds.includes(groupId);
                      this.updateFormGroupIds(this.formGroupIds.value.filter(filterOrgAdminGroups));
                    }
                  },
                },
              }),
            },
          },
        ];
      }),
    );
  }

  public ngOnInit(): void {
    this.buildObservables();
  }
}
