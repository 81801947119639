import { Component, OnInit } from '@angular/core';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-attribution',
  templateUrl: './attribution.component.html',
})
export class AttributionComponent implements OnInit {
  public pageTitle: string;

  constructor(private routingService: RoutingService) {
    this.pageTitle = '';
  }

  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
  }
}
