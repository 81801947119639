<ds-modal class="modal-container" [modal]="modal" [title]="cardTitle | translate" #modalRef>
  <form [formGroup]="form" class="d-flex flex-column">
    <ds-tabs [(activeId)]="activeTabId" [tablistLabelledBy]="modalRef.titleId">
      <ds-tab [heading]="'task_details_label' | translate" [label]="'task_details_label' | translate" [headingLevel]="3">
        <div class="mb-3">{{ 'form-field-marked-as-required' | translate }}</div>
        <ng-container *ngIf="needToSelectProjectFromWorkspace">
          <div class="d-flex gap-2 mb-4">
            <div class="flex-grow-1" *ngIf="availableProjects$ | async as availableProjects">
              <ds-input
                [label]="'available_projects' | translate"
                [required]="true"
                [instructions]="'available_projects_instructions' | translate"
                [errorMessage]="projectInputError(availableProjects)"
                [errorState]="formFieldErrors[$task.projectId]?.length > 0"
              >
                <select
                  class="w-100"
                  ds-input-field
                  
                  id="project-selector"
                  [formControlName]="$task.projectId"
                  (ngModelChange)="onProjectChange($event)"
                >
                  <option [ngValue]="null" disabled>{{ projectPlaceholder(availableProjects) }}</option>
                  <option *ngFor="let project of availableProjects" [ngValue]="project">
                    {{ project.name | ellipsis: 30 }}
                  </option>
                </select>
              </ds-input>

              <div id="issueTracking" *ngIf="userCanPushIssueIntegration$ | async">
                <div class="mt-2">
                  <ds-checkbox
                    [label]="issueIntegrationProjectLabel$ | async"
                    [disabled]="(userCanPushIssueIntegration$ | async) === false"
                    [isSelected]="pushTaskToIssueTracking"
                    (valueChange)="onPushTaskToIssueTrackingChange($event)"
                  ></ds-checkbox>
                </div>

                <div
                  *ngIf="
                    pushTaskToIssueTracking && issueTrackingIssueTypes$ | async as issueTrackingIssueTypes;
                    else getIssueTypesError
                  "
                >
                  <ds-input
                    [label]="'push_task_as' | translate"
                    [required]="pushTaskToIssueTracking"
                    [errorMessage]="formFieldErrors[$task.issueIntegrationType]"
                    [errorState]="formFieldErrors[$task.issueIntegrationType]?.length > 0"
                    class="mb-4"
                  >
                    <select
                      class="w-100"
                      ds-input-field
                      id="jira-ticket-type-selector"
                      [formControlName]="$task.issueIntegrationType"
                    >
                      <option [ngValue]="null" disabled selected>{{ selectIssueTypeLabel$ | async }}</option>
                      <option
                        *ngFor="let issueTrackingIssueType of issueTrackingIssueTypes"
                        [ngValue]="issueTrackingIssueType.id"
                      >
                        {{ issueTrackingIssueType.name }}
                      </option>
                    </select>
                  </ds-input>
                </div>
                <ng-template #getIssueTypesError>
                  <div *ngIf="pushTaskToIssueTracking && loadingError$ | async" class="alert alert-danger" role="alert">
                    {{ 'error_loading_jira_project_issue_types' | translate }}
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="mt-5">
              <button
                ds-button
                class="create-project text-nowrap"
                type="button"
                (click)="createNewProject()"
                [variant]="DsButtonVariants.secondary"
              >
                {{ 'create_project' | translate }}
              </button>
            </div>
          </div>
          <app-banner #createProjectSuccessBanner [message]="projectCreatedSuccessMessage$ | async"></app-banner>
        </ng-container>

        <div class="mt-2">
          <ds-input
            [label]="'label_task_form_name' | translate"
            [errorMessage]="formFieldErrors[$task.name]"
            [errorState]="formFieldErrors[$task.name]?.length > 0"
            [required]="true"
            class="mb-4"
          >
            <input ds-input-field [formControlName]="$task.name" />
          </ds-input>
        </div>

        <ds-input
          [label]="'label_task_form_status' | translate"
          [errorMessage]="formFieldErrors[$task.status]"
          [errorState]="formFieldErrors[$task.status]?.length > 0"
          [required]="false"
          class="mb-4"
          [state]="DsInputStates.inactive"
        >
          <input ds-input-field [id]="$task.status" [formControlName]="$task.status" />
        </ds-input>

        <ds-input [label]="'label_task_priority' | translate" [required]="false" class="mb-4">
          <select [id]="$task.priority" class="w-100" ds-input-field [formControlName]="$task.priority">
            <option *ngFor="let option of taskPriorityOptions" [value]="option.priorityValue">
              {{ option.name | translate }}
            </option>
          </select>
        </ds-input>

        <ng-container *ngIf="selectedIssues && selectedIssues.length > 0">
          <label class="label mb-4 text-nowrap linked-findings-label" id="linkedFindings">
            {{ 'linked_findings' | translate }}
          </label>
          <ng-container *ngIf="!canUnlinkTask">
            <app-alert
              [type]="alertType.danger"
              [message]="unlinkTaskErrorMessage"
              (onClose)="canUnlinkTask = true"
              class="mb-2 unlink-task-error-message"
            ></app-alert>
          </ng-container>
          <app-ngb-table
            [config]="linkedFindingsTableConfig"
            [data]="linkedFindingsTableRows"
            class="linked-findings-table"
            
          >
          </app-ngb-table>
        </ng-container>

        <div class="attachments-container mt-4">
          <app-file-drag-and-drop
            [form]="form"
            [formValidationRequest$]="formValidationRequest"
            (fileChange)="onFileUpload($event)"
            (existingUploadsChange)="onExistingAttachmentsChange($event)"
            [(existingUploads)]="attachments"
            [context]="uploaderContext"
            [removableFiles]="true"
            [disabled]="disableUploadButtons$ | async"
          >
            <div *ngIf="showAzureUploadWarning$ | async" class="uploader-warning">
              {{ 'azure_filesize_warning' | translate: azureFileSizeLimit }}
            </div>
          </app-file-drag-and-drop>
        </div>
      </ds-tab>
      <ds-tab
        [heading]="'table_header_rule_description' | translate"
        [label]="'table_header_rule_description' | translate"
        [headingLevel]="3"
      >
        <div class="margin-bottom">
          <app-markdown-editor [formControlName]="$task.description"> </app-markdown-editor>
          <app-form-field-error
            label="label_description"
            [formValidationRequest$]="formValidationRequest$"
            [field]="form.get($task.description)"
            [name]="$task.description"
          ></app-form-field-error>
        </div>
      </ds-tab>
    </ds-tabs>
  </form>
  <ng-template ds-modal-slide-out-footer>
    <ds-button-group
      [primaryAction]="{ label: submitButton | translate }"
      [secondAction]="{ label: 'form_cancel' | translate, variant: DsButtonVariants.secondary }"
      (primaryActionClicked)="submitForm()"
      (secondActionClicked)="modalService.closeAllModals()"
    >
    </ds-button-group>
  </ng-template>
</ds-modal>
