import { Component, ComponentRef, EventEmitter, Input, Output } from '@angular/core';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';

import { FileExtension } from '../../../../shared/constants/file-extension';
import { ModalService } from '../../services/modal.service';
import { ShareReportModalComponent } from '../../pages/public/scans/scan-results/view-scan-results-summary/share-report-modal/share-report-modal.component';
import { IModalDialogInputData } from '../../interfaces/modal-dialog.interface';
import { AccessibilityAuditToolNames } from '../../../../shared/constants/audit-tool';
import { EapColors } from '../../shared/eap-colors';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

const FileTypeIcons: Record<FileExtension, Icons> = {
  [FileExtension.csv]: Icons.FileInvoice,
  [FileExtension.xlsx]: Icons.FileExcel,
};

@Component({
  selector: 'app-export-report-button',
  templateUrl: './export-report-button.component.html',
  styleUrls: ['./export-report-button.component.scss'],
})
export class ExportReportButtonComponent {
  @Output() public onDownloadReport: EventEmitter<FileExtension>;
  @Input() public fileExtensions: FileExtension[];
  @Input() public shareModalInputData: IModalDialogInputData<{
    workspaceId: string;
    digitalPropertyId: string;
    scanId: string;
    auditTool: AccessibilityAuditToolNames;
  }>;
  public Icons: typeof Icons;
  public EapColors: typeof EapColors;
  public DsButtonVariants: typeof DsButtonVariants;
  public FileTypeIcons: typeof FileTypeIcons;

  constructor(private modalService: ModalService) {
    this.Icons = Icons;
    this.EapColors = EapColors;
    this.DsButtonVariants = DsButtonVariants;
    this.onDownloadReport = new EventEmitter<FileExtension>();
    this.FileTypeIcons = FileTypeIcons;
  }

  public get isShareable(): boolean {
    return SharedCommonUtility.notNullish(this.shareModalInputData);
  }

  public onDownloadReportRequest(fileExtension: FileExtension): void {
    this.onDownloadReport.emit(fileExtension);
  }

  public onShareReportRequest(): void {
    const modalComponentRef: ComponentRef<ShareReportModalComponent> = this.modalService.open(ShareReportModalComponent);
    modalComponentRef.instance.modalInputData = this.shareModalInputData;
  }
}
