<form [formGroup]="form">
  <h4>{{ stepNumber + 1 }} - {{ 'schedule' | translate }}</h4>

  <div class="row margin-bottom">
    <div class="col">
      <app-common-select
        class="d-block"
        [form]="form"
        [values]="RECURRENCE_OPTIONS"
        [formValidationRequest$]="formValidationRequest$"
        [context]="{
          label: 'recurrence',
          field: $digitalPropertyMonitoring.occurrence,
          nameKey: 'key',
          valueKey: 'value',
        }"
      >
      </app-common-select>
    </div>
    <div class="col">
      <app-common-input
        class="d-block"
        [formValidationRequest$]="formValidationRequest$"
        [form]="form"
        [context]="{
          label: 'start_on',
          field: $digitalPropertyMonitoring.startAt,
          type: 'date',
          overrideErrors: {
            minDate: 'date_must_be_greater_than_today' | translate,
          },
        }"
      ></app-common-input>
    </div>
  </div>
  <div class="row">
    <span class="col">{{ scheduleSet }}</span>
  </div>
</form>
