<div>
  <form class="d-flex flex-column align-items-start" (ngSubmit)="applyFilter()">
    <label for="scanTagSearchField" class="label margin-bottom-xs text-nowrap">
      {{ 'label_filter_by_scan_tag_names' | translate }}
    </label>
    <div class="d-flex w-50">
      <input
        #searchField
        type="text"
        id="scanTagSearchField"
        [(ngModel)]="filterText"
        [ngModelOptions]="{ standalone: true }"
        class="form-control"
      />
      <button type="submit" class="btn btn-outline-primary margin-left pe-4 ps-4 fw-bold">
        {{ 'form_filter' | translate }}
      </button>
    </div>
  </form>

  <div class="margin-top">
    <ul class="list-unstyled scan-tags-list">
      <li
        *ngFor="let scanTag of filteredValues"
        class="text-item"
        [class.selected]="selectedScanTagId === scanTag._id"
        (click)="select(scanTag)"
      >
        <button role="radio" type="button" class="btn btn-text">
          {{ scanTag.tagName }}
        </button>
      </li>
    </ul>
  </div>
</div>
