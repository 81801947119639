import { Component, ViewChild } from '@angular/core';
import { ModalContainerComponent } from '../../modals/modal-container.component';
import { IModal } from '../../modals/modal.interface';

@Component({
  templateUrl: './turn-off-monitoring-confirmation.component.html',
})
export class TurnOffMonitoringConfirmationComponent implements IModal {
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;
  public confirmed: boolean;

  constructor() {}

  public close(): void {
    this.container.closeModal();
  }

  public onConfirm(): void {
    this.confirmed = true;
    this.close();
  }
}
