export enum $auditedDocument {
  _id = '_id',
  reference = 'reference',
  referenceHash = 'referenceHash',
  scanId = 'scanId',
  size = 'size',
  type = 'type',
  title = 'title',
  version = 'version',
  screenshot = 'screenshot',
  pageId = 'pageId',
  scanPoint = 'scanPoint',
  createdAt = 'createdAt',
}

export enum AuditDocumentQuery {
  url = 'url',
  pageId = 'pageId',
}
