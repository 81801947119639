import { NgModule, Type } from '@angular/core';

import { CompoundNavbarComponent } from './compound-navbar.component';
import { CompoundTopNavbarComponent } from './compound-top-navbar/compound-top-navbar.component';
import { TopNavNotificationsComponent } from './compound-top-navbar/top-navbar-notifications/top-nav-notifications.component';
import { CompoundThirdLevelNavbarComponent } from './compound-third-level-navbar/compound-third-level-navbar.component';
import { SharedModule } from '../../shared/shared.module';
import { CompoundNavbarLinkComponent } from './compound-navbar-link/compound-navbar-link.component';
import { TopNavbarWorkspacesDropdownComponent } from './compound-top-navbar/top-navbar-workspaces-dropdown/top-navbar-workspaces-dropdown.component';
import { TopNavbarProfileDropdownComponent } from './compound-top-navbar/top-navbar-profile-dropdown/top-navbar-profile-dropdown.component';
import { CompoundSecondLevelNavbarComponent } from './compound-second-level-navbar/compound-second-level-navbar.component';
import { TopNavbarLauncherDropdownComponent } from './compound-top-navbar/top-navbar-launcher-dropdown/top-navbar-launcher-dropdown.component';
import { MobileTopNavbarMenuModalComponent } from './compound-top-navbar/mobile-top-navbar-menu-modal/mobile-top-navbar-menu-modal.component';
import { MobileSecondNavbarMenuModalComponent } from './compound-second-level-navbar/mobile-second-navbar-menu-modal/mobile-second-navbar-menu-modal.component';
import { WebsitesAppsSelectComponent } from './websites-apps-select/websites-apps-select.component';
import { WorkspacesSelectComponent } from './compound-second-level-navbar/mobile-second-navbar-menu-modal/workspaces-select/workspaces-select.component';
import { CompoundTopNavbarUnauthenticatedComponent } from './compound-top-navbar/compound-top-navbar-unauthenticated.component';

const compoundNavbarComponents: Type<any>[] = [
  CompoundNavbarComponent,
  CompoundTopNavbarComponent,
  CompoundTopNavbarUnauthenticatedComponent,
  CompoundThirdLevelNavbarComponent,
  CompoundNavbarLinkComponent,
  TopNavNotificationsComponent,
  TopNavbarWorkspacesDropdownComponent,
  TopNavbarProfileDropdownComponent,
  TopNavbarLauncherDropdownComponent,
  CompoundSecondLevelNavbarComponent,
  MobileTopNavbarMenuModalComponent,
  MobileSecondNavbarMenuModalComponent,
  WebsitesAppsSelectComponent,
  WorkspacesSelectComponent,
];

@NgModule({
  declarations: [...compoundNavbarComponents],
  exports: [...compoundNavbarComponents],
  imports: [SharedModule],
})
export class CompoundNavbarModule {}
