import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IFilterOutput } from '../filter.interface';
import { CommonUtility } from '../../../utility/common.utility';

@Component({
  selector: 'app-filter-number',
  templateUrl: './filter-number.component.html',
})
export class FilterNumberComponent<T> implements OnInit {
  @Input() public label: string;
  @Input() public field: string;
  @Input() public disabled: boolean;
  @Input() public value: T;
  @Output() public valueChange: EventEmitter<IFilterOutput<T>>;

  public output: IFilterOutput<T>;
  public domID: string;

  public constructor() {
    this.label = '';
    this.field = '';
    this.disabled = false;
    this.valueChange = new EventEmitter<IFilterOutput<T>>();
    this.domID = `${CommonUtility.createUniqueDOMId()}`;
  }

  public onValueChange(): void {
    this.valueChange.emit(this.output);
  }

  public ngOnInit(): void {
    this.output = {
      field: String(this.field),
      values: this.value,
    };
  }
}
