<ds-modal
  [modal]="modal"
  [title]="parentMenuItemLabel | translate"
  [backNavLabel]="backNavLabel | translate"
  [isInlineTitle]="isModalTitleInline"
  [colorVariant]="modalColorVariant"
>
  <app-workspaces-select *ngIf="showWorkspacesSelector" (workspaceChanged)="onModalClose()"></app-workspaces-select>

  <div class="web-apps-select" *ngIf="showWebAppSelector">
    <app-websites-apps-select label="select_website_app" (webAppChanged)="onModalClose()"></app-websites-apps-select>
  </div>

  <ul >
    <li *ngFor="let childRoute of childrenRoutes" [class.active]="childRoute.isActive">
      <button
        ds-button
        *ngIf="childRoute.hasChildren; else links"
        [variant]="DsButtonVariants.microActionWithChevronRight"
        [hideMicroActionIcon]="true"
        (click)="openChildNavbarMenuItemsModal(childRoute)"
      >
        {{ childRoute.label | translate }}
      </button>
      <ng-template #links>
        <a
          ds-button
          *ngIf="!!childRoute.externalLink === false; else externalLink"
          [routerLink]="childRoute.routerLink"
          [variant]="DsButtonVariants.microActionHorizontalLayout"
          [hideMicroActionIcon]="true"
          [attr.aria-current]="childRoute.isActive ? 'page' : undefined"
          (click)="onModalClose()"
        >
          {{ childRoute.label | translate }}
        </a>
        <ng-template #externalLink>
          <a
            ds-button
            [href]="childRoute.externalLink"
            [variant]="DsButtonVariants.microActionHorizontalLayout"
            [hideMicroActionIcon]="true"
            [isExternalLink]="true"
            target="_blank"
          >
            {{ childRoute.label | translate }}
          </a>
        </ng-template>
      </ng-template>
    </li>
  </ul>
</ds-modal>

<ng-template #topLevelModal let-modal>
  <app-mobile-top-navbar-menu-modal [modal]="modal"></app-mobile-top-navbar-menu-modal>
</ng-template>
