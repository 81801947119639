import { CommonUtility } from './common.utility';
import { TranslateService } from '../translate/translate.service';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import timezones from '../../../shared/constants/timezones.json';
import { IDefinedTimezone } from '../../../shared/interfaces/timezone.interface';

export class DateUtility {
  constructor() {}

  public static timeAgo(since: string, language: string, translateService: TranslateService, useAbbreviation?: boolean): string {
    const current: number = new Date().getTime();
    const sinceTime: number = new Date(since).getTime();
    const msPerMinute: number = 60 * 1000;
    const msPerHour: number = msPerMinute * 60;
    const msPerDay: number = msPerHour * 24;
    const msPerWeek: number = msPerDay * 7;
    const msPerMonth: number = msPerDay * 30;
    const msPerYear: number = msPerDay * 365;

    const elapsed: number = current - sinceTime;

    const isIntlRelativeTimeFormatSupported: boolean =
      CommonUtility.isHostMethod(window, 'Intl') && CommonUtility.isHostMethod((window as any).Intl, 'RelativeTimeFormat');
    let rtf: any;

    if (isIntlRelativeTimeFormatSupported) {
      const normalizedLanguage: string = language === 'en-us-2' ? 'en-us' : language;
      rtf = new (window as any).Intl.RelativeTimeFormat(normalizedLanguage, {
        numeric: 'auto',
      });
    }

    let result: number;

    if (elapsed < msPerMinute) {
      result = Math.round(elapsed / 1000);

      if (isIntlRelativeTimeFormatSupported && !useAbbreviation) {
        return rtf.format(-result, 'seconds');
      }
      return translateService.instant(useAbbreviation ? 'n_seconds_abbreviation' : 'seconds_ago', [result]);
    } else if (elapsed < msPerHour) {
      result = Math.round(elapsed / msPerMinute);

      if (isIntlRelativeTimeFormatSupported && !useAbbreviation) {
        return rtf.format(-result, 'minutes');
      }
      return translateService.instant(useAbbreviation ? 'n_minutes_abbreviation' : 'minutes_ago', [result]);
    } else if (elapsed < msPerDay) {
      result = Math.round(elapsed / msPerHour);

      if (isIntlRelativeTimeFormatSupported && !useAbbreviation) {
        return rtf.format(-result, 'hours');
      }
      return translateService.instant(useAbbreviation ? 'n_hours_abbreviation' : 'hours_ago', [result]);
    } else if (elapsed < msPerWeek) {
      result = Math.round(elapsed / msPerDay);

      if (isIntlRelativeTimeFormatSupported && !useAbbreviation) {
        return rtf.format(-result, 'days');
      }
      return translateService.instant(useAbbreviation ? 'n_days_abbreviation' : 'days_ago', [result]);
    } else if (elapsed < msPerMonth) {
      result = Math.round(elapsed / msPerWeek);

      if (isIntlRelativeTimeFormatSupported && !useAbbreviation) {
        return rtf.format(-result, 'weeks');
      }
      return translateService.instant(useAbbreviation ? 'n_weeks_abbreviation' : 'weeks_ago', [result]);
    } else if (elapsed < msPerYear) {
      result = Math.round(elapsed / msPerMonth);

      if (isIntlRelativeTimeFormatSupported && !useAbbreviation) {
        return rtf.format(-result, 'months');
      }
      return translateService.instant(useAbbreviation ? 'n_months_abbreviation' : 'months_ago', [result]);
    }
    result = Math.round(elapsed / msPerYear);

    if (isIntlRelativeTimeFormatSupported && !useAbbreviation) {
      return rtf.format(-result, 'years');
    }
    return translateService.instant(useAbbreviation ? 'n_years_abbreviation' : 'years_ago', [result]);
  }

  /**
   * Returns the timezone value from utc timezone format.
   *
   * @param utcTimezone string or null (eg. 'America/New_York')
   * @returns timezone string or null (eg. 'Eastern Standard Time')
   */
  public static getTimezoneValueFromUtc(utcTimezone: string | null): string | null {
    if (SharedCommonUtility.isNullishOrEmpty(utcTimezone)) {
      return utcTimezone;
    }

    // Find the first non-DST (Daylight Saving Time) timezone that matches the UTC timezone
    const standardTimezone: IDefinedTimezone = timezones.find(
      (timezoneObj: IDefinedTimezone) => !timezoneObj.isdst && timezoneObj.utc.includes(utcTimezone),
    );

    return standardTimezone ? standardTimezone.value : utcTimezone;
  }

  public static getFormattedTime(duration: number): string {
    const seconds: number = Math.floor((duration / 1000) % 60);
    const minutes: number = Math.floor((duration / (60 * 1000)) % 60);
    const hours: number = Math.floor((duration / (60 * 60 * 1000)) % 60);
    let time: string = '';

    if (duration < 1) {
      return '< 1 ms';
    }

    if (hours > 0) {
      time += hours + 'h ';
    }

    if (minutes > 0) {
      time += minutes + 'm ';
    }

    if (seconds > 0) {
      time += seconds + 's ';
    }

    return time.trim();
  }

  public static determineUserTimeZone(): string | null {
    let userTimeZone: string | null = null;

    try {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      console.warn('[DateUtility.determineUserTimeZone] Intl.DateTimeFormat not supported', e);
    }

    return userTimeZone;
  }

  // For Toronto, this produces a message like '2:16 p.m. EST'
  public static prettifyTime(date: Date, includeTimeZone: boolean = true): string {
    const timeZoneName: 'long' | 'short' = includeTimeZone ? 'short' : undefined;
    const timeStyle: Intl.DateTimeFormat = new Intl.DateTimeFormat([], {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName,
    });

    return timeStyle.format(date);
  }

  // Returns something like 'October 19, 2021'
  public static prettifyDate(date: Date, formatOptions?: Intl.DateTimeFormatOptions): string {
    const defaultFormatOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const dateStyle: Intl.DateTimeFormat = new Intl.DateTimeFormat(
      [],
      SharedCommonUtility.notNullish(formatOptions) ? formatOptions : defaultFormatOptions,
    );
    return dateStyle.format(date);
  }

  // Returns something like 'Oct 19, 2021'
  public static toShortUSDate(date: Date): string {
    const dateStyle: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    return dateStyle.format(date);
  }

  public static getFormattedISODate(
    dateISO: string,
    withTime: boolean = true,
    locales: Array<string> = [],
    timeZoneName?: boolean,
  ): string {
    const localDate: Date = new Date(dateISO);

    if (withTime) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: timeZoneName ? 'short' : undefined,
      };
      const dateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(locales, options);
      return dateTimeFormat.format(localDate);
    }

    const localMidnight: Date = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), 0, 0, 0, 0);
    return new Intl.DateTimeFormat(locales).format(localMidnight);
  }

  public static formatCellDate(date: Date, timeConnector: string = ''): string {
    const localeDateString = date.toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' });
    if (SharedCommonUtility.isNullishOrEmpty(timeConnector) === false) {
      const localeTimeString = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', hour12: true });

      return `${localeDateString} ${timeConnector} ${localeTimeString}`;
    }

    return localeDateString;
  }
}
