<ng-container *ngIf="useList; else accordion">
  <ul [attr.role]="nestedList ? 'group' : 'tree'" [attr.aria-labelledby]="ariaLabelledBy" class="list-unstyled m-0">
    <li *ngFor="let panel of panels" [attr.aria-expanded]="panel.isOpen" role="treeitem">
      <div id="{{ panel.id }}-header" class="lap-accordion-header">
        <ng-template
          [ngTemplateOutlet]="panel.headerTpl?.templateRef"
          [ngTemplateOutletContext]="{ $implicit: panel, opened: panel.isOpen }"
        ></ng-template>
      </div>
      <ul
        *ngIf="panel.isOpen"
        id="{{ panel.id }}"
        role="group"
        class="list-unstyled m-0 lap-accordion-body"
        [attr.aria-labelledby]="panel.id + '-header'"
      >
        <li *ngIf="!nestedList; else item" role="treeitem">
          <ng-container *ngTemplateOutlet="item"></ng-container>
        </li>
        <ng-template #item>
          <ng-template [ngTemplateOutlet]="panel.contentTpl?.templateRef || null"></ng-template>
        </ng-template>
      </ul>
    </li>
  </ul>
</ng-container>
<ng-template #accordion>
  <ng-template ngFor let-panel [ngForOf]="panels">
    <div>
      <div id="{{ panel.id }}-header" class="lap-accordion-header">
        <ng-template
          [ngTemplateOutlet]="panel.headerTpl?.templateRef"
          [ngTemplateOutletContext]="{ $implicit: panel, opened: panel.isOpen }"
        ></ng-template>
      </div>
      <div id="{{ panel.id }}" role="tabpanel" [attr.aria-labelledby]="panel.id + '-header'" *ngIf="panel.isOpen">
        <div class="lap-accordion-body">
          <ng-template [ngTemplateOutlet]="panel.contentTpl?.templateRef || null"></ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
