import { DashboardDataFrequency } from '../../../shared/interfaces/ws-dashboard.interface';
import { SharedDateUtility } from '../../../shared/utils/date.utility';
import { DateUtility } from './date.utility';

export class RiskChartUtility {
  public static formatDateForFrequency(date: Date, frequency: DashboardDataFrequency, verbose: boolean = false): string {
    const month: string = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day: string = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    switch (frequency) {
      case DashboardDataFrequency.monthly:
        if (verbose === true) {
          return new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
        }
        return `${month}`;
      case DashboardDataFrequency.quarterly:
        return `Q${SharedDateUtility.getQuarter(date)} ${date.getUTCFullYear()}`;
      default:
        if (verbose === true) {
          return DateUtility.prettifyDate(date, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
        }
        return `${month} ${day}`;
    }
  }

  public static getDescriptorKeyForFrequency(frequency: DashboardDataFrequency): string {
    switch (frequency) {
      case DashboardDataFrequency.quarterly:
        return 'quarter';
      case DashboardDataFrequency.monthly:
        return 'time_month';
      default:
        return 'label_date';
    }
  }

  // when in monthly and quarterly view, only show data for periods that are in the past
  public static shouldShowDataPoint(computedDate: Date, freq: DashboardDataFrequency): boolean {
    const now: Date = new Date();
    switch (freq) {
      case DashboardDataFrequency.monthly:
        return now.getUTCMonth() !== computedDate.getUTCMonth() || now.getUTCFullYear() !== computedDate.getFullYear();
      case DashboardDataFrequency.quarterly:
        return (
          SharedDateUtility.getQuarter(now) !== SharedDateUtility.getQuarter(computedDate) ||
          now.getUTCFullYear() !== computedDate.getUTCFullYear()
        );
      case DashboardDataFrequency.weekly:
      default:
        return true;
    }
  }
}
