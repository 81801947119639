<span class="text-center" aria-hidden="true" >
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="variation === 0">
      <ng-container *ngIf="config?.showNoChange === true">
        {{ noChangePrefix + ' ' + ('no_change' | translate) }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="variation > 0">
      <app-icon
        class="me-1"
        [icon]="Icons.TrendUpIcon"
        [style.color]="config.isIncreaseImprovement ? EapColors.GreenSeverityLow : EapColors.RedError"
      >
      </app-icon>
      {{ '+' + variation }}
    </ng-container>
    <ng-container *ngSwitchCase="variation < 0">
      <app-icon
        class="me-1"
        [icon]="Icons.TrendDownIcon"
        [style.color]="config.isIncreaseImprovement ? EapColors.RedError : EapColors.GreenSeverityLow"
      >
      </app-icon>
      {{ variation }}
    </ng-container>
  </ng-container>
</span>

<div class="visuallyhidden" >
  <ng-container *ngIf="absVariation === 0; else scoreMessage">
    <ng-container *ngIf="config?.showNoChange === true">
      {{ 'no_change' | translate }}
    </ng-container>
  </ng-container>
  <ng-template #scoreMessage>
    {{ (isPositiveTrend ? 'score-increase-message' : 'score-decrease-message') | translate: [absVariation] }}
  </ng-template>
</div>
