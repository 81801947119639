import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Router, UrlSegment, PRIMARY_OUTLET, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

interface ISetValueOptions {
  onlySelf?: boolean;
  emitEvent?: boolean;
}

export class AngularUtility {
  constructor() {}

  public static resetFormData(formValues: any, form: UntypedFormGroup, options: ISetValueOptions): void {
    form.setValue(formValues, options);
    form.markAsPristine();
    form.markAsUntouched();
  }

  public static getControlName(control: AbstractControl): string | null {
    const formGroup: { [key: string]: AbstractControl } | AbstractControl[] = control.parent.controls;

    const findControl = (name: string): boolean => {
      return control === formGroup[name];
    };

    return Object.keys(formGroup).find(findControl) || null;
  }

  public static getRouterPaths(router: Router): string[] {
    return router
      .parseUrl(router.routerState.snapshot.url)
      .root.children[PRIMARY_OUTLET].segments.map((urlSegment: UrlSegment): string => {
        return urlSegment.path;
      });
  }

  public static getFullRoutePath(snapshot: ActivatedRouteSnapshot): string {
    return snapshot.pathFromRoot.map((route: ActivatedRouteSnapshot) => route.url.toString()).join('/');
  }

  /**
   * use as an operator in RxJS pipes. Makes sure that if multiple subscribers are subscribed to the observable, the
   * original resource only gets called once. Useful when piping from observables returned from HTTP calls.
   * Example:
   * const userNames$ = linkedPropertyData$.pipe(switchMap((..) => myservice.callHttp(..)))
   * if you use userNames$|async in the template multiple times, or if you do userNames.subscribe three times,
   * myservice.callHttp() will be actually called 3 times, and will make 3 http requests. On the other hand this:
   * const userNames$ = linkedPropertyData$.pipe(switchMap((..) => myservice.callHttp(..), AngularUtility.shareRef()))
   * will only call myservice.callHttp() once, producing one httpCall, no matter how many subscribers it has.
   *
   * @warning it is not without caveats though. may produce unexpected behavior with inter-dependent observables. see
   * this github discussion for more info: https://github.com/ReactiveX/rxjs/discussions/6605
   */
  public static shareRef<T>() {
    return (source: Observable<T>): Observable<T> => source.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }
}
