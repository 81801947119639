import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../../../shared/constants/api';
import { $shortLink, shortLinkType } from '../../../shared/constants/short-link';
import { $tenant } from '../../../shared/constants/tenant';
import {
  IAuditFindingShortLinkContext,
  IFindingUrlShortLinkContext,
  IShareFindingUrlShortLinkContext,
  IShortLink,
  IUploadShortLinkContext,
} from '../../../shared/interfaces/short-link.interface';
import { ITenant } from '../../../shared/interfaces/tenant.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { config } from '../../environments/config.shared';
import { $viewIssuesParams } from '../constants/view-issues-params';
import { AppConfigService } from './app-config.service';
import { RestBuilder } from './helpers/rest.builder';
import { ShortLinkRestApi } from './rest/short-link.api';

@Injectable({
  providedIn: 'root',
})
export class ShortLinkService {
  private restBuilder: RestBuilder;

  constructor(
    private appConfigService: AppConfigService,
    private shortLinkRestApi: ShortLinkRestApi,
  ) {
    this.restBuilder = new RestBuilder();
  }

  private getAttachmentShortLink(shortLink: IShortLink): string {
    const attachmentContext: IUploadShortLinkContext = shortLink[$shortLink.context];
    return this.restBuilder.create().secureResources(attachmentContext.uploadId).getApiUrl();
  }

  private static getUrlFindingShortLink(shortLink: IShortLink): string {
    const urlContext: IFindingUrlShortLinkContext = shortLink[$shortLink.context];
    const redirectUrl: string =
      `/${Api.scans}/${Api.scan_results}/${urlContext.scanId}/${Api.aggregated_report}` +
      `/${urlContext.tool}/${urlContext.ruleId}?${urlContext.urlQuery}`;
    if (urlContext.issueId) {
      return `${redirectUrl}&${$viewIssuesParams.issueId}=${urlContext.issueId}`;
    }
    return redirectUrl;
  }

  private static getAuditFindingShortLink(shortLink: IShortLink): string {
    const context: IAuditFindingShortLinkContext = shortLink[$shortLink.context];
    return `/${Api.manual_evaluations}/${context.scanId}/${Api.results}/${context.elementReference}/${Api.view}?${context.urlQuery}`;
  }

  private static getShareFindingShortLink(shortLink: IShortLink): string {
    const context: IShareFindingUrlShortLinkContext = shortLink[$shortLink.context];
    return `/${Api.designEvaluations}/${context.projectId}/${Api.pages}/${context.pageId}/${Api.findings}/${context.findingKey}?${context.urlQuery}`;
  }

  public getRedirectPath(shortLink: IShortLink): string {
    switch (shortLink[$shortLink.linkType]) {
      case shortLinkType.findingUrl:
        return ShortLinkService.getUrlFindingShortLink(shortLink);

      case shortLinkType.auditFinding:
        return ShortLinkService.getAuditFindingShortLink(shortLink);

      case shortLinkType.shareFinding:
        return ShortLinkService.getShareFindingShortLink(shortLink);

      case shortLinkType.auditFindingAttachment:
        return this.getAttachmentShortLink(shortLink);

      default:
        return null;
    }
  }

  public generateShortLink<T>(linkType: shortLinkType, context: T, tenantId: string = null): Observable<{ url: string }> {
    return this.shortLinkRestApi.generateShortLink(linkType, context, tenantId);
  }

  /**
   * Returns the tenant URL if tenant is not nullish. If used on a tenant instance it will return a tenant URL
   * regardless if tenant is nullish.
   *
   * @param tenant
   */
  public getBaseUrl(tenant?: ITenant): string {
    if (SharedCommonUtility.isNullish(tenant)) {
      return config.appUrl;
    }

    const protocol: string = new URL(SharedCommonUtility.getNormalizedUrlString(config.appUrl)).protocol;
    return `${protocol}//${tenant[$tenant.domain]}.${this.appConfigService.getTenantBaseUrl()}`;
  }
}
