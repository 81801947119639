import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { $scanViewport } from '../../../../shared/constants/scan-viewport';

@Component({
  selector: 'app-viewport-custom-values',
  templateUrl: './viewport-custom-values.component.html',
  styleUrls: ['./viewport-custom-values.component.scss'],
})
export class ViewportCustomValuesComponent {
  @Input()
  public viewportForm: UntypedFormGroup;
  public $scanViewport: typeof $scanViewport;

  constructor() {
    this.$scanViewport = $scanViewport;
    this.viewportForm = new UntypedFormGroup({});
  }
}
