export enum $workspaceActivityLog {
  _id = '_id',
  user = 'user',
  workspace = 'workspace',
  event = 'event',
  extra = 'extra',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  workspaceActivityLogs = 'workspaceActivityLogs',
  digitalPropertyId = 'digitalPropertyId',
  digitalProperty = 'digitalProperty',
  viewed = 'viewed',
  viewedBy = 'viewedBy',
  newActivityLogsCount = 'newActivityLogsCount',
  logsToBeMarked = 'logsToBeMarked',
}

export enum workspaceActivityLogEvent {
  userJoined = 'userJoined',
  documentAddedToDigitalProperty = 'documentAddedToDigitalProperty',
  documentUpdatedInDigitalProperty = 'documentUpdatedInDigitalProperty',
  documentRemovedFromDigitalProperty = 'documentRemovedFromDigitalProperty',
  scanPerformedAndCompleted = 'scanPerformedAndCompleted',
  scanReportEmailed = 'scanReportEmailed',
  commentAddedToManualFinding = 'commentAddedToManualFinding',
  commentAddedToProjectTask = 'commentAddedToProjectTask',
  automatedFindingDismissed = 'automatedFindingDismissed',
  commentAddedToIssueIntegrationTask = 'commentAddedToIssueIntegrationTask',
  commentUpdatedOnIssueIntegrationTask = 'commentUpdatedOnIssueIntegrationTask',
  commentDeletedFromIssueIntegrationTask = 'commentDeletedFromIssueIntegrationTask',
  manualFindingDismissed = 'manualFindingDismissed',
  projectTaskStatusChanged = 'projectTaskStatusChanged',
  manualFindingStatusChanged = 'manualFindingStatusChanged',
  taskCreatedFromAutomatedFindings = 'taskCreatedFromAutomatedFindings',
  taskCreatedFromManualFindings = 'taskCreatedFromManualFindings',
  taskCreatedFromDesignReview = 'taskCreatedFromDesignReview',
  taskAssigned = 'taskAssigned',
  taskReassigned = 'taskReassigned',
  taskUnassigned = 'taskUnassigned',
  taskSupportRequest = 'taskSupportRequest',
  workspaceUserRemoved = 'workspaceUserRemoved',
  remediatedPdfUploaded = 'remediatedPdfUploaded',
  remediationRequestRevokedForDocument = 'remediationRequestRevokedForDocument',
  monitoringScanScoreDropFromPrevious = 'monitoringScanScoreDropFromPrevious',
  monitoringScanScoreDrop = 'monitoringScanScoreDrop',
  monitoringScanComplete = 'monitoringScanComplete',
  asyncReportGenerationCompleted = 'asyncReportGenerationCompleted',
  requestValidationTesting = 'requestValidationTesting',
}

export enum workspaceActivityLogFilter {
  document = 'document',
  scan = 'scan',
  user = 'user',
}

export enum WorkspaceActivityLogSearchFields {
  workspaceId = 'workspaceId',
  digitalPropertyId = 'digitalPropertyId',
  userDisplayNameFilter = 'userDisplayNameFilter',
}
