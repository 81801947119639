<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 d-flex flex-column">
      <app-header></app-header>
      <div class="flex-1 d-flex justify-content-center align-items-center">
        <div class="card">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <h1 id="maintenance-header">We will be back soon!</h1>
            <p id="maintenance-text">
              We are currently undergoing maintenance and apologize for any inconvenience. Thank you for your patience.
            </p>
            <img src="/static/assets/images/maintenancegraphic.svg" alt="Page maintenance graphic" />
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
