<div
  class="{{ 'd-flex flex-column empty-state-section text-center' + (hideBorders ? ' no-borders' : '') }}"
  *ngIf="tableEmptyState"
>
  <ng-container [ngSwitch]="tableEmptyState">
    <ng-container *ngSwitchCase="tableEmptyStateType.searchingNoResults">
      <div class="search-icon full-width">
        <svg aria-hidden="true" width="96" height="60" fill="none">
          <use xlink:href="#search-icon-deprecated"></use>
        </svg>
      </div>
      <h3 class="title-label full-width text-center">{{ 'no_search_results_available' | translate }}</h3>
      <div class="description-label full-width text-center">{{ 'label_no_search_results_hint' | translate }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="tableEmptyStateType.inReviewNoResultsTotal">
      <div class="in-review-icon full-width">
        <svg aria-hidden="true" width="68" height="96" fill="none">
          <use xlink:href="#clipboard-icon"></use>
        </svg>
      </div>
      <h3 class="title-label full-width text-center">{{ 'in_review_no_results_available' | translate }}</h3>
      <div class="description-label full-width text-center">{{ 'in_review_no_results_available_description' | translate }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="tableEmptyStateType.failedNoResultsTotal">
      <div class="failed-icon full-width">
        <svg aria-hidden="true" width="111" height="90" fill="none">
          <use xlink:href="#highfive"></use>
        </svg>
      </div>
      <h3 class="title-label full-width text-center">{{ 'failed_no_results_available' | translate }}</h3>
      <div class="description-label full-width text-center">{{ 'failed_no_results_available_description' | translate }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="tableEmptyStateType.passedNoResultsTotal">
      <div class="passed-icon full-width">
        <svg aria-hidden="true" width="68" height="96" fill="none">
          <use xlink:href="#clipboard-red"></use>
        </svg>
      </div>
      <h3 class="title-label full-width text-center">{{ 'passed_no_results_available' | translate }}</h3>
      <div class="description-label full-width text-center">{{ 'passed_no_results_available_description' | translate }}</div>
    </ng-container>
    <ng-container *ngSwitchCase="tableEmptyStateType.notApplicableNoResultsTotal">
      <div class="not-applicable-icon full-width">
        <svg aria-hidden="true" width="96" height="60" fill="none">
          <use xlink:href="#empty-box"></use>
        </svg>
      </div>
      <h3 class="title-label full-width text-center">{{ 'not_applicable_no_results_available' | translate }}</h3>
      <div class="description-label full-width text-center">
        {{ 'not_applicable_no_results_available_description' | translate }}
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="tableEmptyStateType.noDigitalPropertiesFound">
      <div class="search-icon full-width">
        <svg aria-hidden="true" width="96" height="60" fill="none">
          <use xlink:href="#search-icon-deprecated"></use>
        </svg>
      </div>
      <h3 class="title-label full-width text-center">{{ 'no_digital_properties_found' | translate }}</h3>
      <div class="description-label full-width text-center">{{ 'no_digital_properties_found_description' | translate }}</div>
    </ng-container>
  </ng-container>
</div>
