export class NotificationModel {
  public id: string;
  public keepAfterRouteChange: boolean;
  public keepOnlyOne: boolean;
  public message: string | null;
  public position: NotificationPosition;
  public type: NotificationType;

  constructor() {}
}

export enum NotificationType {
  Success,
  Error,
  Info,
  Warning,
}

export enum NotificationPosition {
  Toast,
  Inline,
}
