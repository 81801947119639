import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from '../../services/user.service';
import { IUserServerResponse } from '../../../../shared/interfaces/user.interface';
import { AclService, AclSecurityAdapter } from '../../services/acl.service';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { IRequiredSecurity } from '../../../../shared/interfaces/security.interface';
import { SecurityEntityLevel } from '../../../../shared/constants/security-group';
import { AppConfigService } from '../../services/app-config.service';

@Directive({
  selector: '[appHasPermissions]',
})
export class HasPermissionsDirective implements OnInit, OnDestroy {
  private currentUser: IUserServerResponse;
  private requiredSecurity: IRequiredSecurity;
  private isHidden: boolean;
  private readonly subscriptions: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
    private aclService: AclService,
    private appConfigServices: AppConfigService,
  ) {
    this.isHidden = true;
    this.subscriptions = new Subscription();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    if (SharedCommonUtility.isNullish(this.currentUser)) {
      return false;
    }

    const adapter: AclSecurityAdapter = this.aclService
      .createAccessCheck(this.currentUser)
      .useFunctionalActions(this.requiredSecurity?.functionalActions);

    const isAdmin: boolean = !this.appConfigServices.isTenantEnabled();
    const allowAdminAccess: boolean =
      this.requiredSecurity?.entityLevel !== SecurityEntityLevel.admin && this.requiredSecurity?.allowAdminAccess;

    if (isAdmin && allowAdminAccess) {
      adapter.useAdmin();
    } else {
      switch (this.requiredSecurity?.entityLevel) {
        case SecurityEntityLevel.admin:
        case SecurityEntityLevel.tenant:
          adapter.useEntityLevel();
          break;
        case SecurityEntityLevel.digitalProperty:
          adapter.useWorkspaceFromUser().useDigitalPropertyFromUser();
          break;
        default:
          adapter.useWorkspaceFromUser();
          break;
      }
    }

    return adapter.check();
  }

  @Input()
  public set appHasPermissions(permissions: IRequiredSecurity) {
    this.requiredSecurity = permissions;

    this.updateView();
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.userService.userDataChanged$.subscribe((user: IUserServerResponse) => {
        this.currentUser = user;
        this.updateView();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
