import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, ObservableInput, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CommonUtility } from '../../utility/common.utility';
import { errorMessagesNames } from '../../../../shared/constants/errors';
import { UserService } from '../user.service';

@Injectable()
export class NoWorkspaceInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleNoWorkspacesAvailableError = (err: any, caught: Observable<HttpEvent<any>>): ObservableInput<any> => {
      if (
        this.userService.hasAWorkspaceSubject$.getValue() &&
        err instanceof HttpErrorResponse &&
        (CommonUtility.extractHTTPErrorName(err) === errorMessagesNames.ErrorUserHasNoAvailableWorkspaces ||
          CommonUtility.extractHTTPErrorName(err) === errorMessagesNames.NoAccessToTenant)
      ) {
        this.userService.setToNoWorkspaceState();
        return of();
      }
      return throwError(err);
    };

    return next.handle(request).pipe(catchError(handleNoWorkspacesAvailableError));
  }
}
