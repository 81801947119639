<div #columnsSwitcherDropdown class="dropdown">
  <button
    class="dropdown-toggle btn btn-link fw-bold text-underline btn-focus-contrast-outline"
    data-toggle="columnSelector"
    aria-haspopup="true"
    aria-controls="columnSelector"
    id="columnSelectorToggler"
    [ngClass]="{ 'border-dark': columnSelectorOpened }"
    [attr.aria-expanded]="columnSelectorOpened"
    (click)="toggleColumnSelector()"
  >
    <svg aria-hidden="true" width="16" height="14" class="margin-right-xs">
      <use xlink:href="#fa-columns"></use>
    </svg>
    <span>{{ 'label_hide_and_show_columns' | translate }}</span>
  </button>

  <div
    class="dropdown-menu m-0 p-3 border-dark"
    [class]="{ show: columnSelectorOpened }"
    id="columnSelector"
    aria-labelledby="columnSelectorToggler"
  >
    <app-table-column-selector-panel
      [columnGroups]="columnsDefinition"
      [selectedColumns]="selectedColumns"
      (cancel)="onCancel()"
      (selectedColumnsChange)="onSelectedColumnsChange($event)"
    >
    </app-table-column-selector-panel>
  </div>
</div>
