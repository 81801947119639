import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Event, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { RoutingService } from './routing.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService implements OnDestroy {
  private readonly gtmId: string;
  private subscriptions: Subscription;

  constructor(
    private routingService: RoutingService,
    private appConfigService: AppConfigService,
    private router: Router,
  ) {
    this.gtmId = this.appConfigService.getGoogleTagManagerId();
    this.subscriptions = new Subscription();
  }

  private isGtmConfigured(): boolean {
    return typeof this.gtmId === 'string' && this.gtmId.length > 0;
  }

  private pushOnDataLayer(obj: unknown): void {
    const dataLayer: any = this.getDataLayer();
    dataLayer.push(obj);
  }

  private getDataLayer(): any {
    (window as any).dataLayer = (window as any).dataLayer || [];

    return (window as any).dataLayer;
  }

  private loadGoogleTagManager(): void {
    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const gtmScript: HTMLScriptElement = document.createElement('script');

    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${this.gtmId}`;

    document.head.insertBefore(gtmScript, document.head.firstChild);

    const ifrm: HTMLIFrameElement = document.createElement('iframe');

    ifrm.src = `//www.googletagmanager.com/ns.html?id=${this.gtmId}`;
    ifrm.style.width = '0';
    ifrm.style.height = '0';
    ifrm.style.display = 'none';
    ifrm.style.visibility = 'hidden';

    const noscript: HTMLElement = document.createElement('noscript');

    noscript.id = 'GTMiframe';
    noscript.appendChild(ifrm);

    document.body.insertBefore(noscript, document.body.firstChild);
  }

  private processEvent(value: Event): void {
    if (value instanceof NavigationEnd) {
      this.subscriptions.add(
        this.routingService.pageTitle$.pipe(first()).subscribe((pageTitle: string) => {
          const gtmTag: { event: string; pageName: string } = {
            event: 'page',
            pageName: pageTitle || value.url,
          };
          this.pushOnDataLayer(gtmTag);
        }),
      );
    }
  }

  public initialiseGoogleTagManager(): void {
    if (this.isGtmConfigured() === false) {
      return;
    }

    this.loadGoogleTagManager();
    this.router.events.forEach(this.processEvent.bind(this));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
