import { Component, Input } from '@angular/core';
import { DsChipColorVariant } from '@levelaccess/design-system';

import { BaseCellComponent, ICellConfig } from '../base-cell/base-cell.component';

export interface IDSChipCellConfig extends ICellConfig {
  chipColorVariant: DsChipColorVariant;
}

@Component({
  selector: 'table-cell-ds-chip',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: ` <ds-chip #ref [text]="config.text" [colorVariant]="config.chipColorVariant"></ds-chip> `,
})
export class DSChipCellComponent extends BaseCellComponent {
  @Input() public config: IDSChipCellConfig;
}
