import { Component, Input } from '@angular/core';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';

import { ClipboardUtilityService } from '../../services/clipboard-utility.service';

@Component({
  selector: 'app-code-block',
  templateUrl: './code-block.component.html',
  styleUrls: ['./code-block.component.scss'],
})
export class CodeBlockComponent {
  @Input() public code: string;
  @Input() public copyButtonAriaLabel: string;

  constructor(private clipboardUtilityService: ClipboardUtilityService) {}

  public get microButtonVariant(): DsButtonVariants {
    return DsButtonVariants.microAction;
  }

  public get copyIcon(): Icons {
    return Icons.Copy;
  }

  public copyCodeToClipboard(): void {
    this.clipboardUtilityService.copyTextToClipboard(this.code);
  }
}
