export enum $workspaceFindingCount {
  _id = '_id',
  workspaceId = 'workspaceId',
  toolName = 'toolName',
  computedDate = 'computedDate',
  remediationProgress = 'remediationProgress',
  mostCompromisedDigitalProperties = 'mostCompromisedDigitalProperties',
  previousData = 'previousData',
  mostViolatedSuccessCriteria = 'mostViolatedSuccessCriteria',
  countByActiveDigitalProperty = 'countByActiveDigitalProperty',
  agingUnresolvedIssues = 'agingUnresolvedIssues',
  a11yPolicyThresholds = 'a11yPolicyThresholds',
  agingUnresolvedIssuesBySeverity = 'agingUnresolvedIssuesBySeverity',
  totalUnresolvedIssuesBySeverity = 'totalUnresolvedIssuesBySeverity',
}
