import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import { observeOn } from 'rxjs/operators';

interface Channel {
  subject: Subject<string>;
}

interface Channels {
  [key: string]: string;
}

export enum MessageBusChannels {
  tooltipShow = 'tooltipShow',
  tooltipHide = 'tooltipHide',
}

@Injectable({
  providedIn: 'root',
})
export class MessageBusService {
  private channels: { [channelName: string]: Channel };
  private channelsNames: Channels;

  constructor() {
    this.initChannels();
  }

  private buildChannelsName(): void {
    for (const channel in MessageBusChannels) {
      this.channelsNames[channel] = channel;
    }
  }

  private initChannel(channelName: string): void {
    const channel: Channel = {
      subject: new Subject<any>(),
    };

    if (typeof this.channels[channelName] === 'object') {
      throw new Error('[MessageBusService.to] channel "' + channel + '" does not exists.');
    }

    this.channels[channelName] = channel;
  }

  private initChannels(): void {
    this.channels = {};
    this.channelsNames = {};

    this.buildChannelsName();
    Object.keys(this.channelsNames).forEach(this.initChannel.bind(this));
  }

  public removeChannel(channel: string): void {
    delete this.channels[channel];
  }

  public from(channel: string): Observable<any> {
    if (typeof this.channels[channel] === 'undefined') {
      throw new Error('[MessageBusService] from, channel "' + channel + '" does not exists.');
    }

    // Keep async scheduler to preserve order of events for observers
    return this.channels[channel].subject.asObservable().pipe(observeOn(asyncScheduler));
  }

  public to(channel: string, data: any): void {
    if (typeof this.channels[channel] === 'undefined') {
      throw new Error('[MessageBusService.to] channel "' + channel + '" does not exists.');
    }

    this.channels[channel].subject.next(data);
  }
}
