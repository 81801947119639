import { ITableConfig, ITableRow, SortEvent } from '../components/table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../translate/translate.service';
import { $sortingOrder } from '../../../shared/constants/sort';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

export abstract class BasePaginatedTableController<T> {
  public entriesPerPage: number;
  public availableEntriesPerPage: number[];
  public page: number;
  public paginatedData: ITableRow<T>[];
  public paginationLabel: string;
  public dataLength: number;
  protected tableData: ITableRow<T>[];

  protected rawData: T[];
  public tableConfig: ITableConfig;
  protected translateService: TranslateService;

  protected constructor(translateService: TranslateService, entriesPerPageAmount: number[]) {
    this.translateService = translateService;
    this.availableEntriesPerPage = entriesPerPageAmount;
    this.page = 1;
    this.entriesPerPage = entriesPerPageAmount[0];
    this.rawData = [];
    this.tableData = [];
    this.dataLength = 0;
  }

  protected getPaginationLabel(page: number, entriesPerPage: number, total: number): string {
    return this.translateService.instant('n_of_t', [
      `${Math.min((page - 1) * entriesPerPage + 1, total)} - ${Math.min(page * entriesPerPage, total)}`,
      String(total),
    ]);
  }

  protected updatePaginatedData(): void {
    this.paginatedData = SharedCommonUtility.getPaginatedItems(this.tableData, this.page, this.entriesPerPage);
    this.paginationLabel = this.getPaginationLabel(this.page, this.entriesPerPage, this.tableData.length);
  }

  protected sortFunc(direction: $sortingOrder, aValue: string | number, bValue: string | number): number {
    let ascending: number = 0;
    if (direction === $sortingOrder.all) {
      return ascending;
    }
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      ascending = aValue - bValue;
    } else {
      ascending = String(aValue).localeCompare(String(bValue), 'en-US', { numeric: true, sensitivity: 'base' });
    }

    return direction === $sortingOrder.asc ? ascending : -ascending;
  }

  public onEntriesPerPageChange(value: number): void {
    this.entriesPerPage = value;
    this.updatePaginatedData();
  }

  public onPageChange(value: number): void {
    this.page = value;
    this.updatePaginatedData();
  }

  protected abstract filterInitialData(rawData: T[]): T[];
  protected abstract initialSort(): void;
  protected abstract toTableData(rawData: T[]): ITableRow[];
  protected abstract sortData(column: string, direction: $sortingOrder, a: T, b: T): number;

  public setInitialData(rawData: T[]): void {
    this.rawData = this.filterInitialData(rawData);
    this.initialSort();
    this.tableData = this.toTableData(this.rawData);
    this.dataLength = this.tableData.length;
    this.updatePaginatedData();
  }

  public onTableSort({ column, direction }: SortEvent): void {
    const sortFunc = (a: T, b: T): number => {
      return this.sortData(column, direction, a, b);
    };

    if (direction === $sortingOrder.all) {
      this.tableData = this.toTableData(this.rawData);
    } else {
      this.tableData = this.toTableData([...this.rawData].sort(sortFunc));
    }

    this.updatePaginatedData();
  }
}
