export enum Api {
  academy = 'academy',
  account = 'account',
  accounts = 'accounts',
  activation_link = 'activation-link',
  active = 'active',
  active_scans = 'active-scans',
  activity = 'activity',
  activityLogs = 'activityLogs',
  add = 'add',
  add_scan_points = 'add-scan-points',
  admin = 'admin',
  advanced_scan = 'advanced-scan',
  aggregated_report = 'aggregated-report',
  ai = 'ai',
  all = 'all',
  all_ids = 'all_ids',
  amp_api_key = 'amp-api-key',
  monitoring_analytics = 'monitoring-analytics',
  api = 'api',
  archive = 'archive',
  assign_users = 'assign_users',
  attachmentId = 'attachmentId',
  attachments = 'attachments',
  attribution = 'attribution',
  archivable = 'archivable',
  auditType = 'auditType',
  audit_rules = 'audit-rules',
  audits = 'audits',
  auth = 'auth',
  authenticated = 'authenticated',
  automation = 'automation',
  available = 'available',
  avatar = 'avatar',
  bulk = 'bulk',
  cancel = 'cancel',
  category = 'category',
  callback = 'callback',
  categories = 'categories',
  changePassword = 'change-password',
  changeEmails = 'change-emails',
  chat = 'chat',
  clientSearchTerm = 'clientSearchTerm',
  clients = 'clients',
  commentId = 'commentId',
  comments = 'comments',
  company = 'company',
  component_report = 'component-report',
  config = 'config',
  configureEntities = 'configureEntities',
  configureFields = 'configFields',
  configureJson = 'configJson',
  console = 'console',
  consumer_key = 'consumer-key',
  cookie_policy = 'cookie-policy',
  connect_browser_extension = 'connect-browser-extension',
  count = 'count',
  countries = 'countries',
  create = 'create',
  createDesignRule = 'create-design-rule',
  createWorkspace = 'create-workspace',
  createNewPassword = 'createNewPassword',
  createToken = 'createToken',
  createOAuth2AuthorizationUrl = 'createOAuth2AuthorizationUrl',
  create_new_password = 'create-new-password',
  conformance = 'conformance',
  current = 'current',
  custom_audit_rules = 'custom-audit-rules',
  custom_severities = 'custom-severities',
  dashboard = 'dashboard',
  data = 'data',
  dataId = 'dataId',
  deactivate = 'deactivate',
  default = 'default',
  deletable = 'deletable',
  designReviewProjects = 'design-review-projects',
  designReviewProjectId = 'designReviewProjectId',
  designReviewPages = 'design-review-pages',
  designReviewPageId = 'designReviewPageId',
  designReviewFindings = 'design-review-findings',
  designReviewFindingId = 'designReviewFindingId',
  designReviewFindingKey = 'designReviewFindingKey',
  designReviewFindingCategory = 'designReviewFindingCategory',
  designReviewFindingCommentId = 'designReviewFindingCommentId',
  designReviewFindingType = 'designReviewFindingType',
  designReviewRules = 'design-review-rules',
  designReviewRuleId = 'designReviewRuleId',
  details = 'details',
  digitalPropertyId = 'digitalPropertyId',
  digital_properties = 'digital-properties',
  digital_property = 'digital-property',
  digital_property_type = 'digital-property-type',
  digitalproperty = 'digitalproperty',
  digital_property_limit_state = 'digital-property-limit-state',
  digital_property_automated_flow_limit = 'digital-property-automated-flow-limit',
  digitalPropertyMetrics = 'digital-property-metrics',
  dismiss = 'dismiss',
  documentId = 'documentId',
  documentation = 'documentation',
  documents = 'documents',
  domain = 'domain',
  download = 'download',
  downloadToken = 'downloadToken',
  edit = 'edit',
  editDesignRule = 'edit-design-rule',
  entities = 'entities',
  email = 'email',
  errors = 'errors',
  failedTasks = 'failed-tasks',
  evaluation_rules = 'evaluation-rules',
  evaluations = 'evaluations',
  event = 'event',
  existingKeyValues = 'existing-key-values',
  exists = 'exists',
  export = 'export',
  exclude_monitoring = 'exclude_monitoring',
  exclude_history = 'exclude_history',
  ext = 'ext',
  externalId = 'externalId',
  file = 'file',
  fileId = 'fileId',
  files = 'files',
  findings = 'findings',
  findingId = 'findingId',
  finishAuthorization = 'finishAuthorization',
  flawId = 'flawId',
  flaws = 'flaws',
  forbidden = '403',
  generate_api_key = 'generate-api-key',
  apiKeyName = 'apiKeyName',
  generate_browser_extension_key = 'generate-browser-extension-key',
  groupId = 'groupId',
  groups = 'groups',
  guidelines = 'guidelines',
  hasHistoryUpdates = 'has-history-updates',
  home = 'home',
  id = 'id',
  insights = 'insights',
  instance = 'instance',
  instanceId = 'instanceId',
  integrations = 'integrations',
  internal_users = 'internal-users',
  isDuplicated = 'is-duplicated',
  issueKey = 'issueKey',
  issueType = 'issueType',
  issueTypes = 'issueTypes',
  issueTypeId = 'issueTypeId',
  issue_tracking = 'issue-tracking',
  issues = 'issues',
  issueIntegration = 'issueIntegration',
  issueIntegrationConnectionId = 'issueIntegrationConnectionId',
  issueIntegrationStatuses = 'issue-integration-statuses',
  issue_integration_connection = 'issue-integration-connection',
  issue_integration_connection_config = 'issue-integration-connection-config',
  issue_integration_status_mapping = 'issue-integration-status-mapping',
  issue_integration_projects = 'issue-integration-projects',
  issueIntegrationProjectId = 'issueIntegrationProjectId',
  jobIndustries = 'job-industries',
  jobTitles = 'job-titles',
  keyId = 'keyId',
  keys = 'keys',
  latest_scan = 'latest-scan',
  latest_scans = 'latest-scans',
  list = 'list',
  libraries = 'libraries',
  libraryId = 'libraryId',
  library = 'library',
  locale_id = 'locale',
  locales = 'locales',
  login = 'login',
  loginOrganizationRedirection = 'login-organization-redirection',
  logout = 'logout',
  logs = 'logs',
  manage_roles = 'manage-roles',
  manage_users = 'manage-users',
  manual = 'manual',
  manualAuditIssue = 'manualAuditIssue',
  manualAuditIssueId = 'manualAuditIssueId',
  manualEvaluationId = 'manualEvaluationId',
  manual_audit = 'manual-audit',
  testGuides = 'test-guides',
  testGuideId = 'testGuideId',
  manual_audit_issues_by_severity = 'manual-audit-issues-by-severity',
  manual_audit_top_wcag = 'manual-audit-top-wcag',
  manual_evaluations = 'manual-evaluations',
  manual_requests = 'manual-requests',
  manual_request_id = 'manual_request_id',
  manual_results = 'manual-results',
  manual_upload_file = 'manual-upload-file',
  markAsViewed = 'markAsViewed',
  mark_rule_as_false_positive = 'mark-rule-as-false-positive',
  master_library = 'master-library',
  master_libraries = 'master-libraries',
  matchComponent = 'matchComponent',
  matchComponentId = 'matchComponentId',
  match_components = 'match-components',
  me = 'me',
  mobile_apps = 'mobile-apps',
  mobile_app_report = 'mobile-app-report',
  mobile_scans = 'mobile-scans',
  mobile_testing_emulator = 'mobile-testing-emulator',
  monitoring = 'monitoring',
  monitoringSettings = 'monitoring-settings',
  move = 'move',
  my = 'my',
  myTasks = 'my-tasks',
  names = 'names',
  next_scheduled_scan = 'next-scheduled-scan',
  not_found = '404',
  operating_systems = 'operating-systems',
  options = 'options',
  organization = 'organization',
  organizations = 'organizations',
  overviewData = 'overview-data',
  metrics = 'metrics',
  packageId = 'packageId',
  packageInstanceId = 'packageInstanceId',
  packages = 'packages',
  packageAddons = 'packageAddons',
  pageId = 'pageId',
  pages = 'pages',
  monitoring_pages = 'monitoring-pages',
  openapi = 'openapi',
  page_summary = 'page-summary',
  password_reset = 'password-reset',
  performance = 'performance',
  portfolio = 'portfolio',
  populateMonitoring = 'populateMonitoring',
  populateRemediationProgress = 'populateRemediationProgress',
  possible_target_statuses = 'possible_target_statuses',
  preferences = 'preferences',
  previous = 'previous',
  previous_tokens_info = 'previous-tokens-info',
  profile = 'profile',
  projectId = 'projectId',
  projects = 'projects',
  pull = 'pull',
  push = 'push',
  public = 'public',
  quick_monitoring = 'quick-monitoring',
  quick_scan = 'quick-scan',
  rating = 'rating',
  reauthorize_connection = 'reauthorize-connection',
  refreshAccessToken = 'refreshAccessToken',
  regenerate_auth_url = 'regenerate-auth-url',
  registered = 'registered',
  remaining = 'remaining',
  remediatedDocumentId = 'remediatedDocumentId',
  remove = 'remove',
  removeRemediation = 'removeRemediation',
  revokeRemediationRequest = 'revokeRemediationRequest',
  report = 'report',
  reports = 'reports',
  requestRemediation = 'requestRemediation',
  configurableFields = 'configurable-fields',
  requiredFields = 'required-fields',
  rerun = 'rerun',
  resendActivationLink = 'resend-activation-link',
  resendVisitorActivationLink = 'resend-visitor-activation-link',
  resend_invite = 'resend-invite',
  resetPassword = 'resetPassword',
  resourceId = 'resourceId',
  resources = 'resources',
  restore = 'restore',
  results = 'results',
  resume = 'resume',
  resync = 'resync',
  validate = 'validate',
  role = 'role',
  roles = 'roles',
  rule = 'rule',
  ruleHistory = 'rule-history',
  ruleHistorySearch = 'rule-history-search',
  ruleId = 'ruleId',
  rule_ids = 'rule-ids',
  rules = 'rules',
  run_automated_scan = 'run-automated-scan',
  startDocumentScan = 'startDocumentScan',
  downloadReport = 'download-report',
  downloadTestReport = 'download-test-report',
  run_scan = 'run-scan',
  scan = 'scan',
  scanId = 'scanId',
  secure_resources = 'secure-download',
  setRemediation = 'set-remediation',
  scanOptionId = 'scanOptionId',
  scanSummary = 'scanSummary',
  scanTagId = 'scanTagId',
  scan_document = 'scan-document',
  scan_id = 'scanId',
  scan_results = 'scan-results',
  scan_session = 'scanSession',
  scan_tags = 'scan-tags',
  scans = 'scans',
  page_scans = 'page-scans',
  scans_count = 'scans-count',
  scannableDocumentId = 'scannableDocumentId',
  scannableDocumentRules = 'scannableDocumentRules',
  scannableDocumentRuleId = 'scannableDocumentRuleName',
  scannableDocuments = 'scannableDocuments',
  scannableDocument = 'scannableDocument',
  scim = 'scim',
  scim_api_key = 'scim-api-key',
  scim_config = 'scim-config',
  supplementalScan = 'supplementalScan',
  remediated = 'remediated',
  scheduled = 'scheduled',
  screens = 'screens',
  screenshot = 'screenshot',
  screenshots = 'screenshots',
  scope = 'scope',
  search = 'search',
  severity = 'severity',
  select_language = 'select-language',
  send_email = 'send-email',
  server_api_performance = 'server-api-performance',
  settings = 'settings',
  severities = 'severities',
  shortLink = 'shortLink',
  shortLinks = 'short-links',
  short_link = 'short-link',
  signup = 'signup',
  single_report = 'single-report',
  ssoLink = 'sso-link',
  ssoConfig = 'sso-config',
  staffGroups = 'staff-groups',
  scan_point_summary = 'scan-point-summary',
  scan_point_report = 'scan-point-report',
  status = 'status',
  statuses = 'statuses',
  synchronizedObjects = 'synchronized-objects',
  stream = 'stream',
  success = 'success',
  support = 'support',
  supported_locales = 'supported-locales',
  swagger = 'swagger',
  summary = 'summary',
  summaryKey = 'summaryKey',
  sync = 'sync',
  tags = 'tags',
  taskId = 'taskId',
  tasks = 'tasks',
  salesforce = 'salesforce',
  tenants = 'tenants',
  tenantName = 'tenantName',
  tenantsMembership = 'tenants-membership',
  tenantId = 'tenantId',
  term = 'term',
  test_automation = 'test-automation',
  testAutomationSdks = 'sdks',
  testAutomationJavaSdk = 'java',
  testAutomationJavaMobileSdk = 'java-mobile',
  testAutomationCSharpSdk = 'csharp',
  testAutomationJavascriptSdk = 'javascript',
  testAutomationPythonSdk = 'python',
  testAutomationRubySdk = 'ruby',
  toggle_rule = 'toggle-rule',
  token = 'token',
  tool = 'tool',
  tools = 'tools',
  toolName = 'toolName',
  total = 'total',
  tracking = 'tracking',
  training = `training`,
  translations = 'translations',
  type = 'type',
  types = 'types',
  ui_tools = 'ui-tools',
  unassigned_members = 'unassigned_members',
  unassigned_users = 'unassigned_users',
  unsubscribe = 'unsubscribe',
  updateEmails = 'update-emails',
  upload = 'upload',
  uploadRemediation = 'upload-remediation',
  uploadRemediationResult = 'upload-remediation-result',
  url = 'url',
  urls = 'urls',
  Users = 'Users',
  user = 'user',
  userChangeHistory = 'userChangeHistory',
  userGroups = 'user-groups',
  userId = 'userId',
  userIds = 'userIds',
  user_data = 'user-data',
  user_flows = 'user-flows',
  user_roles = 'user-roles',
  guided_user_flows = 'guided-user-flows',
  automated_user_flows = 'automated-user-flows',
  user_flow_recordings = 'user-flow-recordings',
  user_flow_templates = 'user-flow-templates',
  userFlowTemplateId = 'userFlowTemplateId',
  userFlowTemplateName = 'userFlowTemplateName',
  userFlowId = 'userFlowId',
  user_id = 'user_id',
  users = 'users',
  userway = 'userway',
  userway_site_limit_state = 'userway_site_limit_state',
  userway_sso_link = 'userway-sso-link',
  globalUsers = 'global-users',
  verifyEmail = 'verifyEmail',
  verifyPreviousPassword = 'verify-previous-password',
  verifyPreviousPasswordVisitor = 'verify-previous-password-visitor',
  verifyResetPasswordToken = 'verifyResetPasswordToken',
  verifyToken = 'verify-token',
  verify_email = 'verify-email',
  v0 = 'v0',
  videoId = `videoId`,
  videos = 'videos',
  view = 'view',
  viewDesignRule = 'view-design-rule',
  view_scan_analytics = 'view-scan-analytics',
  webhooks = 'webhooks',
  workspace = 'workspace',
  workspaceId = 'workspaceId',
  workspaceName = 'workspaceName',
  workspaceUser = 'workspaceUser',
  workspaceUserId = 'workspaceUserId',
  workspaces = 'workspaces',
  designReviews = 'design-reviews',
  designEvaluations = 'design-evaluations',
  xpath = 'xpath',
  notifications = 'notifications',
  migrate = 'migrate',
  assign = 'assign',
  withdraw_assignee = 'withdraw-assignee',
  setup_connection = 'setup-connection',
  azure = 'azure',
  jira = 'jira',
  asana = 'asana',
  healthCheck = 'healthcheck',
  ping = 'ping',
  important = 'important',
  assignable = 'assignable',
  mfa = 'mfa',
  labels = 'labels',
  componentId = 'componentId',
  overview = 'overview',
  pdfPagesTestState = 'pdf-pages-test-state',
  security = 'security',
  newSecurity = 'new-security',
  userManagement = 'user-management',
  accessibilityPolicies = 'accessibility-policies',
  designReviewHighlights = 'design-review-highlights',
  activate = 'activate',
  designReviewHighlightId = 'designReviewHighlightId',
  components = 'components',
  pdf = 'pdf',
  pdfDiscovered = 'pdf-discovered',
  copy = 'copy',
  scannableDocumentPageId = 'scannableDocumentPageId',
  scannableDocumentPage = 'scannableDocumentPage',
  contentTags = 'content-tags',
  availableContentTags = 'avaliable-content-tags',
  availableSignupFields = 'available-signup-fields',
  data_snapshots = 'data-snapshots',
  finishOAuth2 = 'finishOAuth2',
  validateTasks = 'validate-tasks',
  websitesAndApps = 'websites-apps',
  environments = 'environments',
  jobs = 'jobs',
  continuum = 'continuum',
  continuumProjectId = 'continuumProjectId',
  incomingWebhooks = 'incoming-webhooks',
  snapshots = 'snapshots',
  taskProjectId = 'taskProjectId',
  cloudsmithToken = 'cloudsmith-token',
  trackedProjects = 'tracked-projects',
  requests = 'requests',
  recentWorkspaces = 'recent-workspaces',
  conformanceScoreReport = 'conformance-score-report',
  metadata = 'metadata',
  auditedDocuments = 'audited-documents',
  successCriteria = 'successCriteria',
  severityKey = 'severityKey',
  swapRanks = 'swap-ranks',
  deleteAndReassign = 'delete-and-reassign',
}

export enum ApiQueryOption {
  audit = 'audit',
  audits = 'audits',
  auditType = 'auditType',
  auditTool = 'auditTool',
  avatarToken = 'avatarToken',
  category = 'category',
  clientSearchTerm = 'clientSearchTerm',
  componentId = 'componentId',
  confirmEmailToken = 'confirmEmailToken',
  createdAt = 'createdAt',
  direction = 'direction',
  downloadReport = 'downloadReport',
  downloadToken = 'downloadToken',
  designReviewFindingId = 'designReviewFindingId',
  email = 'email',
  errorXPath = 'errorXPath',
  excludeForPredefinedRoles = 'excludeForPredefinedRoles',
  externalId = 'externalId',
  includeStaffUsers = 'includeStaffUsers',
  filter = 'filter',
  filterLegacyEA = 'filterLegacyEA',
  filterByUserId = 'filterByUserId',
  finding = 'finding',
  flawId = 'flawId',
  format = 'format',
  frequency = 'frequency',
  fromDate = 'fromDate',
  jobStatus = 'jobStatus',
  id = 'id',
  identifier = 'identifier',
  includeDisabled = 'includeDisabled',
  includeStaffGroups = 'includeStaffGroups',
  skipOrganizationAdmin = 'skipOrganizationAdmin',
  includeMe = 'includeMe',
  issueIds = 'issueIds',
  issueType = 'issueType',
  lastUsedWorkspace = 'lastUsedWorkspace',
  languageForScanning = 'languageForScanning',
  level = 'level',
  limit = 'limit',
  linkedPropertyData = 'linkedPropertyData',
  myContacts = 'myContacts',
  name = 'name',
  resultsCompression = 'resultsCompression',
  pageScore = 'pageScore',
  pathRoot = 'pathRoot',
  queryString = 'queryString',
  resourceId = 'resourceId',
  ruleId = 'ruleId',
  ruleDescription = 'ruleDescription',
  salesforceId = 'salesforceId',
  scanUrl = 'scanUrl',
  scanId = 'scanId',
  scanFromDate = 'scanFromDate',
  scanningType = 'scanningType',
  scanToDate = 'scanToDate',
  scanPointId = 'scanPointId',
  skip = 'skip',
  sortByName = 'title',
  sortCreatedAt = 'createdAt',
  sortCreatedBy = 'createdBy',
  sortByDigitalProperty = 'digitalProperty',
  sortBy = 'sortBy',
  sortDirection = 'sortDirection',
  sortOrder = 'sortOrder',
  source = 'source',
  status = 'status',
  severity = 'severity',
  standards = 'standards',
  successCriteria = 'successCriteria',
  switchToWorkspace = 'switchToWorkspace',
  tenantlessOnly = 'tenantlessOnly',
  tool = 'tool',
  toolName = 'toolName',
  toolVersion = 'toolVersion',
  term = 'term',
  totalFinding = 'totalFinding',
  trendDataLength = 'trendDataLength',
  url = 'url',
  urls = 'urls',
  userIds = 'userIds',
  workspace = 'workspace',
  workspaceId = 'workspaceId',
  withSummary = 'with-summary',
  integrationType = 'integrationType',
  lastId = 'lastId',
  fieldName = 'fieldName',
  query = 'query',
  documentIds = 'documentIds',
  origin = 'origin',
  entityLevel = 'entityLevel',
  pages = 'pages',
  pageIds = 'pageIds',
  tenantId = 'tenantId',
  toolId = 'toolId',
  tags = 'tags',
  important = 'important',
  hasScope = 'hasScope',
  oauth2AuthorizationResult = 'oauth2AuthorizationResult',
  authorizationResult = 'authorizationResult',
  archive = 'archive',
  digitalPropertyId = 'digitalPropertyId',
  riskScoreLevel = 'riskScoreLevel',
  scheduledAt = 'scheduledAt',
  showSuccessAlert = 'showSuccessAlert',
  allFields = 'allFields',
  recordingId = 'recordingId',
  regenerate = 'regenerate',
  manualRequestResult = 'manualRequestResult',
}

export enum ApiHeaderOption {
  skipLoader = 'skipLoader',
}

export const ApiQueryOptionValue: Readonly<{ [key: string]: any }> = Object.freeze({
  true: true,
  false: false,
});
