import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { kebabCase, snakeCase } from 'lodash';
import { TranslateService } from '../../../../translate/translate.service';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { $riskScoreLevels } from '../../../../../../shared/constants/risk-metrics';
import { RiskLevelUtility } from '../../../../../../shared/utils/risk-level.utility';

interface IStep {
  label: string;
  id: $riskScoreLevels;
  class: string;
}

@Component({
  selector: 'app-risk-management-status-stepper',
  templateUrl: './risk-management-status-stepper.component.html',
  styleUrls: ['./risk-management-status-stepper.component.scss'],
})
export class RiskManagementStatusStepperComponent implements OnInit, OnChanges {
  public steps: IStep[];
  public level: $riskScoreLevels;

  @Input() public score: number;
  @Output() public onStatusExplanationClicked: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(private translateService: TranslateService) {
    this.steps = [];
  }

  private getLevelText(level: string): string {
    return this.translateService.instant(snakeCase(level));
  }

  public get containsLevel(): boolean {
    return SharedCommonUtility.notNullish(this.level);
  }

  public ngOnInit(): void {
    this.setupLevels();
  }

  private setupLevels(): void {
    this.steps = [
      this.makeStep($riskScoreLevels.behind),
      this.makeStep($riskScoreLevels.needsAttention),
      this.makeStep($riskScoreLevels.onTrack),
      this.makeStep($riskScoreLevels.excelling),
    ];

    this.selectLevel();
  }

  private makeStep(level: $riskScoreLevels): IStep {
    return {
      label: this.getLevelText(level),
      id: level,
      class: kebabCase(level),
    };
  }

  private selectLevel(): void {
    this.level = RiskLevelUtility.getLevel(this.score);
  }

  public ngOnChanges(): void {
    this.setupLevels();
  }

  public isActive(step: IStep): boolean {
    return this.level === step.id;
  }

  public openRiskScoreExplanationModal(ev: Event): void {
    this.onStatusExplanationClicked.next(ev);
  }
}
