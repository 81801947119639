<div class="monitoring-alert-container">
  <button class="btn btn-link alert-button-icon" (click)="showAlertTooltip()">
    <svg role="img" viewBox="0 0 8 8">
      <title>{{ 'label_monitoring_alerts' | translate }}</title>
      <use xlink:href="#bell"></use>
    </svg>
  </button>
  <div *ngIf="displayTooltip" class="monitoring-label-tooltip px-3 py-1 small">
    <strong>{{ 'label_monitoring_alert' | translate }}</strong>
    <div *ngIf="isAlertArray" >
      <p class="margin-bottom-xs" *ngFor="let alertMessage of alerts">{{ alertMessage }}</p>
    </div>
  </div>
</div>
