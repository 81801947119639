<div [formGroup]="form" class="scan-wait-time">
  <app-common-checkbox
    [(ngModel)]="modifyWaitTime"
    (ngModelChange)="onModifyWaitTime($event)"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="disabled"
  >
    <span class="checkbox-label">{{ 'add_wait_time' | translate }}</span>
  </app-common-checkbox>
  <div *ngIf="modifyWaitTime" class="row margin-left">
    <div class="col">
      <div class="row margin-bottom">
        <div class="col margin-left">
          <div class="alert alert-info margin-right" id="wait-time-description">
            {{ 'wait_time_description' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col margin-left">
          <app-common-input
            id="wait-time-sec"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              id: waitTimeControlName,
              field: waitTimeControlName,
              required: true,
              label: 'wait_time_label',
              describedby: 'wait-time-description',
              type: 'number',
            }"
          >
          </app-common-input>
        </div>
      </div>
    </div>
  </div>
</div>
