<div class="row">
  <div class="col-12">
    <div class="card shadow-none py-5">
      <div class="card-body text-center py-5">
        <svg height="80" aria-hidden="true">
          <use xlink:href="#error-page-forbidden"></use>
        </svg>
        <h2 class="mt-5 mb-2 font-weight-300">{{ pageTitle }}</h2>
        <p [innerHTML]="'page_forbidden_text' | translateHtml"></p>
        <a routerLink="/" class="btn btn-primary my-3 py-2 px-4">{{ 'go_back_to_application' | translate }}</a>
      </div>
    </div>
  </div>
</div>
