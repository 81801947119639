import { Component, OnInit, Input, Output, EventEmitter, HostBinding, HostListener, ElementRef } from '@angular/core';

import { ICheckboxTreeNode, ICheckboxTreeConfig } from '../../../interfaces/checkbox-tree.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';

@Component({
  selector: 'app-checkbox-tree-node',
  templateUrl: './checkbox-tree-node.component.html',
  styleUrls: ['./checkbox-tree-node.component.scss'],
})
export class CheckboxTreeNodeComponent<T> implements OnInit {
  @HostBinding('attr.role') role: string = 'treeitem';

  @HostBinding('attr.id') get getId(): string {
    return `${this.treeId}-level${this.level}-node${this.index}`;
  }

  @HostBinding('attr.aria-expanded') get ariaExpanded(): boolean {
    return this.hasChildren ? !this.node.collapsed : null;
  }

  @Input()
  public level: number;

  @Input()
  public index: number;

  @Input()
  public treeId: string;

  @Input()
  public config: ICheckboxTreeConfig;

  @Input()
  public node: ICheckboxTreeNode<T>;

  @Output()
  public nodeChanged: EventEmitter<boolean>;

  constructor(private elementRef: ElementRef) {
    this.nodeChanged = new EventEmitter<boolean>();
  }

  private checkDescendants(node: ICheckboxTreeNode<T>, checked: boolean): void {
    node.checked = checked;
    node.children.forEach((child: ICheckboxTreeNode<T>): void => this.checkDescendants(child, checked));
  }

  private onChildNodeChecked(): void {
    this.node.checked = undefined;

    if (!this.config.autoCheckParent) {
      return;
    }

    const allChildrenChecked: boolean = this.node.children.every((node: ICheckboxTreeNode<T>): boolean => node.checked === true);
    if (allChildrenChecked) {
      this.node.checked = true;
    }
  }

  private onChildNodeUnchecked(): void {
    const hasCheckedDescendant: boolean = this.node.children.some(
      (node: ICheckboxTreeNode<T>): boolean => node.checked === true || SharedCommonUtility.isNullish(node.checked),
    );

    if (hasCheckedDescendant) {
      this.node.checked = undefined;
    } else {
      this.node.checked = false;
    }
  }

  private getNodesDomFromEvent(event: KeyboardEvent): { nodes: HTMLElement[]; currentNodeIndex: number } {
    const tree: HTMLElement = (event.target as HTMLElement).closest('[role="tree"]');
    const nodes: HTMLElement[] = Array.from(tree.querySelectorAll('[role="treeitem"]'));
    const currentNodeIndex: number = nodes.indexOf(this.elementRef.nativeElement);
    return { nodes, currentNodeIndex };
  }

  private getNextNode(event: KeyboardEvent): HTMLElement | null {
    const { nodes, currentNodeIndex } = this.getNodesDomFromEvent(event);
    if (currentNodeIndex < 0 || currentNodeIndex >= nodes.length - 1) {
      return null;
    }

    return nodes[currentNodeIndex + 1];
  }

  private getPreviousNode(event: KeyboardEvent): HTMLElement | null {
    const { nodes, currentNodeIndex } = this.getNodesDomFromEvent(event);
    if (currentNodeIndex < 1) {
      return null;
    }

    return nodes[currentNodeIndex - 1];
  }

  private expandOrGetFirstChildNode(event: KeyboardEvent): HTMLElement | null {
    let focusNode: HTMLElement = null;

    const currentTargetIsNotCheckbox: boolean = (event.target as HTMLElement).tagName !== 'INPUT';
    if (currentTargetIsNotCheckbox) {
      focusNode = this.elementRef.nativeElement;
    }

    if (this.isCollapsed) {
      this.node.collapsed = false;
    } else if (SharedCommonUtility.isNullish(focusNode) && this.hasChildren) {
      focusNode = this.getNextNode(event);
    }

    return focusNode;
  }

  private collapseOrGetParentNode(event: KeyboardEvent): HTMLElement | null {
    let focusNode: HTMLElement = null;

    if (this.isCollapsed || !this.hasChildren) {
      focusNode = this.elementRef.nativeElement.parentElement.closest('[role="treeitem"]');
    }
    this.node.collapsed = true;

    return focusNode;
  }

  private onKeyboardNavigation(event: KeyboardEvent, key: 'up' | 'down' | 'right' | 'left'): void {
    event.stopPropagation();
    event.preventDefault();

    let focusNode: HTMLElement;
    switch (key) {
      case 'up':
        focusNode = this.getPreviousNode(event);
        break;
      case 'down':
        focusNode = this.getNextNode(event);
        break;
      case 'right':
        focusNode = this.expandOrGetFirstChildNode(event);
        break;
      case 'left':
        focusNode = this.collapseOrGetParentNode(event);
        break;

      // no default
    }

    if (SharedCommonUtility.isNullish(focusNode)) {
      return;
    }

    (focusNode.querySelector('input[type="checkbox"]') as HTMLInputElement).focus();
  }

  public get hasChildren(): boolean {
    return SharedCommonUtility.notNullishOrEmpty(this.node.children);
  }

  public get isCollapsed(): boolean {
    return this.node.collapsed;
  }

  public get isIndeterminate(): boolean {
    return SharedCommonUtility.isNullish(this.node.checked);
  }

  public get checked(): boolean {
    return this.node.checked;
  }

  public set checked(value: boolean) {
    this.checkDescendants(this.node, value);
    this.nodeChanged.emit(value);
  }

  public onToggle(): void {
    this.node.collapsed = !this.node.collapsed;
  }

  public onChildNodeChanged(childNode: ICheckboxTreeNode<T>, checked?: boolean): void {
    switch (checked) {
      case true:
        this.onChildNodeChecked();
        break;
      case false:
        this.onChildNodeUnchecked();
        break;
      default:
        this.node.checked = undefined;
        break;
    }

    this.nodeChanged.emit(this.node.checked);
  }

  @HostListener('keydown.arrowdown', ['$event'])
  public onKeyboardArrowDown(event: KeyboardEvent): void {
    this.onKeyboardNavigation(event, 'down');
  }

  @HostListener('keydown.arrowup', ['$event'])
  public onKeyboardArrowUp(event: KeyboardEvent): void {
    this.onKeyboardNavigation(event, 'up');
  }

  @HostListener('keydown.arrowright', ['$event'])
  public onKeyboardArrowRight(event: KeyboardEvent): void {
    this.onKeyboardNavigation(event, 'right');
  }

  @HostListener('keydown.arrowleft', ['$event'])
  public onKeyboardArrowLeft(event: KeyboardEvent): void {
    this.onKeyboardNavigation(event, 'left');
  }

  @HostListener('keydown.enter', ['$event'])
  public onKeyboardEnter(event: KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (!this.hasChildren) {
      this.node.checked = !this.node.checked;
    } else {
      this.node.collapsed = !this.node.collapsed;
    }
  }

  ngOnInit(): void {
    if (this.config.expandAll) {
      this.node.collapsed = false;
    }
  }
}
