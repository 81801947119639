import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { RoutingService } from '../../../services/routing.service';
import { ShortLinkService } from '../../../services/short-link.service';
import { IShortLink } from '../../../../../shared/interfaces/short-link.interface';
import { RestService } from '../../../services/rest.service';
import { Api } from '../../../../../shared/constants/api';
import { LoadErrorHandler } from '../../../services/load-error-handler';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { errorMessagesNames } from '../../../../../shared/constants/errors';
import { shortLinkType } from '../../../../../shared/constants/short-link';

@Component({
  selector: 'app-short-link',
  templateUrl: './short-link.component.html',
  styleUrls: ['./short-link.component.scss'],
})
export class ShortLinkComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription;

  public pageTitle: string;
  public shortLinkNotFound: boolean;

  constructor(
    private routingService: RoutingService,
    private activatedRoute: ActivatedRoute,
    private shortLinkService: ShortLinkService,
    private restService: RestService,
    private loadErrorHandler: LoadErrorHandler,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.pageTitle = '';
    this.subscriptions = new Subscription();
    this.shortLinkNotFound = false;
  }

  private onGetShortLinkSuccess(shortLink: IShortLink): void {
    let redirectPath: string = this.shortLinkService.getRedirectPath(shortLink);

    if (typeof redirectPath === 'string') {
      // downloads a static file and redirects to the /
      if (shortLink.linkType === shortLinkType.auditFindingAttachment) {
        this.restService.download(redirectPath).toPromise().then();
        redirectPath = '/';
      }
      this.router.navigateByUrl(redirectPath).catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    } else {
      console.error(`[${ShortLinkComponent.name}.onGetShortLinkSuccess] unable to get redirect path for short link:`, shortLink);
    }
  }

  private onGetShortLinkError(error: HttpErrorResponse): void {
    this.shortLinkNotFound = true;
    if (error?.error?.app?.name !== errorMessagesNames.ShortLinkNotFound) {
      this.loadErrorHandler.handleError('error_getting_short_link', error, ShortLinkComponent.name);
    }
  }

  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
  }

  public ngAfterViewInit(): void {
    const params: Params = this.activatedRoute.snapshot.params;
    this.subscriptions.add(
      this.restService.getShortLink(params[Api.shortLink]).subscribe({
        next: this.onGetShortLinkSuccess.bind(this),
        error: this.onGetShortLinkError.bind(this),
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
