import { IScanViewport } from '../interfaces/scan-viewport.interface';
import { $scanViewport, ScanViewportType } from '../constants/scan-viewport';
import { SharedCommonUtility } from './common.utility';

export class ScanViewport implements IScanViewport {
  public readonly [$scanViewport.viewportType]: ScanViewportType;
  public readonly [$scanViewport.height]: number;
  public readonly [$scanViewport.width]: number;

  constructor(type: ScanViewportType, height: number, width: number) {
    this.viewportType = type;
    this.height = height;
    this.width = width;
  }

  private static isScanViewport(_viewport: any): _viewport is IScanViewport {
    if (typeof _viewport !== 'object' || _viewport === null) {
      return false;
    }

    return $scanViewport.viewportType in _viewport && $scanViewport.height in _viewport && $scanViewport.width in _viewport;
  }

  public static [Symbol.hasInstance](obj: any): boolean {
    return ScanViewport.isScanViewport(obj);
  }

  public static getMobileViewport(): ScanViewport {
    return new ScanViewport(ScanViewportType.mobile, 667, 375);
  }

  public static getTabletViewport(): ScanViewport {
    return new ScanViewport(ScanViewportType.tablet, 1024, 768);
  }

  public static getDesktopViewport(): ScanViewport {
    return new ScanViewport(ScanViewportType.desktop, 1080, 1920);
  }

  public static getDefaultPuppeteerViewport(): ScanViewport {
    return new ScanViewport(ScanViewportType.desktop, 600, 800);
  }

  public static getScanViewport(scanViewport: IScanViewport | undefined): ScanViewport {
    if (typeof scanViewport === 'undefined') {
      return ScanViewport.getDefaultPuppeteerViewport();
    }

    return scanViewport;
  }

  public static getViewportFromString(dimensions: string): ScanViewport {
    if (SharedCommonUtility.isNullishOrEmpty(dimensions)) {
      return null;
    }

    if (ScanViewport.isCustomViewportStringValid(dimensions) === false) {
      return null;
    }

    const [width, height] = dimensions.split('x').map((val: string) => Number(val));
    return new ScanViewport(ScanViewport.SCAN_VIEWPORT_DIMENSIONS[dimensions] || ScanViewportType.custom, height, width);
  }

  public static isCustomViewportStringValid(dimensions: string): boolean {
    if (SharedCommonUtility.isNullishOrEmpty(dimensions)) {
      return false;
    }

    return /^\d+x\d+$/.test(dimensions) === true;
  }

  private static SCAN_VIEWPORT_DIMENSIONS: Readonly<Record<string, ScanViewportType>> = Object.freeze({
    '1920x1080': ScanViewportType.desktop,
    '768x1024': ScanViewportType.tablet,
    '375x667': ScanViewportType.mobile,
  });
}
