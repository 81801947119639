import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDigitalPropertyMonitoringDetails } from '../../../../../../../shared/interfaces/digital-property.interface';
import { $digitalPropertyMonitoring, MonitoringDiscoveryMethod } from '../../../../../../../shared/constants/digital-properties';
import { TranslateService } from '../../../../../translate/translate.service';
import { scanAuthenticationType, standardNameAndLevel } from '../../../../../../../shared/constants/scanning';
import {
  AuditToolCommonNames,
  AuditToolCommonNamesToAuditToolNames,
  AuditToolNames,
} from '../../../../../../../shared/constants/audit-tool';
import { $scan } from '../../../../../../../shared/constants/scan';
import { $scanViewport } from '../../../../../../../shared/constants/scan-viewport';
import { IScanViewport } from '../../../../../../../shared/interfaces/scan-viewport.interface';
import { $advancedSetupStep } from '../../constants/monitoring-advanced-setup';
import { ExcludePagesOperation } from '../../../../../../../shared/interfaces/scan.interface';
import { Icons } from '../../../../../shared/eap-icons.component';
import { EapColors } from '../../../../../shared/eap-colors';
import { MonitoringDateUtils } from '../../../utils/monitoring-date-utils';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';

@Component({
  selector: 'app-monitoring-review-step',
  templateUrl: './monitoring-review-step.component.html',
  styleUrls: ['monitoring-review-step.component.scss'],
})
export class MonitoringReviewStepComponent {
  @Input()
  public monitoring: IDigitalPropertyMonitoringDetails;

  @Input()
  public stepNumber: number;

  @Output()
  public onGotoStep: EventEmitter<number>;

  @Input()
  public defaultTool: AuditToolCommonNames;

  public $advancedSetupStep: typeof $advancedSetupStep;
  public MonitoringDiscoveryMethod: typeof MonitoringDiscoveryMethod;
  public scanAuthenticationType: typeof scanAuthenticationType;
  public $digitalPropertyMonitoring: typeof $digitalPropertyMonitoring;
  public $scan: typeof $scan;
  public Icons: typeof Icons;
  public EapColors: typeof EapColors;

  constructor(private translateService: TranslateService) {
    this.onGotoStep = new EventEmitter<number>();
    this.$advancedSetupStep = $advancedSetupStep;
    this.MonitoringDiscoveryMethod = MonitoringDiscoveryMethod;
    this.scanAuthenticationType = scanAuthenticationType;
    this.$digitalPropertyMonitoring = $digitalPropertyMonitoring;
    this.$scan = $scan;
    this.Icons = Icons;
    this.EapColors = EapColors;
  }

  public onEditStep(step: $advancedSetupStep): void {
    this.onGotoStep.emit(step);
  }

  public get discoveryMethod(): MonitoringDiscoveryMethod {
    if (this.monitoring[$digitalPropertyMonitoring.pagesToMonitor]?.length > 0) {
      return MonitoringDiscoveryMethod.listOfPages;
    }

    return this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.crawlDeepLevel]
      ? MonitoringDiscoveryMethod.crawl
      : MonitoringDiscoveryMethod.sitemap;
  }

  public get containsEmailAlerts(): boolean {
    return (
      SharedCommonUtility.notNullish(this.monitoring?.alerts) &&
      (!Array.isArray(this.monitoring.alerts) || this.monitoring.alerts.length > 0)
    );
  }

  public get testingTool(): AuditToolNames {
    return AuditToolCommonNamesToAuditToolNames[this.defaultTool];
  }

  public get conformanceLevel(): standardNameAndLevel {
    return this.monitoring[$digitalPropertyMonitoring.conformanceLevel];
  }

  public get scanViewport(): string {
    const scanViewPort: IScanViewport = this.monitoring[$digitalPropertyMonitoring.viewport];
    const viewportType: string = this.translateService.instant(`scan_viewport_${scanViewPort[$scanViewport.viewportType]}`);
    return this.translateService.instant('scan_viewport_value', [
      viewportType,
      scanViewPort.width.toString(),
      scanViewPort.height.toString(),
    ]);
  }

  public get hasIgnoreUrlEndings(): boolean {
    return (
      this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.skipHashes] ||
      this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.skipQueryParams]
    );
  }

  public get ignoredEndings(): string {
    const ignoredEndings: string[] = [];
    if (this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.skipHashes]) {
      ignoredEndings.push('"#"');
    }
    if (this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.skipQueryParams]) {
      ignoredEndings.push('"?"');
    }

    return ignoredEndings.map((value: string): string => this.translateService.instant('ignore_endings', value)).join(', ');
  }

  public get hasExcludedPages(): boolean {
    return (
      this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.excludeUrlOperations]?.length > 0 ||
      this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.excludeUrls]?.length > 0
    );
  }

  public get excludedPages(): string {
    if (this.monitoring[$digitalPropertyMonitoring.crawler]?.[$scan.excludeUrlOperations]?.length > 0) {
      return this.monitoring[$digitalPropertyMonitoring.crawler][$scan.excludeUrlOperations]
        .map((value: ExcludePagesOperation, index: number): string => {
          const operation: string = this.translateService.instant(`label_advanced_search_operation_${value}`);
          return `${operation} "${this.monitoring[$digitalPropertyMonitoring.crawler][$scan.excludeUrls][index]}"`;
        })
        .join(', ');
    }

    return this.monitoring[$digitalPropertyMonitoring.crawler][$scan.excludeUrls]
      .map((value: string): string => `"${value}"`)
      .join(', ');
  }

  public get hasAuthentication(): boolean {
    switch (this.monitoring[$digitalPropertyMonitoring.scanAuthenticationType]) {
      case scanAuthenticationType.basic_authentication:
      case scanAuthenticationType.secure_web_authentication:
        return true;
      default:
        return false;
    }
  }

  public get hasHeaders(): boolean {
    return this.monitoring[$digitalPropertyMonitoring.headers]?.length > 0;
  }

  public get scheduleInfo(): string {
    return MonitoringDateUtils.getScheduleMessageString(
      this.translateService,
      true,
      this.monitoring[$digitalPropertyMonitoring.occurrence],
      this.monitoring[$digitalPropertyMonitoring.startAt],
    );
  }

  public get hasPopupButtonSelector(): boolean {
    return SharedCommonUtility.notNullishOrEmpty(this.monitoring[$digitalPropertyMonitoring.handlePopupsSelector]);
  }

  public get popupButtonSelector(): string {
    return this.monitoring[$digitalPropertyMonitoring.handlePopupsSelector];
  }

  public get hasWaitTime(): boolean {
    return SharedCommonUtility.notNullishOrEmpty(this.monitoring[$digitalPropertyMonitoring.waitTimeSec]);
  }

  public get waitTime(): number {
    return this.monitoring[$digitalPropertyMonitoring.waitTimeSec];
  }
}
