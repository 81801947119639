import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-spinner',
  templateUrl: './common-spinner.component.html',
  styleUrls: ['./common-spinner.component.scss'],
})
export class CommonSpinnerComponent {
  /**
   * Specify width and height in pixels
   */
  @Input() public size: number;

  constructor() {
    this.size = 32;
  }
}
