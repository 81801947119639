<app-ngb-table
  [config]="tableConfig"
  [data]="tableData$ | async"
  [allSelected]="allSelected$ | async"
  (onSelectAll)="onSelectAll($event)"
>
</app-ngb-table>

<ds-pagination
  [ariaLabel]="'admin_portal_groups_table_pagination' | translate"
  [page]="page$ | async"
  [collectionSize]="total$"
  (pageChange)="onPageChange($event)"
>
</ds-pagination>
