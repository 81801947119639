import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

import { NotificationPosition, NotificationType } from '../models/notification.model';
import { CommonUtility } from '../utility/common.utility';
import { INotification, INotificationActionConfig } from '../interfaces/notification.interface';

@Injectable()
export class NotificationService {
  private notification: Subject<INotification>;

  constructor() {
    this.notification = new Subject<INotification>();
  }

  private createMessage(notificationParam: INotification): void | Notification {
    const notification: INotification = {
      id: CommonUtility.createUniqueDOMId(),
      keepAfterRouteChange: false,
      keepOnlyOne: true,
      message: null,
      position: NotificationPosition.Toast,
      type: NotificationType.Info,
      header: undefined,
      ...notificationParam,
    };

    this.notification.next(notification);
  }

  public getNotification(): Observable<INotification> {
    return this.notification.asObservable();
  }

  public success(
    message: string | SafeHtml,
    position: NotificationPosition,
    keepAfterRouteChange?: boolean,
    id?: string,
    keepOnlyOne?: boolean,
    keepHtml?: boolean,
  ): void {
    this.createMessage({
      position,
      message,
      type: NotificationType.Success,
      keepAfterRouteChange,
      id,
      keepOnlyOne,
      keepHtml,
    });
  }

  public actionableSuccess(message: SafeHtml, actionConfig: INotificationActionConfig): void {
    this.createMessage({
      message,
      type: NotificationType.Success,
      shouldTrapFocus: true,
      actionConfig,
    });
  }

  public error(
    message: string | SafeHtml,
    position: NotificationPosition,
    keepAfterRouteChange?: boolean,
    id?: string,
    keepOnlyOne?: boolean,
    keepHtml?: boolean,
  ): void {
    this.createMessage({
      position,
      message,
      type: NotificationType.Error,
      keepAfterRouteChange,
      id,
      keepOnlyOne,
      keepHtml,
    });
  }

  public info(notification: INotification): void {
    this.createMessage({ ...notification, type: NotificationType.Info });
  }

  public warn(
    message: string | SafeHtml,
    position: NotificationPosition,
    keepAfterRouteChange?: boolean,
    id?: string,
    keepOnlyOne?: boolean,
    keepHtml?: boolean,
  ): void {
    this.createMessage({
      message,
      type: NotificationType.Warning,
      keepAfterRouteChange,
      id,
      keepOnlyOne,
      keepHtml,
    });
  }

  public inlineMessage(message: string, id?: string): void {
    this.createMessage({
      position: NotificationPosition.Inline,
      message,
      type: NotificationType.Info,
      keepAfterRouteChange: true,
      id,
    });
  }

  public clear(): void {
    this.notification.next(null);
  }
}
