<div>
  <div class="row">
    <div class="col-12">
      <app-ngb-table [config]="tableConfig$ | async" [data]="pageData$ | async" (onSort)="onSort($event)" class="task-table">
      </app-ngb-table>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 col-md-auto ms-md-auto">
      <span class="text-black me-3" id="tasksEntriesPerPage">{{ 'label_rows_per_page' | translate }}</span>
      <select [(ngModel)]="pageSize" aria-labelledby="tasksEntriesPerPage">
        <option *ngFor="let limit of pageSizes" [ngValue]="limit">
          {{ limit }}
        </option>
      </select>
    </div>

    <div class="col-12 col-md-auto d-flex align-items-baseline mt-3 mt-md-0">
      <span class="me-3 text-black">{{ paginationLabel$ | async }}</span>
      <ngb-pagination
        [attr.aria-label]="'aria_label_tasks_table_pagination' | translate"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="taskCount$ | async"
      >
        <ng-template ngbPaginationPrevious>{{ 'label_previous' | translate }}</ng-template>
        <ng-template ngbPaginationNext>{{ 'label_next' | translate }}</ng-template>
        <ng-template ngbPaginationNumber let-pageNum>
          <span class="visually-hidden">
            {{ (pageNum !== page ? 'go_to_page' : 'page') | translate }}
            &nbsp;
          </span>
          {{ pageNum }}
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
