import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { snakeCase } from 'lodash';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { Event, NavigationEnd, Router } from '@angular/router';
import { DsModalService } from '@levelaccess/design-system';
import { filter, map } from 'rxjs/operators';

import { TranslateService } from '../../../translate/translate.service';
import { ITableColumn, ITableConfig, ITableRow, SortEvent } from '../../table/ngb-table/utilities/ngb-table.interface';
import { $dpMetrics, $dpMetricsSortKeys, $findingsMetrics, $riskScoreLevels } from '../../../../../shared/constants/risk-metrics';
import {
  IDigitalPropertyMetrics,
  IPaginatedDigitalPropertyMetricsList,
} from '../../../../../shared/interfaces/digital-property-metrics.interface';
import { NgbTableUtilities } from '../../table/ngb-table/utilities/ngb-table.utilities';
import { $paginatedResponse } from '../../../../../shared/interfaces/response.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { ISortingParams } from '../../../../../shared/interfaces/request.interface';
import { Api, ApiQueryOption } from '../../../../../shared/constants/api';
import { $sortingOrder } from '../../../../../shared/constants/sort';
import { $digitalProperty } from '../../../../../shared/constants/digital-properties';
import { LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';
import { $findingCounts } from '../../../../../shared/constants/finding-counts';
import { DateUtility } from '../../../utility/date.utility';
import { DashboardDataFrequency } from '../../../../../shared/interfaces/ws-dashboard.interface';

enum $tableColumns {
  dpName = 'dpName',
  resolvedCount = 'resolvedCount',
  resolvedPercentage = 'resolvedPercentage',
}

@Component({
  selector: 'app-websites-apps-by-risk-management-status-details',
  templateUrl: './websites-apps-by-risk-management-status-details.component.html',
})
export class WebsitesAppsByRiskManagementStatusDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public page: number;
  @Input() public titleId: string;
  @Input() public set frequency(frequency: DashboardDataFrequency) {
    this.frequency$.next(frequency);
  }
  @Input() public set computedAt(computedAt: Date) {
    this.computedAt$.next(computedAt);
  }

  @Output() public onTabChange: EventEmitter<$riskScoreLevels>;
  @Output() public onPageChange: EventEmitter<number>;
  @Output() public onSort: EventEmitter<ISortingParams<$dpMetricsSortKeys>>;

  public tableConfig: ITableConfig;
  public tableData: ITableRow[];
  public riskScoreLevels: $riskScoreLevels[];
  public tabLabels: Record<$riskScoreLevels, string>;
  public formattedComputedDate: string;
  public metricsData: IDigitalPropertyMetrics[];
  public collectionSize$: BehaviorSubject<number>;
  public computedDateDescription$: Observable<string>;

  private subscriptions: Subscription;

  private frequency$: BehaviorSubject<DashboardDataFrequency>;
  private computedAt$: BehaviorSubject<Date>;

  constructor(
    private translateService: TranslateService,
    private modalService: DsModalService,
    private router: Router,
  ) {
    this.subscriptions = new Subscription();
    this.page = 1;
    this.riskScoreLevels = Object.values($riskScoreLevels);
    this.onTabChange = new EventEmitter<$riskScoreLevels>();
    this.onPageChange = new EventEmitter<number>();
    this.onSort = new EventEmitter<ISortingParams<$dpMetricsSortKeys>>();
    this.metricsData = [];
    this.collectionSize$ = new BehaviorSubject<number>(0);
    this.frequency$ = new BehaviorSubject<DashboardDataFrequency>(DashboardDataFrequency.weekly);
    this.computedAt$ = new BehaviorSubject<Date>(null);
  }

  @Input() public set dpMetricsList(metricsList: IPaginatedDigitalPropertyMetricsList) {
    this.metricsData = metricsList?.[$paginatedResponse.data];
    this.collectionSize$.next(metricsList?.[$paginatedResponse._total]);
  }

  public tabIdChange(index: number): void {
    this.onTabChange.emit(this.riskScoreLevels[index]);
  }

  public changePage(page: number): void {
    this.onPageChange.emit(page);
  }

  public sortTableData(sort: SortEvent): void {
    const sortParams: ISortingParams<$dpMetricsSortKeys> =
      SharedCommonUtility.isNullish(sort) || sort.direction === $sortingOrder.all
        ? null
        : {
            [ApiQueryOption.sortBy]: this.getSortKey(sort.column as $tableColumns),
            [ApiQueryOption.sortOrder]: sort.direction,
          };
    this.onSort.emit(sortParams);
  }

  private getSortKey(column: $tableColumns): $dpMetricsSortKeys {
    switch (column) {
      case $tableColumns.dpName:
        return $dpMetricsSortKeys.digitalPropertyName;
      case $tableColumns.resolvedCount:
        return $dpMetricsSortKeys.totalResolvedFindings;
      case $tableColumns.resolvedPercentage:
        return $dpMetricsSortKeys.totalPercentageResolved;
      default:
        return null;
    }
  }

  private buildTabLabels(): void {
    this.tabLabels = this.riskScoreLevels.reduce((acc: Record<string, string>, level: $riskScoreLevels) => {
      acc[level] = this.translateService.instant(snakeCase(level));
      return acc;
    }, {});
  }

  private buildTableConfig(): void {
    const columns: Record<string, ITableColumn> = {
      [$tableColumns.dpName]: {
        translationKey: 'website_and_app_label',
        sortingEnabled: true,
        styles: {
          width: '50%',
          maxWidth: '50%',
        },
      },
      [$tableColumns.resolvedPercentage]: {
        translationKey: 'percent_resolved',
        sortingEnabled: true,
        styles: {
          width: '25%',
          maxWidth: '25%',
        },
      },
      [$tableColumns.resolvedCount]: {
        translationKey: 'number_resolved',
        sortingEnabled: true,
        styles: {
          width: '25%',
          maxWidth: '25%',
        },
      },
    };

    this.tableConfig = {
      columns: columns,
      caption: this.translateService.instant('websites_apps_by_risk_management_status_details_table'),
      emptyState: {
        iconId: 'file-tray',
        title: this.translateService.instant('no_websites_apps_in_status'),
      },
    };
  }

  private getTableData(): ITableRow[] {
    if (SharedCommonUtility.isNullish(this.metricsData)) {
      return null;
    }

    return this.metricsData.map((dpMetrics: IDigitalPropertyMetrics) => ({
      originalData: dpMetrics,
      data: {
        [$tableColumns.dpName]: NgbTableUtilities.linkCell({
          text: dpMetrics[$dpMetrics.digitalProperty][$digitalProperty.name],
          noDecoration: true,
          attributes: {
            routerLink: `/${Api.dashboard}`,
            queryParams: LinkedPropertyUtility.getLinkedPropertyQueryParam(
              dpMetrics[$dpMetrics.digitalProperty][$digitalProperty._id],
              dpMetrics[$dpMetrics.workspaceId],
            ),
          },
        }),
        [$tableColumns.resolvedPercentage]: NgbTableUtilities.textCell({
          text: this.translateService.instant(
            'percent_formatter',
            dpMetrics[$dpMetrics.findingsMetrics][$findingsMetrics.percentageResolved][$findingCounts.total].toLocaleString(),
          ),
        }),
        [$tableColumns.resolvedCount]: NgbTableUtilities.textCell({
          text: dpMetrics[$dpMetrics.findingsMetrics]?.[$findingsMetrics.resolvedFindings][$findingCounts.total].toLocaleString(),
        }),
      },
    }));
  }

  private subscribeToRouteChanges(): void {
    this.subscriptions.add(
      this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe(() => {
        this.modalService.closeAllModals();
      }),
    );
  }

  private getComputedDateDescription(computedAt: Date, frequency: DashboardDataFrequency): string {
    switch (frequency) {
      case DashboardDataFrequency.quarterly:
        return this.translateService.instant('data_from_date_previous_quarter', DateUtility.prettifyDate(computedAt));
      case DashboardDataFrequency.monthly:
        return this.translateService.instant('data_from_date_previous_month', DateUtility.prettifyDate(computedAt));
      case DashboardDataFrequency.weekly:
      default:
        return this.translateService.instant('data_from_date', DateUtility.prettifyDate(computedAt));
    }
  }

  public ngOnInit(): void {
    this.buildTabLabels();
    this.buildTableConfig();
    this.subscribeToRouteChanges();
    this.computedDateDescription$ = combineLatest([this.computedAt$, this.frequency$]).pipe(
      map(([computedAt, frequency]: [Date, DashboardDataFrequency]) => {
        return this.getComputedDateDescription(computedAt, frequency);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(): void {
    this.tableData = this.getTableData();
  }
}
