import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { RestService } from './rest.service';
import { ICurrentSelectedProperty } from './user.service';
import { UserPropertyService } from './user-property.service';
import { IFlaw, IFlawStatusChange, IFlawStatusUserHistory } from '../../../shared/interfaces/flaw.interface';
import { $flaw, changedByType, flawStatus } from '../../../shared/constants/flaw';

@Injectable({
  providedIn: 'root',
})
export class FlawService {
  constructor(
    private restService: RestService,
    private userService: UserPropertyService,
  ) {}

  public setViewed(flawId: string): Observable<void> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.markFlawAsViewed(workspaceId, digitalPropertyId, flawId),
        ),
      );
  }

  public getHistory(flawId: string): Observable<IFlawStatusUserHistory[]> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.getFlawHistory(workspaceId, digitalPropertyId, flawId),
        ),
      );
  }

  public hasHistory(flaw: IFlaw): boolean {
    return (
      flaw[$flaw.statusHistory].some(
        (changeEvent: IFlawStatusChange): boolean => changeEvent.changedByType === changedByType.user,
      ) || false
    );
  }

  public getPreviousStatus(flaw: IFlaw): flawStatus {
    const newFlawStatusHistoryLength: number = 1;
    const isNewFlaw: boolean = flaw[$flaw.statusHistory].length <= newFlawStatusHistoryLength;

    if (isNewFlaw) {
      return flawStatus.open;
    }
    const secondLastStatusIndex: number = flaw[$flaw.statusHistory].length - 2;
    return flaw[$flaw.statusHistory][secondLastStatusIndex].newStatus;
  }
}
