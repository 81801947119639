import { Injectable, OnDestroy } from '@angular/core';
import { catchError, filter, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { BehaviorSubject, interval, Observable, of, Subscription } from 'rxjs';

import { IHttpQueryOptions } from '../../../shared/interfaces/http.query.interface';
import {
  IGetNewActivityLogsCountResponse,
  IGetWorkspaceActivityLogsResponseData,
  ISetWorkspaceActivityLogsAsViewedRequest,
  IWorkspaceActivityLogToBeMarked,
} from '../../../shared/interfaces/workspace-activity-log.interface';
import { UserRestAPI } from './rest/user.api';
import { $workspaceActivityLog } from '../../../shared/constants/workspace-activity-log.constants';
import { PULLING_INTERVAL } from '../shared/constants';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService implements OnDestroy {
  private readonly subscriptions: Subscription;
  private readonly newNotificationsCountSubject: BehaviorSubject<number>;
  public readonly newNotificationsCount$: Observable<number>;

  constructor(
    private userRest: UserRestAPI,
    private userService: UserService,
  ) {
    this.subscriptions = new Subscription();
    this.newNotificationsCountSubject = new BehaviorSubject<number>(0);
    this.newNotificationsCount$ = this.newNotificationsCountSubject.asObservable();

    this.initializeService();
  }

  private initializeService(): void {
    this.subscriptions.add(
      interval(PULLING_INTERVAL.SLOWER)
        .pipe(
          startWith(0),
          switchMap(() => this.userService.isAuthenticated$),
          filter(() => this.userService.hasAWorkspaceSubject$.getValue()),
          mergeMap((isUserAuthenticated: boolean) => this.getUserNewNotificationsCount(isUserAuthenticated)),
        )
        .subscribe((response: IGetNewActivityLogsCountResponse) => {
          if (response !== null) {
            this.newNotificationsCountSubject.next(response[$workspaceActivityLog.newActivityLogsCount]);
          }
        }),
    );
  }

  private getUserNewNotificationsCount(isUserAuthenticated: boolean): Observable<IGetNewActivityLogsCountResponse> {
    const onGetCountError = (): Observable<IGetNewActivityLogsCountResponse> => {
      return of(null);
    };

    if (isUserAuthenticated) {
      return this.userRest.getUserNewNotificationsCount().pipe(catchError(onGetCountError));
    }
    return of(null);
  }

  public getUserWorkspacesRecentActivity(
    query: IHttpQueryOptions,
    skipLoader: boolean = false,
  ): Observable<IGetWorkspaceActivityLogsResponseData> {
    return this.userRest.getUserWorkspacesActivityLogs(query, skipLoader);
  }

  public setUserWorkspacesRecentActivityAsViewed(activityLogs: IWorkspaceActivityLogToBeMarked[]): Observable<void> {
    const setActivityLogsAsViewedRequest: ISetWorkspaceActivityLogsAsViewedRequest = {
      [$workspaceActivityLog.logsToBeMarked]: activityLogs,
    };

    return this.userRest.setUserWorkspacesActivityLogsAsViewed(setActivityLogsAsViewedRequest);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
