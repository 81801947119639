import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import { IGlobalResponse, IUserDeactivateAccount, IUserProfileFormData } from '../../interfaces/user.interface';
import { IHttpQueryOptions } from '../../../../shared/interfaces/http.query.interface';
import {
  IGetUsersResponseData,
  IMfaLoginRequest,
  IMfaRegistrationQrResponse,
  IMfaStatusResponse,
  IMfaTokenVerificationRequest,
  IUser,
  IUserPasswordSettings,
  IUserServerResponse,
  IUserPublicResponse,
  IBulkEmailUpdateResponse,
  IUserEmailResponse,
} from '../../../../shared/interfaces/user.interface';
import {
  IGetNewActivityLogsCountResponse,
  IGetWorkspaceActivityLogsResponseData,
  ISetWorkspaceActivityLogsAsViewedRequest,
} from '../../../../shared/interfaces/workspace-activity-log.interface';
import { ApiHeaderOption, ApiQueryOption } from '../../../../shared/constants/api';
import { $user } from '../../../../shared/constants/user';
import { IDigitalPropertiesListResponse, IWorkspaceIDItem } from '../../../../shared/interfaces/digital-property.interface';
import { IUserSignupResponse, IUserSignUpRequest } from '../../../../shared/interfaces/user-authentication.interface';
import { EmptyObject } from '../../../../shared/interfaces/empty-object.interface';
import { IUserPreferencesServerResponse } from '../../../../shared/interfaces/user-preference.interface';
import { $userPreferenceFilter, userPreferenceScope } from '../../../../shared/constants/user-preferences';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { RestService } from '../rest.service';
import { IImageSrcResponse } from '../../../../shared/interfaces/common.interface';
import { AccessibilityAuditToolNames } from '../../../../shared/constants/audit-tool';
import { IRecentUserWorkspace } from '../../../../shared/interfaces/recent-resources.interface';

@Injectable({
  providedIn: 'root',
})
export class UserRestAPI {
  private restBuilder: RestBuilder;

  constructor(
    private httpClient: HttpClient,
    private restService: RestService,
  ) {
    this.restBuilder = new RestBuilder();
  }

  // GET /user/:id
  public getUserById(userId: string): Observable<IUserServerResponse | IUserPublicResponse> {
    const url: string = this.restBuilder.create().user(userId).getApiUrl();

    return this.httpClient.get(url);
  }

  // GET /user/authenticated
  public getAuthenticatedUser(): Observable<IUserServerResponse> {
    const url: string = this.restBuilder.create().user().authenticated().getApiUrl();

    return this.httpClient.get<IUserServerResponse>(url);
  }

  // PUT /admin/user/:id
  public updateSelectedUserProfile(formData: IUserProfileFormData, id: string): Observable<IUser> {
    const url: string = this.restBuilder.create().admin().user(id).getApiUrl();

    return this.httpClient.put<IUser>(url, formData);
  }

  // PUT /workspaces/:workspaceId/user/:userId
  public updateSelectedUserProfileAsWorkspaceAdmin(formData: FormData, workspaceId: string, userId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).user(userId).getApiUrl();

    return this.httpClient.put<void>(url, formData);
  }

  // PUT /user/:id
  public saveUser(formData: FormData): Observable<IUser> {
    const url: string = this.restBuilder.create().user().getApiUrl();

    const headers: HttpHeaders = new HttpHeaders();

    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');

    return this.httpClient.put<IUser>(url, formData, { headers: headers });
  }

  // GET /user/mfa
  public getMfaRegistrationQr(): Observable<IMfaRegistrationQrResponse> {
    const url: string = this.restBuilder.create().user().mfa().getApiUrl();

    return this.httpClient.get<IMfaRegistrationQrResponse>(url);
  }

  // POST /user/mfa
  public verifyAndEnableMfa(request: IMfaTokenVerificationRequest): Observable<void> {
    const url: string = this.restBuilder.create().user().mfa().getApiUrl();

    return this.httpClient.post<void>(url, request);
  }

  // DELETE /user/mfa
  public disableMfa(): Observable<void> {
    const url: string = this.restBuilder.create().user().mfa().getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // DELETE /user/:userId/mfa
  public disableMfaForUser(userId: string): Observable<void> {
    const url: string = this.restBuilder.create().user(userId).mfa().getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // GET /user/mfa/status
  public getMfaStatus(): Observable<IMfaStatusResponse> {
    const url: string = this.restBuilder.create().user().mfa().status().getApiUrl();

    return this.httpClient.get<IMfaStatusResponse>(url);
  }

  // GET /user/:userId/mfa/status
  public getMfaStatusForUser(userId: string): Observable<IMfaStatusResponse> {
    const url: string = this.restBuilder.create().user(userId).mfa().status().getApiUrl();

    return this.httpClient.get<IMfaStatusResponse>(url);
  }

  // POST /user/login/mfa
  public mfaLogin(request: IMfaLoginRequest): Observable<HttpResponse<{ userId: string }>> {
    const url: string = this.restBuilder.create().user().login().mfa().getApiUrl();
    const options: { observe: 'response' } = {
      observe: 'response',
    };

    return this.httpClient.post<{ userId: string }>(url, request, options);
  }

  // PUT /user/deactivate
  public deactivateAccount(formData: any): Observable<IUserDeactivateAccount> {
    const url: string = this.restBuilder.create().user().deactivate().getApiUrl();

    return this.httpClient.put<IUserDeactivateAccount>(url, formData);
  }

  // POST /user/verifyEmail
  public verifyEmail(token: string): Observable<IUserServerResponse> {
    const url: string = this.restBuilder.create().user().verifyEmail().getApiUrl();

    const data: { token: string } = {
      token: token,
    };

    return this.httpClient.post<IUserServerResponse>(url, data);
  }

  // POST /user/verifyResetPasswordToken
  public verifyResetPasswordToken(token: string): Observable<{ user: { id: string } }> {
    const url: string = this.restBuilder.create().user().verifyResetPasswordToken().getApiUrl();

    const data: { token: string } = {
      token: token,
    };

    return this.httpClient.post<{ user: { id: string } }>(url, data);
  }

  // POST /auth/status
  public authStatus(): Observable<HttpResponse<IGlobalResponse>> {
    const url: string = this.restBuilder.create().auth().status().getApiUrl();

    const headers: HttpHeaders = new HttpHeaders();

    headers.set('Cache-Control', 'no-cache');

    return this.httpClient.get<IGlobalResponse>(url, {
      headers: headers,
      observe: 'response',
    });
  }

  // POST /user/login
  public login(data: any): Observable<HttpResponse<{ userId: string; mfaRequired: boolean }>> {
    const url: string = this.restBuilder.create().user().login().getApiUrl();

    return this.httpClient.post<{ userId: string; mfaRequired: boolean }>(url, data, { observe: 'response' });
  }

  // POST /user/logout
  public logout(): Observable<void> {
    const url: string = this.restBuilder.create().user().logout().getApiUrl();

    return this.httpClient.post<void>(url, null);
  }

  // POST /user/sign-up
  public signup(data: IUserSignUpRequest): Observable<IUserSignupResponse> {
    const url: string = this.restBuilder.create().user().signup().getApiUrl();

    return this.httpClient.post<IUserSignupResponse>(url, data);
  }

  // POST /user/resetPassword
  public resetPassword(email: string): Observable<EmptyObject> {
    const url: string = this.restBuilder.create().user().resetPassword().getApiUrl();

    const data: { email: string } = {
      email: email,
    };

    return this.httpClient.post<Record<string, never>>(url, data);
  }

  // POST /user/resend-activation-link
  public resendActivationLink(email: string): Observable<EmptyObject> {
    const url: string = this.restBuilder.create().user().resendActivationLink().getApiUrl();

    const data: { email: string } = {
      email: email,
    };

    return this.httpClient.post<Record<string, never>>(url, data);
  }

  // POST /user/resend-visitor-activation-link
  public resendVisitorActivationLink(email: string): Observable<EmptyObject> {
    const url: string = this.restBuilder.create().user().resendVisitorActivationLink().getApiUrl();

    const data: { email: string } = {
      email: email,
    };

    return this.httpClient.post<EmptyObject>(url, data);
  }

  // PUT /user/createNewPassword
  public createNewPassword(password: string, verificationToken: string): Observable<EmptyObject> {
    const url: string = this.restBuilder.create().user().createNewPassword().getApiUrl();

    const data: { password: string; token: string } = {
      password: password,
      token: verificationToken,
    };

    return this.httpClient.put<Record<string, never>>(url, data);
  }

  // PUT /user/workspaces
  public switchUserToWorkspace(workspaceId: string): Observable<IUserServerResponse> {
    const url: string = this.restBuilder.create().user().workspaces().getApiUrl();

    const data: Partial<IUserServerResponse> = {
      [$user.lastUsedWorkspace]: workspaceId,
    };

    return this.httpClient.put(url, data);
  }

  // PUT /user/digital_properties
  public switchUserToDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IUserServerResponse> {
    const url: string = this.restBuilder.create().user().digitalProperties().getApiUrl();

    const data: Partial<IUserServerResponse> = {
      [$user.lastUsedDigitalProperty]: digitalPropertyId,
      [$user.lastUsedWorkspace]: workspaceId,
    };

    return this.httpClient.put(url, data);
  }

  // GET /user/preferences
  public getUserPreferences(scope: string, workspaceId?: string, projectId?: string): Observable<IUserPreferencesServerResponse> {
    const url: string = this.restBuilder.create().user().preferences(scope, workspaceId).getApiUrl();

    let params: HttpParams = new HttpParams();
    if (SharedCommonUtility.notNullish(projectId)) {
      params = params.set($userPreferenceFilter.projectId, projectId);
    }

    return this.httpClient.get<IUserPreferencesServerResponse>(url, { params });
  }

  // GET /user/:userId/preferences
  public getUserPreferencesForUser(
    scope: string,
    userId: string,
    workspaceId?: string,
    projectId?: string,
  ): Observable<IUserPreferencesServerResponse> {
    const url: string = this.restBuilder.create().user(userId).preferences(scope, workspaceId).getApiUrl();

    let params: HttpParams = new HttpParams();
    if (SharedCommonUtility.notNullish(projectId)) {
      params = params.set($userPreferenceFilter.projectId, projectId);
    }

    return this.httpClient.get<IUserPreferencesServerResponse>(url, { params });
  }

  // PUT /user/preferences/:workspaceId?
  public updateUserPreferences(
    preferences: { [key: string]: any },
    scope: userPreferenceScope,
    workspaceId?: string,
  ): Observable<IUserPreferencesServerResponse> {
    const url: string = this.restBuilder.create().user().preferences(scope, workspaceId).getApiUrl();

    return this.httpClient.put<IUserPreferencesServerResponse>(url, preferences);
  }

  // PUT /user/:userId/preferences/:workspaceId?
  public updateUserPreferencesForUser(
    preferences: { [key: string]: any },
    scope: userPreferenceScope,
    userId: string,
    workspaceId?: string,
  ): Observable<IUserPreferencesServerResponse> {
    const url: string = this.restBuilder.create().user(userId).preferences(scope, workspaceId).getApiUrl();

    return this.httpClient.put<IUserPreferencesServerResponse>(url, preferences);
  }

  // GET /user/workspaces/activityLogs
  public getUserWorkspacesActivityLogs(
    queryParams: IHttpQueryOptions,
    skipLoader?: boolean,
  ): Observable<IGetWorkspaceActivityLogsResponseData> {
    const url: string = this.restBuilder.create().user().workspaces().activityLogs().getApiUrl();

    let params: HttpParams = new HttpParams();
    for (const [field, value] of Object.entries(queryParams)) {
      params = params.set(field, value);
    }

    return this.httpClient.get<IGetWorkspaceActivityLogsResponseData>(url, {
      params,
      headers: {
        [ApiHeaderOption.skipLoader]: skipLoader === true ? 'true' : 'false',
      },
    });
  }

  // GET /user/workspaces/activityLogs/count
  public getUserNewNotificationsCount(): Observable<IGetNewActivityLogsCountResponse> {
    const url: string = this.restBuilder.create().user().workspaces().activityLogs().count().getApiUrl();

    return this.httpClient.get<IGetNewActivityLogsCountResponse>(url, {
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  // GET /user/digital-properties
  public getAvailableDigitalProperties(): Observable<IDigitalPropertiesListResponse> {
    const url: string = this.restBuilder.create().user().digitalProperties().getApiUrl();
    return this.httpClient.get<IDigitalPropertiesListResponse>(url);
  }

  // PUT /user/workspaces/activityLogs
  public setUserWorkspacesActivityLogsAsViewed(
    setActivityLogsAsViewedRequest: ISetWorkspaceActivityLogsAsViewedRequest,
  ): Observable<void> {
    const url: string = this.restBuilder.create().user().workspaces().activityLogs().getApiUrl();

    return this.httpClient.put<void>(url, setActivityLogsAsViewedRequest);
  }

  // GET /users
  public getUsers(queryParams: IHttpQueryOptions): Observable<IGetUsersResponseData> {
    const url: string = this.restBuilder.create().users().getApiUrl();

    const query: Record<string, any> = {};

    if (queryParams && typeof queryParams[ApiQueryOption.limit] === 'number') {
      query[ApiQueryOption.limit] = String(queryParams[ApiQueryOption.limit]);
    }

    if (typeof queryParams[ApiQueryOption.skip] === 'number') {
      query[ApiQueryOption.skip] = String(queryParams[ApiQueryOption.skip]);
    }

    if (queryParams && typeof queryParams[ApiQueryOption.term] === 'string') {
      query[ApiQueryOption.term] = window.encodeURIComponent(String(queryParams[ApiQueryOption.term])).trim();
    }

    if (queryParams && typeof queryParams[ApiQueryOption.includeMe] === 'boolean') {
      query[ApiQueryOption.includeMe] = window.encodeURIComponent(String(queryParams[ApiQueryOption.includeMe])).trim();
    }

    if (queryParams && typeof queryParams[ApiQueryOption.sortBy] === 'string') {
      query[ApiQueryOption.sortBy] = window.encodeURIComponent(String(queryParams[ApiQueryOption.sortBy])).trim();
    }

    if (queryParams && typeof queryParams[ApiQueryOption.sortOrder] === 'string') {
      query[ApiQueryOption.sortOrder] = window.encodeURIComponent(String(queryParams[ApiQueryOption.sortOrder])).trim();
    }

    if (queryParams && typeof queryParams[ApiQueryOption.status] !== 'undefined') {
      query[ApiQueryOption.status] = queryParams[ApiQueryOption.status];
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: query,
    });

    return this.httpClient.get<IGetUsersResponseData>(url, { params: httpParams });
  }

  // GET /users/change-emails/download
  public changeEmailsDownloadTemplate(): Observable<void> {
    const url: string = this.restBuilder.create().users().changeEmails().download().getApiUrl();
    return this.restService.download(url);
  }

  // POST /users/update-emails
  public updateEmails(fileForm: FormData): Observable<IBulkEmailUpdateResponse> {
    const url: string = this.restBuilder.create().users().updateEmails().getApiUrl();
    return this.httpClient.post<IBulkEmailUpdateResponse>(url, fileForm);
  }

  public getAvailableDigitalPropertyIds(): Observable<IWorkspaceIDItem[]> {
    const url: string = this.restBuilder.create().user().workspaces().allIds().getApiUrl();

    return this.httpClient.get<IWorkspaceIDItem[]>(url);
  }

  public checkAccessToProperty(digitalPropertyId: string, workspaceId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .user()
      .digitalProperty()
      .query(new HttpParams({ fromObject: { workspace: workspaceId, digitalProperty: digitalPropertyId } }))
      .getApiUrl();

    return this.httpClient.get<void>(url);
  }

  // PUT /user/changePassword
  public changePassword(passwordSettings: IUserPasswordSettings): Observable<void> {
    const url: string = this.restBuilder.create().user().changePassword().getApiUrl();
    return this.httpClient.put<void>(url, passwordSettings);
  }

  // POST /user/verifyPreviousPassword
  public verifyPreviousPassword(password: string): Observable<boolean> {
    const url: string = this.restBuilder.create().user().verifyPreviousPassword().getApiUrl();
    return this.httpClient.post<boolean>(url, { password });
  }

  // POST /user/verifyPreviousPasswordVisitor
  public verifyPreviousPasswordVisitor(password: string, emailToken: string, skipLoader: boolean = false): Observable<boolean> {
    const url: string = this.restBuilder.create().user().verifyPreviousPasswordVisitor().getApiUrl();

    let headers: HttpHeaders = null;
    if (skipLoader) {
      headers = new HttpHeaders({
        [ApiHeaderOption.skipLoader]: 'true',
      });
    }

    return this.httpClient.post<boolean>(
      url,
      { password, emailToken },
      {
        headers,
      },
    );
  }

  public getAvatarUrl(userId: string): Observable<IImageSrcResponse> {
    const url: string = this.restBuilder.create().user().avatar(userId).getApiUrl();
    return this.httpClient.get<IImageSrcResponse>(url);
  }

  // GET /user/email
  public getEmailByToken(token: string): Observable<IUserEmailResponse> {
    const url: string = this.restBuilder.create().user().email().getApiUrl();
    const httpParams: HttpParams = new HttpParams({
      fromObject: { [ApiQueryOption.confirmEmailToken]: token },
    });

    return this.httpClient.get<IUserEmailResponse>(url, { params: httpParams });
  }

  // GET /user/recent-workspaces
  public getRecentWorkspaces(toolName: AccessibilityAuditToolNames): Observable<IRecentUserWorkspace[]> {
    const url: string = this.restBuilder.create().user().recentWorkspaces().getApiUrl();
    const httpParams: HttpParams = new HttpParams({
      fromObject: { [ApiQueryOption.toolName]: toolName },
    });

    return this.httpClient.get<IRecentUserWorkspace[]>(url, { params: httpParams });
  }
}
