<header id="app-header">
  <nav class="navigation container-fluid" role="navigation" aria-label="Site menu">
    <div class="logo-wrapper d-block">
      <a class="logo" href="/">
        <img src="/static/assets/images/2022-LevelAccess_Logo_V1_RGB.svg" alt="Logo - go to the home page" />
        <em class="visuallyhidden">Logo - go to the home page</em>
      </a>
    </div>
  </nav>
</header>
