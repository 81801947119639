import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { AutomatedScanAdvancedService } from '../../automated-scan-advanced.service';
import { IScanOptions } from '../../../../../../../../shared/interfaces/scan-options.interface';
import { SharedCommonUtility } from '../../../../../../../../shared/utils/common.utility';
import { CustomValidators } from '../../../../../../services/helpers/form-custom-validators';
import { scanCreateRequest } from '../../../../../../../../shared/constants/scan-create-request';
import { scanningType } from '../../../../../../../../shared/constants/scanning';

@Component({
  selector: 'app-advanced-scan-wait-time',
  templateUrl: './wait-time.component.html',
})
export class WaitTimeComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public modifyWaitTime: boolean = false;
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public waitTimeControlName: string = scanCreateRequest.waitTimeSec;
  public disabled: boolean = false;

  constructor(
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private advancedService: AutomatedScanAdvancedService,
  ) {}

  private subscribeToScanningType(): void {
    this.subscription.add(
      this.advancedService.getScanningType$().subscribe((type: scanningType) => {
        if (type === scanningType.automatedUserFlow) {
          this.removeControl();
          this.modifyWaitTime = false;
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public onModifyWaitTime(value: boolean): void {
    if (!value) {
      this.removeControl();
      return;
    }

    if (!this.form.contains(this.waitTimeControlName)) {
      this.registerControl();
    }

    this.changeDetectorRef.detectChanges();
    this.el.nativeElement.querySelector('#wait-time-sec input').focus();
  }

  private onOptionsUpdated(opts: IScanOptions): void {
    this.modifyWaitTime = SharedCommonUtility.notNullish(opts.waitTimeSec);

    if (this.modifyWaitTime) {
      this.registerControl(opts.waitTimeSec);
    } else {
      this.removeControl();
    }

    this.changeDetectorRef.detectChanges();
  }

  private removeControl(): void {
    if (SharedCommonUtility.notNullish(this.form.get(this.waitTimeControlName))) {
      this.form.removeControl(this.waitTimeControlName);
      this.changeDetectorRef.detectChanges();
    }
  }

  private registerControl(value: number = 0): void {
    const waitTimeControl: AbstractControl = this.form.get(this.waitTimeControlName);
    if (SharedCommonUtility.notNullish(waitTimeControl)) {
      waitTimeControl.patchValue(value);
    } else {
      this.form.registerControl(
        this.waitTimeControlName,
        this.formBuilder.control(value, [
          CustomValidators.required,
          CustomValidators.validateIsEmpty,
          CustomValidators.min(0),
          CustomValidators.max(10),
        ]),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscription.add(this.advancedService.onScanOptionsChanged().subscribe(this.onOptionsUpdated.bind(this)));
    this.subscribeToScanningType();
  }
}
