import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { RestService } from './rest.service';
import { ResourceUtility } from '../utility/resource.utility';
import { ResourceData } from '../interfaces/resource-data';
import { ICurrentSelectedProperty } from './user.service';
import { UserPropertyService } from './user-property.service';
import { FileExtension } from '../../../shared/constants/file-extension';
import { AccessibilityAuditToolNames } from '../../../shared/constants/audit-tool';
import { IScanReportResponse } from '../../../shared/interfaces/async-report.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(
    private restService: RestService,
    private userService: UserPropertyService,
  ) {}

  public downloadReportNew(
    scanId: string,
    fileExtension: FileExtension,
    scanUrl: string = '',
    ruleId: string,
    extraQueryParams: Record<string, any> = {},
  ): Observable<void> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.downloadReport(workspaceId, digitalPropertyId, scanId, fileExtension, scanUrl, ruleId, extraQueryParams),
        ),
      );
  }

  public getFullScanReport(
    scanId: string,
    fileExtension: FileExtension,
    scanUrl: string = '',
    auditTool: AccessibilityAuditToolNames,
  ): Observable<IScanReportResponse> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.downloadFullScanReport(workspaceId, digitalPropertyId, scanId, fileExtension, scanUrl, auditTool),
        ),
      );
  }

  public generateFullScanReport(
    scanId: string,
    fileExtension: FileExtension,
    scanUrl: string = '',
    auditTool: AccessibilityAuditToolNames,
  ): Observable<IScanReportResponse> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.generateFullScanReport(workspaceId, digitalPropertyId, scanId, fileExtension, scanUrl, auditTool),
        ),
      );
  }

  public downloadReport(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    fileExtension: FileExtension,
    scanUrl: string = '',
    ruleId: string,
    extraQueryParams: Record<string, any> = {},
  ): Observable<void> {
    return this.restService
      .downloadScanReportByRule(workspaceId, digitalPropertyId, scanId, fileExtension, scanUrl, ruleId, extraQueryParams)
      .pipe(map(ResourceUtility.downloadResponse));
  }

  public getReport(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    fileExtension: FileExtension,
    ruleId: string | null = null,
    extraQueryParams: Record<string, any> = {},
  ): Observable<File> {
    return this.restService
      .downloadScanReportByRule(workspaceId, digitalPropertyId, scanId, fileExtension, '', ruleId, extraQueryParams)
      .pipe(
        map(ResourceUtility.fromResponse),
        map((resourceData: ResourceData): File => {
          const blob: Blob = new Blob([resourceData.data], { type: resourceData.mimeType });
          const eapFriendlyMimeType: string = resourceData.mimeType.split(';')[0];
          return new File([blob], resourceData.filename, { type: eapFriendlyMimeType });
        }),
      );
  }

  public sendReportToEmails(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    auditTool: AccessibilityAuditToolNames | null,
    emails: string[],
  ): Observable<string[]> {
    return this.restService.sendReportToEmails(workspaceId, digitalPropertyId, scanId, auditTool, emails);
  }
}
