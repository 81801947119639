import { padStart } from 'lodash';
import { SharedCommonUtility } from './common.utility';
import { ITimezone } from '../interfaces/timezone.interface';
import timezones from '../constants/timezones.json';

export class SharedDateUtility {
  public static DAY_IN_MS: number = 24 * 60 * 60 * 1000; // 24h * 60m * 60s * 1000ms to convert 1 day to ms
  public static DEFAULT_LOCALE: string = 'en-US';

  /**
   * Normalize a date string into a date object.
   *
   * @param date May be an actual date object or a date string.
   * @return A date object
   * @private
   */
  private static getDateObject(date: string | Date): Date {
    if (typeof date === 'string') {
      return new Date(date);
    }
    return date;
  }

  public static isValidStringDate(date: string): boolean {
    if (typeof date !== 'string' || date.length === 0) {
      return false;
    }
    const time: number = Date.parse(date);

    return isNaN(time) === false;
  }

  /**
   * Checks whether a date string matches YYYY-MM-DD format, without time or timezone info.
   * @param date
   * @returns `true` if it matches YYYY-MM-DD, otherwise returns `false`.
   */
  public static isDateWithoutTime(date: string): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(date);
  }

  public static isValidDate(dateValue: string | Date): boolean {
    if (typeof dateValue === 'string') {
      return SharedDateUtility.isValidStringDate(dateValue);
    }
    const isInvalidDate: boolean = isNaN(new Date(dateValue).getTime());
    return !isInvalidDate;
  }

  public static toMidnight(value: string | Date): Date {
    const dateValue: Date = new Date(value);
    return new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
  }

  public static toNextMidnight(value: string | Date): Date {
    const nextDate: Date = new Date(value);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    return nextDate;
  }

  public static getLastDayOfMonth(value: Date): number {
    return new Date(value.getFullYear(), value.getMonth() + 1, 0).getDate();
  }

  public static getDateWithLastDayOfMonth(value: Date): Date {
    const lastDayOfMonth: number = SharedDateUtility.getLastDayOfMonth(value);
    const dateWithAdjustedDay: Date = new Date(value);
    dateWithAdjustedDay.setDate(lastDayOfMonth);
    return dateWithAdjustedDay;
  }

  public static formatTime(ms: number): string {
    const PREFIX: number = 0;
    const date: Date = new Date(ms);
    const minutes: number = date.getMinutes();
    const seconds: number = date.getSeconds();
    const milliseconds: number = date.getMilliseconds();

    let time: string = '';

    if (minutes === 0 && seconds === 0 && milliseconds === 0) {
      time += '< 1ms';
    } else {
      time += minutes > PREFIX ? minutes + 'm ' : '';
      time += seconds > PREFIX ? seconds + 's ' : '';
      time += milliseconds > PREFIX ? milliseconds + 'ms' : '';
    }

    return time;
  }

  public static getRelativeDateString(date: string | Date): string {
    const otherDate: Date = this.getDateObject(date);
    const weekInMs = 7 * 24 * 3600 * 1000;
    if (new Date().getTime() - otherDate.getTime() < weekInMs) {
      return otherDate.toLocaleDateString('en-US', { weekday: 'long' });
    }
    return otherDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }

  // Example: June 1, 2021
  public static getDateString(date: string | Date): string {
    const otherDate: Date = this.getDateObject(date);
    return otherDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }

  public static getNextYear(date: Date): Date {
    const nextYearDate: Date = new Date(date);
    nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
    return nextYearDate;
  }

  /**
   * Check if the given epoch date has already occurred
   *
   * @param epochDate The epoch date to be verified
   * @returns A boolean indicating whether the given epoch date has already occurred
   */
  public static checkIfEpochDateAlreadyOccurred(epochDate: number): boolean {
    return Date.now() >= epochDate;
  }

  /**
   * Get the date from the given last milliseconds ago (before now)
   *
   * @param msTimeAgo Time ago in milliseconds
   * @returns Date object of the date occurred in the given last milliseconds ago
   */
  public static getLastMSDate(msTimeAgo: number): Date {
    return new Date(Date.now() - msTimeAgo);
  }

  public static getReportDateString(date: Date | string, timezoneOffset?: number): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    if (timezoneOffset) {
      options.timeZone = SharedDateUtility.getNamedTimezone(timezoneOffset);
    }
    return new Date(date).toLocaleDateString('en-US', options);
  }

  public static addSeconds(prevDate: Date, secondsToAdd: number): Date {
    const result: Date = new Date(prevDate.getTime());
    result.setSeconds(prevDate.getSeconds() + secondsToAdd);
    return result;
  }

  public static addDays(originalData: Date, daysToAdd: number): Date {
    return this.addSeconds(originalData, daysToAdd * 24 * 60 * 60);
  }

  public static isYesterday(date: Date): boolean {
    const yesterday: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
    return date.toLocaleDateString() === yesterday.toLocaleDateString();
  }

  public static isTomorrow(date: Date): boolean {
    const tomorrow: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
    return date.toLocaleDateString() === tomorrow.toLocaleDateString();
  }

  public static isToday(date: Date): boolean {
    return date.toLocaleDateString() === new Date().toLocaleDateString();
  }

  /**
   * Format date in international standard format YYYY-MM-DD (ISO8601)
   * using local time zone
   * Example: 2021-07-20
   *
   * @param date A date object or string
   */
  public static getLocalISODate(date: Date | string): string {
    const otherDate: Date = this.getDateObject(date);
    const padNumber = (n: number): string => n.toString().padStart(2, '0');

    const day = padNumber(otherDate.getDate());
    const month = padNumber(otherDate.getMonth() + 1);
    const year = otherDate.getFullYear();

    return `${year}-${month}-${day}`;
  }

  public static dateToDatetimeLocalFormat(date: Date): string {
    return (
      SharedCommonUtility.withLeadingZeros(date.getFullYear(), 4) +
      '-' +
      SharedCommonUtility.withLeadingZeros(date.getMonth() + 1, 2) +
      '-' +
      SharedCommonUtility.withLeadingZeros(date.getDate(), 2) +
      'T' +
      SharedCommonUtility.withLeadingZeros(date.getHours(), 2) +
      ':' +
      SharedCommonUtility.withLeadingZeros(date.getMinutes(), 2)
    );
  }

  public static amountOfDaysInMonth(month: number, year: number): number {
    let dayNum: number;
    if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
      return 31;
    }
    if ([4, 6, 9, 11].includes(month)) {
      return 30;
    }
    // If month is February, calculate whether it is a leap year or not
    const isLeapYear = new Date(year, 1, 29).getMonth() === 1;
    if (isLeapYear) {
      dayNum = 29;
    } else {
      dayNum = 28;
    }
    return dayNum;
  }

  public static getLocalDateFromNumbers(year: number, month: number, date: number): Date {
    const utc: number = Date.UTC(year, month, date);
    return new Date(utc + new Date().getTimezoneOffset() * 60000);
  }

  /**
   * Receives a date in the format 2023-02-01T00:03:03.935Z and returns 2023-02-01, ruling out timezone differences when they don't matter.
   * If time matters, use `SharedDateUtility.getLocalDate` instead.
   * @param inputISODate
   * @returns date without time
   */
  public static getUTCDateWithoutTime(inputISODate: string): Date {
    return SharedDateUtility.getLocalDate(inputISODate.split('T')[0]);
  }

  public static getLocalDate(inputDate: string): Date {
    const date: Date = new Date(inputDate);
    if (SharedDateUtility.isDateWithoutTime(inputDate) === false) {
      return date;
    }

    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  public static getTimezoneTextByValue(timezoneValue: string): string {
    if (typeof timezoneValue !== 'string') {
      return null;
    }

    const timezone = (_timezone: ITimezone): boolean => {
      return _timezone.value === timezoneValue;
    };

    const foundedTimezone: ITimezone = (timezones as unknown as ITimezone[]).find(timezone);

    return foundedTimezone ? foundedTimezone.text : null;
  }

  public static convertMinutestoMilliseconds(minutes: number): number {
    return Math.floor(minutes * 60 * 1000);
  }

  public static getNamedTimezone(timezoneOffset: number): string {
    const timeZoneNotDetermined: string = 'UTC';

    for (const tz of timezones) {
      if (-tz.offset === timezoneOffset) {
        return tz.utc[0];
      }
    }

    return timeZoneNotDetermined;
  }

  public static getLocaleDateTimeWithDayName(date: string, locale: string): string {
    const d: Date = new Date(date);

    return (
      d.toLocaleDateString(locale, { weekday: 'long' }) + ', ' + d.toLocaleDateString(locale) + ' ' + d.toLocaleTimeString(locale)
    );
  }

  public static getLocaleDateTime(
    date: Date,
    userLocale: string = SharedDateUtility.DEFAULT_LOCALE,
    userTimezoneOffset: number = 0,
    skipTime: boolean = false,
  ): string {
    const utcTimeZone: string = SharedDateUtility.getNamedTimezone(userTimezoneOffset ?? 0);
    let timeFormat: Intl.DateTimeFormatOptions;
    if (!skipTime) {
      timeFormat = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
      };
    }

    const format: Intl.DateTimeFormatOptions = {
      timeZone: utcTimeZone,
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...timeFormat,
    };

    return date.toLocaleString(userLocale || SharedDateUtility.DEFAULT_LOCALE, format);
  }

  public static getGMTDateTime(
    date: Date,
    userLocale: string = SharedDateUtility.DEFAULT_LOCALE,
    timezoneOffset: number = 0,
  ): string {
    // not using getNamedTimezone() as it assumes only -ve offsets
    const timeZone: string =
      timezones.find((tz: { offset: number; utc: string[] }) => tz.offset === (timezoneOffset ?? 0))?.utc[0] || 'UTC';

    const format: { [key: string]: string } = {
      timeZone: timeZone,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    };

    const formatter = Intl.DateTimeFormat(userLocale || SharedDateUtility.DEFAULT_LOCALE, format);
    return formatter.format(date);
  }

  public static getLocaleDate(date: Date, userLocale: string, timeZone: string): string {
    return date.toLocaleDateString(userLocale, { timeZone });
  }

  public static getFormattedDateForDownload(date: Date): string {
    return `${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;
  }

  /**
   * @returns only the date portion of ISO date. e.g. '2022-03-29' date based on UTC
   */
  public static getUTCISODate(date: Date | string): string {
    if (SharedCommonUtility.isNullish(date)) {
      return null;
    }

    const dateString: string = new Date(date).toISOString();
    return dateString.substring(0, 10);
  }

  // For toronto, return something like, '2022-07-28T14:04:00.000-0400'
  public static toJiraDateTimeString(date: Date): string {
    const tzo: number = -date.getTimezoneOffset();
    const dif: string = tzo >= 0 ? '+' : '-';

    function pad(num: number): string {
      return padStart(String(num), 2, '0');
    }

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      '.000' +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      pad(Math.abs(tzo) % 60)
    );
  }

  // February 10, 2021 at 7:06 PM
  public static getDateAtTime(date: Date): string {
    return Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' } as any).format(date);
  }

  /**
   * Randomizes time within a day and sets the same time to both
   * startDate and endDate
   *
   * @param startDate
   * @param endDate
   */
  public static randomizeTime(startDate: Date, endDate?: Date): void {
    function getRandomInt(maxExclusive: number): number {
      return Math.floor(Math.random() * maxExclusive);
    }
    const hourMinSec: [number, number, number] = [getRandomInt(24), getRandomInt(60), getRandomInt(60)];
    startDate.setHours(...hourMinSec);
    if (SharedCommonUtility.notNullish(endDate)) {
      endDate.setHours(...hourMinSec);
    }
  }

  public static IsDateGreaterThanOrEqualToday(date: Date): boolean {
    return SharedDateUtility.isToday(date) || date >= new Date();
  }

  /**
   * Retrieves the date from the input field, supposing it was entered as
   * a local timezone.
   *
   * @param dateControl the date form input control
   */
  public static getDateFromLocalInputDate(value: string): Date {
    if (SharedCommonUtility.isNullish(value)) {
      return null;
    }
    const date: Date = new Date(value); // Here date will be parsed in UTC
    const year: number = date.getUTCFullYear();
    const month: number = date.getUTCMonth();
    const day: number = date.getUTCDate();

    date.setFullYear(year, month, day); // Set year, month, date as local timezone
    date.setHours(0, 0, 0, 0); // Set at midnight local time, but may be could use current local time?

    return date;
  }

  /**
   * Retrieves the quarter from given date
   * Jan-Mar: 1
   * Apr-Jun: 2
   * Jul-Sep: 3
   * Oct-Dec: 4
   *
   * @param date input date
   */
  public static getQuarter(date: Date): number {
    return Math.floor((date.getUTCMonth() + 3) / 3);
  }
}
