import {
  Component,
  OnDestroy,
  AfterViewInit,
  AfterViewChecked,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ContentChild,
  ViewChild,
  TemplateRef,
  HostListener,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as KeyCode from 'keycode-js';

import { CommonUtility } from '../../utility/common.utility';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
  private debounceSubscription: Subscription;
  private lastSearchQuery: string;

  public searchQuery: string;
  public isInputFocused: boolean;
  public hoverItemIndex: number;

  @Input() public id: string;
  @Input() public data: any[];
  @Input() public isAutofocused: boolean;

  @Output() public onSearch: EventEmitter<string>;
  @Output() public onSelect: EventEmitter<any>;
  @Output() public onCancel: EventEmitter<void>;

  @ContentChild('typeaheadResultRow') public resultRowTpl: TemplateRef<any>;

  @ViewChild('searchInput') public searchInputRef: ElementRef;
  @ViewChild('resultsList') public resultsListRef: ElementRef;

  constructor() {
    this.isInputFocused = false;

    this.onSearch = new EventEmitter();
    this.onSelect = new EventEmitter();
    this.onCancel = new EventEmitter();
  }

  @HostListener('document:click', ['$event'])
  public onKeyUp(event: MouseEvent): void {
    if (this.searchInputRef.nativeElement !== document.activeElement) {
      this.isInputFocused = false;
      this.hoverItemIndex = undefined;
    }
  }

  public onSearchInputFocused(): void {
    this.isInputFocused = true;
  }

  public onSearchInputKeyDown(event: KeyboardEvent): void {
    if (
      (event.keyCode === KeyCode.KEY_RETURN || event.keyCode === KeyCode.KEY_FIREFOX_ENTER) &&
      typeof this.hoverItemIndex !== 'undefined'
    ) {
      event.preventDefault();
      this.onResultsSelected(this.data[this.hoverItemIndex]);
    } else if (event.keyCode === KeyCode.KEY_ESCAPE) {
      if (
        typeof this.searchQuery === 'undefined' ||
        this.searchQuery.length === 0 ||
        (this.isInputFocused === false && typeof this.hoverItemIndex === 'undefined')
      ) {
        this.onCancel.emit();
      }
      this.isInputFocused = false;
      this.hoverItemIndex = undefined;
    } else if (event.keyCode === KeyCode.KEY_UP || event.keyCode === KeyCode.KEY_DOWN) {
      event.preventDefault();
    } else {
      this.isInputFocused = true;
    }
  }

  public onSearchInputKeyUp(event: KeyboardEvent): void {
    if (event.keyCode === KeyCode.KEY_UP || event.keyCode === KeyCode.KEY_DOWN) {
      if (event.keyCode === KeyCode.KEY_UP) {
        if (typeof this.hoverItemIndex === 'undefined') {
          this.hoverItemIndex = 0;
        }
        if (this.hoverItemIndex === 0) {
          this.hoverItemIndex = this.data.length - 1;
        } else {
          this.hoverItemIndex -= 1;
        }
      } else if (event.keyCode === KeyCode.KEY_DOWN) {
        if (typeof this.hoverItemIndex === 'undefined') {
          this.hoverItemIndex = this.data.length - 1;
        }
        if (this.hoverItemIndex === this.data.length - 1) {
          this.hoverItemIndex = 0;
        } else {
          this.hoverItemIndex += 1;
        }
      }

      if (typeof this.resultsListRef !== 'undefined') {
        const hoveredElement: HTMLDivElement = this.resultsListRef.nativeElement.children[this.hoverItemIndex];
        if (hoveredElement) {
          this.resultsListRef.nativeElement.scrollTop = hoveredElement.offsetTop;
        }
      }
    }
  }

  public onResultsSelected(value: any): void {
    this.onSelect.emit(value);
    this.isInputFocused = false;
  }

  public onResultsMouseEnter(index: number): void {
    this.hoverItemIndex = index;
  }

  public onResultsMouseLeave(index: number): void {
    this.hoverItemIndex = undefined;
  }

  public ngOnDestroy(): void {
    if (typeof this.debounceSubscription !== 'undefined') {
      this.debounceSubscription.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    if (this.isAutofocused) {
      CommonUtility.setFocusToElement(this.id);
    }
  }

  public ngAfterViewChecked(): void {
    if (typeof this.debounceSubscription !== 'undefined') {
      return;
    }

    const onSearch: (text: KeyboardEvent) => void = (text: KeyboardEvent): void => {
      const value: string = this.searchInputRef.nativeElement.value;
      if (this.lastSearchQuery === value) {
        return;
      }
      this.lastSearchQuery = value;
      this.hoverItemIndex = undefined;
      this.onSearch.emit(value);
    };

    this.debounceSubscription = fromEvent(this.searchInputRef.nativeElement, 'keyup')
      .pipe(filter(Boolean), debounceTime(300), distinctUntilChanged(), tap(onSearch))
      .subscribe();
  }
}
