import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SecureResourceApi } from './rest/secure-resource-api';

@Injectable({
  providedIn: 'root',
})
export class SecureResourceService {
  public constructor(private secureResourceApi: SecureResourceApi) {}

  public getSecureResourceBytes(resourceId: string): Observable<Blob> {
    return this.secureResourceApi.getSecureResourceBytes(resourceId);
  }
}
