<span>
  <span class="px-1">
    <ng-container [ngSwitch]="scanViewport.viewportType">
      <svg *ngSwitchCase="viewportType.mobile" height="18" width="18" fill="none">
        <use xlink:href="#mobile-viewport-icon"></use>
      </svg>
      <svg *ngSwitchCase="viewportType.tablet" height="18" width="18" fill="none">
        <use xlink:href="#tablet-viewport-icon"></use>
      </svg>
      <svg *ngSwitchCase="viewportType.desktop" height="18" width="18" fill="none">
        <use xlink:href="#desktop-viewport-icon"></use>
      </svg>
    </ng-container>
  </span>
  <span class="px-1">{{ 'scan_viewport_' + scanViewport.viewportType | translate }}</span>
  <span class="px-1">{{ getViewportLabel() }}</span>
</span>
