import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { catchError, filter, map } from 'rxjs/operators';

import { Api } from '../../../../../shared/constants/api';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';
import { IDigitalPropertyListItem, IDigitalPropertyType } from '../../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty, $digitalPropertyType } from '../../../../../shared/constants/digital-properties';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { UserService } from '../../user.service';
import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { $user } from '../../../../../shared/constants/user';
import { DigitalPropertyService } from '../../digital-property.service';

export const MobileDigitalPropertyGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const forbiddenPage: UrlTree = router.parseUrl(Api.forbidden);

  // property data should be defined by LinkedPropertyDataService during NavigationStart event
  const propertyData: ILinkedPropertyData = LinkedPropertyUtility.fromLinkedPropertyQueryParam(route.queryParams);

  return combineLatest([getMobileDigitalPropertyTypes$(), getUserDigitalProperty$(propertyData[Api.digitalPropertyId])]).pipe(
    map(([mobileTypes, digitalProperty]: [IDigitalPropertyType[], IDigitalPropertyListItem]) => {
      if (SharedCommonUtility.isNullish(digitalProperty)) {
        return forbiddenPage;
      }

      return (
        mobileTypes.some(
          (type: IDigitalPropertyType) => type[$digitalPropertyType._id] === digitalProperty[$digitalProperty.typeId],
        ) || forbiddenPage
      );
    }),
    catchError(() => of(forbiddenPage)),
  );

  function getUserDigitalProperty$(propertyId: string): Observable<IDigitalPropertyListItem> {
    return inject(UserService).userDataChanged$.pipe(
      filter(SharedCommonUtility.notNullish),
      map((user: IUserServerResponse) =>
        user[$user.digitalProperties].find((property: IDigitalPropertyListItem) => property[$digitalProperty._id] === propertyId),
      ),
    );
  }

  function getMobileDigitalPropertyTypes$(): Observable<IDigitalPropertyType[]> {
    const service: DigitalPropertyService = inject(DigitalPropertyService);

    return service
      .getDigitalPropertyTypes()
      .pipe(map((types: IDigitalPropertyType[]) => types.filter((type: IDigitalPropertyType) => service.isMobileType(type))));
  }
};
