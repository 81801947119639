export enum ScanViewportType {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
  custom = 'custom',
}

export enum $scanViewport {
  viewportType = 'viewportType',
  height = 'height',
  width = 'width',
}
