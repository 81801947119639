<app-modal-container>
  <span class="modal-header-content">
    {{ cardTitle | translate }}
  </span>

  <div class="modal-body-content">
    <p class="modal-body-content ms-0">{{ 'task_details_label' | translate }}</p>
    <div>{{ 'required_fields_label' | translate }}</div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (ngSubmit)="submitForm()" class="d-flex flex-column">
          <div *ngIf="availableProjects$ | async as availableProjects">
            <label for="project-selector" [attr.aria-required]="true" class="mb-1">
              {{ 'project' | translate }}
            </label>
            <select
              class="w-100 form-element"
              id="project-selector"
              [formControlName]="$task.projectId"
              (ngModelChange)="onProjectChange($event)"
            >
              <option [ngValue]="null" disabled>{{ 'select_a_project' | translate }}</option>
              <option *ngFor="let project of availableProjects" [ngValue]="project">
                {{ project.name }}
              </option>
            </select>
            <app-form-field-error
              [formValidationRequest$]="formValidationRequest$"
              [field]="form.get($task.projectId)"
              [name]="$task.projectId"
              label="project"
            ></app-form-field-error>

            <div id="issueTracking" *ngIf="userCanPushIssueIntegration$ | async">
              <div class="mt-2">
                <label class="mb-1" for="jira-ticket-type-selector" id="jira-ticket-type-selector-label">
                  {{ 'issue_tracking' | translate }}
                </label>
                <app-common-checkbox
                  [ngModel]="pushTaskToIssueTracking"
                  (ngModelChange)="onPushTaskToIssueTrackingChange($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="(userCanPushIssueIntegration$ | async) === false"
                  class="me-0"
                  [context]="{ describedBy: 'jira-ticket-type-selector-label' }"
                >
                  <span class="checkbox-label fw-normal">{{ issueIntegrationProjectLabel$ | async }}</span>
                </app-common-checkbox>
              </div>

              <div
                *ngIf="
                  pushTaskToIssueTracking && issueTrackingIssueTypes$ | async as issueTrackingIssueTypes;
                  else getIssueTypesError
                "
              >
                <div>
                  <select
                    class="w-100 form-element"
                    id="jira-ticket-type-selector"
                    [formControlName]="$task.issueIntegrationType"
                  >
                    <option [ngValue]="null" disabled selected>{{ selectIssueTypeLabel$ | async }}</option>
                    <option *ngFor="let issueTrackingIssueType of issueTrackingIssueTypes" [ngValue]="issueTrackingIssueType.id">
                      {{ issueTrackingIssueType.name }}
                    </option>
                  </select>
                </div>
                <app-form-field-error
                  [formValidationRequest$]="formValidationRequest$"
                  [field]="form.get($task.issueIntegrationType)"
                  [name]="$task.issueIntegrationType"
                  [label]="'push_task_as'"
                ></app-form-field-error>
              </div>
              <ng-template #getIssueTypesError>
                <div *ngIf="pushTaskToIssueTracking && loadingError$ | async" class="alert alert-danger" role="alert">
                  {{ 'error_loading_jira_project_issue_types' | translate }}
                </div>
              </ng-template>
            </div>
          </div>

          <div class="mt-2">
            <app-common-input
              class="d-block margin-bottom"
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'label_task_form_name',
                placeholder: '',
                field: $task.name,
              }"
            ></app-common-input>
          </div>

          <div class="margin-bottom">
            <app-markdown-editor [formControlName]="$task.description">
              <ng-container class="markdown-editor-header">
                {{ 'label_description' | translate }}
              </ng-container>
            </app-markdown-editor>
            <app-form-field-error
              label="label_description"
              [formValidationRequest$]="formValidationRequest$"
              [field]="form.get($task.description)"
              [name]="$task.description"
            ></app-form-field-error>
          </div>

          <ng-container *ngIf="selectedIssues && selectedIssues.length > 0">
            <label class="label mb-1 text-nowrap" id="linkedFindings">
              {{ 'linked_findings' | translate }}
            </label>
            <ng-container *ngIf="!canUnlinkTask">
              <app-alert
                [type]="alertType.danger"
                [message]="unlinkTaskErrorMessage"
                (onClose)="canUnlinkTask = true"
                class="mb-2 unlink-task-error-message"
              ></app-alert>
            </ng-container>
            <app-ngb-table
              [config]="linkedFindingsTableConfig"
              [data]="linkedFindingsTableRows"
              class="linked-findings-table"
              
            >
            </app-ngb-table>
          </ng-container>

          <app-common-input
            class="d-block margin-bottom"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              disabled: true,
              label: 'label_task_form_status',
              placeholder: '',
              field: $task.status,
              required: false,
            }"
          ></app-common-input>

          <app-common-select
            class="d-block margin-bottom"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="taskPriorityOptions"
            [context]="{
              field: $task.priority,
              label: 'label_task_priority',
              translateName: true,
              nameKey: 'name',
              valueKey: 'priorityValue',
              required: false,
              fullWidthInput: true,
            }"
          ></app-common-select>

          <div class="attachments-container">
            <app-file-drag-and-drop
              [form]="form"
              [formValidationRequest$]="formValidationRequest"
              (fileChange)="onFileUpload($event)"
              (existingUploadsChange)="onExistingAttachmentsChange($event)"
              [(existingUploads)]="attachments"
              [context]="uploaderContext"
              [removableFiles]="true"
              [disabled]="disableUploadButtons$ | async"
            >
              <div *ngIf="showAzureUploadWarning$ | async" class="uploader-warning">
                {{ 'azure_filesize_warning' | translate: azureFileSizeLimit }}
              </div>
            </app-file-drag-and-drop>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between modal-footer-content">
    <button class="btn btn-outline-primary" (click)="container.closeModal()">
      {{ 'label_close' | translate }}
    </button>
    <button class="btn btn-primary" (click)="submitForm()" id="create-task-button">
      {{ submitButton | translate }}
    </button>
  </div>
</app-modal-container>
