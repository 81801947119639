<form [formGroup]="alertEditorForm">
  <div class="recipients-email margin-bottom">
    <app-common-input
      type="email"
      [form]="alertEditorForm"
      [formValidationRequest$]="formValidationRequest$"
      [context]="{
        id: 'alert-editor-email',
        field: formFields.email,
        label: 'recipient_s_email_platform_users_only',
        description: 'recipient_s_email_info',
        overrideErrors: {
          notUniqueEmail: 'form_validation_unique_email' | translate,
          notPlatformEmail: 'form_validation_platform_email' | translate,
        },
      }"
    >
    </app-common-input>
  </div>

  <label class="required label mb-1 text-nowrap">{{ 'send_email_for_alerts' | translate }}</label>
  <div aria-live="polite" role="status" class="error-container">
    <app-form-field-error
      [formValidationRequest$]="formValidationRequest$"
      [field]="alertEditorForm"
      [name]="formFields.atLeastOneAlertSelected"
      [label]="'send_email_for_alerts'"
    ></app-form-field-error>
  </div>

  <div class="scan-completion margin-bottom">
    <app-common-checkbox
      [formGroup]="alertEditorForm"
      [formControlName]="formFields.scanCompletion"
      (change)="onAlertSelectionChanged(formFields.scanCompletion)"
      [context]="{
        id: 'alert-editor-scan-completion',
      }"
    >
      <span class="checkbox-label">{{ formFields.scanCompletion | translate }}</span>
    </app-common-checkbox>
  </div>

  <div class="score-drop margin-bottom">
    <app-common-checkbox
      [formGroup]="alertEditorForm"
      [formControlName]="formFields.scoreDropByPercent"
      (change)="onAlertSelectionChanged(formFields.scoreDropByPercent)"
      [context]="{
        id: 'alert-editor-score-drop',
      }"
    >
      <span class="checkbox-label">{{ formFields.scoreDropByPercent | translate }}</span>
    </app-common-checkbox>

    <div *ngIf="scoreDropSelected" class="score-threshold">
      <app-common-input
        [form]="alertEditorForm"
        [formValidationRequest$]="formValidationRequest$"
        [context]="{
          id: 'alert-editor-score-drop-threshold',
          label: 'threshold_score',
          field: formFields.scoreDropByPercentThreshold,
          autocomplete: 'off',
          width: '5rem',
          type: 'number',
        }"
      >
      </app-common-input>
    </div>
  </div>

  <div class="score-drop-from-previous margin-bottom">
    <app-common-checkbox
      [formGroup]="alertEditorForm"
      [formControlName]="formFields.scoreDropByPercentFromPrevious"
      (change)="onAlertSelectionChanged(formFields.scoreDropByPercentFromPrevious)"
      [context]="{
        id: 'alert-editor-score-drop-from-previous',
      }"
    >
      <span class="checkbox-label">{{ formFields.scoreDropByPercentFromPrevious | translate }}</span>
    </app-common-checkbox>

    <div *ngIf="scoreDropFromPreviousSelected" class="score-threshold">
      <app-common-input
        [form]="alertEditorForm"
        [formValidationRequest$]="formValidationRequest$"
        [context]="{
          id: 'alert-editor-score-drop-from-previous-threshold',
          label: 'label_monitoring_value_percentage_change',
          field: formFields.scoreDropByPercentFromPreviousThreshold,
          autocomplete: 'off',
          width: '5rem',
          type: 'number',
        }"
      >
      </app-common-input>
    </div>
  </div>

  <button type="cancel" class="btn btn-link" (click)="onCancelClicked()">
    {{ 'cancel_changes' | translate }}
  </button>
  <button type="submit" class="btn btn-primary" (click)="onSaveClicked()">
    {{ 'save_recipient' | translate }}
  </button>
</form>
