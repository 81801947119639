<div class="row">
  <div class="col-md-8">
    <h1>{{ pageTitle }}</h1>

    <app-alert
      *ngIf="hasResetFailed === true && isEmailUnverifiedError === false"
      [type]="alertType.danger"
      [header]="errorHeader"
      [message]="errorMessage"
    ></app-alert>

    <app-edit-permissions-banner
      *ngIf="isEmailUnverifiedError === true"
      [bannerType]="BannerType.error"
      (onClose)="onUserUnverifiedEmailBannerClose()"
    >
      {{ 'admin_recently_changed_email' | translate }} <br />
      {{ 'check_your_inbox_verification_email' | translate }}
      <button
        ds-button
        [variant]="DsButtonVariants.microAction"
        [microActionIcon]="Icons.Plus"
        [hideMicroActionIcon]="true"
        (click)="onResendActivationLink()"
      >
        {{ 'resend_verification' | translate }}
      </button>
    </app-edit-permissions-banner>

    <form
      *ngIf="isResetDone === false"
      name="form"
      (ngSubmit)="emailForm.form.valid && resetPassword()"
      #emailForm="ngForm"
      ngNativeValidate
    >
      <p>{{ 'enter_email_we_will_sent_link_to_reset_password' | translate }}</p>
      <fieldset>
        <legend class="visuallyhidden">{{ 'password_reset' | translate }}</legend>

        <div class="row">
          <div class="col-md-3 align-items-center d-flex">
            <label for="email">{{ 'email' | translate }}</label>
          </div>
          <div class="col-md-9">
            <input
              id="email"
              type="email"
              class="form-element"
              name="email"
              required="required"
              [(ngModel)]="email"
              placeholder="{{ 'enter_email_here' | translate }}"
              [attr.aria-invalid]="hasResetFailed && errorMessage !== ''"
            />
            <button type="submit" class="btn btn-primary margin-left" [disabled]="!emailForm.form.valid">
              {{ 'request_password' | translate }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>

    <div *ngIf="isResetDone" aria-live="polite">
      <p>
        {{ 'reset_done' | translate }}
      </p>
      <p>
        {{ 'go_back' | translate }}
        <a routerLink="/login">{{ 'login' | translate }}.</a>
      </p>
    </div>
  </div>
</div>
