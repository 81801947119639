import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IDclConfig,
  ITableColumn,
  ITableConfig,
  ITableEmptyState,
  ITableRow,
  SortEvent,
} from '../../table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../../../translate/translate.service';
import { NgbTableUtilities } from '../../table/ngb-table/utilities/ngb-table.utilities';
import { DEFAULT_ENTRY_LIMITS, DEFAULT_PAGINATION_MAX_SIZE } from '../../../constants/form.constants';
import {
  IAuditRuleServerItem,
  IAuditRuleServerItemSuccessCriteria,
  IAuditRuleServerResponse,
} from '../../../../../shared/interfaces/audit-rule.interface';
import { $auditRule } from '../../../../../shared/constants/audit-rule';
import { $sortingOrder } from '../../../../../shared/constants/sort';
import { Api } from '../../../../../shared/constants/api';
import { ICellConfig } from '../../table/ngb-table/cells/base-cell/base-cell.component';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { IDropdownItem } from '../../table/ngb-table/cells/dropdown-cell/dropdown-cell.component';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { CriteriaPreset, Separator, SuccessCriteriaFormatterService } from '../../../services/success-criteria-formatter.service';
import { $auditStandardSuccessCriteria } from '../../../../../shared/constants/audit-standard-success-criteria';
import {
  AuditDbStandard,
  AuditStandards,
  mapAuditDbStandardToAuditStandard,
} from '../../../../../shared/constants/audit-standard';

@Component({
  selector: 'app-audit-rule-table',
  templateUrl: './audit-rule-table.component.html',
  providers: [NgbPaginationConfig],
})
export class AuditRuleTableComponent implements OnInit {
  private _rules$: Subject<IAuditRuleServerResponse>;
  private pageSize$: BehaviorSubject<number>;
  @Input()
  private page$: BehaviorSubject<number>;
  @Input()
  private emptyStateConfig?: ITableEmptyState;

  private _canEditRule$: BehaviorSubject<boolean>;
  private _canDeleteRule$: BehaviorSubject<boolean>;

  public pageData$: Observable<ITableRow[]>;
  public pageSizes: readonly number[];
  public paginationLabel$: Observable<string>;
  public taskCount$: Observable<number>;
  public tableConfig$: Observable<ITableConfig>;

  @Input()
  public set rules(value: IAuditRuleServerResponse) {
    this._rules$.next(value);
  }

  @Input()
  public set canEditRule(value: boolean) {
    this._canEditRule$.next(value);
  }

  @Input()
  public set canDeleteRule(value: boolean) {
    this._canDeleteRule$.next(value);
  }

  public set pageSize(value: number) {
    this.pageSize$.next(value);
    this.pageSizeChanged.next(value);
  }

  public get pageSize(): number {
    return this.pageSize$.value;
  }

  public set page(value: number) {
    this.page$.next(value);
    this.viewportScroller.scrollToPosition([0, 0]);
    this.pageChanged.next(value);
  }

  public get page(): number {
    return this.page$.value;
  }

  @Output()
  public pageChanged: EventEmitter<number>;
  @Output()
  public pageSizeChanged: EventEmitter<number>;
  @Output()
  public sortChange: EventEmitter<[$auditRule, $sortingOrder]>;
  @Output()
  public auditRuleDeleted: EventEmitter<string>;

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private viewportScroller: ViewportScroller,
    private ngbPaginationConfig: NgbPaginationConfig,
    private errorHandlerService: ErrorHandlerService,
    private successCriteriaFormatterService: SuccessCriteriaFormatterService,
  ) {
    this.pageSizes = DEFAULT_ENTRY_LIMITS;
    this.pageSize$ = new BehaviorSubject<number>(DEFAULT_ENTRY_LIMITS[DEFAULT_ENTRY_LIMITS.length - 1]);
    this.page$ = new BehaviorSubject<number>(1);
    this.pageChanged = new EventEmitter<number>();
    this.pageSizeChanged = new EventEmitter<number>();
    this.sortChange = new EventEmitter<[$auditRule, $sortingOrder]>();
    this.auditRuleDeleted = new EventEmitter<string>();
    this._canEditRule$ = new BehaviorSubject(false);
    this._canDeleteRule$ = new BehaviorSubject(false);
    this.ngbPaginationConfig.maxSize = DEFAULT_PAGINATION_MAX_SIZE;

    this._rules$ = new Subject<IAuditRuleServerResponse>();
  }

  private editAuditRule(id: string): void {
    this.router
      .navigate([id, Api.edit], { relativeTo: this.activatedRoute })
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  private ruleToPageRow(rule: IAuditRuleServerItem, canEditRule: boolean, canDeleteRule: boolean): ITableRow {
    const getSuccessCriteriaStandards = (successCriteria: IAuditRuleServerItemSuccessCriteria): AuditStandards[] => {
      if (SharedCommonUtility.notNullishOrEmpty(successCriteria['standard'])) {
        return Array.isArray(successCriteria['standard']) ? successCriteria['standard'] : [successCriteria['standard']];
      }

      return Array.isArray(successCriteria[$auditStandardSuccessCriteria.auditStandards])
        ? successCriteria[$auditStandardSuccessCriteria.auditStandards].map(
            (auditDbStandard: AuditDbStandard): AuditStandards => mapAuditDbStandardToAuditStandard[auditDbStandard],
          )
        : [mapAuditDbStandardToAuditStandard[successCriteria[$auditStandardSuccessCriteria.auditStandards]]];
    };

    const data: Record<string, IDclConfig<ICellConfig>> = {
      [$auditRule.ruleId]: NgbTableUtilities.linkCell({
        text: rule.ruleId,
        attributes: {
          routerLink: `./${rule.ruleId}/${Api.view}`,
        },
      }),
      [$auditRule.description]: NgbTableUtilities.textCell({ text: rule.description }),
      [$auditRule.successCriterias]: NgbTableUtilities.htmlCell({
        text: [
          ...new Set(
            rule[$auditRule.successCriterias]?.flatMap((successCriteria: IAuditRuleServerItemSuccessCriteria) => {
              return this.successCriteriaFormatterService.toDisplayCriterias({
                standards: getSuccessCriteriaStandards(successCriteria),
                identifiers: [successCriteria[$auditStandardSuccessCriteria.identifier]],
                separator: Separator.newLine,
                preset: CriteriaPreset.criteriaIdentifierWithLevel,
              });
            }),
          ),
        ].join('\n'),
        classes: ['pre-line'],
      }),
      [$auditRule.toolVersions]: NgbTableUtilities.htmlCell({
        text: [
          ...new Set(
            rule.successCriterias?.flatMap((successCriteria: IAuditRuleServerItemSuccessCriteria) => {
              return this.successCriteriaFormatterService.toDisplayCriterias({
                standards: getSuccessCriteriaStandards(successCriteria),
                identifiers: [successCriteria[$auditStandardSuccessCriteria.identifier]],
                separator: Separator.newLine,
                preset: CriteriaPreset.criteriaVersions,
              });
            }),
          ),
        ].join('\n'),
        classes: ['pre-line'],
      }),
      [$auditRule.severity]: NgbTableUtilities.severityCell({ severity: rule.severity }),
    };

    const editAuditRuleItem: IDropdownItem = {
      label: this.translateService.instant('action_edit'),
      clickListener: this.editAuditRule.bind(this, rule[$auditRule.ruleId]),
    };
    const deleteAuditRuleItem: IDropdownItem = {
      label: this.translateService.instant('action_delete'),
      clickListener: () => this.auditRuleDeleted.emit(rule[$auditRule.ruleId]),
    };

    const actionItems: IDropdownItem[] = [
      ...(canEditRule ? [editAuditRuleItem] : []),
      ...(canDeleteRule ? [deleteAuditRuleItem] : []),
    ];

    if (SharedCommonUtility.notNullishOrEmpty(actionItems)) {
      data['actions'] = NgbTableUtilities.dropdownCell({
        ellipsisIcon: true,
        classes: ['btn-link', 'px-0'],
        dropdownItems: actionItems,
        ariaLabel: this.translateService.instant('action_for_rule', [rule[$auditRule.ruleId]]),
      });
    }

    return { data };
  }

  public getTableConfig(canEditRule: boolean, canDeleteRule: boolean): ITableConfig {
    const columns: Record<string, ITableColumn> = {
      [$auditRule.ruleId]: {
        translationKey: 'issue_field_ruleId',
        sortingEnabled: true,
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      },
      [$auditRule.description]: {
        translationKey: 'issue_description',
        sortingEnabled: true,
        styles: {
          maxWidth: '1px',
          minWidth: '300px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
      [$auditRule.successCriterias]: {
        translationKey: 'issue_field_successCriteria',
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      },
      [$auditRule.toolVersions]: {
        translationKey: 'version',
        styles: {
          maxWidth: '7.5%',
          width: '7.5%',
        },
      },
      [$auditRule.severity]: {
        translationKey: 'issue_field_severity',
        sortingEnabled: true,
        styles: {
          maxWidth: '7.5%',
          width: '7.5%',
        },
      },
    };

    if (canEditRule || canDeleteRule) {
      columns['actions'] = {
        translationKey: 'table_column_actions',
        styles: {
          width: '7.5%',
          maxWidth: '7.5%',
        },
      };
    }

    return {
      columns: columns,
      caption: this.translateService.instant('table_caption_tasks'),
      emptyState: SharedCommonUtility.notNullish(this.emptyStateConfig)
        ? this.emptyStateConfig
        : {
            title: this.translateService.instant('no_search_results_available'),
            subtitle: this.translateService.instant('label_no_search_results_hint'),
          },
    };
  }

  public onSort(event: SortEvent): void {
    this.sortChange.next([$auditRule[event.column], event.direction]);
  }

  public ngOnInit(): void {
    this.pageData$ = combineLatest([this._rules$, this._canEditRule$, this._canDeleteRule$]).pipe(
      map(([rules, canEditRule, canDeleteRule]: [IAuditRuleServerResponse, boolean, boolean]) => {
        return (rules.auditRules as IAuditRuleServerItem[]).map(
          (auditRule: IAuditRuleServerItem): ITableRow => this.ruleToPageRow(auditRule, canEditRule, canDeleteRule),
        );
      }),
    );
    this.tableConfig$ = combineLatest([this._canEditRule$, this._canDeleteRule$]).pipe(
      map(([canEditRule, canDeleteRule]: [boolean, boolean]): ITableConfig => this.getTableConfig(canEditRule, canDeleteRule)),
    );
    this.taskCount$ = this._rules$.pipe(map((rules: IAuditRuleServerResponse) => rules._total));
    this.paginationLabel$ = combineLatest([this.taskCount$, this.page$, this.pageSize$]).pipe(
      map(([taskCount, page, pageSize]: [number, number, number]) => {
        const startIndex = Math.min((page - 1) * pageSize + 1, taskCount);
        const lastIndex = Math.min(startIndex + pageSize - 1, taskCount);
        return this.translateService.instant('n_of_t', [`${startIndex} - ${lastIndex}`, String(taskCount)]);
      }),
    );
  }
}
