<div class="mb-4 sign-up-overview-description">
  {{ description }}
</div>

<div class="mb-4">
  <ds-link
    href="https://client.levelaccess.com/hc/en-us/articles/12507620329111-User-groups"
    [external]="true"
    [standalone]="true"
    
  >
    {{ 'sign_up_overview_learn_more' | translate }}
  </ds-link>
</div>

<div class="mb-5">
  <ul class="sign-up-overview-workspace-list fa-ul">
    <li *ngFor="let workspace of userWorkspaces" class="mb-4">
      <span class="fa-li">
        <ds-icon [icon]="Icons.Briefcase" [iconStyle]="IconStyles.Light"></ds-icon>
      </span>
      {{ workspace.name }}
      <ul class="sign-up-overview-website-list">
        <li *ngFor="let websiteAppName of workspace.websiteAppNames">
          {{ websiteAppName }}
        </li>
      </ul>
    </li>
  </ul>
</div>

<div>
  <button type="button" ds-button [variant]="DsButtonVariants.primary" (click)="onSubmit()" >
    {{ 'sign_up_finish' | translate }}
  </button>
</div>
