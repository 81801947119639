import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

enum FocusedWindowClassNames {
  focused = 'app-focused',
  blurred = 'app-blurred',
}

@Injectable({
  providedIn: 'root',
})
export class FocusedWindowService {
  private focused: BehaviorSubject<boolean>;
  private subscriptions: Subscription;

  constructor() {
    this.focused = new BehaviorSubject<boolean>(true);
    window.document.body.classList.add(FocusedWindowClassNames.focused);
    this.subscriptions = new Subscription();
  }

  public whenFocused<T>(action: Observable<T>): Observable<T> {
    return action.pipe(filter((): boolean => this.focused.value));
  }

  public init(): void {
    this.subscriptions.unsubscribe();

    const onFocus = (): void => {
      this.focused.next(true);
      window.document.body.classList.remove(FocusedWindowClassNames.blurred);
      window.document.body.classList.add(FocusedWindowClassNames.focused);
    };

    const onBlur = (): void => {
      this.focused.next(false);
      window.document.body.classList.remove(FocusedWindowClassNames.focused);
      window.document.body.classList.add(FocusedWindowClassNames.blurred);
    };

    this.subscriptions.add(
      fromEvent(window, 'focus').subscribe({
        next: onFocus,
      }),
    );

    this.subscriptions.add(
      fromEvent(window, 'blur').subscribe({
        next: onBlur,
      }),
    );
  }

  public destroy(): void {
    this.subscriptions.unsubscribe();
  }
}
