import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkService {
  constructor(private appConfigService: AppConfigService) {}

  public getZendeskTicketUrl(ticketNumber: number): string {
    return `${this.getBaseZendeskUrl()}/agent/tickets/${ticketNumber}`;
  }

  public getZendeskSubmitTicketUrl(): string {
    return `${this.getBaseZendeskUrl()}/hc/en-us/requests/new`;
  }

  public getHelpCenterUrl(): string {
    return `${this.getBaseZendeskUrl()}/hc/en-us`;
  }

  public getEmailConsultationFormUrl(): string {
    return `${this.getZendeskSubmitTicketUrl()}?ticket_form_id=${this.appConfigService.getZendeskEmailConsultationFormId()}`;
  }

  public getManualEvaluationServiceRequestFormUrl(): string {
    return `${this.getZendeskSubmitTicketUrl()}?ticket_form_id=${this.appConfigService.getZendeskManualEvaluationServiceRequestFormId()}`;
  }

  public getProductSupportFormUrl(): string {
    return `${this.getZendeskSubmitTicketUrl()}?ticket_form_id=${this.appConfigService.getZendeskProductSupportFormId()}`;
  }

  public getProductRoadmapUrl(): string {
    return 'https://portal.productboard.com/esstp6cmyupxmfjysyeye5rm/tabs/2-under-consideration';
  }

  public getValidationTestingServiceRequestFormUrl(): string {
    return `${this.getManualEvaluationServiceRequestFormUrl()}&test_type=progression`;
  }

  private getBaseZendeskUrl(): string {
    const zendeskUrl: string = this.appConfigService.getZendeskUrl();
    return zendeskUrl.endsWith('/') ? zendeskUrl.slice(0, -1) : zendeskUrl;
  }
}
