import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';

const legacy: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: '',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '',
    [$successCriteria.title]: '',
    [$successCriteria.versions]: [''],
    [$successCriteria.url]: '',
  },
];

export class Legacy implements ISuccessCriteriaIndex {
  public get(num: string): ISuccessCriteria {
    return legacy[0];
  }

  public getAll(): ISuccessCriteria[] {
    return legacy;
  }
}
