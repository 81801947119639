export enum $sortingOrder {
  all = 'all',
  asc = 'asc',
  desc = 'desc',
}

export const $sortOrderToMongo = {
  [$sortingOrder.asc]: 1,
  [$sortingOrder.desc]: -1,
} as const;
