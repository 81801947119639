import { Injectable } from '@angular/core';
import { Router, Event, NavigationStart, NavigationExtras, Params } from '@angular/router';
import { filter, map, mergeMap, take, switchMap, catchError } from 'rxjs/operators';
import { from, Observable, of, Subscription } from 'rxjs';

import { UserService } from './user.service';
import { ErrorHandlerService } from './error-handler.service';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';
import { IDigitalPropertyListItem } from '../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { $user } from '../../../shared/constants/user';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { LinkedPropertyUtility } from '../../../shared/utils/linked-property.utility';
import { Api } from '../../../shared/constants/api';
import { getQueryParams, mergeUrlParams } from '../utility/url.utility';

@Injectable({ providedIn: 'root' })
export class LinkedPropertyDataService {
  constructor(
    private userService: UserService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  public preserveLinkedPropertyParam(): Subscription {
    return this.router.events
      .pipe(
        /*
         * Important: it must NOT be NavigationEnd, or you won't prevent navigation, and the browser's 'back' button won't work.
         */
        filter((e: Event) => e instanceof NavigationStart),
        filter((e: NavigationStart) => LinkedPropertyUtility.hasLinkedPropertyData(getQueryParams(e.url)) === false),
        filter((e: NavigationStart) => {
          const [urlWithoutParams]: string[] = e.url.split('?');
          const isRoot: boolean = urlWithoutParams === '/';
          const isLogin: boolean = urlWithoutParams.includes(Api.login);
          const isShortLink: boolean = urlWithoutParams.includes(Api.short_link);
          const isIssueIntegrationCallback: boolean = urlWithoutParams.includes(`${Api.issue_tracking}/${Api.callback}`);
          return !(isRoot || isShortLink || isIssueIntegrationCallback || isLogin);
        }),
        switchMap((e: NavigationStart) =>
          this.getDigitalPropertyFromParams(getQueryParams(e.url)).pipe(
            take(1),
            map((property: IDigitalPropertyListItem) => [e, property]),
          ),
        ),
        filter(
          ([, property]: [NavigationStart, IDigitalPropertyListItem]) => SharedCommonUtility.getTypeOf(property) === 'object',
        ),
        switchMap(([e, property]: [NavigationStart, IDigitalPropertyListItem]) => {
          const [urlWithoutParams, queryParams, fragment] = mergeUrlParams(
            e.url,
            LinkedPropertyUtility.getLinkedPropertyQueryParam(
              property[$digitalProperty._id],
              property[$digitalProperty.workspace]._id,
            ),
          );
          const navigationExtras: NavigationExtras = this.router.getCurrentNavigation()?.extras || {};

          return from(
            this.router
              .navigate([urlWithoutParams], {
                ...navigationExtras,
                queryParams: queryParams,
                fragment: fragment,
              })
              .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService)),
          );
        }),
        catchError((err: any) => {
          console.error('[LinkedPropertyDataService.preserveLinkedPropertyParam]', err);
          return of(false);
        }),
      )
      .subscribe();
  }

  private getDigitalPropertyFromParams(queryParams: Params): Observable<IDigitalPropertyListItem> {
    if (!LinkedPropertyUtility.hasLinkedPropertyParam(queryParams)) {
      return this.userService.currentDigitalProperty$;
    }

    return this.userService.userDataChanged$.pipe(
      filter((user: IUserServerResponse) => SharedCommonUtility.notNullish(user)),
      map((user: IUserServerResponse): IDigitalPropertyListItem => {
        const { digitalPropertyId, workspaceId } = LinkedPropertyUtility.fromLinkedPropertyQueryParam(queryParams);

        const byWorkspaceId = (item: IDigitalPropertyListItem): boolean =>
          workspaceId ? item[$digitalProperty.workspace]._id === workspaceId : true;
        const byDigitalPropertyId = (item: IDigitalPropertyListItem): boolean =>
          digitalPropertyId ? item[$digitalProperty._id] === digitalPropertyId : true;

        const userDigitalProperties: IDigitalPropertyListItem[] = user[$user.digitalProperties].filter(byWorkspaceId);
        return userDigitalProperties.find(byDigitalPropertyId) || userDigitalProperties[0];
      }),
      mergeMap((userDigitalProperty: IDigitalPropertyListItem) =>
        SharedCommonUtility.notNullish(userDigitalProperty) ? of(userDigitalProperty) : this.userService.currentDigitalProperty$,
      ),
    );
  }
}
