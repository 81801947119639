import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { random } from 'lodash';

@Component({
  selector: 'app-form-field-char-count',
  templateUrl: './form-field-char-count.component.html',
})
export class FormFieldCharCountComponent {
  /**
   * Max character limit. Defaults to 100.
   */
  @Input() public charLimit: number = 100;
  @Input() public field: AbstractControl;
  @Input() public getLength: (control: AbstractControl) => number = (control: AbstractControl): number =>
    control.value?.length ?? 0;

  public id: string;

  constructor() {
    this.id = `FormFieldCharCountComponent_${random(100000000, 999999999, false)}`;
  }
}
