<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="none">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="1, 0, 0, 0, 0
                0, 1, 0, 0, 0
                0, 0, 1, 0, 0
                0, 0, 0, 1, 0"
      />
    </filter>
    <filter id="protanopia">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.567, 0.433, 0,     0, 0
                0.558, 0.442, 0,     0, 0
                0,     0.242, 0.758, 0, 0
                0,     0,     0,     1, 0"
      />
    </filter>
    <filter id="protanomaly">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.817, 0.183, 0,     0, 0
                0.333, 0.667, 0,     0, 0
                0,     0.125, 0.875, 0, 0
                0,     0,     0,     1, 0"
      />
    </filter>
    <filter id="deuteranopia">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.625, 0.375, 0,   0, 0
                0.7,   0.3,   0,   0, 0
                0,     0.3,   0.7, 0, 0
                0,     0,     0,   1, 0"
      />
    </filter>
    <filter id="deuteranomaly">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.8,   0.2,   0,     0, 0
                0.258, 0.742, 0,     0, 0
                0,     0.142, 0.858, 0, 0
                0,     0,     0,     1, 0"
      />
    </filter>
    <filter id="tritanopia">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.95, 0.05,  0,     0, 0
                0,    0.433, 0.567, 0, 0
                0,    0.475, 0.525, 0, 0
                0,    0,     0,     1, 0"
      />
    </filter>
    <filter id="tritanomaly">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.967, 0.033, 0,     0, 0
                0,     0.733, 0.267, 0, 0
                0,     0.183, 0.817, 0, 0
                0,     0,     0,     1, 0"
      />
    </filter>
    <filter id="achromatopsia">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.299, 0.587, 0.114, 0, 0
                  0.299, 0.587, 0.114, 0, 0
                  0.299, 0.587, 0.114, 0, 0
                  0,     0,     0,     1, 0"
      />
    </filter>
    <filter id="achromatomaly">
      <feColorMatrix
        in="SourceGraphic"
        type="matrix"
        values="0.618, 0.320, 0.062, 0, 0
                0.163, 0.775, 0.062, 0, 0
                0.163, 0.320, 0.516, 0, 0
                0,     0,     0,     1, 0"
      />
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="high_contrast">
      <feComponentTransfer>
        <feFuncR type="gamma" exponent="3.0" />
        <feFuncG type="gamma" exponent="3.0" />
        <feFuncB type="gamma" exponent="3.0" />
      </feComponentTransfer>
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="high_contrast_back">
      <feComponentTransfer>
        <feFuncR type="gamma" exponent="0.33" />
        <feFuncG type="gamma" exponent="0.33" />
        <feFuncB type="gamma" exponent="0.33" />
      </feComponentTransfer>
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="grayscale">
      <feColorMatrix
        type="matrix"
        values="0.2126 0.7152 0.0722 0 0
                0.2126 0.7152 0.0722 0 0
                0.2126 0.7152 0.0722 0 0
                0      0      0      1 0"
      />
      <feComponentTransfer>
        <feFuncR type="gamma" exponent="3" />
        <feFuncG type="gamma" exponent="3" />
        <feFuncB type="gamma" exponent="3" />
      </feComponentTransfer>
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="grayscale_back">
      <feComponentTransfer>
        <feFuncR type="gamma" exponent="0.33" />
        <feFuncG type="gamma" exponent="0.33" />
        <feFuncB type="gamma" exponent="0.33" />
      </feComponentTransfer>
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="invert">
      <feComponentTransfer>
        <feFuncR type="gamma" amplitude="-1" exponent="3" offset="1" />
        <feFuncG type="gamma" amplitude="-1" exponent="3" offset="1" />
        <feFuncB type="gamma" amplitude="-1" exponent="3" offset="1" />
      </feComponentTransfer>
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="invert_back">
      <feComponentTransfer>
        <feFuncR type="table" tableValues="1 0" />
        <feFuncG type="table" tableValues="1 0" />
        <feFuncB type="table" tableValues="1 0" />
      </feComponentTransfer>
      <feComponentTransfer>
        <feFuncR type="gamma" exponent="1.7" />
        <feFuncG type="gamma" exponent="1.7" />
        <feFuncB type="gamma" exponent="1.7" />
      </feComponentTransfer>
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="invert_grayscale">
      <feColorMatrix
        type="matrix"
        values="0.2126 0.7152 0.0722 0 0
                0.2126 0.7152 0.0722 0 0
                0.2126 0.7152 0.0722 0 0
                0      0      0      1 0"
      />
      <feComponentTransfer>
        <feFuncR type="gamma" amplitude="-1" exponent="3" offset="1" />
        <feFuncG type="gamma" amplitude="-1" exponent="3" offset="1" />
        <feFuncB type="gamma" amplitude="-1" exponent="3" offset="1" />
      </feComponentTransfer>
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="yellow_on_black">
      <feComponentTransfer>
        <feFuncR type="gamma" amplitude="-1" exponent="3" offset="1" />
        <feFuncG type="gamma" amplitude="-1" exponent="3" offset="1" />
        <feFuncB type="gamma" amplitude="-1" exponent="3" offset="1" />
      </feComponentTransfer>
      <feColorMatrix
        type="matrix"
        values="0.3 0.5 0.2 0 0
                0.3 0.5 0.2 0 0
                0   0   0   0 0
                0   0   0   1 0"
      />
    </filter>
    <filter x="0" y="0" width="100%" height="100%" id="yellow_on_black_back">
      <feComponentTransfer>
        <feFuncR type="table" tableValues="1 0" />
        <feFuncG type="table" tableValues="1 0" />
        <feFuncB type="table" tableValues="1 0" />
      </feComponentTransfer>
      <feComponentTransfer>
        <feFuncR type="gamma" exponent="0.33" />
        <feFuncG type="gamma" exponent="0.33" />
        <feFuncB type="gamma" exponent="0.33" />
      </feComponentTransfer>
    </filter>
  </defs>
</svg>
