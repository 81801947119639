import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { AppConfigService } from '../../app-config.service';
import { Api } from '../../../../../shared/constants/api';

@Injectable()
export class NonSsoTenantGuard {
  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const hasAccess: boolean = !this.appConfigService.isUsingExternalIdp();
    if (!hasAccess) {
      return of(this.router.createUrlTree([`/${Api.forbidden}`], { queryParams: { url: state.url, title: next.data.title } }));
    }
    return of(hasAccess);
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(next, state);
  }
}
