import { Observable, Subscriber } from 'rxjs';

export class FileUtility {
  public static readFileAsText(file: File): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      const reader = new FileReader();

      reader.onerror = (event: ProgressEvent<FileReader>): void => subscriber.error(event);
      reader.onabort = (event: ProgressEvent<FileReader>): void => subscriber.error(event);
      reader.onload = (event: ProgressEvent<FileReader>): void => subscriber.next(event.target.result.toString());
      reader.onloadend = (): void => subscriber.complete();

      return reader.readAsText(file);
    });
  }
}
