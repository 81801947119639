export enum $auditIssuesView {
  ruleDescription = 'ruleDescription',
  successCriteriaConformanceLevel = 'successCriteriaConformanceLevel',
  successCriteriaIdentifier = 'successCriteriaIdentifier',
  severity = 'severity',
  severityNumber = 'severityNumber',
  failures = 'failures',
  passes = 'passes',
  ignores = 'ignores',
  triggered = 'triggered',
  link = 'link',
  queryParams = 'queryParams',
  scoreImpact = 'scoreImpact',
  findings = 'findings',
  testType = 'testType',
}

export enum auditIssuesViewStatus {
  failed = 'failed',
  review = 'review',
  passed = 'passed',
}
