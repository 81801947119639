import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SecurityUsersApi } from './rest/security-users.api';
import {
  ICreateUsersRequest,
  ISecurityUserWithGroupsResponse,
  ISecurityGroupsWithSecurityGroupUserLastUpdatedInTenant,
  IAssignUsersRequest,
} from '../../../shared/interfaces/security-group.interface';
import { IAssignUsersResponse, IGetOrCreateUserResponse, IUsersServerResponse } from '../../../shared/interfaces/user.interface';
import { IBulkAddToStaffGroupsResponse } from '../../../shared/interfaces/security.interface';

@Injectable({
  providedIn: 'root',
})
export class SecurityUserService {
  public constructor(private securityUsersApi: SecurityUsersApi) {}

  public findUsersWithSecurityGroupsByTenantId(
    limit: number,
    skip: number,
    searchTerm?: string,
    workspaceId?: string,
    digitalPropertyId?: string,
  ): Observable<ISecurityUserWithGroupsResponse> {
    return this.securityUsersApi.findUsersWithSecurityGroupsByTenantId(limit, skip, searchTerm, workspaceId, digitalPropertyId);
  }

  public getUnassignedUsers(limit: number, skip: number, searchTerm?: string): Observable<IUsersServerResponse> {
    return this.securityUsersApi.getUnassignedUsers(limit, skip, searchTerm);
  }

  public assignSecurityUsers(request: IAssignUsersRequest): Observable<IAssignUsersResponse> {
    return this.securityUsersApi.assignSecurityUsers(request);
  }

  public createSecurityUsers(request: ICreateUsersRequest): Observable<IGetOrCreateUserResponse> {
    return this.securityUsersApi.createSecurityUsers(request);
  }

  public removeUser(userId: string): Observable<void> {
    return this.securityUsersApi.removeUser(userId);
  }

  public getSecurityUserTenantsMembership(userId: string): Observable<ISecurityGroupsWithSecurityGroupUserLastUpdatedInTenant[]> {
    return this.securityUsersApi.getSecurityUserTenantsMembership(userId);
  }

  public addUserToSecurityGroups(userId: string, groupIds: string[]): Observable<void> {
    return this.securityUsersApi.addUserToSecurityGroups(userId, groupIds);
  }

  public updateUserProfile(userId: string, userProfile: FormData): Observable<void> {
    return this.securityUsersApi.updateUserProfile(userProfile, userId);
  }

  public updateUserSecurityGroups(userId: string, groupIds: { groupIds: string[] }): Observable<void> {
    return this.securityUsersApi.updateUserSecurityGroups(userId, groupIds);
  }

  public removeUserFromSecurityGroups(userId: string, groupIds: string[]): Observable<void> {
    return this.securityUsersApi.removeUserFromSecurityGroups(userId, groupIds);
  }

  public resendInvite(userId: string): Observable<void> {
    return this.securityUsersApi.resendInvite(userId);
  }

  public bulkAddToStaffSecurityGroups(
    userId: string,
    staffSecurityName: string,
    tenantIds: string[],
  ): Observable<IBulkAddToStaffGroupsResponse> {
    return this.securityUsersApi.bulkAddToStaffSecurityGroups(userId, staffSecurityName, tenantIds);
  }
}
