import { Directive, ElementRef, OnInit } from '@angular/core';

import { NgControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input, select, textarea',
})
export class NativeElementInjectorDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private control: NgControl,
  ) {}

  public ngOnInit(): void {
    if (typeof this.control.control === 'undefined') {
      return;
    }

    (this.control.control as any).nativeElement = this.el.nativeElement;
  }
}
