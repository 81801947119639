import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';

import { StepComponent } from '../step/step.component';
import { StepNextDirective } from '../directives/step-next.directive';
import { StepPreviousDirective } from '../directives/step-previous.directive';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { CommonUtility } from '../../../utility/common.utility';

@Component({
  selector: 'app-horizontal-stepper',
  styleUrls: ['./horizontal-stepper.component.scss'],
  templateUrl: './horizontal-stepper.component.html',
  exportAs: 'appHorizontalStepper',
})
export class HorizontalStepperComponent implements AfterContentInit {
  private subscriptions: Subscription = new Subscription();
  private ACTIVE_STEP: number = 0;

  @Output() public activeStepChange: EventEmitter<number> = new EventEmitter();

  @ContentChildren(StepComponent, { descendants: true })
  public steps: QueryList<StepComponent>;

  @ContentChild(StepNextDirective)
  public stepNext: StepNextDirective;

  @ContentChild(StepPreviousDirective)
  public stepPrevious: StepPreviousDirective;

  constructor(private viewportScroller: ViewportScroller) {}

  private setPanelIds(): void {
    if (!SharedCommonUtility.isNullish(this.steps)) {
      this.steps.forEach((step: StepComponent): void => {
        step.id = CommonUtility.createUniqueDOMId();
        step.setHostAttributes();
      });
    }
  }

  private setActiveStatus(step: number, active: boolean = true): void {
    const stepComponent: StepComponent = this.steps.get(step);
    if (!SharedCommonUtility.isNullish(stepComponent)) {
      stepComponent.isActive = active;
    }
  }

  private registerNextStep(): void {
    if (!SharedCommonUtility.isNullish(this.stepNext)) {
      this.subscriptions.add(this.stepNext.click$.subscribe(this.nextStep.bind(this)));
    }
  }

  private registerPreviousStep(): void {
    if (!SharedCommonUtility.isNullish(this.stepPrevious)) {
      this.subscriptions.add(this.stepPrevious.click$.subscribe(this.previousStep.bind(this)));
    }
  }

  @Input()
  set activeStep(value: number) {
    if (value !== this.ACTIVE_STEP) {
      this.setActiveStatus(this.ACTIVE_STEP, false);
      this.ACTIVE_STEP = value;
      this.setActiveStatus(value);
      this.activeStepChange.emit(this.ACTIVE_STEP);
    }
  }

  get activeStep(): number {
    return this.ACTIVE_STEP;
  }

  public previousStep(): void {
    if (this.ACTIVE_STEP > 0) {
      this.activeStep--;
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }

  public nextStep(): void {
    if (this.ACTIVE_STEP < this.steps.length - 1) {
      this.activeStep++;
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }

  public ngAfterContentInit(): void {
    this.setPanelIds();
    this.setActiveStatus(this.ACTIVE_STEP);
    this.registerNextStep();
    this.registerPreviousStep();
  }
}
