import { SIZE_5_MB } from './uploads';

export enum $screenshot {
  _id = '_id',
  error = 'error',
  regularScreenshot = 'regularScreenshot',
  thumbnailScreenshot = 'thumbnailScreenshot',
  auditedDocument = 'auditedDocument',
}

export const SCREENSHOT_MAX_FILE_SIZE: number = SIZE_5_MB;
