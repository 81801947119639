import { workspaceActivityLogEvent } from './workspace-activity-log.constants';

/**
 * issueIntegrationTaskCommentsReflected is a single settings option to select multiple events:
 * commentAddedToIssueIntegrationTask,
 * commentUpdatedOnIssueIntegrationTask,
 * commentDeletedFromIssueIntegrationTask
 *
 * taskAssigneeChanged is a single settings option to select multiple events:
 * taskAssigned,
 * taskReassigned,
 * taskUnassigned
 */
export enum $compoundNotificationSettings {
  issueIntegrationTaskCommentsReflected = 'issueIntegrationTaskCommentsReflected',
  taskAssigneeChanged = 'taskAssigneeChanged',
  findingDismissed = 'findingDismissed',
  taskCreatedFromFinding = 'taskCreatedFromFinding',
}

export enum $notificationSettings {
  commentAddedToManualFinding = workspaceActivityLogEvent.commentAddedToManualFinding,
  commentAddedToProjectTask = workspaceActivityLogEvent.commentAddedToProjectTask,
  projectTaskStatusChanged = workspaceActivityLogEvent.projectTaskStatusChanged,
  manualFindingStatusChanged = workspaceActivityLogEvent.manualFindingStatusChanged,
  taskCreatedFromFinding = $compoundNotificationSettings.taskCreatedFromFinding,
  findingDismissed = $compoundNotificationSettings.findingDismissed,
  documentAddedToDigitalProperty = workspaceActivityLogEvent.documentAddedToDigitalProperty,
  documentRemovedFromDigitalProperty = workspaceActivityLogEvent.documentRemovedFromDigitalProperty,
  documentUpdatedInDigitalProperty = workspaceActivityLogEvent.documentUpdatedInDigitalProperty,
  issueIntegrationTaskCommentsReflected = $compoundNotificationSettings.issueIntegrationTaskCommentsReflected,
  scanPerformedAndCompleted = workspaceActivityLogEvent.scanPerformedAndCompleted,
  monitoringScanScoreDropFromPrevious = workspaceActivityLogEvent.monitoringScanScoreDropFromPrevious,
  monitoringScanScoreDrop = workspaceActivityLogEvent.monitoringScanScoreDrop,
  monitoringScanComplete = workspaceActivityLogEvent.monitoringScanComplete,
  scanReportEmailed = workspaceActivityLogEvent.scanReportEmailed,
  userJoined = workspaceActivityLogEvent.userJoined,
  taskAssigneeChanged = $compoundNotificationSettings.taskAssigneeChanged,
  remediatedPdfUploaded = workspaceActivityLogEvent.remediatedPdfUploaded,
  remediationRequestRevokedForDocument = workspaceActivityLogEvent.remediationRequestRevokedForDocument,
  taskSupportRequest = workspaceActivityLogEvent.taskSupportRequest,
  asyncReportGenerationCompleted = workspaceActivityLogEvent.asyncReportGenerationCompleted,
  requestValidationTesting = workspaceActivityLogEvent.requestValidationTesting,
}

export const allNotificationsId = 'allNotifications';

export const verifyConfirmationEmailNotificationId: string = 'verifyConfirmationEmailNotification';

export const compoundNotificationSettings: ReadonlyMap<$compoundNotificationSettings, workspaceActivityLogEvent[]> =
  Object.freeze(
    new Map([
      [
        $compoundNotificationSettings.issueIntegrationTaskCommentsReflected,
        [
          workspaceActivityLogEvent.commentAddedToIssueIntegrationTask,
          workspaceActivityLogEvent.commentUpdatedOnIssueIntegrationTask,
          workspaceActivityLogEvent.commentDeletedFromIssueIntegrationTask,
        ],
      ],
      [
        $compoundNotificationSettings.taskAssigneeChanged,
        [
          workspaceActivityLogEvent.taskAssigned,
          workspaceActivityLogEvent.taskReassigned,
          workspaceActivityLogEvent.taskUnassigned,
        ],
      ],
      [
        $compoundNotificationSettings.findingDismissed,
        [workspaceActivityLogEvent.manualFindingDismissed, workspaceActivityLogEvent.automatedFindingDismissed],
      ],
      [
        $compoundNotificationSettings.taskCreatedFromFinding,
        [workspaceActivityLogEvent.taskCreatedFromManualFindings, workspaceActivityLogEvent.taskCreatedFromAutomatedFindings],
      ],
    ]),
  );
