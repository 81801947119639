import { Component, Input } from '@angular/core';
import { ScanViewportType } from '../../../../shared/constants/scan-viewport';
import { TranslateService } from '../../translate/translate.service';
import { IScanViewport } from '../../../../shared/interfaces/scan-viewport.interface';

@Component({
  selector: 'app-viewport-box',
  templateUrl: './viewport-box.component.html',
})
export class ViewportBoxComponent {
  @Input() public scanViewport: IScanViewport;
  public viewportType: typeof ScanViewportType;
  public isCustomViewport: boolean = false;

  constructor(private translateService: TranslateService) {
    this.viewportType = ScanViewportType;
  }

  public getViewportLabel(viewport: IScanViewport): string {
    return this.translateService.instant('viewport_value_label', [viewport.width.toString(), viewport.height.toString()]);
  }
}
