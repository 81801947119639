import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationExtras, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { Api } from '../../../../../shared/constants/api';
import { IAuditRuleFormSubmission } from '../../../interfaces/audit-rule.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { $auditRule, AuditRuleAction } from '../../../../../shared/constants/audit-rule';
import { IAuditRule } from '../../../../../shared/interfaces/audit-rule.interface';
import { AuditRuleService } from '../../../services/audit-rule.service';
import { CustomAuditRuleService } from '../../../services/custom-audit-rule.service';
import { ICustomAuditRuleWithLibraryName } from '../audit-rule-view/audit-rule-view.component';
import { IAuditRuleCreateRequest, IAuditRuleEditRequest } from '../../../../../shared/interfaces/audit-rule.request.interface';
import { NotificationPosition } from '../../../models/notification.model';
import { TranslateService } from '../../../translate/translate.service';
import { NotificationService } from '../../../services/notification.service';
import { ICreateCustomAuditRules, IEditCustomAuditRules } from '../../../../../shared/interfaces/custom-audit-rule.interface';
import { LoadErrorHandler } from '../../../services/load-error-handler';

@Component({
  selector: 'app-upsert-audit-rule',
  templateUrl: './upsert-audit-rule.component.html',
})
export class UpsertAuditRuleComponent implements OnInit, OnDestroy {
  protected Api: typeof Api = Api;

  public auditRuleAction: AuditRuleAction;
  public ruleId: string;
  public auditRule: IAuditRule | ICustomAuditRuleWithLibraryName;
  public isMasterRule: boolean;
  public isCreate: boolean;

  private subscription: Subscription;

  public constructor(
    private auditRuleService: AuditRuleService,
    private customAuditRuleService: CustomAuditRuleService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private loadErrorHandler: LoadErrorHandler,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.subscription = new Subscription();
  }

  private getRouteData(data: Data): void {
    this.isMasterRule = SharedCommonUtility.notNullish(data['isMasterRule']) ? data['isMasterRule'] : false;
    this.isCreate = SharedCommonUtility.notNullish(data['isCreate']) ? data['isCreate'] : false;
    this.auditRuleAction = this.isCreate ? AuditRuleAction.Create : AuditRuleAction.Update;
  }

  private getRuleInfo(): void {
    this.ruleId = this.activatedRoute.snapshot.paramMap.get('ruleId');

    if (SharedCommonUtility.notNullish(this.ruleId) && this.auditRuleAction === AuditRuleAction.Update) {
      let ruleInfoSubscription: Subscription;
      if (this.isMasterRule) {
        ruleInfoSubscription = this.auditRuleService.findRuleByID(this.ruleId).subscribe((auditRule: IAuditRule): void => {
          this.auditRule = auditRule;
        });
      } else {
        ruleInfoSubscription = this.customAuditRuleService
          .findCustomAuditRuleByRuleId(this.ruleId)
          .subscribe((customAuditRule: ICustomAuditRuleWithLibraryName): void => {
            this.auditRule = customAuditRule;
          });
      }

      this.subscription.add(ruleInfoSubscription);
    }
  }

  private onSaveSuccess(): void {
    const ruleSaveTranslationKey: string = this.isCreate ? 'rule_save_success' : 'fields_save_success';
    this.notificationService.success(this.translateService.instant(ruleSaveTranslationKey), NotificationPosition.Toast, true);

    let commands: string[];
    let extras: NavigationExtras = {
      relativeTo: this.activatedRoute,
    };

    if (this.isMasterRule && this.isCreate) {
      commands = ['..'];
      extras = {
        ...extras,
        queryParams: {
          [$auditRule.ruleId]: null,
          [$auditRule.description]: null,
        },
        queryParamsHandling: 'merge',
      };
    } else if (!this.isMasterRule && this.isCreate) {
      commands = ['/', Api.custom_audit_rules];
      extras = {
        queryParamsHandling: 'preserve',
      };
    } else if (!this.isCreate) {
      commands = [`../${Api.view}`];
    }

    this.router.navigate(commands, extras).then();
  }

  private handleError(error: HttpErrorResponse): void {
    const ruleSaveErrorTranslationKey: string = this.isCreate ? 'audit_rule_creation_error' : 'error_action_cant_be_performed';

    if (this.isMasterRule && !this.isCreate) {
      this.notificationService.error(
        this.translateService.instant(ruleSaveErrorTranslationKey),
        NotificationPosition.Toast,
        true,
      );
    } else if (this.isCreate) {
      this.loadErrorHandler.handleError(ruleSaveErrorTranslationKey, error, UpsertAuditRuleComponent.name);
    }
  }

  public get isCustomRuleCreate(): boolean {
    return !this.isMasterRule && this.isCreate;
  }

  public get backLink(): string {
    return `/${Api.custom_audit_rules}`;
  }

  public get headingTitleKey(): string {
    return this.auditRuleAction === AuditRuleAction.Create ? 'add_rule' : 'edit_rule';
  }

  public save(auditRuleFormSubmission: IAuditRuleFormSubmission): void {
    if (this.isMasterRule) {
      if (this.isCreate) {
        this.subscription.add(
          this.auditRuleService.createAuditRule(auditRuleFormSubmission.ruleUpsertRequest as IAuditRuleCreateRequest).subscribe({
            next: this.onSaveSuccess.bind(this),
            error: this.handleError.bind(this),
          }),
        );
      } else {
        this.subscription.add(
          this.auditRuleService
            .editRuleByRuleId(auditRuleFormSubmission.ruleUpsertRequest as IAuditRuleEditRequest, this.ruleId)
            .subscribe({
              next: this.onSaveSuccess.bind(this),
              error: this.handleError.bind(this),
            }),
        );
      }
    } else if (!this.isMasterRule) {
      if (this.isCreate) {
        this.subscription.add(
          this.customAuditRuleService
            .createCustomAuditRule(auditRuleFormSubmission.ruleUpsertRequest as ICreateCustomAuditRules)
            .subscribe({
              next: this.onSaveSuccess.bind(this),
              error: this.handleError.bind(this),
            }),
        );
      } else {
        this.subscription.add(
          this.customAuditRuleService
            .editCustomAuditRuleByRuleId(auditRuleFormSubmission.ruleUpsertRequest as IEditCustomAuditRules, this.ruleId)
            .subscribe({
              next: this.onSaveSuccess.bind(this),
              error: this.handleError.bind(this),
            }),
        );
      }
    }
  }

  public ngOnInit(): void {
    this.subscription.add(this.activatedRoute.data.subscribe((data: Data) => this.getRouteData(data)));

    if (!this.isCreate) {
      this.getRuleInfo();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
