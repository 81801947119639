import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';
import { DequeVersion } from '../../../interfaces/audit-rule.interface';
import { SuccessCriteriaIdentifier } from '../../../constants/audit-standard-success-criteria';

const axe: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: 'Best Practice',
    [$successCriteria.level]: '',
    [$successCriteria.num]: SuccessCriteriaIdentifier.DEQUEBP4,
    [$successCriteria.title]: 'axe-core Best Practice',
    [$successCriteria.versions]: [DequeVersion.v40],
    [$successCriteria.url]: '',
  },
];

export class Axe implements ISuccessCriteriaIndex {
  public get(num: string): ISuccessCriteria {
    const axeSuccessCriteria: ISuccessCriteria | undefined = axe.find(
      (bestPracticeSuccessCriteria: ISuccessCriteria) => bestPracticeSuccessCriteria.num === num,
    );
    if (typeof axeSuccessCriteria === 'undefined') {
      return null;
    }
    return axeSuccessCriteria;
  }

  public getAll(): ISuccessCriteria[] {
    return axe;
  }
}
