import { Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { $uploads } from '../../../../../shared/constants/uploads';
import { IModal } from '../../modals/modal.interface';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { CHAR_LIMIT_ALT_TEXT } from '../../../../../shared/constants/audit-finding';
import { ModalContainerComponent } from '../../modals/modal-container.component';
import { ErrorMessageService } from '../../../services/error-message.service';

export interface IEditAttachment {
  uploadId: string;
  altText: string;
}

export interface IEditAttachmentModalContext {
  uploadId: string;
  altText?: string;
  onSave?: EventEmitter<IEditAttachment>;
}

@Component({
  selector: 'app-edit-attachment-thumbnail',
  templateUrl: './edit-attachment-modal.component.html',
  styleUrls: ['./edit-attachment-modal.component.scss'],
})
export class EditAttachmentModalComponent implements IModal {
  public readonly $uploads: typeof $uploads = $uploads;
  public readonly CHAR_LIMIT_ALT_TEXT: number = CHAR_LIMIT_ALT_TEXT;
  public formValidationRequest$: Subject<void> = new Subject();
  public form: UntypedFormGroup;
  public uploadId: string;

  private onSave: EventEmitter<IEditAttachment>;

  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private element: ElementRef<Element>,
    private errorMessageService: ErrorMessageService,
  ) {}

  public init(context: IEditAttachmentModalContext): void {
    this.uploadId = context.uploadId;

    this.form = this.formBuilder.group({
      [$uploads.altText]: this.formBuilder.control(context.altText, [
        CustomValidators.required,
        CustomValidators.maxLength(CHAR_LIMIT_ALT_TEXT),
      ]),
    });

    this.onSave = context.onSave ?? new EventEmitter();
  }

  public cancel(): void {
    this.container.closeModal();
  }

  public save(): void {
    const isFormPristine: boolean = this.form.pristine;

    this.formValidationRequest$.next();

    if (this.form.invalid) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }

    if (isFormPristine) {
      this.container.closeModal();
      return;
    }

    const editAttachment: IEditAttachment = {
      uploadId: this.uploadId,
      altText: this.form.get($uploads.altText).value,
    };

    this.onSave.emit(editAttachment);
    this.container.closeModal();
  }
}
