export enum $auditStandard {
  _id = '_id',
  identifier = 'identifier',
  standard = 'standard',
  version = 'version',
  level = 'level',
  isDisabled = 'isDisabled',
}

export enum $scanAuditStandard {
  id = 'id',
  description = 'description',
  url = 'url',
}

export enum AuditStandards {
  wcag = 'WCAG',
  wcagCr = 'WCAG CR',
  axe = 'Axe',
  essential = 'eSSENTIAL',
  wave = 'Wave',
  en301549 = 'EN 301 549',
  section508 = 'Section 508',
  css = 'CSS3',
  html = 'HTML5',
  notApplicable = 'Not applicable',
}

export enum AuditDbStandard {
  wcag = 'WCAG',
  wcagCr = 'WCAG CR',
  axe = 'DEQUEBP',
  essential = 'EABP',
  wave = 'WAVEBP',
  en301549 = 'EN 301 549',
  section508 = 'Section 508',
  notApplicable = 'NOT_APPLICABLE',
}

export const AuditDbStandardValues: Readonly<AuditDbStandard[]> = Object.freeze([
  AuditDbStandard.wcag,
  AuditDbStandard.axe,
  AuditDbStandard.essential,
  AuditDbStandard.wave,
  AuditDbStandard.notApplicable,
]);

export const mapAuditDbStandardToAuditStandard: Record<AuditDbStandard, AuditStandards> = {
  [AuditDbStandard.wcag]: AuditStandards.wcag,
  [AuditDbStandard.wcagCr]: AuditStandards.wcagCr,
  [AuditDbStandard.axe]: AuditStandards.axe,
  [AuditDbStandard.essential]: AuditStandards.essential,
  [AuditDbStandard.wave]: AuditStandards.wave,
  [AuditDbStandard.en301549]: AuditStandards.en301549,
  [AuditDbStandard.section508]: AuditStandards.section508,
  [AuditDbStandard.notApplicable]: AuditStandards.notApplicable,
};
