<div class="row">
  <div class="d-flex col">
    <label [for]="uniqueId">{{ label | translate }}</label>
    <div class="d-flex ms-5 flex-column">
      <form [formGroup]="form">
        <div class="user-avatar" (click)="triggerFileUploadDialog()">
          <img [attr.src]="value.src" alt="{{ value.alt }}" id="userAvatar" (error)="avatarOnError($event)" />
        </div>
        <input
          type="file"
          [accept]="supportedAvatarFileTypes"
          [id]="uniqueId"
          name="avatar"
          (change)="onSelectedAvatar($event)"
          [disabled]="isDisabled"
          formControlName="avatar"
          class="form-element"
        />
        <app-form-field-error
          [formValidationRequest$]="formValidationRequest$"
          [field]="form.get('avatar')"
          name="avatar"
          [label]="label | translate"
        ></app-form-field-error>
      </form>
      <app-crop-image [file]="avatarSelectedFile" (onCroppedFile)="onCroppedFile($event)"></app-crop-image>
    </div>
  </div>
</div>
