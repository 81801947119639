import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { IHighlight, IHighlightFinding } from '../../interfaces/image-highlight.interface';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '../../translate/translate.service';
import { NotificationPosition } from '../../models/notification.model';

export class HighlightCopyPasteHelper {
  private selectedHighlight: IHighlight<any>;
  private selectedFinding: IHighlightFinding<any>;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  public copy(selectedBox: IHighlight<any>, finding?: IHighlightFinding<any>): void {
    if (SharedCommonUtility.isNullishOrEmpty(selectedBox.findings)) {
      return;
    }

    this.selectedHighlight = selectedBox;
    this.selectedFinding = finding;

    if (this.selectedHighlight.findings.length === 1 || SharedCommonUtility.notNullish(finding)) {
      this.notificationService.success(this.translateService.instant('finding_copied'), NotificationPosition.Toast);
    } else {
      this.notificationService.success(this.translateService.instant('findings_copied'), NotificationPosition.Toast);
    }
  }

  public paste(action: (highlightId: string, findingId?: string) => void): void {
    if (!this.selectedHighlight) {
      return;
    }

    action(this.selectedHighlight._id, this.selectedFinding?._id);

    if (this.selectedHighlight.findings.length === 1 || SharedCommonUtility.notNullish(this.selectedFinding)) {
      this.notificationService.success(this.translateService.instant('finding_pasted'), NotificationPosition.Toast);
    } else {
      this.notificationService.success(this.translateService.instant('findings_pasted'), NotificationPosition.Toast);
    }
  }
}
