<div class="tree-node" [ngClass]="{ 'ms-4': level > 0 }">
  <div class="d-flex align-items-baseline">
    <button
      *ngIf="hasChildren; else placeholder"
      type="button"
      class="toggle-button btn btn-flat d-flex px-1 py-1 me-1"
      [attr.aria-label]="(node.collapsed ? 'expand' : 'collapse') | translate"
      (click)="onToggle()"
    >
      <svg [ngSwitch]="isCollapsed" aria-hidden="true" class="toggle-icon">
        <use *ngSwitchCase="true" xlink:href="#chevron-right-thin"></use>
        <use *ngSwitchCase="false" xlink:href="#chevron-bottom-thin"></use>
      </svg>
    </button>
    <ng-template #placeholder>
      <div class="px-1 py-1 me-1 placeholder-checkbox" aria-hidden="true"></div>
    </ng-template>

    <app-common-checkbox
      [ngSwitch]="node.translate"
      [(ngModel)]="checked"
      [disabled]="node.disabled"
      [indeterminate]="isIndeterminate"
    >
      <span *ngSwitchCase="true" class="checkbox-label">{{ node.text | translate }}</span>
      <span *ngSwitchDefault class="checkbox-label">{{ node.text }}</span>
    </app-common-checkbox>
  </div>

  <ng-container *ngIf="!isCollapsed">
    <app-checkbox-tree-node
      *ngFor="let child of node.children; let index = index"
      [level]="level + 1"
      [index]="index"
      [treeId]="treeId"
      [config]="config"
      [node]="child"
      (nodeChanged)="onChildNodeChanged(child, $event)"
    >
    </app-checkbox-tree-node>
  </ng-container>
</div>
