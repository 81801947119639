import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { CommonUtility } from '../../utility/common.utility';
import { alertType } from '../../constants/alert.constants';
import { IErrorPageScreenshot } from '../../../../shared/interfaces/error.interface';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  private _type: alertType;

  @Output() public onClose: EventEmitter<void>;

  @Input()
  public get type(): alertType {
    return this._type;
  }
  public set type(type: alertType) {
    this._type = type;
    this.setFocusForDangerAlert(type); // Note: this shouldn't be here as setter should only be responsible for setting a value. This is a temporary solution.
  }

  @Input() public header: string;
  @Input() public technicalDetails: Array<string>;
  @Input() public pageScreenshot: IErrorPageScreenshot | null;
  @Input() public message: string;

  public get isVisible(): boolean {
    return this.message?.length > 0;
  }

  public componentId: string;
  public errorDetailsAriaExpandedState: boolean;

  constructor() {
    this.onClose = new EventEmitter();
    this.header = '';
    this.message = '';
    this.technicalDetails = [];
    this.errorDetailsAriaExpandedState = false;
    this.pageScreenshot = null;
  }

  private setFocusForDangerAlert(type: alertType): void {
    if (type === alertType.danger) {
      CommonUtility.setFocusToElement(this.componentId);
    }
  }

  public remove(): void {
    this.header = '';
    this.message = '';
    this.technicalDetails.length = 0;
    this.pageScreenshot = null;
    this.onClose.emit();
  }

  public getHeaderData(): string {
    return JSON.stringify(this.pageScreenshot.header, null, 2);
  }

  public ngOnInit(): void {
    this.componentId = 'alert-' + this.type + '-' + CommonUtility.createUniqueDOMId();
  }
}
