import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DigitalPropertyService } from '../../services/digital-property.service';
import { UserService } from '../../services/user.service';
import { IDigitalProperty } from '../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../../shared/constants/digital-properties';

@Component({
  selector: 'app-domain-restrictions',
  templateUrl: './domain-restrictions.component.html',
  styleUrls: ['./domain-restrictions.component.scss'],
})
export class DomainRestrictionsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  public hasDomainRestrictions: boolean;
  public loadingRestrictions: boolean;
  public authorizedDomains: string[];

  @Input()
  public showTitle: boolean;
  @Input()
  public boldButton: boolean;
  @Output()
  public domainsLoaded: EventEmitter<RegExp[]> = new EventEmitter<RegExp[]>();

  constructor(
    private digitalPropertyService: DigitalPropertyService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.subscriptions = new Subscription();
    this.loadingRestrictions = true;
    this.hasDomainRestrictions = false;
    this.showTitle = true;
    this.boldButton = true;
  }

  private onRestrictionsLoaded(restrictions: RegExp[]): void {
    this.domainsLoaded.emit(restrictions);
    this.loadingRestrictions = false;

    this.hasDomainRestrictions = (restrictions?.length || 0) > 0;

    this.changeDetectorRef.detectChanges();
  }

  private onDigitalPropertyChanged(property: IDigitalProperty): void {
    this.domains = property[$digitalProperty.authorizedDomains];
  }

  @Input()
  public set domains(domains: string[]) {
    this.authorizedDomains = domains;
    this.onRestrictionsLoaded(this.digitalPropertyService.getDomainRestrictions(domains));
  }

  public ngOnInit(): void {
    if (Array.isArray(this.authorizedDomains) === false) {
      this.subscriptions.add(
        this.userService.currentDigitalProperty$.pipe(debounceTime(100)).subscribe(this.onDigitalPropertyChanged.bind(this)),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
