<ds-modal [title]="'disconnected_tasks' | translate">
  <div class="task-list mb-3">
    <div *ngFor="let task of failedTasks">{{ task.taskProjectId }}</div>
  </div>

  <ds-button-group
    [primaryAction]="{ label: 'label_resync_connection' | translate }"
    [secondAction]="{ label: 'label_cancel' | translate, variant: DsButtonVariants.secondary }"
    (primaryActionClicked)="confirmClicked()"
    (secondActionClicked)="cancelClicked()"
  >
  </ds-button-group>
</ds-modal>
