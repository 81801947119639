export enum $remediationProgressCount {
  resolved = 'resolved',
  unresolved = 'unresolved',
}

export enum $flawCounts {
  flawCountByDpToolRuleStatuses = 'flawCountByDpToolRuleStatuses',
  defaultPolicyFlawCountBySeverityTime = 'defaultPolicyFlawCountBySeverityTime',
  userPolicyFlawCountBySeverityTime = 'userPolicyFlawCountBySeverityTime',
}

export enum $getWorkspaceDashboardResponse {
  dashboardData = 'dashboardData',
  updatedAt = 'updatedAt',
  updateRequestedAt = 'updateRequestedAt',
}

export enum $manualFindingSummary {
  digitalPropertyId = 'digitalPropertyId',
  digitalPropertyName = 'digitalPropertyName',
  successCriteria = 'successCriteria',
  isResolved = 'isResolved',
  category = 'category',
}

export enum $digitalPropertyFindingCount {
  severityCount = 'severityCount',
  rankDelta = 'rankDelta',
  weeksInRank = 'weeksInRank',
  successCriteria = 'successCriteria',
  totalUnresolvedIssuesBySeverity = 'totalUnresolvedIssuesBySeverity',
  agingUnresolvedIssuesBySeverity = 'agingUnresolvedIssuesBySeverity',
  remediationProgress = 'remediationProgress',
  userPolicyAgingUnresolvedIssuesBySeverity = 'userPolicyAgingUnresolvedIssuesBySeverity',
}

export enum $countByActiveDigitalProperty {
  webPropertiesWithMonitoring = 'webPropertiesWithMonitoring',
  totalWebProperties = 'totalWebProperties',
  propertiesWithActiveManualEvaluations = 'propertiesWithActiveManualEvaluations',
  totalProperties = 'totalProperties',
}

export enum $mostViolatedCounts {
  successCriteria = 'successCriteria',
  severityCount = 'severityCount',
  criticalCount = 'criticalCount',
  category = 'category',
}
