<div class="step-icon-container">
  <div class="step-icon" [ngClass]="{ 'step-done': done, 'step-active': active }" aria-hidden="true">
    <ng-container *ngIf="done; else notDone">
      <svg class="checkmark">
        <use xlink:href="#check"></use>
      </svg>
    </ng-container>

    <ng-template #notDone>
      {{ step + 1 }}
    </ng-template>
  </div>
  <span class="text-wrap text-black" [ngClass]="{ 'font-weight-500': active }" aria-hidden="true">{{ label }}</span>
  <span class="visuallyhidden">
    {{ 'label_step_n' | translate: [step + 1] }}
    {{ label }}
    <ng-container *ngIf="done">
      {{ 'done' | translate }}
    </ng-container>
  </span>
</div>
