import { Component, Input } from '@angular/core';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';
import { Observable } from 'rxjs';
import { BaseCellComponent, ICellConfig } from '../base-cell/base-cell.component';

export interface IDSButtonCellConfig extends ICellConfig {
  variant: DsButtonVariants;
  clickListener: Function;
  microActionIcon?: Icons;
  isDisabled$?: Observable<boolean>;
}

@Component({
  selector: 'table-cell-ds-button',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    @if (isMicroAction()) {
      <button
        ds-button
        #ref
        [variant]="config.variant"
        [microActionIcon]="config.microActionIcon"
        [hideMicroActionIcon]="!config.microActionIcon"
        [disabled]="config?.isDisabled$ | async"
        (click)="config.clickListener()"
      >
        {{ config.text }}
      </button>
    } @else {
      <button ds-button #ref [variant]="config.variant" [disabled]="config?.isDisabled$ | async" (click)="config.clickListener()">
        {{ config.text }}
      </button>
    }
  `,
})
export class DSButtonCellComponent extends BaseCellComponent {
  private static readonly MICRO_ACTION_VARIANTS: DsButtonVariants[] = [
    DsButtonVariants.microAction,
    DsButtonVariants.microActionHorizontalLayout,
    DsButtonVariants.microActionStacked,
    DsButtonVariants.microActionWithChevronRight,
    DsButtonVariants.microActionWithChevronRightInline,
  ];

  @Input() public config: IDSButtonCellConfig;

  public isMicroAction(): boolean {
    return DSButtonCellComponent.MICRO_ACTION_VARIANTS.includes(this.config.variant);
  }
}
