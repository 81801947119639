<div [formGroup]="form">
  <fieldset>
    <legend class="font-size-normal label margin-bottom-xs text-nowrap" [ngClass]="{ required: required }">
      {{ context.label | translate }}<span *ngIf="required" class="color-error"> *</span>
    </legend>
    <div [id]="context.field" class="common-checkbox-controls">
      <ng-container *ngFor="let value of selectValues">
        <app-common-checkbox
          [disabled]="disabled"
          [ngModel]="isSelected(value)"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="valueChanged(value, $event)"
        >
          <span class="checkbox-label">
            <ng-container *ngIf="context.translateName">
              {{ getOptionName(value) | translate }}
            </ng-container>
            <ng-container *ngIf="!context.translateName">
              {{ getOptionName(value) }}
            </ng-container>
          </span>
        </app-common-checkbox>
      </ng-container>
    </div>
    <div [id]="context.field + '-error'" class="error-container">
      <app-form-field-error
        [formValidationRequest$]="formValidationRequest$"
        [field]="form.get(context.field)"
        [name]="context.field"
        [label]="context.label"
      ></app-form-field-error>
    </div>
  </fieldset>
</div>
