import { clone } from 'lodash';

import { IHighlight } from '../../interfaces/image-highlight.interface';
import { MovementDirection } from '../../constants/image-highlight.constants';
import { $boundingBox } from '../../../../shared/constants/design-review';

const DIRECTIONS_MAP: Partial<Record<MovementDirection, Partial<Record<MovementDirection, MovementDirection>>>> = {
  [MovementDirection.ArrowDown]: {
    [MovementDirection.ArrowLeft]: MovementDirection.ArrowDownLeft,
    [MovementDirection.ArrowRight]: MovementDirection.ArrowDownRight,
  },
  [MovementDirection.ArrowUp]: {
    [MovementDirection.ArrowLeft]: MovementDirection.ArrowUpLeft,
    [MovementDirection.ArrowRight]: MovementDirection.ArrowUpRight,
  },
  [MovementDirection.ArrowLeft]: {
    [MovementDirection.ArrowUp]: MovementDirection.ArrowUpLeft,
    [MovementDirection.ArrowDown]: MovementDirection.ArrowDownLeft,
  },
  [MovementDirection.ArrowRight]: {
    [MovementDirection.ArrowUp]: MovementDirection.ArrowUpRight,
    [MovementDirection.ArrowDown]: MovementDirection.ArrowDownRight,
  },
};

export class HighlightActionsHelper {
  public getCombinedDirection(mainDirection: MovementDirection, otherDirection: MovementDirection): MovementDirection {
    if (!otherDirection) {
      return mainDirection;
    }

    const direction: MovementDirection | undefined = DIRECTIONS_MAP[mainDirection]?.[otherDirection];

    return direction ?? mainDirection;
  }

  public moveHighlight<T>(input: IHighlight<T>, direction: MovementDirection, amount: number): IHighlight<T> {
    const result: IHighlight<T> = clone(input);
    switch (direction) {
      case MovementDirection.ArrowUp:
        result[$boundingBox.y] -= amount;
        break;
      case MovementDirection.ArrowDown:
        result[$boundingBox.y] += amount;
        break;
      case MovementDirection.ArrowLeft:
        result[$boundingBox.x] -= amount;
        break;
      case MovementDirection.ArrowRight:
        result[$boundingBox.x] += amount;
        break;
      case MovementDirection.ArrowUpLeft:
        result[$boundingBox.x] -= amount;
        result[$boundingBox.y] -= amount;
        break;
      case MovementDirection.ArrowUpRight:
        result[$boundingBox.x] += amount;
        result[$boundingBox.y] -= amount;
        break;
      case MovementDirection.ArrowDownLeft:
        result[$boundingBox.x] -= amount;
        result[$boundingBox.y] += amount;
        break;
      case MovementDirection.ArrowDownRight:
        result[$boundingBox.x] += amount;
        result[$boundingBox.y] += amount;
        break;
      default:
        return result;
    }
    return result;
  }

  public resizeHighlight<T>(input: IHighlight<T>, direction: MovementDirection, amount: number): IHighlight<T> {
    const result: IHighlight<T> = clone(input);
    switch (direction) {
      case MovementDirection.ArrowUp:
        result[$boundingBox.height] -= amount;
        break;
      case MovementDirection.ArrowRight:
        result[$boundingBox.width] += amount;
        break;
      case MovementDirection.ArrowDown:
        result[$boundingBox.height] += amount;
        break;
      case MovementDirection.ArrowLeft:
        result[$boundingBox.width] -= amount;
        break;
      case MovementDirection.ArrowUpLeft:
        result[$boundingBox.height] -= amount;
        result[$boundingBox.width] -= amount;
        break;
      case MovementDirection.ArrowUpRight:
        result[$boundingBox.height] -= amount;
        result[$boundingBox.width] += amount;
        break;
      case MovementDirection.ArrowDownLeft:
        result[$boundingBox.height] += amount;
        result[$boundingBox.width] -= amount;
        break;
      case MovementDirection.ArrowDownRight:
        result[$boundingBox.height] += amount;
        result[$boundingBox.width] += amount;
        break;
      default:
        return result;
    }
    return result;
  }
}
