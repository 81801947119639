<ng-container>
  <div class="card-body" *ngIf="containsLevel">
    <ol class="stepper-list m-0 p-0" >
      <ng-container *ngFor="let step of steps; let i = index">
        <li
          class="stepper-list-item flex-fill"
          [class.active]="isActive(step)"
          [ngClass]="step.class"
          
          [attr.aria-current]="isActive(step) ? 'true' : null"
        >
          <div aria-hidden="true" class="stepper-bar stepper-bar-vertical"></div>
          <span class="step-text mb-0">{{ step.label }}</span>
          <div aria-hidden="true" class="stepper-bar stepper-bar-horizontal"></div>
        </li>
      </ng-container>
    </ol>
    <ds-link
      class="p-0 mt-2"
      standalone="true"
      href="#risk-score-explanation"
      
      (click)="openRiskScoreExplanationModal($event)"
      >{{ 'how_is_this_status_calculated' | translate }}</ds-link
    >
  </div>
  <ng-container *ngIf="!containsLevel">
    <h3 class="h1 d-inline-block me-4" >{{ 'no_status_yet' | translate }}</h3>
  </ng-container>
</ng-container>
