import { IHighlightDisplayOptions, IPoint } from '../../interfaces/image-highlight.interface';
import { IBoundingBox } from '../../../../shared/interfaces/design-review.interface';
import { BoxPositions } from '../../constants/image-highlight.constants';

export class HighlightMouseHelper {
  constructor(private highlightDisplayOptions: IHighlightDisplayOptions) {}

  public isMouseInsideHighlightBounds(box: IBoundingBox, point: IPoint): boolean {
    return point.x >= box.x && point.x <= box.x + box.width && point.y >= box.y && point.y <= box.y + box.height;
  }

  public getCoordinatesFromMouseEvent(event: MouseEvent): IPoint {
    return {
      x: event.offsetX,
      y: event.offsetY,
    };
  }

  public getBoxPosition(mousePoint: IPoint, selectedBox: IBoundingBox): BoxPositions | null {
    const topLeftResizeBox: IBoundingBox = {
      x: selectedBox.x - this.highlightDisplayOptions.resizeRectSize,
      y: selectedBox.y - this.highlightDisplayOptions.resizeRectSize,
      width: this.highlightDisplayOptions.resizeRectSize * 2,
      height: this.highlightDisplayOptions.resizeRectSize * 2,
    };

    const topRightResizeBox: IBoundingBox = {
      x: selectedBox.x + selectedBox.width - this.highlightDisplayOptions.resizeRectSize,
      y: selectedBox.y - this.highlightDisplayOptions.resizeRectSize,
      width: this.highlightDisplayOptions.resizeRectSize * 2,
      height: this.highlightDisplayOptions.resizeRectSize * 2,
    };

    const bottomLeftResizeBox: IBoundingBox = {
      x: selectedBox.x - this.highlightDisplayOptions.resizeRectSize,
      y: selectedBox.y + selectedBox.height - this.highlightDisplayOptions.resizeRectSize,
      width: this.highlightDisplayOptions.resizeRectSize * 2,
      height: this.highlightDisplayOptions.resizeRectSize * 2,
    };

    const bottomRightResizeBox: IBoundingBox = {
      x: selectedBox.x + selectedBox.width - this.highlightDisplayOptions.resizeRectSize,
      y: selectedBox.y + selectedBox.height - this.highlightDisplayOptions.resizeRectSize,
      width: this.highlightDisplayOptions.resizeRectSize * 2,
      height: this.highlightDisplayOptions.resizeRectSize * 2,
    };

    if (this.isMouseInsideHighlightBounds(topLeftResizeBox, mousePoint)) {
      return BoxPositions.topLeft;
    }

    if (this.isMouseInsideHighlightBounds(topRightResizeBox, mousePoint)) {
      return BoxPositions.topRight;
    }

    if (this.isMouseInsideHighlightBounds(bottomLeftResizeBox, mousePoint)) {
      return BoxPositions.bottomLeft;
    }

    if (this.isMouseInsideHighlightBounds(bottomRightResizeBox, mousePoint)) {
      return BoxPositions.bottomRight;
    }

    return null;
  }

  public getResizedBoxBasedOnMousePoint(
    currentMousePoint: IPoint,
    selectedBox: IBoundingBox,
    boxPosition: BoxPositions,
    initialBoxSize: IBoundingBox,
  ): IBoundingBox {
    const getNewHeightAccountingForCurrentMousePointAtBottomPosition = (): number => currentMousePoint.y - initialBoxSize.y;

    const getNewHeightAccountingForCurrentMousePointAtTopPosition = (): number =>
      initialBoxSize.height + (initialBoxSize.y - currentMousePoint.y);

    const getNewWidthAccountingForCurrentMousePointAtRightPosition = (): number => currentMousePoint.x - initialBoxSize.x;

    const getNewWidthAccountingForCurrentMousePointAtLeftPosition = (): number =>
      initialBoxSize.width + (initialBoxSize.x - currentMousePoint.x);

    let newWidth: number = selectedBox.width;
    let newheight: number = selectedBox.height;
    let newX: number = selectedBox.x;
    let newY: number = selectedBox.y;

    switch (boxPosition) {
      case BoxPositions.topLeft:
        newX = currentMousePoint.x;
        newY = currentMousePoint.y;
        newWidth = getNewWidthAccountingForCurrentMousePointAtLeftPosition();
        newheight = getNewHeightAccountingForCurrentMousePointAtTopPosition();
        break;
      case BoxPositions.topRight:
        newX = initialBoxSize.x;
        newY = currentMousePoint.y;
        newWidth = getNewWidthAccountingForCurrentMousePointAtRightPosition();
        newheight = getNewHeightAccountingForCurrentMousePointAtTopPosition();
        break;
      case BoxPositions.bottomLeft:
        newX = currentMousePoint.x;
        newY = initialBoxSize.y;
        newWidth = getNewWidthAccountingForCurrentMousePointAtLeftPosition();
        newheight = getNewHeightAccountingForCurrentMousePointAtBottomPosition();
        break;
      case BoxPositions.bottomRight:
        newX = initialBoxSize.x;
        newY = initialBoxSize.y;
        newWidth = getNewWidthAccountingForCurrentMousePointAtRightPosition();
        newheight = getNewHeightAccountingForCurrentMousePointAtBottomPosition();
        break;

      default:
        console.error('[resizeSelectedBoxBasedOnMousePoint] invalid boxResizingFromPosition value', boxPosition);
        break;
    }

    return {
      x: newX,
      y: newY,
      width: newWidth,
      height: newheight,
    };
  }
}
