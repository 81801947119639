import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum tableEmptyStateType {
  searchingNoResults = 'searchingNoResults',
  inReviewNoResultsTotal = 'issuesInReviewNoResultsTotal',
  failedNoResultsTotal = 'failedNoResultsTotal',
  passedNoResultsTotal = 'passedNoResultsTotal',
  notApplicableNoResultsTotal = 'notApplicableNoResultsTotal',
  noDigitalPropertiesFound = 'noDigitalPropertiesFound',
}

@Component({
  selector: 'app-table-empty-state',
  templateUrl: './table-empty-state.component.html',
  styleUrls: ['./table-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableEmptyStateComponent {
  @Input() public tableEmptyState: tableEmptyStateType;
  @Input() public hideBorders?: boolean;

  public tableEmptyStateType: typeof tableEmptyStateType;

  constructor() {
    this.tableEmptyStateType = tableEmptyStateType;
  }
}
