import { SIZE_1_MB } from './uploads';
import { $ruleSummary } from './scan-summaries';

export enum $auditFinding {
  _id = '_id',
  externalId = 'externalId',
  actualResult = 'actualResult',
  attachment = 'attachment',
  auditData = 'auditData',
  /**
   * Used for storing component info in audit results.
   */
  componentData = 'componentData',
  documentId = 'documentId',
  discoveredBy = 'discoveredBy',
  element = 'element',
  elementReference = 'elementReference',
  elementReferenceType = 'elementReferenceType',
  isElementReferenceEdited = 'isElementReferenceEdited',
  expectedResult = 'expectedResult',
  falsePositiveData = 'falsePositiveData',
  isDisabled = 'isDisabled',
  issue = 'issue',
  ruleId = 'ruleId',
  rule = 'rule',
  skipReason = 'skipReason',
  toolId = 'toolId',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  // Manual Audit specific
  accessibilityImpact = 'accessibilityImpact',
  ATCombination = 'ATCombination',
  comments = 'comments',
  disabilityAffected = 'disabilityAffected',
  functionalImpact = 'functionalImpact',
  instances = 'instances',
  issueId = 'issueId',
  issueFrequency = 'issueFrequency',
  QAComment = 'QAComment',
  QARecommendation = 'QARecommendation',
  QAScreencast = 'QAScreencast',
  QAStatus = 'QAStatus',
  userImpact = 'userImpact',
  recommendation = 'recommendation',
  schemaVersion = 'schemaVersion',
  screen = 'screen',
  screenshot = 'screenshot',
  severity = 'severity',
  stepsToReproduce = 'stepsToReproduce',
  summary = 'summary',
  componentId = 'componentId',
  viewport = 'viewport',

  // virtuals
  task = 'task',
  component = 'component',
  document = 'document',
}

export enum $componentData {
  componentName = 'componentName',
  componentId = 'componentId',
  componentXpath = 'componentXpath',
  elementXpath = 'elementXpath',
}

export enum $auditFindingViewport {
  width = 'width',
  height = 'height',
}

export enum FindingIssue {
  error = 'error',
  skipped = 'skipped',
  passed = 'passed',
}

export enum AuditFindingReferenceType {
  xpath = 'xpath',
  issueId = 'issueId',
  selector = 'selector',
}

export enum AuditFindingIssueFrequency {
  individual = 'individual',
  global = 'global',
}

export enum AuditFindingIssueEffort {
  low = 'low',
  high = 'high',
}

export enum AuditFindingFunctionalImpact {
  low = 'low',
  high = 'high',
}

export enum AuditFindingDisabilityAffected {
  cognitive = 'cognitive',
  physical = 'physical',
  visual = 'visual',
  auditory = 'auditory',
  neurological = 'neurological',
  speech = 'speech',
}

export enum AuditFindingAccessibilityImpact {
  low = 'low',
  high = 'high',
  critical = 'critical',
}

export enum AuditExcelFindingAccessibilityImpact {
  Low = 'Low',
  High = 'High',
  Critical = 'Critical',
}

export enum AuditFindingTaskLinkStatus {
  linked = 'linked',
  unlinked = 'unlinked',
}

export enum AuditFindingViewport {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export const AuditFindingViewportBreakpoints: Readonly<Partial<Record<AuditFindingViewport, number>>> = {
  [AuditFindingViewport.medium]: 320,
  [AuditFindingViewport.large]: 1280,
};

export enum AuditFindingQAStatus {
  toReview = 'toReview',
  open = 'open',
  fixed = 'fixed',
  partiallyFixed = 'partiallyFixed',
  notFixed = 'notFixed',
  notReproducible = 'notReproducible',
  cannotBeFixed = 'cannotBeFixed',
  dismissed = 'dismissed',
  validationRequested = 'validationRequested',

  /**
   * @deprecated
   */
  new = 'new',
}

export const AuditFindingQAStatusReportLabel: Readonly<Record<AuditFindingQAStatus, string>> = Object.freeze({
  [AuditFindingQAStatus.toReview]: 'To review',
  [AuditFindingQAStatus.open]: 'Open',
  [AuditFindingQAStatus.fixed]: 'Fixed',
  [AuditFindingQAStatus.partiallyFixed]: 'Partially fixed',
  [AuditFindingQAStatus.notFixed]: 'Not fixed',
  [AuditFindingQAStatus.notReproducible]: 'Not reproducible',
  [AuditFindingQAStatus.cannotBeFixed]: 'Cannot be fixed',
  [AuditFindingQAStatus.dismissed]: 'Dismissed',
  [AuditFindingQAStatus.validationRequested]: 'Validation requested',

  /**
   * @deprecated
   */
  [AuditFindingQAStatus.new]: 'New',
});

export const AuditFindingQAStatusDisplayLabel: Readonly<Record<AuditFindingQAStatus, string>> = Object.freeze({
  [AuditFindingQAStatus.toReview]: 'manual_audit_issue_qa_status_display_label_to_review',
  [AuditFindingQAStatus.open]: 'manual_audit_issue_qa_status_display_label_open',
  [AuditFindingQAStatus.fixed]: 'manual_audit_issue_qa_status_display_label_fixed',
  [AuditFindingQAStatus.partiallyFixed]: 'manual_audit_issue_qa_status_display_label_partially_fixed',
  [AuditFindingQAStatus.notFixed]: 'manual_audit_issue_qa_status_display_label_not_fixed',
  [AuditFindingQAStatus.notReproducible]: 'manual_audit_issue_qa_status_display_label_not_reproducible',
  [AuditFindingQAStatus.cannotBeFixed]: 'manual_audit_issue_qa_status_display_label_cannot_be_fixed',
  [AuditFindingQAStatus.dismissed]: 'dismissed',
  [AuditFindingQAStatus.validationRequested]: 'validation_requested',

  /**
   * @deprecated
   */
  [AuditFindingQAStatus.new]: 'manual_audit_issue_qa_status_display_label_new',
});

// Note: this is ordered in how product wants to see the list of statuses presented.
export const ActiveAuditFindingQAStatus: ReadonlyArray<AuditFindingQAStatus> = Object.freeze<AuditFindingQAStatus[]>([
  AuditFindingQAStatus.toReview,
  AuditFindingQAStatus.open,
  AuditFindingQAStatus.fixed,
  AuditFindingQAStatus.partiallyFixed,
  AuditFindingQAStatus.notFixed,
  AuditFindingQAStatus.notReproducible,
  AuditFindingQAStatus.cannotBeFixed,
  AuditFindingQAStatus.dismissed,
  AuditFindingQAStatus.validationRequested,
]);

export const AuditFindingQAStatusResolved: ReadonlyArray<AuditFindingQAStatus> = Object.freeze<AuditFindingQAStatus[]>([
  AuditFindingQAStatus.fixed,
  AuditFindingQAStatus.notReproducible,
  AuditFindingQAStatus.dismissed,
]);

export const AuditFindingQAStatusResolvedSet: Set<AuditFindingQAStatus> = new Set<AuditFindingQAStatus>(
  AuditFindingQAStatusResolved,
);

export const AuditFindingQAStatusUnresolved: ReadonlyArray<AuditFindingQAStatus> = Object.freeze<AuditFindingQAStatus[]>([
  AuditFindingQAStatus.toReview,
  AuditFindingQAStatus.open,
  AuditFindingQAStatus.partiallyFixed,
  AuditFindingQAStatus.notFixed,
  AuditFindingQAStatus.cannotBeFixed,
  AuditFindingQAStatus.validationRequested,
]);

export const AuditFindingQAStatusUnresolvedSet: Set<AuditFindingQAStatus> = new Set<AuditFindingQAStatus>(
  AuditFindingQAStatusUnresolved,
);

export const AuditFindingQAStatusRemaining: ReadonlyArray<AuditFindingQAStatus> = Object.freeze<AuditFindingQAStatus[]>([
  AuditFindingQAStatus.open,
  AuditFindingQAStatus.partiallyFixed,
  AuditFindingQAStatus.notFixed,
]);

export const AuditFindingQAStatusTotal: ReadonlyArray<AuditFindingQAStatus> = Object.freeze<AuditFindingQAStatus[]>([
  AuditFindingQAStatus.fixed,
  ...AuditFindingQAStatusRemaining,
]);

export enum ManualAuditQuery {
  // used for selecting
  fields = 'fields',
  formatColumns = 'formatColumns',
  pageId = 'pageId',
  xpath = 'xpath',
  codeSnippet = 'codeSnippet',
  component = 'component',
  viewport = 'viewport',
}

export const MAX_ATTACHMENTS: number = 20;
export const MAX_ATTACHMENTS_SIZE: number = 100 * SIZE_1_MB;
export const CHAR_LIMIT_ALT_TEXT: number = 100;
export const VIEWPORT_WIDTH_MAX_VALUE: number = 7680;
export const VIEWPORT_WIDTH_MIN_VALUE: number = 320;
export const VIEWPORT_HEIGHT_MAX_VALUE: number = 4320;
export const VIEWPORT_HEIGHT_MIN_VALUE: number = 256;

/**
 * Maps how findings with each status should be counted in the scan and page summaries.
 */
export const FindingStatusToSummaryField: Readonly<Record<AuditFindingQAStatus, $ruleSummary>> = Object.freeze({
  [AuditFindingQAStatus.dismissed]: $ruleSummary.ignored,
  [AuditFindingQAStatus.fixed]: $ruleSummary.passed,
  [AuditFindingQAStatus.toReview]: $ruleSummary.errors,
  [AuditFindingQAStatus.open]: $ruleSummary.errors,
  [AuditFindingQAStatus.partiallyFixed]: $ruleSummary.errors,
  [AuditFindingQAStatus.notFixed]: $ruleSummary.errors,
  [AuditFindingQAStatus.notReproducible]: $ruleSummary.errors,
  [AuditFindingQAStatus.cannotBeFixed]: $ruleSummary.errors,
  [AuditFindingQAStatus.validationRequested]: $ruleSummary.errors,
  [AuditFindingQAStatus.new]: $ruleSummary.errors,
});
