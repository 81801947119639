<div id="mainWrapper" class="main-login">
  <app-global-spinner *ngIf="isLoading" class="fullscreen"></app-global-spinner>
  <main id="mainContainer" class="container-fluid pt-3 pt-md-4">
    <div class="m-0 my-auto">
      <div class="main-content col-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
