import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { SecureResourceService } from '../../services/secure-resource.service';
import { LoadErrorHandler } from '../../services/load-error-handler';
import { TranslateService } from '../../translate/translate.service';

@Directive({
  selector: 'img[appSecureResourceId]',
})
export class SecureResourceIdDirective implements OnDestroy {
  private subscription: Subscription;

  @Input()
  public set appSecureResourceId(resourceId: string) {
    this.loadSecureImage(resourceId);
  }

  public constructor(
    private resourceService: SecureResourceService,
    private elementRef: ElementRef,
    private loadErrorHandler: LoadErrorHandler,
    private translateService: TranslateService,
  ) {
    this.subscription = new Subscription();
  }

  private loadSecureImage(resourceId: string): void {
    this.subscription.add(
      this.resourceService.getSecureResourceBytes(resourceId).subscribe(
        (imageBytes: Blob) => {
          this.elementRef.nativeElement.src = URL.createObjectURL(imageBytes);
          this.elementRef.nativeElement.dispatchEvent(new Event('load'));
        },
        (error: HttpErrorResponse) => {
          this.loadErrorHandler.handleError(
            this.translateService.instant('failed_to_load_resource', resourceId),
            error,
            SecureResourceIdDirective.name,
          );
          this.elementRef.nativeElement.dispatchEvent(new Event('error'));
        },
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
