import { EChartsOption, EChartsType } from 'echarts/types/dist/shared';
import { Component, Input } from '@angular/core';

import * as echarts from 'echarts/core';
import { GridComponent, LegendComponent, TooltipComponent } from 'echarts/components';
import { max } from 'lodash';
import { BarChart } from 'echarts/charts';
import { SVGRenderer } from 'echarts/renderers';
import { DsModalService, DsModalVariant } from '@levelaccess/design-system';
import { $riskScoreLevels } from '../../../../../shared/constants/risk-metrics';
import { TranslateService } from '../../../translate/translate.service';
import { CommonUtility } from '../../../utility/common.utility';
import { DateUtility } from '../../../utility/date.utility';
import { WebsitesAppsByRiskManagementStatusTableModalComponent } from './websites-apps-by-risk-management-table-modal/websites-apps-by-risk-management-table-modal.component';
import { IRiskMetricsChartConfig, IRiskMetricsChartData } from '../../../interfaces/risk-metrics-chart.interface';
import { $riskMetricsChartConfig, $riskMetricsChartData } from '../../../constants/risk-metrics-chart';
import { DashboardDataFrequency } from '../../../../../shared/interfaces/ws-dashboard.interface';
import { RiskChartUtility } from '../../../utility/risk-chart.utility';

@Component({
  selector: 'app-websites-apps-by-risk-management-chart',
  templateUrl: './websites-apps-by-risk-management-chart.component.html',
  styleUrls: ['websites-apps-by-risk-management-chart.component.scss'],
})
export class WebsitesAppsByRiskManagementStatusChartComponent {
  private chartData: IRiskMetricsChartData[];
  private frequency: DashboardDataFrequency;

  private DECAL_COLOR: string = 'rgba(255, 255, 255, 0.8)';
  // TODO: extract to colors to DSColors in design system package
  private BEHIND_COLOR: string = '#F4364C';
  private NEEDS_ATTENTION_COLOR: string = '#DB6E00';
  private ON_TRACK_COLOR: string = '#4B9E24';
  private EXCELLING_COLOR: string = '#074EB0';
  private DATA_POINT_LIMIT: number = 6;
  private MIN_Y_AXIS_OFFSET: number = 10;

  @Input() set config(config: IRiskMetricsChartConfig) {
    if (config) {
      this.onGetConfigSuccess(config);
    }
  }
  public chart: EChartsType;

  constructor(
    private translate: TranslateService,
    private dsModalService: DsModalService,
  ) {}

  private onGetConfigSuccess(config: IRiskMetricsChartConfig): void {
    this.frequency = config[$riskMetricsChartConfig.frequency];
    this.chartData = [...config[$riskMetricsChartConfig.data]]
      .sort(
        (a: IRiskMetricsChartData, b: IRiskMetricsChartData): number =>
          new Date(a[$riskMetricsChartData.computedAt]).getTime() - new Date(b[$riskMetricsChartData.computedAt]).getTime(),
      )
      .slice(-this.DATA_POINT_LIMIT);
    this.drawGraph();
  }

  private getDataValuesForStatus(metrics: IRiskMetricsChartData[], status: $riskScoreLevels): number[] {
    return metrics.map((data: IRiskMetricsChartData) => {
      return data[$riskMetricsChartData.dpCountsByRiskLevel][status];
    });
  }

  private calculateYAxisMax(values: number[]): number {
    const highestYValue: number = max(values) ?? 0;
    if (highestYValue % this.MIN_Y_AXIS_OFFSET === 0) {
      return highestYValue + this.MIN_Y_AXIS_OFFSET;
    }
    return Math.ceil(highestYValue / this.MIN_Y_AXIS_OFFSET) * this.MIN_Y_AXIS_OFFSET;
  }

  private drawGraph(): void {
    const behind: number[] = this.getDataValuesForStatus(this.chartData, $riskScoreLevels.behind);
    const needsAttention: number[] = this.getDataValuesForStatus(this.chartData, $riskScoreLevels.needsAttention);
    const onTrack: number[] = this.getDataValuesForStatus(this.chartData, $riskScoreLevels.onTrack);
    const excelling: number[] = this.getDataValuesForStatus(this.chartData, $riskScoreLevels.excelling);

    const labels: string[] = this.chartData.map((metrics: IRiskMetricsChartData) => {
      return RiskChartUtility.formatDateForFrequency(new Date(metrics[$riskMetricsChartData.computedAt]), this.frequency);
    });

    const yAxisMax = this.calculateYAxisMax([...behind, ...needsAttention, ...onTrack, ...excelling]);

    const xAxisTitle: string = this.translate.instant(RiskChartUtility.getDescriptorKeyForFrequency(this.frequency));
    const yAxisTitle: string = this.translate.instant('number_of_websites_apps');

    const behindString: string = this.translate.instant('behind');
    const needsAttentionString: string = this.translate.instant('needs_attention');
    const onTrackString: string = this.translate.instant('on_track');
    const excellingString: string = this.translate.instant('excelling');

    echarts.use([TooltipComponent, GridComponent, LegendComponent, BarChart, SVGRenderer]);
    this.chart = echarts.init(document.getElementById('riskManagementStatusChart'));

    const option: EChartsOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none',
        },
        formatter: (params: any): string => {
          let formattedToolTip: string = '';
          const formattedDate: string = DateUtility.prettifyDate(
            new Date(this.chartData[params[0].dataIndex][$riskMetricsChartData.computedAt]),
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          );
          formattedToolTip += formattedDate;
          const toolTipData: string[] = params.map((param: any) => {
            return `<br />${param.marker}${param.seriesName}<span style="float:right; margin-left: 20px"><b>${param.value}</b></span>`;
          });
          formattedToolTip += toolTipData.join('');
          return formattedToolTip;
        },
      },
      legend: {
        data: [behindString, needsAttentionString, onTrackString, excellingString],
        left: 'right',
      },
      grid: {
        left: '4%',
        right: '0%',
        bottom: '10%',
        top: '20%',
        containLabel: true,
      },
      xAxis: [
        {
          name: xAxisTitle,
          nameLocation: 'middle',
          type: 'category',
          data: labels,
          nameTextStyle: {
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: 24,
            color: 'black',
            fontFamily: 'Arial',
          },
          nameGap: 32,
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: yAxisTitle,
          nameLocation: 'middle',
          nameGap: 32,
          nameTextStyle: {
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: 24,
            color: 'black',
            fontFamily: 'Arial',
          },
          minInterval: 1,
          min: 0,
          max: yAxisMax,
        },
      ],
      series: [
        {
          name: behindString,
          type: 'bar',
          barMaxWidth: 30,
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          data: behind,
          itemStyle: {
            borderWidth: 3,
            borderColor: 'rgba(0, 0, 0, 0)',
            decal: {
              color: this.DECAL_COLOR,
              dashArrayX: [1, 0],
              dashArrayY: [2, 5],
              symbolSize: 1,
              rotation: -Math.PI / 6,
            },
          },
          color: this.BEHIND_COLOR,
        },
        {
          name: needsAttentionString,
          type: 'bar',
          barMaxWidth: 30,
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          data: needsAttention,
          itemStyle: {
            borderWidth: 3,
            borderColor: 'rgba(0, 0, 0, 0)',
            decal: {
              symbol: 'circle',
              symbolSize: 0.5,
              rotation: Math.PI / 6,
              color: this.DECAL_COLOR,
            },
          },
          color: this.NEEDS_ATTENTION_COLOR,
        },
        {
          name: onTrackString,
          type: 'bar',
          barMaxWidth: 30,
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          data: onTrack,
          itemStyle: {
            borderWidth: 3,
            borderColor: 'rgba(0, 0, 0, 0)',
            decal: {
              color: this.DECAL_COLOR,
              dashArrayX: [1, 0],
              dashArrayY: [2, 5],
              symbolSize: 1,
              rotation: Math.PI / 6,
            },
          },
          color: this.ON_TRACK_COLOR,
        },
        {
          name: excellingString,
          type: 'bar',
          barMaxWidth: 30,
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          data: excelling,
          itemStyle: {
            borderWidth: 3,
            borderColor: 'rgba(0, 0, 0, 0)',
          },
          color: this.EXCELLING_COLOR,
        },
      ],
      aria: {
        // Note: default screen reader behaviour is overriden in the template
        // keeping enabled for decal to work
        enabled: true,
        decal: {
          show: true,
        },
      },
    };

    this.chart.setOption(option);

    const chartSvg: SVGElement = this.chart.getDom().querySelector('svg');
    chartSvg.setAttribute('style', 'overflow: visible;');
    chartSvg.parentElement.setAttribute('style', 'overflow: visible;');

    // echart seems to set the id of this element to '0' which conflicts
    // causing an accessibility violation
    const chartRect: SVGRectElement = this.chart.getDom().querySelector('rect');
    chartRect.setAttribute('id', CommonUtility.createUniqueDOMId('0'));

    this.chart.on('legendselectchanged', (params: any) => {
      this.chart.setOption({ animation: false });
      this.chart.dispatchAction({
        type: 'legendSelect',
        name: params.name,
      });
      this.chart.setOption({ animation: true });
    });
  }

  public openTableModal(ev: Event): void {
    ev.preventDefault();

    const componentRef: WebsitesAppsByRiskManagementStatusTableModalComponent = this.dsModalService.open(
      WebsitesAppsByRiskManagementStatusTableModalComponent,
      DsModalVariant.center,
    ).componentInstance;
    componentRef.data = this.chartData;
    componentRef.frequency = this.frequency;
  }
}
