<div class="row">
  <div class="col-12">
    <span class="visually-hidden" id="healthGraphAriaLabel">
      {{ 'monitoring_report_accessibility_health_graph_description' | translate }}
    </span>
    <div
      id="echarts"
      (window:resize)="onWindowResize()"
      class="graph w-100"
      [attr.aria-hidden]="true"
      aria-labelledby="healthGraphAriaLabel"
    ></div>
  </div>
</div>
