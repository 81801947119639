<app-common-select
  [form]="form"
  [values]="viewports"
  [formValidationRequest$]="formValidationRequest$"
  [context]="{
    label: 'scan_viewport_label',
    field: $scanViewport.viewportType,
    nameKey: getViewportLabel(),
    valueKey: $scanViewport.viewportType,
  }"
></app-common-select>
<ng-container *ngIf="form.get($scanViewport.viewportType).value === viewportType.custom">
  <app-viewport-custom-values [viewportForm]="form"></app-viewport-custom-values>
</ng-container>
