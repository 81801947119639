import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-common-name-tag',
  templateUrl: './common-name-tag.component.html',
  styleUrls: ['./common-name-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonNameTagComponent {
  @Input()
  public fileName: string;

  @Input()
  public redBorder: boolean;

  @Output()
  public closeButtonClicked: EventEmitter<void>;

  constructor() {
    this.closeButtonClicked = new EventEmitter<void>();
  }

  public buttonClicked(): void {
    this.closeButtonClicked.emit();
  }
}
