import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Inject,
  Input,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonUtility } from '../utility/common.utility';
import { GeneralErrorComponent } from './general-error/general-error.component';
import { errorMessagesNames } from '../../../shared/constants/errors';
import { NoAccessToTenantComponent } from './no-access-to-tenant/no-access-to-tenant.component';
import { FOUND_ERROR_TOKEN } from './constants';

// TODO: EAP-17799 backfill the tests
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit, AfterViewInit {
  static errorPages: Record<string, Type<any>> = Object.freeze({
    [errorMessagesNames.NoAccessToTenant]: NoAccessToTenantComponent,
    [errorMessagesNames.UserInvalid]: NoAccessToTenantComponent,
    [errorMessagesNames.ExternalAuthUserNotFound]: NoAccessToTenantComponent,
    [errorMessagesNames.TenantNotFound]: NoAccessToTenantComponent,
  });

  @Input()
  public error: any;
  @ViewChild('errorComponent', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(FOUND_ERROR_TOKEN) private caughtError: any,
  ) {}

  private createComponent(): void {
    this.container.clear();
    const errorPage: Type<any> = MaintenanceComponent.errorPages[this.caughtError?.error?.app?.name] ?? GeneralErrorComponent;

    const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(errorPage);
    this.container.createComponent(factory);
  }

  private static hidePageLoader(): void {
    const pageLoaderElement: HTMLElement = document.querySelector('.page-loader');
    const pageLoaderStyles: HTMLElement = document.getElementById('loaderStyles');
    const loadingScript: HTMLElement = document.getElementById('loadingScript');

    if (pageLoaderElement && CommonUtility.isHostMethod(pageLoaderElement, 'remove')) {
      pageLoaderElement.remove();
    }

    if (pageLoaderStyles && CommonUtility.isHostMethod(pageLoaderStyles, 'remove')) {
      pageLoaderStyles.remove();
    }

    if (loadingScript && CommonUtility.isHostMethod(loadingScript, 'remove')) {
      loadingScript.remove();
    }
  }

  public ngOnInit(): void {
    MaintenanceComponent.hidePageLoader();
  }

  public ngAfterViewInit(): void {
    this.createComponent();
  }
}
