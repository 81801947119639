<nav
  [class.compound-third-level-navbar]="!showMobileNavVersion"
  [class.compound-third-level-navbar-mobile]="showMobileNavVersion"
  *ngIf="(!showMobileNavVersion && navbarItems.length > 0) || (showMobileNavVersion && mobileNavItemInTheActiveSubtree$ | async)"
  [attr.aria-label]="'third_level_navbar_label' | translate"
>
  <div class="container py-2 g-5">
    <ng-container *ngTemplateOutlet="showMobileNavVersion ? mobileVersion : desktopVersion"></ng-container>
  </div>
</nav>

<ng-template #desktopVersion>
  <div class="webapp-select">
    <app-websites-apps-select label="website_and_app_label" [state]="DsInputStates.inline"></app-websites-apps-select>
  </div>
  <div class="menu" #navbarMenuItemsWrapper >
    <compound-navbar-link *ngFor="let item of visibleNavbarItems" [navLink]="item.routerLink" [isActive]="item.isActive">
      {{ item.label | translate }}
    </compound-navbar-link>
    <ds-dropdown class="dropdown-menu-items" *ngIf="menuNavbarItems.length > 0" [selectedValue]="(activeNavItem$ | async)?.id">
      <ds-dropdown-toggle
        [variant]="DsButtonVariants.microAction"
        label="{{ 'more' | translate }}"
        [icon]="Icons.Plus"
      ></ds-dropdown-toggle>
      <div ds-dropdown-menu>
        <ds-dropdown-item
          *ngFor="let item of menuNavbarItems"
          [href]="item.routerLink"
          [label]="item.label | translate"
          [value]="item.id"
        ></ds-dropdown-item>
      </div>
    </ds-dropdown>
  </div>
  <ng-container *ngIf="gearRoutes$ | async as gearRoutes">
    <ds-dropdown
      class="gear-dropdown-menu"
      *ngIf="gearRoutes.length > 0"
      [selectedValue]="(activeNavItem$ | async)?.id"
      [ds-tooltip]="'scan_settings_tooltip_label' | translate"
      [tooltipPlacement]="DsTooltipPosition.bottom"
    >
      <ds-dropdown-toggle
        [variant]="DsButtonVariants.microAction"
        [icon]="Icons.Gear"
        [ariaLabel]="'scan_settings' | translate"
      ></ds-dropdown-toggle>
      <div ds-dropdown-menu>
        <ds-dropdown-item
          *ngFor="let item of gearRoutes"
          [href]="item.routerLink"
          [label]="item.label | translate"
          [value]="item.id"
          [icon]="item.iconName"
        ></ds-dropdown-item>
      </div>
    </ds-dropdown>
  </ng-container>

  <div class="ds-visually-hidden menu" aria-hidden="true">
    <compound-navbar-link *ngFor="let item of navbarItems" #invisibleMenuItems >
      {{ item.label | translate }}
    </compound-navbar-link>
    <ds-dropdown class="dropdown-menu-items" #invisibleMoreMenu >
      <ds-dropdown-toggle
        [variant]="DsButtonVariants.microAction"
        label="{{ 'more' | translate }}"
        [icon]="Icons.Plus"
      ></ds-dropdown-toggle>
      <div ds-dropdown-menu></div>
    </ds-dropdown>
    <div class="menu-space-between-items" #invisibleSpaceBetweenItems></div>
  </div>
</ng-template>

<ng-template #mobileVersion>
  <div class="row">
    <div class="col-12">
      <span class="mobile-navbar-label" >
        {{ mobileNavbarLabel$ | async }}
      </span>
    </div>
  </div>
</ng-template>
