import { environment } from '../environments/environment';
import { UserService } from './services/user.service';
import { TranslateService } from './translate/translate.service';
import { BusMessageService } from './services/bus-message.service';
import { FeaturesService } from './services/features.service';
import { TrackingConsoleService } from './services/tracking/tracking-console.service';
import { LazyLoadingStylesService } from './services/lazy-loading-styles.service';
import { AppConfigService } from './services/app-config.service';
import { LinkedPropertyDataService } from './services/linked-property-data.service';

export function AppInitialization(
  userService: UserService,
  translateService: TranslateService,
  messageBusService: BusMessageService,
  featuresService: FeaturesService,
  appConfigService: AppConfigService,
  trackingConsoleService: TrackingConsoleService,
  lazyLoadingStylesService: LazyLoadingStylesService,
  linkedPropertyDataService: LinkedPropertyDataService,
): Function {
  const init = async (): Promise<void> => {
    if (environment.production) {
      trackingConsoleService.createConsoleWrapper();
    }

    const setApplication = async (): Promise<[void, void, void]> => {
      return Promise.all([
        translateService.initializeTranslations(),
        featuresService.fetchFeaturesConfig(),
        messageBusService.initChannels(),
      ]);
    };

    const lazyLoadStyles = async (): Promise<void> => {
      return lazyLoadingStylesService.loadAppStyles();
    };

    const setupLinkedPropertyDataPreserver = async (): Promise<void> => {
      linkedPropertyDataService.preserveLinkedPropertyParam();
    };

    return appConfigService
      .fetchConfig()
      .then(userService.getAuthStatus.bind(userService))
      .then(setupLinkedPropertyDataPreserver)
      .then(setApplication)
      .then(lazyLoadStyles);
  };

  return async (): Promise<void> => {
    return Promise.resolve().then(init);
  };
}
