export enum $auditRule {
  _id = '_id',
  atCombination = 'atCombination',
  actualResult = 'actualResult',
  applicableElementsSelectors = 'applicableElementsSelectors',
  categories = 'categories',
  complexity = 'complexity',
  description = 'description',
  disabilitiesAffected = 'disabilitiesAffected',
  enabled = 'enabled',
  functionalImpact = 'functionalImpact',
  history = 'history',
  href = 'href',
  isAvailableForScanning = 'isAvailableForScanning',
  isDisabled = 'isDisabled',
  isSelectedForScanning = 'isSelectedForScanning',
  reasonForImportance = 'reasonForImportance',
  recommendation = 'recommendation',
  results = 'results',
  ruleId = 'ruleId',
  severity = 'severity',
  severityNumber = 'severityNumber',
  standards = 'standards',
  stepsToReproduce = 'stepsToReproduce',
  successCriterias = 'successCriterias',
  techniques = 'techniques',
  testType = 'testType',
  title = 'title',
  tool = 'tool',
  toolVersions = 'toolVersions',
  whatToDo = 'whatToDo',
  weight = 'weight',
  wcagCriteria = 'wcagCriteria',
  wcagConformanceLevel = 'wcagConformanceLevel',
  ruleLibrary = 'ruleLibrary',
  category = 'category',
  mediaType = 'mediaType',
  bestPracticeId = 'bestPracticeId',
  testId = 'testId',
  testName = 'testName',
  testManualSteps = 'testManualSteps',
  mapsTo = 'mapsTo',
  referencedRule = 'referencedRule',
  userImpact = 'userImpact',
  compliantCodeExample = 'compliantCodeExample',
  nonCompliantCodeExample = 'nonCompliantCodeExample',
  effort = 'effort',
}

export enum $auditRuleStandard {
  wcag_20 = 'wcag_20',
  wcag_21 = 'wcag_21',
  wcag_22 = 'wcag_22',
  best_practice = 'best_practice',
}

export enum $auditRuleSearch {
  levels = 'levels',
  severities = 'severities',
  standards = 'standards',
  tools = 'tools',
  ruleIdSubstring = 'ruleIdSubstring',
  ruleIdOrKeyword = 'ruleIdOrKeyword',
  ruleLibrary = 'ruleLibrary',
  category = 'category',
}

export const ManualCustomRulePrefix: string = 'man-cust-';

export enum AuditRuleAction {
  Create = 'Create',
  Update = 'Update',
}
