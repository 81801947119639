<div [formGroup]="form" class="authentication-details">
  <app-common-checkbox
    [(ngModel)]="addAuthDetails"
    (ngModelChange)="onAddAuthenticationDetails($event)"
    [disabled]="isDisabled"
    [ngModelOptions]="{ standalone: true }"
    
  >
    <span class="checkbox-label">{{ 'label_add_authentication_details' | translate }}</span>
  </app-common-checkbox>
  <div *ngIf="addAuthDetails" class="row margin-top margin-left">
    <div class="col-12 margin-bottom">
      <select
        [id]="scanCreateRequest.scanAuthenticationType"
        [formControlName]="scanCreateRequest.scanAuthenticationType"
        [attr.aria-label]="'select_scan_authentication_type_label' | translate"
      >
        <ng-container *ngFor="let type of scanAuthenticationType | keys: scanAuthenticationType.no_authentication">
          <option [ngValue]="type">{{ 'scan_authentication_type_' + type | translate }}</option>
        </ng-container>
      </select>
    </div>
    <ng-container [ngSwitch]="form.get(scanCreateRequest.scanAuthenticationType).value">
      <ng-container *ngSwitchCase="scanAuthenticationType.basic_authentication">
        <app-common-input
          [form]="form"
          [context]="getInputFieldContext(scanCreateRequest.scanAuthenticationUserName)"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
        >
        </app-common-input>
        <app-common-input
          [form]="form"
          [context]="getInputFieldContext(scanCreateRequest.scanAuthenticationPassword)"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
        >
        </app-common-input>
      </ng-container>
      <ng-container *ngSwitchCase="scanAuthenticationType.secure_web_authentication">
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaLoginPageURL)"
        ></app-common-input>
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaUserName)"
        ></app-common-input>
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaUserNameFieldId)"
        ></app-common-input>
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaSubmitUsernameFieldId)"
        ></app-common-input>
        <div class="col-12 col-xl-6" aria-hidden="true"></div>
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaPassword)"
        ></app-common-input>
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaPasswordFieldId)"
        ></app-common-input>
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaSubmitFieldId)"
        ></app-common-input>
        <app-common-input
          [form]="form"
          [formValidationRequest$]="formValidationRequest$"
          class="col-12 col-xl-6 margin-bottom"
          [context]="getInputFieldContext(scanCreateRequest.swaSubmitSuccessId)"
        ></app-common-input>
      </ng-container>
    </ng-container>
  </div>
</div>
