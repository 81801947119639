import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';
import { EssentialVersion } from '../../../interfaces/audit-rule.interface';
import { SuccessCriteriaIdentifier } from '../../../constants/audit-standard-success-criteria';

const aslint: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: 'Best Practice',
    [$successCriteria.level]: '',
    [$successCriteria.num]: SuccessCriteriaIdentifier.EABP1,
    [$successCriteria.title]: 'Level Access Best Practice',
    [$successCriteria.versions]: [EssentialVersion.v10],
    [$successCriteria.url]: '',
  },
];

export class Aslint implements ISuccessCriteriaIndex {
  public get(num: string): ISuccessCriteria {
    const aslintSuccessCriteria: ISuccessCriteria | undefined = aslint.find(
      (bestPracticeSuccessCriteria: ISuccessCriteria) => bestPracticeSuccessCriteria.num === num,
    );
    if (typeof aslintSuccessCriteria === 'undefined') {
      return null;
    }
    return aslintSuccessCriteria;
  }

  public getAll(): ISuccessCriteria[] {
    return aslint;
  }
}
