import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CommonUtility } from '../../utility/common.utility';

export interface CheckboxContext {
  id?: string;
  describedBy?: string;
}

@Component({
  selector: 'app-common-checkbox',
  templateUrl: './common-checkbox.component.html',
  styleUrls: ['./common-checkbox.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CommonCheckboxComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input()
  public disabled: boolean;
  public onTouched: Function;
  public onChange: Function;

  public value: boolean;

  @Input()
  public indeterminate: boolean;

  @Input()
  public context: CheckboxContext;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.onTouched = (): void => {};
    this.onChange = (): void => {};
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  public writeValue(obj: any): void {
    this.value = obj;
    this.changeDetectorRef.detectChanges();
  }

  public onValueChanged(value: boolean): void {
    if (value !== this.value) {
      this.onChange(value);
    }
    this.value = value;
    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    this.context = {
      id: CommonUtility.createUniqueDOMId(this.context?.id),
      describedBy: this.context?.describedBy,
    };
  }
}
