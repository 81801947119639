import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CommonUtility } from '../../utility/common.utility';
import { MarkdownUtility } from '../../../../shared/utils/markdown.utility';

export enum MarkdownEditorMode {
  Preview,
  Edit,
}

export interface IMarkdownEditorConfig {
  /**
   * Number of rows for the textarea.
   */
  rows?: number;
  required?: boolean;
  /**
   * Whether the textarea should be resizable. Only supports vertical resizing.
   */
  resizable?: boolean;
  /**
   * Mode to start editor in. Defaults to edit.
   */
  mode?: MarkdownEditorMode;
  /**
   * min height of textarea and preview.
   */
  minHeight?: string;
}

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkdownEditorComponent),
      multi: true,
    },
  ],
})
export class MarkdownEditorComponent implements OnInit, ControlValueAccessor {
  public internalValue: string = '';

  @Input() public config: IMarkdownEditorConfig = {
    rows: 10,
    required: false,
    resizable: false,
    mode: MarkdownEditorMode.Edit,
    minHeight: '240px',
  };

  public MarkdownEditorMode: typeof MarkdownEditorMode;

  public disabled: boolean = false;
  public domId: string = CommonUtility.createUniqueDOMId();
  public mode: MarkdownEditorMode = MarkdownEditorMode.Edit;

  private onChange: Function = () => {};

  public ngOnInit(): void {
    this.MarkdownEditorMode = MarkdownEditorMode;
    this.mode = this.config.mode;
  }

  public propagateChange(): void {
    const cleaned: string = MarkdownUtility.sanitize(this.internalValue || '');
    this.onChange(cleaned);
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {}

  public setDisabledState(isDisabled: boolean): void {
    if (this.disabled !== isDisabled) {
      this.disabled = isDisabled;
    }
  }

  public writeValue(obj: string): void {
    this.internalValue = obj;
  }

  public render(): any {
    return MarkdownUtility.render(this.internalValue || '');
  }

  public toggleMode(): void {
    this.mode = this.mode === MarkdownEditorMode.Edit ? MarkdownEditorMode.Preview : MarkdownEditorMode.Edit;
  }
}
