<div class="app-svg-images">
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <symbol id="image-logo" viewBox="0 0 213.88 80.44" xmlns="http://www.w3.org/2000/svg">
      <rect class="acls-1" width="106.94" height="80.44" rx="40.22" fill="#bcd9ea" />
      <rect class="acls-1" x="106.94" width="106.94" height="80.44" rx="40.22" fill="#bcd9ea" />
      <circle class="acls-2" cx="107.61" cy="40.22" r="26.97" fill="#fff" />
      <path class="acls-2" d="M37.44 64.7z" fill="#fff" />
      <path
        class="acls-3"
        d="M45.71 23.2a4.09 4.09 0 1 0-4.14-4.08 4.11 4.11 0 0 0 4.14 4.08zM56.51 50.36a2.38 2.38 0 0 0-1.61 1.75v.09a12.06 12.06 0 0 1-7.81 7.65 11.38 11.38 0 0 1-4.89.41 12.8 12.8 0 0 1-6.5-3.14l-.19-.12c-.18-.17-.35-.35-.51-.53l-.45-.47-.14-.18a12.33 12.33 0 0 1-2.19-4.19 12.93 12.93 0 0 1-.42-5.39A12.26 12.26 0 0 1 38 37.1l.07-.06a2.44 2.44 0 0 0-.53-4.65 2.27 2.27 0 0 0-1.37.22 17.22 17.22 0 0 0-9.2 13 17.69 17.69 0 0 0 .64 7.8 16.6 16.6 0 0 0 20.65 11.28 17 17 0 0 0 11.15-11.3 2.48 2.48 0 0 0 0-1.43 2.33 2.33 0 0 0-2.9-1.6z"
        fill="#103c60"
      />
      <path
        class="acls-3"
        d="M75.88 53.74a1.86 1.86 0 0 0-2.5-.67l-2.73 1.53-6.91-10.43a2.48 2.48 0 0 0-2.11-1.28h-13v-4.13h9a2.6 2.6 0 0 0 0-5.17h-9v-5.37a2.92 2.92 0 0 0-5.84 0v17a3 3 0 0 0 2.67 2.92 4 4 0 0 0 .6 0h15.08l6.57 9.9a2.16 2.16 0 0 0 1.34.92 1.84 1.84 0 0 0 .41.06h.06a1.82 1.82 0 0 0 .72-.12h.25l4.7-2.63a1.8 1.8 0 0 0 .69-2.53zM180.94 64.45a.44.44 0 0 1-.44.44h-36.18a.44.44 0 0 1-.44-.44V51.24a.44.44 0 0 1 .44-.44h36.18a.43.43 0 0 1 .44.44v13.21m-23.51-7.36H161a.39.39 0 0 0 .38-.39v-3.57a.39.39 0 0 0-.38-.39h-3.57a.39.39 0 0 0-.39.39v3.57a.39.39 0 0 0 .39.39m-6.78 1.28h-3.57a.38.38 0 0 0-.38.39v3.57a.38.38 0 0 0 .38.38h3.57a.38.38 0 0 0 .39-.38v-3.57a.38.38 0 0 0-.39-.39m0-1.28h3.57a.39.39 0 0 0 .39-.39v-3.57a.39.39 0 0 0-.39-.39h-3.57a.39.39 0 0 0-.39.39v3.57a.39.39 0 0 0 .39.39m13.53 0h3.57a.39.39 0 0 0 .39-.39v-3.57a.39.39 0 0 0-.39-.39h-3.57a.39.39 0 0 0-.38.39v3.57a.39.39 0 0 0 .38.39m6.77 0h3.57a.39.39 0 0 0 .38-.39v-3.57a.39.39 0 0 0-.38-.39H171a.38.38 0 0 0-.38.39v3.57a.38.38 0 0 0 .38.39m0 1.28h-3.57a.38.38 0 0 0-.38.39v3.57a.38.38 0 0 0 .38.38H171a.38.38 0 0 0 .39-.38v-3.57a.38.38 0 0 0-.39-.39m-13.53 0h-3.57a.38.38 0 0 0-.38.39v3.57a.37.37 0 0 0 .38.38h3.57a.38.38 0 0 0 .39-.38v-3.57a.38.38 0 0 0-.39-.39m20.3 0h-3.57a.38.38 0 0 0-.38.39v3.57a.38.38 0 0 0 .38.38h3.57a.39.39 0 0 0 .39-.38v-3.57a.39.39 0 0 0-.39-.39m-13.53 0h-3.57a.38.38 0 0 0-.39.39v3.57a.38.38 0 0 0 .39.38h3.57a.39.39 0 0 0 .39-.38v-3.57a.39.39 0 0 0-.39-.39m-19.43-9.82a.36.36 0 0 0 .33.39h34.64a.37.37 0 0 0 .33-.39V15.94a.36.36 0 0 0-.33-.39h-34.69a.36.36 0 0 0-.33.39zm4.69-5.78v-22a.31.31 0 0 1 .3-.32h25.52a.3.3 0 0 1 .29.32v22a.3.3 0 0 1-.29.32h-25.57a.31.31 0 0 1-.3-.32M204.13 80v-4.42h-1.59v-.91h4.26v.91h-1.59V80zM207.49 80v-5.33h1.62l1 3.67 1-3.67h1.62V80h-1v-4.18L210.6 80h-1l-1.06-4.22V80zM118.54 35.11L97.08 23.94c0 4.6.52 7.56 3.67 9.2l10.78 5.61zM121.35 36.57l-7 3.64-13.5 7c-3.07 1.67-3.77 5.29-3.77 9.26l19.49-10.14c4.31-2.35 4.78-5.22 4.78-9.79z"
        fill="#103c60"
      />
    </symbol>
    <symbol id="screenshot" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>>{{ 'page_screenshot' | translate }}</title>
      <path
        d="M6 19v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 2 0zM10 5a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2h-2a1 1 0 0 0-1 1zM5 14a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zM23 6h1v1a1 1 0 0 0 2 0V6a2 2 0 0 0-2-2h-1a1 1 0 0 0 0 2zM16 5a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2h-2a1 1 0 0 0-1 1zM7 24H6v-1a1 1 0 0 0-2 0v1a2 2 0 0 0 2 2h1a1 1 0 0 0 0-2zM6 7V6h1a1 1 0 0 0 0-2H6a2 2 0 0 0-2 2v1a1 1 0 0 0 2 0zM24 11v2.001a1 1 0 0 0 2 0V11a1 1 0 0 0-2 0z"
      />
      <g>
        <path
          d="M25 16h-1.764a2 2 0 0 1-1.789-1.106l-.171-.342a1 1 0 0 0-.894-.552h-4.764a.998.998 0 0 0-.894.553l-.171.342A2.002 2.002 0 0 1 12.764 16H11a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1zm-7 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
        />
        <circle cx="18" cy="21" r="2" />
      </g>
    </symbol>
  </svg>
</div>
