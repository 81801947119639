import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

const FOCUSABLE_ELEMENTS: string[] = ['button', '[href]', 'input', 'select', 'textarea', '[tabindex]'];
const INVALID_ATTRIBUTES: string = [':not([tabindex="-1"])', ':not([disabled=true])'].join('');
const FOCUSABLE_QUERY: string = FOCUSABLE_ELEMENTS.map(
  (focusableElement: string) => `${focusableElement}${INVALID_ATTRIBUTES}`,
).join(',');

@Directive({
  selector: '[appFocusFirstFocusableElement]',
})
export class FocusFirstFocusableElementDirective implements AfterViewInit {
  constructor(private el: ElementRef<any>) {}

  public ngAfterViewInit(): void {
    const element: HTMLElement = this.el.nativeElement.querySelector(FOCUSABLE_QUERY);
    if (SharedCommonUtility.notNullish(element)) {
      element.focus();
    }
  }
}
