import { scanningJobType } from './scanning';

export enum AuditTypes {
  accessibility = 'accessibility',
  accessibility_axe = 'accessibility_axe',
  accessibility_ibm = 'accessibility_ibm',
  accessibility_wave = 'accessibility_wave',
  accessibility_access = 'accessibility_access',
  accessibility_manual = 'accessibility_manual',
  screenshots = 'screenshots',
}
type AutomatedAuditTypes = Exclude<AuditTypes, AuditTypes.accessibility_manual>;

export const AuditTypeToScanningJobType: Readonly<Record<AutomatedAuditTypes, scanningJobType>> = {
  [AuditTypes.accessibility]: scanningJobType.accessibility,
  [AuditTypes.accessibility_access]: scanningJobType.accessibility_access,
  [AuditTypes.accessibility_axe]: scanningJobType.accessibility_axe,
  [AuditTypes.accessibility_ibm]: scanningJobType.accessibility_ibm,
  [AuditTypes.accessibility_wave]: scanningJobType.accessibility_wave,
  [AuditTypes.screenshots]: scanningJobType.screenshot,
};

const typesReducer: (
  prev: Record<scanningJobType, AuditTypes>,
  [audit, job]: [AuditTypes, scanningJobType],
) => Record<scanningJobType, AuditTypes> = (
  prev: Record<scanningJobType, AuditTypes>,
  [audit, job]: [AuditTypes, scanningJobType],
) => {
  prev[job] = audit;
  return prev;
};

export const ScanningJobTypeToAuditType: Readonly<Record<scanningJobType, AuditTypes>> = Object.entries(
  AuditTypeToScanningJobType,
).reduce(typesReducer, {} as Record<scanningJobType, AuditTypes>);

export const AuditTypeNames: { [key in keyof typeof AuditTypes]: string } = Object.freeze({
  [AuditTypes.accessibility]: 'Accessibility',
  [AuditTypes.accessibility_access]: 'Access Engine',
  [AuditTypes.accessibility_axe]: 'aXe Accessibility',
  [AuditTypes.accessibility_ibm]: 'IBM Accessibility',
  [AuditTypes.accessibility_wave]: 'Wave Accessibility',
  [AuditTypes.accessibility_manual]: 'Manual Accessibility',
  [AuditTypes.screenshots]: 'Screenshots',
});

export const WcagAuditTypes: Readonly<AuditTypes[]> = Object.freeze([
  AuditTypes.accessibility_access,
  AuditTypes.accessibility_axe,
  AuditTypes.accessibility_ibm,
  AuditTypes.accessibility_wave,
]);
