<div
  class="alert alert-box margin-bottom d-flex"
  [ngClass]="{
    'alert-box-warning': alertType === AlertType.warning,
    'alert-box-info': alertType === AlertType.info,
    'alert-box-error': alertType === AlertType.error,
  }"
  role="alert"
  aria-live="polite"
>
  <div class="me-2" *ngIf="hasIcon">
    <ng-container [ngSwitch]="alertType">
      <svg class="alert-warning-icon" viewBox="0 0 16 16" aria-hidden="true" *ngSwitchCase="AlertType.warning">
        <use xlink:href="#exclamation-triangle"></use>
      </svg>
      <svg class="alert-info-icon" viewBox="0 0 16 16" aria-hidden="true" *ngSwitchCase="AlertType.info">
        <use xlink:href="#info-circle-fill"></use>
      </svg>
      <ds-icon
        [icon]="Icons.TriangleExclamation"
        [iconStyle]="IconStyles.Regular"
        *ngSwitchCase="AlertType.error"
        [style.color]="EapColors.RedError"
      ></ds-icon>
    </ng-container>
  </div>
  <div class="ms-2">
    <div class="alert-title mb-2" *ngIf="title">{{ title }}</div>
    <div class="alert-text">{{ text }}</div>
  </div>
  <div class="ms-auto" *ngIf="closeable">
    <button ds-button [variant]="DsButtonVariants.microAction" [microActionIcon]="Icons.XMark" (click)="onClose.emit()"></button>
  </div>
</div>
