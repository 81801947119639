import { Injectable } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { A11Y_MESSAGE_TIMEOUT_IN_MILLIS } from '../shared/constants';

@Injectable()
export class A11yService {
  constructor(private liveAnnouncer: LiveAnnouncer) {}

  public setMessage(message: string): void {
    this.liveAnnouncer.announce(message, 'polite', A11Y_MESSAGE_TIMEOUT_IN_MILLIS);
  }

  public setAlertMessage(message: string): void {
    this.liveAnnouncer.announce(message, 'assertive', A11Y_MESSAGE_TIMEOUT_IN_MILLIS);
  }
}
