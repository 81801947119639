<ds-modal [title]="'unauthorized' | translate">
  <div class="error-content mb-4">
    <span class="label">
      {{ 'integration_type_supplied_error' | translate: [connection.integrationType | translate] }}
    </span>
    <span> {{ connection.lastReportedError }}</span>
  </div>
  <div class="buttons d-flex gap-3 mt-2">
    <button ds-button type="button" [variant]="DsButtonVariants.primary" (click)="reauthorizeClicked()">
      {{ 'label_reauthorize_connection' | translate }}
    </button>
    <button ds-button type="button" [variant]="DsButtonVariants.secondary" (click)="cancelClicked()">
      {{ 'label_cancel' | translate }}
    </button>
  </div>
</ds-modal>
