export enum $scanTag {
  _id = '_id',
  digitalPropertyId = 'digitalPropertyId',
  tagName = 'tagName',
  description = 'description',
  lastUsed = 'lastUsed',
  scanCount = 'scanCount',
}

export enum SystemTags {
  Default = 'Default',
  Monitoring = 'Monitoring',
  MonitoredPage = 'Monitored page',
  GuidedUserFlow = 'Guided user flow',
  AutomatedUserFlow = 'Automated user flow',
}

export const reservedSystemTags: Readonly<SystemTags[]> = Object.freeze([
  SystemTags.Monitoring,
  SystemTags.MonitoredPage,
  SystemTags.GuidedUserFlow,
  SystemTags.AutomatedUserFlow,
]);

export const TagNameMaxLength: number = 30;
