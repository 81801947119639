import { Component, Input } from '@angular/core';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { SupplementaryTableController } from './supplementary-table-controller';

@Component({
  selector: 'app-rule-issues-supplementary-table',
  templateUrl: './rule-issues-supplementary-table.component.html',
  styleUrls: ['./rule-issues-table.component.scss'],
  providers: [NgbPaginationConfig],
})
export class RuleIssuesSupplementaryTableComponent {
  @Input()
  public tableController: SupplementaryTableController;
  @Input()
  public title: string;
  @Input()
  public description: string;
  @Input()
  public name: string;
  @Input()
  public topPaginationAriaLabel: string;
  @Input()
  public bottomPaginationAriaLabel: string;
}
