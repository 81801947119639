import { Directive, HostBinding, Input } from '@angular/core';
import { TranslateService } from '../../translate/translate.service';

@Directive({
  selector: '[appAriaLabelSort]',
})
export class AriaLabelSortDirective {
  @Input()
  appAriaLabelSort: string;
  @Input()
  sortColumn: string;
  @Input()
  sortColumnExtra: string;

  @HostBinding('attr.aria-label') get ariaLabel(): string {
    const sortCol: string = this.translateService.instant(this.sortColumn, this.sortColumnExtra);
    const sortDir: string = this.translateService.instant(this.appAriaLabelSort ?? 'none').toLowerCase();
    const sortTxt: string = this.translateService.instant('label_sort').toLowerCase();
    return `${sortCol} ${sortDir} ${sortTxt}`;
  }

  constructor(private translateService: TranslateService) {}
}
