<ng-container *ngIf="data.canChange; else readOnlyStatus">
  <span class="rule-status-label-wrapper" [ngClass]="{ 'status-on': data.enabled }">
    <select
      class="ps-2 pe-4 py-1 rule-status-label dropdown"
      [(ngModel)]="data.enabled"
      [ngClass]="{ 'status-on': data.enabled }"
      (change)="showChangeRuleStatusDialog()"
    >
      <option [ngValue]="true">{{ 'label_on' | translate }}</option>
      <option [ngValue]="false">{{ 'label_off' | translate }}</option>
    </select>
  </span>
</ng-container>
<ng-template #readOnlyStatus>
  <span class="px-2 rule-status-label" [ngClass]="{ 'status-on': data.enabled }">
    {{ (data.enabled ? 'label_on' : 'label_off') | translate }}
  </span>
</ng-template>
<div class="d-inline-block ms-3">
  <app-view-rule-history
    *ngIf="data.changeLog.length > 0"
    [ruleTitle]="data.title"
    [historyInput]="data.changeLog"
  ></app-view-rule-history>
</div>
