import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../../../../../shared/constants/api';
import { AppConfigService } from '../../app-config.service';
import { UserService } from '../../user.service';

@Injectable()
export class AuthorizeOnlyStaffUsersSsoTenantGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private appConfigService: AppConfigService,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const forbiddenPage: UrlTree = this.router.parseUrl(Api.forbidden);

    if (!this.appConfigService.isUsingExternalIdp()) {
      return of(true);
    }

    return this.userService.hasTenantStaffAccess().pipe(
      map((hasStaffAccess: boolean): boolean | UrlTree => {
        return hasStaffAccess || forbiddenPage;
      }),
    );
  }
}
