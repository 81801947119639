import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-svg-image-filters',
  templateUrl: '../../assets/images/image-filters.html',
})
export class SvgImageFiltersComponent {
  // 'display: none' causes image filters to fail in firefox
  @HostBinding('style.display') public inLineBlock: string = 'inline-block';
  @HostBinding('style.height') public height: string = '0';
  @HostBinding('style.width') public width: string = '0';
  @HostBinding('class') public class: string = 'visually-hidden';
  @HostBinding('attr.aria-hidden') public ariaHidden: boolean = true;
}
