import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { IModal } from '../../../../../../components/modals/modal.interface';
import { ModalContainerComponent } from '../../../../../../components/modals/modal-container.component';
import { ModalService } from '../../../../../../services/modal.service';
import { WorkspaceService } from '../../../../../../services/workspace.service';
import { IUser } from '../../../../../../../../shared/interfaces/user.interface';
import { AccessibilityAuditToolNames } from '../../../../../../../../shared/constants/audit-tool';
import { TranslateService } from '../../../../../../translate/translate.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { NotificationPosition } from '../../../../../../models/notification.model';
import { IModalDialogInputData } from '../../../../../../interfaces/modal-dialog.interface';
import { ReportService } from '../../../../../../services/report.service';
import { IReport } from '../../../../../../interfaces/report.interface';
import { LoadErrorHandler } from '../../../../../../services/load-error-handler';
import { WorkspaceUserSearchFields } from '../../../../../../../../shared/constants/workspace-user.constants';
import { accountStatus } from '../../../../../../../../shared/constants/user';

@Component({
  selector: 'app-share-report-modal',
  templateUrl: './share-report-modal.component.html',
  styleUrls: ['./share-report-modal.component.scss'],
})
export class ShareReportModalComponent implements OnInit, IModal, OnDestroy {
  @Input() public modalInputData: IModalDialogInputData<{
    workspaceId: string;
    digitalPropertyId: string;
    scanId: string;
    auditTool: AccessibilityAuditToolNames;
  }>;
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  public subscriptions: Subscription;
  public possibleEmails: string[];
  public selectedEmails: string[];
  public form: UntypedFormGroup;

  constructor(
    private modalService: ModalService,
    private reportService: ReportService,
    private workspaceService: WorkspaceService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private loadErrorHandler: LoadErrorHandler,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.subscriptions = new Subscription();
    this.possibleEmails = [];
    this.selectedEmails = [];
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      email: this.formBuilder.control(null),
    });
    this.subscriptions.add(
      this.form.get('email').valueChanges.subscribe((value: string[]) => {
        this.selectedEmails = value;
      }),
    );
  }

  private onGetUsersSuccess(users: IUser[]): void {
    this.possibleEmails = users.map((user: IUser) => user.email);
  }

  private onGetUsersError(error: HttpErrorResponse): void {
    this.loadErrorHandler.handleError('unable_to_get_all_workspace_users', error, ShareReportModalComponent.name);
    this.close();
  }

  private getWorkspaceUsers(): void {
    this.subscriptions.add(
      this.workspaceService
        .getUsers(this.modalInputData.data.workspaceId, {
          [WorkspaceUserSearchFields.status]: [accountStatus.active],
          [WorkspaceUserSearchFields.includeStaffUsers]: true,
        })
        .subscribe({ next: this.onGetUsersSuccess.bind(this), error: this.onGetUsersError.bind(this) }),
    );
  }

  private onSendReportSuccess(report: IReport): void {
    this.close();
    this.notificationService.success(this.translateService.instant('sending_report_success'), NotificationPosition.Toast);
    console.debug('[ShareReportModalComponent.onSendReportSuccess', report);
  }

  private onGetReportError(response: HttpErrorResponse): void {
    this.close();
    this.loadErrorHandler.handleError('scanning_send_report_to_emails_error', response, ShareReportModalComponent.name);
  }

  public close(): void {
    this.modalService.closeModal();
  }

  public onShareReport(): void {
    this.subscriptions.add(
      this.reportService
        .sendReportToEmails(
          this.modalInputData.data.workspaceId,
          this.modalInputData.data.digitalPropertyId,
          this.modalInputData.data.scanId,
          this.modalInputData.data.auditTool,
          this.selectedEmails,
        )
        .subscribe(this.onSendReportSuccess.bind(this), this.onGetReportError.bind(this)),
    );
  }

  public ngOnInit(): void {
    this.getWorkspaceUsers();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
