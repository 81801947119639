import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { CustomValidators } from '../../services/helpers/form-custom-validators';
import { ExcludePagesOperation } from '../../../../shared/interfaces/scan.interface';
import { TranslateService } from '../../translate/translate.service';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

export const EXCLUDE_PAGES_FIELD_NAME: 'excludePagesComponent' = 'excludePagesComponent';

@Component({
  selector: 'app-exclude-pages',
  templateUrl: './exclude-pages.component.html',
  styleUrls: ['./exclude-pages.component.scss'],
})
export class ExcludePagesComponent implements OnInit {
  private maxInputLength: number = 100;
  private maxParamLength: number = 10;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public field: string;
  @Input()
  public excludeOperations: ExcludePagesOperation[];
  @Input()
  public excludeUrls: string[];
  public showControls: boolean;
  public operations: { key: string; value: string }[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {
    this.field = EXCLUDE_PAGES_FIELD_NAME;
    this.operations = Object.values(ExcludePagesOperation).map((val: string): { key: string; value: string } => ({
      key: this.translateService.instant(`label_advanced_search_operation_${val}`),
      value: val,
    }));
  }

  public addControl(): void {
    this.fieldForm.push(
      this.formBuilder.group({
        operation: this.formBuilder.control(ExcludePagesOperation.contains, [
          CustomValidators.required,
          CustomValidators.validateIsEmpty,
        ]),
        value: this.formBuilder.control('', [
          CustomValidators.required,
          CustomValidators.validateIsEmpty,
          CustomValidators.maxLength(this.maxInputLength),
        ]),
      }),
    );
  }

  public get fieldForm(): UntypedFormArray {
    return this.form.get(this.field) as UntypedFormArray;
  }

  public get fieldValues(): UntypedFormGroup[] {
    return this.fieldForm.controls as UntypedFormGroup[];
  }

  public get isMaxParam(): boolean {
    return this.fieldForm.length >= this.maxParamLength;
  }

  public onShowControlsChange(value: boolean): void {
    if (value === false) {
      this.form.removeControl(this.field);
      return;
    }

    if (this.form.contains(this.field) === false) {
      this.form.registerControl(this.field, this.formBuilder.array([]));
      this.addControl();
    }
  }

  public removeControl(index: number): void {
    this.fieldForm.removeAt(index);
  }

  public ngOnInit(): void {
    if (this.fieldForm || SharedCommonUtility.isNullishOrEmpty(this.excludeOperations)) {
      this.showControls = this.fieldForm ? this.fieldForm.length > 0 : false;
      this.onShowControlsChange(this.showControls);
      return;
    }

    this.showControls = true;
    this.onShowControlsChange(true);

    this.fieldForm.patchValue(
      this.excludeOperations.map(
        (
          operation: ExcludePagesOperation,
          index: number,
        ): {
          operation: ExcludePagesOperation;
          value: string;
        } => {
          this.addControl();
          return {
            operation: operation,
            value: this.excludeUrls[index],
          };
        },
      ),
    );

    while (this.fieldForm.length > this.excludeOperations.length) {
      this.removeControl(this.excludeOperations.length);
    }
  }
}
