import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { Subscription, Observable } from 'rxjs';

import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { FormService } from '../../services/form.service';

export interface CheckboxSelectContext {
  field: string;
  label?: string;
  valueKey?: string;
  nameKey?: string | ((value: any) => string);
  translateName?: boolean;
  required?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'app-common-checkbox-select',
  templateUrl: './common-checkbox-select.component.html',
  styleUrls: ['./common-checkbox-select.component.scss'],
})
export class CommonCheckboxSelectComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public context: CheckboxSelectContext;
  @Input()
  public values: string[] | Record<string, any>[];
  @Input()
  public formValidationRequest$: Observable<void>;

  @Output()
  public valueChanged$: EventEmitter<any>;

  public selectedValues: Set<unknown>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formService: FormService,
  ) {
    this.valueChanged$ = new EventEmitter<any>();
    this.subscription = new Subscription();
    this.selectedValues = new Set<unknown>();
  }

  public get disabled(): boolean {
    return typeof this.context.disabled === 'boolean' ? this.context.disabled : false;
  }

  public get required(): boolean {
    return this.formService.isFieldRequired(this.form, this.context);
  }

  public get selectValues(): string[] | Record<string, any>[] {
    return Array.isArray(this.values) ? this.values : [];
  }

  public getOptionName(value: string | Record<string, any>): any {
    if (typeof this.context.nameKey === 'function') {
      return this.context.nameKey(value);
    }

    if (typeof this.context.nameKey === 'string') {
      return value[this.context.nameKey];
    }

    return value;
  }

  public getValue(value: unknown): unknown {
    if (typeof this.context.valueKey === 'string') {
      return value[this.context.valueKey];
    }

    return value;
  }

  public onSelectChange(): void {
    const value: unknown[] = Array.from(this.selectedValues);
    this.form.get(this.context.field).setValue(value);
    this.valueChanged$.emit(value);
  }

  public valueChanged(value: unknown | Record<string, unknown>, selected: boolean): void {
    if (selected) {
      this.selectedValues.add(this.getValue(value));
    } else {
      this.selectedValues.delete(this.getValue(value));
    }
    this.onSelectChange();
  }

  public isSelected(value: unknown): boolean {
    return this.selectedValues.has(this.getValue(value));
  }

  public ngOnInit(): void {
    if (this.formValidationRequest$) {
      this.subscription.add(
        this.formValidationRequest$.subscribe(() => {
          this.form.get(this.context.field).markAsDirty();
          this.form.get(this.context.field).markAsTouched();
          this.changeDetectorRef.detectChanges();
        }),
      );
    }

    this.selectedValues = new Set(this.form.get(this.context.field).value);
    this.subscription.add(
      this.form.get(this.context.field).valueChanges.subscribe((value: unknown[]) => {
        if (SharedCommonUtility.isNullishOrEmpty(value)) {
          return;
        }
        if (isEqual(Array.from(this.selectedValues).sort(), value.sort())) {
          return;
        }
        this.selectedValues = new Set(value);
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
