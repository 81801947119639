import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { compact, Dictionary, keyBy, map as _map } from 'lodash';

import { SecurityGroupsApi } from './rest/security-groups.api';
import { UserRestAPI } from './rest/user.api';
import {
  IDigitalPropertiesListResponse,
  IDigitalPropertyListItem,
  IDigitalPropertyWorkspace,
} from '../../../shared/interfaces/digital-property.interface';
import {
  ISecurityEntity,
  ISecurityGroup,
  ISecurityGroupsResponse,
  ISecurityGroupWithEntityNames,
  ISecurityGroupWithUserIds,
  ISecurityGroupWithUsers,
  IUpsertSecurityGroup,
} from '../../../shared/interfaces/security-group.interface';
import {
  $securityEntity,
  $securityGroup,
  SecurityEntityLevel,
  SecurityGroupOrigin,
} from '../../../shared/constants/security-group';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { $workspace } from '../../../shared/constants/workspace';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

export interface SecurityGroupsParams {
  limit?: number;
  skip?: number;
  includeStaffGroups?: boolean;
  origin?: SecurityGroupOrigin;
  entityLevel?: SecurityEntityLevel | SecurityEntityLevel[];
  skipOrganizationAdmin?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SecurityGroupService {
  public constructor(
    private securityGroupsApi: SecurityGroupsApi,
    private userApi: UserRestAPI,
  ) {}

  private populateGroupWithEntityNames(
    group: ISecurityGroup,
    dpDictionary: Dictionary<IDigitalPropertyListItem>,
    wsDictionary: Dictionary<IDigitalPropertyWorkspace>,
  ): ISecurityGroupWithEntityNames {
    // aux function that looks up all entity names at a given level (workspace-level or dp-level)
    const getEntityNames = (
      level: SecurityEntityLevel,
      dictionary: Dictionary<IDigitalPropertyListItem | IDigitalPropertyWorkspace>,
    ): string[] => {
      const result: (string | null)[] = group[$securityGroup.entities].map((entity: ISecurityEntity) => {
        const entityId: string =
          level === SecurityEntityLevel.digitalProperty
            ? entity[$securityEntity.digitalPropertyId]
            : entity[$securityEntity.workspaceId];

        if (SharedCommonUtility.isNullish(entityId)) {
          return null;
        }

        const item: IDigitalPropertyListItem | IDigitalPropertyWorkspace = dictionary[entityId];

        // if entityId references an archived digital property then it will not match
        // one of the available digital properties in the dictionary array and item will be null
        if (SharedCommonUtility.isNullish(item)) {
          return null;
        }

        return item[$digitalProperty.name];
      });

      return compact(result); // remove nulls from the list
    };

    return {
      ...group,
      digitalPropertyNames: getEntityNames(SecurityEntityLevel.digitalProperty, dpDictionary),
      workspaceNames: getEntityNames(SecurityEntityLevel.workspace, wsDictionary),
    };
  }

  public findSecurityGroups(
    limit: number,
    skip: number,
    origin?: SecurityGroupOrigin,
    entityLevel?: SecurityEntityLevel | SecurityEntityLevel[],
  ): Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> {
    return this.securityGroupsApi.findSecurityGroups(limit, skip, origin, entityLevel);
  }

  public findSecurityGroupsByTenantId(
    tenantId: string,
    limit: number,
    skip: number,
    origin?: SecurityGroupOrigin,
    entityLevel?: SecurityEntityLevel | SecurityEntityLevel[],
  ): Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> {
    return this.securityGroupsApi.findSecurityGroupsByTenantId(tenantId, limit, skip, origin, entityLevel);
  }

  public groupsWithEntityNames({
    limit,
    skip,
    includeStaffGroups,
    origin,
    entityLevel,
    skipOrganizationAdmin,
  }: SecurityGroupsParams): Observable<ISecurityGroupsResponse<ISecurityGroupWithEntityNames>> {
    const availableDigitalProperties$: Observable<IDigitalPropertiesListResponse> = this.userApi.getAvailableDigitalProperties();
    const securityGroups$: Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> =
      this.securityGroupsApi.findSecurityGroupsForUserManagement({
        limit,
        skip,
        includeStaffGroups,
        origin,
        entityLevel,
        skipOrganizationAdmin,
      });

    return combineLatest([securityGroups$, availableDigitalProperties$]).pipe(
      map(
        ([groupsResponse, activePropertiesForWorkspace]: [
          ISecurityGroupsResponse<ISecurityGroupWithUserIds>,
          IDigitalPropertiesListResponse,
        ]) => {
          const dpDictionary: Dictionary<IDigitalPropertyListItem> = keyBy(
            activePropertiesForWorkspace.items,
            $digitalProperty._id,
          );
          const workspaces: IDigitalPropertyWorkspace[] = _map(activePropertiesForWorkspace.items, $digitalProperty.workspace);
          const wsDictionary: Dictionary<IDigitalPropertyWorkspace> = keyBy(workspaces, $workspace._id);

          const groupsWithEntityNames = groupsResponse.groups.map((group: ISecurityGroup) =>
            this.populateGroupWithEntityNames(group, dpDictionary, wsDictionary),
          );
          return {
            groups: groupsWithEntityNames,
            _total: groupsResponse._total,
          };
        },
      ),
    );
  }

  public findAllSecurityGroupsByUserIdAndTenantId(
    userId: string,
    options: {
      includeStaffGroups?: boolean;
      entityLevel?: SecurityEntityLevel | SecurityEntityLevel[];
    },
  ): Observable<ISecurityGroupsResponse> {
    return this.securityGroupsApi.findAllSecurityGroupsByUserIdAndTenantId(userId, options);
  }

  public findStaffGroupsByTenantId(limit: number, skip: number): Observable<ISecurityGroupsResponse<ISecurityGroupWithUserIds>> {
    return this.securityGroupsApi.findStaffGroupsByTenantId(limit, skip);
  }

  public createSecurityGroup(group: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    return this.securityGroupsApi.createSecurityGroup(group);
  }

  public createStaffGroup(group: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    return this.securityGroupsApi.createStaffGroup(group);
  }

  public updateSecurityGroup(groupId: string, update: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    return this.securityGroupsApi.updateSecurityGroup(groupId, update);
  }

  public updateStaffGroup(groupId: string, update: IUpsertSecurityGroup): Observable<ISecurityGroup> {
    return this.securityGroupsApi.updateStaffGroup(groupId, update);
  }

  public getSecurityGroup(groupId: string): Observable<ISecurityGroupWithUsers> {
    return this.securityGroupsApi.getSecurityGroup(groupId);
  }

  public getStaffGroup(groupId: string): Observable<ISecurityGroupWithUsers> {
    return this.securityGroupsApi.getStaffGroup(groupId);
  }

  public deleteSecurityGroup({ groupId, isStaff = false }: { groupId: string; isStaff: boolean }): Observable<void> {
    return isStaff ? this.securityGroupsApi.deleteStaffGroup(groupId) : this.securityGroupsApi.deleteSecurityGroup(groupId);
  }
}
