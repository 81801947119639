import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { standardNameAndLevel } from '../../../../shared/constants/scanning';

@Component({
  selector: 'app-accessibility-conformance-level-selector',
  templateUrl: './accessibility-conformance-level-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccessibilityConformanceLevelSelectorComponent),
      multi: true,
    },
  ],
})
export class AccessibilityConformanceLevelSelectorComponent implements OnInit, ControlValueAccessor {
  public conformanceLevel: string | null;
  public accessibilityConformanceOptions: standardNameAndLevel[];

  @Input() public disabled: boolean;
  @Input() public required: boolean;
  @Input() public errorState: boolean;
  @Input() public errorMessage: string;
  @Input() public formValidationRequest$: Subject<void>;

  private _onChange: (_level: string) => void;
  private _onTouched: () => void;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.disabled = false;
    this.conformanceLevel = null;
    this._onChange = (_level: string): void => {};
    this._onTouched = (): void => {};
  }

  private buildAccessibilityLevelOptions(): void {
    this.accessibilityConformanceOptions = Object.values(standardNameAndLevel).filter(
      (standard: standardNameAndLevel) => standard !== standardNameAndLevel.essential_v10_A,
    );
  }

  public onConformanceLevelChange(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (target.value === this.conformanceLevel) {
      return;
    }

    this.conformanceLevel = target.value;
    this._onTouched();
    this._onChange(target.value);
    this.changeDetectorRef.detectChanges();
  }

  public setDisabledState(_disabled: boolean): void {
    this.disabled = _disabled;
    this.changeDetectorRef.detectChanges();
  }

  public registerOnChange(fn: (_level: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public writeValue(value: string): void {
    this.conformanceLevel = value;
    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    this.buildAccessibilityLevelOptions();
  }
}
