import { Component, Input, OnInit } from '@angular/core';
import { sum } from 'lodash';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITableConfig, ITableRow, ITableColumn } from '../../../table/ngb-table/utilities/ngb-table.interface';
import { NgbTableUtilities } from '../../../table/ngb-table/utilities/ngb-table.utilities';
import { $riskScoreLevels } from '../../../../../../shared/constants/risk-metrics';
import { TranslateService } from '../../../../translate/translate.service';
import { IRiskMetricsChartData } from '../../../../interfaces/risk-metrics-chart.interface';
import { $riskMetricsChartData } from '../../../../constants/risk-metrics-chart';
import { DashboardDataFrequency } from '../../../../../../shared/interfaces/ws-dashboard.interface';
import { RiskChartUtility } from '../../../../utility/risk-chart.utility';

enum $tableColumns {
  date = 'date',
  behind = 'behind',
  needsAttention = 'needsAttention',
  onTrack = 'onTrack',
  excelling = 'excelling',
  total = 'total',
}

@Component({
  selector: 'app-websites-apps-by-risk-management-table-modal',
  templateUrl: './websites-apps-by-risk-management-table-modal.component.html',
})
export class WebsitesAppsByRiskManagementStatusTableModalComponent implements OnInit {
  public tableData$: Observable<ITableRow<IRiskMetricsChartData>[]>;
  public tableConfig$: Observable<ITableConfig>;

  private chartData$: BehaviorSubject<IRiskMetricsChartData[]> = new BehaviorSubject<IRiskMetricsChartData[]>(null);
  private frequency$: BehaviorSubject<DashboardDataFrequency> = new BehaviorSubject<DashboardDataFrequency>(
    DashboardDataFrequency.weekly,
  );

  @Input() set data(data: IRiskMetricsChartData[]) {
    if (data) {
      this.chartData$.next(data);
    }
  }

  @Input() set frequency(freq: DashboardDataFrequency) {
    this.frequency$.next(freq);
  }
  constructor(
    private translateService: TranslateService,
    public modal: NgbActiveModal,
  ) {}

  private createTableConfig(frequency: DashboardDataFrequency): ITableConfig {
    const columns: Record<string, ITableColumn> = {
      [$tableColumns.date]: {
        translationKey: RiskChartUtility.getDescriptorKeyForFrequency(frequency),
        styles: {
          width: '30%',
          maxWidth: '30%',
        },
      },
      [$tableColumns.behind]: {
        translationKey: 'behind',
        styles: {
          width: '15%',
          maxWidth: '15%',
        },
      },
      [$tableColumns.needsAttention]: {
        translationKey: 'needs_attention',
        styles: {
          width: '15%',
          maxWidth: '15%',
        },
      },
      [$tableColumns.onTrack]: {
        translationKey: 'on_track',
        styles: {
          width: '15%',
          maxWidth: '15%',
        },
      },
      [$tableColumns.excelling]: {
        translationKey: 'excelling',
        styles: {
          width: '15%',
          maxWidth: '15%',
        },
      },
      [$tableColumns.total]: {
        translationKey: 'total',
        styles: {
          width: '10%',
          maxWidth: '10%',
        },
      },
    };

    return {
      columns: columns,
      caption: this.translateService.instant('websites_apps_by_risk_management_status_table'),
    };
  }

  private buildTableData(
    snapshots: IRiskMetricsChartData[],
    frequency: DashboardDataFrequency,
  ): ITableRow<IRiskMetricsChartData>[] {
    return [...snapshots].reverse().map((snapshot: IRiskMetricsChartData) => ({
      originalData: snapshot,
      data: {
        [$tableColumns.date]: NgbTableUtilities.textCell({
          text: RiskChartUtility.formatDateForFrequency(snapshot[$riskMetricsChartData.computedAt], frequency, true),
        }),
        [$tableColumns.behind]: NgbTableUtilities.textCell({
          text: snapshot[$riskMetricsChartData.dpCountsByRiskLevel][$riskScoreLevels.behind].toLocaleString(),
        }),
        [$tableColumns.needsAttention]: NgbTableUtilities.textCell({
          text: snapshot[$riskMetricsChartData.dpCountsByRiskLevel][$riskScoreLevels.needsAttention].toLocaleString(),
        }),
        [$tableColumns.onTrack]: NgbTableUtilities.textCell({
          text: snapshot[$riskMetricsChartData.dpCountsByRiskLevel][$riskScoreLevels.onTrack].toLocaleString(),
        }),
        [$tableColumns.excelling]: NgbTableUtilities.textCell({
          text: snapshot[$riskMetricsChartData.dpCountsByRiskLevel][$riskScoreLevels.excelling].toLocaleString(),
        }),
        [$tableColumns.total]: NgbTableUtilities.textCell({
          text: sum(Object.values(snapshot[$riskMetricsChartData.dpCountsByRiskLevel])).toLocaleString(),
        }),
      },
    }));
  }

  public ngOnInit(): void {
    this.tableConfig$ = this.frequency$.pipe(
      map((freq: DashboardDataFrequency) => {
        return this.createTableConfig(freq);
      }),
    );
    this.tableData$ = combineLatest([this.chartData$, this.frequency$]).pipe(
      map(([data, frequency]: [IRiskMetricsChartData[], DashboardDataFrequency]) => {
        return this.buildTableData(data, frequency);
      }),
    );
  }
}
