export enum $issueIntegrationConnection {
  _id = '_id',
  status = 'status',
  ownerId = 'ownerId',
  owner = 'owner',
  workspace = 'workspace',
  tenantId = 'tenantId',
  tenant = 'tenant',
  instance = 'instance',
  authorizeUrl = 'authorizeUrl',
  createdAt = 'createdAt',
  lastReportedError = 'lastReportedError',
  statusMapping = 'statusMapping',
  statusMappingToIssueTracking = 'statusMappingToIssueTracking',
  issueIntegrationIssueStatus = 'issueIntegrationIssueStatus',
  issueIntegrationIssueStatusId = 'issueIntegrationIssueStatusId',
  eapTaskStatus = 'eapTaskStatus',
  integrationType = 'integrationType',
  linkedProjects = 'linkedProjects',
  personalAccessToken = 'personalAccessToken',
  taskTransitionRulesConfigurationType = 'taskTransitionRulesConfigurationType',
  issueIntegrationProjectId = 'issueIntegrationProjectId',
  issueIntegrationIssueTypeId = 'issueIntegrationIssueTypeId',
  tenantWorkspaceIds = 'tenantWorkspaceIds',
  tenantWorkspaces = 'tenantWorkspaces',
  oAuth2 = 'oAuth2',
  webhooksSupported = 'webhooksSupported',
}

export enum $statusMapping {
  _id = '_id',
  connectionId = 'connectionId',
  projectId = 'projectId',
  eapProjectId = 'eapProjectId',
  statusMapping = 'statusMapping',
  statusMappingToIssueTracking = 'statusMappingToIssueTracking',
  issueIntegrationIssueStatus = 'issueIntegrationIssueStatus',
  issueIntegrationIssueStatusId = 'issueIntegrationIssueStatusId',
  eapTaskStatus = 'eapTaskStatus',
  integrationType = 'integrationType',
  personalAccessToken = 'personalAccessToken',
  taskTransitionRulesConfigurationType = 'taskTransitionRulesConfigurationType',
  issueIntegrationProjectId = 'issueIntegrationProjectId',
  issueIntegrationIssueTypeId = 'issueIntegrationIssueTypeId',
}

export enum $issueIntegrationProject {
  uuid = 'uuid',
  name = 'name',
  key = 'key',
  self = 'self',
  projectTypeKey = 'projectTypeKey',
  id = 'id',
}

export enum $createIssueIntegrationRequest {
  taskId = 'taskId',
  name = 'name',
  projectId = 'projectId',
  issueTypeId = 'issueTypeId',
  description = 'description',
  attachments = 'attachments',
  attachmentFileOriginNames = 'attachmentFileOriginNames',
  comments = 'comments',
  projectFieldsConfig = 'projectFieldsConfig',
}

export enum $linkIssueIntegrationRequest {
  taskId = 'taskId',
  issueKey = 'issueKey',
}

export enum connectionStatus {
  authorized = 'authorized',
  unauthorized = 'unauthorized',
  interrupted = 'interrupted',
}

export enum pushToIssueTrackingForm {
  issueTrackingIssueType = 'issueTrackingIssueType',
  issueId = 'issueId',
}

export enum $issueIntegrationConfiguredFields {
  _id = '_id',
  connectionId = 'connectionId',
  projectId = 'projectId',
  eapProjectId = 'eapProjectId',
  issueType = 'issueType',
  fieldsConfig = 'fieldsConfig',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
}
