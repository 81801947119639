import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AclSecurityAdapter, AclService } from './acl.service';
import { UserService } from './user.service';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';

@Injectable()
export class UserAclService {
  constructor(
    private aclService: AclService,
    private userService: UserService,
  ) {}

  public createCheckAccessForCurrentUser(): Observable<AclSecurityAdapter> {
    return this.userService.userDataChanged$.pipe(
      filter((profile: IUserServerResponse): boolean => profile !== null),
      map((user: IUserServerResponse): AclSecurityAdapter => new AclSecurityAdapter(user, this.aclService)),
    );
  }
}
