import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DsButtonVariants, DsCheckboxGroupComponent } from '@levelaccess/design-system';
import { Subscription } from 'rxjs';

import { UserService } from '../../../../services/user.service';
import { userPreferenceScope, userPreferenceScopeParam } from '../../../../../../shared/constants/user-preferences';
import { TranslateService } from '../../../../translate/translate.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationPosition } from '../../../../models/notification.model';
import { BannerType } from '../../../../components/users/edit-user/edit-permissions-banner/edit-permissions-banner.component';
import { PLATFORM_ACTIONS, PlatformAction, PlatformActionItem } from '../../../../../../shared/constants/platform-action';
import { TenantPackageService } from '../../../../services/tenant-package.service';
import { PackageNames } from '../../../../../../shared/constants/packaging';

@Component({
  selector: 'app-signup-platform-usage',
  templateUrl: './signup-platform-usage.component.html',
})
export class SignupPlatformUsageComponent implements OnDestroy {
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly BannerType: typeof BannerType = BannerType;

  private subscription: Subscription = new Subscription();
  public showSignupSuccessBanner: boolean = true;

  @Input() public email: string;

  @Output() public onSuccess: EventEmitter<void> = new EventEmitter();

  @ViewChild('platFormActionCheckboxGroup')
  public platFormActionCheckboxGroup: DsCheckboxGroupComponent;

  public platformActions: PlatformActionItem[] = [];

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private tenantPackageService: TenantPackageService,
  ) {
    // This can be used in the future to exclude actions based on tenant package
    const packageNameToExcludedActions: Partial<Record<Exclude<PackageNames, PackageNames.Audit>, PlatformAction[]>> = {};

    this.platformActions = PLATFORM_ACTIONS.filter((action: PlatformActionItem) => {
      const excludedActions: PlatformAction[] | undefined = packageNameToExcludedActions[this.tenantPackageService.tenantPackage];
      if (excludedActions === undefined) {
        return true;
      }
      return !excludedActions.includes(action.value);
    });
  }

  public closeSignupSuccessBanner(): void {
    this.showSignupSuccessBanner = false;
  }

  public onSubmit(): void {
    this.subscription.add(
      this.userService
        .updateUserPreferences(userPreferenceScope.platformUsage, {
          [userPreferenceScopeParam.platformActions]: this.platFormActionCheckboxGroup.value,
        })
        .subscribe({
          next: () => this.onUserPreferenceUpdateSuccess(),
          error: (response: HttpErrorResponse) => this.onUserPreferenceUpdateFailed(response),
        }),
    );
  }

  private onUserPreferenceUpdateSuccess(): void {
    this.onSuccess.next();
  }

  private onUserPreferenceUpdateFailed(response: HttpErrorResponse): void {
    const errorMessage: string = this.translateService.instant('sign_up_platform_usage_save_error');
    this.notificationService.error(errorMessage, NotificationPosition.Toast);

    console.error(`[${SignupPlatformUsageComponent.name}.onUserPreferenceUpdateFailed]: ${errorMessage}`, response);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
