import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status',
  template: `<span *ngIf="value" [ngClass]="'status-' + value" class="status">{{ 'status_' + value | translate }}</span>`,
})
export class StatusComponent {
  @Input()
  public value: string;
}
