<div class="scan-actions">
  <button
    *ngIf="canRerunScan()"
    type="button"
    class="btn btn-secondary btn-sm"
    [attr.aria-label]="'rerun_scanning_title' | translate"
    (click)="rerunScan()"
  >
    <svg viewBox="0 0 8 8" class="icon"><use xlink:href="#reload"></use></svg>{{ 'rerun_scanning' | translate }}
  </button>
  <button
    *ngIf="(canRemoveScan$ | async) && scan.status !== scanStatus.removed"
    type="button"
    class="btn btn-danger btn-sm"
    [attr.aria-label]="'remove_scanning_title' | translate"
    (click)="removeScan()"
  >
    <svg viewBox="0 0 8 8" class="icon"><use xlink:href="#trash"></use></svg>{{ 'remove_scanning' | translate }}
  </button>
  <a *ngIf="isScanCompleted()" [routerLink]="['/', Api.scans, Api.scan_results, scan._id]" class="btn btn-primary btn-sm">
    <svg viewBox="0 0 8 8" class="icon"><use xlink:href="#document"></use></svg>{{ 'view_scan_results' | translate }}
  </a>
</div>
