import { Injectable } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { AppConfigService } from './app-config.service';

const SCRIPT_ID: string = 'pendo-script';

export interface IVisitor {
  id: string;
  [key: string]: string;
}

export interface IAccount {
  id: string;
  [key: string]: string;
}

interface IPendo {
  initialize: (settings: { visitor: IVisitor; account: IAccount }) => void;
}

interface IWindowWithPendo {
  pendo: IPendo;
}

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  private pendoScriptIsLoaded$: Observable<any>;
  private subscriptions: Subscription;
  constructor(private appConfigService: AppConfigService) {
    this.subscriptions = new Subscription();
  }

  private get apiKey(): string {
    return this.appConfigService.getPendoApiKey();
  }

  private isPendoConfigured(): boolean {
    return typeof this.apiKey === 'string' && this.apiKey.length > 0;
  }

  // public for testing purposes
  public getPendoScript(): HTMLScriptElement {
    const script: HTMLScriptElement = document.createElement('script');
    script.id = SCRIPT_ID;
    script.innerHTML = `(function (apiKey) {
    (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
        for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
                o[m] =
                    o[m] ||
                    function () {
                        o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                    };
            })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
})("${this.apiKey}");`;
    script.async = true;
    return script;
  }

  // this doesn't mean the script is completely loaded
  // it just means, the script is injected into the DOM
  private get pendoScriptIsInjected(): boolean {
    return document.querySelector(`#${SCRIPT_ID}`) !== null;
  }

  private get pendo(): IPendo {
    return (window as unknown as IWindowWithPendo).pendo;
  }

  private initializePendo(): void {
    if (this.isPendoConfigured() === false || this.pendoScriptIsInjected) {
      return;
    }
    this.pendoScriptIsLoaded$ = interval(1000).pipe(
      filter(() => SharedCommonUtility.notNullish(this.pendo)),
      take(1),
    );
    document.head.appendChild(this.getPendoScript());
  }

  public registerUser(sessionData: Record<string, unknown>): void {
    if (this.isPendoConfigured() === false || SharedCommonUtility.isNullish(sessionData)) {
      return;
    }
    if (SharedCommonUtility.isNullish(this.pendoScriptIsLoaded$)) {
      this.initializePendo();
    }
    this.subscriptions.add(
      this.pendoScriptIsLoaded$.subscribe((): void => {
        this.pendo.initialize({
          visitor: {
            id: String(sessionData.userId),
            ...sessionData,
          },
          account: {
            id: String(sessionData.TID),
          },
        });
      }),
    );
  }
}
