import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonUtility } from '../../utility/common.utility';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchboxComponent),
      multi: true,
    },
  ],
})
export class SearchboxComponent implements ControlValueAccessor {
  private static searchBoxPrefix: string = 'search-box';

  @ViewChild('textbox', { static: false })
  private textbox: ElementRef;

  public onChange: Function = (_: string) => {};
  public value: string;
  public componentID: string;

  @Input() public label: string;
  @Input() public placeholder: string = '';
  @Input() public inputId: string;

  @Input() public stackSearchButtonOnMobile: boolean;

  constructor(private cdRef: ChangeDetectorRef) {
    this.componentID = `${SearchboxComponent.searchBoxPrefix}-${CommonUtility.createUniqueDOMId()}`;
  }

  public get controlId(): string {
    if (this.inputId) {
      return this.inputId;
    }
    return this.componentID;
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public writeValue(val: string): void {
    this.value = val;
    this.cdRef.markForCheck();
  }

  public clear(): void {
    this.value = null;
    this.textbox.nativeElement.focus();
    this.propagateChanges();
  }

  public propagateChanges(event?: KeyboardEvent): void {
    event?.preventDefault();
    this.onChange(this.value);
  }
}
