import { Component, Input, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';

import { TestAutomationService, TestAutomationTools } from '../../../../../services/test-automation.service';
import { NotificationPosition } from '../../../../../models/notification.model';
import { NotificationService } from '../../../../../services/notification.service';
import { TranslateService } from '../../../../../translate/translate.service';
import { LoadErrorHandler } from '../../../../../services/load-error-handler';
import { DetectUtility } from '../../../../../utility/detect.utility';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';

export enum DownloadType {
  button,
  dropdown,
  internal_link,
  external_link,
  install,
}

export interface ImageDetails {
  src: string;
  width: number;
}

export interface option {
  label: string;
  tool: TestAutomationTools;
  action?: (...args: any[]) => any;
}

@Component({
  selector: 'app-tool-template',
  templateUrl: './tool-template.html',
  styles: ['h3 { margin-bottom: 2.7rem; margin-top: 1.75rem;}'],
})
export class ToolTemplateComponent implements OnDestroy {
  @Input()
  public downloadType: DownloadType;
  public DownloadType: typeof DownloadType = DownloadType;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public Icons: typeof Icons = Icons;

  @Input()
  public image: ImageDetails;

  @Input()
  public dropdownOption?: option[];

  @Input()
  public tool?: TestAutomationTools;

  @Input()
  public label: string;

  @Input()
  public buttonName: string;

  @Input()
  public buttonVariant: DsButtonVariants = DsButtonVariants.primary;

  @Input()
  public disabled: boolean;

  @Input()
  public url?: string;

  private subscriptions: Subscription;
  constructor(
    private testAutomationService: TestAutomationService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private errorHandler: LoadErrorHandler,
  ) {
    this.subscriptions = new Subscription();
  }

  private downloadSuccess(): void {
    this.notificationService.success(
      this.translateService.instant('test_automation_tool_download_success'),
      NotificationPosition.Toast,
    );
  }

  private downloadError(error: unknown): void {
    this.errorHandler.handleError('test_automation_tool_download_error', error as HttpErrorResponse, ToolTemplateComponent.name);
  }

  private openLink(href: string): void {
    location.href = href;
  }

  public handleDropdownClick(dropdownOption: option): void {
    if (SharedCommonUtility.notNullish(dropdownOption.action)) {
      dropdownOption.action();
      return;
    }

    this.download(dropdownOption.tool);
  }

  public download(tool: TestAutomationTools): void {
    this.subscriptions.add(
      this.testAutomationService
        .download(tool)
        .pipe(take(1))
        .subscribe({
          next: this.downloadSuccess.bind(this),
          error: this.downloadError.bind(this),
        }),
    );
  }

  public install(tool: TestAutomationTools): void {
    if (tool !== TestAutomationTools.extension_firefox || DetectUtility.getBrowserName() !== 'Firefox') {
      return;
    }

    this.subscriptions.add(
      this.testAutomationService
        .getToolUrl(tool)
        .pipe(take(1))
        .subscribe({
          next: this.openLink.bind(this),
          error: this.downloadError.bind(this),
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
