import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { cloneDeep } from 'lodash';

import { ICheckboxTreeConfig, ICheckboxTreeNode } from '../../interfaces/checkbox-tree.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

let counter: number = 1;

@Component({
  selector: 'app-common-checkbox-tree',
  templateUrl: './common-checkbox-tree.component.html',
})
export class CommonCheckboxTreeComponent<T> {
  @HostBinding('attr.id') id: string;
  @HostBinding('attr.role') role: string = 'tree';

  private _nodes: ICheckboxTreeNode<T>[];

  @Input()
  public config: ICheckboxTreeConfig;

  @Input()
  public emptyTreeNode: ICheckboxTreeNode<T>;

  @Output()
  public treeChanged: EventEmitter<T[]>;

  constructor() {
    this._nodes = [];

    this.id = `checkbox-tree${counter++}`;
    this.treeChanged = new EventEmitter<T[]>();
  }

  private autoCorrectTreeNode(node: ICheckboxTreeNode<T>): boolean {
    node.children.forEach((child: ICheckboxTreeNode<T>): void => {
      if (node.checked) {
        child.checked = true;
      }

      const childState: boolean = this.autoCorrectTreeNode(child);
      if (node.checked) {
        return;
      }

      if (childState || SharedCommonUtility.isNullish(childState)) {
        node.checked = undefined;
      }
    });

    return node.checked;
  }

  public get nodes(): ICheckboxTreeNode<T>[] {
    return this._nodes;
  }

  @Input()
  public set nodes(nodes: ICheckboxTreeNode<T>[]) {
    const clonedNodes: ICheckboxTreeNode<T>[] = cloneDeep(nodes);
    clonedNodes.forEach(this.autoCorrectTreeNode.bind(this));
    this._nodes = clonedNodes;
  }

  private flatValues(tree: ICheckboxTreeNode<T>): T[] {
    const result: T[] = [];

    if (tree.checked) {
      result.push(tree.value);
    }

    if (!this.config.returnOptimizedHierarchy || !tree.checked) {
      const childValues: T[] = tree.children.flatMap(this.flatValues.bind(this));
      result.push(...childValues);
    }

    return result;
  }

  public onTreeChanged(): void {
    const values: T[] = this.nodes.flatMap(this.flatValues.bind(this));
    this.treeChanged.emit(values);
  }
}
