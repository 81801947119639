<form [formGroup]="form">
  <h4>{{ stepNumber + 1 }} - {{ 'scan_settings' | translate }}</h4>

  <div class="row margin-bottom">
    <div class="col-3">
      <label class="d-block"
        >{{ 'testing_tool' | translate }}
        <app-icon
          class="margin-bottom-sm tooltip-opener"
          appTooltip="{{ 'default_dp_tool' | translate }}"
          container="body"
          [attr.aria-label]="'default_dp_tool' | translate"
          icon="question-mark-tooltip"
        >
        </app-icon>
      </label>
      <span class="d-block">{{ 'scan_results_tool_option_' + testingTool | translate }}</span>
    </div>
    <div class="col">
      <app-common-select
        [formValidationRequest$]="formValidationRequest$"
        [form]="form"
        [values]="conformanceLevelSelectOptions"
        [context]="{
          label: 'digital_default_conformance_level_label',
          field: $digitalPropertyMonitoring.conformanceLevel,
          nameKey: 'key',
          valueKey: 'value',
          placeholderValue: 'select_an_option',
        }"
        class="d-block margin-bottom"
        
      ></app-common-select>
    </div>
    <div class="col">
      <app-viewport-select
        [formValidationRequest$]="formValidationRequest$"
        [formControlName]="$digitalPropertyMonitoring.viewport"
      ></app-viewport-select>
    </div>
  </div>
</form>
