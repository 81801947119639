<ds-input
  id="accessibility-conformance-level"
  [label]="'accessibility_conformance_level' | translate"
  [required]="required"
  [errorState]="errorState"
  [errorMessage]="errorMessage"
>
  <select
    ds-input-field
    class="mw-100"
    [disabled]="disabled"
    id="accessibility-conformance-level-selector"
    (change)="onConformanceLevelChange($event)"
  >
    <option *ngFor="let level of accessibilityConformanceOptions" [value]="level" [selected]="level === conformanceLevel">
      {{ level | translate }}
    </option>
  </select>
</ds-input>
