import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DsButtonVariants, DsInputStates, DsTooltipPosition, Icons } from '@levelaccess/design-system';

import { NavLink } from '../compound-second-level-navbar/compound-second-level-navbar.component';
import { NavService } from '../../../services/navigation/nav.service';
import { INavMenuItem, NavigationItem } from '../../../services/navigation/nav-structure';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { $digitalProperty } from '../../../../../shared/constants/digital-properties';
import { IDigitalPropertyListItem } from '../../../../../shared/interfaces/digital-property.interface';
import { $workspace } from '../../../../../shared/constants/workspace';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'compound-third-level-navbar',
  templateUrl: './compound-third-level-navbar.component.html',
  styleUrls: ['./compound-third-level-navbar.component.scss'],
})
export class CompoundThirdLevelNavbarComponent implements OnInit, OnDestroy, AfterViewChecked {
  private readonly MOBILE_NAV_ITEMS_SHOWING_ACTIVE_WORKSPACE_NAME: NavigationItem[] = [
    NavigationItem.workspace_dashboard,
    NavigationItem.pdf,
    NavigationItem.projects_and_tasks,
    NavigationItem.files,
  ];
  private readonly MOBILE_NAV_ITEMS_SHOWING_ACTIVE_DP_NAME: NavigationItem[] = [NavigationItem.websites_and_apps];
  private _subscriptions: Subscription;
  private _menusWrapperResizeObserver: ResizeObserver;
  private _navbarMenuItemsWrapper: ElementRef;
  private activeWorkspaceName$: Observable<string>;
  private activeDigitalPropertyName$: Observable<string>;
  public Icons: typeof Icons = Icons;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public DsInputStates: typeof DsInputStates = DsInputStates;
  public $digitalProperty: typeof $digitalProperty = $digitalProperty;
  public navbarItems: NavLink[] = [];
  public childrenRoutes$: Observable<NavLink[]>;
  public gearRoutes$: Observable<NavLink[]>;
  public digitalProperties$: Observable<IDigitalPropertyListItem[]>;
  public readonly DsTooltipPosition: typeof DsTooltipPosition = DsTooltipPosition;
  public mobileNavItemInTheActiveSubtree$: Observable<boolean>;
  public mobileNavbarLabel$: Observable<string>;

  @Input() public showMobileNavVersion: boolean;

  @ViewChildren('invisibleMenuItems', { read: ElementRef }) invisibleMenuItems: QueryList<ElementRef>;
  @ViewChild('invisibleMoreMenu', { read: ElementRef }) invisibleMoreMenu: ElementRef;
  @ViewChild('invisibleSpaceBetweenItems', { read: ElementRef }) invisibleSpaceBetweenItems: ElementRef;
  @ViewChild('webAppSelect', { static: false }) webAppSelect: ElementRef;
  @ViewChild('webAppSelectPortfolioOption', { static: false }) webAppSelectPortfolioOption: ElementRef;

  @ViewChild('navbarMenuItemsWrapper') set navbarMenuItemsWrapper(value: ElementRef | undefined) {
    if (SharedCommonUtility.notNullish(value)) {
      this._navbarMenuItemsWrapper = value;
      this._menusWrapperResizeObserver.observe(this._navbarMenuItemsWrapper.nativeElement);
    }
  }

  constructor(
    private navService: NavService,
    private zone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
  ) {
    this._subscriptions = new Subscription();
    this._menusWrapperResizeObserver = new ResizeObserver((): void => this.zone.run((): void => {}));
  }

  private _handleParentMenuItem(parentMenuItem?: INavMenuItem): NavLink[] {
    const thirdLevelMenuItems: INavMenuItem[] = parentMenuItem?.children ?? [];

    return thirdLevelMenuItems
      .filter((thirdLevelMenuItem: INavMenuItem): boolean => {
        return thirdLevelMenuItem.isAvailable && !thirdLevelMenuItem.displayedOutsideOfNavbar && !thirdLevelMenuItem.mobileOnly;
      })
      .map(
        (thirdLevelMenuItem: INavMenuItem): NavLink => ({
          routerLink: thirdLevelMenuItem.routerLink,
          externalLink: thirdLevelMenuItem.externalLink,
          label: thirdLevelMenuItem.label,
          isActive: thirdLevelMenuItem.isActive,
          hasChildren: thirdLevelMenuItem.children?.length > 0,
          isHiddenOnCurrentScreenSize: undefined,
          isHidden: thirdLevelMenuItem.isHidden,
          id: thirdLevelMenuItem.id,
          iconName: thirdLevelMenuItem.iconName,
        }),
      );
  }

  private _handleChildrenRoutesChanges(childrenRoutes: NavLink[]): void {
    this.navbarItems = childrenRoutes.filter((item: NavLink): boolean => item.id !== NavigationItem.websites_and_apps_settings);
  }

  /**
   * This method is to determine the navbar menu items that need to be put inside More dropdown menu.
   * Whenever childrenRoutes$ is updated, render them into navbar(which is outside visible area)
   * and calculate remaining space by iterating all rendered menu items.
   *
   * If there is not enough space, mark the navbar menu item to be put in the More dropdown menu via
   * the "isHiddenOnCurrentScreenSize" property.
   *
   *
   * @private
   */
  private _adjustVisibleItems(): void {
    if (SharedCommonUtility.isNullish(this._navbarMenuItemsWrapper) || SharedCommonUtility.isNullish(this.invisibleMoreMenu)) {
      return;
    }

    let runChangesDetection: boolean = false;
    const dropdownMenuButtonWidth: number = this.invisibleMoreMenu.nativeElement.offsetWidth;
    const spaceBetweenItems: number = this.invisibleSpaceBetweenItems.nativeElement.offsetWidth;
    const navbarComputedStyle: CSSStyleDeclaration = getComputedStyle(this._navbarMenuItemsWrapper.nativeElement);
    let remainingSpace: number =
      this._navbarMenuItemsWrapper.nativeElement.clientWidth -
      parseFloat(navbarComputedStyle.paddingLeft) -
      parseFloat(navbarComputedStyle.paddingRight);

    for (let i: number = 0; i < this.navbarItems.length; i++) {
      const isTheLastItem: boolean = i === this.navbarItems.length - 1;
      const item: NavLink = this.navbarItems[i];
      const itemWidth: number = this.invisibleMenuItems.get(i).nativeElement.offsetWidth;

      const isHiddenOnCurrentScreenSize: boolean = isTheLastItem
        ? remainingSpace < itemWidth
        : remainingSpace < itemWidth + dropdownMenuButtonWidth + spaceBetweenItems;

      remainingSpace = isHiddenOnCurrentScreenSize ? 0 : remainingSpace - itemWidth - spaceBetweenItems;

      if (item.isHiddenOnCurrentScreenSize !== isHiddenOnCurrentScreenSize) {
        runChangesDetection = true;
        item.isHiddenOnCurrentScreenSize = isHiddenOnCurrentScreenSize;
      }
    }

    if (runChangesDetection) {
      this.changeDetectorRef.detectChanges();
    }
  }

  public get menuNavbarItems(): NavLink[] {
    return this.navbarItems.filter(
      (navbarItem: NavLink): boolean => navbarItem.isHiddenOnCurrentScreenSize && !navbarItem.isHidden,
    );
  }

  public get visibleNavbarItems(): NavLink[] {
    return this.navbarItems.filter(
      (navbarItem: NavLink): boolean => !navbarItem.isHiddenOnCurrentScreenSize && !navbarItem.isHidden,
    );
  }

  private _hideOffscreenElements(): void {
    this.invisibleMenuItems?.forEach((menuItem: ElementRef): void => {
      const navbarLink: HTMLLinkElement = menuItem.nativeElement.querySelector('div.compound-navbar-link > *');
      if (SharedCommonUtility.notNullish(navbarLink)) {
        navbarLink.tabIndex = -1;
      }
    });

    const dropdown: HTMLButtonElement = this.invisibleMoreMenu?.nativeElement.querySelector('button.ds-btn');
    if (SharedCommonUtility.notNullish(dropdown)) {
      dropdown.tabIndex = -1;
    }
  }

  public get activeNavItem$(): Observable<NavLink | undefined> {
    return combineLatest([this.childrenRoutes$, this.gearRoutes$]).pipe(
      map(([childrenRoutes, gearRoutes]: [NavLink[], NavLink[]]): NavLink | undefined => {
        const activatedGearRoute: NavLink | undefined = gearRoutes.find((navLink: NavLink): boolean => navLink.isActive);
        return activatedGearRoute || childrenRoutes.find((navLink: NavLink): boolean => navLink.isActive);
      }),
    );
  }

  ngOnInit(): void {
    this.childrenRoutes$ = this.navService.activeSecondLevelMenuItem$().pipe(map(this._handleParentMenuItem));
    this.gearRoutes$ = this.navService.activeSecondLevelMenuItem$().pipe(
      map(
        (menuItem: INavMenuItem): INavMenuItem =>
          (menuItem?.children || []).find(
            (childNavItem: INavMenuItem): boolean =>
              childNavItem.id === NavigationItem.websites_and_apps_settings && childNavItem.isAvailable,
          ),
      ),
      map(this._handleParentMenuItem),
    );
    this.mobileNavItemInTheActiveSubtree$ = this.navService
      .getNavItemInTheActiveSubtree$([
        ...this.MOBILE_NAV_ITEMS_SHOWING_ACTIVE_WORKSPACE_NAME,
        ...this.MOBILE_NAV_ITEMS_SHOWING_ACTIVE_DP_NAME,
      ])
      .pipe(map(SharedCommonUtility.notNullish));
    this.activeWorkspaceName$ = this.userService.currentDigitalProperty$.pipe(
      map((dp: IDigitalPropertyListItem) => dp[$digitalProperty.workspace][$workspace.name]),
    );
    this.activeDigitalPropertyName$ = this.userService.currentDigitalProperty$.pipe(
      map((dp: IDigitalPropertyListItem) => dp[$digitalProperty.name]),
    );
    this.mobileNavbarLabel$ = this.navService
      .getNavItemInTheActiveSubtree$(this.MOBILE_NAV_ITEMS_SHOWING_ACTIVE_DP_NAME)
      .pipe(
        switchMap(
          (navItem: INavMenuItem): Observable<string> =>
            SharedCommonUtility.notNullish(navItem) ? this.activeDigitalPropertyName$ : this.activeWorkspaceName$,
        ),
      );
    this._subscriptions.add(this.childrenRoutes$.subscribe(this._handleChildrenRoutesChanges.bind(this)));
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();

    if (
      SharedCommonUtility.notNullish(this._menusWrapperResizeObserver) &&
      SharedCommonUtility.notNullish(this._navbarMenuItemsWrapper?.nativeElement)
    ) {
      this._menusWrapperResizeObserver.unobserve(this._navbarMenuItemsWrapper.nativeElement);
    }
  }

  ngAfterViewChecked(): void {
    this._hideOffscreenElements();
    this._adjustVisibleItems();
  }
}
