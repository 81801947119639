<div
  *ngIf="isVisible"
  class="alert alert-dismissable alert-{{ type }} py-3"
  role="alert"
  id="{{ componentId }}"
  [attr.aria-labelledby]="componentId + '-header'"
  tabindex="-1"
>
  <svg aria-hidden="true">
    <use xlink:href="#alert"></use>
  </svg>

  <h3 [ngClass]="header.length === 0 ? 'visuallyhidden' : ''" id="{{ componentId }}-header" class="mb-2">
    <ng-container *ngIf="header.length > 0">{{ header }}</ng-container>
    <ng-container *ngIf="header.length === 0">{{ 'error_header' | translate }}</ng-container>
  </h3>

  <p class="alert-message">{{ message }}</p>

  <ng-container *ngIf="technicalDetails.length > 0">
    <div class="technical-details">
      <ul id="{{ componentId }}-errorDetailsList" class="mt-1">
        <li *ngFor="let technicalMessage of technicalDetails" [innerHTML]="technicalMessage"></li>
      </ul>
      <div class="row" *ngIf="pageScreenshot !== null">
        <div class="col-md-12">
          <h4>{{ 'error_page_screenshot' | translate }}</h4>
          <img [attr.src]="pageScreenshot.screenshot" alt="{{ 'scan_error_page_screenshot' | translate }}" />
        </div>
        <div class="col-md-12">
          <h4>{{ 'error_page_header' | translate }}</h4>
          <pre>{{ getHeaderData() }}</pre>
        </div>
      </div>
    </div>
  </ng-container>

  <button type="button" class="close-alert" (click)="remove()">
    <span class="visuallyhidden">{{ 'close_alert' | translate }}</span>
    &times;
  </button>
</div>
