import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { $clientLog, ITrackingConsoleRequest } from '../../../../shared/interfaces/tracking-console.interface';
import { RestService } from '../rest.service';
import { UserService, ICurrentUserData } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingConsoleService {
  private nativeConsole: any;
  private originalConsoleReferences: any;

  constructor(
    private restService: RestService,
    private userService: UserService,
    private router: Router,
  ) {
    this.originalConsoleReferences = {};
  }

  private sendData(report: ITrackingConsoleRequest): boolean {
    return this.restService.sendTrackingConsole(report);
  }

  public createConsoleWrapper(): any {
    this.nativeConsole = window.console;

    const createWrapper = (consoleType: string): void => {
      this.originalConsoleReferences[consoleType] = window.console[consoleType];

      window.console[consoleType] = (...args: any[]): void => {
        const fnArgs: any[] = Array.prototype.slice.call(args, 0);
        let argsData: string;

        try {
          argsData = JSON.stringify(fnArgs);
        } catch (e) {
          argsData = JSON.stringify(`[error] while trying to use JSON stringify with console. Error: ${e}`);
        }

        const userData: ICurrentUserData = this.userService.getUserDataForLogging();

        const consoleData: ITrackingConsoleRequest = {
          [$clientLog.data]: argsData,
          [$clientLog.type]: consoleType,
          [$clientLog.userId]: this.userService.getStoredUserId(),
          [$clientLog.url]: this.router.url,
          ...userData,
        };

        for (const arg of args) {
          if (arg instanceof Error) {
            consoleData[$clientLog.stack] = arg.stack;
            break;
          }
        }

        const consoleTrackingSendBeacon: boolean = this.sendData(consoleData);

        if (consoleTrackingSendBeacon === false) {
          args.push('[TrackingConsoleService.handleConsoleLog.consoleTrackingSendBeacon] failed to send tracking beacon');
        }

        this.originalConsoleReferences[consoleType].apply(this.nativeConsole, args);
      };
    };

    // Wrap only console.error() calls
    createWrapper('error');
  }
}
