import { Injectable } from '@angular/core';
import { mergeMap, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'lodash';

import { ICurrentSelectedProperty } from './user.service';
import { IAuditFinding } from '../../../shared/interfaces/audit-finding.interface';
import {
  IDashboardManualEvaluationsBySeverity,
  TopWcagKeyNumPair,
} from '../../../shared/interfaces/dashboard-scan-result.interface';
import { WorkspacesRestAPI } from './rest/workspaces.api';
import {
  IGetManualEvaluationsResponse,
  IManualAuditIssueServerResponse,
  IManualEvaluationQuery,
  IPopulatedManualAuditIssueServerResponse,
  IManualEvaluation,
} from '../../../shared/interfaces/manual-audit.interface';
import { IUploadClient } from '../../../shared/interfaces/uploads.interface';
import { UserPropertyService } from './user-property.service';
import { $formatColumns } from '../../../shared/constants/manual-audit-issues';
import {
  ICreateTaskDescription,
  JiraMarkdownUtils,
} from '../components/markdown-editor/markdown-formatter/markdown-jira.utility';
import { $auditFinding, AuditFindingDisabilityAffected } from '../../../shared/constants/audit-finding';
import { $auditRule } from '../../../shared/constants/audit-rule';
import { $auditedDocument } from '../../../shared/constants/audited-document';
import { ISuccessCriteria } from '../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { TranslateService } from '../translate/translate.service';
import {
  IManualAuditFindingServerResponse,
  IManualAuditQuery,
  IManualFindingBulkStatusChangeRequest,
  IPopulatedManualFinding,
  IPopulatedManualFindingClient,
} from '../../../shared/interfaces/manual-audit-finding.interface';
import { IManualAuditRuleReport } from '../../../shared/interfaces/audit-reports/audit-report.interface';
import { $auditStandardSuccessCriteria } from '../../../shared/constants/audit-standard-success-criteria';
import { $severity } from '../../../shared/constants/accessibility';
import { IGetShortLinkResponse } from '../../../shared/interfaces/short-link.interface';
import { IScan } from '../../../shared/interfaces/scan.interface';
import { IRuleAuditHistoryManualLineItem } from '../../../shared/interfaces/rule-audit-history.interface';
import { IManualAuditHistoryRequest } from '../interfaces/manual-evaluation.interface';
import { IManualEvaluationDataSnapshot } from '../../../shared/interfaces/manual-evaluation-data-snapshot.interface';
import { SuccessCriteriaService } from './success-criteria.service';
import { AiSummaryKey } from '../../../shared/constants/ai-data-summary';
import { IDataSummaryClient, ISimpleDataSummary } from '../../../shared/interfaces/ai-data-summary.interface';
import { IConformanceScoreReport } from '../../../shared/interfaces/conformance-score.interface';

@Injectable({
  providedIn: 'root',
})
export class ManualAuditIssueService {
  constructor(
    private workspaceRestApi: WorkspacesRestAPI,
    private userService: UserPropertyService,
    private translateService: TranslateService,
    private successCriteriaService: SuccessCriteriaService,
  ) {}

  public getRuleAuditHistory(
    manualAuditHistoryRequest: IManualAuditHistoryRequest,
  ): Observable<IRuleAuditHistoryManualLineItem[]> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        switchMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IRuleAuditHistoryManualLineItem[]> =>
            this.workspaceRestApi.getManualEvaluationIssueRuleAuditHistory(
              workspaceId,
              digitalPropertyId,
              manualAuditHistoryRequest,
            ),
        ),
      );
  }

  public hasHistory(manualAuditHistoryRequest: IManualAuditHistoryRequest): Observable<boolean> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        switchMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<boolean> =>
            this.workspaceRestApi.getManualEvaluationIssueHasHistory(workspaceId, digitalPropertyId, manualAuditHistoryRequest),
        ),
      );
  }

  public getRuleCategories(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<string[]> {
    return this.workspaceRestApi.getManualRuleCategories(workspaceId, digitalPropertyId, scanId);
  }

  public getEvaluationIssues(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    params: IManualAuditQuery,
  ): Observable<IManualAuditFindingServerResponse> {
    return this.workspaceRestApi.getManualEvaluationIssues(workspaceId, digitalPropertyId, scanId, params);
  }

  public updateEvaluationTitleById(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    title: string,
  ): Observable<IScan> {
    return this.workspaceRestApi.updateManualEvaluationTitleById(workspaceId, digitalPropertyId, scanId, title);
  }

  public getEvaluationTopWcag(scanId: string): Observable<TopWcagKeyNumPair[]> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<TopWcagKeyNumPair[]> =>
            this.workspaceRestApi.getManualEvaluationTopWcag(workspaceId, digitalPropertyId, scanId),
        ),
      );
  }

  public getEvaluationIssuesBySeverity(scanId: string): Observable<IDashboardManualEvaluationsBySeverity> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IDashboardManualEvaluationsBySeverity> =>
            this.workspaceRestApi.getManualEvaluationIssuesBySeverity(workspaceId, digitalPropertyId, scanId),
        ),
      );
  }

  public downloadCSVReport(
    scanId: string,
    selectedFields: string[],
    formatColumns: $formatColumns,
    params: IManualAuditQuery,
  ): Observable<void> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<void> =>
            this.workspaceRestApi.downloadManualEvaluationIssues(
              workspaceId,
              digitalPropertyId,
              scanId,
              selectedFields,
              formatColumns,
              params,
            ),
        ),
      );
  }

  public getIssue(scanId: string, manualAuditIssueId: string): Observable<IPopulatedManualAuditIssueServerResponse> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IPopulatedManualAuditIssueServerResponse> =>
            this.workspaceRestApi.getManualEvaluationIssue(workspaceId, digitalPropertyId, scanId, manualAuditIssueId),
        ),
      );
  }

  public deleteIssue(scanId: string, manualAuditIssueId: string): Observable<void> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        switchMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.workspaceRestApi.deleteManualEvaluationIssue(workspaceId, digitalPropertyId, scanId, manualAuditIssueId),
        ),
      );
  }

  public updateQAIssueStatus(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
  ): Observable<IManualAuditIssueServerResponse[]> {
    return this.workspaceRestApi.updateQAIssueStatus(body, workspaceId, scanId, digitalPropertyId, manualAuditIssueId);
  }

  /**
   * Bulk update the status of a set of findings.
   * Does not support changing to the following statuses: [dismissed, toReview]
   * and from the following statuses: [dismissed].
   *
   * @param workspaceId
   * @param digitalPropertyId
   * @param scanId
   * @param request
   */
  public bulkUpdateFindingStatuses(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    request: IManualFindingBulkStatusChangeRequest,
  ): Observable<IManualAuditIssueServerResponse[]> {
    return this.workspaceRestApi.bulkUpdateFindingStatuses(workspaceId, digitalPropertyId, scanId, request);
  }

  public dismissQAIssue(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
  ): Observable<IAuditFinding> {
    return this.workspaceRestApi.dismissQAIssue(body, workspaceId, scanId, digitalPropertyId, manualAuditIssueId);
  }

  public dismissQAIssues(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
  ): Observable<IAuditFinding[]> {
    return this.workspaceRestApi.dismissQAIssues(body, workspaceId, scanId, digitalPropertyId);
  }

  public restoreQAIssue(
    body: FormData,
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
  ): Observable<IAuditFinding> {
    return this.workspaceRestApi.restoreQAIssue(body, workspaceId, scanId, digitalPropertyId, manualAuditIssueId);
  }

  public updateQAIssue(
    workspaceId: string,
    scanId: string,
    digitalPropertyId: string,
    manualAuditIssueId: string,
    issue: FormData,
  ): Observable<IAuditFinding> {
    return this.workspaceRestApi.updateQAIssue(workspaceId, scanId, digitalPropertyId, manualAuditIssueId, issue);
  }

  public uploadAttachment(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
    attachment: FormData,
  ): Observable<IUploadClient[]> {
    return this.workspaceRestApi.uploadManualEvaluationIssueAttachment(
      workspaceId,
      digitalPropertyId,
      scanId,
      manualAuditIssueId,
      attachment,
    );
  }

  public updateAttachment(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
    attachmentId: string,
    update: FormData,
  ): Observable<IUploadClient> {
    return this.workspaceRestApi.updateManualEvaluationIssueAttachment(
      workspaceId,
      digitalPropertyId,
      scanId,
      manualAuditIssueId,
      attachmentId,
      update,
    );
  }

  public deleteAttachment(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    manualAuditIssueId: string,
    attachmentId: string,
  ): Observable<{ _id: string }> {
    return this.workspaceRestApi.deleteManualEvaluationIssueAttachment(
      workspaceId,
      digitalPropertyId,
      scanId,
      manualAuditIssueId,
      attachmentId,
    );
  }

  public canInlineScreenshot(screenshotUrl: string): boolean {
    const acceptedImageExtensions: string[] = ['.png', '.jpg', 'jpeg'];

    const isImage = (fileName: string): boolean => {
      const lastDot = fileName.lastIndexOf('.');
      if (lastDot <= 0) {
        return false;
      }

      return acceptedImageExtensions.includes(fileName.substring(lastDot));
    };

    if (typeof screenshotUrl !== 'string' || screenshotUrl.length === 0 || screenshotUrl.includes('\n')) {
      return false;
    }

    try {
      const url: URL = new URL(screenshotUrl);
      if (/\.essentialaccessibility\.com$/i.test(url.hostname) === false) {
        return false;
      }

      return isImage(url.pathname);
    } catch (error) {
      return false;
    }
  }

  public getManualEvaluation(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IManualEvaluation> {
    return this.workspaceRestApi.getManualEvaluation(workspaceId, digitalPropertyId, scanId);
  }

  public getManualEvaluations(
    workspaceId: string,
    digitalPropertyId: string,
    query: IManualEvaluationQuery,
  ): Observable<IGetManualEvaluationsResponse> {
    return this.workspaceRestApi.getManualEvaluations(workspaceId, digitalPropertyId, query);
  }

  /**
   * @deprecated Will be removed on EAP-28995
   */
  public getManualRuleReportFromFinding(
    finding: IPopulatedManualFinding | IPopulatedManualFindingClient,
  ): IManualAuditRuleReport {
    return {
      ruleDescription: finding[$auditFinding.ruleId][$auditRule.description],
      ruleId: finding[$auditFinding.ruleId][$auditRule.ruleId],
      severity: finding[$auditFinding.ruleId][$auditRule.severity] as $severity,
      successCriterias: this.successCriteriaService.getWCAGWithNACriteriasByIdentifier(
        map(finding[$auditFinding.ruleId][$auditRule.successCriterias], $auditStandardSuccessCriteria.identifier),
      ),
      disabilitiesAffected: finding[$auditFinding.disabilityAffected],
    };
  }

  /**
   * @deprecated Will be removed on EAP-28995
   */
  public generateTaskDescription(
    baseUrl: string,
    finding: IPopulatedManualFinding | IPopulatedManualFindingClient,
    linkToFinding: string,
  ): string {
    const encodeScreenshotUrl: (url: string) => string = (url: string) => {
      try {
        // First, decode the URL to handle already encoded URLs
        const decodedUrl = decodeURI(url);

        // Then, re-encode it properly to handle any unencoded characters
        return encodeURI(decodedUrl);
      } catch (e) {
        // If decoding fails (e.g., due to bad encoding), encode it as-is
        return encodeURI(url);
      }
    };

    const ruleReport: IManualAuditRuleReport = this.getManualRuleReportFromFinding(finding);

    const scanIssueToDescriptionConfig: ICreateTaskDescription[] = [
      { header: 'link_to_finding', content: baseUrl + linkToFinding },
      {
        header: 'issue_field_severity',
        content: this.translateService.instant(
          `scan_issue_severity_${finding[$auditRule.severity] ?? finding[$auditFinding.ruleId][$auditRule.severity]}`,
        ),
      },
      { header: 'issue_field_url', content: finding[$auditFinding.documentId][$auditedDocument.reference] },
      { header: 'label_actual_result', content: finding[$auditFinding.actualResult] },
      { header: 'label_STR', content: finding[$auditFinding.stepsToReproduce] },
      {
        header: 'label_issue_frequency',
        content: this.translateService.instant(`issue_field_frequency_${finding[$auditFinding.issueFrequency]}`),
      },
      { header: 'issue_field_screen', content: finding[$auditFinding.screen] },
      { header: 'issue_field_screenshot', content: finding[$auditFinding.screenshot]?.map(encodeScreenshotUrl)?.join('\n') },
      {
        header: 'issue_field_successCriteria',
        content: ruleReport.successCriterias.map((sc: ISuccessCriteria) => sc.num).join(', '),
      },
      {
        header: 'issue_field_conformanceLevel',
        content: ruleReport.successCriterias.map((sc: ISuccessCriteria) => sc.level).join(', '),
      },
      {
        header: 'label_effort',
        content: this.translateService.instant(`scan_issue_severity_${finding[$auditFinding.functionalImpact]}`),
      },
      {
        header: 'label_instances',
        content: finding[$auditFinding.instances]
          ? finding[$auditFinding.instances]
          : this.translateService.instant('label_none_provided'),
      },
      {
        header: 'issue_field_disabilityAffected',
        content: (finding[$auditFinding.disabilityAffected] ?? [])
          .map((value: AuditFindingDisabilityAffected) => this.translateService.instant(`issue_field_disability_${value}`))
          .join(', '),
      },
      { header: 'recommendation_comment', content: finding[$auditFinding.recommendation] },
    ];

    return scanIssueToDescriptionConfig
      .map((description: ICreateTaskDescription): string =>
        JiraMarkdownUtils.jiraDescriptionBlock(this.translateService, description),
      )
      .join('');
  }

  public async getShortLink(scanId: string, manualAuditIssueId: string): Promise<string> {
    const resp: { shortLink: string } = await this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IGetShortLinkResponse> =>
            this.workspaceRestApi.getManualAuditIssueShortLink(workspaceId, digitalPropertyId, scanId, manualAuditIssueId),
        ),
      )
      .toPromise();

    return resp.shortLink;
  }

  public getManualEvaluationDataSnapshots(scanId: string, limit: number): Observable<IManualEvaluationDataSnapshot[]> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.workspaceRestApi.getManualEvaluationDataSnapshots(workspaceId, digitalPropertyId, scanId, limit),
        ),
      );
  }

  public getManualEvaluationAuditSummary(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
    summaryCode: AiSummaryKey,
    regenerate: boolean = false,
  ): Observable<IDataSummaryClient> {
    return this.workspaceRestApi.getManualEvaluationAuditSummary(
      workspaceId,
      digitalPropertyId,
      manualAuditId,
      summaryCode,
      regenerate,
    );
  }

  public getManualEvaluationAuditSummaryById(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
    summaryCode: AiSummaryKey,
    summaryId: string,
  ): Observable<IDataSummaryClient> {
    return this.workspaceRestApi.getManualEvaluationAuditSummaryById(
      workspaceId,
      digitalPropertyId,
      manualAuditId,
      summaryCode,
      summaryId,
    );
  }

  public getManualEvaluationAuditSummaryList(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
    summaryCode: AiSummaryKey,
  ): Observable<ISimpleDataSummary[]> {
    return this.workspaceRestApi.getManualEvaluationAuditSummaryList(workspaceId, digitalPropertyId, manualAuditId, summaryCode);
  }

  public rateManualEvaluationAiSummary(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
    summaryId: string,
    rating: number,
  ): Observable<void> {
    return this.workspaceRestApi.rateManualEvaluationAiSummary(workspaceId, digitalPropertyId, manualAuditId, summaryId, rating);
  }

  public getOrCreateConformanceScoreReport(
    workspaceId: string,
    digitalPropertyId: string,
    manualAuditId: string,
  ): Observable<IConformanceScoreReport> {
    return this.workspaceRestApi.getOrCreateEvaluationConformanceScoreReport(workspaceId, digitalPropertyId, manualAuditId);
  }
}
