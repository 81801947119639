<app-modal-container cardClass="card-width-50vw" [displayXButton]="false">
  <div class="modal-header-content">
    {{ options.headerKey | translate }}
  </div>
  <div class="modal-body-content text-black">
    <div class="row">
      <p class="col-auto">
        {{ options.descriptionKey | translate }}
      </p>
    </div>
    <div class="row">
      <div class="col-auto">
        <p class="mb-1">{{ options.listHeaderKey | translate }}</p>
        <ul>
          <li *ngFor="let item of options.listItems" [innerHTML]="formatListItem(item)"></li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form">
          <app-common-input [form]="form" [formValidationRequest$]="formValidationRequest$" [context]="nameInputContext">
          </app-common-input>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer-content">
    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn btn-primary font-weight-500" (click)="confirm()">
          {{ options.confirmButtonKey | translate }}
        </button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-outline-primary" (click)="container.closeModal()">
          {{ options.cancelButtonKey | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal-container>
