<div class="file-root container">
  <div class="row">
    <div class="col-md-12 d-flex no-padding">
      <div
        id="chosen-file"
        class="flex-grow-1"
        [class.disabled]="disabled"
        [attr.aria-hidden]="true"
        (click)="nativeInput.click()"
      >
        {{ fileName }}
      </div>
      <label
        [for]="id"
        [className]="'btn ' + btnClass"
        [attr.aria-label]="'btn_browse_files_label' | translate"
        role="button"
        tabindex="0"
      >
        {{ 'btn_browse_files_label' | translate }}
      </label>
      <input
        #nativeInput
        type="file"
        class="form-element visuallyhidden"
        [(ngModel)]="filePath"
        [id]="id"
        [accept]="accept"
        [disabled]="disabled"
        [attr.aria-hidden]="true"
        (change)="onFilesSelected($event)"
        tabindex="-1"
      />
    </div>
  </div>
</div>
