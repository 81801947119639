import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-common-switch',
  templateUrl: './common-switch.component.html',
  styleUrls: ['./common-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonSwitchComponent {
  public label: string;
  @Input() public id: string;
  @Input() public name: string;
  @Input() public disabled: boolean;
  @Input() public nonClickableLabel: boolean;

  @Input() public value: boolean;
  @Output() public valueChange: EventEmitter<boolean>;

  @Input() public labelTemplate: TemplateRef<{ nonClickableLabel: boolean }>;
  @Input() public switchButtonContainerTemplate: TemplateRef<any>;
  @Input() public switchContainerClass: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.valueChange = new EventEmitter<boolean>();
    this.switchContainerClass = 'd-flex align-items-center justify-content-between';
  }

  public onValueChanged(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
    this.changeDetectorRef.detectChanges();
  }

  public get status(): string {
    return this.value === true ? 'label_on' : 'label_off';
  }
}
