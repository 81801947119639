import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  DsButtonVariants,
  DsModalColorVariant,
  DsModalService,
  Icons,
  IconScale,
  IconStyles,
  DsCheckboxesFeedbackOption,
} from '@levelaccess/design-system';
import { Subject, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { CustomValidators } from '../../services/helpers/form-custom-validators';
import { AiChatService } from './ai-chat.service';
import { TextareaFieldContext } from '../common-textarea/common-textarea.component';
import { IChatMessageStatus, ISendChatMessageResponse } from '../../../../shared/interfaces/ai-chat.interface';
import { IHttpErrorResponse } from '../../interfaces/error.interface';
import { UserService } from '../../services/user.service';
import { $user } from '../../../../shared/constants/user';
import { TranslateService } from '../../translate/translate.service';
import { FeatureFlagCollection } from '../../../../shared/interfaces/feature-flag.interface';
import { Api } from '../../../../shared/constants/api';
import { IUserServerResponse } from '../../../../shared/interfaces/user.interface';
import { BrowserLocalStorageService } from '../../services/browser-local-storage.service';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

export enum ResponseRating {
  notHelpful = 1,
  helpFul = 5,
}

export const AI_CHAT_SESSION_ID_KEY: string = 'AI_CHAT_SESSION_ID';

@Component({
  selector: 'app-ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrls: ['./ai-chat.component.scss'],
})
export class AiChatComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _subscription: Subscription;
  protected sendMessageSubscription: Subscription = Subscription.EMPTY;

  protected readonly Icons: typeof Icons = Icons;
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  protected readonly DsModalColorVariant: typeof DsModalColorVariant = DsModalColorVariant;
  protected readonly IconScale: typeof IconScale = IconScale;
  protected readonly IconStyles: typeof IconStyles = IconStyles;
  protected readonly IChatMessageStatus: typeof IChatMessageStatus = IChatMessageStatus;
  protected readonly FeatureFlagCollection: typeof FeatureFlagCollection = FeatureFlagCollection;

  protected form: FormGroup<{ message: FormControl }>;
  protected formValidationRequest$: Subject<void>;
  public messages: ISendChatMessageResponse[] = [];
  protected readonly ratingTitle: string;
  protected readonly ratingOptions: DsCheckboxesFeedbackOption<number>[];

  protected readonly textAreaContext: TextareaFieldContext;

  private sessionId: string;

  constructor(
    public modalService: DsModalService,
    private fb: FormBuilder,
    private aiChatService: AiChatService,
    private userService: UserService,
    private translateService: TranslateService,
    private browserLocalStorageService: BrowserLocalStorageService,
  ) {
    this._subscription = new Subscription();
    this.formValidationRequest$ = new Subject();
    this.textAreaContext = {
      label: 'ai_chat_input_label',
      rows: 3,
      field: 'message',
      placeholder: 'ai_chat_input_placeholder',
      required: false,
    };
    this.ratingTitle = this.translateService.instant('ai-chat-message-rating-title');
    this.ratingOptions = [
      {
        label: this.translateService.instant('helpful'),
        id: 'rating-helpful',
        value: ResponseRating.helpFul,
        icon: Icons.ThumbsUp,
      },
      {
        label: this.translateService.instant('not-helpful'),
        id: 'rating-not-helpful',
        value: ResponseRating.notHelpful,
        icon: Icons.ThumbsDown,
      },
    ];

    this.form = this.fb.group({
      message: this.fb.control('', [CustomValidators.validateIsEmpty]),
    });
  }

  public sendMessage(): void {
    this.formValidationRequest$.next();
    if (!this.form.valid) {
      return;
    }

    this.doSendMessage(this.form.value.message);
  }

  public get userName(): string {
    return this.userService.retrieveAuthenticatedUserProfile()[$user.displayName];
  }

  private doSendMessage(message: string, resetIndex?: number): void {
    const addMessage = (response: ISendChatMessageResponse): void => {
      if (resetIndex !== undefined) {
        this.messages[resetIndex] = response;
      } else {
        this.messages.unshift(response);
      }
      this.form.reset();
    };

    this.sendMessageSubscription = this.aiChatService.sendMessage({ message, sessionId: this.sessionId }).subscribe({
      next: addMessage,
      error: (response: IHttpErrorResponse): void => {
        addMessage({ status: IChatMessageStatus.error, message, error: response.error.app.message } as ISendChatMessageResponse);
      },
    });
    this._subscription.add(this.sendMessageSubscription);
  }

  public retry(message: string, index: number): void {
    this.doSendMessage(message, index);
  }

  public onRatingChange(message: ISendChatMessageResponse, rating: number): void {
    message.rating = rating;
    if (rating === undefined) {
      this._subscription.add(this.aiChatService.unsetRating(message._id).subscribe());
    } else {
      this._subscription.add(this.aiChatService.setRating(message._id, rating).subscribe());
    }
  }

  public ngAfterViewInit(): void {
    // Allow user browse the website while chatting
    document.querySelector('ngb-offcanvas-backdrop').className = '';
    document.querySelector('body').style.overflow = 'unset';
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.initChatSessionId();
  }

  private initChatSessionId(): void {
    this.sessionId = this.browserLocalStorageService.getItem(AI_CHAT_SESSION_ID_KEY);

    if (SharedCommonUtility.isNullishOrEmpty(this.sessionId)) {
      const currentUserProfile: IUserServerResponse = this.userService.retrieveAuthenticatedUserProfile();
      this.sessionId = `${Api.chat}-${currentUserProfile[$user.currentTenantId]}-${new Date().toISOString()}`;
      this.browserLocalStorageService.setItem(AI_CHAT_SESSION_ID_KEY, this.sessionId);
    }
  }
}
