import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DefaultBreadcrumbsResolver } from './default-breadcrumbs.resolver';
import { AbstractBreadcrumbsResolver } from './abstract-breadcrumbs.resolver';
import { IPostProcessBreadcrumb } from './breadcrumbs.config';
import { IBreadcrumb } from '../../components/breadcrumbs/breadcrumbs.shared';
import { TranslateService } from '../../translate/translate.service';

@Injectable({ providedIn: 'root' })
export class ProjectAndTasksBreadcrumbsResolver extends AbstractBreadcrumbsResolver {
  public constructor(
    private defaultResolver: DefaultBreadcrumbsResolver,
    private translateService: TranslateService,
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): IPostProcessBreadcrumb {
    const resolve$: Observable<IBreadcrumb[]> = this.defaultResolver.resolve(route, state) as Observable<IBreadcrumb[]>;

    return resolve$.pipe(
      switchMap((prev: IBreadcrumb[]) => {
        const lastBreadcrumb: IBreadcrumb = prev[prev.length - 1];
        const textTranslated: string = this.translateService.instant('projects_and_tasks');
        return of([
          ...prev.slice(0, prev.length - 1),
          {
            path: lastBreadcrumb.path,
            isLinkToPageAvailable: lastBreadcrumb.isLinkToPageAvailable,
            queryParams: lastBreadcrumb.queryParams,
            queryParamsHandling: lastBreadcrumb.queryParamsHandling,
            textTranslated,
          } as IBreadcrumb,
        ]);
      }),
    );
  }
}
