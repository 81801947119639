import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { IModalDialogInputData } from '../../interfaces/modal-dialog.interface';
import { ColorFinderRGB, ColorRGB, ColorsUtility, IColorResult } from '../../../../shared/utils/colors.utility';
import { IASLintRuleData } from '../../../../shared/interfaces/aslint.interface';
import { ModalService } from '../../services/modal.service';
import { IModal } from '../modals/modal.interface';
import { ModalContainerComponent } from '../modals/modal-container.component';
import { IAutomatedScanIssue } from '../../../../shared/interfaces/scan-issues.interface';
import { $scanIssues } from '../../../../shared/constants/scan-issues';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { $flaw } from '../../../../shared/constants/flaw';
import { $auditFinding } from '../../../../shared/constants/audit-finding';

const ratioOptions: number[] = [3, 4.5, 7];
enum componentEnum {
  background = 'background',
  component = 'component',
  foreground = 'foreground',
  ratio = 'ratio',
  colourToEdit = 'colourToEdit',
  changeTextType = 'changeTextType',
}

@Component({
  selector: 'app-color-contrast-rule-suggestions',
  templateUrl: './color-contrast-rule-suggestions.component.html',
  styleUrls: ['./color-contrast-rule-suggestions.component.scss'],
})
export class ColorContrastRuleSuggestionsComponent implements OnInit, IModal {
  @Input() public modalInputData: IModalDialogInputData<IAutomatedScanIssue>;
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  public componentEnum: { [key in keyof typeof componentEnum]: any };
  public ratioOptions: number[];
  public results: IColorResult[];
  public resultsGeneratedFor: componentEnum | null;
  public ratio: string;
  public contrastBackground: string;
  public contrastColor: string;
  public colorContrastForm: UntypedFormGroup;
  public formData: any;
  public searchedAtLeastOnce: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
  ) {
    this.ratio = '';
    this.contrastBackground = '';
    this.contrastColor = '';
    this.ratioOptions = ratioOptions;
    this.formData = {};
    this.results = [];
    this.componentEnum = componentEnum;
    this.searchedAtLeastOnce = false;
    this.resultsGeneratedFor = null;

    this.createForm();
  }

  private createForm(): void {
    const formConfig = {
      [componentEnum.changeTextType]: new UntypedFormControl(componentEnum.foreground),
      [componentEnum.ratio]: new UntypedFormControl(this.ratioOptions[1]),
    };
    this.colorContrastForm = this.formBuilder.group(formConfig);
  }

  private setContrastRatio(): void {
    try {
      const ratio: string | null = /ratio:(.*):1/gim.exec(this.modalInputData.data[$flaw.data][$auditFinding.actualResult])[1];

      if (ratio === null) {
        this.ratio = '0';
      } else {
        this.ratio = ratio;
      }
    } catch (e) {
      console.error('[ColorContrastRuleSuggestionsComponent.setContrastRatio]', e);
    }
  }

  public findClosestColorsThatPassesContrastRatio(): void {
    this.searchedAtLeastOnce = true;
    let colorFinder: ColorFinderRGB;

    if (this.contrastBackground === 'transparent' || this.contrastColor === 'transparent') {
      return;
    }

    const background: ColorRGB = this.HEXtoRGB(this.contrastBackground);
    const color: ColorRGB = this.HEXtoRGB(this.contrastColor);

    this.formData = this.colorContrastForm.value;

    if (this.formData[componentEnum.changeTextType] === componentEnum.background) {
      this.resultsGeneratedFor = componentEnum.background;
      colorFinder = new ColorFinderRGB(
        { red: color.red, green: color.green, blue: color.blue },
        { red: background.red, green: background.green, blue: background.blue },
        this.colorContrastForm.value.ratio,
      );
    } else {
      this.resultsGeneratedFor = componentEnum.foreground;
      colorFinder = new ColorFinderRGB(
        { red: background.red, green: background.green, blue: background.blue },
        { red: color.red, green: color.green, blue: color.blue },
        this.colorContrastForm.value.ratio,
      );
    }

    this.results = colorFinder.findColors();
  }

  public RGBtoHEX(rgb: ColorRGB): string {
    return ColorsUtility.RGBtoHEX(rgb);
  }

  public HEXtoRGB(hex: string): ColorRGB | null {
    return ColorsUtility.HEXtoRGB(hex);
  }

  public closeModal(): void {
    this.modalService.closeModal();
  }

  public tableSampleColumnStyles(result: IColorResult): { [key: string]: any } | null {
    if (this.resultsGeneratedFor === componentEnum.foreground) {
      return { 'background-color': this.contrastBackground, color: this.RGBtoHEX(result.color) };
    }

    if (this.resultsGeneratedFor === componentEnum.background) {
      return { 'background-color': this.RGBtoHEX(result.color), color: this.contrastColor };
    }

    return null;
  }

  public tableSampleColumnTextStyles(result: IColorResult): { [key: string]: any } | null {
    if (this.resultsGeneratedFor === componentEnum.foreground) {
      return { color: this.RGBtoHEX(result.color) };
    }

    if (this.resultsGeneratedFor === componentEnum.background) {
      return { color: this.contrastColor };
    }

    return null;
  }

  public ngOnInit(): void {
    if (SharedCommonUtility.isNullish(this.modalInputData.data[$flaw.data][$auditFinding.auditData])) {
      // Note: this is a fake colors as in the previous ASLint colors weren't passed
      this.contrastBackground = 'transparent';
      this.contrastColor = 'transparent';

      return;
    }

    this.setContrastRatio();

    const ruleReportData: IASLintRuleData = this.modalInputData.data[$flaw.data][$scanIssues.auditData];

    this.contrastBackground = ruleReportData.contrastBackground;
    this.contrastColor = ruleReportData.contrastColor;

    if (typeof ruleReportData.contrastRatio !== 'undefined') {
      this.ratio = ruleReportData.contrastRatio;
    }
  }
}
