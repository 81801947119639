<div class="row">
  <div class="col-md-12 verification-response">
    <h1>{{ pageTitle }}</h1>

    <p *ngIf="isEmailVerified === null" class="verification-in-progress">
      <svg viewBox="0 0 8 8">
        <use xlink:href="#aperture"></use></svg
      >{{ 'email_verification_in_progress' | translate }}
    </p>

    <p *ngIf="isEmailVerified === true" class="verification-success">
      <svg viewBox="0 0 8 8">
        <use xlink:href="#check"></use></svg
      >{{ 'email_verified_successfully' | translate: userEmail }}
    </p>

    <ng-container *ngIf="isEmailVerified === false">
      <p class="verification-unsuccessful">
        <svg viewBox="0 0 8 8">
          <use xlink:href="#warning"></use></svg
        >{{ 'email_verified_unsuccessfully' | translate }}
      </p>
    </ng-container>
  </div>
</div>
