import { Directive, ElementRef } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Directive({
  selector: '[appStepNext]',
})
export class StepNextDirective {
  public click$: Observable<void>;

  constructor(private host: ElementRef) {
    this.click$ = fromEvent(this.host.nativeElement, 'click');
  }
}
