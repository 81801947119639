import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { switchMap, finalize, catchError, filter, take } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { StatusCodes } from 'http-status-codes';

import { AuthService } from '../auth.service';
import { config } from '../../../environments/config.shared';
import { UserService } from '../user.service';
import { ErrorHandlerService } from '../error-handler.service';
import { Api } from '../../../../shared/constants/api';
import { IGlobalError } from '../../../../shared/interfaces/error.interface';
import { errorMessagesNames } from '../../../../shared/constants/errors';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshingToken: boolean;
  private tokenSubject: BehaviorSubject<string>;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.isRefreshingToken = false;
    this.tokenSubject = new BehaviorSubject<string>(null);
  }

  private static addAccessToken(request: HttpRequest<any>, accessToken: string | undefined): HttpRequest<any> {
    let newRequest: HttpRequest<any>;

    if (typeof accessToken === 'string') {
      newRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return newRequest || request;
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string | undefined = this.authService.getAccessTokenFromStorage();
    const requestWithToken = TokenInterceptor.addAccessToken(request, accessToken);

    const handleError = (errorResponse: any): Observable<HttpEvent<any>> => {
      if (errorResponse instanceof HttpErrorResponse) {
        const error: IGlobalError = errorResponse.error as IGlobalError;
        if (
          errorResponse.status === StatusCodes.FORBIDDEN &&
          SharedCommonUtility.notNullish(error?.app) &&
          error.app.name !== errorMessagesNames.NoAccessToDigitalProperty
        ) {
          if (this.isRefreshingToken === false) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);

            const processNewToken = (refreshTokenResponse: HttpResponse<any>): Observable<HttpEvent<any> | never> => {
              const newToken: string | null = refreshTokenResponse.headers.get(config.token.access.header);

              if (SharedCommonUtility.isNullish(newToken)) {
                return next.handle(request);
              }

              this.authService.saveAccessTokenToStorage(newToken);
              this.tokenSubject.next(newToken);
              return next.handle(TokenInterceptor.addAccessToken(request, newToken));
            };

            const errorGettingNewToken = (err: any, _caught: Observable<HttpEvent<any>>): Observable<never> => {
              if (err.status === StatusCodes.FORBIDDEN) {
                this.router
                  .navigate([`/${Api.forbidden}`])
                  .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

                return EMPTY;
              }

              this.userService.processUnauthorization();

              this.router
                .navigate([`/${Api.login}`])
                .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

              return throwError(err);
            };

            const stopRefreshingToken = (): void => {
              this.isRefreshingToken = false;
            };

            return this.authService
              .getNewAccessToken()
              .pipe(switchMap(processNewToken), catchError(errorGettingNewToken), finalize(stopRefreshingToken));
          }
          const validToken = (token: string): boolean => {
            return token !== null;
          };

          const createRequest = (token: string): Observable<HttpEvent<any>> => {
            return next.handle(TokenInterceptor.addAccessToken(request, token));
          };

          return this.tokenSubject.pipe(filter(validToken), take(1), switchMap(createRequest));
        } else if (errorResponse.status === StatusCodes.UNAUTHORIZED) {
          // Note: generally an unauthorized request happens when the user permissions in the UI are different than the one on server
          // as for instance the user does not belong to the workspace anymore, or his permissions on that workspace changed or his permissions
          // at application level changed. Then we need to reload the user profile to ensure we retrieved the updated data
          this.userService.checkUserPermissionsChange();
        }
      }

      return throwError(errorResponse);
    };

    return next.handle(requestWithToken).pipe(catchError(handleError));
  }
}
