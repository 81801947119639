import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestService } from './rest.service';
import { IJobIndustriesServerResponse } from '../../../shared/interfaces/job-industry.interface';

@Injectable({
  providedIn: 'root',
})
export class JobIndustryService {
  constructor(private restService: RestService) {}

  public getJobIndustries(limit: number = 50, skip: number = 0): Observable<IJobIndustriesServerResponse> {
    return this.restService.getJobIndustries(limit, skip);
  }
}
