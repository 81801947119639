import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DsButtonVariants, Icons, IconStyles } from '@levelaccess/design-system';
import _ from 'lodash';

import { UserService } from '../../../../services/user.service';
import { IDigitalPropertyListItem } from '../../../../../../shared/interfaces/digital-property.interface';
import { IUserServerResponse } from '../../../../../../shared/interfaces/user.interface';
import { $user } from '../../../../../../shared/constants/user';
import { ISecurityEntity, ISecurityGroup } from '../../../../../../shared/interfaces/security-group.interface';
import { $securityEntity, $securityGroup, SecurityEntityLevel } from '../../../../../../shared/constants/security-group';
import { $digitalProperty } from '../../../../../../shared/constants/digital-properties';
import { $workspace } from '../../../../../../shared/constants/workspace';
import { TranslateService } from '../../../../translate/translate.service';

interface IUserWorkspace {
  name: string;
  websiteAppNames: string[];
}

@Component({
  selector: 'app-signup-overview',
  templateUrl: './signup-overview.component.html',
  styleUrls: ['./signup-overview.component.scss'],
})
export class SignupOverviewComponent implements OnInit, OnDestroy {
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly Icons: typeof Icons = Icons;
  public readonly IconStyles: typeof IconStyles = IconStyles;

  private subscription: Subscription = new Subscription();

  public description: string;
  public userWorkspaces: IUserWorkspace[] = [];

  @Input() public tenantName: string;

  @Output() public onSuccess: EventEmitter<void> = new EventEmitter();

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  public onSubmit(): void {
    this.onSuccess.next();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.userService.getAuthenticatedUser().subscribe((response: IUserServerResponse) => {
        this.userWorkspaces = this.getUserWorkspaces(response);
        this.description = this.getDescription(response, this.userWorkspaces);
      }),
    );
  }

  private getUserWorkspaces(response: IUserServerResponse): IUserWorkspace[] {
    return _.chain(response[$user.digitalProperties])
      .groupBy((item: IDigitalPropertyListItem) => item[$digitalProperty.workspace][$workspace.name])
      .entries()
      .map(([workspaceName, properties]: [string, IDigitalPropertyListItem[]]) => ({
        name: workspaceName,
        websiteAppNames: properties.map((item: IDigitalPropertyListItem) => item[$digitalProperty.name]),
      }))
      .value();
  }

  private getDescription(response: IUserServerResponse, userWorkspaces: IUserWorkspace[]): string {
    const workspaceCount: number = userWorkspaces.length;
    const propertyCount: number = userWorkspaces.flatMap((workspace: IUserWorkspace) => workspace.websiteAppNames).length;

    if (workspaceCount === 1) {
      return this.translateService.instant('sign_up_overview_entity_count_for_workspace', [
        propertyCount,
        userWorkspaces[0].name,
      ]);
    }

    const hasTenantLevelAccess: boolean = response[$user.groups]
      .flatMap((group: ISecurityGroup) => group[$securityGroup.entities])
      .some((entity: ISecurityEntity) => entity[$securityEntity.level] === SecurityEntityLevel.tenant);

    return this.translateService.instant(
      hasTenantLevelAccess ? 'sign_up_overview_all_entity_count' : 'sign_up_overview_entity_count',
      [workspaceCount, propertyCount, this.tenantName],
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
