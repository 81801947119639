import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { RoutingService } from '../../../../services/routing.service';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-forbidden-error',
  templateUrl: './forbidden-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenErrorComponent implements OnInit {
  public pageTitle: string;

  constructor(
    private routingService: RoutingService,
    private modalService: ModalService,
  ) {}

  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
    this.modalService.closeModal();
  }
}
