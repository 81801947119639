import { Component, Input } from '@angular/core';
import { Icons } from '@levelaccess/design-system';

@Component({
  selector: 'app-secure-alert',
  templateUrl: './secure-alert.component.html',
  styleUrls: ['./secure-alert.component.scss'],
})
export class SecureAlertComponent {
  @Input() public alert: string;

  constructor() {}

  public get alertIcon(): Icons {
    return Icons.TriangleExclamation;
  }
}
