import {
  AfterContentChecked,
  Component,
  Directive,
  Input,
  TemplateRef,
  ContentChildren,
  QueryList,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectorRef,
  Host,
  HostListener,
  HostBinding,
} from '@angular/core';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

let nextId = 0;

export enum CssState {
  FOCUS = 'focus',
  BOLD = 'bold',
  HOVER = 'hover',
}

@Directive({
  selector: 'ng-template[appAccordionPanelHeader]',
})
export class AccordionPanelHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * A directive that wraps the accordion panel content.
 */
@Directive({ selector: 'ng-template[appAccordionPanelContent]' })
export class AccordionPanelContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * A directive that wraps an individual accordion panel with title and collapsible content.
 */
@Directive({ selector: 'div[appAccordionPanel]' })
export class AccordionPanelDirective implements AfterContentChecked {
  @Input() public disabled: boolean = false;

  @Input() public id: string = `ngb-panel-${nextId++}`;

  public isOpen: boolean = false;

  public headerTpl: AccordionPanelHeaderDirective;
  public contentTpl: AccordionPanelContentDirective;

  @ContentChildren(AccordionPanelHeaderDirective, { descendants: false }) headerTpls: QueryList<AccordionPanelHeaderDirective>;
  @ContentChildren(AccordionPanelContentDirective, { descendants: false }) contentTpls: QueryList<AccordionPanelContentDirective>;

  public ngAfterContentChecked(): void {
    // We are using @ContentChildren instead of @ContentChild as in the Angular version being used
    // only @ContentChildren allows us to specify the {descendants: false} option.
    // Without {descendants: false} we are hitting bugs described in:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/2240
    this.headerTpl = this.headerTpls.first;
    this.contentTpl = this.contentTpls.first;
  }
}

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccordionComponent implements AfterContentChecked {
  @ContentChildren(AccordionPanelDirective) panels: QueryList<AccordionPanelDirective>;

  /**
   * An array or comma separated strings of panel ids that should be opened **initially**.
   */
  @Input() activeIds: string | readonly string[] = [];

  @Input() useList: boolean = false;

  @Input() nestedList: boolean = false;

  @Input() labeledBy: string = null;

  public get ariaLabelledBy(): string | undefined {
    return !this.nestedList && SharedCommonUtility.notNullish(this.labeledBy) ? this.labeledBy : undefined;
  }

  constructor(private changeDetector: ChangeDetectorRef) {}

  private changeOpenState(panel: AccordionPanelDirective | null, nextState: boolean): void {
    if (SharedCommonUtility.notNullish(panel) && !panel.disabled && panel.isOpen !== nextState) {
      panel.isOpen = nextState;

      this.updateActiveIds();
      this.changeDetector.detectChanges();
    }
  }

  private findPanelById(panelId: string): AccordionPanelDirective | null {
    return this.panels.find((p: AccordionPanelDirective) => p.id === panelId) || null;
  }

  private updateActiveIds(): void {
    this.activeIds = this.panels
      .filter((panel: AccordionPanelDirective) => panel.isOpen && !panel.disabled)
      .map((panel: AccordionPanelDirective): string => panel.id);
  }

  public expand(panelId: string): void {
    this.changeOpenState(this.findPanelById(panelId), true);
  }

  public expandAll(): void {
    this.panels.forEach((panel: AccordionPanelDirective): void => this.changeOpenState(panel, true));
  }

  public collapse(panelId: string): void {
    this.changeOpenState(this.findPanelById(panelId), false);
  }

  public collapseAll(): void {
    this.panels.forEach((panel: AccordionPanelDirective) => {
      this.changeOpenState(panel, false);
    });
  }

  public toggle(panelId: string): void {
    const panel = this.findPanelById(panelId);
    if (panel) {
      this.changeOpenState(panel, !panel.isOpen);
    }
  }

  public ngAfterContentChecked(): void {
    if (typeof this.activeIds === 'string') {
      this.activeIds = this.activeIds.split(/\s*,\s*/);
    }

    this.panels.forEach((panel: AccordionPanelDirective) => {
      panel.isOpen = !panel.disabled && this.activeIds.indexOf(panel.id) > -1;
    });
  }
}

@Component({
  selector: 'app-accordion-chevron',
  template: `
    <div class="chevron pe-1 pe-md-2" [hidden]="opened === false">
      <svg class="accordion-chevron" role="img" aria-hidden="true">
        <use xlink:href="#chevron-bottom-thin"></use>
      </svg>
    </div>
    <div class="chevron pe-1 pe-md-2" [hidden]="opened">
      <svg class="accordion-chevron" role="img" aria-hidden="true">
        <use xlink:href="#chevron-right-thin"></use>
      </svg>
    </div>
  `,
})
export class AccordionChevronComponent {
  @Input() opened: boolean;
  @Input() label: string;
}

@Directive({
  selector: 'button[appAccordionButton]',
})
export class AccordionButtonDirective {
  @HostBinding('type') type: string = 'button';
  @HostBinding('class') class: string =
    'h-100 w-100 d-flex flex-col justify-content-start align-items-baseline lap-accordion-button py-2';

  @Input() public hoverState: CssState;
  @Input() public focusState: CssState;

  public constructor(private el: ElementRef) {}

  @HostListener('mouseover') public onHover(): void {
    if (SharedCommonUtility.notNullish(this.hoverState)) {
      this.el.nativeElement.classList.add(this.hoverState);
    }
  }

  @HostListener('mouseleave') public onHoverOff(): void {
    if (SharedCommonUtility.notNullish(this.hoverState)) {
      this.el.nativeElement.classList.remove(this.hoverState);
    }
  }

  @HostListener('focus') public onFocus(): void {
    if (SharedCommonUtility.notNullish(this.focusState)) {
      this.el.nativeElement.classList.add(this.focusState);
    }
  }

  @HostListener('blur') public onBlur(): void {
    if (SharedCommonUtility.notNullish(this.focusState)) {
      this.el.nativeElement.classList.remove(this.focusState);
    }
  }
}

/**
 * A directive to put on a button that toggles panel opening and closing.
 */
@Directive({
  selector: 'button[appAccordionPanelToggle]',
})
export class AccordionPanelToggleDirective {
  @HostBinding('type') type: string = 'button';
  @HostBinding('class') class: string = 'h-100 w-100 d-flex flex-col justify-content-start align-items-baseline py-2';
  @HostBinding('disabled') get disabled(): boolean {
    return this.panel.disabled;
  }
  @HostBinding('class.collapsed') get collapsed(): boolean {
    return !this.panel.isOpen;
  }
  @HostBinding('attr.aria-expanded') get expanded(): boolean {
    return this.panel.isOpen;
  }
  @HostBinding('attr.aria-controls') get controls(): string {
    if (this.panel.isOpen) {
      return this.panel.id;
    }
    return undefined;
  }

  public constructor(
    public accordion: AccordionComponent,
    @Host() public panel: AccordionPanelDirective,
  ) {}

  @HostListener('click') click(): void {
    this.accordion.toggle(this.panel.id);
  }
}
