import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { PackageNames } from '../../../shared/constants/packaging';

@Injectable({
  providedIn: 'root',
})
export class TenantPackageService {
  private readonly packageSubject: BehaviorSubject<PackageNames>;
  private readonly packageChange$: Observable<PackageNames>;

  constructor() {
    this.packageSubject = new BehaviorSubject<PackageNames>(null);
    this.packageChange$ = this.packageSubject.pipe(filter(SharedCommonUtility.notNullish), distinctUntilChanged(), skip(1));
  }

  public get tenantPackageChange$(): Observable<PackageNames> {
    return this.packageChange$;
  }

  public get tenantPackage(): PackageNames {
    return this.packageSubject.value;
  }

  public set tenantPackage(value: PackageNames) {
    this.packageSubject.next(value);
  }
}
