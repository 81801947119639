<div>
  <label class="fw-bold margin-bottom-sm" [for]="domID">
    {{ label | translate }}
  </label>
  <input
    type="number"
    class="w-100 form-element"
    [id]="domID"
    [disabled]="disabled"
    [(ngModel)]="output.values"
    (change)="onValueChange()"
  />
</div>
