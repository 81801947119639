import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { scanAuthenticationType } from '../../../../shared/constants/scanning';
import { CustomValidators } from '../../services/helpers/form-custom-validators';
import { InputFieldContext } from '../common-input/common-input.component';
import { scanCreateRequest } from '../../../../shared/constants/scan-create-request';
import { UserService } from '../../services/user.service';
import {
  IDigitalProperty,
  IDigitalPropertyListItem,
  IDigitalPropertyMonitoring,
} from '../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty, $digitalPropertyMonitoring } from '../../../../shared/constants/digital-properties';
import { DigitalPropertyService } from '../../services/digital-property.service';
import { ISecureWebAuthentication } from '../../../../shared/interfaces/scan-secuire-web-authentication.interface';
import { $scan } from '../../../../shared/constants/scan';

const basicControls: string[] = [scanCreateRequest.scanAuthenticationPassword, scanCreateRequest.scanAuthenticationUserName];
const swaControls: string[] = [
  scanCreateRequest.swaLoginPageURL,
  scanCreateRequest.swaPassword,
  scanCreateRequest.swaUserName,
  scanCreateRequest.swaPasswordFieldId,
  scanCreateRequest.swaUserNameFieldId,
  scanCreateRequest.swaSubmitFieldId,
  scanCreateRequest.swaSubmitUsernameFieldId,
  scanCreateRequest.swaSubmitSuccessId,
];
const controls: string[] = [...basicControls, ...swaControls];
const nonRequiredFields: string[] = [scanCreateRequest.swaSubmitUsernameFieldId];

@Component({
  selector: 'app-scan-authentication-details',
  templateUrl: './scan-authentication-details.component.html',
  styleUrls: ['./scan-authentication-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanAuthenticationDetailsComponent implements OnInit, OnDestroy {
  private scanAuthenticationChangeSubscription: Subscription;
  private propertyChangeSubscription: Subscription;
  private domainRestrictions$: BehaviorSubject<RegExp[]>;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public formValidationRequest$: Observable<void>;
  public isDisabled: boolean;
  public scanCreateRequest: typeof scanCreateRequest;
  public scanAuthenticationType: typeof scanAuthenticationType;
  public addAuthDetails: boolean;

  @Input()
  public set monitoring(value: IDigitalPropertyMonitoring) {
    if (this.form.get(scanCreateRequest.scanAuthenticationType) || !value?.[$digitalPropertyMonitoring.scanAuthenticationType]) {
      return;
    }

    switch (value[$digitalPropertyMonitoring.scanAuthenticationType]) {
      case scanAuthenticationType.basic_authentication:
        this.onAddAuthenticationDetails(true);
        this.form.patchValue({
          [scanCreateRequest.scanAuthenticationType]: scanAuthenticationType.basic_authentication,
          [scanCreateRequest.scanAuthenticationUserName]: value[$digitalPropertyMonitoring.scanAuthenticationUserName],
          [scanCreateRequest.scanAuthenticationPassword]: value[$digitalPropertyMonitoring.scanAuthenticationPassword],
        });
        break;
      case scanAuthenticationType.secure_web_authentication:
        const swaAuthentication: ISecureWebAuthentication = value[$digitalPropertyMonitoring.secureWebAuthentication];
        this.onAddAuthenticationDetails(true);
        this.form.patchValue({
          [scanCreateRequest.scanAuthenticationType]: scanAuthenticationType.secure_web_authentication,
          [scanCreateRequest.swaLoginPageURL]: swaAuthentication[$scan.swaLoginPageURL],
          [scanCreateRequest.swaPassword]: swaAuthentication[$scan.swaPassword],
          [scanCreateRequest.swaUserName]: swaAuthentication[$scan.swaUserName],
          [scanCreateRequest.swaPasswordFieldId]: swaAuthentication[$scan.swaPasswordFieldId],
          [scanCreateRequest.swaUserNameFieldId]: swaAuthentication[$scan.swaUserNameFieldId],
          [scanCreateRequest.swaSubmitFieldId]: swaAuthentication[$scan.swaSubmitFieldId],
          [scanCreateRequest.swaSubmitUsernameFieldId]: swaAuthentication[$scan.swaSubmitUsernameFieldId],
          [scanCreateRequest.swaSubmitSuccessId]: swaAuthentication[$scan.swaSubmitSuccessId],
        });
        break;
      // no default
    }
  }

  @Input()
  public set disabled(value: boolean) {
    this.isDisabled = value;

    const opts: Record<string, any> = { emitEvent: false };

    [...controls, scanCreateRequest.scanAuthenticationType]
      .filter((key: string): boolean => key in this.form.controls)
      .map((key: string): AbstractControl => this.form.get(key))
      .forEach((control: AbstractControl): void => (this.isDisabled ? control.disable(opts) : control.enable(opts)));

    this.changeDetectorRef.detectChanges();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private digitalPropertyService: DigitalPropertyService,
  ) {
    this.scanCreateRequest = scanCreateRequest;
    this.scanAuthenticationType = scanAuthenticationType;
    this.scanAuthenticationChangeSubscription = new Subscription();
    this.propertyChangeSubscription = new Subscription();
    this.domainRestrictions$ = new BehaviorSubject<RegExp[]>([]);
  }

  private setAuthenticationType(type: scanAuthenticationType = scanAuthenticationType.basic_authentication): void {
    const control: UntypedFormControl = this.form.get(scanCreateRequest.scanAuthenticationType) as UntypedFormControl;
    if (control === null) {
      this.form.registerControl(scanCreateRequest.scanAuthenticationType, this.formBuilder.control(type));
    } else {
      control.setValue(type, { onlySelf: true, emitEvent: false });
    }
  }

  private addValidators(): void {
    controls
      .filter((control: string): boolean => control in this.form.controls)
      .forEach((control: string): void => {
        const ctrl: AbstractControl = this.form.get(control);
        const validators: ValidatorFn[] = [];
        if (!nonRequiredFields.includes(control)) {
          validators.push(CustomValidators.required);
          validators.push(CustomValidators.validateIsEmpty);
        }
        if (control === scanCreateRequest.swaLoginPageURL) {
          validators.push(CustomValidators.urlValidator);
          validators.push(CustomValidators.domainRestrictionsValidator(this.domainRestrictions$.value));
        }
        ctrl.setValidators(validators);
      });
  }

  private removeBasicAuthControls(): void {
    this.form.removeControl(scanCreateRequest.scanAuthenticationUserName);
    this.form.removeControl(scanCreateRequest.scanAuthenticationPassword);
  }

  private removeSWAControls(): void {
    this.form.removeControl(scanCreateRequest.swaLoginPageURL);
    this.form.removeControl(scanCreateRequest.swaPassword);
    this.form.removeControl(scanCreateRequest.swaUserName);
    this.form.removeControl(scanCreateRequest.swaPasswordFieldId);
    this.form.removeControl(scanCreateRequest.swaUserNameFieldId);
    this.form.removeControl(scanCreateRequest.swaSubmitFieldId);
    this.form.removeControl(scanCreateRequest.swaSubmitUsernameFieldId);
    this.form.removeControl(scanCreateRequest.swaSubmitSuccessId);
  }

  private onDigitalPropertyChanged(property: IDigitalProperty): void {
    this.domainRestrictions$.next(
      this.digitalPropertyService.getDomainRestrictions(property[$digitalProperty.authorizedDomains]),
    );
  }

  public upsertControls(): void {
    const controlNotExists = (control: string): boolean => control in this.form.controls === false;
    const setControl = (control: string): any => this.form.registerControl(control, this.formBuilder.control(''));

    switch (this.form.get(scanCreateRequest.scanAuthenticationType).value) {
      case scanAuthenticationType.secure_web_authentication:
        swaControls.filter(controlNotExists).forEach(setControl);
        break;

      case scanAuthenticationType.basic_authentication:
        basicControls.filter(controlNotExists).forEach(setControl);
        break;
      // no default
    }

    this.addValidators();

    this.form.updateValueAndValidity();
  }

  public resetControls(): void {
    const setControl = (control: string): any => this.form.registerControl(control, this.formBuilder.control(''));
    const removeControl = (key: string): void => this.form.removeControl(key);
    const hasControl = (key: string): boolean => key in this.form.controls;

    switch (this.form.get(scanCreateRequest.scanAuthenticationType).value) {
      case scanAuthenticationType.secure_web_authentication:
        swaControls.forEach(setControl);
        basicControls.filter(hasControl).forEach(removeControl);
        break;

      case scanAuthenticationType.basic_authentication:
        basicControls.forEach(setControl);
        swaControls.filter(hasControl).forEach(removeControl);
        break;
      // no default
    }

    this.addValidators();

    this.form.updateValueAndValidity();
  }

  public onAddAuthenticationDetails(addAuthEnabled: boolean, resetControls: boolean = true): void {
    if (addAuthEnabled) {
      this.setAuthenticationType(
        resetControls
          ? scanAuthenticationType.basic_authentication
          : this.form.get(scanCreateRequest.scanAuthenticationType).value,
      );

      this.scanAuthenticationChangeSubscription.unsubscribe();
      this.scanAuthenticationChangeSubscription = this.form
        .get(scanCreateRequest.scanAuthenticationType)
        .valueChanges.subscribe(() => this.resetControls());
    } else {
      this.setAuthenticationType(scanAuthenticationType.no_authentication);

      this.removeBasicAuthControls();
      this.removeSWAControls();
    }

    this.addAuthDetails = addAuthEnabled;

    if (resetControls) {
      this.resetControls();
    } else {
      this.upsertControls();
    }

    this.changeDetectorRef.detectChanges();
  }

  // eslint-disable-next-line consistent-return
  public getInputFieldContext(field: scanCreateRequest): InputFieldContext {
    switch (field) {
      case scanCreateRequest.scanAuthenticationUserName:
      case scanCreateRequest.swaUserName:
        return {
          label: 'scan_authentication_user_name',
          field,
          placeholder: 'user_name_placeholder',
          autocomplete: 'off',
          required: true,
        };
      case scanCreateRequest.scanAuthenticationPassword:
      case scanCreateRequest.swaPassword:
        return {
          label: 'scan_authentication_password',
          field,
          placeholder: 'user_password_placeholder',
          type: 'password',
          autocomplete: 'off',
          required: true,
        };
      case scanCreateRequest.swaUserNameFieldId:
        return {
          label: 'username_field_css_selector',
          field,
          placeholder: 'username_field_css_selector',
          required: true,
        };
      case scanCreateRequest.swaPasswordFieldId:
        return {
          label: 'password_field_css_selector',
          field,
          placeholder: 'password_field_css_selector',
          required: true,
        };
      case scanCreateRequest.swaSubmitSuccessId:
        return {
          label: 'success_element_css_selector',
          field,
          placeholder: 'success_element_css_selector',
          required: true,
        };
      case scanCreateRequest.swaSubmitFieldId:
        return {
          label: 'submit_button_css_selector',
          field,
          placeholder: 'submit_button_css_selector',
          required: true,
        };
      case scanCreateRequest.swaSubmitUsernameFieldId:
        return {
          label: 'username_submit_button_css_selector',
          field,
          placeholder: 'username_submit_button_css_selector',
          required: false,
          description: 'html_username_submit_button_id_description',
        };
      case scanCreateRequest.swaLoginPageURL:
        return {
          label: 'scan_authentication_login_url',
          field,
          subLabel: 'login_url_placeholder',
          required: true,
        };
      // no default
    }
  }

  public updateAuthValues(): void {
    const authenticationType: scanAuthenticationType = this.form.getRawValue()[
      scanCreateRequest.scanAuthenticationType
    ] as scanAuthenticationType;
    if (authenticationType && authenticationType !== scanAuthenticationType.no_authentication) {
      this.onAddAuthenticationDetails(true, false);
    } else {
      this.onAddAuthenticationDetails(false);
    }
  }

  public ngOnInit(): void {
    this.updateAuthValues();

    this.scanAuthenticationChangeSubscription = this.form.get(scanCreateRequest.scanAuthenticationType).valueChanges.subscribe({
      next: this.updateAuthValues.bind(this),
    });

    this.propertyChangeSubscription.add(
      this.userService.currentDigitalProperty$
        .pipe(
          distinctUntilChanged(
            (prev: IDigitalPropertyListItem | null, next: IDigitalPropertyListItem | null): boolean =>
              prev?.[$digitalProperty._id] === next?.[$digitalProperty._id],
          ),
        )
        .subscribe(this.onDigitalPropertyChanged.bind(this)),
    );
  }

  public ngOnDestroy(): void {
    this.scanAuthenticationChangeSubscription.unsubscribe();
    this.propertyChangeSubscription.unsubscribe();
  }
}
