import { Component, Input } from '@angular/core';
import { Api } from '../../../../shared/constants/api';

export enum pageLoadingState {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

@Component({
  selector: 'app-page-loading-state',
  templateUrl: './page-loading-state.component.html',
  styleUrls: ['./page-loading-state.component.scss'],
})
export class PageLoadingStateComponent {
  @Input() loadingState: pageLoadingState;

  public Api: typeof Api;
  public pageLoadingState: typeof pageLoadingState;

  constructor() {
    this.pageLoadingState = pageLoadingState;
    this.Api = Api;
  }

  public reloadPage(): void {
    window.location.reload();
  }
}
