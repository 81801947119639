import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';

import { CommonControlsViewMode } from '../../constants/common-controls-view-mode';

export interface InputFieldContext {
  customMessage?: string;
  label: string;
  field: string;
  readonly?: boolean;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  fullWidthInput?: boolean;
  type?: string;
  autocomplete?: string;
  overrideErrors?: Record<string, string>;
  class: string;
  tooltip: string;
  omitTabindex?: boolean;
  saveButton?: boolean;
}

@Component({
  selector: 'app-common-input-in-place-editor',
  templateUrl: './common-input-in-place-editor.component.html',
  styleUrls: ['./common-input-in-place-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonInputInPlaceEditorComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private originalValue: string;

  @ViewChild('search') searchElement: ElementRef;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public context: InputFieldContext;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public view: CommonControlsViewMode;
  @Input()
  public toggleEdit: Subject<boolean>;

  @Output()
  public inputChanged$: EventEmitter<any>;
  @Output()
  public isEditToggled$: EventEmitter<boolean>;

  public isEdit: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.view = 'col-view';
    this.isEdit = false;
    this.subscription = new Subscription();
    this.inputChanged$ = new EventEmitter<void>();
    this.isEditToggled$ = new EventEmitter<boolean>();
    this.toggleEdit = new Subject<boolean>();
  }

  get required(): boolean {
    return typeof this.context.required === 'boolean' ? this.context.required : true;
  }

  get fullWidthInput(): boolean {
    return typeof this.context.fullWidthInput === 'boolean' ? this.context.fullWidthInput : true;
  }

  get type(): string {
    return typeof this.context.type === 'string' ? this.context.type : 'text';
  }

  get readonly(): boolean {
    return this.context.readonly === true;
  }

  @HostListener('document:keydown.escape')
  public onEscape(): void {
    if (this.isEdit) {
      this.toggleEditInput(false, true);
    }
  }

  public toggleEditInput(shouldEnableEdit: boolean, restoreValue: boolean = false): void {
    this.isEdit = shouldEnableEdit || this.form.get(this.context.field).invalid;
    this.isEditToggled$.emit(this.isEdit);
    if (shouldEnableEdit) {
      setTimeout(() => {
        // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.focus();
      }, 0);
    } else if (restoreValue) {
      this.form.get(this.context.field).setValue(this.originalValue);
    } else {
      this.inputChanged$.emit(this.form.get(this.context.field).value);
      this.originalValue = this.form.get(this.context.field).value;
    }
  }

  public onFocusOut(): void {
    if (this.context.saveButton) {
      return;
    }
    this.toggleEditInput(false);
  }

  public ngOnInit(): void {
    if (this.formValidationRequest$) {
      this.subscription.add(
        this.formValidationRequest$.subscribe(() => {
          const field: AbstractControl = this.form.get(this.context.field);
          field.markAsDirty();
          field.markAsTouched();
          this.changeDetectorRef.detectChanges();
        }),
      );
    }

    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        this.changeDetectorRef.detectChanges();
      }),
    );

    this.originalValue = this.form.get(this.context.field)?.value;

    this.subscription.add(
      this.form.get(this.context.field).valueChanges.subscribe((value: string) => {
        if (this.isEdit === false) {
          this.originalValue = value;
        }
      }),
    );

    this.subscription.add(
      this.toggleEdit.subscribe((toggled: boolean) => {
        this.toggleEditInput(toggled);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
