import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { curry } from 'lodash';
import { mergeMap } from 'rxjs/operators';

import {
  IAutomatedFlowShortItem,
  IAutomatedUserFlow,
  IAutomatedUserFlowCreateRequest,
  IAutomatedUserFlowResponse,
  IAutomatedUserFlowUpdateRequest,
  IUserFlowAction,
} from '../../../shared/interfaces/automated-user-flow.interface';
import { AutomatedUserFlowApi } from './rest/automated-user-flow.api';
import { $automatedUserFlow, AutomatedFlowType } from '../../../shared/constants/automated-user-flow';
import { CustomAsyncValidators } from './helpers/form-custom-async-validators';
import { UserPropertyService } from './user-property.service';
import { ICurrentSelectedProperty } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AutomatedUserFlowService {
  public constructor(
    private automatedUserFlowApi: AutomatedUserFlowApi,
    private userPropertyService: UserPropertyService,
  ) {}

  public createAutomatedUserFlow(
    wsId: string,
    dpId: string,
    userFlowName: string,
    userFlowType: AutomatedFlowType,
    userFlowActions: IUserFlowAction[],
  ): Observable<IAutomatedUserFlow> {
    const userFlowCreateRequest: IAutomatedUserFlowCreateRequest = {
      [$automatedUserFlow.name]: userFlowName,
      [$automatedUserFlow.flowType]: userFlowType,
      [$automatedUserFlow.actions]: userFlowActions,
    };

    return this.automatedUserFlowApi.createAutomatedUserFlow(wsId, dpId, userFlowCreateRequest);
  }

  public getAll(
    workspaceId: string,
    digitalPropertyId: string,
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
  ): Observable<IAutomatedUserFlowResponse> {
    return this.automatedUserFlowApi.getAll(workspaceId, digitalPropertyId, skip, limit, term, sortBy, direction);
  }

  public getByType(flowType: AutomatedFlowType): Observable<IAutomatedFlowShortItem[]> {
    return this.userPropertyService.currentSelectedProperty().pipe(
      mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) => {
        return this.automatedUserFlowApi.getByType(workspaceId, digitalPropertyId, flowType);
      }),
    );
  }

  public getAutomatedUserFlow(userFlowId: string, wsId: string, dpId: string): Observable<IAutomatedUserFlow> {
    return this.automatedUserFlowApi.getAutomatedUserFlow(userFlowId, wsId, dpId);
  }

  public updateAutomatedUserFlow(
    userFlowId: string,
    wsId: string,
    dpId: string,
    userFlowUpdate: IAutomatedUserFlowUpdateRequest,
  ): Observable<IAutomatedUserFlow> {
    return this.automatedUserFlowApi.updateAutomatedUserFlow(userFlowId, wsId, dpId, userFlowUpdate);
  }

  public getUserFlowRecording(userFlowId: string, wsId: string, dpId: string): Observable<IAutomatedUserFlow> {
    return this.automatedUserFlowApi.getUserFlowRecording(userFlowId, wsId, dpId);
  }

  public deleteUserFlowRecording(userFlowId: string, wsId: string, dpId: string): Observable<void> {
    return this.automatedUserFlowApi.deleteUserFlowRecording(userFlowId, wsId, dpId);
  }

  public findByName(workspaceId: string, dpId: string, name: string): Observable<IAutomatedUserFlow> {
    return this.automatedUserFlowApi.findByName(workspaceId, dpId, name);
  }

  public validateNameUniqueness(
    workspaceId: string,
    dpId: string,
    nameToSkip: () => string = (): string => '',
  ): (control: AbstractControl) => Observable<ValidationErrors | null> {
    return CustomAsyncValidators.validateNameUniqueness(nameToSkip, curry(this.findByName)(workspaceId, dpId).bind(this));
  }
}
