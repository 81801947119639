import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  OnDestroy,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { IModal } from '../components/modals/modal.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { CommonUtility } from '../utility/common.utility';

const APP_CONTAINER_ELEMENT_ID: string = 'app-container';
const MODAL_CONTAINER_ID: string = 'modalContainer';

@Injectable({ providedIn: 'root' })
export class ModalService implements OnDestroy {
  private subscription: Subscription;
  private rootViewContainerRef: ViewContainerRef;
  private modalContainerRef: ComponentRef<IModal>;
  private previouslyActiveElement: Element;
  private appContainer: Element;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.subscription = new Subscription();
  }

  /**
   * The modal must be closed before doing anything that requires moving focus outside the modal container.
   * Focus-trap actively prevents focus from moving out the area it has set up. Deactive will return the
   * tabbing order and allow focus elsewhere.
   *
   * @private
   */
  private clearAfterModalClosed(): void {
    CommonUtility.deactivateFocusTrap();

    (this.previouslyActiveElement as HTMLElement).focus();

    this.modalContainerRef = undefined;
    this.previouslyActiveElement = undefined;

    if (SharedCommonUtility.notNullish(this.appContainer)) {
      this.appContainer.removeAttribute('aria-hidden');
    }
  }

  private createComponent<T>(componentType: Type<T>): ComponentRef<T> {
    const factory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(componentType);
    return factory.create(this.rootViewContainerRef.parentInjector);
  }

  private openModal(focusElement?: HTMLElement): void {
    if (SharedCommonUtility.notNullish(focusElement)) {
      this.previouslyActiveElement = focusElement;
    } else {
      this.previouslyActiveElement = window.document.activeElement;
    }

    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }

    // timeout is needed for those rare cases when modal opening triggers underlying content rerendering
    setTimeout(() => {
      this.appContainer = document.getElementById(APP_CONTAINER_ELEMENT_ID);
      this.appContainer.setAttribute('aria-hidden', 'true');

      const modal: HTMLElement = document.getElementById(MODAL_CONTAINER_ID);
      CommonUtility.activateFocusTrap(modal, { initialFocus: false, allowOutsideClick: true });
    }, 50);

    this.subscription.add(this.modalContainerRef.instance.container.onClose$.subscribe(this.closeModal.bind(this)));
    this.modalContainerRef.onDestroy(this.clearAfterModalClosed.bind(this));

    this.rootViewContainerRef.insert(this.modalContainerRef.hostView);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public closeModal(): void {
    if (typeof this.modalContainerRef !== 'undefined') {
      this.modalContainerRef.destroy();
    }
  }

  public setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.rootViewContainerRef = viewContainerRef;
  }

  public open<T extends IModal>(component: Type<T>, focusElement?: HTMLElement): ComponentRef<T> {
    this.modalContainerRef = this.createComponent(component);

    this.openModal(focusElement);

    return this.modalContainerRef as ComponentRef<T>;
  }

  public isModalOpen(): boolean {
    return SharedCommonUtility.notNullish(this.modalContainerRef);
  }
}
