import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { finalize, tap, catchError } from 'rxjs/operators';

import { BusyIndicatorService } from './busy-indicator.service';

@Injectable()
export class BusyIndicatorInterceptor implements HttpInterceptor {
  constructor(private busyIndicatorService: BusyIndicatorService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleBusyIndicator = (evt: HttpEvent<any>): void => {
      if (evt instanceof HttpResponse) {
        this.busyIndicatorService.closeIndicator();
      } else {
        this.busyIndicatorService.openIndicator();
      }
    };

    const handleBusyIndicatorForError = (err: any, caught: Observable<HttpEvent<any>>): ObservableInput<any> => {
      if (err instanceof HttpErrorResponse) {
        this.busyIndicatorService.closeIndicator();
      }

      return throwError(err);
    };

    return next
      .handle(request)
      .pipe(
        tap(handleBusyIndicator),
        catchError(handleBusyIndicatorForError),
        finalize(this.busyIndicatorService.closeIndicator.bind(this.busyIndicatorService)),
      );
  }
}
