import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { customHeaders } from '../../../../shared/constants/custom-headers';
import { ServerVersionService } from '../server-version.service';

@Injectable()
export class ServerVersionInterceptor implements HttpInterceptor {
  constructor(private versionService: ServerVersionService) {}

  private handleApplicationVersion(response: HttpResponse<any>): void {
    if (response.headers?.has(customHeaders.x_app_version)) {
      const serverVersion: string = response.headers.get(customHeaders.x_app_version);
      if (this.versionService.version === null || this.versionService.version !== serverVersion) {
        this.versionService.version = serverVersion;
      }
    }
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((response: HttpResponse<any>) => this.handleApplicationVersion(response)));
  }
}
