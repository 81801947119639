export enum FunctionalAreaSetting {
  monitoringPagesLimit = 'monitoringPagesLimit',
  pdfPagesLimit = 'pdfPagesLimit',
  academySeatsLimit = 'academySeatsLimit',
  digitalPropertyLimit = 'digitalPropertyLimit',
  monitoringPagesLimitWithFF = 'monitoringPagesLimitWithFF',
  userWayWebsiteLimit = 'userWayWebsiteLimit',
  digitalPropertyAutomatedFlowLimit = 'digitalPropertyAutomatedFlowLimit',
}

export enum $package {
  _id = '_id',
  name = 'name',
  settings = 'settings',
  roleIds = 'roleIds',
  roles = 'roles',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum $packageInstance {
  _id = '_id',
  tenantId = 'tenantId',
  packageId = 'packageId',
  packageDetails = 'packageDetails',
  originalPackage = 'originalPackage',
  packageAddonIds = 'packageAddonIds',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum $packageAddon {
  _id = '_id',
  name = 'name',
  description = 'description',
  roleIds = 'roleIds',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum PackageNames {
  Accelerate = 'Accelerate',
  AcceleratePlus = 'Accelerate+ (with UserWay)',
  Audit = 'Audit',
  Enterprise = 'Enterprise',
  EnterprisePlus = 'Enterprise+ (with UserWay)',
  Essentials = 'Essentials',
  EssentialsPlus = 'Essentials+ (with UserWay)',
  LegacyEA = 'Legacy EA',
}

export const PLUS_PACKAGE_NAMES: string[] = [
  PackageNames.AcceleratePlus,
  PackageNames.EssentialsPlus,
  PackageNames.EnterprisePlus,
];
