import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';
import { Section508Version } from '../../../interfaces/audit-rule.interface';

const section508: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: 'Without Vision',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.1',
    [$successCriteria.title]: '302.1 Without Vision',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'With Limited Vision',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.2',
    [$successCriteria.title]: '302.2 With Limited Vision',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Without Perception of Color',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.3',
    [$successCriteria.title]: '302.3 Without Perception of Color',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Without Hearing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.4',
    [$successCriteria.title]: '302.4 Without Hearing',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'With Limited Hearing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.5',
    [$successCriteria.title]: '302.5 With Limited Hearing',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Without Speech',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.6',
    [$successCriteria.title]: '302.6 Without Speech',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'With Limited Manipulation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.7',
    [$successCriteria.title]: '302.7 With Limited Manipulation',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'With Limited Reach and Strength',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.8',
    [$successCriteria.title]: '302.8 With Limited Reach and Strength',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'With Limited Language, Cognitive, and Learning Abilities',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '302.9',
    [$successCriteria.title]: '302.9 With Limited Language, Cognitive, and Learning Abilities',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text Content',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.1.1',
    [$successCriteria.title]: '1.1.1 Non-text Content',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio-only and Video-only (Prerecorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.2.1',
    [$successCriteria.title]: '1.2.1 Audio-only and Video-only (Prerecorded)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions (Prerecorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.2.2',
    [$successCriteria.title]: '1.2.2 Captions (Prerecorded)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio Description or Media Alternative (Prerecorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.2.3',
    [$successCriteria.title]: '1.2.3 Audio Description or Media Alternative (Prerecorded)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Info and Relationships',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.3.1',
    [$successCriteria.title]: '1.3.1 Info and Relationships',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Meaningful Sequence',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.3.2',
    [$successCriteria.title]: '1.3.2 Meaningful Sequence',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Sensory Characteristics',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.3.3',
    [$successCriteria.title]: '1.3.3 Sensory Characteristics',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Use of Color',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.4.1',
    [$successCriteria.title]: '1.4.1 Use of Color',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio Control',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.4.2',
    [$successCriteria.title]: '1.4.2 Audio Control',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Keyboard',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.1.1',
    [$successCriteria.title]: '2.1.1 Keyboard',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'No Keyboard Trap',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.1.2',
    [$successCriteria.title]: '2.1.2 No Keyboard Trap',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Timing Adjustable',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.2.1',
    [$successCriteria.title]: '2.2.1 Timing Adjustable',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pause, Stop, Hide',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.2.2',
    [$successCriteria.title]: '2.2.2 Pause, Stop, Hide',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Three Flashes or Below Threshold',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.3.1',
    [$successCriteria.title]: '2.3.1 Three Flashes or Below Threshold',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Bypass Blocks',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.4.1',
    [$successCriteria.title]: '2.4.1 Bypass Blocks',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Page Titled',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.4.2',
    [$successCriteria.title]: '2.4.2 Page Titled',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus Order',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.4.3',
    [$successCriteria.title]: '2.4.3 Focus Order',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Link Purpose (In Context)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.4.4',
    [$successCriteria.title]: '2.4.4 Link Purpose (In Context)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of Page',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.1.1',
    [$successCriteria.title]: '3.1.1 Language of Page',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On Focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.2.1',
    [$successCriteria.title]: '3.2.1 On Focus',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On Input',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.2.2',
    [$successCriteria.title]: '3.2.2 On Input',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error Identification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.3.1',
    [$successCriteria.title]: '3.3.1 Error Identification',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Labels or Instructions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.3.2',
    [$successCriteria.title]: '3.3.2 Labels or Instructions',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Parsing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.1.1',
    [$successCriteria.title]: '4.1.1 Parsing',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Name, Role, Value',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.1.2',
    [$successCriteria.title]: '4.1.2 Name, Role, Value',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions (Live)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.2.4',
    [$successCriteria.title]: '1.2.4 Captions (Live)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio Description (Prerecorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.2.5',
    [$successCriteria.title]: '1.2.5 Audio Description (Prerecorded)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Contrast (Minimum)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.4.3',
    [$successCriteria.title]: '1.4.3 Contrast (Minimum)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Resize Text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.4.4',
    [$successCriteria.title]: '1.4.4 Resize Text',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Images of Text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '1.4.5',
    [$successCriteria.title]: '1.4.5 Images of Text',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Multiple Ways',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.4.5',
    [$successCriteria.title]: '2.4.5 Multiple Ways',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Headings and Labels',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.4.6',
    [$successCriteria.title]: '2.4.6 Headings and Labels',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus Visible',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '2.4.7',
    [$successCriteria.title]: '2.4.7 Focus Visible',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of Parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.1.2',
    [$successCriteria.title]: '3.1.2 Language of Parts',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Consistent Navigation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.2.3',
    [$successCriteria.title]: '3.2.3 Consistent Navigation',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Consistent Identification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.2.4',
    [$successCriteria.title]: '3.2.4 Consistent Identification',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error Suggestion',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.3.3',
    [$successCriteria.title]: '3.3.3 Error Suggestion',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error Prevention (Legal, Financial, Data)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '3.3.4',
    [$successCriteria.title]: '3.3.4 Error Prevention (Legal, Financial, Data)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 1 Conformance Level',
    [$successCriteria.level]: '',
    [$successCriteria.num]: 'CR 1 Conformance Level',
    [$successCriteria.title]: 'Conformance Requirement 1 Conformance Level',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 2 Full pages',
    [$successCriteria.level]: '',
    [$successCriteria.num]: 'CR 2 Full pages',
    [$successCriteria.title]: 'Conformance Requirement 2 Full pages',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 3 Complete processes',
    [$successCriteria.level]: '',
    [$successCriteria.num]: 'CR 3 Complete processes',
    [$successCriteria.title]: 'Conformance Requirement 3 Complete processes',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 4 Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.level]: '',
    [$successCriteria.num]: 'CR 4 Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.title]: 'Conformance Requirement 4 Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 5 Non-Interference',
    [$successCriteria.level]: '',
    [$successCriteria.num]: 'CR 5 Non-Interference',
    [$successCriteria.title]: 'Conformance Requirement 5 Non-Interference',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Closed Functionality',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402',
    [$successCriteria.title]: '402 Closed Functionality',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.1',
    [$successCriteria.title]: '402.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech-Output Enabled',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.2',
    [$successCriteria.title]: '402.2 Speech-Output Enabled',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Information Displayed On-Screen',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.2.1',
    [$successCriteria.title]: '402.2.1 Information Displayed On-Screen',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Transactional Outputs',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.2.2',
    [$successCriteria.title]: '402.2.2 Transactional Outputs',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech Delivery Type and Coordination',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.2.3',
    [$successCriteria.title]: '402.2.3 Speech Delivery Type and Coordination',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User Control',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.2.4',
    [$successCriteria.title]: '402.2.4 User Control',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Braille Instructions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.2.5',
    [$successCriteria.title]: '402.2.5 Braille Instructions',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Volume',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.3',
    [$successCriteria.title]: '402.3 Volume',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Private Listening',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.3.1',
    [$successCriteria.title]: '402.3.1 Private Listening',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-private Listening',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.3.2',
    [$successCriteria.title]: '402.3.2 Non-private Listening',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Characters on Display Screens',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.4',
    [$successCriteria.title]: '402.4 Characters on Display Screens',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Characters on Variable Message Signs',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '402.5',
    [$successCriteria.title]: '402.5 Characters on Variable Message Signs',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Biometrics',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '403',
    [$successCriteria.title]: '403 Biometrics',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '403.1',
    [$successCriteria.title]: '403.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Preservation of Information Provided for Accessibility',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '404',
    [$successCriteria.title]: '404 Preservation of Information Provided for Accessibility',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '404.1',
    [$successCriteria.title]: '404.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Privacy',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '405',
    [$successCriteria.title]: '405 Privacy',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '405.1',
    [$successCriteria.title]: '405.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Standard Connections',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '406',
    [$successCriteria.title]: '406 Standard Connections',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '406.1',
    [$successCriteria.title]: '406.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Operable Parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407',
    [$successCriteria.title]: '407 Operable Parts',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Contrast',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.2',
    [$successCriteria.title]: '407.2 Contrast',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Input Controls',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.3',
    [$successCriteria.title]: '407.3 Input Controls',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Tactilely Discernible',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.3.1',
    [$successCriteria.title]: '407.3.1 Tactilely Discernible',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Alphabetic Keys',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.3.2',
    [$successCriteria.title]: '407.3.2 Alphabetic Keys',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Numeric Keys',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.3.3',
    [$successCriteria.title]: '407.3.3 Numeric Keys',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Key Repeat',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.4',
    [$successCriteria.title]: '407.4 Key Repeat',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Timed Response',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.5',
    [$successCriteria.title]: '407.5 Timed Response',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Operation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.6',
    [$successCriteria.title]: '407.6 Operation',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Tickets, Fare Cards, and Keycards',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.7',
    [$successCriteria.title]: '407.7 Tickets, Fare Cards, and Keycards',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Reach Height and Depth',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8',
    [$successCriteria.title]: '407.8 Reach Height and Depth',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Vertical Reference Plane',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.1',
    [$successCriteria.title]: '407.8.1 Vertical Reference Plane',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Vertical Plane for Side Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.1.1',
    [$successCriteria.title]: '407.8.1.1 Vertical Plane for Side Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Vertical Plane for Forward Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.1.2',
    [$successCriteria.title]: '407.8.1.2 Vertical Plane for Forward Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Side Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.2',
    [$successCriteria.title]: '407.8.2 Side Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Unobstructed Side Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.2.1',
    [$successCriteria.title]: '407.8.2.1 Unobstructed Side Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Obstructed Side Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.2.2',
    [$successCriteria.title]: '407.8.2.2 Obstructed Side Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Forward Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.3',
    [$successCriteria.title]: '407.8.3 Forward Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Unobstructed Forward Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.3.1',
    [$successCriteria.title]: '407.8.3.1 Unobstructed Forward Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Obstructed Forward Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.3.2',
    [$successCriteria.title]: '407.8.3.2 Obstructed Forward Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Operable Part Height for ICT with Obstructed Forward Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.3.2.1',
    [$successCriteria.title]: '407.8.3.2.1 Operable Part Height for ICT with Obstructed Forward Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Knee and Toe Space under ICT with Obstructed Forward Reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '407.8.3.2.2',
    [$successCriteria.title]: '407.8.3.2.2 Knee and Toe Space under ICT with Obstructed Forward Reach',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Display Screens',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '408',
    [$successCriteria.title]: '408 Display Screens',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Visibility',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '408.2',
    [$successCriteria.title]: '408.2 Visibility',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Flashing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '408.3',
    [$successCriteria.title]: '408.3 Flashing',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Status Indicators',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '409',
    [$successCriteria.title]: '409 Status Indicators',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '409.1',
    [$successCriteria.title]: '409.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Color Coding',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '410',
    [$successCriteria.title]: '410 Color Coding',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '410.1',
    [$successCriteria.title]: '410.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audible Signals',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '411',
    [$successCriteria.title]: '411 Audible Signals',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '411.1',
    [$successCriteria.title]: '411.1 General',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'ICT with Two-Way Voice Communication',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412',
    [$successCriteria.title]: '412 ICT with Two-Way Voice Communication',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Volume Gain',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.2',
    [$successCriteria.title]: '412.2 Volume Gain',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Volume Gain for Wireline Telephones',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.2.1',
    [$successCriteria.title]: '412.2.1 Volume Gain for Wireline Telephones',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Volume Gain for Non-Wireline ICT',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.2.2',
    [$successCriteria.title]: '412.2.2 Volume Gain for Non-Wireline ICT',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Interference Reduction and Magnetic Coupling',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.3',
    [$successCriteria.title]: '412.3 Interference Reduction and Magnetic Coupling',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Wireless Handsets',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.3.1',
    [$successCriteria.title]: '412.3.1 Wireless Handsets',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Wireline Handsets',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.3.2',
    [$successCriteria.title]: '412.3.2 Wireline Handsets',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Digital Encoding of Speech',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.4',
    [$successCriteria.title]: '412.4 Digital Encoding of Speech',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Real-Time Text Functionality',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.5',
    [$successCriteria.title]: '412.5 Real-Time Text Functionality',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Caller ID',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.6',
    [$successCriteria.title]: '412.6 Caller ID',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Video Communication',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.7',
    [$successCriteria.title]: '412.7 Video Communication',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Legacy TTY Support',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.8',
    [$successCriteria.title]: '412.8 Legacy TTY Support',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'TTY Connectability',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.8.1',
    [$successCriteria.title]: '412.8.1 TTY Connectability',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Voice and Hearing Carry Over',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.8.2',
    [$successCriteria.title]: '412.8.2 Voice and Hearing Carry Over',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Signal Compatibility',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.8.3',
    [$successCriteria.title]: '412.8.3 Signal Compatibility',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Voice Mail and Other Messaging Systems',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '412.8.4',
    [$successCriteria.title]: '412.8.4 Voice Mail and Other Messaging Systems',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Closed Caption Processing Technologies',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '413',
    [$successCriteria.title]: '413 Closed Caption Processing Technologies',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Decoding and Display of Closed Captions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '413.1.1',
    [$successCriteria.title]: '413.1.1 Decoding and Display of Closed Captions',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pass-Through of Closed Caption Data',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '413.1.2',
    [$successCriteria.title]: '413.1.2 Pass-Through of Closed Caption Data',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio Description Processing Technologies',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '414',
    [$successCriteria.title]: '414 Audio Description Processing Technologies',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Digital Television Tuners',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '414.1.1',
    [$successCriteria.title]: '414.1.1 Digital Television Tuners',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Other ICT',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '414.1.2',
    [$successCriteria.title]: '414.1.2 Other ICT',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User Controls for Captions and Audio Descriptions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '415',
    [$successCriteria.title]: '415 User Controls for Captions and Audio Descriptions',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Caption Controls',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '415.1.1',
    [$successCriteria.title]: '415.1.1 Caption Controls',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio Description Controls',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '415.1.2',
    [$successCriteria.title]: '415.1.2 Audio Description Controls',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Scope – Incorporation of WCAG 2.0 AA',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '501.1',
    [$successCriteria.title]: '501.1 Scope – Incorporation of WCAG 2.0 AA',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Interoperability with Assistive Technology',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502',
    [$successCriteria.title]: '502 Interoperability with Assistive Technology',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User Control of Accessibility Features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.2.1',
    [$successCriteria.title]: '502.2.1 User Control of Accessibility Features',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'No Disruption of Accessibility Features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.2.2',
    [$successCriteria.title]: '502.2.2 No Disruption of Accessibility Features',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Accessibility Services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3',
    [$successCriteria.title]: '502.3 Accessibility Services',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Object Information',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.1',
    [$successCriteria.title]: '502.3.1 Object Information',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Modification of Object Information',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.2',
    [$successCriteria.title]: '502.3.2 Modification of Object Information',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Row, Column, and Headers',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.3',
    [$successCriteria.title]: '502.3.3 Row, Column, and Headers',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Values',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.4',
    [$successCriteria.title]: '502.3.4 Values',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Modification of Values',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.5',
    [$successCriteria.title]: '502.3.5 Modification of Values',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Label Relationships',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.6',
    [$successCriteria.title]: '502.3.6 Label Relationships',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Hierarchical Relationships',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.7',
    [$successCriteria.title]: '502.3.7 Hierarchical Relationships',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.8',
    [$successCriteria.title]: '502.3.8 Text',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Modification of Text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.9',
    [$successCriteria.title]: '502.3.9 Modification of Text',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'List of Actions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.10',
    [$successCriteria.title]: '502.3.10 List of Actions',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Actions on Objects',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.11',
    [$successCriteria.title]: '502.3.11 Actions on Objects',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus Cursor',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.12',
    [$successCriteria.title]: '502.3.12 Focus Cursor',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Modification of Focus Cursor',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.13',
    [$successCriteria.title]: '502.3.13 Modification of Focus Cursor',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Event Notification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.3.14',
    [$successCriteria.title]: '502.3.14 Event Notification',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Platform Accessibility Features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '502.4',
    [$successCriteria.title]: '502.4 Platform Accessibility Features',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Applications',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '503',
    [$successCriteria.title]: '503 Applications',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User Preferences',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '503.2',
    [$successCriteria.title]: '503.2 User Preferences',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Alternative User Interfaces',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '503.3',
    [$successCriteria.title]: '503.3 Alternative User Interfaces',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User Controls for Captions and Audio Description',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '503.4',
    [$successCriteria.title]: '503.4 User Controls for Captions and Audio Description',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Caption Controls',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '503.4.1',
    [$successCriteria.title]: '503.4.1 Caption Controls',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio Description Controls',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '503.4.2',
    [$successCriteria.title]: '503.4.2 Audio Description Controls',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Authoring Tools',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '504',
    [$successCriteria.title]: '504 Authoring Tools',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Content Creation or Editing (if not authoring tool, enter “not applicable”)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '504.2',
    [$successCriteria.title]: '504.2 Content Creation or Editing (if not authoring tool, enter “not applicable”)',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Preservation of Information Provided for Accessibility in Format Conversion',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '504.2.1',
    [$successCriteria.title]: '504.2.1 Preservation of Information Provided for Accessibility in Format Conversion',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'PDF Export',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '504.2.2',
    [$successCriteria.title]: '504.2.2 PDF Export',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Prompts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '504.3',
    [$successCriteria.title]: '504.3 Prompts',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Templates',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '504.4',
    [$successCriteria.title]: '504.4 Templates',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Scope',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '601.1',
    [$successCriteria.title]: '601.1 Scope',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Support Documentation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '602',
    [$successCriteria.title]: '602 Support Documentation',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Accessibility and Compatibility Features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '602.2',
    [$successCriteria.title]: '602.2 Accessibility and Compatibility Features',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Electronic Support Documentation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '602.3',
    [$successCriteria.title]: '602.3 Electronic Support Documentation',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Alternate Formats for Non-Electronic Support Documentation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '602.4',
    [$successCriteria.title]: '602.4 Alternate Formats for Non-Electronic Support Documentation',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Support Services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '603',
    [$successCriteria.title]: '603 Support Services',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Information on Accessibility and Compatibility Features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '603.2',
    [$successCriteria.title]: '603.2 Information on Accessibility and Compatibility Features',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Accommodation of Communication Needs',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '603.3',
    [$successCriteria.title]: '603.3 Accommodation of Communication Needs',
    [$successCriteria.versions]: [Section508Version.v2018],
    [$successCriteria.url]: '',
  },
];

export class Section508 implements ISuccessCriteriaIndex {
  public get(num: string): ISuccessCriteria {
    const section508SuccessCriteria: ISuccessCriteria | undefined = section508.find(
      (bestPracticeSuccessCriteria: ISuccessCriteria): boolean => bestPracticeSuccessCriteria.num === num,
    );
    if (typeof section508SuccessCriteria === 'undefined') {
      return null;
    }
    return section508SuccessCriteria;
  }

  public getAll(): ISuccessCriteria[] {
    return section508;
  }
}
