import { TemplateRef, Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface ITemplateCellConfig extends ICellConfig {
  template: TemplateRef<any>;
  templateContext?: any;
}

@Component({
  selector: 'template-cell-text',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <ng-container #ref [ngTemplateOutlet]="config.template" [ngTemplateOutletContext]="config.templateContext"></ng-container>
  `,
})
export class TemplateCellComponent extends BaseCellComponent {
  @Input() public config: ITemplateCellConfig;
}
