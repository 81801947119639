<div [formGroup]="form">
  <app-common-checkbox
    [(ngModel)]="showControls"
    (ngModelChange)="onShowControlsChange($event)"
    [ngModelOptions]="{ standalone: true }"
  >
    <span class="checkbox-label"> {{ 'exclude_pages' | translate }}</span>
    <span class="checkbox-description"> {{ 'exclude_pages_description' | translate }}</span>
  </app-common-checkbox>

  <div [formArrayName]="field" *ngIf="showControls" class="ms-4 mt-2">
    <div *ngFor="let control of fieldValues; let index = index; let first = first" class="row parameter">
      <app-common-select
        class="d-block margin-bottom col"
        [form]="control"
        [values]="operations"
        [formValidationRequest$]="formValidationRequest$"
        [context]="{
          field: 'operation',
          nameKey: 'key',
          valueKey: 'value',
        }"
      >
      </app-common-select>
      <app-common-input
        [form]="control"
        [context]="{
          field: 'value',
          required: false,
          noLabel: true,
        }"
        [formValidationRequest$]="formValidationRequest$"
        class="col"
      >
      </app-common-input>
      <div class="col">
        <button (click)="removeControl(index)" class="btn btn-link margin-bottom fw-bold">
          <svg aria-hidden="true" focusable="false" viewBox="0 0 8 8" class="delete-icon">
            <use xlink:href="#trash"></use>
          </svg>
          <span class="me-2">{{ 'remove_action' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button (click)="addControl()" [disabled]="isMaxParam" class="btn btn-link fw-bold shadow-none add">
          <svg aria-hidden="true" focusable="false" viewBox="0 0 8 8" class="plus-icon">
            <use xlink:href="#plus"></use>
          </svg>
          <span class="me-2">{{ 'add_parameter' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
