<div class="d-flex">
  <div class="info-box">
    <div class="d-flex justify-content-center align-items-center">
      <ng-container [ngSwitch]="scanViewport.viewportType">
        <svg *ngSwitchCase="viewportType.mobile" width="17" height="32" fill="none" aria-hidden="true">
          <use xlink:href="#mobile-viewport-icon"></use>
        </svg>
        <svg *ngSwitchCase="viewportType.tablet" width="27" height="38" fill="none" aria-hidden="true">
          <use xlink:href="#tablet-viewport-icon"></use>
        </svg>
        <svg *ngSwitchCase="viewportType.desktop" width="42" height="36" fill="none" aria-hidden="true">
          <use xlink:href="#desktop-viewport-icon"></use>
        </svg>
      </ng-container>
      <div class="ps-2 me-3">
        <div>
          <span class="fw-bold">{{ 'scan_viewport_' + scanViewport.viewportType | translate }}</span>
        </div>
        <div>
          <span class="small fw-bold">{{ getViewportLabel(scanViewport) }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
