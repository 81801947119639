import {
  ApplicationRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { clone, cloneDeep, find, isUndefined, sortBy } from 'lodash';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, first, map, shareReplay } from 'rxjs/operators';
import { IBoundingBox } from '../../../../shared/interfaces/design-review.interface';
import { DEBOUNCE_TIME } from '../../shared/constants';

import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import {
  getFindingNumberBox,
  getActionBox,
  highlightNumberCornerRadius,
  highlightNumberDistanceFromHighlight,
  highlightNumberRectStrokeWidth,
  highlightNumberSize,
  inlineFocusOffset,
} from '../../../../shared/utils/image-highlighter.utility';
import {
  BoxPositions,
  HighlightModes,
  ImageHighlightModes,
  ImageHighlightStates,
  MovementDirection,
  MovementSpeed,
  EventKeys,
} from '../../constants/image-highlight.constants';
import {
  ICoordinates,
  IHighlight,
  IHighlightCopy,
  IHighlightDisplayOptions,
  IHighlightFinding,
  IImageScale,
  IImageSize,
  IPoint,
  ISpatialChange,
} from '../../interfaces/image-highlight.interface';
import { ModalService } from '../../services/modal.service';
import { $boundingBox } from '../../../../shared/constants/design-review';
import { HighlightImgHelper } from './highlight-img.helper';
import { HighlightMouseHelper } from './highlight-mouse.helper';
import { HighlightActionsHelper } from './highlight-actions.helper';
import { HighlightCopyPasteHelper } from './highlight-copy-paste.helper';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '../../translate/translate.service';
import { Icons } from '../../shared/eap-icons.component';

enum tabbablePopoverItems {
  link = 'popover-link',
  deleteButton = 'popover-delete',
}

@Component({
  selector: 'app-image-highlighter',
  templateUrl: './image-highlighter.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./image-highlighter.component.scss'],
})
export class ImageHighlighterComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private isMouseDown: boolean;
  private newHighlightCoordinates: ICoordinates;
  private imageEditingState: ImageHighlightStates;
  private componentReady$: Observable<boolean>;
  private absoluteMousePosition: IPoint = { x: 0, y: 0 };
  private _imgHelper: HighlightImgHelper;
  private _mouseHelper: HighlightMouseHelper;
  private _actionsHelper: HighlightActionsHelper;

  @ViewChild('highlightRects')
  private highlightRects: ElementRef<SVGElement>;

  @ViewChild('img')
  public imageElement: ElementRef<HTMLImageElement>;

  public imageLoaded$: EventEmitter<boolean>;
  public originalBoxes: IHighlight<any>[] = [];
  public imageHighlightModes: typeof ImageHighlightModes = ImageHighlightModes;
  public highlightModes: typeof HighlightModes = HighlightModes;
  public highlights: IHighlight<any>[] = [];
  public findingsLength: number = 0;
  public newBox: IHighlight<any>;
  public selectedBox: IHighlight<any>;
  public selectedFinding: IHighlightFinding<any>;
  public boxRelativeDistanceToMouseInitialPosition: IPoint;
  public boxInitialPositionAndSizeBeforeResize: IHighlight<any>;
  public boxResizingFromPosition: BoxPositions;
  public $imageHighlightModes: typeof ImageHighlightModes;
  public focusSettingEventEmitter: EventEmitter<void>;
  public tabbablePopoverItems: typeof tabbablePopoverItems = tabbablePopoverItems;
  public MovementDirection: typeof MovementDirection;
  public MovementSpeed: typeof MovementSpeed;
  public Icons: typeof Icons;

  @Input()
  public startingIndex: number = 0;

  @Input()
  public screenshotPath$: Observable<string> = of('');
  @Input()
  public originalBoxes$: Observable<IHighlight<any>[]> = of([]);
  @Input()
  public findingToFocus$: Observable<IHighlightFinding<any>> = of(null);

  @Input()
  public mode: ImageHighlightModes;

  @Input()
  public showPopoverButtonTray: boolean;

  @Input()
  public showPopoverEditButton: boolean;

  @Input()
  public showPopoverRemoveButton: boolean;

  @Output()
  public onHighlightCreated: EventEmitter<IHighlight<any>> = new EventEmitter();

  @Output()
  public onHighlightEdited: EventEmitter<IHighlight<any>> = new EventEmitter<IHighlight<any>>();

  @Output()
  public onHighlightSelected: EventEmitter<IHighlight<any>> = new EventEmitter<IHighlight<any>>();

  @Output()
  public onFindingSelected: EventEmitter<IHighlightFinding<any>> = new EventEmitter<IHighlightFinding<any>>();

  @Output()
  public onFindingEditButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onFindingDeleteButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onCopyHighlight: EventEmitter<IHighlightCopy> = new EventEmitter();

  @Output()
  public onOriginalWidthSet: ReplaySubject<number> = new ReplaySubject<number>();

  @Output()
  public onAddToHighlight: EventEmitter<IHighlight<any>> = new EventEmitter<IHighlight<any>>();

  /**
   * used to signalize to the parent component, that the highlighted rectangle has changed in position or size,
   * requesting that the parent scrolls the viewport by the specific amount if applicable
   *
   * WHY this is used? the element.scrollToView method works fine for vertical scrolling, but fails for horizontal, that's
   * why we have to use this weird emitter, so that the parent can do the horizontal scroll manually
   */
  @Output()
  public onSpatialChange: EventEmitter<ISpatialChange> = new EventEmitter<ISpatialChange>();

  public highlightDisplayOptions: IHighlightDisplayOptions = {
    resizeRectSize: 8,
    outlineBorderOffset: 4,
    highlightNumberSize,
    highlightNumberDistanceFromHighlight,
    highlightNumberRectStrokeWidth,
    highlightNumberCornerRadius,
    inlineFocusOffset,
  };
  private otherDirection: MovementDirection;
  private copyPasteHelper: HighlightCopyPasteHelper;

  constructor(
    private modalService: ModalService,
    private applicationRef: ApplicationRef,
    notificationService: NotificationService,
    protected translateService: TranslateService,
  ) {
    this.focusSettingEventEmitter = new EventEmitter<void>();
    this.imageEditingState = ImageHighlightStates.noop;
    this.subscription = new Subscription();
    this.imageLoaded$ = new EventEmitter<boolean>();
    this.$imageHighlightModes = ImageHighlightModes;
    this.MovementDirection = MovementDirection;
    this.MovementSpeed = MovementSpeed;
    this.copyPasteHelper = new HighlightCopyPasteHelper(notificationService, translateService);
    this.Icons = Icons;
  }

  public get imgHelper(): HighlightImgHelper {
    if (!this._imgHelper) {
      this._imgHelper = new HighlightImgHelper(this.imageElement.nativeElement);
    }

    return this._imgHelper;
  }

  public get mouseHelper(): HighlightMouseHelper {
    if (!this._mouseHelper) {
      this._mouseHelper = new HighlightMouseHelper(this.highlightDisplayOptions);
    }

    return this._mouseHelper;
  }

  public get actionsHelper(): HighlightActionsHelper {
    if (!this._actionsHelper) {
      this._actionsHelper = new HighlightActionsHelper();
    }

    return this._actionsHelper;
  }

  private getReverseScaleBoundingBox(box: IHighlight<any>): IHighlight<any> {
    const scale: IImageScale = this.imgHelper.getImageScale();
    return {
      ...box,
      height: box.height * (1 / scale.heightCoefficient),
      width: box.width * (1 / scale.widthCoefficient),
      x: box.x * (1 / scale.widthCoefficient),
      y: box.y * (1 / scale.heightCoefficient),
    };
  }

  private scaleBoundingBoxes(boxes: IHighlight<any>[], scale: IImageScale): IHighlight<any>[] {
    return boxes.map((box: IHighlight<any>): IHighlight<any> => {
      return {
        ...box,
        height: box.height * scale.heightCoefficient,
        width: box.width * scale.widthCoefficient,
        x: box.x * scale.widthCoefficient,
        y: box.y * scale.heightCoefficient,
      };
    });
  }

  private setNumberRectAndIndex(lastFindingIndex: number, highlight: IHighlight<any>): number {
    const imgSize: IImageSize = this.imgHelper.getImageSize();
    let _lastFindingIndex: number = lastFindingIndex;
    let intermediaryIndex: number;
    highlight.index = _lastFindingIndex;
    this.initializeHighlightAction(highlight);

    highlight.findings.forEach((finding: IHighlightFinding<any>, findingIndex: number) => {
      intermediaryIndex = findingIndex;
      finding.numberBox = getFindingNumberBox(highlight, highlight.findings.length, findingIndex, imgSize.actual.width);
      finding.index = _lastFindingIndex++;
    });

    highlight.action.box = getActionBox(highlight, highlight.findings.length, intermediaryIndex, imgSize.actual.width);

    return _lastFindingIndex;
  }

  /**
   * Initializes highlight action attribute, so that it can be set
   *
   * @param highlight
   * @private
   */
  private initializeHighlightAction(highlight: IHighlight<any>): void {
    if (isUndefined(highlight.action)) {
      highlight.action = {
        box: {
          [$boundingBox.x]: 0,
          [$boundingBox.y]: 0,
          [$boundingBox.height]: 0,
          [$boundingBox.width]: 0,
        },
        isSelected: false,
        isFocused: false,
      };
    }
  }

  private setNumberRectAndIndexToHighlights(highlights: IHighlight<any>[]): void {
    highlights.reduce(this.setNumberRectAndIndex.bind(this), this.startingIndex);
  }

  private sortByTopLeftPosition(boxes: IHighlight<any>[]): IHighlight<any>[] {
    return sortBy(boxes, [(box: IHighlight<any>): number => box.y, (box: IHighlight<any>): number => box.x]);
  }

  private getHighlightsWithViewMode(boxes: IHighlight<any>[]): IHighlight<any>[] {
    return boxes.map(
      (box: IHighlight<any>): IHighlight<any> => ({
        ...box,
        isSelected: box._id === this.selectedBox?._id,
        mode: box._id === this.selectedBox?._id ? this.selectedBox.mode : HighlightModes.view,
      }),
    );
  }

  private getAdjustedDrawnCoordinates(): ICoordinates {
    const clonedCoordinates: ICoordinates = clone(this.newHighlightCoordinates);

    if (clonedCoordinates.x2 < clonedCoordinates.x1) {
      [clonedCoordinates.x2, clonedCoordinates.x1] = [clonedCoordinates.x1, clonedCoordinates.x2];
    }

    if (clonedCoordinates.y2 < clonedCoordinates.y1) {
      [clonedCoordinates.y1, clonedCoordinates.y2] = [clonedCoordinates.y2, clonedCoordinates.y1];
    }

    return clonedCoordinates;
  }

  private updateBoxCoordinates(box: IHighlight<any>, currentMouseCoordinates: IPoint): void {
    box.x = currentMouseCoordinates.x;
    box.y = currentMouseCoordinates.y;
    this.setNumberRectAndIndex(box.index, box);
  }

  private updateBoxSize(box: IBoundingBox, width: number, height: number): void {
    box.width = width;
    box.height = height;
  }

  private updateNewBoxCoordinates(currentMouseCoordinates: IPoint): void {
    const { x, y } = currentMouseCoordinates;
    if (SharedCommonUtility.isNullish(this.newHighlightCoordinates)) {
      return;
    }

    this.newHighlightCoordinates.x2 = x;
    this.newHighlightCoordinates.y2 = y;

    const coordinates: ICoordinates = this.getAdjustedDrawnCoordinates();
    this.newBox.x = coordinates.x1;
    this.newBox.y = coordinates.y1;
    this.newBox.width = coordinates.x2 - coordinates.x1;
    this.newBox.height = coordinates.y2 - coordinates.y1;
  }

  private onAddStart({ x, y }: IPoint): void {
    this.imageEditingState = ImageHighlightStates.adding;

    this.newHighlightCoordinates = {
      x1: x,
      y1: y,
      x2: x,
      y2: y,
    };

    const box: IBoundingBox = {
      x: this.newHighlightCoordinates.x1,
      y: this.newHighlightCoordinates.y1,
      width: 0,
      height: 0,
    };

    this.newBox = {
      ...box,
      _id: null,
      isSelected: null,
      data: null,
      mode: HighlightModes.view,
    };
  }

  private onStartMovingHighlight(point: IPoint): void {
    this.imageEditingState = ImageHighlightStates.moving;
    this.boxRelativeDistanceToMouseInitialPosition = {
      x: point.x - this.selectedBox.x,
      y: point.y - this.selectedBox.y,
    };
  }

  private onStartResizingHighlight(point: IPoint): void {
    this.imageEditingState = ImageHighlightStates.resizing;

    this.boxInitialPositionAndSizeBeforeResize = clone<IHighlight<any>>(this.selectedBox);
  }

  private resizeSelectedBoxBasedOnMousePoint(currentMousePoint: IPoint): void {
    const resizedBox: IBoundingBox = this.mouseHelper.getResizedBoxBasedOnMousePoint(
      currentMousePoint,
      this.selectedBox,
      this.boxResizingFromPosition,
      this.boxInitialPositionAndSizeBeforeResize,
    );

    if (this.imgHelper.isBoxValid(resizedBox)) {
      this.updateBoxSize(this.selectedBox, resizedBox[$boundingBox.width], resizedBox[$boundingBox.height]);
      this.updateBoxCoordinates(this.selectedBox, { x: resizedBox[$boundingBox.x], y: resizedBox[$boundingBox.y] });
    }
  }

  private handleMouseMoveOnEdit(currentMousePoint: IPoint): void {
    if (this.imageEditingState === ImageHighlightStates.moving) {
      const point: IPoint = this.imgHelper.getMouseAllowedCoordinates(
        currentMousePoint,
        this.boxRelativeDistanceToMouseInitialPosition,
        this.selectedBox,
      );

      this.updateBoxCoordinates(this.selectedBox, point);
    }

    if (this.imageEditingState === ImageHighlightStates.resizing) {
      this.resizeSelectedBoxBasedOnMousePoint(currentMousePoint);
    }
  }

  private get isEditingImage(): boolean {
    return this.imageEditingState === ImageHighlightStates.moving || this.imageEditingState === ImageHighlightStates.resizing;
  }

  private handleMouseMoveUpEdit(currentMousePoint: IPoint): void {
    if (this.isEditingImage) {
      const rescaledBox: IHighlight<any> = this.getReverseScaleBoundingBox(this.selectedBox);
      this.onHighlightEdited.emit(rescaledBox);
      this.onHighlightSelected.next(rescaledBox);
      this.selectedFinding = null;
      this.onFindingSelected.next(null);
    }

    this.boxRelativeDistanceToMouseInitialPosition = null;
    this.imageEditingState = ImageHighlightStates.noop;
  }

  private handleMouseMoveOnAdd(currentMouseCoordinates: IPoint, copyHighlightId?: string, findingId?: string): void {
    this.updateNewBoxCoordinates(currentMouseCoordinates);
    if (this.imgHelper.isBoxValid(this.newBox)) {
      this.newBox = this.getReverseScaleBoundingBox(this.newBox);
      this.newBox.mode = HighlightModes.view;
      if (copyHighlightId) {
        this.onCopyHighlight.emit({ ...this.newBox, highlightId: copyHighlightId, findingId: findingId });
      } else {
        this.onHighlightCreated.emit(this.newBox);
      }
      this.selectHighlight(this.newBox);
    }

    this.redraw();
    this.newBox = null;
    this.imageEditingState = ImageHighlightStates.noop;
  }

  private unselectAllHighlightsExcept(selectedHighlight: IHighlight<any>): void {
    this.highlights.forEach((highlight: IHighlight<any>) => {
      if (selectedHighlight?._id === highlight._id) {
        return;
      }
      highlight.isSelected = false;
      highlight.isFocused = false;
      highlight.mode = HighlightModes.view;
      highlight.isAction = false;
    });
  }

  private unselectAllFindings(): void {
    this.highlights.forEach((highlight: IHighlight<any>) => {
      highlight.findings.forEach((finding: IHighlightFinding<any>) => {
        finding.isSelected = false;
        finding.isFocused = false;
        finding.highlight.mode = HighlightModes.view;
        finding.highlight.isSelected = false;
      });
    });
  }

  private unselectAllFindingsExcept(selectedFinding: IHighlightFinding<any>): void {
    this.highlights.forEach((highlight: IHighlight<any>) => {
      highlight.findings.forEach((finding: IHighlightFinding<any>) => {
        if (selectedFinding?._id === finding._id) {
          return;
        }
        finding.isSelected = false;
        finding.isFocused = false;
        finding.highlight.mode = HighlightModes.view;
        finding.highlight.isSelected = false;
      });
    });
  }

  private getHighlightClickableRect(highlight: IHighlight<any>): SVGRectElement {
    return this.highlightRects.nativeElement.querySelector(`g[id='${highlight._id}'] rect.clickable`);
  }

  private getFindingClickableRect(finding: IHighlightFinding<any>): SVGRectElement {
    return this.highlightRects.nativeElement.querySelector(`g[id='${finding._id}'] rect.clickable`);
  }

  private selectFinding(finding: IHighlightFinding<any>): void {
    const clickableRect: SVGRectElement = this.getFindingClickableRect(finding);
    clickableRect?.dispatchEvent(new Event('click'));
    clickableRect?.focus();
  }

  public focusOnFinding(finding: IHighlightFinding<any>): void {
    const clickableRect: SVGRectElement = this.getFindingClickableRect(finding);
    clickableRect?.focus();
  }

  private subscribeToHighlightFocus(): void {
    const focusSubscription = combineLatest([this.componentReady$.pipe(delay(0)), this.findingToFocus$])
      .pipe(
        filter(([isComponentReady, highlight]: [boolean, IHighlightFinding<any>]) => {
          return isComponentReady && SharedCommonUtility.notNullish(highlight);
        }),
        map(([_, finding]: [boolean, IHighlightFinding<any>]) => finding),
      )
      .subscribe({
        next: (finding: IHighlightFinding<any>) => {
          this.selectFinding(finding);
        },
      });

    this.subscription.add(focusSubscription);
  }

  private createComponentReadyObservable(): void {
    this.componentReady$ = combineLatest([this.imageLoaded$, this.screenshotPath$]).pipe(
      filter(([isImageLoaded, screenshotPath]: [boolean, string]) => {
        return isImageLoaded === true && SharedCommonUtility.notNullishOrEmpty(screenshotPath);
      }),
      debounceTime(DEBOUNCE_TIME),
      map(([]: [boolean, string]) => true),
      shareReplay(1),
      first(),
    );
  }

  public onFindingMouseDown(event: MouseEvent, finding: IHighlightFinding<any>): void {
    this.isMouseDown = true;
  }

  private addHighlight(highlightId?: string, findingId?: string): void {
    this.imageEditingState = ImageHighlightStates.adding;
    const box: IBoundingBox = this.imgHelper.computePossibleBoundingBoxFromPoint(this.absoluteMousePosition);

    this.selectedBox = null;
    this.onHighlightSelected.next(null);
    this.onFindingSelected.next(null);
    this.newHighlightCoordinates = { x1: box.x, y1: box.y, x2: box.x, y2: box.y };
    this.newBox = { ...box, _id: null, isSelected: null, data: null, mode: HighlightModes.view };
    this.handleMouseMoveOnAdd({ x: box.x + box.width, y: box.y + box.height }, highlightId, findingId);
  }

  private scrollToElement(element: Element): void {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }

  private selectHighlight(highlight: IHighlight<any>): void {
    if (SharedCommonUtility.notNullish(highlight) && !highlight.isAction) {
      highlight.isAction = true;
    }

    this.selectedBox = highlight;
    this.selectedBox.mode = HighlightModes.edit;
    this.selectedBox.isSelected = true;
    this.unselectAllHighlightsExcept(this.selectedBox);
    // by now the highlight isn't drawn on screen yet, that's we need to wait for next tick to focus it
    this.applicationRef.tick();
    this.getHighlightClickableRect(highlight)?.focus();
    this.selectedBox.isFocused = true;
    this.onHighlightSelected.next(this.selectedBox);
  }

  public get tooltipPlacement(): string {
    return window.innerWidth > 500 ? 'auto' : 'center';
  }

  public setFindingsLength(highlights: IHighlight<any>[]): void {
    if (!highlights) {
      return;
    }

    this.findingsLength = 0;

    highlights.forEach((highlight: IHighlight<any>) => {
      this.findingsLength += highlight.findings?.length;
    });
  }

  public onHighlightMouseDown(event: MouseEvent, highlight: IHighlight<any>): void {
    this.isMouseDown = true;
    if (this.mode === ImageHighlightModes.view) {
      return;
    }

    // specifically handling the case, when already editing a highlight (especially with keyboard) and user selects
    // a different highlight -> should save the previous highlight, deselect it and then select the new one
    if (this.isEditingImage && highlight._id !== this.selectedBox._id) {
      this.finishEditingSelectedBox();
    }

    if (this.canEdit(highlight)) {
      this.selectHighlight(highlight);

      const currentMouseCoordinates: IPoint = this.mouseHelper.getCoordinatesFromMouseEvent(event);

      const boxPosition: BoxPositions | null = this.mouseHelper.getBoxPosition(currentMouseCoordinates, this.selectedBox);
      if (boxPosition !== null) {
        this.boxResizingFromPosition = boxPosition;
        this.onStartResizingHighlight(currentMouseCoordinates);
        return;
      }

      const shouldMove: boolean = this.mouseHelper.isMouseInsideHighlightBounds(this.selectedBox, currentMouseCoordinates);

      if (shouldMove) {
        this.onStartMovingHighlight(currentMouseCoordinates);
      }
    }
  }

  public onMouseDown(event: MouseEvent): void {
    if (this.mode === ImageHighlightModes.view) {
      return;
    }

    const currentMouseCoordinates: IPoint = this.mouseHelper.getCoordinatesFromMouseEvent(event);

    if (this.mode === ImageHighlightModes.add) {
      this.selectedBox = null;
      this.onHighlightSelected.next(null);
      this.onFindingSelected.next(null);
      this.onAddStart(currentMouseCoordinates);
      this.isMouseDown = true;
      return;
    }
  }

  public onMouseMove(event: MouseEvent): void {
    if (this.isMouseDown === false) {
      return;
    }

    if (this.mode === ImageHighlightModes.view) {
      return;
    }

    const currentMouseCoordinates: IPoint = this.mouseHelper.getCoordinatesFromMouseEvent(event);

    if (this.mode === ImageHighlightModes.add) {
      this.updateNewBoxCoordinates(currentMouseCoordinates);
      return;
    }

    if (this.mode === ImageHighlightModes.edit) {
      this.handleMouseMoveOnEdit(currentMouseCoordinates);
    }
  }

  public onMouseUp(event: MouseEvent): void {
    if (!this.isMouseDown) {
      return;
    }

    setTimeout(() => {
      // this is needed to prevent setting `isMouseDown` to `false`
      // until all the logic behind the `mouseDown` event is finished
      this.isMouseDown = false;
    }, 0);

    if (this.mode === ImageHighlightModes.view) {
      return;
    }

    const currentMouseCoordinates: IPoint = this.mouseHelper.getCoordinatesFromMouseEvent(event);

    if (this.mode === ImageHighlightModes.add) {
      this.handleMouseMoveOnAdd(currentMouseCoordinates);
    }

    if (this.mode === ImageHighlightModes.edit) {
      this.handleMouseMoveUpEdit(currentMouseCoordinates);
    }
  }

  public onMouseLeave(event: MouseEvent): void {
    this.isMouseDown = false;
    this.newHighlightCoordinates = null;
    this.newBox = null;
    this.boxRelativeDistanceToMouseInitialPosition = null;
  }

  public resetOtherDirection(eventKey: string): void {
    // "Meta" is the "command" button on Mac. When resizing, the last pressed eventKey on Mac is always "Meta"
    if (eventKey === this.otherDirection || eventKey === EventKeys.Meta) {
      this.otherDirection = null;
    }
  }

  public setOtherDirection(eventKey: string): void {
    if (this.otherDirection || !MovementDirection[eventKey]) {
      return;
    }

    this.otherDirection = eventKey as MovementDirection;
  }

  public onKeyboardMove(
    event: KeyboardEvent,
    highlight: IHighlight<any>,
    direction: MovementDirection,
    speed: MovementSpeed,
  ): void {
    if (highlight.mode !== HighlightModes.edit) {
      return;
    }
    event.preventDefault();

    const combinedDirection: MovementDirection = this.actionsHelper.getCombinedDirection(direction, this.otherDirection);

    const movedHighlight: IHighlight<any> = this.actionsHelper.moveHighlight(highlight, combinedDirection, speed);
    const point: IPoint = this.imgHelper.getKeyboardAllowedCoordinates(movedHighlight, highlight);

    this.onSpatialChange.emit({
      point: {
        x: point.x - highlight.x,
        y: point.y - highlight.y,
      },
      element: this.getHighlightClickableRect(highlight),
    });

    this.selectHighlight(highlight);
    this.updateBoxCoordinates(this.selectedBox, {
      x: point.x,
      y: point.y,
    });
    this.onHighlightSelected.next(this.selectedBox);
    this.imageEditingState = ImageHighlightStates.moving;
  }

  public onKeyboardResize(
    event: KeyboardEvent,
    highlight: IHighlight<any>,
    direction: MovementDirection,
    speed: MovementSpeed,
  ): void {
    if (highlight.mode !== HighlightModes.edit) {
      return;
    }
    event.preventDefault();

    const combinedDirection: MovementDirection = this.actionsHelper.getCombinedDirection(direction, this.otherDirection);

    const resizedHighlight: IHighlight<any> = this.actionsHelper.resizeHighlight(highlight, combinedDirection, speed);
    if (!this.imgHelper.isBoxValid(resizedHighlight)) {
      return;
    }

    this.onSpatialChange.emit({
      point: {
        x: resizedHighlight[$boundingBox.width] - highlight[$boundingBox.width],
        y: resizedHighlight[$boundingBox.height] - highlight[$boundingBox.height],
      },
      element: this.getHighlightClickableRect(highlight),
    });

    this.selectHighlight(highlight);
    this.updateBoxSize(this.selectedBox, resizedHighlight.width, resizedHighlight.height);
    this.updateBoxCoordinates(this.selectedBox, resizedHighlight);
    this.onHighlightSelected.next(this.selectedBox);
    this.imageEditingState = ImageHighlightStates.resizing;
  }

  public saveSelectedHighlight(): void {
    if (SharedCommonUtility.isNullish(this.selectedBox) || this.mode === ImageHighlightModes.view) {
      return;
    }
    const rescaledBoundingBox: IHighlight<any> = this.getReverseScaleBoundingBox(this.selectedBox);
    this.onHighlightEdited.emit(rescaledBoundingBox);
  }

  public copySelectedHighlight(): void {
    if (this.mode === ImageHighlightModes.view) {
      return;
    }
    this.copyPasteHelper.copy(this.selectedBox);
  }

  public pasteSelectedHighlight(): void {
    if (this.mode === ImageHighlightModes.view) {
      return;
    }
    this.copyPasteHelper.paste((highlightId: string, findingId: string) => this.addHighlight(highlightId, findingId));
  }

  public copyFinding(finding: IHighlightFinding<any>): void {
    if (this.mode === ImageHighlightModes.view) {
      return;
    }
    this.copyPasteHelper.copy(finding.highlight, finding);
  }

  public pasteFinding(event: any): void {
    if (this.modalService.isModalOpen()) {
      return;
    }

    event?.preventDefault();

    if (this.mode === ImageHighlightModes.view) {
      return;
    }
    this.copyPasteHelper.paste((highlightId: string, findingId: string) => this.addHighlight(highlightId, findingId));
  }

  private finishEditingSelectedBox(): void {
    this.selectedBox = this.getReverseScaleBoundingBox(this.selectedBox);
    this.selectedBox.mode = HighlightModes.view;
    this.onHighlightSelected.next(null);
    this.onHighlightEdited.emit(this.selectedBox);
    this.selectedBox = null;
    this.imageEditingState = ImageHighlightStates.noop;
  }

  public handleHighlightKeyEnterEvent(event: Event, highlight: IHighlight<any>): void {
    event.preventDefault();
    this.triggerHighlightSelected(highlight);
  }

  public triggerHighlightSelected(highlight: IHighlight<any>): void {
    if (this.mode === ImageHighlightModes.add) {
      return;
    }

    // if already in edit mode -> 'enter' key or selecting a different highlight means "save current changes"
    if (this.isEditingImage) {
      this.finishEditingSelectedBox();
      return;
    }

    if (this.canEdit(highlight)) {
      highlight.mode = HighlightModes.edit;
    }

    this.selectedBox = highlight;
    this.unselectAllHighlightsExcept(highlight);

    this.onHighlightSelected.next(this.getReverseScaleBoundingBox(highlight));
  }

  public handleFindingKeyEnterEvent(event: Event, finding: IHighlightFinding<any>, popover: NgbPopover): void {
    event.preventDefault();
    this.triggerFindingSelected(finding, popover);
  }

  public triggerFindingSelected(finding: IHighlightFinding<any>, popover: NgbPopover): void {
    if (this.mode === ImageHighlightModes.add) {
      return;
    }

    if (popover.isOpen()) {
      popover.close();
      finding.isFocused = true;
      finding.isSelected = true;
    } else {
      popover.open();
      finding.isFocused = false;
      finding.isSelected = true;
    }

    this.selectedFinding = finding;
    if (this.canEdit(finding.highlight)) {
      finding.highlight.isSelected = true;
      finding.highlight.mode = HighlightModes.edit;
    }

    this.unselectAllFindingsExcept(finding);

    this.onFindingSelected.next(finding);
    this.onHighlightSelected.next(finding.highlight);
  }

  public selectInput(): void {
    this.focusSettingEventEmitter.emit();
  }

  /**
   * Checks if passed in highlight is the action highlight
   *
   * @param highlight Highlight to check
   */
  public isActionHighlight(highlight: IHighlight<any>): boolean {
    return highlight.isAction;
  }

  public onHighlightBlur(highlight: IHighlight<any>): void {
    highlight.isFocused = false;
  }

  public onFindingBlur(finding: IHighlightFinding<any>): void {
    finding.isFocused = false;
  }

  public onFindingReverseTab(finding: IHighlightFinding<any>, highlight: IHighlight<any>): void {
    if (this.isFirstFindingInHighlight(finding)) {
      highlight.isAction = false;
    }
  }

  /**
   * Checks if provided {finding} is the first finding for the highlight
   *
   * @param finding
   * @private
   */
  private isFirstFindingInHighlight(finding: IHighlightFinding<any>): boolean {
    const firstFinding = finding.highlight.findings[0];
    return firstFinding._id === finding._id;
  }

  public onHighlightDeselect(highlight: IHighlight<any>): void {
    highlight.isSelected = false;
    highlight.isFocused = true;
    highlight.mode = HighlightModes.view;
    this.selectedBox = null;
    this.onHighlightSelected.next(null);
  }

  public onFindingPopoverEscape(event: KeyboardEvent, finding: IHighlightFinding<any>, popover: NgbPopover): void {
    if (popover.isOpen()) {
      event.preventDefault();
      event.stopImmediatePropagation();
      popover.close();
      finding.isSelected = false;
      finding.isFocused = true;
      this.selectedFinding = null;

      this.unselectAllHighlightsExcept(finding.highlight);
      this.unselectAllFindings();

      this.focusOnFinding(finding);
    }
  }

  public onFindingDeselect(event: KeyboardEvent, finding: IHighlightFinding<any>, highlight: IHighlight<any>): void {
    if ((event.target as SVGRectElement) === this.getFindingClickableRect(finding)) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if (finding.isSelected) {
        finding.isSelected = false;
      }
    }
  }

  public onPopoverDeleteButtonClick(): void {
    this.onFindingDeleteButtonClicked.emit();
  }

  public onPopoverEditButtonClick(): void {
    this.onFindingEditButtonClicked.emit();
  }

  public onImageLoaded(): void {
    this.imageLoaded$.next(true);
  }

  public draw(): void {
    const boxesWithViewMode: IHighlight<any>[] = this.getHighlightsWithViewMode(this.originalBoxes);
    const scaledBoxes: IHighlight<any>[] = this.scaleBoundingBoxes(boxesWithViewMode, this.imgHelper.getImageScale());
    this.highlights = this.sortByTopLeftPosition(scaledBoxes);
    this.setFindingsLength(this.highlights);
    this.setNumberRectAndIndexToHighlights(this.highlights);
    this.unselectAllHighlightsExcept(this.selectedBox);
  }

  public redraw(): void {
    if (SharedCommonUtility.isNullish(this.imageElement)) {
      return;
    }

    this.draw();
  }

  public getResizeBoxLeftX(box: IHighlight<any>): number {
    return box.x - this.highlightDisplayOptions.resizeRectSize / 2;
  }

  public getResizeBoxRightX(box: IHighlight<any>): number {
    return box.x + box.width - this.highlightDisplayOptions.resizeRectSize / 2;
  }

  public getResizeBoxTopY(box: IHighlight<any>): number {
    return box.y - this.highlightDisplayOptions.resizeRectSize / 2;
  }

  public getResizeBoxBottomY(box: IHighlight<any>): number {
    return box.y + box.height - this.highlightDisplayOptions.resizeRectSize / 2;
  }

  public close(popover: NgbPopover): void {
    popover.close();
  }

  public canEdit(highlight: IHighlight<any>): boolean {
    return this.mode === ImageHighlightModes.edit && highlight.editable === true;
  }

  public highlightTrackBy(index: number, item: IHighlight<any>): string {
    return item?._id;
  }

  public findingTrackBy(index: number, item: IHighlightFinding<any>): string {
    return item?._id;
  }

  public getScreenLabel(highlights: IHighlight<any>[], findingsLength: number): string {
    let textKey: string;

    if (highlights.length === 1) {
      textKey =
        findingsLength === 1 ? 'screen_with_single_highlight_and_single_finding' : 'screen_with_single_highlight_and_n_findings';
    } else {
      textKey = findingsLength === 1 ? 'screen_with_n_highlights_and_single_finding' : 'screen_with_n_highlights_and_n_findings';
    }

    return this.translateService.instant(textKey, [highlights.length, findingsLength]);
  }

  public onHighlightFocused(event: FocusEvent, highlight: IHighlight<any>): void {
    highlight.isFocused = true;
    highlight.action.isSelected = false;

    if (!highlight.isAction) {
      highlight.isAction = true;
    }

    if (this.isMouseDown === true) {
      return;
    }

    this.scrollToElement(event.target as SVGRectElement);
  }

  public onFindingFocused(event: Event, finding: IHighlightFinding<any>, highlight: IHighlight<any>): void {
    finding.isFocused = true;

    if (!highlight.isAction) {
      highlight.isAction = true;
    }

    if (this.isMouseDown === true) {
      return;
    }

    this.unselectAllHighlightsExcept(highlight);
    this.unselectAllFindingsExcept(finding);

    this.scrollToElement(event.target as SVGRectElement);
  }

  public subscribeToEmitOriginalWidth(): void {
    this.subscription.add(
      this.componentReady$.subscribe({
        next: () => {
          const imageSize: IImageSize = this.imgHelper.getImageSize();
          this.onOriginalWidthSet.next(imageSize.original.width);
        },
      }),
    );
  }

  private subscribeToHighlightChanges(): void {
    this.subscription.add(
      combineLatest([this.imageLoaded$, this.originalBoxes$])
        .pipe(
          filter(([isImageLoaded, originalBoxes]: [boolean, IHighlight<any>[]]) => {
            return isImageLoaded === true;
          }),
          map(([_, originalBoxes]: [boolean, IHighlight<any>[]]) => [originalBoxes]),
        )
        .subscribe({
          next: ([originalBoxes]: [IHighlight<any>[]]) => {
            this.originalBoxes = cloneDeep(originalBoxes);
            this.redraw();

            const newlyAddedBox: IHighlight<any> | null = find(originalBoxes, 'newlyAdded');
            if (SharedCommonUtility.notNullish(newlyAddedBox)) {
              this.selectHighlight(find(this.highlights, { _id: newlyAddedBox._id }));
            }
          },
        }),
    );
  }

  public updateAbsoluteMousePosition(event: MouseEvent): void {
    this.absoluteMousePosition = {
      x: event.pageX,
      y: event.pageY,
    };
  }

  private isInputElementActive(): boolean {
    const tagName: string = document.activeElement.tagName.toLowerCase();
    return tagName === 'input' || tagName === 'textarea';
  }

  @HostListener('document:keydown.N')
  public addHighlightHotkey(): void {
    if (this.mode !== ImageHighlightModes.add || this.isInputElementActive() || this.modalService.isModalOpen()) {
      return;
    }
    this.addHighlight();
  }

  public ngOnInit(): void {
    if (SharedCommonUtility.isNullish(this.mode)) {
      this.mode = ImageHighlightModes.edit;
    }

    this.createComponentReadyObservable();
    this.subscribeToHighlightChanges();
    this.subscribeToHighlightFocus();
    this.subscribeToEmitOriginalWidth();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public triggerAction(event: any, highlight: IHighlight<any>): void {
    if (this.canEdit(highlight)) {
      highlight.action.isSelected = true;
      highlight.mode = HighlightModes.edit;
    }

    this.unselectAllFindings();

    this.onHighlightSelected.next(highlight);
    this.onAddToHighlight.emit();
  }

  public handleActionKeyEnterEvent(event: any, highlight: IHighlight<any>): void {
    highlight.action.isSelected = true;
    event.preventDefault();
    this.triggerAction(event, highlight);
  }

  public onActionBlur(highlight: IHighlight<any>): void {
    highlight.action.isFocused = false;
    highlight.action.isSelected = false;
    highlight.isAction = false;
  }

  public onActionFocused(event: FocusEvent, highlight: IHighlight<any>): void {
    highlight.action.isFocused = true;

    this.scrollToElement(event.target as SVGRectElement);
  }

  /**
   * Checks is highlight add action selected.
   * Used for display purposes.
   *
   * @param highlight
   */
  public actionSelected(highlight: IHighlight<any>): boolean {
    return highlight.action.isSelected;
  }

  /**
   * Checking reverse tabbing from highlight box
   *
   * @param highlight
   */
  public onHighlightReverseTab(highlight: IHighlight<any>): void {
    highlight.isAction = false;
  }
}
