import { Component, Input } from '@angular/core';
import { $severity } from '../../../../shared/constants/accessibility';

@Component({
  selector: 'app-rule-severity',
  templateUrl: './rule-severity.component.html',
})
export class RuleSeverityComponent {
  @Input() public severity: string;

  public $severity: typeof $severity;
  constructor() {
    this.$severity = $severity;
  }
}
