import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ZendeskStatus } from '../../../shared/constants/zendesk-status';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private isApplicationReady: BehaviorSubject<boolean>;
  private areTranslationsReady: BehaviorSubject<boolean>;
  private zendeskStatus: BehaviorSubject<ZendeskStatus>;

  public $isApplicationReady: Observable<boolean>;
  public $areTranslationsReady: Observable<boolean>;
  public $zendeskStatus: Observable<ZendeskStatus>;

  constructor() {
    this.isApplicationReady = new BehaviorSubject(false);
    this.$isApplicationReady = this.isApplicationReady.asObservable();

    this.areTranslationsReady = new BehaviorSubject(false);
    this.$areTranslationsReady = this.areTranslationsReady.asObservable();

    this.zendeskStatus = new BehaviorSubject(ZendeskStatus.pending);
    this.$zendeskStatus = this.zendeskStatus.asObservable();
  }

  public setApplicationReadyStatus(status: boolean): void {
    this.isApplicationReady.next(status);
  }

  public setAreTranslationsReady(status: boolean): void {
    this.areTranslationsReady.next(status);
  }

  public getAreTranslationsReady(): boolean {
    return this.areTranslationsReady.getValue();
  }

  public setZendeskStatus(status: ZendeskStatus): void {
    this.zendeskStatus.next(status);
  }

  public getZendeskStatus(): ZendeskStatus {
    return this.zendeskStatus.getValue();
  }
}
