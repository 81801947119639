import { $sortingOrder } from '../constants/sort';
import { $severity } from '../constants/accessibility';
import { SharedCommonUtility } from './common.utility';

export class SharedSortUtility {
  public static sortNumbersFunction(sortOrder: $sortingOrder): any {
    if (sortOrder === $sortingOrder.asc) {
      return (a: number, b: number): number => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      };
    }

    return (a: number, b: number): number => {
      if (a < b) {
        return 1;
      }
      if (a > b) {
        return -1;
      }
      return 0;
    };
  }

  public static functionSortObjectsByStringProperty(
    sortOrder: $sortingOrder,
    property: string,
    collationOptions?: Intl.CollatorOptions,
  ): any {
    let a: string;
    let b: string;

    if (sortOrder === $sortingOrder.asc) {
      return (objA: Record<string, any>, objB: Record<string, any>): number => {
        a = typeof objA[property] === 'string' ? objA[property] : '';
        b = typeof objB[property] === 'string' ? objB[property] : '';

        return a.localeCompare(b, undefined, collationOptions);
      };
    }

    return (objA: Record<string, any>, objB: Record<string, any>): number => {
      a = typeof objA[property] === 'string' ? objA[property] : '';
      b = typeof objB[property] === 'string' ? objB[property] : '';

      return b.localeCompare(a, undefined, collationOptions);
    };
  }

  public static functionSortObjectsByNumberProperty(sortOrder: $sortingOrder, property: string): any {
    let a: number;
    let b: number;

    if (sortOrder === $sortingOrder.asc) {
      return (objA: Record<string, any>, objB: Record<string, any>): number => {
        a = typeof objA[property] === 'number' ? objA[property] : 0;
        b = typeof objB[property] === 'number' ? objB[property] : 0;

        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }

        return 0;
      };
    }

    return (objA: Record<string, any>, objB: Record<string, any>): number => {
      a = typeof objA[property] === 'number' ? objA[property] : 0;
      b = typeof objB[property] === 'number' ? objB[property] : 0;

      if (a < b) {
        return 1;
      }
      if (a > b) {
        return -1;
      }

      return 0;
    };
  }

  public static functionSortObjectBySeverity(sortOrder: $sortingOrder, property: string = 'severity'): any {
    let a: $severity;
    let b: $severity;
    let aOrder: number;
    let bOrder: number;

    const severityOrder: $severity[] = [$severity.info, $severity.low, $severity.high, $severity.critical];

    if (sortOrder === $sortingOrder.asc) {
      return (objA: Record<string, any>, objB: Record<string, any>): number => {
        a = typeof objA[property] !== 'undefined' ? objA[property] : null;
        b = typeof objB[property] !== 'undefined' ? objB[property] : null;

        if (a === null || b === null) {
          return 0;
        }

        aOrder = severityOrder.indexOf(a);
        bOrder = severityOrder.indexOf(b);

        if (aOrder < bOrder) {
          return -1;
        }
        if (aOrder > bOrder) {
          return 1;
        }

        return 0;
      };
    }

    return (objA: Record<string, any>, objB: Record<string, any>): number => {
      a = typeof objA[property] !== 'undefined' ? objA[property] : null;
      b = typeof objB[property] !== 'undefined' ? objB[property] : null;

      if (a === null || b === null) {
        return 0;
      }

      aOrder = severityOrder.indexOf(a);
      bOrder = severityOrder.indexOf(b);

      if (aOrder < bOrder) {
        return 1;
      }
      if (aOrder > bOrder) {
        return -1;
      }

      return 0;
    };
  }

  public static functionSortObjectsByDateProperty<T>(sortOrder: $sortingOrder, field: keyof T): (a: T, b: T) => number {
    return (a: T, b: T): number => {
      const dateValueLeft: Date = new Date(a[field] as any);
      const dateValueRight: Date = new Date(b[field] as any);
      if (SharedCommonUtility.isDateValid(dateValueLeft) === false) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (SharedCommonUtility.isDateValid(dateValueRight) === false) {
        return sortOrder === 'asc' ? 1 : -1;
      }

      if (sortOrder === 'asc') {
        return dateValueLeft.getTime() - dateValueRight.getTime();
      }

      return dateValueRight.getTime() - dateValueLeft.getTime();
    };
  }

  public static functionSortArrayByString(
    sortOrder: $sortingOrder,
    collationOptions?: Intl.CollatorOptions,
  ): (a: string, b: string) => number {
    if (sortOrder === $sortingOrder.asc) {
      return (a: string, b: string): number => {
        return a.localeCompare(b, undefined, collationOptions);
      };
    }

    return (a: string, b: string): number => {
      return b.localeCompare(a, undefined, collationOptions);
    };
  }

  public static isSortParametersValid(sortBy: string, sortDirection: $sortingOrder): boolean {
    return (
      SharedCommonUtility.notNullishOrEmpty(sortBy) &&
      SharedCommonUtility.notNullishOrEmpty(sortDirection) &&
      sortDirection !== $sortingOrder.all
    );
  }
}
