import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { $workspace } from '../../../../../../../shared/constants/workspace';
import { IWorkspaceClient } from '../../../../../../../shared/interfaces/workspace.interface';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../../../shared/utils/linked-property.utility';
import { INavMenuItem, IWorkspacePermission, NavigationItem } from '../../../../../services/navigation/nav-structure';
import { Api } from '../../../../../../../shared/constants/api';
import { LoadErrorHandler } from '../../../../../services/load-error-handler';
import { WorkspaceService } from '../../../../../services/workspace.service';
import { NavService } from '../../../../../services/navigation/nav.service';
import { ErrorHandlerService } from '../../../../../services/error-handler.service';
import { UserService } from '../../../../../services/user.service';
import { IDigitalPropertyListItem } from '../../../../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../../../../../shared/constants/digital-properties';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { BusMessageChannels, BusMessageService } from '../../../../../services/bus-message.service';

@Component({
  selector: 'app-workspaces-select',
  templateUrl: './workspaces-select.component.html',
  styleUrls: ['./workspaces-select.component.scss'],
})
export class WorkspacesSelectComponent implements OnInit {
  protected readonly $workspace: typeof $workspace = $workspace;
  private _workspacesMenuItem: INavMenuItem;
  private _activeMenuItem: INavMenuItem;
  private _subscription: Subscription;
  private workspaceLocations: Map<string, string>;
  private workspacesLoaded$: BehaviorSubject<boolean>;

  public workspaces: IWorkspaceClient[];
  public selectedWorkspaceId: string | undefined;

  @Output() public workspaceChanged: EventEmitter<void> = new EventEmitter();

  constructor(
    private loadErrorHandler: LoadErrorHandler,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private navService: NavService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private busMessageService: BusMessageService,
  ) {
    this._subscription = new Subscription();
    this.workspaceLocations = new Map<string, string>();
    this.workspacesLoaded$ = new BehaviorSubject(false);
  }

  public getWorkSpaces(): void {
    const onGetWorkspacesError = (error: HttpErrorResponse): Observable<IWorkspaceClient[]> => {
      this.loadErrorHandler.handleError('error_while_getting_workspaces', error, WorkspacesSelectComponent.name);
      return EMPTY;
    };

    const onAuthenticationChange = (status: boolean): void => {
      if (status) {
        this._subscription.add(
          this.workspaceService
            .getActiveWorkspacesV2()
            .pipe(catchError(onGetWorkspacesError.bind(this)))
            .subscribe((workspaces: IWorkspaceClient[]): void => {
              this.workspaces = workspaces;
              this.workspacesLoaded$.next(true);
            }),
        );
      }
    };

    this._subscription.add(this.userService.isAuthenticated$.subscribe(onAuthenticationChange.bind(this)));
  }

  public getSelectedWorkspaceId(): void {
    const urlSelectedWorkspace$: Observable<ILinkedPropertyData> = this.activatedRoute.queryParams.pipe(
      map(LinkedPropertyUtility.fromLinkedPropertyQueryParam),
    );

    this._subscription.add(
      urlSelectedWorkspace$
        .pipe(
          map((linkedPropertyData: ILinkedPropertyData): string | undefined => {
            return linkedPropertyData?.[Api.workspaceId] ?? undefined;
          }),
        )
        .subscribe((workspaceId: string | undefined): void => {
          this.selectedWorkspaceId = workspaceId;
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  public selectWorkspace(workspaceId: string): void {
    this.selectedWorkspaceId = workspaceId;

    const workspace: IWorkspaceClient = this.workspaces.find(
      (workspaceClient: IWorkspaceClient): boolean => workspaceClient[$workspace._id] === workspaceId,
    );

    const propertyId: string = workspace[$workspace.digitalProperties]?.[0]?._id;

    this._subscription.add(
      this.userService
        .switchUserToDigitalProperty(workspaceId, propertyId)
        .pipe(
          switchMap(() => {
            const routePath: string = this.workspaceLocations.get(workspaceId);

            return from(
              this.router
                .navigate([routePath], {
                  queryParams: LinkedPropertyUtility.getLinkedPropertyQueryParam(propertyId, workspaceId),
                  relativeTo: this.activatedRoute,
                  queryParamsHandling: 'merge',
                })
                .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService)),
            );
          }),
        )
        .subscribe(() => {
          this.busMessageService.to(BusMessageChannels.userSwitchedToWorkspace).next();
          this.busMessageService.to(BusMessageChannels.userSwitchedToDigitalProperty).next();
        }),
    );

    this.workspaceChanged.emit();
  }

  public ngOnInit(): void {
    this._subscription.add(
      combineLatest([
        this.workspacesLoaded$,
        this.navService.getTopLevelMenuItems$([NavigationItem.workspace]),
        this.navService.activeMenuItemForWorkspaceSwitcher$(),
        this.userService.getFirstAvailableDigitalPropertyForWorkspaces(),
      ])
        .pipe(
          filter(
            ([workspacesLoaded, menuItems, activeMenuItem, workspacesMap]: [
              boolean,
              INavMenuItem[],
              INavMenuItem,
              Map<string, IDigitalPropertyListItem>,
            ]): boolean => workspacesLoaded,
          ),
          switchMap(
            ([_, menuItems, activeMenuItem, workspaceMap]: [
              boolean,
              INavMenuItem[],
              INavMenuItem,
              Map<string, IDigitalPropertyListItem>,
            ]): IWorkspacePermission[] => {
              this._workspacesMenuItem = menuItems?.[0];
              this._activeMenuItem = activeMenuItem;

              return this.workspaces.map((workspace: IWorkspaceClient): IWorkspacePermission => {
                const workspaceId: string = workspace[$workspace._id];
                const digitalPropertyId: string = workspaceMap.get(workspaceId)?.[$digitalProperty._id];
                return {
                  activeMenuItem: this._activeMenuItem,
                  workspacesMenuItem: this._workspacesMenuItem,
                  workspaceId,
                  digitalPropertyId,
                };
              });
            },
          ),
          switchMap((workspacePermission: IWorkspacePermission): Observable<IWorkspacePermission> => {
            return this.navService
              .hasAccessToMenuItemInWorkspace$(
                workspacePermission.activeMenuItem,
                workspacePermission.workspaceId,
                workspacePermission.digitalPropertyId,
              )
              .pipe(
                map((hasAccess: boolean): IWorkspacePermission => {
                  return {
                    ...workspacePermission,
                    hasAccess,
                  };
                }),
              );
          }),
        )
        .subscribe({
          next: (workspacePermission: IWorkspacePermission): void => {
            this.workspaceLocations.set(
              workspacePermission.workspaceId,
              workspacePermission.hasAccess && SharedCommonUtility.notNullish(workspacePermission.activeMenuItem)
                ? workspacePermission.activeMenuItem.routerLink
                : workspacePermission.workspacesMenuItem.routerLink,
            );
          },
        }),
    );

    this.getSelectedWorkspaceId();
    this.getWorkSpaces();
  }
}
