import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import { shortLinkType } from '../../../../shared/constants/short-link';

@Injectable({ providedIn: 'root' })
export class ShortLinkRestApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // PUT /short-links/short-link
  public generateShortLink(linkType: shortLinkType, context: any, tenantId: string): Observable<{ url: string }> {
    const url: string = this.restBuilder.create().shortLinks().shortLink().getApiUrl();
    return this.httpClient.put<{ url: string }>(url, { linkType, context, tenantId });
  }
}
