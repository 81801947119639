import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkspacesRestAPI } from './rest/workspaces.api';
import { IScanTag, IScanTagRequest, IScanTagServerResponse } from '../../../shared/interfaces/scan-tag.interface';

@Injectable({
  providedIn: 'root',
})
export class ScanTagService {
  constructor(private workspaceRestApi: WorkspacesRestAPI) {}

  public getScanTagsFromDigitalProperty(workspaceId: string, digitalPropertyId: string): Observable<IScanTagServerResponse> {
    return this.workspaceRestApi.getScanTagsFromDigitalProperty(workspaceId, digitalPropertyId);
  }

  public createScanTag(workspaceId: string, digitalPropertyId: string, scanTag: IScanTagRequest): Observable<IScanTag> {
    return this.workspaceRestApi.createScanTag(workspaceId, digitalPropertyId, scanTag);
  }

  public deleteScanTag(workspaceId: string, digitalPropertyId: string, scanTagId: string): Observable<void> {
    return this.workspaceRestApi.deleteScanTag(workspaceId, digitalPropertyId, scanTagId);
  }

  public getScanTag(workspaceId: string, digitalPropertyId: string, scanTagId: string): Observable<IScanTag> {
    return this.workspaceRestApi.getScanTag(workspaceId, digitalPropertyId, scanTagId);
  }

  public updateScanTag(
    workspaceId: string,
    digitalPropertyId: string,
    scanTagId: string,
    scanTag: IScanTagRequest,
  ): Observable<void> {
    return this.workspaceRestApi.updateScanTag(workspaceId, digitalPropertyId, scanTagId, scanTag);
  }
}
