import { Component, Input } from '@angular/core';
import { BaseCellComponent, ICellConfig, IIconConfig, ITagConfig } from '../base-cell/base-cell.component';

export interface ITextCellConfig extends ICellConfig, ITagConfig {
  icon?: IIconConfig;
  tagLabel?: string;
  tagColor?: string;
  tagTooltip?: string;
  for?: string;
}

@Component({
  selector: 'table-cell-text',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <ng-template #icon>
      <svg
        [ngClass]="config.icon.iconBeforeText ? 'me-2' : 'ms-2'"
        [attr.aria-hidden]="!config.icon.iconAriaLabelText"
        [attr.width]="config.icon.iconWidth"
        [attr.height]="config.icon.iconHeight"
        [ngStyle]="config.icon.iconStyles"
        [attr.aria-labelledby]="!!config.icon.iconAriaLabelText ? 'iconTitle' : null"
        #svgTemplate
      >
        <title *ngIf="!!config.icon.iconAriaLabelText" id="iconTitle">
          {{ config.icon.iconAriaLabelText }}
        </title>
        <use [attr.xlink:href]="'#' + config.icon.iconName"></use>
      </svg>
    </ng-template>
    <ng-container *ngIf="config.icon && config.icon.iconBeforeText">
      <ng-template [ngTemplateOutlet]="icon"></ng-template>
    </ng-container>
    <ng-container *ngIf="config.for; else span">
      <label #ref [ngStyle]="config?.styles" [for]="config.for" class="font-normal" [class.margin-right]="config.tagLabel">
        {{ config.text }}
      </label>
    </ng-container>
    <ng-template #span>
      <span #ref [ngStyle]="config?.styles" [class.margin-right]="config.tagLabel">{{ config.text }}</span>
    </ng-template>
    <ng-container *ngIf="config.icon && !config.icon.iconBeforeText">
      <ng-template [ngTemplateOutlet]="icon"></ng-template>
    </ng-container>
    <ng-container *ngIf="config.tagLabel">
      <span
        class="tag-label d-inline-block"
        data-test-id="table-cell-tag-label"
        [appTooltip]="config.tagTooltip"
        [attr.aria-label]="config.tagTooltip"
        [style.background-color]="config.tagColor"
        >{{ config.tagLabel }}</span
      >
    </ng-container>
    <div class="sub-text" *ngIf="config.subText" data-test-id="table-cell-sub-text">
      {{ config.subText }}
    </div>
  `,
})
export class TextCellComponent extends BaseCellComponent {
  @Input() public config: ITextCellConfig;
}
