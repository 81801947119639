import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, distinctUntilKeyChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { pick } from 'lodash';

import { IModal } from '../../../../../components/modals/modal.interface';
import { ModalContainerComponent } from '../../../../../components/modals/modal-container.component';
import { $task, $taskFlaw, TaskPriority, TaskSource, TaskStatus } from '../../../../../../../shared/constants/task';
import { CustomValidators } from '../../../../../services/helpers/form-custom-validators';
import { NotificationPosition } from '../../../../../models/notification.model';
import { Api, ApiQueryOption } from '../../../../../../../shared/constants/api';
import { ErrorMessageService } from '../../../../../services/error-message.service';
import { NotificationService } from '../../../../../services/notification.service';
import { ProjectService } from '../../../../../services/project.service';
import { CommonUtility } from '../../../../../utility/common.utility';
import { TranslateService } from '../../../../../translate/translate.service';
import { ITask } from '../../../../../../../shared/interfaces/task.interface';
import {
  MimeTypes,
  MimeTypeToFileExtension,
  SupportedDocumentExtensions,
  SupportedTaskAttachmentMimeType,
} from '../../../../../../../shared/constants/mime-type';
import { ITableColumn, ITableConfig, ITableRow } from '../../../../../components/table/ngb-table/utilities/ngb-table.interface';
import { NgbTableUtilities } from '../../../../../components/table/ngb-table/utilities/ngb-table.utilities';
import { IUploadClient } from '../../../../../../../shared/interfaces/uploads.interface';
import { $uploads } from '../../../../../../../shared/constants/uploads';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { config } from '../../../../../../environments/config.shared';
import { IProject } from '../../../../../../../shared/interfaces/project.interface';
import {
  IIssueIntegrationConnection,
  IIssueTrackingIssueType,
} from '../../../../../../../shared/interfaces/issue-integration-connection.interface';
import { IssueTrackingService } from '../../../../../services/issue-tracking.service';
import { IAutomatedScanIssue, IScanIssues } from '../../../../../../../shared/interfaces/scan-issues.interface';
import { $scanIssues } from '../../../../../../../shared/constants/scan-issues';
import { ReportService } from '../../../../../services/report.service';
import { AccessibilityAuditToolNames } from '../../../../../../../shared/constants/audit-tool';
import { alertType } from '../../../../../constants/alert.constants';
import { ITaskCreateRequest, ITaskEditRequest } from '../../../../../../../shared/interfaces/task.requests.interface';
import { $project } from '../../../../../../../shared/constants/project';
import { FileExtension } from '../../../../../../../shared/constants/file-extension';
import { $issueIntegrationType, AZURE_ATTACHMENT_MAX_FILESIZE } from '../../../../../../../shared/constants/issue-tracking';
import { $issueIntegrationConnection } from '../../../../../../../shared/constants/issue-tracking-connection';
import { UserAclService } from '../../../../../services/user-acl.service';
import { AclSecurityAdapter } from '../../../../../services/acl.service';
import { RequiredSecurities } from '../../../../../../../shared/constants/required-securities';
import {
  FileDragAndDropComponent,
  IFileDragAndDropContext,
} from '../../../../../components/form-field/file-upload/file-drag-and-drop/file-drag-and-drop.component';

export interface TaskPriorityOptions {
  priorityValue: string;
  name: string;
}

enum linkedFindingsTableFields {
  summary = 'summary',
}

const attachmentAcceptedTypes: string[] = [
  MimeTypes.CSV,
  MimeTypes.XLSX,
  MimeTypes.JPEG,
  MimeTypes.PNG,
  MimeTypes.XLS,
  MimeTypeToFileExtension[MimeTypes.CSV],
  MimeTypeToFileExtension[MimeTypes.XLSX],
  MimeTypeToFileExtension[MimeTypes.JPEG],
  MimeTypeToFileExtension[MimeTypes.PNG],
  MimeTypeToFileExtension[MimeTypes.XLS],
  '.jpg',
];

const MAX_NUM_OF_ATTACHMENTS = 10;
const BUFFER_FOR_SYSTEM = 255;

const ErrorFlawAttachTaskAlreadyLinkedError: string = 'ErrorFlawAttachTaskAlreadyLinked';
const ErrorCreateTaskWithDuplicatedFindingError: string = 'ErrorCreateTaskWithDuplicatedFinding';

/**
 * @deprecated to be replaced with the slideout component
 */
@Component({
  selector: 'app-upsert-task-modal',
  templateUrl: './upsert-task-modal.component.html',
  styleUrls: ['./upsert-task-modal.component.scss'],
})
export class UpsertTaskModalComponent implements IModal, OnInit, OnDestroy {
  private save$: Subject<void>;
  private subscriptions: Subscription;
  private _attachments: IUploadClient[];
  private selectedAttachments: File[];
  private linkedFindings: IAutomatedScanIssue[];
  private autoAttachedGeneratedCSV: File;
  private attachAutoGeneratedFileSubscription: Subscription;
  private static INPUT_NAME_MAX_LENGTH: number = 255;
  private static INPUT_FIELD_MAX_LENGTH: number = 10000 - BUFFER_FOR_SYSTEM;

  public availableProjects$: Observable<IProject[]>;
  public selectedProject: BehaviorSubject<IProject>;
  public form: UntypedFormGroup;
  public formValidationRequest: Subject<void>;
  public formValidationRequest$: Observable<void>;
  public disableUploadButtons$: BehaviorSubject<boolean>;
  public issueIntegrationProjectLabel$: Observable<string>;
  public selectIssueTypeLabel$: Observable<string>;
  public $task: typeof $task;
  public taskPriorityOptions: TaskPriorityOptions[];
  public onUpsert: EventEmitter<ITask>;
  public attachmentAcceptedTypes: string[];
  public maxUploadSize: number;
  public maxUploadQuantity: number;
  public linkedFindingsTableRows: ITableRow[];

  public linkedFindingsTableConfig: ITableConfig;
  public pushTaskToIssueTracking: boolean;
  public issueTrackingIssueTypes$: Observable<IIssueTrackingIssueType[]>;

  public loadingError$: Subject<boolean>;
  public canUnlinkTask: boolean;
  public alertType: typeof alertType;

  public unlinkTaskErrorMessage: string;

  @Input()
  public projectId: string;
  @Input() public workspaceId: string;
  @Input() public digitalPropertyId: string;
  @Input() public scanId: string;

  @Input() public toolName: AccessibilityAuditToolNames;
  @Input() public isEdit: boolean;

  @Input() public taskSource: TaskSource;
  @Input() public taskId: string;
  @Input() public taskName: string;
  @Input() public description: string;
  @Input() public status: string;
  @Input() public priority: string;
  @Input() public notifyChanges: boolean;

  @Input() public set attachments(attachments: IUploadClient[]) {
    this._attachments = attachments || [];
  }

  public get attachments(): IUploadClient[] {
    return this._attachments;
  }

  @Input() public auditFindings: string[];
  @Input() public designReviewFindings: string[];
  @Input() public issues: IScanIssues;

  @Input() public selectedIssues: IAutomatedScanIssue[];

  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;
  @ViewChild(FileDragAndDropComponent) public fileAttacher: FileDragAndDropComponent;

  constructor(
    private errorMessageService: ErrorMessageService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    private element: ElementRef<Element>,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private issueTrackingService: IssueTrackingService,
    private reportService: ReportService,
    private userAclService: UserAclService,
  ) {
    this.attachmentAcceptedTypes = attachmentAcceptedTypes;
    this.$task = $task;
    this.taskPriorityOptions = Object.keys(TaskPriority).map((priority: string) => {
      return { priorityValue: priority, name: `label_task_priority_${priority}` };
    });
    this.maxUploadSize = config.files.taskMaxUploadSize;
    this.maxUploadQuantity = MAX_NUM_OF_ATTACHMENTS;
    this.loadingError$ = new Subject<boolean>();
    this.formValidationRequest = new Subject<void>();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();
    this.disableUploadButtons$ = new BehaviorSubject<boolean>(false);
    this.selectedProject = new BehaviorSubject<IProject>(null);
    this.taskSource = TaskSource.automated;

    this.subscriptions = new Subscription();
    this.save$ = new Subject<void>();
    this.onUpsert = new EventEmitter<ITask>();
    this.selectedAttachments = [];
    this._attachments = [];
    this.status = TaskStatus.backlog;
    this.auditFindings = [];
    this.designReviewFindings = [];
    this.selectedIssues = [];
    this.linkedFindings = [];
    this.linkedFindingsTableConfig = this.getLinkedFindingsTableConfig();

    this.alertType = alertType;

    this.pushTaskToIssueTracking = false;

    this.form = this.setupForm();
  }

  private setupForm(): UntypedFormGroup {
    return this.formBuilder.group({
      [$task.name]: this.formBuilder.control('', [
        CustomValidators.required,
        Validators.maxLength(UpsertTaskModalComponent.INPUT_NAME_MAX_LENGTH),
      ]),
      [$task.description]: this.formBuilder.control('', [Validators.maxLength(UpsertTaskModalComponent.INPUT_FIELD_MAX_LENGTH)]),
      [$task.priority]: this.formBuilder.control(TaskPriority.medium),
      [$task.status]: this.formBuilder.control(this.translateService.instant('default_task_status')),
      [$task.attachments]: this.formBuilder.control('', { updateOn: 'submit' }),
    });
  }

  private shouldBlockUnlinkFinding(): boolean {
    return this.linkedFindings.length === 1;
  }

  private showUnlinkTaskErrorMessage(): void {
    this.canUnlinkTask = false;
    this.unlinkTaskErrorMessage = this.translateService.instant('unlink_task_must_have_at_least_one');
  }

  private getTaskLink(task: ITask): string {
    return `/${Api.projects}/${task.projectId}/${Api.tasks}/${task._id}`;
  }

  private getLinkedFindingsTableRows(): ITableRow[] {
    this.canUnlinkTask = true;

    const unlinkFinding = (entry: IAutomatedScanIssue): void => {
      if (this.shouldBlockUnlinkFinding()) {
        this.showUnlinkTaskErrorMessage();
        return;
      }
      this.linkedFindings = this.linkedFindings.filter((oldEntry: IAutomatedScanIssue) => {
        return oldEntry.rawFindingId !== entry.rawFindingId;
      });
      this.linkedFindingsTableRows = this.getLinkedFindingsTableRows();

      this.changeDetectorRef.detectChanges();
    };

    return this.linkedFindings.map((issueDetail: IAutomatedScanIssue): ITableRow => {
      return {
        data: {
          [linkedFindingsTableFields.summary]: NgbTableUtilities.issuesViewDetailsCell({
            selectedIssue: issueDetail,
            issues: this.issues,
          }),
          action: NgbTableUtilities.buttonCell({
            text: this.translateService.instant('unlink'),
            classes: ['btn-link', 'font-weight-500', 'pt-0'],
            attributes: { type: 'button' },
            listeners: {
              click: () => unlinkFinding(issueDetail),
            },
          }),
        },
      };
    });
  }

  private getLinkedFindingsTableConfig(): ITableConfig {
    const columns: Record<string, ITableColumn> = {
      [linkedFindingsTableFields.summary]: {
        translationKey: 'table_column_summary',
      },
      action: {
        translationKey: 'table_header_action',
        styles: {
          maxWidth: '25%',
          width: '25%',
        },
      },
    };
    return {
      columns: columns,
      caption: this.translateService.instant('linked_findings'),
    };
  }

  private handleError(err: any): void {
    if (CommonUtility.extractHTTPErrorName(err) === ErrorFlawAttachTaskAlreadyLinkedError) {
      this.notificationService.info({
        message: this.translateService.instant('label_toast_please_refresh_to_update_finding'),
        header: this.translateService.instant('label_toast_finding_already_linked'),
        autoHide: false,
      });
      return;
    }

    if (CommonUtility.extractHTTPErrorName(err) === ErrorCreateTaskWithDuplicatedFindingError) {
      this.notificationService.error(this.translateService.instant('task_duplicated_finding'), NotificationPosition.Toast, true);
      return;
    }

    this.notificationService.error(this.translateService.instant('task_save_error'), NotificationPosition.Toast, true);
  }

  private onSaveSuccess(task?: ITask): void {
    if (this.notifyChanges) {
      this.notificationService.success(
        this.isEdit
          ? this.translateService.instant('task_update_success')
          : this.translateService.instantHtml('task_has_been_created_successfully', [this.getTaskLink(task), task.taskProjectId]),
        NotificationPosition.Toast,
        true,
        undefined,
        false,
        true,
      );
    }
    this.onUpsert.emit(task);
    this.container.closeModal();
  }

  private attachFilesToRequest(request: ITaskCreateRequest | ITaskEditRequest): FormData {
    const formData: FormData = CommonUtility.createFormData(request);
    let counterFiles: number = 0;

    const addFileToFormData = (file: File): void => {
      formData.append($task.attachments + `_${counterFiles}`, file, file.name);
      counterFiles += 1;
    };

    this.selectedAttachments.forEach(addFileToFormData);
    return formData;
  }

  private updateAutoGeneratedCSV(taskProjectId?: string): Observable<File> {
    const issueIds: string = this.linkedFindings.map((i: IAutomatedScanIssue): string => i.rawFindingId).join(',');
    const extraQueryParams: Record<string, any> = {
      [ApiQueryOption.auditTool]: this.toolName,
      [ApiQueryOption.issueIds]: issueIds,
    };
    if (SharedCommonUtility.notNullish(taskProjectId)) {
      extraQueryParams[Api.taskProjectId] = taskProjectId;
    }
    return this.reportService.getReport(
      this.workspaceId,
      this.digitalPropertyId,
      this.scanId,
      FileExtension.csv,
      this.issues[$scanIssues.ruleId],
      extraQueryParams,
    );
  }

  private autoAttachGeneratedCSV(): void {
    this.attachAutoGeneratedFileSubscription?.unsubscribe();

    this.attachAutoGeneratedFileSubscription = this.updateAutoGeneratedCSV()
      .pipe(
        take(1),
        tap((file: File): void => {
          this.fileAttacher?.removeEntry(this.autoAttachedGeneratedCSV);
          this.autoAttachedGeneratedCSV = file;
          this.fileAttacher?.onFileChange([file]);
          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe();

    this.subscriptions.add(this.attachAutoGeneratedFileSubscription);
  }

  private updateCsvWithTaskId(): Observable<[string, File]> {
    return this.projectService.getTaskProjectId(this.workspaceId, this.projectId).pipe(
      switchMap((taskProjectId: string) => {
        return combineLatest([of(taskProjectId), this.updateAutoGeneratedCSV(taskProjectId)]);
      }),
    );
  }

  private addAutomatedFindingsData(taskCreateRequest: ITaskCreateRequest, taskProjectId?: string): void {
    if (this.linkedFindings.length === 0) {
      return;
    }
    taskCreateRequest[$taskFlaw.scanId] = this.scanId;
    taskCreateRequest[$taskFlaw.digitalPropertyId] = this.digitalPropertyId;
    if (taskProjectId) {
      taskCreateRequest[$task.taskProjectId] = taskProjectId;
    }
    const flaws: string[] = [];
    const unmatchedFlaws: string[] = [];
    this.linkedFindings.forEach((issue: IAutomatedScanIssue): void => {
      if (issue.isRawFinding) {
        unmatchedFlaws.push(issue.rawFindingId);
      } else {
        flaws.push(issue._id);
      }
    });
    if (flaws.length > 0) {
      taskCreateRequest[$taskFlaw.flaws] = flaws;
    }
    if (unmatchedFlaws.length > 0) {
      taskCreateRequest[$taskFlaw.unmatchedFlaws] = unmatchedFlaws;
    }
  }

  public get cardTitle(): string {
    return this.isEdit ? 'label_edit_task' : 'label_create_task';
  }

  public get submitButton(): string {
    return this.isEdit ? 'save_task_button_label' : 'label_create_task';
  }

  public get isIssueTrackingProjectSelected(): boolean {
    const selectedProject: IProject = this.form.get($task.projectId)?.value;
    return SharedCommonUtility.notNullish(selectedProject?.[$project.issueIntegrationProject]);
  }

  public get userCanPushIssueIntegration$(): Observable<boolean> {
    return this.userAclService.createCheckAccessForCurrentUser().pipe(
      map((adapter: AclSecurityAdapter): boolean =>
        adapter
          .useWorkspaceFromUser()
          .useFunctionalActions(RequiredSecurities.TI_Issue_Tracking_Basic_Create.functionalActions)
          .check(),
      ),
      map((hasAccess: boolean): boolean => hasAccess && this.isIssueTrackingProjectSelected),
    );
  }

  public get azureFileSizeLimit(): string {
    return SharedCommonUtility.formatBytes(AZURE_ATTACHMENT_MAX_FILESIZE, 0);
  }

  public get showAzureUploadWarning$(): Observable<boolean> {
    return this.selectedProject.pipe(
      map((project: IProject) => {
        const integrationType: $issueIntegrationType =
          project?.[$project.issueIntegrationConnection]?.[$issueIntegrationConnection.integrationType];
        return this.pushTaskToIssueTracking && integrationType === $issueIntegrationType.azure;
      }),
    );
  }

  public onFileUpload(files: File[]): void {
    this.selectedAttachments = files;
    this.autoAttachedGeneratedCSV = this.selectedAttachments.find(
      (file: File): boolean => file === this.autoAttachedGeneratedCSV,
    );
    this.setAttachmentsValidator();
  }

  public onExistingAttachmentsChange(uploads: IUploadClient[]): void {
    this.attachments = uploads;
    this.setAttachmentsValidator();
  }

  public setAttachmentsValidator(): void {
    this.form
      .get($task.attachments)
      .setValidators([
        CustomValidators.validateAttachmentFileType(
          this.selectedAttachments,
          SupportedTaskAttachmentMimeType,
          SupportedDocumentExtensions,
        ),
        CustomValidators.validateAttachmentTotalSize(this.selectedAttachments, this.maxUploadSize),
        CustomValidators.validateAttachmentQuantity(this.selectedAttachments, MAX_NUM_OF_ATTACHMENTS, this.attachments.length),
      ]);
    this.form.get($task.attachments).updateValueAndValidity();
    this.disableUploadButtons$.next(this.selectedAttachments.length + this.attachments.length >= MAX_NUM_OF_ATTACHMENTS);
  }

  public get uploaderContext(): IFileDragAndDropContext {
    return {
      field: $task.attachments,
      label: this.translateService.instant('label_task_form_attachments'),
      tableTitle: this.translateService.instant('attachments'),
      acceptedFileExtensions: this.attachmentAcceptedTypes,
      maxFileSize: this.maxUploadSize,
      maxTotalFileQuantity: this.maxUploadQuantity,
      required: false,
    };
  }

  public submitForm(): void {
    this.formValidationRequest.next();
    if (this.form.valid === false) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }
    this.save$.next();
  }

  public onProjectChange(project: IProject): void {
    this.selectedProject.next(project);
  }

  public onPushTaskToIssueTrackingChange(pushTaskToIssueTracking: boolean): void {
    this.pushTaskToIssueTracking = pushTaskToIssueTracking;
    const project: IProject = this.form.get($task.projectId).value;
    const connectionType: $issueIntegrationType =
      project[$project.issueIntegrationConnection][$issueIntegrationConnection.integrationType];
    if (this.pushTaskToIssueTracking && connectionType !== $issueIntegrationType.asana) {
      this.issueTrackingIssueTypes$ = this.issueTrackingService
        .getIssueTrackingIssueTypes(
          project[$project.issueIntegrationConnectionId],
          project[$project.issueIntegrationProject][$project.issueIntegrationProjectId],
        )
        .pipe(
          catchError((_: any) => {
            this.loadingError$.next(true);
            return of(null);
          }),
        );
    } else {
      this.issueTrackingIssueTypes$ = null;
    }
    if (this.pushTaskToIssueTracking && connectionType !== $issueIntegrationType.asana) {
      this.form.addControl($task.issueIntegrationType, this.formBuilder.control(null, [CustomValidators.required]));
    } else {
      this.form.removeControl($task.issueIntegrationType);
    }
    this.changeDetectorRef.detectChanges();
  }

  private prepareFormDataForCreateTask(): Observable<FormData> {
    const taskCreateRequest: ITaskCreateRequest = {
      [$task.pushTaskToIssueTracking]: this.pushTaskToIssueTracking ? 'true' : 'false',
      ...pick(this.form.value, $task.name, $task.description, $task.priority, $task.issueIntegrationType),
      [$task.attachments]: this.attachments.map((attachment: IUploadClient) => attachment[$uploads._id]),
      source: this.taskSource,
    };

    if (this.auditFindings.length > 0) {
      taskCreateRequest[$task.auditFindingIds] = this.auditFindings;
    }

    if (this.designReviewFindings.length > 0) {
      taskCreateRequest[$task.designReviewFindingIds] = this.designReviewFindings;
    }

    const updateCsv$: Observable<[string, File]> = this.autoAttachedGeneratedCSV
      ? this.updateCsvWithTaskId()
      : of([undefined, undefined]);

    return updateCsv$.pipe(
      map(([taskProjectId, updatedCsv]: [string, File]): FormData => {
        if (updatedCsv) {
          this.selectedAttachments = [
            updatedCsv,
            ...this.selectedAttachments.filter((f: File) => f !== this.autoAttachedGeneratedCSV),
          ];
          this.autoAttachedGeneratedCSV = updatedCsv;
        }
        this.addAutomatedFindingsData(taskCreateRequest, taskProjectId);
        delete taskCreateRequest[$task.status];
        return this.attachFilesToRequest(taskCreateRequest);
      }),
    );
  }

  // Modal is dynamically loaded. So lifecycle management has to be done manually.
  public modalInit(prepopulate: boolean): void {
    if (prepopulate) {
      const formSetValueIfExists = (fieldName: string, value: string | IUploadClient[]): void => {
        if (SharedCommonUtility.isNullish(value)) {
          return;
        }
        this.form.get(fieldName).setValue(value);
      };

      // Note: attachments are sent directly to a lower component to handle
      formSetValueIfExists($task.name, this.taskName);
      formSetValueIfExists($task.description, this.description);
      formSetValueIfExists($task.status, this.translateService.instant('label_task_status_' + this.status));
      formSetValueIfExists($task.priority, this.priority);
      this.linkedFindings = [...this.selectedIssues];
      this.linkedFindingsTableRows = this.getLinkedFindingsTableRows();

      const autogenerateCSV: boolean =
        !SharedCommonUtility.isNullish(this.digitalPropertyId) &&
        !SharedCommonUtility.isNullish(this.workspaceId) &&
        !SharedCommonUtility.isNullish(this.scanId) &&
        !SharedCommonUtility.isNullish(this.toolName);
      if (autogenerateCSV) {
        this.autoAttachGeneratedCSV();
      }
    }

    const needToSelectProjectFromWorkspace: boolean =
      SharedCommonUtility.isNullish(this.projectId) && !SharedCommonUtility.isNullish(this.workspaceId);

    if (needToSelectProjectFromWorkspace) {
      this.form.addControl($task.projectId, this.formBuilder.control(null, [CustomValidators.required]));
      this.subscriptions.add(
        this.form.get($task.projectId).valueChanges.subscribe(() => {
          this.pushTaskToIssueTracking = false; // NOTE: uncheck checkbox when switching projects
        }),
      );
      this.availableProjects$ = this.projectService.getProjects(this.workspaceId);
    }

    this.subscriptions.add(
      this.save$
        .pipe(
          switchMap((): Observable<ITask> => {
            if (SharedCommonUtility.isNullish(this.projectId)) {
              this.projectId = this.form.get($task.projectId).value._id;
            }

            if (this.isEdit) {
              const taskEditRequest: ITaskEditRequest = {
                ...pick(this.form.value, $task.name, $task.description, $task.priority),
                [$task.attachments]: this.attachments.map((attachment: IUploadClient) => attachment[$uploads._id]),
              };
              delete taskEditRequest[$task.status];
              const formData: FormData = this.attachFilesToRequest(taskEditRequest);
              return this.projectService.editTask(this.workspaceId, this.projectId, this.taskId, formData);
            }
            return this.prepareFormDataForCreateTask().pipe(
              switchMap((formData: FormData) => this.projectService.createTask(this.workspaceId, this.projectId, formData)),
            );
          }),
        )
        .subscribe({
          next: (task: ITask) => this.onSaveSuccess(task),
          error: this.handleError.bind(this),
        }),
    );
    this.disableUploadButtons$.next(this.selectedAttachments.length + this.attachments.length >= MAX_NUM_OF_ATTACHMENTS);

    const issueIntegrationConnection$: Observable<IIssueIntegrationConnection> = this.selectedProject.pipe(
      distinctUntilKeyChanged($project._id),
      switchMap((project: IProject): Observable<IIssueIntegrationConnection> => {
        if (SharedCommonUtility.notNullish(project[$project.issueIntegrationConnectionId])) {
          return this.issueTrackingService.getIssueTrackingConnectionInfo(project[$project.issueIntegrationConnectionId]);
        }
        return of(null);
      }),
    );

    this.issueIntegrationProjectLabel$ = issueIntegrationConnection$.pipe(
      map((connection: IIssueIntegrationConnection): string => {
        const integrationType: $issueIntegrationType = connection
          ? connection[$issueIntegrationConnection.integrationType]
          : null;
        return integrationType ? this.translateService.instant(`push_to_${integrationType}`) : this.translateService.instant('');
      }),
    );

    this.selectIssueTypeLabel$ = issueIntegrationConnection$.pipe(
      map((connection: IIssueIntegrationConnection): string => {
        const integrationType: $issueIntegrationType = connection
          ? connection[$issueIntegrationConnection.integrationType]
          : null;
        return integrationType
          ? this.translateService.instant(`select_a_${integrationType.toLowerCase()}_issue_type`)
          : this.translateService.instant('');
      }),
    );
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.formValidationRequest$.subscribe(() => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
