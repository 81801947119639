<div class="card-body container">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <h1>{{ pageTitle }}</h1>

      <app-alert
        *ngIf="hasResendFailed === true"
        [type]="alertType.danger"
        [header]="'resend_failed_message' | translate"
        [message]="errorMessage"
      ></app-alert>

      <p *ngIf="!isAuthenticated">{{ 'enter_email_we_will_send_link_to_verify' | translate }}</p>

      <form
        *ngIf="isResendDone === false"
        name="form"
        (ngSubmit)="emailForm.form.valid && resendActivationLink()"
        #emailForm="ngForm"
        ngNativeValidate
      >
        <fieldset>
          <legend class="visuallyhidden">{{ 'resend_activation_link' | translate }}</legend>

          <div class="row form-row">
            <div class="col-md-3 d-flex align-items-center">
              <label for="email">{{ 'email' | translate }}</label>
            </div>
            <div class="col-md-8">
              <input
                type="email"
                id="email"
                class="form-element w-100"
                name="email"
                required="required"
                [(ngModel)]="email"
                placeholder="{{ 'enter_email_here' | translate }}"
              />
            </div>
          </div>

          <div class="row form-row">
            <div class="col-md-6 offset-md-5">
              <button type="submit" class="btn btn-primary float-end" [disabled]="!emailForm.form.valid">
                {{ 'resend_activation_link' | translate }}
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 offset-md-5">
              <p class="text-end">
                {{ 'or_go_back_to' | translate }} <a [routerLink]="['/', Api.login]">{{ 'login' | translate }}</a
                >.
              </p>
            </div>
          </div>
        </fieldset>
      </form>

      <div *ngIf="isResendDone" aria-live="polite">
        <p>
          {{ 'resend_done' | translate }}
        </p>
        <p *ngIf="isAuthenticated; else login">
          {{ 'go_back' | translate }}
          <a [routerLink]="['/', Api.settings, Api.profile]">{{ 'profile' | translate }}.</a>
        </p>
        <ng-template #login>
          <p>
            {{ 'go_back' | translate }}
            <a [routerLink]="['/', Api.login]">{{ 'login' | translate }}.</a>
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</div>
