import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkspacesRestAPI } from './rest/workspaces.api';
import { IComment } from '../../../shared/interfaces/comment.interface';
import { IManualEvaluationAuditListItem } from '../../../shared/interfaces/manual-audit.interface';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  constructor(private workspacesRestAPI: WorkspacesRestAPI) {}

  public postManualAuditIssueComment(
    formData: FormData,
    workspaceId: string,
    digitalPropertyId: string,
    evaluationScanId: string,
    manualIssueId: string,
  ): Observable<IComment> {
    return this.workspacesRestAPI.postManualAuditIssueComment(
      formData,
      workspaceId,
      digitalPropertyId,
      evaluationScanId,
      manualIssueId,
    );
  }

  public getManualAuditIssueComments(
    evaluationId: string,
    issueId: string,
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IManualEvaluationAuditListItem[]> {
    return this.workspacesRestAPI.getManualAuditIssueComments(evaluationId, issueId, workspaceId, digitalPropertyId);
  }

  public deleteManualAuditIssueComment(
    workspaceId: string,
    digitalPropertyId: string,
    evaluationScanId: string,
    issueId: string,
    commentId: string,
  ): Observable<IComment> {
    return this.workspacesRestAPI.deleteManualAuditIssueComment(
      workspaceId,
      digitalPropertyId,
      evaluationScanId,
      issueId,
      commentId,
    );
  }
}
