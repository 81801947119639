import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import { ApiHeaderOption, ApiQueryOption } from '../../../../shared/constants/api';
import { IExistsResponse } from '../../../../shared/interfaces/common.interface';
import { IPage, IPagesResponse } from '../../../../shared/interfaces/page.interface';
import { $page } from '../../../../shared/constants/page';

@Injectable({
  providedIn: 'root',
})
export class PageApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/pages
  public getPages(
    workspaceId: string,
    digitalPropertyId: string,
    skip: number = 0,
    limit: number = 0,
    term: string = '',
    sortBy?: string,
    direction?: string,
    mustContainUrl: boolean = false,
  ): Observable<IPagesResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .pages()
      .getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params[ApiQueryOption.limit] = String(limit);
    }

    if (typeof skip === 'number') {
      params[ApiQueryOption.skip] = String(skip);
    }

    if (typeof sortBy === 'string') {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    if (typeof direction === 'string') {
      params[ApiQueryOption.direction] = direction;
    }

    if (term?.length > 0) {
      params[ApiQueryOption.term] = window.encodeURIComponent(term.trim());
    }

    params.mustContainUrl = String(mustContainUrl);

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });
    return this.httpClient.get<IPagesResponse>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/pagess/:scanPointId
  public getPage(workspaceId: string, digitalPropertyId: string, scanPointId: string): Observable<IPage> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .pages(scanPointId)
      .getApiUrl();

    return this.httpClient.get<IPage>(url);
  }

  // POST /workspaces/:workspaceId/digital-properties/:digitalPropertyId/pages
  public createPage(workspaceId: string, digitalPropertyId: string, formData: FormData): Observable<IPage> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .pages()
      .getApiUrl();

    return this.httpClient.post<IPage>(url, formData);
  }

  // PUT /workspaces/:workspaceId/digital-properties/:digitalPropertyId/pages/:scanPointId
  public updatePage(
    workspaceId: string,
    digitalPropertyId: string,
    scanPointId: string,
    updateData: FormData,
  ): Observable<IPage> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .pages(scanPointId)
      .getApiUrl();

    return this.httpClient.put<IPage>(url, updateData);
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/pages/exists?name=:scanPointName
  public pageExists(
    workspaceId: string,
    digitalPropertyId: string,
    pageName: string,
    options: { skipLoader: boolean } = { skipLoader: false },
  ): Observable<IExistsResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .pages()
      .exists()
      .getApiUrl();

    return this.httpClient.get<IExistsResponse>(url, {
      params: new HttpParams({ fromObject: { [$page.name]: pageName } }),
      headers: {
        [ApiHeaderOption.skipLoader]: String(options.skipLoader),
      },
    });
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/pages?name=:scanPointName
  public pageExistsByURL(workspaceId: string, digitalPropertyId: string, pageURL: string): Observable<IExistsResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .pages()
      .exists()
      .getApiUrl();

    return this.httpClient.get<IExistsResponse>(url, {
      params: new HttpParams({ fromObject: { [$page.url]: pageURL } }),
    });
  }
}
