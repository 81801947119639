import { Pipe, PipeTransform } from '@angular/core';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Pipe({
  name: 'compactNumber',
})
export class CompactNumberPipe implements PipeTransform {
  transform(value: number): string {
    return SharedCommonUtility.formatCompactNumber(value);
  }
}
