<app-modal-container [displayHeader]="false" [displayFooter]="false" [backdropClose]="false">
  <div class="modal-body-content d-flex flex-column">
    <p class="body-content-heading">{{ 'label_application_updated' | translate }}</p>

    <div class="margin-bottom">
      <button class="btn btn-link p-0" (click)="reload()" tabindex="0" autofocus>
        {{ 'label_new_version_link' | translate }}
      </button>
    </div>
  </div>
</app-modal-container>
