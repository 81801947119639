export enum PlatformAction {
  SCANS = 'scans',
  MONITORING = 'monitoring',
  MANUAL_EVALUATIONS = 'manual_evaluations',
  BROWSER_EXTENSION_SCANS = 'browser_extension_scans',
  ISSUE_TRACKING = 'issue_tracking',
  TASKS = 'tasks',
  PDFS = 'pdfs',
  DESIGN_EVALUATIONS = 'design_evaluations',
  UNKNOWN = 'unknown',
}

export interface PlatformActionItem {
  value: PlatformAction;
  labelKey: string;
}

export const PLATFORM_ACTIONS: Readonly<PlatformActionItem[]> = [
  {
    value: PlatformAction.SCANS,
    labelKey: 'sign_up_platform_action_scans',
  },
  {
    value: PlatformAction.MONITORING,
    labelKey: 'sign_up_platform_action_monitoring',
  },
  {
    value: PlatformAction.MANUAL_EVALUATIONS,
    labelKey: 'sign_up_platform_action_manual_evaluations',
  },
  {
    value: PlatformAction.BROWSER_EXTENSION_SCANS,
    labelKey: 'sign_up_platform_action_browser_extension_scans',
  },
  {
    value: PlatformAction.ISSUE_TRACKING,
    labelKey: 'sign_up_platform_action_issue_tracking',
  },
  {
    value: PlatformAction.TASKS,
    labelKey: 'sign_up_platform_action_tasks',
  },
  {
    value: PlatformAction.PDFS,
    labelKey: 'sign_up_platform_action_pdfs',
  },
  {
    value: PlatformAction.DESIGN_EVALUATIONS,
    labelKey: 'sign_up_platform_action_design_evaluations',
  },
  {
    value: PlatformAction.UNKNOWN,
    labelKey: 'sign_up_platform_action_unknown',
  },
];
