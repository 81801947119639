import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AclSecurityAdapter } from '../../acl.service';
import { IRequiredSecurity } from '../../../../../shared/interfaces/security.interface';
import { environment } from '../../../../environments/environment';
import { Api } from '../../../../../shared/constants/api';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { SecurityEntityLevel } from '../../../../../shared/constants/security-group';
import { LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';
import { AppConfigService } from '../../app-config.service';
import { UserAclService } from '../../user-acl.service';

@Injectable({ providedIn: 'root' })
export class SecurityGuard {
  constructor(
    private userAclService: UserAclService,
    private router: Router,
    private appConfigServices: AppConfigService,
  ) {}

  private attemptSettingDigitalPropertyFromLinkedProperty(adapter: AclSecurityAdapter, route: ActivatedRouteSnapshot): void {
    if (LinkedPropertyUtility.hasLinkedPropertyData(route.queryParams) === false) {
      adapter.useWorkspaceFromUser().useDigitalPropertyFromUser();
      return;
    }

    const { workspaceId, digitalPropertyId } = LinkedPropertyUtility.fromLinkedPropertyQueryParam(route.queryParams);
    adapter.useWorkspaceId(workspaceId).useDigitalPropertyId(digitalPropertyId);
  }

  private attemptSettingWorkspaceFromLinkedProperty(adapter: AclSecurityAdapter, route: ActivatedRouteSnapshot): void {
    if (LinkedPropertyUtility.hasLinkedPropertyData(route.queryParams) === false) {
      adapter.useWorkspaceFromUser();
      return;
    }

    const { workspaceId } = LinkedPropertyUtility.fromLinkedPropertyQueryParam(route.queryParams);
    adapter.useWorkspaceId(workspaceId);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const requiredSecurity: IRequiredSecurity = route.routeConfig?.data?.requiredSecurity;
    const isAdmin: boolean = !this.appConfigServices.isTenantEnabled();

    return this.userAclService.createCheckAccessForCurrentUser().pipe(
      map((adapter: AclSecurityAdapter): AclSecurityAdapter => {
        return adapter
          .useOptions({
            requireFunctionalActions: true,
            throwIfFunctionalActionsUndefined: !environment.production,
          })
          .useFunctionalActions(requiredSecurity?.functionalActions);
      }),
      map((adapter: AclSecurityAdapter): boolean => {
        if (SharedCommonUtility.isNullishOrEmpty(requiredSecurity?.entityLevel)) {
          return adapter.check();
        }

        const allowAdminAccess: boolean =
          requiredSecurity?.entityLevel !== SecurityEntityLevel.admin && requiredSecurity?.allowAdminAccess;

        if (isAdmin && allowAdminAccess) {
          adapter.useAdmin();
        } else {
          switch (requiredSecurity?.entityLevel) {
            case SecurityEntityLevel.admin:
            case SecurityEntityLevel.tenant:
              adapter.useEntityLevel();
              break;
            case SecurityEntityLevel.digitalProperty:
              this.attemptSettingDigitalPropertyFromLinkedProperty(adapter, route);
              break;
            default:
              this.attemptSettingWorkspaceFromLinkedProperty(adapter, route);
              break;
          }
        }

        return adapter.check();
      }),
      map((canActivate: boolean): boolean | UrlTree => canActivate || this.router.parseUrl(`/${Api.forbidden}`)),
    );
  }
}
