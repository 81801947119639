<div class="card-body container">
  <div class="row">
    <div class="col-md-6 offset-md-3 text-center">
      <h1>{{ pageTitle }}</h1>
      <p [innerHTML]="logoutMessage"></p>
      <a [routerLink]="['/', Api.login]" *ngIf="!isUserAuthenticated" class="btn-primary btn-lg">
        {{ 'log_in' | translate }}
      </a>
    </div>
  </div>
</div>
