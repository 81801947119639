<div class="container-fluid" id="skiplink" role="navigation">
  <ds-link
    *ngIf="showSkipToAiChatLink$ | async"
    standalone="true"
    href="#ai-chat-button"
    skip="true"
    
  >
    {{ 'skip_to_ai_chat' | translate }}
  </ds-link>
  <ds-link standalone="true" href="#main-content" skip="true">{{ 'go_to_main_content' | translate }}</ds-link>
</div>
<compound-navbar></compound-navbar>
<div class="container g-5 pt-3 flex-grow-1">
  <main id="main-content" tabindex="-1">
    <ng-container [ngTemplateOutlet]="routerOutlet"></ng-container>
  </main>
</div>
<app-global-spinner *ngIf="isLoading"></app-global-spinner>
<app-footer></app-footer>
