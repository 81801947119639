import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';
import { WaveVersion } from '../../../interfaces/audit-rule.interface';
import { SuccessCriteriaIdentifier } from '../../../constants/audit-standard-success-criteria';

const wave: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: 'Best Practice',
    [$successCriteria.level]: '',
    [$successCriteria.num]: SuccessCriteriaIdentifier.WAVEBP1,
    [$successCriteria.title]: 'WAVE Best Practice',
    [$successCriteria.versions]: [WaveVersion.v10],
    [$successCriteria.url]: '',
  },
];

export class Wave implements ISuccessCriteriaIndex {
  public get(num: string): ISuccessCriteria {
    const waveSuccessCriteria: ISuccessCriteria | undefined = wave.find(
      (bestPracticeSuccessCriteria: ISuccessCriteria) => bestPracticeSuccessCriteria.num === num,
    );
    if (typeof waveSuccessCriteria === 'undefined') {
      return null;
    }
    return waveSuccessCriteria;
  }

  public getAll(): ISuccessCriteria[] {
    return wave;
  }
}
