import { IReportFormat } from '../interfaces/report-format.interface';
import { FileExtension } from './file-extension';

export const ReportFormat: { [key: string]: IReportFormat } = Object.freeze({
  CSV: {
    description: 'Excel (.csv)',
    type: FileExtension.csv,
  },
  XLSX: {
    description: 'Excel (.xlsx)',
    type: FileExtension.xlsx,
  },
});
