import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { A11yService } from '../a11y.service';
import { TranslateService } from '../../translate/translate.service';

@Injectable()
export class BusyIndicatorService {
  private toggleIndicator: Subject<boolean>;

  public indicatorToggled$: Observable<boolean>;

  constructor(
    private a11yService: A11yService,
    private translateService: TranslateService,
  ) {
    this.toggleIndicator = new Subject<boolean>();
    this.indicatorToggled$ = this.toggleIndicator.asObservable();
  }

  public a11MessageOnIndicatorChange(): Observable<boolean> {
    return this.indicatorToggled$.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap((busy: boolean): void => {
        if (busy && this.translateService.isTranslationAvailableForKey('application_is_busy')) {
          this.a11yService.setMessage(this.translateService.instant('application_is_busy'));
        }
        if (busy === false && this.translateService.isTranslationAvailableForKey('application_is_ready_to_use')) {
          this.a11yService.setMessage(this.translateService.instant('application_is_ready_to_use'));
        }
      }),
    );
  }

  private onOpenIndicator(): void {
    this.toggleIndicator.next(true);
  }

  private onCloseIndicator(): void {
    this.toggleIndicator.next(false);
  }

  public openIndicator(): void {
    this.onOpenIndicator();
  }

  public closeIndicator(): void {
    this.onCloseIndicator();
  }
}
