<ng-container *ngIf="menuItems$ | async as menuItems">
  <ds-dropdown
    *ngIf="menuItems.length > 0"
    class="top-navbar-launcher-dropdown"
    [ds-tooltip]="'control_hub_dropdown_tooltip_text' | translate"
    [tooltipPlacement]="DsTooltipPosition.bottom"
    [selectedValue]="(currentActiveMenuItem$ | async)?.id"
    
  >
    <ds-dropdown-toggle
      [icon]="Icons.Grid"
      [variant]="DsButtonVariants.microAction"
      [ariaLabel]="'control_hub_dropdown_tooltip_text' | translate"
    >
    </ds-dropdown-toggle>
    <div ds-dropdown-menu>
      <ds-dropdown-item
        *ngFor="let menuItem of menuItems"
        [icon]="menuItem.iconName"
        [label]="menuItem.label | translate"
        [href]="menuItem.routerLink"
        [value]="menuItem.id"
      ></ds-dropdown-item>
    </div>
  </ds-dropdown>
</ng-container>
