import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { DsButtonVariants, DsTooltipPosition, Icons } from '@levelaccess/design-system';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { CommonControlsViewMode } from '../../constants/common-controls-view-mode';
import { CommonUtility } from '../../utility/common.utility';
import { FormService } from '../../services/form.service';

type CustomLabelFormatter = (label: string, value: string, description: string) => string;

export interface InputFieldContext {
  id?: string;
  customMessage?: string;
  label: string;
  field: string;
  readonly?: boolean;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  describedby?: string;
  description?: string;
  descriptionLocation?: DescriptionLocation;
  fullWidthInput?: boolean;
  type?: string;
  autocomplete?: string;
  overrideErrors?: Record<string, string>;
  tooltip?: string;
  noLabel?: boolean;
  hiddenLabel?: boolean;
  customLabelFormatter?: CustomLabelFormatter;
  width?: string;
  subLabel?: string;
}

type DescriptionLocation = 'top' | 'bottom' | 'right';

@Component({
  selector: 'app-common-input',
  templateUrl: './common-input.component.html',
  styleUrls: ['./common-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonInputComponent implements OnInit, OnDestroy, OnChanges {
  private subscription: Subscription;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public context: InputFieldContext;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public view: CommonControlsViewMode;

  public Icons: typeof Icons = Icons;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public DsTooltipPosition: typeof DsTooltipPosition = DsTooltipPosition;
  public labelId: string = CommonUtility.createUniqueDOMId();
  public descriptionId: string = CommonUtility.createUniqueDOMId();
  public subLabelId: string = CommonUtility.createUniqueDOMId();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formService: FormService,
  ) {
    this.view = 'col-view';
  }

  get required(): boolean {
    return this.formService.isFieldRequired(this.form, this.context);
  }

  get fullWidthInput(): boolean {
    if (SharedCommonUtility.isNullish(this.context.fullWidthInput) && SharedCommonUtility.isNullish(this.context.width)) {
      return true;
    }
    return this.context.fullWidthInput;
  }

  get type(): string {
    return typeof this.context.type === 'string' ? this.context.type : 'text';
  }

  get readonly(): boolean {
    return this.context.readonly === true;
  }

  public ngOnChanges(): void {
    if (SharedCommonUtility.notNullish(this.context?.disabled)) {
      if (this.context.disabled) {
        this.form.get(this.context.field).disable();
      } else {
        this.form.get(this.context.field).enable();
      }
    }
  }

  public ngOnInit(): void {
    if (this.formValidationRequest$) {
      this.subscription = this.formValidationRequest$.subscribe(() => {
        const field: AbstractControl = this.form.get(this.context.field);
        field.markAsDirty();
        field.markAsTouched();
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
