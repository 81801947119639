<div class="row m-0 table-container">
  <div class="col-12 p-0">
    <table>
      <caption *ngIf="tableDataAvailable" class="visuallyhidden">
        {{
          tableCaption
        }}
        <span *ngIf="hasSortableColumns">{{ 'column_headers_with_buttons_are_sortable' | translate }}</span>
      </caption>
      <thead>
        <tr>
          <th *ngIf="tableRowsAreCheckable" [style.minWidth.px]="50" [style.width.px]="50">
            <input type="checkbox" [(ngModel)]="checkedAll" (change)="onCheckHead()" />
          </th>
          <th
            *ngFor="let columnKey of columnsList"
            [attr.aria-sort]="getTableHeaderAriaSort(columnKey)"
            [ngStyle]="getColumnConfig(columnKey)?.styles"
            scope="col"
          >
            <div class="header-container" *ngIf="getColumnConfig(columnKey)">
              <ng-container *ngIf="getColumnConfig(columnKey).sortingEnabled; else sortDisabledColumnCell">
                <button
                  class="btn btn-link d-flex align-items-center p-0 fw-bold text-decoration-none text-black text-start"
                  [attr.aria-label]="getTableHeaderAriaSortLabel(columnKey)"
                  (click)="sortingTriggered(columnKey)"
                >
                  {{ getColumnConfig(columnKey).translationKey | translate }}
                  <ng-container [ngSwitch]="getColumnState(columnKey).sortingOrder">
                    <ng-container *ngSwitchCase="$sortingOrder.asc">
                      <svg>
                        <use xlink:href="#fs-sort-up"></use>
                      </svg>
                    </ng-container>

                    <ng-container *ngSwitchCase="$sortingOrder.desc">
                      <svg>
                        <use xlink:href="#fs-sort-down"></use>
                      </svg>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <svg>
                        <use xlink:href="#fs-sort"></use>
                      </svg>
                    </ng-container>
                  </ng-container>
                </button>
              </ng-container>
              <ng-template #sortDisabledColumnCell>
                {{ getColumnConfig(columnKey).translationKey ? (getColumnConfig(columnKey).translationKey | translate) : '' }}
              </ng-template>
            </div>
          </th>
          <th scope="col" data-column="action" class="action" *ngIf="actions?.length > 0">
            <div class="header-container">
              <p>
                {{ 'column_header_action' | translate }}
              </p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="tableDataAvailable && rowsData.length > 0">
          <tr *ngFor="let tableRow of rowsData; trackBy: trackBy; let rowIndex = index" [ngStyle]="tableRow.styles">
            <td *ngIf="tableRowsAreCheckable" [style.minWidth.px]="50" [style.width.px]="50">
              <input type="checkbox" [(ngModel)]="checkedRows[rowIndex]" (change)="onCheckCell(rowIndex)" />
            </td>
            <ng-container *ngFor="let columnKey of columnsList; trackBy: trackBy">
              <ng-template
                [ngTemplateOutlet]="cellContent"
                [ngTemplateOutletContext]="{ columnKey: columnKey, rowIndex: rowIndex }"
              ></ng-template>
            </ng-container>
            <ng-container *ngIf="actions?.length > 0">
              <td [ngStyle]="getCellConfig('actions', rowIndex)?.styles" data-column="action">
                <table-cell-dropdown
                  *ngIf="tableRow.hideActions !== true"
                  [config]="getActionCellConfig(tableRow)"
                ></table-cell-dropdown>
              </td>
            </ng-container>
          </tr>
          <tr aria-hidden="true" class="fix-scroll-row" *ngIf="actions?.length > 0" [style.height.rem]="actions.length * 1.8">
            <!-- Fixes vertical scroll when open "Actions" -->
            <td colspan="" [colSpan]="rowsData.length + 1"></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex empty-state" *ngIf="emptyState" role="status" aria-live="polite">
  <app-table-empty-state [hideBorders]="emptyStateHideBorders" [tableEmptyState]="emptyState"></app-table-empty-state>
</div>

<ng-template #customColumns let-columnKey="columnKey" let-rowIndex="rowIndex">
  <ng-container *ngIf="getColumnConfig(columnKey).customType; else isRegularColumn">
    <ng-container [ngSwitch]="getColumnConfig(columnKey).customType">
      <ng-container *ngSwitchCase="customColumnType.link">
        <ng-template
          [ngTemplateOutlet]="linkedCell"
          [ngTemplateOutletContext]="{ data: getLinkCellData(columnKey, rowIndex), tableLink: true }"
        ></ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="customColumnType.linkedCell">
        <ng-template
          [ngTemplateOutlet]="linkedCell"
          [ngTemplateOutletContext]="{ data: getLinkCellData(columnKey, rowIndex) }"
        ></ng-template>
      </ng-container>

      <div *ngSwitchCase="customColumnType.externalLink" class="full-width">
        <a class="table-link" [href]="getLinkCellData(columnKey, rowIndex).href">
          {{ getLinkCellData(columnKey, rowIndex).linkLabel }}
        </a>
      </div>

      <div
        *ngSwitchCase="customColumnType.severity"
        class="severity-block severity"
        [ngClass]="'severity-' + getSeverityCellData(columnKey, rowIndex)"
      >
        <svg
          *ngIf="getSeverityCellData(columnKey, rowIndex) === $severity.critical; else severityNotCritical"
          aria-hidden="true"
          viewBox="0 0 512 512"
        >
          <use xlink:href="#exclamation-triangle"></use>
        </svg>
        <ng-template #severityNotCritical>
          <svg aria-hidden="true" viewBox="0 0 512 512">
            <use xlink:href="#exclamation-circle"></use>
          </svg>
        </ng-template>
        {{ 'scan_issue_severity_' + getSeverityCellData(columnKey, rowIndex) | translate }}
      </div>

      <div *ngSwitchCase="customColumnType.score" class="score-container">
        <ng-container *ngIf="getScoreCellData(columnKey, rowIndex) as cellData">
          <ng-container *ngIf="cellData.score >= 0; else scoreEmptyLabel">
            <!-- Using this visually hidden span to make the screen readers announce the content of this cell more clearly -->
            <span class="visuallyhidden">{{ 'n_of_t' | translate: [cellData.score, 100] }}</span>
            <span aria-hidden="true" [ngClass]="getScoreCellClass(columnKey, rowIndex)" class="score-value">
              {{ cellData.score }}
            </span>
            <span aria-hidden="true">/ 100</span>
          </ng-container>
          <ng-template #scoreEmptyLabel>
            {{ cellData.emptyStateLabel }}
          </ng-template>
        </ng-container>
      </div>

      <div *ngSwitchCase="customColumnType.scoreImpact" class="score-impact-container d-flex">
        <span class="sign-icon me-1">
          {{ getScoreImpactCellData(columnKey, rowIndex) < 0 ? '–' : '+' }}
        </span>
        <span> {{ getScoreImpactCellData(columnKey, rowIndex, true) }}% </span>
      </div>

      <div *ngSwitchCase="customColumnType.monitoring" class="monitoring-container">
        <app-monitoring-label [data]="getCellDataForMonitoring(columnKey, rowIndex)"></app-monitoring-label>
      </div>

      <div *ngSwitchCase="customColumnType.textWithTag" class="table-tag-container">
        {{ getTextWithTagCellData(columnKey, rowIndex).text }}
        <span
          class="table-tag-label margin-left"
          style="{{ 'background:' + getTextWithTagCellData(columnKey, rowIndex).tagColor + ';' }}"
        >
          {{ getTextWithTagCellData(columnKey, rowIndex).tagLabel }}
        </span>
      </div>

      <div *ngSwitchCase="customColumnType.tooltip" class="d-flex flex-col">
        <span class="me-5">{{ getToolTipCellData(columnKey, rowIndex).message }}</span>
        <div
          *ngIf="getToolTipCellData(columnKey, rowIndex).hideTooltip === false"
          class="d-inline-block px-2 tooltip-label"
          [attr.aria-label]="getToolTipCellData(columnKey, rowIndex).tooltipMessage"
          [appTooltip]="getToolTipCellData(columnKey, rowIndex).tooltipPopupMessage"
        >
          <span [attr.aria-hidden]="true">
            {{ getToolTipCellData(columnKey, rowIndex).tooltipMessage }}
          </span>
        </div>
      </div>

      <div *ngSwitchCase="customColumnType.action" class="d-flex flex-col justify-content-end">
        <button class="btn btn-link btn-flat" (click)="getActionCellData(columnKey, rowIndex).action(rowsData[rowIndex])">
          {{ getActionCellData(columnKey, rowIndex).label | translate }}
        </button>
      </div>

      <div *ngSwitchCase="customColumnType.checkbox" class="checkbox-container">
        <ng-template
          [ngTemplateOutlet]="checkbox"
          [ngTemplateOutletContext]="{ cellData: getCheckboxCellData(columnKey, rowIndex), rowData: rowsData[rowIndex] }"
        ></ng-template>
      </div>

      <div *ngSwitchCase="customColumnType.img">
        <ng-container *ngIf="getImageCellData(columnKey, rowIndex).embedImage; else noImage">
          <img
            [src]="getImageCellData(columnKey, rowIndex).url"
            alt="{{ 'screenshot' | translate }}"
            class="img-fluid screenshot-data"
          />
        </ng-container>
        <ng-template #noImage>
          {{ getImageCellData(columnKey, rowIndex).url }}
        </ng-template>
      </div>

      <div *ngSwitchCase="customColumnType.attachments" class="attachments-container">
        <a
          *ngFor="let attachment of getAttachmentsCellData(columnKey, rowIndex)"
          href="{{ attachment.href }}"
          rel="noreferrer noopener"
          target="_blank"
          class="pe-2"
        >
          <svg *ngIf="attachment.icon === 'attachment-document'">
            <use xlink:href="#attachment-document"></use>
          </svg>
          <svg *ngIf="attachment.icon === 'attachment-picture'">
            <use xlink:href="#attachment-picture"></use>
          </svg>
          <i aria-hidden="true"></i>
        </a>
      </div>

      <div *ngSwitchCase="customColumnType.remediationProgress" class="align-items-center remediation-progress-cell">
        <ng-container *ngIf="!getRemediationProgressCellData(columnKey, rowIndex).isArchived">
          <ng-container *ngIf="getRemediationProgressCellData(columnKey, rowIndex).max > 0; else noRemediations">
            <div class="progress mt-1 mb-2">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                [style.width]="getRemediationProgressCellData(columnKey, rowIndex).progress + '%'"
                [attr.aria-label]="getRemediationProgressAriaLabel(columnKey)"
                [attr.aria-valuenow]="getRemediationProgressCellData(columnKey, rowIndex).progress + '%'"
              ></div>
            </div>
            <div class="count-val d-flex">
              <div class="me-auto pe-2">
                <a
                  [routerLink]="getRemediationProgressCellData(columnKey, rowIndex).linkTo"
                  [queryParams]="getRemediationProgressCellData(columnKey, rowIndex).linkToParams || {}"
                >
                  {{
                    'findings_remaining'
                      | translate
                        : getRemediationProgressCellData(columnKey, rowIndex).max -
                            getRemediationProgressCellData(columnKey, rowIndex).current
                  }}
                  <br />
                  ({{ getRemediationProgressCellData(columnKey, rowIndex).critical }}
                  {{ 'label_critical' | translate | lowercase }})
                </a>
              </div>
              <div class="percents text-end">{{ roundDown(getRemediationProgressCellData(columnKey, rowIndex).progress) }}%</div>
            </div>
          </ng-container>
          <ng-template #noRemediations>
            <div>
              {{ 'label_remediation_progress_empty' | translate }}
            </div>
          </ng-template>
        </ng-container>
      </div>

      <div *ngSwitchCase="customColumnType.progress" class="d-flex align-items-center progress-cell">
        <div *ngIf="getProgressCellData(columnKey, rowIndex).max > 0" class="progress-val">
          {{ roundDown(getProgressCellData(columnKey, rowIndex).progress) }}%
        </div>
        <div *ngIf="getProgressCellData(columnKey, rowIndex).max > 0" class="progress flex-1">
          <div
            class="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            [style.width]="getProgressCellAsPercent(columnKey, rowIndex)"
            [attr.aria-label]="'remediation_progress_percentage' | translate"
            [attr.aria-valuenow]="getProgressCellRounded(columnKey, rowIndex)"
          ></div>
        </div>
        <div *ngIf="getProgressCellData(columnKey, rowIndex).max === 0">
          {{ 'label_remediation_progress_empty' | translate }}
        </div>
      </div>

      <div *ngSwitchCase="customColumnType.profilePicture" class="d-flex">
        <img [src]="getProfilePictureCellData(columnKey, rowIndex).href" alt="" class="user-profile-picture" />
        <span class="align-items-center ms-3">{{ getProfilePictureCellData(columnKey, rowIndex).message }}</span>
      </div>

      <ng-container *ngSwitchCase="customColumnType.invitationStatus">
        <ng-template
          [ngTemplateOutlet]="invitationStatusCell"
          [ngTemplateOutletContext]="{ data: getInvitationStatusCellData(columnKey, rowIndex) }"
        ></ng-template>
      </ng-container>

      <div *ngSwitchCase="customColumnType.forcedWordWrapText" class="forced-word-wrap">
        {{ getCellData(columnKey, rowIndex) }}
      </div>
    </ng-container>
  </ng-container>
  <ng-template #isRegularColumn>{{ getCellData(columnKey, rowIndex) }}</ng-template>
</ng-template>

<ng-template #cellContent let-columnKey="columnKey" let-rowIndex="rowIndex">
  <ng-container *ngIf="getColumnConfig(columnKey).isRowHeader; else regularCell">
    <th
      *ngIf="hasCell(columnKey, rowIndex)"
      [colSpan]="getCellConfig(columnKey, rowIndex)?.colSpan"
      [ngStyle]="getCellConfig(columnKey, rowIndex)?.styles"
      scope="row"
    >
      <ng-template
        [ngTemplateOutlet]="customColumns"
        [ngTemplateOutletContext]="{ columnKey: columnKey, rowIndex: rowIndex }"
      ></ng-template>
    </th>
  </ng-container>
  <ng-template #regularCell>
    <td
      *ngIf="hasCell(columnKey, rowIndex)"
      [colSpan]="getCellConfig(columnKey, rowIndex)?.colSpan"
      [ngStyle]="getCellConfig(columnKey, rowIndex)?.styles"
    >
      <ng-template
        [ngTemplateOutlet]="customColumns"
        [ngTemplateOutletContext]="{ columnKey: columnKey, rowIndex: rowIndex }"
      ></ng-template>
    </td>
  </ng-template>
</ng-template>

<ng-template #linkedCell let-data="data" let-tableLink="tableLink">
  <div [class.full-width]="tableLink">
    @if (data.disabled) {
      <span>
        {{ data.linkLabel }}
      </span>
    } @else {
      <a [routerLink]="data.href" [class.table-link]="tableLink" [queryParams]="data.queryParams ? data.queryParams : {}">
        {{ data.linkLabel }}
      </a>
      <div *ngIf="data.image" class="d-flex align-items-center table-link-img-container" >
        <img [src]="data.image.src" [alt]="data.image.alt ?? ''" class="me-2" />
        {{ data.image.label }}
      </div>
    }
  </div>
</ng-template>

<ng-template #checkbox let-cellData="cellData" let-rowData="rowData">
  <label class="d-flex align-items-center fw-normal">
    <input
      type="checkbox"
      class="form-element"
      [checked]="cellData?.checked(rowData)"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="cellData?.checked(rowData)"
      [indeterminate]="cellData?.indeterminate(rowData)"
      [disabled]="cellData?.disabled(rowData)"
      (ngModelChange)="cellData?.action($event, rowData)"
    />
    <span *ngIf="cellData?.label(rowData)" class="margin-left-xs">
      {{ cellData?.label(rowData) | translate }}
    </span>
  </label>
</ng-template>

<ng-template #invitationStatusCell let-data="data">
  <div class="d-flex align-items-center">
    {{ 'status_' + data | translate }}
    <ng-container [ngSwitch]="data">
      <svg
        *ngSwitchCase="$invitationStatus.pending"
        class="icon invitation-status-cell--icon invitation-status-pending"
        viewBox="0 0 20 20"
      >
        <use xlink:href="#clock-outline"></use>
      </svg>
      <svg *ngSwitchCase="$invitationStatus.expired" class="icon invitation-status-cell--icon" viewBox="0 0 21 18">
        <use xlink:href="#warning-3"></use>
      </svg>
      <svg *ngSwitchCase="$invitationStatus.completed" class="icon invitation-status-cell--icon" viewBox="0 0 21 20">
        <use xlink:href="#check-outline"></use>
      </svg>
    </ng-container>
  </div>
</ng-template>
