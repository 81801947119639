import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import hljs from 'highlight.js/lib/core';
import hjjs_xml from 'highlight.js/lib/languages/xml';
import hljs_xquery from 'highlight.js/lib/languages/xquery';
import hljs_js from 'highlight.js/lib/languages/javascript';
import { LinkifyHtmlPipe } from '../../pipes/linkify-html.pipe';

@Directive({
  selector: '[appHighlight]',
})
export class HighlightDirective implements OnChanges {
  @Input() public type: string;
  @Input() public appHighlight: string;
  @Input() public linkify: boolean;

  constructor(private el: ElementRef<HTMLElement>) {
    hljs.registerLanguage('xml', hjjs_xml);
    hljs.registerLanguage('xquery', hljs_xquery);
    hljs.registerLanguage('javascript', hljs_js);
  }

  public ngOnChanges(): void {
    const html: string = hljs.highlight(this.appHighlight ?? '', { language: this.type }).value;
    this.el.nativeElement.innerHTML = this.linkify ? new LinkifyHtmlPipe().transform(html) : html;
  }
}
