import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';

import { UserService } from '../../user.service';
import { IHttpErrorResponse } from '../../../interfaces/error.interface';
import { Api } from '../../../../../shared/constants/api';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { SharedDateUtility } from '../../../../../shared/utils/date.utility';
import { LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';

interface IDigitalPropertyAccessStatus {
  hasAccess: boolean;
  lastUpdate: Date;
}

const ACCESS_CACHE_EXPIRY_SECONDS: number = 30;

@Injectable({ providedIn: 'root' })
export class DigitalPropertyGuard {
  private readonly hasDigitalPropertyAccessCache: Record<string, IDigitalPropertyAccessStatus>;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {
    this.hasDigitalPropertyAccessCache = {};
  }

  private async handleScanError(e: IHttpErrorResponse, state: RouterStateSnapshot): Promise<boolean> {
    if (e instanceof HttpErrorResponse && e.status === StatusCodes.FORBIDDEN) {
      return this.router.navigate(['/', Api.forbidden], { queryParams: { url: state.url }, state: e.error });
    }
    return false;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (LinkedPropertyUtility.hasLinkedPropertyData(route.queryParams) === false) {
      return of(true);
    }

    const { workspaceId, digitalPropertyId } = LinkedPropertyUtility.fromLinkedPropertyQueryParam(route.queryParams);

    const cachedResult: IDigitalPropertyAccessStatus = this.hasDigitalPropertyAccessCache[digitalPropertyId];
    if (SharedCommonUtility.getTypeOf(cachedResult) === 'object') {
      const cacheExpiryDate: Date = SharedDateUtility.addSeconds(cachedResult.lastUpdate, ACCESS_CACHE_EXPIRY_SECONDS);
      if (new Date().getTime() < cacheExpiryDate.getTime()) {
        return of(this.hasDigitalPropertyAccessCache[digitalPropertyId].hasAccess);
      }
    }

    return this.userService.checkAccessToProperty(digitalPropertyId, workspaceId).pipe(
      tap(() => {
        this.hasDigitalPropertyAccessCache[digitalPropertyId] = {
          hasAccess: true,
          lastUpdate: new Date(),
        };
      }),
      catchError((e: IHttpErrorResponse): Observable<false> => {
        this.hasDigitalPropertyAccessCache[digitalPropertyId] = {
          hasAccess: false,
          lastUpdate: new Date(),
        };
        return from(this.handleScanError(e, state)).pipe(map(() => false));
      }),
    );
  }
}
