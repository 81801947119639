import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from '../../../../shared/constants/api';
import { IRuleAuditHistoryLineItem } from '../../../../shared/interfaces/rule-audit-history.interface';
import { ModalService } from '../../services/modal.service';
import { RuleStatusChangeComponent } from '../rule-status-change/rule-status-change.component';

export interface IRuleStatusToggleData {
  enabled: boolean;
  canChange: boolean;
  changeLog: IRuleAuditHistoryLineItem[];
  ruleId: string;
  title: string;
}

@Component({
  selector: 'app-rule-status-toggle',
  templateUrl: './rule-status-toggle.component.html',
  styleUrls: ['./rule-status-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleStatusToggleComponent implements OnDestroy {
  private subscription: Subscription;
  @Input()
  public data: IRuleStatusToggleData;
  public displayTooltip: boolean;
  public Api: typeof Api;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    this.subscription = new Subscription();
    this.displayTooltip = false;
    this.Api = Api;
  }

  public showChangeRuleStatusDialog(): void {
    const modalComponentRef: ComponentRef<RuleStatusChangeComponent> = this.modalService.open(RuleStatusChangeComponent);

    modalComponentRef.instance.modalInputData = {
      data: this.data,
    };

    this.subscription.add(
      modalComponentRef.instance.onSave.subscribe((newLogEntry: IRuleAuditHistoryLineItem): void => {
        this.data.changeLog.unshift(newLogEntry);
        this.changeDetectorRef.detectChanges();
      }),
    );

    this.subscription.add(
      modalComponentRef.instance.onCancel.subscribe((): void => {
        this.data.enabled = !this.data.enabled;
      }),
    );
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: MouseEvent): void {
    if (this.displayTooltip === false) {
      return;
    }

    if (this.elementRef.nativeElement.contains(event.target as Node) === false) {
      this.displayTooltip = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
