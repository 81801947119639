import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RoutingService } from '../../services/routing.service';
import { BreadcrumbParamsHandling } from '../../components/breadcrumbs/breadcrumbs.shared';
import { IPostProcessBreadcrumb } from './breadcrumbs.config';
import { AbstractBreadcrumbsResolver } from './abstract-breadcrumbs.resolver';

@Injectable({ providedIn: 'root' })
export class DefaultBreadcrumbsResolver extends AbstractBreadcrumbsResolver {
  public constructor(private routingService: RoutingService) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): IPostProcessBreadcrumb {
    const data: Data = route.data;
    const path: string = this.getFullPath(route);

    const isLinkToPageAvailable: boolean = data.breadcrumbs && data.breadcrumbs.isLinkToPageAvailable;
    const paramsHandling: BreadcrumbParamsHandling =
      typeof data.breadcrumbs?.queryParamsHandling === 'string' ? data.breadcrumbs.queryParamsHandling : 'merge';

    const queryParams: Params = paramsHandling === 'none' ? {} : route.queryParams;

    return this.routingService.resolvePageTitle(data.title).pipe(
      map((pageTitle: string) => [
        {
          text: null,
          path: path,
          textTranslated: pageTitle,
          isLinkToPageAvailable: isLinkToPageAvailable,
          queryParams: queryParams,
          queryParamsHandling: paramsHandling,
        },
      ]),
    );
  }
}
