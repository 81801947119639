import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';
import { En301549Version } from '../../../interfaces/audit-rule.interface';

const en301549: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: 'Usage without vision',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.1',
    [$successCriteria.title]: '4.2.1 Usage without vision',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Usage with limited vision',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.2',
    [$successCriteria.title]: '4.2.2 Usage with limited vision',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Usage without perception of colour',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.3',
    [$successCriteria.title]: '4.2.3 Usage without perception of colour',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Usage without hearing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.4',
    [$successCriteria.title]: '4.2.4 Usage without hearing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Usage with limited hearing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.5',
    [$successCriteria.title]: '4.2.5 Usage with limited hearing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Usage with no or limited vocal capability',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.6',
    [$successCriteria.title]: '4.2.6 Usage with no or limited vocal capability',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Usage with limited manipulation or strength',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.7',
    [$successCriteria.title]: '4.2.7 Usage with limited manipulation or strength',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: '4.2.8 Usage with limited reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.8',
    [$successCriteria.title]: '4.2.8 Usage with limited reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: '4.2.9 Minimize photosensitive seizure triggers',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.9',
    [$successCriteria.title]: '4.2.9 Minimize photosensitive seizure triggers',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Usage with limited cognition, language or learning',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.10',
    [$successCriteria.title]: '4.2.10 Usage with limited cognition, language or learning',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Privacy',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '4.2.11',
    [$successCriteria.title]: '4.2.11 Privacy',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Closed functionality',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1',
    [$successCriteria.title]: '5.1 Closed functionality',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.2',
    [$successCriteria.title]: '5.1.2 General',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Closed functionality',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.2.1',
    [$successCriteria.title]: '5.1.2.1 Closed functionality',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Assistive technology',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.2.2',
    [$successCriteria.title]: '5.1.2.2 Assistive technology',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-visual access',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3',
    [$successCriteria.title]: '5.1.3 Non-visual access',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio output of visual information',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.1',
    [$successCriteria.title]: '5.1.3.1 Audio output of visual information',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Auditory output delivery including speech',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.2',
    [$successCriteria.title]: '5.1.3.2 Auditory output delivery including speech',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Auditory output correlation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.3',
    [$successCriteria.title]: '5.1.3.3 Auditory output correlation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech output user control',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.4',
    [$successCriteria.title]: '5.1.3.4 Speech output user control',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech output automatic interruption',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.5',
    [$successCriteria.title]: '5.1.3.5 Speech output automatic interruption',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech output for non-text content',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.6',
    [$successCriteria.title]: '5.1.3.6 Speech output for non-text content',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech output for video information',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.7',
    [$successCriteria.title]: '5.1.3.7 Speech output for video information',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Masked entry',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.8',
    [$successCriteria.title]: '5.1.3.8 Masked entry',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Private access to personal data',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.9',
    [$successCriteria.title]: '5.1.3.9 Private access to personal data',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-interfering audio output',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.10',
    [$successCriteria.title]: '5.1.3.10 Non-interfering audio output',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Private listening volume',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.11',
    [$successCriteria.title]: '5.1.3.11 Private listening volume',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speaker volume',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.12',
    [$successCriteria.title]: '5.1.3.12 Speaker volume',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Volume reset',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.13',
    [$successCriteria.title]: '5.1.3.13 Volume reset',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Spoken languages',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.14',
    [$successCriteria.title]: '5.1.3.14 Spoken languages',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-visual error identification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.15',
    [$successCriteria.title]: '5.1.3.15 Non-visual error identification',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Receipts, tickets, and transactional outputs',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.3.16',
    [$successCriteria.title]: '5.1.3.16 Receipts, tickets, and transactional outputs',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Functionality closed to text enlargement',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.4',
    [$successCriteria.title]: '5.1.4 Functionality closed to text enlargement',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Visual output for auditory information',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.5',
    [$successCriteria.title]: '5.1.5 Visual output for auditory information',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Operation without keyboard interface',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.6',
    [$successCriteria.title]: '5.1.6 Operation without keyboard interface',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Closed functionality',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.6.1',
    [$successCriteria.title]: '5.1.6.1 Closed functionality',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Input focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.6.2',
    [$successCriteria.title]: '5.1.6.2 Input focus',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Access without speech',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.1.7',
    [$successCriteria.title]: '5.1.7 Access without speech',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Activation of accessibility features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.2',
    [$successCriteria.title]: '5.2 Activation of accessibility features',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Biometrics',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.3',
    [$successCriteria.title]: '5.3 Biometrics',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Preservation of accessibility information during conversion',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.4',
    [$successCriteria.title]: '5.4 Preservation of accessibility information during conversion',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Operable parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.5',
    [$successCriteria.title]: '5.5 Operable parts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Means of operation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.5.1',
    [$successCriteria.title]: '5.5.1 Means of operation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Operable parts discernibility',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.5.2',
    [$successCriteria.title]: '5.5.2 Operable parts discernibility',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Locking or toggle controls',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.6',
    [$successCriteria.title]: '5.6 Locking or toggle controls',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Tactile or auditory status',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.6.1',
    [$successCriteria.title]: '5.6.1 Tactile or auditory status',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Visual status',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.6.2',
    [$successCriteria.title]: '5.6.2 Visual status',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Key repeat',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.7',
    [$successCriteria.title]: '5.7 Key repeat',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Double-strike key acceptance',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.8',
    [$successCriteria.title]: '5.8 Double-strike key acceptance',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Simultaneous user actions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '5.9',
    [$successCriteria.title]: '5.9 Simultaneous user actions',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio bandwidth for speech',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.1',
    [$successCriteria.title]: '6.1 Audio bandwidth for speech',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Real-time text (RTT) functionality',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2',
    [$successCriteria.title]: '6.2 Real-time text (RTT) functionality',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'RTT communication',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.1.1',
    [$successCriteria.title]: '6.2.1.1 RTT communication',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Concurrent voice and text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.1.2',
    [$successCriteria.title]: '6.2.1.2 Concurrent voice and text',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Visually distinguishable display',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.2.1',
    [$successCriteria.title]: '6.2.2.1 Visually distinguishable display',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Programmatically determinable send and receive direction',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.2.2',
    [$successCriteria.title]: '6.2.2.2 Programmatically determinable send and receive direction',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speaker identification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.2.3',
    [$successCriteria.title]: '6.2.2.3 Speaker identification',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Visual indicator of Audio with RTT',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.2.4',
    [$successCriteria.title]: '6.2.2.4 Visual indicator of Audio with RTT',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Interoperability',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.3',
    [$successCriteria.title]: '6.2.3 Interoperability',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'RTT responsiveness',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.2.4',
    [$successCriteria.title]: '6.2.4 RTT responsiveness',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Caller ID',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.3',
    [$successCriteria.title]: '6.3 Caller ID',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Alternatives to voice-based services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.4',
    [$successCriteria.title]: '6.4 Alternatives to voice-based services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Video communication',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.5',
    [$successCriteria.title]: '6.5 Video communication',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'General (informative)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.5.1',
    [$successCriteria.title]: '6.5.1 General (informative)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Resolution',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.5.2',
    [$successCriteria.title]: '6.5.2 Resolution',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Frame rate',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.5.3',
    [$successCriteria.title]: '6.5.3 Frame rate',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Synchronization between audio and video',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.5.4',
    [$successCriteria.title]: '6.5.4 Synchronization between audio and video',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Visual indicator of audio with video',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.5.5',
    [$successCriteria.title]: '6.5.5 Visual indicator of audio with video',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speaker identification with video (sign language) communication',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.5.6',
    [$successCriteria.title]: '6.5.6 Speaker identification with video (sign language) communication',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Alternatives to video-based services (advisory only)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '6.6',
    [$successCriteria.title]: '6.6 Alternatives to video-based services (advisory only)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Caption processing technology',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.1',
    [$successCriteria.title]: '7.1 Caption processing technology',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captioning playback',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.1.1',
    [$successCriteria.title]: '7.1.1 Captioning playback',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captioning synchronization',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.1.2',
    [$successCriteria.title]: '7.1.2 Captioning synchronization',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Preservation of captioning',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.1.3',
    [$successCriteria.title]: '7.1.3 Preservation of captioning',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions characteristics',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.1.4',
    [$successCriteria.title]: '7.1.4 Captions characteristics',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Spoken subtitles',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.1.5',
    [$successCriteria.title]: '7.1.5 Spoken subtitles',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description playback',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.2.1',
    [$successCriteria.title]: '7.2.1 Audio description playback',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description synchronization',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.2.2',
    [$successCriteria.title]: '7.2.2 Audio description synchronization',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Preservation of audio description',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.2.3',
    [$successCriteria.title]: '7.2.3 Preservation of audio description',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User controls for captions and audio description',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '7.3',
    [$successCriteria.title]: '7.3 User controls for captions and audio description',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Generic requirements',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.1.1',
    [$successCriteria.title]: '8.1.1 Generic requirements',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Standard connections',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.1.2',
    [$successCriteria.title]: '8.1.2 Standard connections',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Colour',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.1.3',
    [$successCriteria.title]: '8.1.3 Colour',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Hardware products with speech output',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.2',
    [$successCriteria.title]: '8.2 Hardware products with speech output',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech volume range',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.2.1.1',
    [$successCriteria.title]: '8.2.1.1 Speech volume range',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Incremental volume control',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.2.1.2',
    [$successCriteria.title]: '8.2.1.2 Incremental volume control',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Fixed-line devices',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.2.2.1',
    [$successCriteria.title]: '8.2.2.1 Fixed-line devices',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Wireless communication devices',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.2.2.2',
    [$successCriteria.title]: '8.2.2.2 Wireless communication devices',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Stationary ICT',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3',
    [$successCriteria.title]: '8.3 Stationary ICT',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Forward or side reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.1',
    [$successCriteria.title]: '8.3.1 Forward or side reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Unobstructed high forward reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.1',
    [$successCriteria.title]: '8.3.2.1 Unobstructed high forward reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Unobstructed low forward reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.2',
    [$successCriteria.title]: '8.3.2.2 Unobstructed low forward reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Clear space',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.3.1',
    [$successCriteria.title]: '8.3.2.3.1 Clear space',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Obstructed (< 510 mm) forward reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.3.2',
    [$successCriteria.title]: '8.3.2.3.2 Obstructed (< 510 mm) forward reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Obstructed (< 635 mm) forward reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.3.3',
    [$successCriteria.title]: '8.3.2.3.3 Obstructed (< 635 mm) forward reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Knee and toe clearance width',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.4',
    [$successCriteria.title]: '8.3.2.4 Knee and toe clearance width',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Toe clearance',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.5',
    [$successCriteria.title]: '8.3.2.5 Toe clearance',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Knee clearance',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.2.6',
    [$successCriteria.title]: '8.3.2.6 Knee clearance',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Unobstructed high side reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.3.1',
    [$successCriteria.title]: '8.3.3.1 Unobstructed high side reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Unobstructed low side reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.3.2',
    [$successCriteria.title]: '8.3.3.2 Unobstructed low side reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Obstructed (≤ 255 mm) side reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.3.3.1',
    [$successCriteria.title]: '8.3.3.3.1 Obstructed (≤ 255 mm) side reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Obstructed (≤ 610 mm) side reach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.3.3.2',
    [$successCriteria.title]: '8.3.3.3.2 Obstructed (≤ 610 mm) side reach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Change in level',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.4.1',
    [$successCriteria.title]: '8.3.4.1 Change in level',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Clear floor or ground space',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.4.2',
    [$successCriteria.title]: '8.3.4.2 Clear floor or ground space',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Approach - General',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.4.3.1',
    [$successCriteria.title]: '8.3.4.3.1 Approach - General',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Forward approach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.4.3.2',
    [$successCriteria.title]: '8.3.4.3.2 Forward approach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Parallel approach',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.4.3.3',
    [$successCriteria.title]: '8.3.4.3.3 Parallel approach',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Visibility',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.5',
    [$successCriteria.title]: '8.3.5 Visibility',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Installation instructions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.3.6',
    [$successCriteria.title]: '8.3.6 Installation instructions',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Mechanically Operable parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.4',
    [$successCriteria.title]: '8.4 Mechanically Operable parts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Numeric keys',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.4.1',
    [$successCriteria.title]: '8.4.1 Numeric keys',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Means of operation of mechanical parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.4.2.1',
    [$successCriteria.title]: '8.4.2.1 Means of operation of mechanical parts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Force of operation of mechanical parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.4.2.2',
    [$successCriteria.title]: '8.4.2.2 Force of operation of mechanical parts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Keys, tickets and fare cards',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.4.3',
    [$successCriteria.title]: '8.4.3 Keys, tickets and fare cards',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Tactile indication of speech mode',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '8.5',
    [$successCriteria.title]: '8.5 Tactile indication of speech mode',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text content',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.1.1',
    [$successCriteria.title]: '9.1.1.1 Non-text content',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio-only and video- only (pre-recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.2.1',
    [$successCriteria.title]: '9.1.2.1 Audio-only and video- only (pre-recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions (pre-recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.2.2',
    [$successCriteria.title]: '9.1.2.2 Captions (pre-recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description or media alternative (pre-recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.2.3',
    [$successCriteria.title]: '9.1.2.3 Audio description or media alternative (pre-recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description (pre-recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.2.5',
    [$successCriteria.title]: '9.1.2.5 Audio description (pre-recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Info and relationships',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.3.1',
    [$successCriteria.title]: '9.1.3.1 Info and relationships',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Meaningful sequence',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.3.2',
    [$successCriteria.title]: '9.1.3.2 Meaningful sequence',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Sensory characteristics',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.3.3',
    [$successCriteria.title]: '9.1.3.3 Sensory characteristics',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Orientation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.3.4',
    [$successCriteria.title]: '9.1.3.4 Orientation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Identify input purpose',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.3.5',
    [$successCriteria.title]: '9.1.3.5 Identify input purpose',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Use of colour',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.1',
    [$successCriteria.title]: '9.1.4.1 Use of colour',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio control',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.2',
    [$successCriteria.title]: '9.1.4.2 Audio control',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Contrast (minimum)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.3',
    [$successCriteria.title]: '9.1.4.3 Contrast (minimum)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Resize text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.4',
    [$successCriteria.title]: '9.1.4.4 Resize text',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Images of text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.5',
    [$successCriteria.title]: '9.1.4.5 Images of text',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Reflow',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.10',
    [$successCriteria.title]: '9.1.4.10 Reflow',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text contrast',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.11',
    [$successCriteria.title]: '9.1.4.11 Non-text contrast',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Text spacing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.12',
    [$successCriteria.title]: '9.1.4.12 Text spacing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Content on hover or focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.1.4.13',
    [$successCriteria.title]: '9.1.4.13 Content on hover or focus',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Keyboard',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.1.1',
    [$successCriteria.title]: '9.2.1.1 Keyboard',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'No keyboard trap',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.1.2',
    [$successCriteria.title]: '9.2.1.2 No keyboard trap',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Character key shortcuts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.1.4',
    [$successCriteria.title]: '9.2.1.4 Character key shortcuts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Timing adjustable',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.2.1',
    [$successCriteria.title]: '9.2.2.1 Timing adjustable',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pause, stop, hide',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.2.2',
    [$successCriteria.title]: '9.2.2.2 Pause, stop, hide',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Three flashes or below threshold',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.3.1',
    [$successCriteria.title]: '9.2.3.1 Three flashes or below threshold',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Bypass blocks',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.4.1',
    [$successCriteria.title]: '9.2.4.1 Bypass blocks',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Page titled',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.4.2',
    [$successCriteria.title]: '9.2.4.2 Page titled',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus Order',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.4.3',
    [$successCriteria.title]: '9.2.4.3 Focus Order',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Link purpose (in context)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.4.4',
    [$successCriteria.title]: '9.2.4.4 Link purpose (in context)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Multiple ways',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.4.5',
    [$successCriteria.title]: '9.2.4.5 Multiple ways',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Headings and labels',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.4.6',
    [$successCriteria.title]: '9.2.4.6 Headings and labels',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus visible',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.4.7',
    [$successCriteria.title]: '9.2.4.7 Focus visible',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pointer gestures',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.5.1',
    [$successCriteria.title]: '9.2.5.1 Pointer gestures',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pointer cancellation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.5.2',
    [$successCriteria.title]: '9.2.5.2 Pointer cancellation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Label in name',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.5.3',
    [$successCriteria.title]: '9.2.5.3 Label in name',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Motion actuation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.2.5.4',
    [$successCriteria.title]: '9.2.5.4 Motion actuation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of page',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.1.1',
    [$successCriteria.title]: '9.3.1.1 Language of page',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.1.2',
    [$successCriteria.title]: '9.3.1.2 Language of parts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.2.1',
    [$successCriteria.title]: '9.3.2.1 On focus',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On input',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.2.2',
    [$successCriteria.title]: '9.3.2.2 On input',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Consistent navigation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.2.3',
    [$successCriteria.title]: '9.3.2.3 Consistent navigation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Consistent identification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.2.4',
    [$successCriteria.title]: '9.3.2.4 Consistent identification',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error identification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.3.1',
    [$successCriteria.title]: '9.3.3.1 Error identification',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Labels or instructions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.3.2',
    [$successCriteria.title]: '9.3.3.2 Labels or instructions',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error suggestion',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.3.3',
    [$successCriteria.title]: '9.3.3.3 Error suggestion',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error prevention (legal, financial, data)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.3.3.4',
    [$successCriteria.title]: '9.3.3.4 Error prevention (legal, financial, data)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Parsing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.4.1.1',
    [$successCriteria.title]: '9.4.1.1 Parsing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Name, role, value',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.4.1.2',
    [$successCriteria.title]: '9.4.1.2 Name, role, value',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Status messages',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.4.1.3',
    [$successCriteria.title]: '9.4.1.3 Status messages',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'WCAG conformance requirements',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '9.6',
    [$successCriteria.title]: '9.6 WCAG conformance requirements',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text content',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.1.1',
    [$successCriteria.title]: '10.1.1.1 Non-text content',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: '10.1.2.1 Audio-only and video- only (pre-recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.2.1',
    [$successCriteria.title]: '10.1.2.1 Audio-only and video- only (pre-recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions (pre- recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.2.2',
    [$successCriteria.title]: '10.1.2.2 Captions (pre- recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description or media alternative (pre- recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.2.3',
    [$successCriteria.title]: '10.1.2.3 Audio description or media alternative (pre- recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions (live)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.2.4',
    [$successCriteria.title]: '10.1.2.4 Captions (live)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description (pre- recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.2.5',
    [$successCriteria.title]: '10.1.2.5 Audio description (pre- recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Info and relationships',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.3.1',
    [$successCriteria.title]: '10.1.3.1 Info and relationships',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Meaningful sequence',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.3.2',
    [$successCriteria.title]: '10.1.3.2 Meaningful sequence',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Sensory characteristics',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.3.3',
    [$successCriteria.title]: '10.1.3.3 Sensory characteristics',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Orientation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.3.4',
    [$successCriteria.title]: '10.1.3.4 Orientation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Identify input purpose',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.3.5',
    [$successCriteria.title]: '10.1.3.5 Identify input purpose',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Use of colour',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.1',
    [$successCriteria.title]: '10.1.4.1 Use of colour',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio control',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.2',
    [$successCriteria.title]: '10.1.4.2 Audio control',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Contrast (minimum)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.3',
    [$successCriteria.title]: '10.1.4.3 Contrast (minimum)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Resize text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.4',
    [$successCriteria.title]: '10.1.4.4 Resize text',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Images of text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.5',
    [$successCriteria.title]: '10.1.4.5 Images of text',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Reflow',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.10',
    [$successCriteria.title]: '10.1.4.10 Reflow',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text contrast',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.11',
    [$successCriteria.title]: '10.1.4.11 Non-text contrast',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Text spacing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.12',
    [$successCriteria.title]: '10.1.4.12 Text spacing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Content on hover or focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.1.4.13',
    [$successCriteria.title]: '10.1.4.13 Content on hover or focus',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Keyboard',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.1.1',
    [$successCriteria.title]: '10.2.1.1 Keyboard',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'No keyboard trap',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.1.2',
    [$successCriteria.title]: '10.2.1.2 No keyboard trap',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Character key shortcuts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.1.4',
    [$successCriteria.title]: '10.2.1.4 Character key shortcuts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Timing adjustable',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.2.1',
    [$successCriteria.title]: '10.2.2.1 Timing adjustable',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pause, stop, hide',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.2.2',
    [$successCriteria.title]: '10.2.2.2 Pause, stop, hide',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Three flashes or below threshold',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.3.1',
    [$successCriteria.title]: '10.2.3.1 Three flashes or below threshold',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Document titled',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.4.2',
    [$successCriteria.title]: '10.2.4.2 Document titled',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus Order',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.4.3',
    [$successCriteria.title]: '10.2.4.3 Focus Order',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Link purpose (in context)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.4.4',
    [$successCriteria.title]: '10.2.4.4 Link purpose (in context)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Headings and labels',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.4.6',
    [$successCriteria.title]: '10.2.4.6 Headings and labels',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus visible',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.4.7',
    [$successCriteria.title]: '10.2.4.7 Focus visible',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pointer gestures',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.5.1',
    [$successCriteria.title]: '10.2.5.1 Pointer gestures',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pointer cancellation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.5.2',
    [$successCriteria.title]: '10.2.5.2 Pointer cancellation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Label in name',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.5.3',
    [$successCriteria.title]: '10.2.5.3 Label in name',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Motion actuation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.2.5.4',
    [$successCriteria.title]: '10.2.5.4 Motion actuation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of document',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.1.1',
    [$successCriteria.title]: '10.3.1.1 Language of document',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of parts',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.1.2',
    [$successCriteria.title]: '10.3.1.2 Language of parts',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.2.1',
    [$successCriteria.title]: '10.3.2.1 On focus',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On input',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.2.2',
    [$successCriteria.title]: '10.3.2.2 On input',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error identification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.3.1',
    [$successCriteria.title]: '10.3.3.1 Error identification',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Labels or instructions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.3.2',
    [$successCriteria.title]: '10.3.3.2 Labels or instructions',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error suggestion',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.3.3',
    [$successCriteria.title]: '10.3.3.3 Error suggestion',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error prevention (legal, financial, data)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.3.3.4',
    [$successCriteria.title]: '10.3.3.4 Error prevention (legal, financial, data)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Parsing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.4.1.1',
    [$successCriteria.title]: '10.4.1.1 Parsing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Name, role, value',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.4.1.2',
    [$successCriteria.title]: '10.4.1.2 Name, role, value',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Status messages',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.4.1.3',
    [$successCriteria.title]: '10.4.1.3 Status messages',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Caption positioning',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.5',
    [$successCriteria.title]: '10.5 Caption positioning',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description timing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '10.6',
    [$successCriteria.title]: '10.6 Audio description timing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text content (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.1.1.1',
    [$successCriteria.title]: '11.1.1.1.1 Non-text content (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text content (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.1.1.2',
    [$successCriteria.title]: '11.1.1.1.2 Non-text content (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio-only and video-only (pre-recorded - open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.2.1.1',
    [$successCriteria.title]: '11.1.2.1.1 Audio-only and video-only (pre-recorded - open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: '11.1.2.1.2 Audio-only and video-only (pre-recorded - closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.2.1.2',
    [$successCriteria.title]: '11.1.2.1.2 Audio-only and video-only (pre-recorded - closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions (pre- recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.2.2',
    [$successCriteria.title]: '11.1.2.2 Captions (pre- recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description or media alternative (pre-recorded - open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.2.3.1',
    [$successCriteria.title]: '11.1.2.3.1 Audio description or media alternative (pre-recorded - open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description or media alternative (pre-recorded - closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.2.3.2',
    [$successCriteria.title]: '11.1.2.3.2 Audio description or media alternative (pre-recorded - closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captions (live)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.2.4',
    [$successCriteria.title]: '11.1.2.4 Captions (live)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio description (pre- recorded)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.2.5',
    [$successCriteria.title]: '11.1.2.5 Audio description (pre- recorded)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Info and relationships (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.1.1',
    [$successCriteria.title]: '11.1.3.1.1 Info and relationships (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Info and relationships (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.1.2',
    [$successCriteria.title]: '11.1.3.1.2 Info and relationships (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Meaningful sequence (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.2.1',
    [$successCriteria.title]: '11.1.3.2.1 Meaningful sequence (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Meaningful sequence (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.2.2',
    [$successCriteria.title]: '11.1.3.2.2 Meaningful sequence (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Sensory characteristics',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.3',
    [$successCriteria.title]: '11.1.3.3 Sensory characteristics',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Orientation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.4',
    [$successCriteria.title]: '11.1.3.4 Orientation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Identify input purpose (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.5.1',
    [$successCriteria.title]: '11.1.3.5.1 Identify input purpose (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Identify input purpose (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.3.5.2',
    [$successCriteria.title]: '11.1.3.5.2 Identify input purpose (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Use of colour',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.1',
    [$successCriteria.title]: '11.1.4.1 Use of colour',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Audio control',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.2',
    [$successCriteria.title]: '11.1.4.2 Audio control',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Contrast (minimum)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.3',
    [$successCriteria.title]: '11.1.4.3 Contrast (minimum)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Resize text (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.4.1',
    [$successCriteria.title]: '11.1.4.4.1 Resize text (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Resize text (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.4.2',
    [$successCriteria.title]: '11.1.4.4.2 Resize text (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Images of text (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.5.1',
    [$successCriteria.title]: '11.1.4.5.1 Images of text (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Images of text (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.5.2',
    [$successCriteria.title]: '11.1.4.5.2 Images of text (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Reflow',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.10',
    [$successCriteria.title]: '11.1.4.10 Reflow',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-text contrast',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.11',
    [$successCriteria.title]: '11.1.4.11 Non-text contrast',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Text spacing',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.12',
    [$successCriteria.title]: '11.1.4.12 Text spacing',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Content on hover or focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.1.4.13',
    [$successCriteria.title]: '11.1.4.13 Content on hover or focus',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Keyboard (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.1.1.1',
    [$successCriteria.title]: '11.2.1.1.1 Keyboard (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Keyboard (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.1.1.2',
    [$successCriteria.title]: '11.2.1.1.2 Keyboard (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'No keyboard trap',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.1.2',
    [$successCriteria.title]: '11.2.1.2 No keyboard trap',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Character key shortcuts (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.1.4.1',
    [$successCriteria.title]: '11.2.1.4.1 Character key shortcuts (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Character key shortcuts (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.1.4.2',
    [$successCriteria.title]: '11.2.1.4.2 Character key shortcuts (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Timing adjustable',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.2.1',
    [$successCriteria.title]: '11.2.2.1 Timing adjustable',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pause, stop, hide',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.2.2',
    [$successCriteria.title]: '11.2.2.2 Pause, stop, hide',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Three flashes or below threshold',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.3.1',
    [$successCriteria.title]: '11.2.3.1 Three flashes or below threshold',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus order',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.4.3',
    [$successCriteria.title]: '11.2.4.3 Focus order',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Link purpose (in context)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.4.4',
    [$successCriteria.title]: '11.2.4.4 Link purpose (in context)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Headings and labels',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.4.6',
    [$successCriteria.title]: '11.2.4.6 Headings and labels',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Focus visible',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.4.7',
    [$successCriteria.title]: '11.2.4.7 Focus visible',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pointer gestures',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.5.1',
    [$successCriteria.title]: '11.2.5.1 Pointer gestures',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Pointer cancellation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.5.2',
    [$successCriteria.title]: '11.2.5.2 Pointer cancellation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Label in name (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.5.3.1',
    [$successCriteria.title]: '11.2.5.3.1 Label in name (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Label in name (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.5.3.2',
    [$successCriteria.title]: '11.2.5.3.2 Label in name (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Motion actuation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.2.5.4',
    [$successCriteria.title]: '11.2.5.4 Motion actuation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of software (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.1.1.1',
    [$successCriteria.title]: '11.3.1.1.1 Language of software (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Language of software (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.1.1.2',
    [$successCriteria.title]: '11.3.1.1.2 Language of software (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On focus',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.2.1',
    [$successCriteria.title]: '11.3.2.1 On focus',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'On input',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.2.2',
    [$successCriteria.title]: '11.3.2.2 On input',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error identification (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.3.1.1',
    [$successCriteria.title]: '11.3.3.1.1 Error identification (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error Identification (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.3.1.2',
    [$successCriteria.title]: '11.3.3.1.2 Error Identification (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Labels or instructions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.3.2',
    [$successCriteria.title]: '11.3.3.2 Labels or instructions',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error suggestion',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.3.3',
    [$successCriteria.title]: '11.3.3.3 Error suggestion',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Error prevention (legal, financial, data)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.3.3.4',
    [$successCriteria.title]: '11.3.3.4 Error prevention (legal, financial, data)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Parsing (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.4.1.1.1',
    [$successCriteria.title]: '11.4.1.1.1 Parsing (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Parsing (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.4.1.1.2',
    [$successCriteria.title]: '11.4.1.1.2 Parsing (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Name, role, value (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.4.1.2.1',
    [$successCriteria.title]: '11.4.1.2.1 Name, role, value (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Name, role, value (closed functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.4.1.2.2',
    [$successCriteria.title]: '11.4.1.2.2 Name, role, value (closed functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Status messages (open functionality)',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.4.1.3.1',
    [$successCriteria.title]: '11.4.1.3.1 Status messages (open functionality)',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Closed functionality',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.1',
    [$successCriteria.title]: '11.5.1 Closed functionality',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Platform accessibility service support for software that provides a user interface',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.1',
    [$successCriteria.title]: '11.5.2.1 Platform accessibility service support for software that provides a user interface',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Platform accessibility service support for assistive technologies',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.2',
    [$successCriteria.title]: '11.5.2.2 Platform accessibility service support for assistive technologies',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Use of accessibility services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.3',
    [$successCriteria.title]: '11.5.2.3 Use of accessibility services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Assistive technology',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.4',
    [$successCriteria.title]: '11.5.2.4 Assistive technology',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Object information',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.5',
    [$successCriteria.title]: '11.5.2.5 Object information',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Row, column, and headers',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.6',
    [$successCriteria.title]: '11.5.2.6 Row, column, and headers',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Values',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.7',
    [$successCriteria.title]: '11.5.2.7 Values',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Label relationships',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.8',
    [$successCriteria.title]: '11.5.2.8 Label relationships',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Parent-child relationships',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.9',
    [$successCriteria.title]: '11.5.2.9 Parent-child relationships',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.10',
    [$successCriteria.title]: '11.5.2.10 Text',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'List of available actions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.11',
    [$successCriteria.title]: '11.5.2.11 List of available actions',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Execution of available actions',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.12',
    [$successCriteria.title]: '11.5.2.12 Execution of available actions',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Tracking of focus and selection attributes',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.13',
    [$successCriteria.title]: '11.5.2.13 Tracking of focus and selection attributes',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Modification of focus and selection attributes',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.14',
    [$successCriteria.title]: '11.5.2.14 Modification of focus and selection attributes',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Change notification',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.15',
    [$successCriteria.title]: '11.5.2.15 Change notification',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Modifications of states and properties',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.16',
    [$successCriteria.title]: '11.5.2.16 Modifications of states and properties',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Modifications of values and text',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.5.2.17',
    [$successCriteria.title]: '11.5.2.17 Modifications of values and text',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User control of accessibility features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.6.1',
    [$successCriteria.title]: '11.6.1 User control of accessibility features',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'No disruption of accessibility features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.6.2',
    [$successCriteria.title]: '11.6.2 No disruption of accessibility features',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'User preferences',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.7',
    [$successCriteria.title]: '11.7 User preferences',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Content technology',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.8.1',
    [$successCriteria.title]: '11.8.1 Content technology',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Accessible content creation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.8.2',
    [$successCriteria.title]: '11.8.2 Accessible content creation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Preservation of accessibility information in transformations',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.8.3',
    [$successCriteria.title]: '11.8.3 Preservation of accessibility information in transformations',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Repair assistance',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.8.4',
    [$successCriteria.title]: '11.8.4 Repair assistance',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Templates',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '11.8.5',
    [$successCriteria.title]: '11.8.5 Templates',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Product documentation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '12.1',
    [$successCriteria.title]: '12.1 Product documentation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: '12.1.1 Accessibility and compatibility features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '12.1.1',
    [$successCriteria.title]: '12.1.1 Accessibility and compatibility features',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Accessible documentation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '12.1.2',
    [$successCriteria.title]: '12.1.2 Accessible documentation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Support Services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '12.2',
    [$successCriteria.title]: '12.2 Support Services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Information on accessibility and compatibility features',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '12.2.2',
    [$successCriteria.title]: '12.2.2 Information on accessibility and compatibility features',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Effective communication',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '12.2.3',
    [$successCriteria.title]: '12.2.3 Effective communication',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Accessible documentation',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '12.2.4',
    [$successCriteria.title]: '12.2.4 Accessible documentation',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Relay services requirements',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.1',
    [$successCriteria.title]: '13.1 Relay services requirements',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Text relay services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.1.2',
    [$successCriteria.title]: '13.1.2 Text relay services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Sign relay services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.1.3',
    [$successCriteria.title]: '13.1.3 Sign relay services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Lip-reading relay services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.1.4',
    [$successCriteria.title]: '13.1.4 Lip-reading relay services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Captioned telephony services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.1.5',
    [$successCriteria.title]: '13.1.5 Captioned telephony services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Speech to speech relay services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.1.6',
    [$successCriteria.title]: '13.1.6 Speech to speech relay services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Access to relay services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.2',
    [$successCriteria.title]: '13.2 Access to relay services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Access to emergency services',
    [$successCriteria.level]: '',
    [$successCriteria.num]: '13.3',
    [$successCriteria.title]: '13.3 Access to emergency services',
    [$successCriteria.versions]: [En301549Version.v321],
    [$successCriteria.url]: '',
  },
];

export class EN301549 implements ISuccessCriteriaIndex {
  public get(num: string): ISuccessCriteria {
    const en301549SuccessCriteria: ISuccessCriteria | undefined = en301549.find(
      (bestPracticeSuccessCriteria: ISuccessCriteria): boolean => bestPracticeSuccessCriteria.num === num,
    );
    if (typeof en301549SuccessCriteria === 'undefined') {
      return null;
    }
    return en301549SuccessCriteria;
  }

  public getAll(): ISuccessCriteria[] {
    return en301549;
  }
}
