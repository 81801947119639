import { SharedCommonUtility } from './common.utility';

export interface ColorRGB {
  red: number;
  green: number;
  blue: number;
} // red,green,blue: 0-255
export interface ColorHSV {
  hue: number;
  saturation: number;
  value: number;
} // hue: 0-360, saturation,value: 0-1

export interface IColorResult {
  color: ColorRGB;
  contrast: number;
  distance: number;
}

const RGB_RANGE: number = 256;
const RGB_MAX_VALUE: number = 255;
const HUE_MAX_VALUE: number = 360;
const HUE_SEXTANT_VALUE: number = 360 / 6;
const SRGB_FACTOR: number = 0.03928;
const LUMINANCE_INF: number = 12.92;
const LUMINANCE_SUP_CONST: number = 0.055;
const LUMINANCE_SUP_CONST2: number = 1.055;
const LUMINANCE_EXP: number = 2.4;
const CONTRAST_FACTOR: number = 0.05;
const RED_LUMINOSITY_FACTOR: number = 0.2126;
const GREEN_LUMINOSITY_FACTOR: number = 0.7152;
const BLUE_LUMINOSITY_FACTOR: number = 0.0722;
const CONTRAST_ROUND_VALUE: number = 100000; // 5 digits rounding
const DISTANCE_ROUND_VALUE: number = 100; // 2 digits rounding
const FINDER_STEP: number = 1;
const MAX_POSSIBLE_VALUE: number = 255;
const DEFAULT_MAX_MOVE: number = 60;
const CONTRAST_THRESHOLD: number = 0.001;
const DEFAULT_MAX_MOVE_HUE: number = 5;
const COMPOSANT_INF: number = SRGB_FACTOR / LUMINANCE_INF;
const LUMINANCE_INVERSE_EXP: number = 1 / LUMINANCE_EXP;

// Note:
// This color finder is iterating over RGB components for finding a set of colors that
// will match the minimum required contrast.
// The returned colors must be as close as possible to the original color.
// In future we can provide an alternate algorithm that can iterate over value or saturation
// in the HSV space for finding the matching colors. We may find different results and performance.
export class ColorFinderRGB {
  private validColors: IColorResult[] = [];
  private colorToKeep: ColorRGB;
  private initialColorHSV: ColorHSV;
  private initialColor: ColorRGB;
  private initialContrast: number;
  private minContrast: number;
  private maxContrast: number;
  private minRed: number;
  private maxRed: number;
  private minGreen: number;
  private maxGreen: number;
  private minBlue: number;
  private maxBlue: number;
  private luminosityToReach: number;
  private mustIncreaseLuminosity: boolean;
  private maxMove: number = DEFAULT_MAX_MOVE;
  private maxMoveHue: number = DEFAULT_MAX_MOVE_HUE;
  public testedColors: number = 0;

  constructor(_colorToKeep: ColorRGB, _colorToChange: ColorRGB, _minContrast: number) {
    this.colorToKeep = _colorToKeep;
    this.initialColorHSV = ColorsUtility.rgbToHSV(_colorToChange);
    this.initialColor = _colorToChange;
    this.minContrast = _minContrast;
    this.maxContrast = _minContrast + CONTRAST_THRESHOLD;
    this.initialContrast = ColorsUtility.rgbContrast(_colorToKeep, _colorToChange);

    if (this.initialContrast < _minContrast) {
      const gap: number = _minContrast - this.initialContrast;
      if (gap < 1) {
        this.maxMove = 60;
      } else if (gap < 2) {
        this.maxMove = 80;
      } else {
        this.maxMove = 110;
      }
    }

    const initialLuminosity = ColorsUtility.rgbLuminosity(_colorToChange);
    const colorToKeepLuminosity = ColorsUtility.rgbLuminosity(_colorToKeep);
    this.mustIncreaseLuminosity = initialLuminosity > colorToKeepLuminosity;
    this.luminosityToReach = this.mustIncreaseLuminosity
      ? _minContrast * (colorToKeepLuminosity + CONTRAST_FACTOR) - CONTRAST_FACTOR
      : (colorToKeepLuminosity + CONTRAST_FACTOR) / _minContrast - CONTRAST_FACTOR;

    this.minRed = _colorToChange.red < this.maxMove ? 0 : _colorToChange.red - this.maxMove;
    this.maxRed = _colorToChange.red + this.maxMove > MAX_POSSIBLE_VALUE ? MAX_POSSIBLE_VALUE : _colorToChange.red + this.maxMove;
    this.minGreen = _colorToChange.green < this.maxMove ? 0 : _colorToChange.green - this.maxMove;
    this.maxGreen =
      _colorToChange.green + this.maxMove > MAX_POSSIBLE_VALUE ? MAX_POSSIBLE_VALUE : _colorToChange.green + this.maxMove;
    this.minBlue = _colorToChange.blue < this.maxMove ? 0 : _colorToChange.blue - this.maxMove;
    this.maxBlue =
      _colorToChange.blue + this.maxMove > MAX_POSSIBLE_VALUE ? MAX_POSSIBLE_VALUE : _colorToChange.blue + this.maxMove;

    this.testedColors = 0;
  }

  private addNewColorIfValid(newColor: ColorRGB): void {
    this.testedColors += 1;
    const contrast = ColorsUtility.rgbContrast(newColor, this.colorToKeep);

    if (contrast > this.minContrast && contrast < this.maxContrast) {
      const newColorHSV: ColorHSV = ColorsUtility.rgbToHSV(newColor);
      const hueMove = Math.abs(newColorHSV.hue - this.initialColorHSV.hue);

      if (hueMove < this.maxMoveHue || hueMove > HUE_MAX_VALUE - this.maxMoveHue) {
        this.validColors.push({
          color: Object.assign({}, newColor),
          contrast: contrast,
          distance: ColorsUtility.rgbCompuPhaseDistance(newColor, this.initialColor),
        });
      }
    }
  }

  private iterateRed(colorToChange: ColorRGB): void {
    const newColor: ColorRGB = Object.assign({}, colorToChange);

    for (let red = this.minRed; red <= this.maxRed; red += FINDER_STEP) {
      newColor.red = red;
      const redContrib: number = ColorsUtility.getComposantValue(newColor.red) * RED_LUMINOSITY_FACTOR;

      for (let green = this.minGreen; green <= this.maxGreen; green += FINDER_STEP) {
        newColor.green = green;
        const greenContrib: number = ColorsUtility.getComposantValue(newColor.green) * GREEN_LUMINOSITY_FACTOR;

        const blueContrib = this.luminosityToReach - redContrib - greenContrib;
        if (blueContrib < 0 || blueContrib > BLUE_LUMINOSITY_FACTOR) {
          continue;
        }

        let blueValue = this.invertComposant(blueContrib / BLUE_LUMINOSITY_FACTOR);
        blueValue = this.mustIncreaseLuminosity ? Math.ceil(blueValue) : Math.floor(blueValue);

        if (blueValue >= this.minBlue && blueValue <= this.maxBlue) {
          newColor.blue = blueValue;
          this.addNewColorIfValid(newColor);
        }
      }
    }
  }

  private invertComposant(composant: number): number {
    let srgb: number = 0;

    if (composant < COMPOSANT_INF) {
      srgb = composant * LUMINANCE_INF;
    } else {
      srgb = Math.pow(composant, LUMINANCE_INVERSE_EXP) * LUMINANCE_SUP_CONST2 - LUMINANCE_SUP_CONST;
    }

    return srgb * MAX_POSSIBLE_VALUE;
  }

  public findColors(): IColorResult[] {
    this.validColors = [];

    if (this.initialContrast >= this.minContrast) {
      this.validColors.push({ color: this.initialColor, contrast: this.initialContrast, distance: 0 });
      return this.validColors;
    }

    this.iterateRed(this.initialColor);

    const sortByDistance = (result1: IColorResult, result2: IColorResult): number => {
      return result1.distance - result2.distance;
    };

    this.validColors = [...this.validColors].sort(sortByDistance);

    return this.validColors;
  }
}

export class ColorsUtility {
  public static getComposantValue(composant: number): number {
    const srgb = composant / RGB_MAX_VALUE;

    if (srgb <= SRGB_FACTOR) {
      return srgb / LUMINANCE_INF;
    }
    return Math.pow((srgb + LUMINANCE_SUP_CONST) / LUMINANCE_SUP_CONST2, LUMINANCE_EXP);
  }

  public static rgbToHSV(rgb: ColorRGB): ColorHSV {
    let min: number = rgb.red < rgb.green ? rgb.red : rgb.green;
    min = min < rgb.blue ? min : rgb.blue;
    let max: number = rgb.red > rgb.green ? rgb.red : rgb.green;
    max = max > rgb.blue ? max : rgb.blue;

    const delta: number = max - min;

    const hsv: ColorHSV = { hue: 0, saturation: 0, value: max / RGB_MAX_VALUE };

    if (delta < 0.001 || max < 0.001) {
      return hsv;
    }

    hsv.saturation = delta / max;

    if (rgb.red === max) {
      hsv.hue = (rgb.green - rgb.blue) / delta;
    } else if (rgb.green === max) {
      hsv.hue = 2 + (rgb.blue - rgb.red) / delta;
    } else {
      hsv.hue = 4 + (rgb.red - rgb.green) / delta;
    }

    hsv.hue = HUE_SEXTANT_VALUE * hsv.hue;
    if (hsv.hue < 0) {
      hsv.hue += HUE_MAX_VALUE;
    }

    return hsv;
  }

  public static rgbLuminosity(rgb: ColorRGB): number {
    return (
      ColorsUtility.getComposantValue(rgb.red) * RED_LUMINOSITY_FACTOR +
      ColorsUtility.getComposantValue(rgb.green) * GREEN_LUMINOSITY_FACTOR +
      ColorsUtility.getComposantValue(rgb.blue) * BLUE_LUMINOSITY_FACTOR
    );
  }

  public static rgbContrast(rgb1: ColorRGB, rgb2: ColorRGB): number {
    const luminosity1: number = ColorsUtility.rgbLuminosity(rgb1);
    const luminosity2: number = ColorsUtility.rgbLuminosity(rgb2);

    let contrast: number = 0;

    if (luminosity1 > luminosity2) {
      contrast = (luminosity1 + CONTRAST_FACTOR) / (luminosity2 + CONTRAST_FACTOR);
    } else {
      contrast = (luminosity2 + CONTRAST_FACTOR) / (luminosity1 + CONTRAST_FACTOR);
    }

    return Math.round(contrast * CONTRAST_ROUND_VALUE) / CONTRAST_ROUND_VALUE;
  }

  // Color distance as described in CompuPhase paper, gives a better result (from the eye point of view)
  // than a standard 3D euclidian distance
  // https://www.compuphase.com/cmetric.htm
  public static rgbCompuPhaseDistance(rgb1: ColorRGB, rgb2: ColorRGB): number {
    const meanRed = (rgb1.red + rgb2.red) / 2;

    const square =
      (2 + meanRed / RGB_RANGE) * Math.pow(rgb1.red - rgb2.red, 2) +
      4 * Math.pow(rgb1.green - rgb2.green, 2) +
      (2 + (RGB_MAX_VALUE - meanRed) / RGB_RANGE) * Math.pow(rgb1.blue - rgb2.blue, 2);

    return Math.round((Math.sqrt(square) / 3) * DISTANCE_ROUND_VALUE) / DISTANCE_ROUND_VALUE;
  }

  // Standard 3D Euclidian distance
  public static rgbEuclidianDistance(rgb1: ColorRGB, rgb2: ColorRGB): number {
    return (
      Math.round(
        Math.sqrt(Math.pow(rgb1.red - rgb2.red, 2) + Math.pow(rgb1.green - rgb2.green, 2) + Math.pow(rgb1.blue - rgb2.blue, 2)) *
          DISTANCE_ROUND_VALUE,
      ) / DISTANCE_ROUND_VALUE
    );
  }

  public static rgbFindCloseColors(
    rgbToKeep: ColorRGB,
    rgbToChange: ColorRGB,
    contrastToMeet: number,
    countLimit: number,
  ): IColorResult[] {
    const colorFinder: ColorFinderRGB = new ColorFinderRGB(rgbToKeep, rgbToChange, contrastToMeet);

    return colorFinder.findColors().slice(0, countLimit);
  }

  public static RGBtoHEX(rgb: ColorRGB): string {
    const toHEX = (x: number): string => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    const rgbToHex = (red: number, green: number, blue: number): string => '#' + [red, green, blue].map(toHEX).join('');

    return rgbToHex(rgb.red, rgb.green, rgb.blue);
  }

  public static HEXtoRGB(hex: string): ColorRGB | null {
    const result: RegExpMatchArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      };
    }
    return null;
  }

  public static RGBStringToColorRGB(rgb: string): ColorRGB | null {
    const values: string[] = rgb?.replace('rgb(', '').replace(')', '').split(',');

    if (SharedCommonUtility.isNullishOrEmpty(values)) {
      return null;
    }
    return {
      red: parseInt(values[0], 10),
      green: parseInt(values[1], 10),
      blue: parseInt(values[2], 10),
    };
  }
}
