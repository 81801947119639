import { Api, ApiQueryOption } from '../constants/api';
import { SharedCommonUtility } from './common.utility';

export const LINKED_PROPERTY_DEFAULT_STRING = 'default';
export const $linkedPropertyDelimiter = '|';

interface Params {
  [key: string]: any;
}

export interface ILinkedPropertyData {
  [Api.digitalPropertyId]?: string;
  [Api.workspaceId]?: string;
}

export class LinkedPropertyUtility {
  public static getLinkedPropertyString(propertyId: string, workspaceId: string): string {
    return `${propertyId || LINKED_PROPERTY_DEFAULT_STRING}${$linkedPropertyDelimiter}${
      workspaceId || LINKED_PROPERTY_DEFAULT_STRING
    }`;
  }

  public static getLinkedPropertyQueryParam(propertyId: string, workspaceId: string): Params {
    return { [ApiQueryOption.linkedPropertyData]: LinkedPropertyUtility.getLinkedPropertyString(propertyId, workspaceId) };
  }

  public static fromLinkedPropertyQueryParam(params: Params): ILinkedPropertyData {
    return LinkedPropertyUtility.fromLinkedPropertyString(params[ApiQueryOption.linkedPropertyData] ?? '');
  }

  public static hasLinkedPropertyParam(params: Params): boolean {
    return SharedCommonUtility.notNullishOrEmpty(params[ApiQueryOption.linkedPropertyData]);
  }

  public static hasLinkedPropertyData(params: Params): boolean {
    const { digitalPropertyId, workspaceId } = LinkedPropertyUtility.fromLinkedPropertyQueryParam(params);
    return typeof digitalPropertyId !== 'undefined' && typeof workspaceId !== 'undefined';
  }

  public static fromLinkedPropertyString(linkedPropertyString: string): ILinkedPropertyData {
    function hasValue(value: string): boolean {
      return !SharedCommonUtility.isStringEmpty(value) && value !== LINKED_PROPERTY_DEFAULT_STRING;
    }

    if (hasValue(linkedPropertyString) === false) {
      return {};
    }

    const [propertyId, workspaceId] = linkedPropertyString.split($linkedPropertyDelimiter);

    return {
      [Api.digitalPropertyId]: hasValue(propertyId) ? propertyId : undefined,
      [Api.workspaceId]: hasValue(workspaceId) ? workspaceId : undefined,
    };
  }

  public static assignLinkedPropertyParam<T extends string | URL>(url: T, propertyId: string, workspaceId: string): T {
    if (url instanceof URL) {
      return setSearchParam(url) as T;
    }

    return setSearchParam(new URL(url as string)).toString() as T;

    function setSearchParam(data: URL): URL {
      const linkedPropertyData: string = LinkedPropertyUtility.getLinkedPropertyString(propertyId, workspaceId);
      data.searchParams.set(ApiQueryOption.linkedPropertyData, linkedPropertyData);
      return data;
    }
  }
}
