import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountSettingsRoutingModule } from './account-settings.routing';
import { SharedModule } from '../../../../../shared/shared.module';
import { AccountSettingsComponent } from './account-settings.component';
import { ChangePasswordComponent } from '../../../../../components/change-password/from-settings/change-password.component';
import { ChangePasswordFromEmailComponent } from '../../../../../components/change-password/from-email/change-password-from-email.component';
import { DeactivateAccountComponent } from '../../../../../components/deactivate-account/deactivate-account.component';
import { ChangePasswordFromSignupComponent } from '../../../../../components/change-password/from-signup/change-password-from-signup.component';

@NgModule({
  imports: [CommonModule, SharedModule, AccountSettingsRoutingModule],
  declarations: [
    AccountSettingsComponent,
    ChangePasswordComponent,
    ChangePasswordFromEmailComponent,
    ChangePasswordFromSignupComponent,
    DeactivateAccountComponent,
  ],
  exports: [AccountSettingsComponent, ChangePasswordFromEmailComponent, ChangePasswordFromSignupComponent],
})
export class AccountSettingsModule {}
