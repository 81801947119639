import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges, ComponentRef } from '@angular/core';
import { $allowedManualAuditAttachmentExt } from '../../../../shared/constants/manual-audit-issues';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { Icons } from '../../shared/eap-icons.component';
import { EapColors } from '../../shared/eap-colors';
import { ModalService } from '../../services/modal.service';
import {
  EditAttachmentModalComponent,
  IEditAttachment,
  IEditAttachmentModalContext,
} from './edit-attachment/edit-attachment-modal.component';
import { ImageFileExtensions } from '../../../../shared/constants/mime-type';

@Component({
  selector: 'app-attachment-thumbnail',
  templateUrl: './attachment-thumbnail.component.html',
  styleUrls: ['./attachment-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentThumbnailComponent implements OnChanges {
  private static readonly IMAGE_FILE_EXTENSIONS: Set<string> = new Set(ImageFileExtensions);

  public readonly Icons: typeof Icons = Icons;
  public readonly EapColors: typeof EapColors = EapColors;
  public $allowedManualAuditAttachmentExt: typeof $allowedManualAuditAttachmentExt;
  public fileExtension: string;
  public displayMissingAltTextWarning: boolean;

  @Input() public fileUploadId: string;
  @Input() public fileName: string;
  @Input() public fileThumbnailPath: string;
  @Input() public fileDownloadLink: string;
  @Input() public fileRelativePath: string;
  @Input() public isEditable: boolean;
  @Input() public isRemovable: boolean;
  @Input() public isInternal: boolean;
  @Input() public altText: string;

  @Output() public updateAttachment: EventEmitter<IEditAttachment> = new EventEmitter();
  @Output() public removeAttachment: EventEmitter<void> = new EventEmitter();

  constructor(private modalService: ModalService) {
    this.$allowedManualAuditAttachmentExt = $allowedManualAuditAttachmentExt;
  }

  public edit(): void {
    const modal: ComponentRef<EditAttachmentModalComponent> = this.modalService.open(EditAttachmentModalComponent);

    const context: IEditAttachmentModalContext = {
      uploadId: this.fileUploadId,
      altText: SharedCommonUtility.notNullish(this.altText) ? this.altText : null,
      onSave: this.updateAttachment,
    };

    modal.instance.init(context);
  }

  public remove(): void {
    this.removeAttachment.emit();
  }

  public ngOnChanges(): void {
    if (SharedCommonUtility.notNullish(this.fileName)) {
      this.fileExtension = SharedCommonUtility.getFileExtension(this.fileName);
    }

    this.isEditable = this.isEditable && AttachmentThumbnailComponent.IMAGE_FILE_EXTENSIONS.has(this.fileExtension);
    this.displayMissingAltTextWarning = this.isEditable && SharedCommonUtility.isNullishOrEmpty(this.altText);
  }
}
