import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DsButtonVariants, Icons, IconStyles } from '@levelaccess/design-system';
import { EapColors } from '../../shared/eap-colors';

export enum AlertType {
  warning = 'warning',
  info = 'info',
  error = 'error',
}

@Component({
  selector: 'app-common-alert-box',
  templateUrl: './common-alert-box.component.html',
  styleUrls: ['./common-alert-box.component.scss'],
})
export class CommonAlertBoxComponent {
  public AlertType: typeof AlertType = AlertType;
  public IconStyles: typeof IconStyles = IconStyles;
  public Icons: typeof Icons = Icons;
  public EapColors: typeof EapColors = EapColors;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;

  @Input()
  public title: string;

  @Input()
  public text: string;

  @Input()
  public hasIcon: boolean = true;

  @Input()
  public alertType: AlertType = AlertType.warning;

  @Input()
  public closeable: boolean;

  @Output()
  public onClose: EventEmitter<void> = new EventEmitter<void>();
}
