import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  ViewChild,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { EChartsOption } from 'echarts/types/dist/shared';
import { isEqual } from 'lodash';

import { IScanSummary } from '../../../../shared/interfaces/scan-summaries.interface';
import { $scanSummaries } from '../../../../shared/constants/scan-summaries';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { TranslateService } from '../../translate/translate.service';

enum scoreRangeLabels {
  low = 'low',
  mid = 'mid',
  high = 'high',
}

@Component({
  selector: 'app-scan-health-chart',
  templateUrl: './scan-health-chart.component.html',
  styleUrls: ['./scan-health-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanHealthChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private pieChart: echarts.EChartsType;

  @ViewChild('scoreChartTarget') private scoreChartTarget: ElementRef<HTMLDivElement>;
  @ViewChild('svgPatterns') private svgPatterns: ElementRef<HTMLElement>;
  @Input() public scanSummary: IScanSummary;
  @Input() public previousScanSummary: IScanSummary;

  public scanScore: number;
  public scoreRanges: Record<string, number[]>;
  public scoreRangeLabels: typeof scoreRangeLabels;
  public scoreChange: {
    class: string;
    message: string;
    score: string;
  };
  public colors: Record<scoreRangeLabels, string>;

  constructor(
    private ref: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.scanSummary = null;
    this.scanScore = null;
    this.scoreRangeLabels = scoreRangeLabels;
    this.scoreRanges = {
      [scoreRangeLabels.low]: [0, 49],
      [scoreRangeLabels.mid]: [50, 79],
      [scoreRangeLabels.high]: [80, 100],
    };
    this.colors = {
      [scoreRangeLabels.low]: '#b10606',
      [scoreRangeLabels.mid]: '#B85000',
      [scoreRangeLabels.high]: '#20652a',
    };
  }

  private initChart(): void {
    if (!this.scoreChartTarget) {
      return;
    }

    this.scanScore = Math.floor(this.scanSummary[$scanSummaries.score]);

    if (this.pieChart) {
      this.pieChart.dispose();
    }

    this.changeDetectorRef.detectChanges();

    const option: EChartsOption = {
      series: {
        type: 'pie',
        radius: [55, 70],
        left: 'center',
        width: '100%',
        animation: false,
        silent: true,
        itemStyle: {
          borderColor: '#FFFFFF',
          borderWidth: 0,
        },
        label: {
          show: false,
        },
        labelLine: {},
        data: [
          {
            value: this.scanScore,
          },
          {
            value: 100 - this.scanScore,
          },
        ],
      },
    };

    echarts.use([PieChart, SVGRenderer]);
    const elChart: HTMLDivElement = this.scoreChartTarget.nativeElement;
    this.pieChart = echarts.init(elChart);
    elChart.querySelector('svg').setAttribute('aria-hidden', 'true');
    this.pieChart.on('finished', () => {
      const xmlns: string = 'http://www.w3.org/2000/svg';
      const scoreLabelWrap: SVGGElement = document.createElementNS(xmlns, 'g') as SVGGElement;
      scoreLabelWrap.setAttribute('class', 'score-label');
      const scoreLabel: SVGTextElement = document.createElementNS(xmlns, 'text') as SVGTextElement;
      scoreLabel.setAttribute('dx', '50%');
      scoreLabel.setAttribute('dy', '50%');
      scoreLabel.setAttribute('text-anchor', 'middle');
      scoreLabel.textContent = String(this.scanScore);
      scoreLabelWrap.appendChild(scoreLabel);
      this.pieChart.getDom().getElementsByTagName('svg')[0].appendChild(scoreLabelWrap);
    });
    this.pieChart.setOption(option);
  }

  public get scoreClass(): string {
    if (
      this.scanScore >= this.scoreRanges[scoreRangeLabels.low][0] &&
      this.scanScore <= this.scoreRanges[scoreRangeLabels.low][1]
    ) {
      return scoreRangeLabels.low;
    } else if (
      this.scanScore >= this.scoreRanges[scoreRangeLabels.mid][0] &&
      this.scanScore <= this.scoreRanges[scoreRangeLabels.mid][1]
    ) {
      return scoreRangeLabels.mid;
    }
    return scoreRangeLabels.high;
  }

  public calculateScoreChange(): void {
    if (SharedCommonUtility.isNullish(this.previousScanSummary)) {
      return;
    }
    const prevScore: number = Math.floor(this.previousScanSummary[$scanSummaries.score]);
    const latestScore: number = Math.floor(this.scanSummary[$scanSummaries.score]);

    if (latestScore - prevScore > 0) {
      this.scoreChange = {
        class: 'score-increase',
        message: this.translateService.instant('score-increase-message', latestScore - prevScore),
        score: String(prevScore),
      };
    } else if (latestScore - prevScore < 0) {
      this.scoreChange = {
        class: 'score-decrease',
        message: this.translateService.instant('score-decrease-message', prevScore - latestScore),
        score: String(prevScore),
      };
    } else {
      this.scoreChange = {
        class: 'score-unchanged',
        message: this.translateService.instant('score-unchanged-message'),
        score: String(prevScore),
      };
    }
  }

  public ngOnInit(): void {
    this.calculateScoreChange();
  }

  public ngAfterViewInit(): void {
    this.ref.nativeElement.querySelectorAll('svg.square').forEach((i: HTMLElement) => {
      i.appendChild(this.svgPatterns.nativeElement);
    });
    this.initChart();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('scanSummary' in changes) {
      if (!isEqual(changes.scanSummary.previousValue, changes.scanSummary.currentValue)) {
        this.initChart();
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.pieChart) {
      this.pieChart.off('click');
    }
  }
}
