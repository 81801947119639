/**
 * Tags not usable for regular scans. Reserved for internal system use only, not by user.
 */
import { reservedSystemTags } from '../constants/scan-tag';

export class ScanTagUtility {
  private static reservedTags: string[] = reservedSystemTags.map((tag: string) => tag.toString().toLowerCase());

  /**
   * Reserved system tags are tags not usable for regular scans. Reserved for internal system use only, not by user.
   *
   * @returns true if [tag] is a reserved tag and cannot be used for regular scans, false otherwise.
   */
  public static isReservedSystemTag(tag: string): boolean {
    return ScanTagUtility.reservedTags.includes(tag.toLowerCase());
  }
}
