export enum $comment {
  _id = '_id',
  version = 'version',
  type = 'type',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  user = 'user',
}

export enum $designReviewFindingActivity {
  designReviewFindingId = 'designReviewFindingId',
  designReviewPageId = 'designReviewPageId',
  designReviewProjectId = 'designReviewProjectId',
  comments = 'comments',
  task = 'task',
  statusUpdates = 'statusUpdates',
  historyChanges = 'historyChanges',
}

export enum $designReviewFindingComment {
  message = 'message',
  designReviewFindingId = 'designReviewFindingId',
  designReviewPageId = 'designReviewPageId',
  designReviewProjectId = 'designReviewProjectId',
}

export enum $manualAuditIssueComment {
  message = 'message',
  screenshot = 'screenshot',
  recommendation = 'recommendation',
  digitalProperty = 'digitalProperty',
  manualAuditFindingId = 'manualAuditFindingId',
  manualAuditIssueId = 'manualAuditIssueId',
  deletedAt = 'deletedAt',
  newManualAuditIssueStatus = 'newManualAuditIssueStatus',
}

export enum $taskCreateComment {
  taskProjectId = 'taskProjectId',
  projectId = 'projectId',
  taskId = 'taskId',
}

export enum $taskDeleteComment {
  taskProjectId = 'taskProjectId',
}

export enum commentType {
  designReviewFindingComment = 'designReviewFindingComment',
  manualAuditIssueComment = 'manualAuditIssueComment',
  taskCreate = 'taskCreate',
  taskDelete = 'taskDelete',
  taskCreateDE = 'taskCreateDE',
  taskDeleteDE = 'taskDeleteDE',
}

export const currentCommentVersion: number = 0;
