<div class="row">
  <div class="col-xl-7">
    <h1>{{ pageTitle }}</h1>

    <h2>axe-core</h2>
    <div [innerHTML]="'attribution_text_axe' | translateHtml"></div>

    <p class="d-flex flex-column">
      <a href="https://github.com/dequelabs/axe-core" target="_blank"> https://github.com/dequelabs/axe-core </a>
      <a href="https://github.com/dequelabs/axe-core/blob/develop/LICENSE" target="_blank">
        https://github.com/dequelabs/axe-core/blob/develop/LICENSE
      </a>
    </p>

    <h2 class="margin-top-lg">IBM Equal Access Accessibility Checker (ACE)</h2>
    <div [innerHTML]="'attribution_text_ace' | translateHtml"></div>

    <p class="d-flex flex-column">
      <a href="https://github.com/IBMa/equal-access" target="_blank"> https://github.com/IBMa/equal-access </a>
      <a href="https://github.com/IBMa/equal-access/blob/master/LICENSE" target="_blank">
        https://github.com/IBMa/equal-access/blob/master/LICENSE
      </a>
    </p>
  </div>
</div>
