import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { CommonUtility } from '../../utility/common.utility';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent implements OnInit, AfterViewInit {
  public isActive: boolean;
  @Input()
  public displayFooter: boolean;
  @Input()
  public displayHeader: boolean;
  @Input()
  public displayXButton: boolean;
  @Input()
  public cardClass: string;
  @Input()
  public cardBodyClass: string;
  @Input()
  public backdropClose: boolean;
  @Input()
  public scrollable: boolean;
  @Input()
  public closeLabel: string = 'label_close';
  @Input()
  public title: string;

  @Output() public onClose$: EventEmitter<void> = new EventEmitter();

  constructor(private elRef: ElementRef<HTMLElement>) {
    this.isActive = false;
    this.displayFooter = true;
    this.displayHeader = true;
    this.displayXButton = true;
    this.backdropClose = true;
    this.scrollable = false;
  }

  @HostListener('document:keydown.escape')
  public onDragEnter(): void {
    if (this.backdropClose) {
      this.closeModal();
    }
  }

  public onOverlayClick(): void {
    if (this.backdropClose) {
      this.closeModal();
    }
  }

  public onContainerClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  public closeModal(): void {
    this.isActive = false;
    CommonUtility.deactivateFocusTrap();
    this.onClose$.next();
  }

  public ngAfterViewInit(): void {
    CommonUtility.setFocusToElement(null, this.elRef.nativeElement.querySelector('.btn-close-modal'));
  }

  public ngOnInit(): void {
    this.isActive = true;
  }
}
