import { Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IThumbnailCellConfig extends ICellConfig {
  thumbnailUrl: string;
  imageUrl: string;
  thumbnailAlt: string;
}

@Component({
  selector: 'table-cell-thumbnail',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div #ref>
      <ng-container *ngIf="config.imageUrl">
        <a href="{{ config.imageUrl }}" rel="noreferrer noopener" target="_blank">
          <img class="mw-100 mh-100 thumbnail-cell" src="{{ config.thumbnailUrl }}" alt="{{ config.thumbnailAlt }}" />
          <i aria-hidden="true"></i>
        </a>
      </ng-container>
    </div>
  `,
})
export class ThumbnailCellComponent extends BaseCellComponent {
  @Input() public config: IThumbnailCellConfig;
}
