import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DigitalPropertyService } from './digital-property.service';
import { UserService } from './user.service';
import { AngularUtility } from '../utility/angular.utility';
import { IDigitalPropertyListItem, IDigitalPropertyType } from '../../../shared/interfaces/digital-property.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { $digitalProperty, $digitalPropertyType } from '../../../shared/constants/digital-properties';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../shared/interfaces/feature-flag.interface';

@Injectable({
  providedIn: 'root',
})
export class UserDigitalPropertyService {
  private _isWebDigitalProperty$: Observable<boolean>;
  private _isMobileDigitalProperty$: Observable<boolean>;
  private _areScansAvailable$: Observable<boolean>;
  private _dpType$: Observable<string>;

  constructor(
    private digitalPropertyService: DigitalPropertyService,
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.setupDigitalPropertyTypes();
  }

  private digitalPropertiesAndTypes$: Observable<[IDigitalPropertyListItem | null, IDigitalPropertyType[]]> = combineLatest([
    this.userService.currentDigitalProperty$,
    this.digitalPropertyService.getDigitalPropertyTypes(),
  ]).pipe(AngularUtility.shareRef());

  private setupDigitalPropertyTypes(): void {
    this._isWebDigitalProperty$ = this.digitalPropertiesAndTypes$.pipe(
      map(([digitalProperty, digitalPropertyTypes]: [IDigitalPropertyListItem | null, IDigitalPropertyType[]]): boolean => {
        if (SharedCommonUtility.isNullish(digitalProperty)) {
          return false;
        }
        const digitalPropertyType: IDigitalPropertyType = digitalPropertyTypes.find(
          (type: IDigitalPropertyType) => type[$digitalPropertyType._id] === digitalProperty[$digitalProperty.typeId],
        );
        return this.digitalPropertyService.isWebsiteType(digitalPropertyType);
      }),
      AngularUtility.shareRef(),
    );

    this._isMobileDigitalProperty$ = this.digitalPropertiesAndTypes$.pipe(
      map(([digitalProperty, digitalPropertyTypes]: [IDigitalPropertyListItem | null, IDigitalPropertyType[]]): boolean => {
        if (SharedCommonUtility.isNullish(digitalProperty)) {
          return false;
        }
        const digitalPropertyType: IDigitalPropertyType = digitalPropertyTypes.find(
          (type: IDigitalPropertyType) => type[$digitalPropertyType._id] === digitalProperty[$digitalProperty.typeId],
        );
        return this.digitalPropertyService.isMobileType(digitalPropertyType);
      }),
      AngularUtility.shareRef(),
    );

    this._areScansAvailable$ = combineLatest([this._isWebDigitalProperty$, this._isMobileDigitalProperty$]).pipe(
      map(([isWeb, isMobile]: [boolean, boolean]): boolean => {
        return isWeb || isMobile;
      }),
      AngularUtility.shareRef(),
    );

    this._dpType$ = this.digitalPropertiesAndTypes$.pipe(
      filter(([digitalProperty]: [IDigitalPropertyListItem | null, IDigitalPropertyType[]]) => {
        return SharedCommonUtility.notNullish(digitalProperty);
      }),
      map(([digitalProperty, digitalPropertyTypes]: [IDigitalPropertyListItem, IDigitalPropertyType[]]): string => {
        const digitalPropertyType: IDigitalPropertyType = digitalPropertyTypes.find(
          (type: IDigitalPropertyType) => type[$digitalPropertyType._id] === digitalProperty[$digitalProperty.typeId],
        );
        return digitalPropertyType[$digitalPropertyType.name];
      }),
      AngularUtility.shareRef(),
    );
  }

  public get isWebDigitalProperty$(): Observable<boolean> {
    return this._isWebDigitalProperty$;
  }

  public get isMobileDigitalProperty$(): Observable<boolean> {
    return this._isMobileDigitalProperty$;
  }

  public get dpType$(): Observable<string> {
    return this._dpType$;
  }

  // not a getter because i need to mock this with errors
  public getAreScansAvailable$(): Observable<boolean> {
    return this._areScansAvailable$;
  }

  public get isMobileDigitalPropertyWithMobileAppScansFF$(): Observable<boolean> {
    return combineLatest([
      this._isMobileDigitalProperty$,
      this.featureFlagService.variation$(FeatureFlagCollection.mobileAppScans, false),
    ]).pipe(
      map(([isMobile, isMobileAppScansFF]: [boolean, boolean]) => {
        return isMobileAppScansFF && isMobile;
      }),
    );
  }
}
