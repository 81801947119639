export enum $scanIssues {
  toolName = 'toolName',
  severity = 'severity',
  severityNumber = 'severityNumber',
  successCriteria = 'successCriteria',
  ruleId = 'ruleId',
  url = 'url',
  problem = 'problem',
  dismissed = 'dismissed',
  hasHistory = 'hasHistory',
  pageTitle = 'pageTitle',
  xpath = 'xpath',
  element = 'element',
  whyItMatters = 'whyItMatters',
  whatToDo = 'whatToDo',
  issues = 'issues',
  totalIssues = 'totalIssues',
  issueId = 'issueId',
  title = 'title',
  auditData = 'auditData',
  flaw = 'flaw',
  description = 'description',
  isNew = 'isNew',
  flawOccurrence = 'flawOccurrence',
}
