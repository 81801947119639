import { Injectable } from '@angular/core';

import { WindowRef } from './window.ref';
import { config } from '../../environments/config.shared';
import { CookieService } from './cookie.service';

@Injectable()
export class BrowserLocalStorageService {
  private storage: Storage;
  private isStorageAvailable: boolean = false;
  private tempData: Record<string, unknown> = {};
  private storageKey: string;

  constructor(
    private windowRef: WindowRef,
    private cookieService: CookieService,
  ) {
    this.isStorageAvailable = this.isAvailable();
    this.storageKey = config.APP.NAME;
  }

  private getData(): Record<string, unknown> {
    let stringData: string;

    if (this.isStorageAvailable === false && this.cookieService.isEnabled() === false) {
      return this.tempData;
    }

    if (this.isStorageAvailable) {
      stringData = this.storage.getItem(this.storageKey);
    } else {
      stringData = this.cookieService.get(this.storageKey);
    }

    let data = {};

    try {
      data = JSON.parse(stringData);
    } catch (e) {
      /* continue regardless of error */
    }

    return data === null ? {} : data;
  }

  private setData(data: Record<string, unknown>): void {
    if (this.isStorageAvailable === false && this.cookieService.isEnabled() === false) {
      this.tempData = data;
      return;
    }

    const stringData = JSON.stringify(data);

    if (this.isStorageAvailable) {
      this.storage.setItem(this.storageKey, stringData);
    } else {
      this.cookieService.set(this.storageKey, stringData, null, null, '/');
    }
  }

  private isAvailable(): boolean {
    const testItem = '__storageTest__';
    this.storage = this.windowRef.windowObject.localStorage;

    try {
      this.storage.setItem(testItem, testItem);
      this.storage.removeItem(testItem);
      return true;
    } catch (e) {
      return false;
    }
  }

  public getItem(key: string): any {
    const data = this.getData();
    let value: string | unknown | null = null;

    if (typeof data[key] === 'undefined') {
      return value;
    }

    value = data[key];
    return value;
  }

  public setItem(key: string, value: any): void {
    const data = this.getData();
    data[key] = value;

    this.setData(data);
  }

  public removeItem(key: string): void {
    if (this.isStorageAvailable === false && this.cookieService.isEnabled() === true) {
      this.cookieService.remove(key);
      return;
    }

    const data = this.getData();

    if (data[key]) {
      delete data[key];
      this.setData(data);
    }
  }

  public clear(): void {
    if (this.isStorageAvailable === false && this.cookieService.isEnabled() === true) {
      this.cookieService.deleteAll();
      return;
    }

    this.setData({});
    this.tempData = {};
    this.storage.removeItem(this.storageKey);
  }

  public get length(): number {
    const data = this.getData();
    return Object.keys(data).length;
  }
}
