import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import _ from 'lodash';

import { ITenantInfoWithPackageInfo } from '../../../shared/interfaces/tenant.interface';
import { IUserServerResponse } from '../../../shared/interfaces/user.interface';
import { IWorkspaceClient, IWorkspaceSummary } from '../../../shared/interfaces/workspace.interface';
import { IDigitalPropertyListItem } from '../../../shared/interfaces/digital-property.interface';
import { $user } from '../../../shared/constants/user';
import { $tenant } from '../../../shared/constants/tenant';
import { $workspace } from '../../../shared/constants/workspace';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { WalkMeService } from './walk-me.service';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';
import { ISecurityGroup } from '../../../shared/interfaces/security-group.interface';
import { $securityGroup } from '../../../shared/constants/security-group';
import { SharedTextUtility } from '../../../shared/utils/text.utility';
import { PendoService } from './pendo.service';
import { JobIndustryService } from './job-industry.service';
import { JobTitleService } from './job-title.service';
import { WorkspaceService } from './workspace.service';
import { IJobTitle, IJobTitlesServerResponse } from '../../../shared/interfaces/job-title.interface';
import { IJobIndustriesServerResponse, IJobIndustry } from '../../../shared/interfaces/job-industry.interface';
import { UserRoleService } from './user-role.service';
import { $jobIndustry } from '../../../shared/constants/job-industry';
import { $jobTitle } from '../../../shared/constants/job-title';
import { IUserRole } from '../../../shared/interfaces/user-role.interface';
import { $userRole } from '../../../shared/constants/user-role';
import { $userPreference, userPreferenceScope, userPreferenceScopeParam } from '../../../shared/constants/user-preferences';
import { IUserPreference, IUserPreferencesServerResponse } from '../../../shared/interfaces/user-preference.interface';

type PendoDataType = string | boolean | number | Date | Array<string>;

interface UserData {
  user: IUserServerResponse;
  tenant?: ITenantInfoWithPackageInfo;
  workspace?: IWorkspaceSummary;
  userPlatformUsage: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CurrentSessionDataService {
  public static readonly SESSION_DATA_KEY: string = 'current_session_data';

  private userDataSubscription: Subscription = Subscription.EMPTY;
  private jobIndustries: IJobIndustry[] = [];
  private jobTitles: IJobTitle[] = [];
  private userRoles: IUserRole[] = [];

  constructor(
    private jobIndustryService: JobIndustryService,
    private jobTitleService: JobTitleService,
    private tenantService: TenantService,
    private userService: UserService,
    private walkMeService: WalkMeService,
    private workspaceService: WorkspaceService,
    private pendoService: PendoService,
    private userRoleService: UserRoleService,
  ) {}

  public initialiseCurrentSessionData(): void {
    if (this.isConfigured()) {
      return;
    }

    this.userDataSubscription.unsubscribe();

    this.userDataSubscription = this.userService.userDataChanged$
      .pipe(
        tap(() => this.resetCurrentSessionData()),
        filter(SharedCommonUtility.notNullish),
        delayWhen(() => this.prepareRequiredData$()),
        switchMap((user: IUserServerResponse) => this.loadUserData$(user)),
      )
      .subscribe({
        next: this.setCurrentSessionData.bind(this),
        error: (err: HttpErrorResponse) => console.error('[CurrentSessionDataService] Session data initialization failed: ', err),
      });
  }

  private isConfigured(): boolean {
    return SharedCommonUtility.getTypeOf(window[CurrentSessionDataService.SESSION_DATA_KEY]) === 'object';
  }

  private resetCurrentSessionData(): void {
    window[CurrentSessionDataService.SESSION_DATA_KEY] = null;
  }

  private prepareRequiredData$(): Observable<any> {
    return forkJoin([
      this.jobIndustryService.getJobIndustries(),
      this.jobTitleService.getJobTitles(),
      this.userRoleService.getUserRoles(),
    ]).pipe(
      tap(
        ([jobIndustryResponse, jobTitleResponse, userRoles]: [
          IJobIndustriesServerResponse,
          IJobTitlesServerResponse,
          IUserRole[],
        ]) => {
          this.jobIndustries = jobIndustryResponse.jobIndustries;
          this.jobTitles = jobTitleResponse.jobTitles;
          this.userRoles = userRoles;
        },
      ),
    );
  }

  private loadUserData$(user: IUserServerResponse): Observable<UserData> {
    const tenant$: Observable<ITenantInfoWithPackageInfo> = SharedCommonUtility.notNullish(user[$user.currentTenantId])
      ? this.tenantService.getTenantFromTenantedScope(user[$user.currentTenantId])
      : of(null);

    const workspace$: Observable<IWorkspaceSummary> = SharedCommonUtility.notNullish(user[$user.lastUsedWorkspace])
      ? this.workspaceService.getWorkspaceSummary(user[$user.lastUsedWorkspace])
      : of(null);

    return forkJoin([tenant$, workspace$, this.getUserPlatformUsage$()]).pipe(
      map(([tenant, workspace, userPlatformUsage]: [ITenantInfoWithPackageInfo, IWorkspaceSummary, string[]]) => ({
        user: user,
        tenant: tenant,
        workspace: workspace,
        userPlatformUsage: userPlatformUsage,
      })),
    );
  }

  private getUserPlatformUsage$(): Observable<string[]> {
    return this.userService
      .getUserPreferences(userPreferenceScope.platformUsage)
      .pipe(
        map(
          (response: IUserPreferencesServerResponse) =>
            response.userPreferences.find(
              (preference: IUserPreference) => preference[$userPreference.param] === userPreferenceScopeParam.platformActions,
            )?.[$userPreference.value] ?? [],
        ),
      );
  }

  private setCurrentSessionData(data: UserData): void {
    const currentSessionData: Record<string, PendoDataType> = {
      ...this.getUserProfileData(data),
      ...this.getUserTenantData(data),
      ...this.getUserSecurityGroupData(data),
    };

    window[CurrentSessionDataService.SESSION_DATA_KEY] = currentSessionData;

    this.walkMeService.setGlobalVariable(currentSessionData);
    this.pendoService.registerUser(currentSessionData);
  }

  private getUserProfileData(data: UserData): Record<string, PendoDataType> {
    const matchingJobIndustry: IJobIndustry = this.jobIndustries.find(
      (jobIndustry: IJobIndustry) => jobIndustry[$jobIndustry._id] === data.user[$user.jobIndustry],
    );
    const matchingJobTitle: IJobTitle = this.jobTitles.find(
      (jobTitle: IJobTitle) => jobTitle[$jobTitle._id] === data.user[$user.jobTitle],
    );
    const matchingUserRole: IUserRole = this.userRoles.find((role: IUserRole) => role[$userRole._id] === data.user[$user.roleId]);

    return {
      userId: data.user[$user._id],
      email: data.user[$user.email],
      displayName: data.user[$user.displayName],
      createdDate: data.user[$user.createdAt],
      lastLoginDate: data.user[$user.lastLogin],
      jobIndustry: data.user[$user.jobIndustry] as string,
      jobIndustryName: SharedCommonUtility.notNullish(matchingJobIndustry) ? matchingJobIndustry[$jobIndustry.name] : null,
      jobTitle: data.user[$user.jobTitle] as string,
      jobTitleName: SharedCommonUtility.notNullish(matchingJobTitle) ? matchingJobTitle[$jobTitle.title] : null,
      roleId: data.user[$user.roleId],
      roleName: SharedCommonUtility.notNullish(matchingUserRole) ? matchingUserRole[$userRole.name] : null,
      signupSurvey: data.userPlatformUsage.join(','),
    };
  }

  private getUserTenantData(data: UserData): Record<string, PendoDataType> {
    const tenant: ITenantInfoWithPackageInfo = data.tenant;

    const currentWorkspace: IWorkspaceClient | null = data.user[$user.workspaces]?.find(
      (ws: IWorkspaceClient) => ws[$workspace._id] === data.user[$user.lastUsedWorkspace],
    );
    const currentDigitalProperty: IDigitalPropertyListItem | null = data.user[$user.digitalProperties]?.find(
      (dp: IDigitalPropertyListItem) => dp[$digitalProperty._id] === data.user[$user.lastUsedDigitalProperty],
    );

    return {
      TID: SharedCommonUtility.notNullish(tenant) ? tenant[$tenant._id] : 'adminenv',
      tenantName: SharedCommonUtility.notNullish(tenant) ? tenant[$tenant.name] : null,
      currentWorkspace: data.user[$user.lastUsedWorkspace],
      workspaceName: currentWorkspace?.[$workspace.name],
      currentDigitalProperty: data.user[$user.lastUsedDigitalProperty],
      digitalPropertyName: currentDigitalProperty?.[$digitalProperty.name],
      workspacesCount: data.user[$user.workspaces]?.length,
      numDpsCurrentWorkspace: SharedCommonUtility.notNullish(data.workspace) ? data.workspace.totalDigitalProperties : null,
      SFID: tenant?.[$tenant.salesforceAccountId] || '(none)',
      packageId: tenant?.packageId ?? null,
      packageName: tenant?.packageName ?? null,
    };
  }

  private getUserSecurityGroupData(data: UserData): Record<string, PendoDataType> {
    const groups: ISecurityGroup[] = data.user[$user.groups] ?? [];

    const [staffGroups, userGroups]: [ISecurityGroup[], ISecurityGroup[]] = _.partition(
      groups,
      (group: ISecurityGroup) => group[$securityGroup.isStaffGroup],
    );

    const userGroupId: string = _.map(userGroups, $securityGroup._id).join(',');
    const userGroupName: string = userGroups
      .map((group: ISecurityGroup): string => SharedTextUtility.escapeCSVField(group[$securityGroup.name]))
      .join(',');

    const staffGroupId: string = _.map(staffGroups, $securityGroup._id).join(',');
    const staffGroupName: string = staffGroups
      .map((group: ISecurityGroup): string => SharedTextUtility.escapeCSVField(group[$securityGroup.name]))
      .join(',');

    return {
      userGroupId,
      userGroupName,
      staffGroupId,
      staffGroupName,
    };
  }
}
