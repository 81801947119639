import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { $sortingOrder } from '../../../../shared/constants/sort';

@Directive({
  selector: 'th,td[appAriaSort]',
  exportAs: 'appAriaSort',
})
export class AriaSortDirective implements OnChanges {
  @Input()
  appAriaSort: $sortingOrder | 'asc' | 'desc' | 'all';

  @HostBinding('attr.aria-sort') ariaSort: string = null;

  constructor() {}

  ngOnChanges(): void {
    if (this.appAriaSort === $sortingOrder.asc) {
      this.ariaSort = 'ascending';
      return;
    } else if (this.appAriaSort === $sortingOrder.desc) {
      this.ariaSort = 'descending';
      return;
    }

    this.ariaSort = null;
  }
}
