import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateService } from '../../../translate/translate.service';
import { IIssueStatusHistory } from '../../../../../shared/interfaces/issue-status-history.interface';

const dateStyle: Intl.DateTimeFormat = new Intl.DateTimeFormat([], {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const timeStyle: Intl.DateTimeFormat = new Intl.DateTimeFormat([], {
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
});

@Component({
  selector: 'app-view-flaw-status-history-item',
  templateUrl: './view-flaw-status-history-item.component.html',
  styleUrls: ['./view-flaw-status-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewFlawStatusHistoryItemComponent {
  @Input()
  public expanded: boolean;

  @Input()
  public historyItem: IIssueStatusHistory;

  constructor(private translateService: TranslateService) {
    this.expanded = true;
  }

  public getUserAndTimestamp(): string {
    const dateTimeOfComment: Date = new Date(this.historyItem.date);

    const displayName: string = this.translateService.instant('manual_audit_issue_comments_user_timestamp', [
      dateStyle.format(dateTimeOfComment),
      timeStyle.format(dateTimeOfComment),
      this.historyItem.userId,
    ]);

    if (this.historyItem.userDisabled) {
      return `${displayName} (${this.translateService.instant('status_inactive')})`;
    }

    return displayName;
  }
}
