import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IIssueStatusHistory } from '../../../../shared/interfaces/issue-status-history.interface';
import { IssueStatusHistoryService } from '../../services/issue-status-history.service';
import { IManualAuditHistoryRequest } from '../../interfaces/manual-evaluation.interface';

@Component({
  selector: 'app-view-flaw-status-history',
  templateUrl: './view-flaw-status-history.component.html',
  styleUrls: ['./view-flaw-status-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewFlawStatusHistoryComponent {
  private issueSource$: BehaviorSubject<string | IManualAuditHistoryRequest>;
  public issueStatusHistories$: Observable<IIssueStatusHistory[]>;

  @Input() public set issueSource(value: string | IManualAuditHistoryRequest) {
    this.issueSource$.next(value);
  }

  constructor(private issueStatusHistoryService: IssueStatusHistoryService) {
    this.issueSource$ = new BehaviorSubject<string | IManualAuditHistoryRequest>(null);

    this.issueStatusHistories$ = this.issueSource$.pipe(
      switchMap((source: string | IManualAuditHistoryRequest) => this.issueStatusHistoryService.getStatusUpdateHistory(source)),
    );
  }
}
