import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { TranslateService } from '../translate/translate.service';
import { IFormErrorMessage, CustomAbstractControl, ErrorContainer } from '../interfaces/form.interface';
import { validationError } from '../constants/form.constants';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { CommonUtility } from '../utility/common.utility';
import { minPasswordLength } from '../../../shared/constants/user';
import { MAX_EMAILS_LIMIT_ERROR_KEY } from '../constants/user.constants';
import { SCAN_URLS_SIZE_LIMIT_ERROR_KEY } from '../constants/scan.constants';
import { $workspace, CHAR_LIMITS } from '../../../shared/constants/workspace';
import { CustomValidators } from './helpers/form-custom-validators';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  public isFieldValid(field: string, form: UntypedFormGroup): boolean {
    const formField: AbstractControl = form.get(field);

    if (formField === null) {
      console.warn(`[FormService.isFieldValid] form field "${field}" does not exists`, form);
      return true;
    }

    if (formField.errors === null || formField.disabled) {
      return true;
    }

    const errors = Object.keys(formField.errors);

    if (errors.length > 0) {
      return false;
    }

    if (formField.touched === false && formField.pristine === true) {
      return true;
    }

    if (formField.dirty === false && formField.errors.length === 0) {
      return true;
    }

    return formField.valid;
  }

  public getErrorMessageForField(
    field: CustomAbstractControl | ErrorContainer,
    fieldName: string,
    customMessage: string = '',
    label: string = '',
    overrideErrors: { [key: string]: string } = {},
  ): string {
    function isCustomAbstractControl(ctrl: CustomAbstractControl | ErrorContainer): ctrl is CustomAbstractControl {
      return ctrl.hasOwnProperty('nativeElement');
    }

    const errors: Array<string> = [];
    let message: string = '';
    let hasMappedCustomMessage: boolean = false;
    const addressedErrors: Set<string> = new Set<string>();
    const addError = (error: string, msg: string): void => {
      addressedErrors.add(error);
      if (SharedCommonUtility.isNullish(overrideErrors[error])) {
        errors.push(msg);
      } else {
        errors.push(overrideErrors[error]);
      }
    };

    if (isCustomAbstractControl(field) && CommonUtility.isHtmlElement(field.nativeElement) && field.nativeElement.disabled) {
      field.clearAsyncValidators();
      field.setErrors(null);
      return message;
    }

    if (field.errors === null) {
      return message;
    }

    if (field.hasError('minlength')) {
      addError(
        'minlength',
        `requires minimum ${field.errors.minlength.requiredLength} chars (you have typed ${field.errors.minlength.actualLength})`,
      );
    }

    if (field.hasError('hasWhiteSpaces')) {
      addError('hasWhiteSpaces', this.translateService.instant('form-validation-whitespaces-pattern'));
    }

    if (field.hasError('isEmpty')) {
      if (
        isCustomAbstractControl(field) &&
        CommonUtility.isHtmlElement(field.nativeElement) &&
        field.nativeElement.type === 'file'
      ) {
        // Note: dataset.selectedFilesLength is here becuase we have a custom "Select or drag and drop files" action button
        if (
          typeof field.nativeElement.dataset.selectedFilesLength === 'string' &&
          Number(field.nativeElement.dataset.selectedFilesLength) === 0
        ) {
          addError('isEmpty', this.translateService.instant('form-validation-is-empty-file-list-pattern'));
        } else if (
          typeof field.nativeElement.dataset.selectedFilesLength !== 'string' &&
          field.nativeElement.files.length === 0
        ) {
          addError('isEmpty', this.translateService.instant('form-validation-is-empty-file-list-pattern'));
        }
      } else {
        addError('isEmpty', this.translateService.instant('form-validation-is-empty-pattern'));
      }
    }

    if (field.hasError('charactersOnly')) {
      addError('charactersOnly', this.translateService.instant('form-characters-only'));
    }

    if (field.hasError('excludeCharacters')) {
      addError('excludeCharacters', this.translateService.instant('exclude-characters', field.errors.excludeCharacters));
    }

    if (field.hasError('alphaNumericCharactersOnly')) {
      addError('charactersOnly', this.translateService.instant('form-alphanumeric-characters-only'));
    }

    if (field.hasError('nonEmojiCharactersOnly')) {
      addError('charactersOnly', this.translateService.instant('form-non-emoji-characters-only'));
    }

    if (field.hasError('designRuleIdPattern')) {
      addError('charactersOnly', this.translateService.instant('form-validation-design-rule-id-pattern'));
    }

    if (field.hasError('invalidPassword')) {
      addError('invalidPassword', this.translateService.instant('form-validation-password-pattern', minPasswordLength));
    }

    if (field.hasError('noTagSelected')) {
      addError('noTagSelected', this.translateService.instant('form-validation-scan-tag'));
      hasMappedCustomMessage = true;
    }

    if (field.hasError('mustBeSelected')) {
      if (!SharedCommonUtility.isNullish(overrideErrors['mustBeSelected'])) {
        addError('mustBeSelected', overrideErrors['mustBeSelected']);
        hasMappedCustomMessage =
          SharedCommonUtility.notNullishOrEmpty(overrideErrors['mustBeSelected']) &&
          overrideErrors['mustBeSelected'] !== this.translateService.instant('must_be_selected');
      }
    }

    if (field.hasError('noConformanceLevelSelected')) {
      addError('noConformanceLevelSelected', this.translateService.instant('select_conformance_option_validation'));
      hasMappedCustomMessage = true;
    }

    if (field.hasError(validationError.required)) {
      if (!SharedCommonUtility.isNullish(overrideErrors[validationError.required])) {
        addError(validationError.required, overrideErrors[validationError.required]);
        hasMappedCustomMessage =
          SharedCommonUtility.isNullishOrEmpty(label) ||
          // TODO remove the two conditions below in EAP-26344
          overrideErrors[validationError.required] === this.translateService.instant('enter_viewport_width') ||
          overrideErrors[validationError.required] === this.translateService.instant('enter_viewport_height');
      } else {
        const nativeElement: HTMLInputElement | null = document.querySelector(`[name='${fieldName}']`);

        if (field.value === null || field.value === undefined || field.value.length === 0) {
          if (nativeElement) {
            if (nativeElement.nodeName.toLowerCase() === 'input' && nativeElement.type === 'datetime-local') {
              addError(validationError.required, this.translateService.instant('form-validation-datetime-local-invalid-format'));
            } else if (nativeElement.nodeName.toLowerCase() === 'select') {
              addError(validationError.required, this.translateService.instant('form-validation-field-is-not-selected'));
            } else if (nativeElement.nodeName.toLowerCase() === 'input' && nativeElement.type === 'email') {
              addError(validationError.required, this.translateService.instant('form-validation-email-pattern'));
            }
          }
        }

        addError(
          validationError.required,
          this.translateService.instant(
            errors.length === 0 ? 'form-validation-is-required' : 'form-validation-and-is-required-pattern',
          ),
        );
      }
    }

    if (field.hasError('invalidEmail')) {
      if (typeof overrideErrors['invalidEmail'] === 'undefined') {
        addError('invalidEmail', this.translateService.instant('form-validation-email-pattern'));
      } else if (errors.length === 0) {
        hasMappedCustomMessage = true;
        addError('invalidEmail', this.translateService.instant(overrideErrors['invalidEmail']));
      }
    }

    if (field.hasError('invalidCreateNoteDate')) {
      addError('invalidCreateNoteDate', this.translateService.instant('form-validation-datetime-local-invalid-format'));
    }

    if (field.hasError('noSelection')) {
      addError('noSelection', this.translateService.instant('form-validation-one-element-must-be-selected'));
    }

    if (field.hasError(validationError.minDate)) {
      addError(validationError.minDate, this.translateService.instant('form-validation-min-date'));
    }

    if (field.hasError(validationError.maxDate)) {
      addError(validationError.maxDate, this.translateService.instant('form-validation-max-date'));
    }

    if (field.hasError('isDomainPartOfSubscription')) {
      addError('isDomainPartOfSubscription', this.translateService.instant('form-validation-url-subscription'));
      hasMappedCustomMessage =
        SharedCommonUtility.notNullishOrEmpty(overrideErrors['isDomainPartOfSubscription']) &&
        SharedCommonUtility.isNullishOrEmpty(label);
    }

    if (field.hasError('isDomainUnavailable')) {
      addError('isDomainUnavailable', this.translateService.instant('form-validation-domain-unavailable'));
    }

    if (field.hasError('isDomainValid')) {
      addError('isDomainValid', this.translateService.instant('form-validation-domain-valid', field.errors.isDomainValid));
      hasMappedCustomMessage =
        SharedCommonUtility.notNullishOrEmpty(overrideErrors['isDomainValid']) && SharedCommonUtility.isNullishOrEmpty(label);
    }

    if (field.hasError('min')) {
      addError('min', this.translateService.instant('form-validation-min', field.errors.min.min));
      hasMappedCustomMessage = SharedCommonUtility.notNullishOrEmpty(overrideErrors['min']);
    }

    if (field.hasError('max')) {
      addError('max', this.translateService.instant('form-validation-max', field.errors.max.max));
      hasMappedCustomMessage = SharedCommonUtility.notNullishOrEmpty(overrideErrors['max']);
    }

    if (field.hasError(SCAN_URLS_SIZE_LIMIT_ERROR_KEY)) {
      addError(
        SCAN_URLS_SIZE_LIMIT_ERROR_KEY,
        this.translateService.instant('form-validation-exceed-max-number-of-urls', field.errors[SCAN_URLS_SIZE_LIMIT_ERROR_KEY]),
      );
    }

    if (field.hasError(MAX_EMAILS_LIMIT_ERROR_KEY)) {
      addError(
        MAX_EMAILS_LIMIT_ERROR_KEY,
        this.translateService.instant('form-validation-exceed-max-number-of-emails', field.errors[MAX_EMAILS_LIMIT_ERROR_KEY]),
      );
    }

    if (field.hasError('maxLines')) {
      addError('maxLines', this.translateService.instant('form-validation-exceed-max-number-of-lines', field.errors.maxLines));
    }

    if (
      field.hasError(validationError.invalidJSON) &&
      SharedCommonUtility.notNullish(overrideErrors[validationError.invalidJSON])
    ) {
      hasMappedCustomMessage = true;
      addError(validationError.invalidJSON, overrideErrors[validationError.invalidJSON]);
    }

    if (field.hasError(validationError.invalidFileType)) {
      addError(
        validationError.invalidFileType,
        this.translateService.instant('form-validation-invalid-file-type', field.errors[validationError.invalidFileType]),
      );
    }

    if (field.hasError(validationError.invalidFileTypes)) {
      addError(
        validationError.invalidFileTypes,
        this.translateService.instant('form-validation-invalid-file-types', field.errors[validationError.invalidFileTypes]),
      );
    }

    if (field.hasError(validationError.attachmentQuantityExceedsLimit)) {
      addError(
        validationError.attachmentQuantityExceedsLimit,
        this.translateService.instant(
          'form-validation-attachment-quantity-exceeds-limit',
          field.errors[validationError.attachmentQuantityExceedsLimit],
        ),
      );
    }

    if (field.hasError(validationError.attachmentSizeExceedsLimit)) {
      addError(
        validationError.attachmentSizeExceedsLimit,
        this.translateService.instant(
          'form-validation-attachment-size-exceeds-limit',
          SharedCommonUtility.formatBytes(field.errors[validationError.attachmentSizeExceedsLimit]),
        ),
      );
    }

    if (field.hasError(validationError.digitalPropertyKey)) {
      addError(validationError.digitalPropertyKey, this.translateService.instant('form-validation-invalid-digital-property-key'));
    }

    if (field.hasError(validationError.digitalPropertyFieldWorkspaceDuplicate)) {
      const propKey: $digitalProperty = field.getError(validationError.digitalPropertyFieldWorkspaceDuplicate);
      addError(
        validationError.digitalPropertyFieldWorkspaceDuplicate,
        this.translateService.instant(`form-validation-duplicated-digital-property-${propKey}`),
      );
    }

    if (field.hasError(validationError.masterLibraryRuleIdDuplicate)) {
      addError(
        validationError.masterLibraryRuleIdDuplicate,
        this.translateService.instant(`form-validation-duplicated-audit-rule-id`),
      );
    }

    if (field.hasError(validationError.notMatchDomain)) {
      addError(validationError.notMatchDomain, this.translateService.instant(`form-validation-domain-not-match`));
    }

    if (field.hasError(validationError.urlInvalid)) {
      addError(validationError.urlInvalid, this.translateService.instant(`form-validation-url-pattern`));
    }

    if (field.hasError(validationError.in)) {
      addError(validationError.in, this.translateService.instant(`form-validation-field-not-in-error`));
    }

    if (field.hasError(validationError.unique)) {
      addError(validationError.unique, this.translateService.instant('form-validation-unique-validator'));
    }

    if (field.hasError(validationError.invalidSubdomain)) {
      addError(validationError.invalidSubdomain, this.translateService.instant('form-validation-subdomain'));
    }

    if (field.hasError(validationError.maxlength)) {
      /**
       * The UI/UX team is trying a new format for some form field error messages. They started this with the form
       * field for the workspace "name" in the upsert workspace form.
       */
      if (
        overrideErrors?.[validationError.maxlength] ===
        this.translateService.instant('upsert_workspace_name_max_length_error_label', CHAR_LIMITS[$workspace.name])
      ) {
        hasMappedCustomMessage = true;
      }

      addError(
        validationError.maxlength,
        this.translateService.instant('form-validation-maxlength', field.errors[validationError.maxlength].requiredLength),
      );
    }

    if (field.hasError('cssSelectorInvalid')) {
      addError('cssSelectorInvalid', this.translateService.instant('invalid_css_selector_format'));
    }

    if (field.hasError(validationError.datesValidator)) {
      addError(validationError.datesValidator, this.translateService.instant('start_before_end_validation'));
    }

    if (field.hasError(validationError.invalidInteger)) {
      addError(validationError.invalidInteger, this.translateService.instant('form_validation_integer_value'));
    }

    Object.keys(field.errors)
      .filter((error: string) => {
        return !addressedErrors.has(error);
      })
      .filter((error: string) => {
        return overrideErrors.hasOwnProperty(error);
      })
      .forEach((error: string) => {
        errors.push(overrideErrors[error]);
      });

    if (field.hasError('invalidArchiveConfirmationMessage')) {
      errors.length = 0;
      errors.push(this.translateService.instant('form-validation-must-not-be-empty-and-is-required'));
    }

    if (errors.length > 0) {
      let labelFieldName: string;

      if (label.length > 0) {
        labelFieldName = this.translateService.instant(label);
      } else {
        labelFieldName = fieldName
          .split(/(?=[A-Z])/)
          .join(' ')
          .toLowerCase();
      }

      message = hasMappedCustomMessage === false ? `${this.translateService.instant('the_form_field', labelFieldName)} ` : '';
      const endSymbol: string = errors[errors.length - 1].endsWith('.') ? '' : '.';
      message += hasMappedCustomMessage === false ? errors.join(', ') + endSymbol : errors[0];
    }

    if (customMessage && customMessage.length > 0) {
      message += ' ' + this.translateService.instant(customMessage) + '.';
    }

    return message;
  }

  public getErrorMessages(form: UntypedFormGroup, formName?: string): IFormErrorMessage[] {
    const errors: Array<IFormErrorMessage> = [];

    if (form.valid === true) {
      return errors;
    }

    const processFormControl = (formControlName: string): void => {
      const formControl: CustomAbstractControl = form.controls[formControlName] as CustomAbstractControl;
      const message: string = this.getErrorMessageForField(formControl, formControlName, '', '');

      if (message.length === 0) {
        return;
      }

      const errorData: IFormErrorMessage = {
        controlName: formControlName,
        message: message,
      };

      errors.push(errorData);
    };

    if (form.errors) {
      const message: string = this.getErrorMessageForField(form, formName);
      if (message.length !== 0) {
        const errorData: IFormErrorMessage = {
          controlName: formName,
          message: message,
        };

        errors.push(errorData);
      }
    } else {
      Object.keys(form.controls).forEach(processFormControl);
    }

    return errors;
  }

  public getInvalidControls(form: UntypedFormGroup): AbstractControl[] {
    const invalidControls: AbstractControl[] = [];

    const processFormControl = (formControlName: string): void => {
      if (form.controls[formControlName].invalid === true) {
        invalidControls.push(form.controls[formControlName]);
      }
    };

    Object.keys(form.controls).forEach(processFormControl);

    return invalidControls;
  }

  public syncFormValuesToQueryParams(form: UntypedFormGroup, fields: string[]): Subscription {
    this.updateFormValuesFromQueryParams(form, fields);

    return form.valueChanges
      .pipe(
        map((values: Record<string, any>) => this.buildQueryParams(values, fields)),
        distinctUntilChanged(isEqual),
      )
      .subscribe(this.updateUrlQueryParams.bind(this));
  }

  private updateFormValuesFromQueryParams(form: UntypedFormGroup, fields: string[]): void {
    const queryParams: Params = this.activatedRoute.snapshot.queryParams;

    fields.forEach((field: string) => {
      if (form.contains(field) && SharedCommonUtility.notNullish(queryParams[field])) {
        form.get(field).setValue(queryParams[field]);
      }
    });
  }

  private buildQueryParams(values: Record<string, any>, fields: string[]): Params {
    const params: Record<string, any> = {};

    fields.forEach((field: string) => {
      params[field] = values[field];
    });

    return params;
  }

  private updateUrlQueryParams(queryParams: Params): void {
    const currentFocusId: string = document.activeElement.getAttribute('id');
    this.router
      .navigate([], {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
      .then(() => {
        // hacky solution to refocus the originally focused element after self-redirect
        // honestly, this looks like the best solution
        setTimeout(() => (document.querySelector(`#${currentFocusId}`) as HTMLElement)?.focus(), 1);
      });
  }

  public buildFormControlErrorMessage$(
    control: AbstractControl,
    labelKey: string,
    overrideErrors: Record<string, string> = {},
  ): Observable<string | null> {
    return control.statusChanges.pipe(map(() => this.getErrorMessageForField(control, '', '', labelKey, overrideErrors)));
  }

  public isFieldRequired(form: FormGroup, context: { field: string; required?: boolean }): boolean {
    const requiredFromContext: boolean = typeof context.required === 'boolean' ? context.required : true;
    const requiredFromValidators: boolean = form.get(context.field)?.hasValidator(CustomValidators.required) ?? false;
    return requiredFromContext || requiredFromValidators;
  }
}
