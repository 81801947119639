import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { RestService } from './rest.service';
import { ICurrentSelectedProperty } from './user.service';
import { UserPropertyService } from './user-property.service';
import { AccessibilityAuditToolNames } from '../../../shared/constants/audit-tool';
import { RuleAuditHistoryType } from '../../../shared/constants/rule-audit-history';
import { IHasHistoryUpdateResponse } from '../../../shared/interfaces/rule-audit-history.interface';
import { IDismissRestoreRequest } from '../../../shared/interfaces/ignore.interface';
import { IAudit } from '../../../shared/interfaces/audit.interface';
import { IAuditRule } from '../../../shared/interfaces/audit-rule.interface';
import { IAutomatedScanIssue, IScanIssues } from '../../../shared/interfaces/scan-issues.interface';
import { $flaw, changedByType, flawStatus, linkableIssueStatus } from '../../../shared/constants/flaw';
import { IFlawStatusChange } from '../../../shared/interfaces/flaw.interface';
import { IGetShortLinkResponse } from '../../../shared/interfaces/short-link.interface';
import { IScreenshotAuditResult } from '../../../shared/interfaces/audit-raw-result.interface';
import { IUploadClient } from '../../../shared/interfaces/uploads.interface';

@Injectable({
  providedIn: 'root',
})
export class ScanReportService {
  constructor(
    private restService: RestService,
    private userService: UserPropertyService,
  ) {}

  public getIssues(
    scanId: string,
    accessibilityTool: AccessibilityAuditToolNames,
    rule: string,
    scanUrl?: string,
    componentId?: string,
    scanPointId?: string,
  ): Observable<IScanIssues> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.getScanIssues(
            workspaceId,
            digitalPropertyId,
            scanId,
            accessibilityTool,
            rule,
            scanUrl,
            componentId,
            scanPointId,
          ),
        ),
      );
  }

  public async getIssueShortLink(
    scanId: string,
    accessibilityTool: AccessibilityAuditToolNames,
    rule: string,
    issueId: string,
  ): Promise<string> {
    const resp: IGetShortLinkResponse = await this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IGetShortLinkResponse> =>
            this.restService.getScanIssueShortLink(workspaceId, digitalPropertyId, scanId, accessibilityTool, rule, issueId),
        ),
      )
      .toPromise();

    return resp.shortLink;
  }

  public getScreenshotAuditDetails(scanId: string, documentId: string): Observable<IScreenshotAuditResult> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.getScreenshotAuditDetails(workspaceId, digitalPropertyId, scanId, documentId),
        ),
      );
  }

  public getAuditedDocumentScreenshot(scanId: string, documentId: string): Observable<IUploadClient> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.getAuditedDocumentScreenshot(workspaceId, digitalPropertyId, scanId, documentId),
        ),
      );
  }

  public dismissIssues(ignoreRequest: IDismissRestoreRequest): Observable<number> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.dismissIssues(workspaceId, digitalPropertyId, ignoreRequest),
        ),
      );
  }

  public hasIssuesChanges(
    tool: AccessibilityAuditToolNames,
    scanId: string,
    from: Date,
    ruleId: string,
    scanUrl: string,
  ): Observable<IHasHistoryUpdateResponse> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.hasHistory(
            workspaceId,
            digitalPropertyId,
            tool,
            RuleAuditHistoryType.issue,
            scanId,
            from,
            ruleId,
            scanUrl,
          ),
        ),
      );
  }

  public restoreIssues(restoreRequest: IDismissRestoreRequest): Observable<number> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.restoreIssues(workspaceId, digitalPropertyId, restoreRequest),
        ),
      );
  }

  public getTotalRulesCount(audits: IAudit[]): number {
    let evaluatedRulesCount: number = 0;

    for (const audit of audits) {
      if (audit.useForTotalScore === false) {
        continue;
      }

      evaluatedRulesCount += this.getSelectedScanRulesCount(audit);
    }

    return evaluatedRulesCount;
  }

  public getSelectedScanRulesCount(audit: IAudit): number {
    if (audit.isSelectedForScanning === false) {
      return 0;
    }
    return Object.values(audit.rules)
      .filter((rule: IAuditRule): boolean => rule.isSelectedForScanning)
      .reduce((sum: number): number => sum + 1, 0);
  }

  public isDismissed(issue: IAutomatedScanIssue): boolean {
    return issue.status === flawStatus.dismissed;
  }

  public hasHistory(issue: IAutomatedScanIssue): boolean {
    return (
      issue[$flaw.statusHistory]?.some(
        (changeEvent: IFlawStatusChange): boolean => changeEvent.changedByType === changedByType.user,
      ) || false
    );
  }

  public isIssueLinked(issue: IAutomatedScanIssue): boolean {
    return Boolean(issue?.[$flaw.taskId]);
  }

  public canBeLinkedToTask(issue: IAutomatedScanIssue): boolean {
    return linkableIssueStatus.includes(issue.status) && this.isIssueLinked(issue) === false;
  }

  public isIssueClosed(issue: IAutomatedScanIssue): boolean {
    return issue[$flaw.status] === flawStatus.closed;
  }
}
