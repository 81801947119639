<div class="d-flex flex-wrap">
  <div class="d-flex align-items-center me-3">
    <ng-container *ngIf="hidePageSizeSelector === false">
      <label
        [class.visuallyhidden]="hideLabel"
        [for]="selectorId"
        class="me-3 fw-normal text-black"
        [id]="'entriesPerPage' + (idSuffix || '')"
      >
        {{ label || ('label_rows_per_page' | translate) }}
      </label>
      <select
        [id]="selectorId"
        [ngModel]="pageSize"
        (ngModelChange)="pageSizeChange.emit($event)"
        [attr.aria-labelledby]="'entriesPerPage' + (idSuffix || '')"
        [class.me-3]="!hideNOfT"
        class="text-black mh-100"
      >
        <option *ngFor="let limit of entryLimits" [ngValue]="limit">
          {{ limit }}
        </option>
      </select>
    </ng-container>
    <div
      *ngIf="!hideNOfT"
      class="d-flex align-items-center text-black"
      [attr.aria-live]="hidePageSizeSelector === false ? 'polite' : null"
      [attr.role]="hidePageSizeSelector === false ? 'status' : null"
    >
      {{ 'n_of_t' | translate: [(startIndex | async) + ' - ' + (lastIndex | async), collectionSize$ | async] }}
      <span class="visuallyhidden">&nbsp;{{ 'results' | translate }}</span>
    </div>
  </div>

  <div class="d-flex mt-3 mt-md-0" *ngIf="ready">
    <ngb-pagination
      [attr.aria-label]="((idSuffix | lowercase) !== 'bottom' ? 'table_pagination' : 'table_pagination_bottom') | translate"
      [page]="page"
      [maxSize]="3"
      (pageChange)="handlePageChange($event)"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize$ | async"
    >
      <ng-template ngbPaginationPrevious>{{ 'label_previous' | translate }}</ng-template>
      <ng-template ngbPaginationNext>{{ 'label_next' | translate }}</ng-template>
      <ng-template ngbPaginationEllipsis>…</ng-template>
      <ng-template ngbPaginationNumber let-pageNum>
        <span class="visually-hidden">
          {{ (pageNum !== page ? 'go_to_page' : 'page') | translate }}
          &nbsp;
        </span>
        {{ pageNum }}
      </ng-template>
    </ngb-pagination>
  </div>
</div>
