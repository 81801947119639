import { switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IDigitalPropertiesListResponse,
  IDigitalPropertyListItem,
} from '../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../../shared/constants/digital-properties';
import { BusMessageChannels, BusMessageService } from '../../services/bus-message.service';
import { TranslateService } from '../../translate/translate.service';
import { IWorkspacePropsType } from '../../interfaces/workspace.interface';
import { WindowService } from '../../services/window.service';
import { viewportSize } from '../../shared/constants';
import { DetectUtility } from '../../utility/detect.utility';
import { UserPropertyService } from '../../services/user-property.service';
import { AccordionComponent } from '../common-accordion/accordion.component';

@Component({
  selector: 'app-digital-property-workspace-filter',
  templateUrl: './digital-property-workspace-filter.component.html',
  styleUrls: ['./digital-property-workspace-filter.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DigitalPropertyWorkspaceFilterComponent), multi: true },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalPropertyWorkspaceFilterComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {
  private onChange: (value: string) => void;
  private onTouched: () => void;
  private properties: IDigitalPropertyListItem[];
  private subscriptions: Subscription;
  @ViewChild(AccordionComponent) private accordionComponent: AccordionComponent;

  public static onChangeConfirmations: Record<any, string> = {};

  public selectedDigitalPropertyId: string;
  public values: Array<IWorkspacePropsType>;
  public filteredValues: Array<IWorkspacePropsType>;
  public filterText: string;
  public selectedWorkspaceIndex: number;
  public selectedWorkspaceId: string;

  public viewportSize: viewportSize;

  @Input()
  public includeTenantName: boolean = false;

  @Output()
  public propertyChange: EventEmitter<IDigitalPropertyListItem>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private busMessageService: BusMessageService,
    private translateService: TranslateService,
    private windowService: WindowService,
    private userPropertyService: UserPropertyService,
  ) {
    this.values = [];
    this.filteredValues = [];
    this.filterText = '';
    this.onChange = (ignore: string): void => {};
    this.onTouched = (): void => {};
    this.properties = [];
    this.propertyChange = new EventEmitter();
    this.subscriptions = new Subscription();

    this.viewportSize = DetectUtility.getViewportSize();
  }

  private onDigitalPropertiesLoaded(props: IDigitalPropertiesListResponse): void {
    this.properties = props.items;
    this.values = UserPropertyService.transformDigitalPropertiesIntoWorkspaces(props, this.includeTenantName);

    const selectedValue: IWorkspacePropsType | undefined = this.values.find((prop: IWorkspacePropsType): boolean =>
      prop.digitalProperties.some(
        (item: IDigitalPropertyListItem): boolean => item[$digitalProperty._id] === this.selectedDigitalPropertyId,
      ),
    );

    if (typeof selectedValue !== 'undefined') {
      selectedValue.hidden = false;

      this.updateSelectedWorkspace();
      this.accordionComponent.expand(`accordion-panel-${this.selectedWorkspaceIndex}`);
    }

    this.applyFilter();
  }

  private updateSelectedWorkspace(): void {
    const byDigitalPropertyId = (prop: IWorkspacePropsType): boolean =>
      prop.digitalProperties.some(
        (item: IDigitalPropertyListItem): boolean => item[$digitalProperty._id] === this.selectedDigitalPropertyId,
      );

    const selectedWorkspace = this.values.find(byDigitalPropertyId);

    this.selectedWorkspaceIndex = this.values.indexOf(selectedWorkspace);
    this.selectedWorkspaceId = selectedWorkspace?._id;
  }

  private set windowViewportSize(size: viewportSize) {
    if (size !== this.viewportSize) {
      this.viewportSize = size;
    }
  }

  private focusOnNonDisabledToggleButton(evt?: MouseEvent): void {
    if (evt) {
      setTimeout(() => {
        const button: HTMLButtonElement = (evt.target as HTMLElement).parentNode.querySelector('button:not([disabled])');
        if (button) {
          button.focus();
        }
      }, 0);
    }
  }

  private propertySelectionConfirmed(): boolean {
    const confirmations: string[] = Object.values(DigitalPropertyWorkspaceFilterComponent.onChangeConfirmations);
    if (confirmations.length === 0) {
      return true;
    }
    const confirmResults: boolean[] = [];
    confirmations.forEach((message: string) => {
      confirmResults.push(this.windowService.confirm(this.translateService.instant(message)));
    });
    return confirmResults.filter((confirmed: boolean): boolean => confirmed === false).length === 0;
  }

  public get shouldShowExpandCollapseButtons(): boolean {
    return this.viewportSize !== viewportSize.small;
  }

  public static setOnChangeConfirmation(component: any, text?: string): void {
    if (typeof text !== 'undefined') {
      this.onChangeConfirmations[component] = text;
    } else {
      delete this.onChangeConfirmations[component];
    }
  }

  public onWindowResize(): void {
    const size: viewportSize = DetectUtility.getViewportSize();
    this.windowViewportSize = size;
  }

  public select(digitalPropertyId: string, initialLoading: boolean = false): void {
    if (digitalPropertyId === this.selectedDigitalPropertyId) {
      return;
    }
    if (initialLoading || this.propertySelectionConfirmed()) {
      this.selectedDigitalPropertyId = digitalPropertyId;
      this.updateSelectedWorkspace();
      this.onTouched();
      this.onChange(digitalPropertyId);
      this.propertyChange.emit(
        this.properties.find((prop: IDigitalPropertyListItem) => prop[$digitalProperty._id] === digitalPropertyId),
      );
      this.changeDetectorRef.detectChanges();
    }
  }

  public applyFilter(): void {
    this.collapseAll();
    this.filteredValues = [];

    const searchString: string = this.filterText.trim().toLowerCase();

    if (searchString.length === 0) {
      this.filteredValues = [...this.values];
      this.changeDetectorRef.detectChanges();
      return;
    }

    const isPropertyNameAllowed = (prop: IDigitalPropertyListItem): boolean => {
      return prop[$digitalProperty.name].toLowerCase().includes(searchString);
    };

    for (const value of this.values) {
      if (value.workspaceName.toLowerCase().includes(searchString) || value.digitalProperties.some(isPropertyNameAllowed)) {
        const items: IWorkspacePropsType = { ...value };
        items.digitalProperties = items.digitalProperties.some(isPropertyNameAllowed)
          ? items.digitalProperties.filter(isPropertyNameAllowed)
          : items.digitalProperties;
        this.filteredValues.push(items);
      }
    }

    this.onTouched();
    this.expandAll();
    this.changeDetectorRef.detectChanges();
  }

  public areAllExpanded(): boolean {
    if (this.accordionComponent) {
      return this.accordionComponent.panels.length === this.accordionComponent.activeIds.length;
    }
    return false;
  }

  public areAllCollapsed(): boolean {
    if (this.accordionComponent) {
      return !this.accordionComponent.activeIds.length;
    }
    return false;
  }

  public expandAll(evt?: MouseEvent): void {
    this.accordionComponent.expandAll();
    this.focusOnNonDisabledToggleButton(evt);
  }

  public collapseAll(evt?: MouseEvent): void {
    this.accordionComponent.collapseAll();
    this.focusOnNonDisabledToggleButton(evt);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
    this.select(obj, true);
  }

  public ngAfterViewInit(): void {
    this.subscriptions.add(
      this.busMessageService
        .from(
          BusMessageChannels.workspaceCreated,
          BusMessageChannels.workspaceUpdated,
          BusMessageChannels.workspaceDeleted,
          BusMessageChannels.digitalPropertyChange,
          BusMessageChannels.userSwitchedToDigitalProperty,
        )
        .pipe(
          switchMap((): Observable<IDigitalPropertiesListResponse> => this.userPropertyService.getAvailableDigitalProperties()),
        )
        .subscribe({
          next: this.onDigitalPropertiesLoaded.bind(this),
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
