import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DsButtonVariants, Icons } from '@levelaccess/design-system';

export enum AdvancedSearchOperation {
  contains = 'contains',
  does_not_contain = 'does_not_contain',
  ends_with = 'ends_with',
  starts_with = 'starts_with',
  regex = 'regex',
}

export enum $advancedSearchForm {
  fieldToSearch = 'fieldToSearch',
  operation = 'operation',
  value = 'value',
}

export interface IAdvancedSearchRow {
  [$advancedSearchForm.fieldToSearch]: string;
  [$advancedSearchForm.operation]: AdvancedSearchOperation;
  [$advancedSearchForm.value]: string;
}

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchComponent implements OnInit {
  public $advancedSearchForm: typeof $advancedSearchForm;
  public searchFields: UntypedFormArray;
  public searchForm: UntypedFormGroup;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly Icons: typeof Icons = Icons;

  @Output()
  public onApply: EventEmitter<IAdvancedSearchRow[]>;
  @Input()
  public fieldsToSearch: string[];
  @Input()
  public operations: AdvancedSearchOperation[];
  @Input()
  public defaultField: string;
  @Input()
  public defaultOperation: AdvancedSearchOperation;
  @Input()
  public initialRows: IAdvancedSearchRow[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.onApply = new EventEmitter<IAdvancedSearchRow[]>();
    this.$advancedSearchForm = $advancedSearchForm;

    this.searchFields = this.formBuilder.array([]);
    this.searchForm = this.formBuilder.group({
      searchFields: this.searchFields,
    });
  }

  public addSearchRow(row?: IAdvancedSearchRow): void {
    const searchRow: IAdvancedSearchRow = {
      [$advancedSearchForm.fieldToSearch]: row?.[$advancedSearchForm.fieldToSearch] ?? this.defaultField,
      [$advancedSearchForm.operation]: row?.[$advancedSearchForm.operation] ?? this.defaultOperation,
      [$advancedSearchForm.value]: row?.[$advancedSearchForm.value] ?? '',
    };
    this.searchFields.push(this.formBuilder.group(searchRow));
    this.changeDetectorRef.detectChanges();
  }

  public removeSearchRow(index: number): void {
    this.searchFields.removeAt(index);
    this.changeDetectorRef.detectChanges();
  }

  public submitSearch(): void {
    this.onApply.emit(this.searchFields.value);
    this.changeDetectorRef.detectChanges();
  }

  public resetSearch(): void {
    this.searchFields.clear();
    this.addSearchRow();
    this.onApply.emit(this.searchFields.value);
    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    if (Array.isArray(this.initialRows) && this.initialRows.length > 0) {
      this.initialRows.forEach(this.addSearchRow.bind(this));
    } else {
      this.addSearchRow();
    }
  }

  public get exceededLimit(): boolean {
    return this.fieldsToSearch?.length === this.searchFields?.length;
  }
}
