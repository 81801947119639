export enum $task {
  _id = '_id',
  name = 'name',
  description = 'description',
  status = 'status',
  priority = 'priority',
  attachments = 'attachments',
  attachmentFileOriginNames = 'attachmentFileOriginNames',
  projectId = 'projectId',
  taskProjectId = 'taskProjectId',
  createdAt = 'createdAt',
  creatorId = 'creatorId',
  auditFindings = 'auditFindings',
  auditFindingIds = 'auditFindingIds',
  designReviewFindingIds = 'designReviewFindingIds',
  designReviewFindings = 'designReviewFindings',
  issueIntegrationUrl = 'issueIntegrationUrl',
  issueIntegrationKey = 'issueIntegrationKey',
  issueIntegrationStatus = 'issueIntegrationStatus',
  issueIntegrationSummary = 'issueIntegrationSummary',
  issueIntegrationAssignee = 'issueIntegrationAssignee',
  issueIntegrationPriority = 'issueIntegrationPriority',
  pushTaskToIssueTracking = 'pushTaskToIssueTracking',
  issueIntegrationType = 'issueIntegrationType',
  linkedFlaws = 'linkedFlaws',
  lastSynchronized = 'lastSynchronized',
  issueIntegrationSyncStatus = 'issueIntegrationSyncStatus',
  taskIssueIntegration = 'taskIssueIntegration',
  assigneeId = 'assigneeId',
  assignee = 'assignee',
  source = 'source',
  asanaWebhookSecret = 'asanaWebhookSecret',
  unsuccessfullyLinkedIssueIntegrationKey = 'unsuccessfullyLinkedIssueIntegrationKey',
  integrationRequestedBy = 'integrationRequestedBy',
}

export enum $taskFlaw {
  flaws = 'flaws',
  unmatchedFlaws = 'unmatchedFlaws',
  scanId = 'scanId',
  digitalPropertyId = 'digitalPropertyId',
}

export enum $taskWithTargetStates {
  targetStates = 'targetStates',
}

export enum $taskWithProject {
  project = 'project',
}

export enum TaskStatus {
  backlog = 'backlog',
  toDo = 'toDo',
  inProgress = 'inProgress',
  requiresReview = 'requiresReview',
  reviewInProgress = 'reviewInProgress',
  done = 'done',
}

export enum $testingEnvironment {
  staging = 'staging',
  development = 'development',
  production = 'production',
}

export const TESTING_ENVIRONMENTS: Readonly<$testingEnvironment[]> = Object.values($testingEnvironment);

export const EA_STATUSES: Readonly<TaskStatus[]> = Object.freeze([TaskStatus.reviewInProgress, TaskStatus.done]);

export enum TaskPriority {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum TaskCommentType {
  taskAssigned = 'taskAssigned',
  taskUnassigned = 'taskUnassigned',
  taskDeleted = 'taskDeleted',
  error = 'error',
}

export enum $taskComment {
  _id = '_id',
  content = 'content',
  taskId = 'taskId',
  author = 'author',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  issueIntegrationId = 'issueIntegrationId',
  issueIntegrationKey = 'issueIntegrationKey',
  externalAuthorName = 'externalAuthorName',
  externalDelete = 'externalDelete',
  commentType = 'commentType',
  newAssignee = 'newAssignee',
  previousAssignee = 'previousAssignee',
  integrationType = 'integrationType',
  error = 'error',
}

export enum TaskSource {
  designReview = 'designReview',
  manual = 'manual',
  automated = 'automated',
}

export enum $taskSupport {
  _id = '_id',
  attachments = 'attachments',
  digitalPropertyId = 'digitalPropertyId',
  message = 'message',
  taskId = 'taskId',
  tenantId = 'tenantId',
  userId = 'userId',
  userEmail = 'userEmail',
  workspaceId = 'workspaceId',
}
