import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Api } from '../../../../../shared/constants/api';
import { ErrorHandlerService } from '../../error-handler.service';
import { AppConfigService } from '../../app-config.service';

@Injectable()
export class TenantlessGuard {
  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private appConfigService: AppConfigService,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const hasAccess: boolean = !this.appConfigService.isTenantEnabled();
    if (!hasAccess) {
      // TODO: From EAP-4845, this shouldn't go to a forbidden page. It should go to a missing tenant page.
      this.router
        .navigate([`/${Api.forbidden}`], { queryParams: { url: state.url, title: next.data.title } })
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    }
    return of(hasAccess);
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }
}
