import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface Channel {
  subject: Subject<string>;
}

interface Channels {
  [key: string]: string;
}

export enum BusMessageChannels {
  isSidebarVisible = 'isSidebarVisible',
  notificationsReady = 'notificationsReady',
  workspaceUpdated = 'workspaceUpdated',
  workspaceCreated = 'workspaceCreated',
  digitalPropertyChange = 'digitalPropertyChange',
  automatedScanPageScanCompleted = 'automatedScanPageScanCompleted',
  userSwitchedToDigitalProperty = 'userSwitchedToDigitalProperty',
  userSwitchedToWorkspace = 'userSwitchedToWorkspace',
  workspaceTrainingVideosCount = 'workspaceTrainingVideosCount',
  workspaceDeleted = 'workspaceDeleted',
  scanStopped = 'scanStopped',
}

@Injectable({
  providedIn: 'root',
})
export class BusMessageService {
  private listeners: Record<string, any>;
  private channels: Channels = {};

  constructor() {
    this.listeners = {};
  }

  private buildChannelsName(): void {
    for (const channel in BusMessageChannels) {
      this.channels[channel] = channel;
    }
  }

  public initChannel(channelName: string): void {
    const channel: Channel = {
      subject: new BehaviorSubject<any>(null),
    };

    if (typeof this.listeners[channelName] === 'object') {
      throw new Error('[MessageBusService.initChannel] channel "' + channelName + '" already exists.');
    }

    this.listeners[channelName] = channel;
  }

  public removeChannel(channel: string): void {
    delete this.listeners[channel];
  }

  public async initChannels(): Promise<void> {
    const createChannels = (resolve: Function): void => {
      this.buildChannelsName();

      Object.keys(this.channels).forEach(this.initChannel.bind(this));

      resolve();
    };

    return new Promise(createChannels);
  }

  public from(...channels: string[]): Observable<any> {
    for (const channel of channels) {
      if (typeof this.listeners[channel] === 'undefined') {
        throw new Error('[MessageBusService] from, channel "' + channel + '" does not exists.');
      }
    }

    if (channels.length === 1) {
      return this.listeners[channels[0]].subject.asObservable();
    }

    return combineLatest(channels.map((channel: string): Observable<any> => this.listeners[channel].subject.asObservable())).pipe(
      debounceTime(100),
    );
  }

  public to(channel: string): Subject<any> {
    if (typeof this.listeners[channel] === 'undefined') {
      throw new Error('[MessageBusService.to] channel "' + channel + '" does not exists.');
    }
    return this.listeners[channel].subject;
  }

  public getValue(channel: string): any {
    return this.listeners[channel].subject.getValue();
  }
}
