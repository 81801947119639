import { ICustomSeverityWithSeverityKey } from '../../../shared/interfaces/tenant.interface';

export enum SeverityClass {
  critical = 'critical',
  high = 'high',
  moderate = 'moderate',
  minor = 'minor',
  low = 'low',
}

export interface ICustomSeverityWithTableRanking extends ICustomSeverityWithSeverityKey {
  tableRanking: number;
  tableRankingClass: SeverityClass;
}
