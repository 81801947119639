<form [formGroup]="form">
  <div class="margin-bottom">
    <h3 class="mb-0">{{ 'quick_monitoring_setup' | translate }}</h3>
    <span>{{ 'required_fields_label' | translate }}</span>
  </div>
  <div class="margin-bottom">
    <span class="d-block font-weight-500">{{ 'scope' | translate }}</span>
    <span
      >{{ 'crawl_dp_description_first_part' | translate }}
      <ds-link external="true" [href]="url">{{ url }}</ds-link>
      {{ 'crawl_dp_description_second_part' | translate }}
    </span>
  </div>
  <div class="margin-bottom d-flex">
    <div class="margin-right">
      <app-common-select
        [form]="form"
        [formValidationRequest$]="formValidationRequest$"
        [values]="recurrenceValues"
        [context]="{
          label: 'recurrence',
          field: $digitalPropertyMonitoring.occurrence,
          nameKey: 'key',
          valueKey: 'val',
        }"
      ></app-common-select>
    </div>
    <div class="margin-right">
      <app-common-input
        class="d-block margin-bottom"
        [form]="form"
        [formValidationRequest$]="formValidationRequest$"
        [context]="{
          label: 'start_on',
          placeholder: '',
          field: $digitalPropertyMonitoring.startAt,
          type: 'date',
          overrideErrors: {
            minDate: 'date_must_be_greater_than_today' | translate,
          },
        }"
      ></app-common-input>
    </div>
  </div>
  <div class="schedule_info margin-bottom">
    {{ scheduleInfo }}
  </div>
  <div class="d-flex margin-bottom">
    <div class="d-flex flex-column margin-right">
      <label class="d-block"
        >{{ 'testing_tool' | translate }}
        <app-icon
          class="margin-bottom-sm tooltip-opener"
          appTooltip="{{ 'default_dp_tool' | translate }}"
          [attr.aria-label]="'default_dp_tool' | translate"
          container="body"
          icon="question-mark-tooltip"
        >
        </app-icon>
      </label>
      <span class="d-block">{{ 'scan_results_tool_option_' + testingTool | translate }}</span>
    </div>
    <div class="d-flex flex-column margin-right">
      <label class="d-block">{{ 'digital_default_conformance_level_label' | translate }}</label>
      <span class="d-block" >{{ conformanceLevel | translate }}</span>
    </div>
    <div class="d-flex flex-column margin-right">
      <label class="d-block">{{ 'scan_viewport_label' | translate }}</label>
      <span class="d-block">{{ scanViewport }}</span>
    </div>
  </div>
  <app-common-info-note>
    {{ 'monitoring_ea_allow_list_info' | translate }}
    <a href="https://wcag.zendesk.com/hc/en-us/articles/9249741469847" target="_blank">
      {{ 'monitoring_ea_allow_list_info_link_label' | translate }}
      <app-icon [icon]="Icons.ExternalLinkIcon" [style.color]="EapColors.BlueInteraction"></app-icon>
    </a>
  </app-common-info-note>
</form>
