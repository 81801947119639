import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellConfig, BaseCellComponent } from '../cells/base-cell/base-cell.component';

@Component({
  selector: 'dcl-wrapper',
  template: `<ng-container #target></ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DclWrapperComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef })
  private target: ViewContainerRef;
  private isViewInitialized: boolean;

  @Input() public type: any;
  @Input() public config: ICellConfig;

  public cmpRef: ComponentRef<BaseCellComponent>;

  public constructor(private changeDetector: ChangeDetectorRef) {
    this.isViewInitialized = false;
  }

  private updateComponent(): void {
    if (this.isViewInitialized === false) {
      return;
    }

    if (this.cmpRef) {
      this.cmpRef.destroy();
    }

    this.cmpRef = this.target.createComponent(this.type);

    if (this.config) {
      this.cmpRef.instance.config = this.config;
    }

    this.changeDetector.detectChanges();
  }

  public ngOnChanges(): void {
    this.updateComponent();
  }

  public ngAfterViewInit(): void {
    this.isViewInitialized = true;
    this.updateComponent();
  }

  public ngOnDestroy(): void {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }

  public markForCheck(): void {
    this.changeDetector.markForCheck();
  }
}
