import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { KEYS } from '../../../../shared/constants/keys';
import { CommonUtility } from '../../utility/common.utility';

@Directive({
  selector: '[appTabs]',
})
export class TabsDirective implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription;

  constructor(
    private element: ElementRef,
    private route: ActivatedRoute,
  ) {
    this.subscriptions = new Subscription();
  }

  private resetTabs(): void {
    const tabsList: HTMLElement = this.element.nativeElement;
    const allTabs: NodeListOf<HTMLAnchorElement> = Array.prototype.slice.call(tabsList.querySelectorAll('a'), 0);

    const resetTab = (tab: HTMLAnchorElement): void => {
      const tabId: string = tab.hash.replace('#', '') || tab.getAttribute('aria-controls');
      const tabContent: HTMLElement = document.getElementById(tabId);

      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-selected', 'false');
      tab.setAttribute('aria-expanded', 'false');

      tab.closest('li').classList.remove('selected-tab');

      if (tabContent) {
        tabContent.classList.remove('hide');
        tabContent.classList.add('hide');
        tabContent.removeAttribute('tabindex');
      }
    };

    allTabs.forEach(resetTab);
  }

  private setActiveTab(tab: HTMLAnchorElement): void {
    const tabId: string = tab.hash.replace('#', '') || tab.getAttribute('aria-controls');
    const tabContent: HTMLElement = document.getElementById(tabId);

    tab.setAttribute('tabindex', '0');
    tab.setAttribute('aria-selected', 'true');
    tab.setAttribute('aria-expanded', 'true');

    tab.closest('li').classList.add('selected-tab');

    if (tabContent) {
      tabContent.classList.remove('hide');
      tabContent.setAttribute('tabindex', '0');
    }
  }

  private goToPreviousTab(tab: HTMLAnchorElement): void {
    const previousLi: Element = tab.closest('li').previousElementSibling;

    if (previousLi === null) {
      return;
    }

    const previousTab: HTMLAnchorElement = previousLi.querySelector('a');

    this.resetTabs();
    this.setActiveTab(previousTab);
    CommonUtility.setFocusToElement(null, previousTab);
  }

  private goToNextTab(tab: HTMLAnchorElement): void {
    const nextLi: Element = tab.closest('li').nextElementSibling;

    if (nextLi === null) {
      return;
    }

    const nextTab: HTMLAnchorElement = nextLi.querySelector('a');

    this.resetTabs();
    this.setActiveTab(nextTab);
    CommonUtility.setFocusToElement(null, nextTab);
  }

  public ngAfterViewInit(): void {
    const tabsList: HTMLElement = this.element.nativeElement;
    const allTabs: HTMLAnchorElement[] = Array.prototype.slice.call(tabsList.querySelectorAll('a'), 0);

    const applyTabChange = (selectedId: string): void => {
      if (typeof selectedId === 'string' && selectedId.length > 0) {
        const findTab = (tab: HTMLAnchorElement): boolean => {
          return tab.hash.replace('#', '') === selectedId;
        };

        const foundTabIndex: number = allTabs.findIndex(findTab);

        if (foundTabIndex === -1) {
          return;
        }

        this.resetTabs();
        this.setActiveTab(allTabs[foundTabIndex]);
      } else {
        const selected: HTMLAnchorElement = allTabs.find((a: HTMLAnchorElement) => a.getAttribute('aria-selected') === 'true');
        if (typeof selected === 'undefined') {
          return;
        }
        this.resetTabs();
        this.setActiveTab(selected);
      }
    };

    if (this.route.fragment) {
      this.subscriptions.add(this.route.fragment.subscribe(applyTabChange));
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('keydown', ['$event'])
  @HostListener('click', ['$event'])
  public clickEvent(event: Event): void {
    let target: HTMLElement = (event.target as HTMLElement) || (event.srcElement as any);

    if (event.type === 'keydown') {
      if ((event as KeyboardEvent).keyCode === KEYS.LEFT) {
        this.goToPreviousTab(target as HTMLAnchorElement);
        event.preventDefault();
      } else if ((event as KeyboardEvent).keyCode === KEYS.RIGHT) {
        this.goToNextTab(target as HTMLAnchorElement);
        event.preventDefault();
      }

      return;
    }

    if ([target.nodeName.toLowerCase(), target.parentNode.nodeName.toLowerCase()].includes('a') === false) {
      return;
    }

    if (target.parentNode.nodeName.toLowerCase() === 'a') {
      target = target.parentNode as HTMLElement;
    }

    this.resetTabs();
    this.setActiveTab(target as HTMLAnchorElement);
    CommonUtility.setFocusToElement(null, target);

    event.preventDefault();
  }
}
