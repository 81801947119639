import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
})
export class KeysPipe implements PipeTransform {
  public transform(value: any, ...exclude: string[]): string[] {
    if (value === null || typeof value === 'undefined' || typeof value !== 'object') {
      return [];
    }
    return Object.keys(value).filter((val: string): boolean => exclude.includes(val) === false);
  }
}
