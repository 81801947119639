import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  public transform(value: string, args?: number): string {
    if (!value) {
      return '';
    }
    const limit: number = args > 0 ? args : 10;
    const trail: string = '...';

    return value.length > limit ? value.substring(0, limit).trim() + trail : value;
  }
}
