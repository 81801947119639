import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ITableEmptyState } from '../utilities/ngb-table.interface';
import { A11yService } from '../../../../services/a11y.service';

@Component({
  selector: 'table-empty-state',
  templateUrl: './table-empty-state.component.html',
  styleUrls: ['./table-empty-state.component.scss'],
})
export class NgbTableEmptyStateComponent implements OnChanges {
  @Input() public config: ITableEmptyState;

  public constructor(private a11yService: A11yService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const prev: ITableEmptyState | undefined = changes['config']?.previousValue;
    const curr: ITableEmptyState | undefined = changes['config']?.currentValue;

    if (curr?.title && prev?.title !== curr?.title) {
      this.a11yService.setMessage(curr.title);
    }

    if (curr?.subtitle && curr?.subtitle !== prev?.subtitle) {
      this.a11yService.setMessage(curr.subtitle);
    }
  }
}
