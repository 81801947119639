<h2>{{ 'deactivate_account' | translate }}</h2>

<div
  *ngIf="isAccountDeactivatedSuccessfully"
  id="successDeactivatingAccount"
  tabindex="-1"
  aria-live="polite"
  role="status"
  class="alert alert-info"
>
  <p>{{ 'account_deactivated_successfully' | translate }}</p>
  <p>{{ 'account_deactivated_unblock_request' | translate: [appConfig.company.emailSupport, appConfig.APP.NAME] }}</p>
  <p>{{ 'redirect_counter' | translate }}: {{ redirectCounter }}</p>
</div>

<p *ngIf="isAccountDeactivatedSuccessfully === false" id="errorDeactivatingAccount" tabindex="-1" class="alert alert-danger">
  {{ 'account_deactivated_unsuccessfully' | translate }}
</p>
<p>
  <a [routerLink]="[]" fragment="deactivatingAccount" target="_self" (click)="toggleDeactivatingAccountSection()">{{
    'start_the_process_of_deactivating_your_account' | translate: appConfig.APP.NAME
  }}</a>
  {{ 'your_profile_will_no_longer_be_accessible_to_anyone' | translate }}
</p>

<form
  *ngIf="isAccountDeactivatedSuccessfully === null || isAccountDeactivatedSuccessfully === false"
  (ngSubmit)="onSubmitDeactivateAccount($event)"
  (keyup.enter)="onSubmitDeactivateAccount($event)"
  [formGroup]="form"
>
  <div id="deactivatingAccount" [(appCollapse)]="isDeactivatingAccountCollapsed">
    <h3>{{ 'tell_us_why_youre_closing_your_account' | translate }}</h3>

    <ul class="feedback-options">
      <li>
        <input type="checkbox" id="duplicateAccount" name="duplicateAccount" formControlName="duplicateAccount" /><label
          for="duplicateAccount"
          >{{ 'i_have_a_duplicate_account' | translate }}</label
        >
      </li>
      <li>
        <input type="checkbox" id="tooManyEmails" name="tooManyEmails" formControlName="tooManyEmails" /><label
          for="tooManyEmails"
          >{{ 'im_getting_too_many_emails' | translate }}</label
        >
      </li>
      <li>
        <input
          type="checkbox"
          id="notGettingValueFromMembership"
          name="notGettingValueFromMembership"
          formControlName="notGettingValueFromMembership"
        /><label for="notGettingValueFromMembership">{{ 'im_not_getting_any_value_from_my_membership' | translate }}</label>
      </li>
      <li>
        <input type="checkbox" id="privacyConcern" name="privacyConcern" formControlName="privacyConcern" /><label
          for="privacyConcern"
          >{{ 'i_have_a_privacy_concern' | translate }}</label
        >
      </li>
      <li>
        <input type="checkbox" id="otherReason" name="otherReason" formControlName="otherReason" /><label for="otherReason">{{
          'other_concern' | translate
        }}</label>
      </li>
    </ul>

    <div class="margin-bottom-lg">
      <label for="userFeedback">{{ 'your_feedback_matters' | translate }}</label>
      <textarea id="userFeedback" name="userFeedback" class="form-element w-100" formControlName="userFeedback"></textarea>
    </div>

    <h3>{{ 'what_else_you_should_know' | translate }}</h3>

    <ol>
      <li>{{ 'deactivate_account_restore_info' | translate }}</li>
      <li [innerHTML]="'deactivate_account_change_display_name' | translateHtml: '/' + Api.settings + '/' + Api.profile"></li>
    </ol>

    <div class="row form-row">
      <div class="col-md-12 text-end">
        <button type="submit" class="btn-danger" [disabled]="form.disabled">{{ 'form_deactivate_account' | translate }}</button>
      </div>
    </div>
  </div>
</form>
