export enum customColumnType {
  attachments = 'attachments',
  checkbox = 'checkbox',
  link = 'link',
  linkedCell = 'linkedCell',
  severity = 'severity',
  externalLink = 'externalLink',
  score = 'score',
  scoreImpact = 'scoreImpact',
  img = 'img',
  monitoring = 'monitoring',
  tooltip = 'tooltip',
  textWithTag = 'textWithTag',
  action = 'action',
  progress = 'progress',
  profilePicture = 'profilePicture',
  remediationProgress = 'remediationProgress',
  invitationStatus = 'invitationStatus',
  forcedWordWrapText = 'forcedWordWrapText',
}

export enum $linkColumn {
  href = 'href',
  linkLabel = 'linkLabel',
}
