import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  ITableColumnSelectorColumnDefinition,
  ITableColumnSelectorColumnGroup,
} from '../../../interfaces/table_column_selector.interface';

@Component({
  selector: 'app-table-column-selector-panel',
  templateUrl: './table-column-selector-panel.component.html',
  styleUrls: ['./table-column-selector-panel.component.scss'],
})
export class TableColumnSelectorPanelComponent implements OnChanges {
  @Input() public selectedColumns: string[];
  @Input() public columnGroups: ITableColumnSelectorColumnGroup[];
  @Output() public selectedColumnsChange: EventEmitter<string[]>;
  @Output() public cancel: EventEmitter<void>;

  public form: UntypedFormGroup;

  public allColumns: Set<string>;
  public readonly maxSelected: number;
  public currentSelectedCount: number;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.allColumns = new Set<string>();
    this.selectedColumns = [];
    this.selectedColumnsChange = new EventEmitter<string[]>();
    this.cancel = new EventEmitter<void>();
    this.maxSelected = 6;
    this.currentSelectedCount = 0;
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({});
  }

  private updateAllColumns(): void {
    this.allColumns = new Set(
      this.columnGroups.reduce((acc: string[], current: ITableColumnSelectorColumnGroup): string[] => {
        return [...acc, ...current.columns.map((value: ITableColumnSelectorColumnDefinition): string => value.field)];
      }, []),
    );
  }

  private setupControls(): void {
    this.updateAllColumns();

    this.allColumns.forEach((column: string): void => {
      if (!this.form.controls[column]) {
        this.form.addControl(column, new UntypedFormControl({ value: false }));
      }
    });

    // remove eventual controls not in the definition
    for (const controlName in this.form.controls) {
      if (this.allColumns.has(controlName) === false) {
        this.form.removeControl(controlName);
      }
    }
    this.initializeSelectedControls();
  }

  private updateControlsLockStatus(): void {
    if (this.currentSelectedCount >= this.maxSelected) {
      for (const col of this.allColumns) {
        if (this.form.get(col).value === false) {
          this.form.get(col).disable();
        } else {
          this.form.get(col).enable();
        }
      }
    } else {
      this.allColumns.forEach((column: string): void => {
        this.form.get(column).enable();
      });
    }
  }

  private initializeSelectedControls(): void {
    // clean-up eventual selected values that are not part of the definition
    if (this.allColumns.size > 0) {
      this.selectedColumns = this.selectedColumns.filter((col: string): boolean => this.allColumns.has(col));
    }
    if (this.selectedColumns.length > this.maxSelected) {
      this.selectedColumns = this.selectedColumns.slice(0, this.maxSelected);
    }

    for (const column of this.allColumns) {
      this.form.get(column).setValue(this.selectedColumns.includes(column));
    }

    this.currentSelectedCount = this.selectedColumns.length;

    this.updateControlsLockStatus();
  }

  private getSelectedFields(): string[] {
    const selectedFields: string[] = [];
    for (const column of this.allColumns) {
      if (this.form.get(column).value) {
        selectedFields.push(column);
      }
    }
    return selectedFields;
  }

  public get columnClass(): string {
    const columnCount: number = this.columnGroups?.length ?? 0;

    if (columnCount < 2) {
      return 'col-12';
    } else if (columnCount === 2) {
      return 'col-6';
    }

    return 'col-4';
  }

  public onSelectionChange(column: string): void {
    if (this.form.get(column).value) {
      this.currentSelectedCount += 1;
    } else {
      this.currentSelectedCount -= 1;
    }
    this.updateControlsLockStatus();
  }

  public onClose(): void {
    this.initializeSelectedControls();
    this.cancel.emit();
  }

  public onApply(): void {
    this.selectedColumnsChange.emit(this.getSelectedFields());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setupControls();
  }
}
