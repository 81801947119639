import { Component, OnDestroy } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { IUserDeactivateAccount } from '../../interfaces/user.interface';
import { TranslateService } from '../../translate/translate.service';
import { A11yService } from '../../services/a11y.service';
import { UserService } from '../../services/user.service';
import { config } from '../../../environments/config.shared';
import { CommonUtility } from '../../utility/common.utility';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { alertType } from '../../constants/alert.constants';
import { Api } from '../../../../shared/constants/api';
import { WindowService } from '../../services/window.service';

const DEFAULT_REDIRECT_AFTER_SUCCESS = 30; // seconds

@Component({
  selector: 'app-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.scss'],
})
export class DeactivateAccountComponent implements OnDestroy {
  private routerChangesSubscription: Subscription;

  public isDeactivatingAccountCollapsed: boolean;
  public isAccountDeactivatedSuccessfully: boolean | null;
  public form: UntypedFormGroup;
  public appConfig: any;
  public alertType: typeof alertType;
  public deactivatingErrorMessage: string | null;
  public redirectCounter: number;
  public Api: typeof Api;

  constructor(
    private router: Router,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private a11yService: A11yService,
    private errorHandlerService: ErrorHandlerService,
    private windowService: WindowService,
  ) {
    this.appConfig = config;
    this.routerChangesSubscription = this.router.events.subscribe(this.handleUrlFragment.bind(this));
    this.isDeactivatingAccountCollapsed = true;
    this.isAccountDeactivatedSuccessfully = null;
    this.alertType = alertType;
    this.deactivatingErrorMessage = null;
    this.redirectCounter = DEFAULT_REDIRECT_AFTER_SUCCESS;
    this.Api = Api;

    this.createForm();
  }

  private createForm(): void {
    const formConfig = {
      duplicateAccount: new UntypedFormControl(null),
      tooManyEmails: new UntypedFormControl(null),
      notGettingValueFromMembership: new UntypedFormControl(null),
      privacyConcern: new UntypedFormControl(null),
      otherReason: new UntypedFormControl(null),
      userFeedback: new UntypedFormControl(null),
    };

    this.form = this.formBuilder.group(formConfig);
  }

  private handleUrlFragment(event: Event): void {
    if (event instanceof Scroll && (event as Scroll).anchor === 'deactivatingAccount') {
      this.isDeactivatingAccountCollapsed = false;
      this.routerChangesSubscription.unsubscribe();
    }
  }

  private getFormData(form: UntypedFormGroup): any {
    const data: IUserDeactivateAccount = {
      duplicateAccount: form.get('duplicateAccount').value,
      tooManyEmails: form.get('tooManyEmails').value,
      notGettingValueFromMembership: form.get('notGettingValueFromMembership').value,
      privacyConcern: form.get('privacyConcern').value,
      otherReason: form.get('otherReason').value,
      userFeedback: form.get('userFeedback').value,
    };

    return data;
  }

  private onDeactivateAccountSuccess(): void {
    const scrollHomePageIntoView = (): void => {
      CommonUtility.scrollPageToTop();
    };

    const countRedirect = (): void => {
      this.redirectCounter -= 1;

      if (this.redirectCounter !== 0) {
        return;
      }

      window.clearInterval(redirectIntervalId);

      this.router
        .navigate(['/'])
        .then(scrollHomePageIntoView)
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    };

    this.isAccountDeactivatedSuccessfully = true;
    this.userService.cleanAfterDeactivateAccount();

    CommonUtility.setFocusToElement('successDeactivatingAccount');
    const redirectIntervalId: number = window.setInterval(countRedirect, 1000);
  }

  private onDeactivateAccountError(response: HttpErrorResponse): void {
    this.isAccountDeactivatedSuccessfully = false;
    this.form.enable();
    CommonUtility.setFocusToElement('errorDeactivatingAccount');

    console.error('onDeactivateAccountError', response);
  }

  public toggleDeactivatingAccountSection(): void {
    this.isDeactivatingAccountCollapsed = !this.isDeactivatingAccountCollapsed;

    if (this.isDeactivatingAccountCollapsed) {
      this.router
        .navigate([], { preserveFragment: false })
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

      return;
    }

    this.router
      .navigate([], { fragment: 'deactivatingAccount' })
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  public onSubmitDeactivateAccount(event: Event): void {
    if (CommonUtility.isFormActionElement(event) === false) {
      return;
    }

    const isDeactivationConfirmed = this.windowService.confirm(this.translateService.instant('deactivate_account_confirmation'));

    if (isDeactivationConfirmed === false) {
      return;
    }

    this.form.disable();

    this.a11yService.setMessage(this.translateService.instant('deactivating_your_account'));

    const angularFormData: IUserDeactivateAccount = this.getFormData(this.form);

    this.routerChangesSubscription.add(
      this.userService
        .deactivateAccount(angularFormData)
        .subscribe(this.onDeactivateAccountSuccess.bind(this), this.onDeactivateAccountError.bind(this)),
    );
  }

  public ngOnDestroy(): void {
    this.routerChangesSubscription.unsubscribe();
  }
}
