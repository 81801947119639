import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { IBreadcrumb } from '../../components/breadcrumbs/breadcrumbs.shared';

export type IPostProcessBreadcrumb = Promise<IBreadcrumb[]> | Observable<IBreadcrumb[]> | IBreadcrumb[];

export type IPostProcessFunc = (crumbs: IBreadcrumb[]) => IPostProcessBreadcrumb;

@Injectable()
export class BreadcrumbsConfig {
  public postProcess: IPostProcessFunc;
}
