import { IPoint } from '../interfaces/image-highlight.interface';

export enum HighlightModes {
  add = 'add',
  view = 'view',
  edit = 'edit',
}

export enum ImageHighlightModes {
  add = 'add',
  view = 'view',
  edit = 'edit',
  remove = 'remove',
}

export enum ImageHighlightAction {
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
  Paste = 'paste',
}

export enum ImageHighlightStates {
  noop = 'noop',
  adding = 'adding',
  resizing = 'resizing',
  moving = 'moving',
}

export enum ZoomDirection {
  in = 'in',
  out = 'out',
}

export const ZOOM_FACTORS: readonly number[] = Object.freeze([0.3, 0.5, 0.75, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5]);

export enum BoxPositions {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

export const MIN_HIGHLIGHT_SIZE: number = 20;
export const DEFAULT_HIGHLIGHT_WIDTH: number = 100;
export const DEFAULT_HIGHLIGHT_HEIGHT: number = 100;
export const DEFAULT_HIGHLIGHT_MARGIN: number = 5;
export const DEFAULT_HIGHLIGHT_POSITION: Readonly<IPoint> = Object.freeze({ x: 5, y: 5 });

export enum MovementDirection {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUpLeft = 'ArrowUpLeft',
  ArrowUpRight = 'ArrowUpRight',
  ArrowDownLeft = 'ArrowDownLeft',
  ArrowDownRight = 'ArrowDownRight',
}

export enum MovementSpeed {
  slow = 1,
  fast = 10,
}

export enum EventKeys {
  Meta = 'Meta',
}
