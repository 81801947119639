<div class="d-inline-block" ngbDropdown>
  <button
    type="button"
    class="btn btn-link d-flex align-items-center"
    id="selectDownloadReportType_{{ selectLabelId }}"
    [attr.disabled]="disabled ? 'disabled' : null"
    ngbDropdownToggle
  >
    <span class="margin-right-xs">{{ 'action_download' | translate }}</span>
    <svg viewBox="0 0 8 8" class="icon download-report"><use xlink:href="#download-button"></use></svg>
  </button>
  <div ngbDropdownMenu role="listbox">
    <button
      type="button"
      ngbDropdownItem
      role="option"
      *ngFor="let reportFormat of reportFormats"
      [attr.aria-selected]="reportFormat.type === selectedFileExtension"
      (click)="onDownloadReportRequest(reportFormat.type)"
    >
      {{ 'download_report_format_label' | translate: reportFormat.type }}
    </button>
  </div>
</div>
