import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import { TRANSLATIONS_VALUE_PLACEHOLDER } from '../../translate/translate.service';

type TemplateKey = '0' | '1' | '2' | '3' | '4' | '5';

/**
 * @example
 *     <div [appTranslateTemplates]="{ '0': dsLinkTemplate }" [innerHTML]="'translation_with_placeholder_0' | translate"></div>
 *
 *     <ng-template #dsLinkTemplate>
 *       <ds-link [external]="true" [href]="'https://example.com'">Contact Support</ds-link>
 *     </ng-template>
 *
 */
@Directive({
  selector: '[appTranslateTemplates][innerHTML]',
})
export class TranslateTemplatesDirective implements AfterViewInit {
  @Input() appTranslateTemplates: Partial<Record<TemplateKey, TemplateRef<any>>> = {};

  constructor(
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    const element: HTMLElement = this.viewContainerRef.element.nativeElement;
    const html: string = element.innerHTML;

    this.viewContainerRef.clear();

    Object.keys(this.appTranslateTemplates).forEach((key: string, index: number) => {
      const placeholder: string = `${TRANSLATIONS_VALUE_PLACEHOLDER}${index}`;

      const parts: string[] = html.split(placeholder);

      element.innerHTML = parts[0];

      if (this.appTranslateTemplates[key]) {
        const viewRef: EmbeddedViewRef<any> = this.viewContainerRef.createEmbeddedView(this.appTranslateTemplates[key]);

        viewRef.rootNodes.forEach((node: Node) => element.appendChild(node));
      }

      if (parts[1]) {
        const remainingText: Text = document.createTextNode(parts[1]);
        element.appendChild(remainingText);
      }
    });

    this.changeDetectorRef.detectChanges();
  }
}
