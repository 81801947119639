import { Component, AfterViewInit, Input, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { TypeaheadComponent } from '../../../../typeahead/typeahead.component';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface ITypeAheadCellConfig extends ICellConfig {
  data$: Observable<Record<string, any>[]>;
  form: UntypedFormGroup;
  formField: string;
  nameKey: string;
  valueKey: string;
  onSearch: (term: string) => void;
  defaultTerm?: string;
}

@Component({
  selector: 'table-cell-typeahead',
  template: `
    <app-typeahead
      #ref
      [data]="config.data$ | async"
      [isAutofocused]="false"
      (onSearch)="onSearch($event)"
      (onSelect)="onSelect($event)"
    >
      <ng-template #typeaheadResultRow let-item>
        <div class="name">{{ item[config.nameKey] }}</div>
      </ng-template>
    </app-typeahead>
  `,
})
export class TypeAheadCellComponent extends BaseCellComponent implements AfterViewInit {
  @Input() public config: ITypeAheadCellConfig;

  @ViewChild(TypeaheadComponent)
  private typeaheadComponent: TypeaheadComponent;

  constructor(
    renderer2: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(renderer2);
  }

  public onSearch(term: string): void {
    this.config.onSearch(term);
  }

  public onSelect(value: any): void {
    this.typeaheadComponent.searchQuery = value[this.config.nameKey];
    this.config.form.get(this.config.formField).setValue(value[this.config.valueKey]);
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (SharedCommonUtility.notNullishOrEmpty(this.config.defaultTerm)) {
      this.typeaheadComponent.searchQuery = this.config.defaultTerm;
      this.changeDetectorRef.detectChanges();
    }
  }
}
