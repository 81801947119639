import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ITestGuideSimpleServerResponse } from '../../../../shared/interfaces/test-guide.interface';
import { ApiQueryOption } from '../../../../shared/constants/api';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { RestBuilder } from '../helpers/rest.builder';

@Injectable({
  providedIn: 'root',
})
export class TestGuidesApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /test-guides/names
  public getTestGuideNames(dpId?: string, filterLegacyEA: boolean = false): Observable<ITestGuideSimpleServerResponse> {
    const url: string = this.restBuilder.create().testGuides().names().getApiUrl();

    const query: {
      [ApiQueryOption.digitalPropertyId]?: string;
      [ApiQueryOption.filterLegacyEA]?: boolean;
    } = {};
    if (SharedCommonUtility.notNullish(dpId)) {
      query[ApiQueryOption.digitalPropertyId] = dpId;
    }
    if (SharedCommonUtility.notNullish(filterLegacyEA)) {
      query[ApiQueryOption.filterLegacyEA] = filterLegacyEA;
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: query,
    });
    return this.httpClient.get<ITestGuideSimpleServerResponse>(url, { params: httpParams });
  }
}
