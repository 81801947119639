import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, pairwise, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServerVersionService {
  private $version: BehaviorSubject<string>;

  constructor() {
    this.$version = new BehaviorSubject<string>(null);
  }

  public get versionChange(): Observable<string> {
    const isNull = (v: string | null): boolean => v === null;
    const compare = ([prev, curr]: [string, string]): string | null => {
      if (prev !== curr) {
        return curr;
      }

      return null;
    };

    return this.$version.pipe(skipWhile(isNull), pairwise(), map(compare), skipWhile(isNull));
  }

  public get version(): string | null {
    return this.$version.value;
  }

  public set version(value: string) {
    this.$version.next(value);
  }
}
