import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router, Event } from '@angular/router';
import { DsInputStates } from '@levelaccess/design-system';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { Api } from '../../../../../shared/constants/api';
import { $digitalProperty } from '../../../../../shared/constants/digital-properties';
import { FunctionalArea, SecureAction } from '../../../../../shared/constants/security';
import { $workspace } from '../../../../../shared/constants/workspace';
import {
  IDigitalPropertiesListResponse,
  IDigitalPropertyListItem,
} from '../../../../../shared/interfaces/digital-property.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';
import { AclSecurityAdapter } from '../../../services/acl.service';
import { BusMessageChannels, BusMessageService } from '../../../services/bus-message.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { INavMenuItem, NavigationItem } from '../../../services/navigation/nav-structure';
import { NavService } from '../../../services/navigation/nav.service';
import { UserAclService } from '../../../services/user-acl.service';
import { UserPropertyService } from '../../../services/user-property.service';
import { UserService } from '../../../services/user.service';
import { AngularUtility } from '../../../utility/angular.utility';

@Component({
  selector: 'app-websites-apps-select',
  templateUrl: 'websites-apps-select.component.html',
})
export class WebsitesAppsSelectComponent implements OnInit, OnDestroy {
  protected readonly DsInputStates: typeof DsInputStates = DsInputStates;

  private _subscriptions: Subscription;
  private _selectedDigitalPropertyId: string;
  private _selectedWorkspaceId: string;
  private reloadDigitalProperties$: BehaviorSubject<void> = new BehaviorSubject(null);

  public selectedWebApp: string;
  public readonly portfolioValue: string = 'website_app_portfolio_option';
  public selectWebAppTooltip: string;
  public $digitalProperty: typeof $digitalProperty = $digitalProperty;
  public digitalProperties$: Observable<IDigitalPropertyListItem[]>;
  public userHasDashboardAccess: boolean;

  @ViewChild('webAppSelect', { static: false }) webAppSelect: ElementRef;

  @Input() public label: string;
  @Input() public state: DsInputStates;

  @Output() public webAppChanged: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private activatedRoute: ActivatedRoute,
    private busMessageService: BusMessageService,
    private userPropertyService: UserPropertyService,
    private navService: NavService,
    private userAclService: UserAclService,
    private userService: UserService,
  ) {
    this._subscriptions = new Subscription();
    this.userHasDashboardAccess = false;
  }

  private getInitialRouteForUser(activeNavItem: INavMenuItem): string[] {
    if (
      !SharedCommonUtility.isStringEmpty(activeNavItem?.routerLink) &&
      activeNavItem.id !== NavigationItem.websites_and_apps_portfolio
    ) {
      return [activeNavItem.routerLink];
    }

    return this.userHasDashboardAccess ? [`/${Api.dashboard}`] : [`/${Api.manual_evaluations}`];
  }

  public selectWebAppChanged(value: string): void {
    if (value === this.portfolioValue) {
      this.selectedWebApp = this.portfolioValue;
      this.selectWebAppTooltip = null;

      this.router
        .navigate([`/${Api.portfolio}`])
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

      this.webAppChanged.emit();
    } else {
      const handleRouteChange = (digitalProperty: IDigitalPropertyListItem, menuItem: INavMenuItem | null): void => {
        // Ellipsis begin at the 26th character
        this.selectWebAppTooltip =
          digitalProperty[$digitalProperty.name].length > 25 ? digitalProperty[$digitalProperty.name] : null;

        const route: string[] = this.getInitialRouteForUser(menuItem);
        const extras: NavigationExtras = {
          queryParams: LinkedPropertyUtility.getLinkedPropertyQueryParam(
            digitalProperty[$digitalProperty._id],
            digitalProperty[$digitalProperty.workspace][$workspace._id],
          ),
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          skipLocationChange: false,
        };

        this.router
          .navigate(route, extras)
          .then(() => {
            this.busMessageService.to(BusMessageChannels.userSwitchedToDigitalProperty).next();
          })
          .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));

        this.webAppChanged.emit();
      };

      this._subscriptions.add(
        this.switchDigitalProperty$(value)
          .pipe(withLatestFrom(this.navService.activeThirdLevelMenuItem$()))
          .subscribe(([property, navMenuItem]: [IDigitalPropertyListItem, INavMenuItem]) => {
            handleRouteChange(property, navMenuItem);
          }),
      );
    }
  }

  private switchDigitalProperty$(propertyId: string): Observable<IDigitalPropertyListItem> {
    return this.digitalProperties$.pipe(
      take(1),
      map((properties: IDigitalPropertyListItem[]) =>
        properties.find((property: IDigitalPropertyListItem) => property[$digitalProperty._id] === propertyId),
      ),
      filter(SharedCommonUtility.notNullish),
      switchMap((property: IDigitalPropertyListItem) =>
        this.userService
          .switchUserToDigitalProperty(property[$digitalProperty.workspace][$workspace._id], property[$digitalProperty._id])
          .pipe(map(() => property)),
      ),
    );
  }

  private updateSelectedWebApp(linkedPropertyData: ILinkedPropertyData): void {
    this._selectedWorkspaceId = linkedPropertyData[Api.workspaceId];
    this._selectedDigitalPropertyId = linkedPropertyData[Api.digitalPropertyId];

    const setSelectedWebApp = (navMenuItem: INavMenuItem): void => {
      const portfolioItem: INavMenuItem = navMenuItem?.children?.find(
        (child: INavMenuItem): boolean => child.id === NavigationItem.websites_and_apps_portfolio,
      );
      const isPortfolioViewActive: boolean = portfolioItem?.isActive;
      this.selectedWebApp = isPortfolioViewActive ? this.portfolioValue : this._selectedDigitalPropertyId;
    };

    this._subscriptions.add(this.navService.activeSecondLevelMenuItem$().pipe(take(1)).subscribe(setSelectedWebApp.bind(this)));
  }

  private loadAvailableDigitalProperties(): void {
    this.digitalProperties$ = this.reloadDigitalProperties$.pipe(
      startWith(),
      switchMap(() => this.userPropertyService.getAvailableDigitalProperties()),
      map((response: IDigitalPropertiesListResponse) =>
        response.items.filter(
          (dp: IDigitalPropertyListItem) => dp[$digitalProperty.workspace][$workspace._id] === this._selectedWorkspaceId,
        ),
      ),
      map((digitalProperties: IDigitalPropertyListItem[]): IDigitalPropertyListItem[] =>
        digitalProperties.sort((a: IDigitalPropertyListItem, b: IDigitalPropertyListItem) =>
          a[$digitalProperty.name].localeCompare(b[$digitalProperty.name]),
        ),
      ),
      AngularUtility.shareRef(),
    );
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this.router.events
        .pipe(
          filter((event: Event) => event instanceof NavigationEnd),
          map(() => LinkedPropertyUtility.fromLinkedPropertyQueryParam(this.activatedRoute.snapshot.queryParams)),
        )
        .subscribe((linkedPropertyData: ILinkedPropertyData) => this.updateSelectedWebApp(linkedPropertyData)),
    );

    this._subscriptions.add(
      this.activatedRoute.queryParams
        .pipe(map(LinkedPropertyUtility.fromLinkedPropertyQueryParam))
        .subscribe((linkedPropertyData: ILinkedPropertyData) => this.updateSelectedWebApp(linkedPropertyData)),
    );

    this.loadAvailableDigitalProperties();

    this._subscriptions.add(
      this.busMessageService
        .from(BusMessageChannels.userSwitchedToWorkspace)
        .subscribe(() => this.reloadDigitalProperties$.next()),
    );

    this._subscriptions.add(
      this.busMessageService.from(BusMessageChannels.digitalPropertyChange).subscribe(() => this.reloadDigitalProperties$.next()),
    );

    this._subscriptions.add(
      this.userAclService
        .createCheckAccessForCurrentUser()
        .pipe(
          map((adapter: AclSecurityAdapter): boolean =>
            adapter
              .useWorkspaceFromUser()
              .useDigitalPropertyFromUser()
              .useFunctionalActions({
                [FunctionalArea.gr_dashboard]: [SecureAction.read],
              })
              .check(),
          ),
        )
        .subscribe((permission: boolean) => {
          this.userHasDashboardAccess = permission;
        }),
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
