import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { SharedTextUtility } from '../../../../../shared/utils/text.utility';
import {
  PromptConfirmationModalOptions,
  PromptDialogListArgument,
  PromptDialogListItem,
  TranslationPromptArgument,
} from '../../../interfaces/modal-dialog.interface';
import { TranslateService } from '../../../translate/translate.service';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { InputFieldContext } from '../../common-input/common-input.component';
import { ErrorMessageService } from '../../../services/error-message.service';
import { ModalContainerComponent } from '../modal-container.component';
import { IModal } from '../modal.interface';

enum FormFields {
  prompt = 'prompt',
}

@Component({
  selector: 'app-prompt-confirmation-modal',
  templateUrl: './prompt-confirmation-modal.component.html',
  styleUrls: ['./prompt-confirmation-modal.component.scss'],
})
export class PromptConfirmationModalComponent implements IModal, AfterViewInit {
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  @Input() public options: PromptConfirmationModalOptions;

  public confirmed: boolean;
  public form: UntypedFormGroup;
  public formValidationRequest$: Subject<void> = new Subject();

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private errorMessageService: ErrorMessageService,
    private element: ElementRef<Element>,
  ) {
    this.confirmed = false;
    this.form = this.formBuilder.group({
      [FormFields.prompt]: new UntypedFormControl(null, [CustomValidators.required]),
    });
  }

  private updatePatternValidator(): void {
    const formControl: UntypedFormControl = this.form.get(FormFields.prompt) as UntypedFormControl;
    const pattern: string = this.translateService.instant(this.options.promptInputKey);
    formControl.setValidators([CustomValidators.required, CustomValidators.pattern(pattern)]);
  }

  public get nameInputContext(): InputFieldContext {
    return {
      label: 'confirmation',
      field: FormFields.prompt,
      description: this.translateService.instant(this.options.promptConfirmKey),
      overrideErrors: {
        pattern: this.translateService.instant('form_error_pattern_incorrect'),
      },
    };
  }

  private shouldTranslate<T extends PromptDialogListArgument>(value: T): value is T & TranslationPromptArgument {
    return SharedCommonUtility.notNullishOrEmpty((value as TranslationPromptArgument).translationKey);
  }

  public formatListItem(listItem: PromptDialogListItem): string {
    const translationArguments: string[] = listItem.arguments.map((item: PromptDialogListArgument) => {
      const value: string = this.shouldTranslate(item) ? this.translateService.instant(item.translationKey) : String(item.value);
      return item.highlight ? `<b>${SharedTextUtility.escapeHtmlTags(value)}</b>` : value;
    });
    return this.translateService.instant(listItem.messageKey, translationArguments);
  }

  public init(options: PromptConfirmationModalOptions): void {
    const defaultOptions: Partial<PromptConfirmationModalOptions> = {
      cancelButtonKey: 'label_cancel',
    };
    this.options = {
      ...defaultOptions,
      ...options,
    };
  }

  public confirm(): void {
    this.formValidationRequest$.next();

    if (this.form.valid === false) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }

    this.confirmed = true;
    this.container.closeModal();
  }

  public ngAfterViewInit(): void {
    this.updatePatternValidator();
  }
}
