import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { AppConfigService } from '../app-config.service';
import { TokenInterceptor } from './token.interceptor';
import { RestBuilder } from '../helpers/rest.builder';

@Injectable({ providedIn: 'root' })
export class EapAuthTokenInterceptor implements HttpInterceptor {
  private restBuilder: RestBuilder;

  constructor(
    private externalAuthHttpInterceptor: AuthHttpInterceptor,
    private eapAuthInterceptor: TokenInterceptor,
    private config: AppConfigService,
  ) {
    this.restBuilder = new RestBuilder();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url === this.restBuilder.create().config().getApiUrl()) {
      return next.handle(request);
    }

    if (this.config.isUsingExternalIdp()) {
      return this.externalAuthHttpInterceptor.intercept(request, next);
    }
    return this.eapAuthInterceptor.intercept(request, next);
  }
}
