import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { IUser, IUserSmallItem } from '../../../shared/interfaces/user.interface';
import { $user, accountStatus } from '../../../shared/constants/user';
import { TranslateService } from '../translate/translate.service';
import { IRuleAuditHistoryGetQuery, IRuleAuditHistoryLineItem } from '../../../shared/interfaces/rule-audit-history.interface';
import { RuleAuditHistoryType } from '../../../shared/constants/rule-audit-history';
import { UserPropertyService } from './user-property.service';
import { RestService } from './rest.service';
import { ICurrentSelectedProperty } from './user.service';
import { AuditToolNames } from '../../../shared/constants/audit-tool';

@Injectable({ providedIn: 'root' })
export class RuleAuditHistoryService {
  constructor(
    private translateService: TranslateService,
    private restService: RestService,
    private userPropertyService: UserPropertyService,
  ) {}

  /**
   * For Toronto, this produces a message like 'December 17, 2020 at 2:16 p.m. EST by Mr John Doe'
   */
  public getStatusLine(date: Date | string, user: IUser | IUserSmallItem): string {
    const dateStyle: Intl.DateTimeFormat = new Intl.DateTimeFormat([], {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const timeStyle: Intl.DateTimeFormat = new Intl.DateTimeFormat([], {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });

    const dateTimeOfComment: Date = new Date(date);

    const displayName: string = this.translateService.instant('manual_audit_issue_comments_user_timestamp', [
      dateStyle.format(dateTimeOfComment),
      timeStyle.format(dateTimeOfComment),
      user[$user.displayName],
    ]);

    if (user[$user.accountStatus] === accountStatus.active) {
      return displayName;
    }

    return `${displayName} (${this.translateService.instant('status_inactive')})`;
  }

  public getHistory(
    toolName: AuditToolNames,
    rule: string,
    type: RuleAuditHistoryType,
    data: IRuleAuditHistoryGetQuery,
  ): Observable<IRuleAuditHistoryLineItem[]> {
    return this.userPropertyService.currentSelectedProperty().pipe(
      mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IRuleAuditHistoryLineItem[]> => {
        return this.restService.getRuleAuditHistory(workspaceId, digitalPropertyId, toolName, type, rule, data);
      }),
    );
  }
}
