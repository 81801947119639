import { AbstractControl } from '@angular/forms';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { SharedDateUtility } from '../../../../../shared/utils/date.utility';

const $yearsRange: number = 5;

export interface IParams {
  min?: Date | null;
  max?: Date | null;
}

export class DateFallbackFormUtils {
  constructor() {}

  public static populateYears(field: AbstractControl): number[] {
    const date: Date = new Date();
    const year: number = date.getFullYear() + $yearsRange;

    const years: number[] = [];
    for (let i: number = 0; i <= $yearsRange * 2; i += 1) {
      years.push(year - i);
    }

    field.setValue(date.getFullYear());
    return years;
  }

  public static populateDays(field: AbstractControl, month: number, date: Date): number[] {
    const resultDate: Date = date instanceof Date ? date : new Date();

    const days: number[] = [];
    const daysNum: number = SharedDateUtility.amountOfDaysInMonth(month + 1, resultDate.getFullYear());

    for (let i: number = 1; i <= daysNum; i += 1) {
      days.push(i);
    }

    // Note: in case there are less days than in previously selected month
    if (daysNum < resultDate.getDate()) {
      field.setValue(daysNum);
    }
    return days;
  }

  public static setResultDate(params: IParams, receivedDate: Date): Date {
    if (SharedCommonUtility.isDateValid(receivedDate) === false) {
      return null;
    }
    let date: Date = new Date(receivedDate);

    if (SharedCommonUtility.getTypeOf(params.min) === 'date' && date < params.min) {
      date = new Date(params.min.getTime());
    }
    if (SharedCommonUtility.getTypeOf(params.max) === 'date' && date > params.max) {
      date = new Date(params.max.getTime());
    }
    date.setSeconds(0, 0);
    date.setUTCSeconds(0, 0);

    return date;
  }
}
