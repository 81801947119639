<app-modal-container [title]="'edit_screenshot' | translate" [scrollable]="true" cardBodyClass="px-4 py-2">
  <div class="modal-header-content">
    {{ 'edit_screenshot' | translate }}
  </div>

  <div class="modal-body-content">
    <div class="required-field-label mb-4">{{ 'required_fields_label' | translate }}</div>
    <div class="font-weight-500 mb-2">{{ 'screenshot' | translate }}</div>

    <div class="row mb-4">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <img [appSecureResourceId]="uploadId" [alt]="'screenshot_preview_alt' | translate" class="img-preview" />
      </div>
    </div>

    <app-common-input
      class="mb-2"
      [form]="form"
      [formValidationRequest$]="formValidationRequest$"
      [context]="{
        id: 'edit-attachment-alt-text',
        field: $uploads.altText,
        label: 'screenshot_alt_text',
        required: true,
      }"
    ></app-common-input>
    <app-form-field-char-count [field]="form.get($uploads.altText)" [charLimit]="CHAR_LIMIT_ALT_TEXT">
    </app-form-field-char-count>
  </div>

  <div class="modal-footer-content">
    <div class="row">
      <div class="col-12 d-flex">
        <button class="btn btn-outline-primary font-weight-500" type="button" (click)="cancel()">
          {{ 'cancel' | translate }}
        </button>
        <button class="btn btn-primary font-weight-500 ms-auto d-block" type="button" (click)="save()">
          {{ 'save' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal-container>
