import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';

import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { UserService } from '../../../services/user.service';
import { $user, accountStatus } from '../../../../../shared/constants/user';
import { TranslateService } from '../../../translate/translate.service';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';

@Component({
  selector: 'app-view-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <img
      [src]="getUserAvatarUrl()"
      [style.width]="width"
      [style.height]="height"
      [style.borderRadius.%]="100"
      alt="{{ getAltText() + ' ' + ('user_profile_avatar' | translate) }}"
    />
  `,
})
export class ViewAvatarComponent implements OnChanges {
  @Input() public user: IUserServerResponse;
  @Input() public width: string = '1.5rem';
  @Input() public height: string = '1.5rem';

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  public getUserAvatarUrl(): string {
    return this.userService.getAvatar(this.user);
  }

  public getAltText(): string {
    if (SharedCommonUtility.isNullish(this.user)) {
      return '';
    }

    if (this.user[$user.accountStatus] === accountStatus.active) {
      return this.user[$user.displayName];
    }

    return `${this.user[$user.displayName]} (${this.translateService.instant('status_inactive')})`;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const prop of Object.getOwnPropertyNames(changes)) {
      if (
        !SharedCommonUtility.isNullish(changes[prop].previousValue) &&
        !isEqual(changes[prop].previousValue, changes[prop].currentValue)
      ) {
        this.changeDetector.detectChanges();
        return;
      }
    }
  }
}
