import { OnInit, OnDestroy, Component, Input } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { IFormErrorMessage } from '../../interfaces/form.interface';
import { CommonUtility } from '../../utility/common.utility';
import { TranslateService } from '../../translate/translate.service';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-form-error-messages',
  templateUrl: './form-error-messages.component.html',
  styleUrls: ['./form-error-messages.component.scss'],
})
export class FormErrorMessagesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  @Input() public form: UntypedFormGroup;
  @Input() public header: string;
  @Input() public formValidationRequest$: Observable<void>;

  public formErrors: Array<IFormErrorMessage>;
  public invalidFormControls: AbstractControl[];
  public componentId: string;
  public formErrorsWithNames: any[];

  constructor(
    private translateService: TranslateService,
    private formService: FormService,
  ) {
    this.subscriptions = new Subscription();
    this.header = '';
    this.invalidFormControls = [];
    this.formErrors = [];
    this.componentId = 'form-errors-' + CommonUtility.createUniqueDOMId();
    this.formErrorsWithNames = [];
  }

  private processFormChanges(): void {
    this.formErrors = this.formService.getErrorMessages(this.form);
    this.invalidFormControls = this.formService.getInvalidControls(this.form);

    this.createFormErrorsWithNames();
    CommonUtility.setFocusToElement(this.componentId);
  }

  private createFormErrorsWithNames(): void {
    const getFormErrorsWithNames = (error: IFormErrorMessage): { message: string; controlName: string; controlText: string } => {
      const label: HTMLLabelElement | null = document.querySelector(`[for='${error.controlName}']`);

      let labelText: string = error.controlName;

      if (label === null) {
        console.warn('[FormErrorMessagesComponent.createFormErrorsWithNames] missing label for element', error.controlName);
      } else {
        labelText = label.textContent;
      }

      return {
        controlText: labelText,
        ...error,
      };
    };

    this.formErrorsWithNames = this.formErrors.map(getFormErrorsWithNames);
  }

  public navigateToFormControl(event: Event): void {
    event.preventDefault();

    const elementId: string = (event.target as HTMLAnchorElement).hash.replace('#', '');

    CommonUtility.setFocusToElement(elementId);
  }

  public ngOnInit(): void {
    if (this.header.length === 0) {
      this.header = this.translateService.instant('form_validation_error_title');
    }

    const formValidationRequestSubscription = this.formValidationRequest$.subscribe(this.processFormChanges.bind(this));

    this.subscriptions.add(formValidationRequestSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
