import { Component, Input } from '@angular/core';
import { IRuleStatusToggleData } from '../../../../rule-status-toggle/rule-status-toggle.component';
import { ICellConfig } from '../base-cell/base-cell.component';

export type IRuleStatusToggleConfig = IRuleStatusToggleData & ICellConfig;

@Component({
  selector: 'table-cell-rule-status',
  template: `<app-rule-status-toggle class="d-flex" [data]="config"></app-rule-status-toggle>`,
})
export class RuleStatusToggleCellComponent {
  @Input() public config: IRuleStatusToggleConfig;
}
