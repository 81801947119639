import { Injectable } from '@angular/core';

import { IUserServerResponse } from '../../../shared/interfaces/user.interface';
import { $user } from '../../../shared/constants/user';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { ISecuritySet } from '../../../shared/interfaces/security-set.interface';
import { FunctionalActions } from '../../../shared/interfaces/security-role.interface';
import { SecurityUtility } from '../../../shared/utils/security.utility';
import { ISecurityEntity } from '../../../shared/interfaces/security-group.interface';
import { IDigitalPropertyListItem } from '../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../shared/constants/digital-properties';
import { AclSecurityAdapterBase } from '../../../shared/services/acl-security-adapter';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../shared/interfaces/feature-flag.interface';
import { SecurityEntityLevel } from '../../../shared/constants/security-group';
import { IRequiredSecurity } from '../../../shared/interfaces/security.interface';

export class AclSecurityAdapter extends AclSecurityAdapterBase {
  constructor(
    private readonly user: IUserServerResponse,
    private aclService: AclService,
  ) {
    super();
  }

  public useRequiredSecurity(requiredSecurity: IRequiredSecurity, isAdmin: boolean): this {
    const allowAdminAccess: boolean =
      requiredSecurity.entityLevel !== SecurityEntityLevel.admin && requiredSecurity.allowAdminAccess;

    if (isAdmin && allowAdminAccess) {
      return this.useAdmin();
    } else if (SharedCommonUtility.notNullish(requiredSecurity.entityLevel)) {
      switch (requiredSecurity.entityLevel) {
        case SecurityEntityLevel.admin:
        case SecurityEntityLevel.tenant:
          return this.useEntityLevel();
        case SecurityEntityLevel.digitalProperty:
          return this.useWorkspaceFromUser().useDigitalPropertyFromUser();
        default:
          return this.useWorkspaceFromUser();
      }
    }

    return this;
  }

  private getDigitalPropertyFromUser(): IDigitalPropertyListItem {
    const lastUsedDigitalPropertyId: string = this.user[$user.lastUsedDigitalProperty];
    if (SharedCommonUtility.isNullishOrEmpty(lastUsedDigitalPropertyId)) {
      return undefined;
    }

    const findFn = (property: IDigitalPropertyListItem): boolean => property[$digitalProperty._id] === lastUsedDigitalPropertyId;
    return this.user[$user.digitalProperties].find(findFn);
  }

  private runNewSecurityCheck(): boolean {
    if (SharedCommonUtility.isNullish(this.functionalActions) && this.options.throwIfFunctionalActionsUndefined) {
      throw new Error('[AclSecurityAdapter.check] Functional actions are not defined');
    }

    if (SharedCommonUtility.isNullish(this.functionalActions) && this.options.requireFunctionalActions) {
      return false;
    }

    return this.aclService.canUserAccessResourceWithNewSecurity(
      this.user,
      this.functionalActions,
      this.checkEntityLevel,
      this.workspaceId,
      this.digitalPropertyId,
    );
  }

  public useWorkspaceFromUser(): this {
    const digitalProperty: IDigitalPropertyListItem = this.getDigitalPropertyFromUser();
    if (SharedCommonUtility.isNullish(digitalProperty)) {
      return this;
    }

    this.checkEntityLevel = true;
    this.workspaceId = digitalProperty[$digitalProperty.workspace]._id;
    return this;
  }

  public useTenantFromUser(): this {
    this.checkEntityLevel = true;
    return this;
  }

  public useDigitalPropertyFromUser(): this {
    const digitalProperty: IDigitalPropertyListItem = this.getDigitalPropertyFromUser();
    if (SharedCommonUtility.isNullish(digitalProperty)) {
      return this;
    }

    this.checkEntityLevel = true;
    this.digitalPropertyId = digitalProperty[$digitalProperty._id];
    return this;
  }

  public check(): boolean {
    return this.runNewSecurityCheck();
  }

  public reset(): void {
    this.checkEntityLevel = undefined;
    this.workspaceId = undefined;
    this.digitalPropertyId = undefined;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AclService {
  constructor(private featureFlagService: FeatureFlagService) {}

  public canUserAccessResourceWithNewSecurity(
    user: IUserServerResponse,
    requiredFunctionalActions?: FunctionalActions,
    checkEntityLevel?: boolean,
    workspaceId?: string,
    digitalPropertyId?: string,
  ): boolean {
    const userSecuritySet: ISecuritySet = user?.[$user.securitySet];
    if (SharedCommonUtility.isNullish(userSecuritySet)) {
      return false;
    }

    const securityEntity: ISecurityEntity = checkEntityLevel
      ? SecurityUtility.createSecurityEntity(user[$user.currentTenantId], workspaceId, digitalPropertyId)
      : undefined;

    const hasAccess: boolean = SecurityUtility.hasAccess(userSecuritySet, requiredFunctionalActions, securityEntity);
    if (!hasAccess && this.featureFlagService.variation(FeatureFlagCollection.permissionLogging, false)) {
      console.trace();
      console.error('[Permission logging] required functional actions', requiredFunctionalActions);
      console.error('user security', userSecuritySet);
      console.error('security entity', securityEntity);
    }
    return hasAccess;
  }

  public createAccessCheck(user: IUserServerResponse): AclSecurityAdapter {
    return new AclSecurityAdapter(user, this);
  }
}
