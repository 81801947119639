export enum $ruleAuditHistory {
  _id = '_id',
  digitalProperty = 'digitalProperty',
  bulkOperationId = 'bulkOperationId',
  reason = 'reason',
  user = 'user',
  tool = 'tool',
  selectors = 'selectors',
  selectorsHash = 'selectorsHash',
  url = 'url',
  urls = 'urls',
  urlHash = 'urlHash',
  rule = 'rule',
  type = 'type',
  updatedAt = 'updatedAt',
  createdAt = 'createdAt',
  action = 'action',
  extra = 'extra',
  dataId = 'dataId',
}

export enum RuleAuditHistoryType {
  rule = 'rule',
  issue = 'issue',
  manual = 'manual',
}

export enum RuleAuditHistoryAction {
  dismiss = 'dismiss',
  create = 'create',
  on = 'on',
  off = 'off',
  statusUpdate = 'statusUpdate',
  restore = 'restore',
  bulkDismiss = 'bulk_dismiss',
}

export enum $ruleAuditHistoryExtra {
  from = 'from',
  to = 'to',
  auditFindingId = 'auditFindingId',
  scanId = 'scanId',
}
