import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-arrows-navigation',
  templateUrl: './pagination-arrows-navigation.component.html',
  styleUrls: ['./pagination-arrows-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationArrowsNavigationComponent {
  @Input() public entriesAmount: number;

  @Input() public currentPage: number;
  @Output() public currentPageChange: EventEmitter<number>;

  @Input() public entriesPerPage: number;

  constructor() {
    this.currentPageChange = new EventEmitter<number>();
  }

  public getPaginationFrom(): number {
    if (this.entriesAmount === 0) {
      return 0;
    }

    return (this.currentPage - 1) * this.entriesPerPage + 1;
  }

  public getPaginationTo(): number {
    return Math.min(this.currentPage * this.entriesPerPage, this.entriesAmount);
  }

  public getPagesCount(): number {
    return Math.ceil(this.entriesAmount / this.entriesPerPage);
  }

  public paginate(page: number): void {
    this.currentPage = page;
    this.currentPageChange.emit(page);
  }

  public isFirstPage(): boolean {
    return this.currentPage <= 1;
  }

  public isLastPage(): boolean {
    return this.currentPage >= this.getPagesCount();
  }
}
