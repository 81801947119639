import { Component } from '@angular/core';
import { BaseCellComponent } from '../base-cell/base-cell.component';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';

@Component({
  selector: 'table-cell-external-link-button',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <a #ref class="btn btn-outline-primary p-0 external-link-button" [attr.aria-label]="lastSegmentOfHref">
      <span class="d-inline-block w-100 py-2 px-2">
        {{ config.text }}
        <svg aria-hidden="true" [attr.width]="'1rem'" [attr.height]="'1rem'" class="ms-1">
          <use [attr.xlink:href]="'#external-link'"></use>
        </svg>
      </span>
      <i aria-hidden="true"></i>
    </a>
  `,
})
export class ExternalLinkButtonCellComponent extends BaseCellComponent {
  get lastSegmentOfHref(): string {
    const href: string = this.config?.attributes?.href;
    if (SharedCommonUtility.notNullish(href)) {
      const segments: string[] = href.split('/');
      return segments?.[segments?.length - 1];
    }
    return '';
  }
}
