import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { Icons, IconStyles } from '@levelaccess/design-system';

import { UserNotificationService } from '../../../../services/user-notification.service';
import { Api } from '../../../../../../shared/constants/api';

@Component({
  selector: 'top-nav-notifications',
  templateUrl: './top-nav-notifications.component.html',
  styleUrls: ['./top-nav-notifications.component.scss'],
})
export class TopNavNotificationsComponent {
  public notificationNumber$: Observable<number>;

  public isHovering: boolean = false;
  public isFocusing: boolean = false;

  constructor(private userNotificationService: UserNotificationService) {
    this.notificationNumber$ = this.userNotificationService.newNotificationsCount$;
  }

  public get IconStyle(): IconStyles {
    if (this.isHovering || this.isFocusing) {
      return IconStyles.Solid;
    }
    return IconStyles.Light;
  }

  public get Icon(): Icons {
    return Icons.Bell;
  }

  public get notificationLink(): string {
    return `/${Api.notifications}`;
  }
}
