import { Component, Input, ViewChild } from '@angular/core';

import { IModal } from '../modal.interface';
import { ModalContainerComponent } from '../modal-container.component';
import { AlertModalOptions } from '../../../interfaces/modal-dialog.interface';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements IModal {
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  @Input() public options: AlertModalOptions;

  public init(options: AlertModalOptions): void {
    this.options = options;
  }
}
