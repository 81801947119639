<div *ngIf="mfaIsConfigurable$ | async" class="row form-row">
  <div class="col-4 center-vertically">
    <label class="mfa-label">{{ 'mfa_form_label' | translate }}</label>
  </div>
  <div *ngIf="(mfaStatus$ | async) === $mfaStatus.enabled; else mfaDisabled" class="col-8 center-vertically gap-3 gap-sm-5">
    <div >
      <ds-icon [icon]="Icons.CircleCheck" [iconStyle]="IconStyles.Solid" [style.color]="EapColors.GreenSeverityLow"></ds-icon>
      <span class="margin-left-xs">{{ 'configured' | translate }}</span>
    </div>
    <button
      ds-button
      [variant]="DsButtonVariants.microAction"
      [microActionIcon]="Icons.TrashCan"
      id="disable-mfa-button"
      type="button"
      (click)="openDisableMfaModal()"
    >
      {{ 'remove' | translate }}
    </button>
  </div>
  <ng-template #mfaDisabled>
    <div class="col-8 center-vertically">
      <button
        ds-button
        [variant]="DsButtonVariants.microAction"
        [hideMicroActionIcon]="true"
        id="enable-mfa-button"
        type="button"
        (click)="enableMfa.emit()"
        [disabled]="!canEnableMfa"
      >
        {{ 'mfa_turn_on_mfa' | translate }}
      </button>
    </div>
  </ng-template>
</div>
