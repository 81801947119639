import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class ExtraHeadersInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params: { setHeaders: { [key: string]: string } } = {
      setHeaders: {
        'X-Request-Date': new Date().toISOString(),
      },
    };

    const newRequest: HttpRequest<any> = request.clone(params);

    return next.handle(newRequest);
  }
}
