import { Directive, HostListener } from '@angular/core';

import { CommonUtility } from '../../utility/common.utility';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type="number"]',
})
export class InputOnlyNumbersDirective {
  @HostListener('keypress', ['$event'])
  public keypressEvent(event: KeyboardEvent): void {
    const charCode: number = CommonUtility.getKeyCode(event);

    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public pasteEvent(event: ClipboardEvent): void {
    const data: number = Number(event.clipboardData.getData('text'));

    if (Number.isNaN(data)) {
      event.preventDefault();
    }
  }
}
