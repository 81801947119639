<div class="parent w-100">
  <div id="headers">
    <ol class="d-flex justify-content-between stepper-list m-0 p-0">
      <ng-container *ngFor="let step of steps; let i = index">
        <li class="stepper-list-item" appStepHeader [step]="i" [activeStep]="activeStep" [label]="step.label" [id]="step.id"></li>
      </ng-container>
    </ol>
  </div>
</div>

<div>
  <ng-content></ng-content>
</div>
