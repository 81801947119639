import { snakeCase } from 'lodash';
import { $riskScoreLevels, RiskScoreLevelThresholds } from '../constants/risk-metrics';
import { SharedCommonUtility } from './common.utility';

export class RiskLevelUtility {
  public static getLevel(score: number): $riskScoreLevels {
    if (SharedCommonUtility.isNullish(score)) {
      return null;
    }

    if (score < RiskScoreLevelThresholds[$riskScoreLevels.behind]) {
      return $riskScoreLevels.behind;
    }
    if (score < RiskScoreLevelThresholds[$riskScoreLevels.needsAttention]) {
      return $riskScoreLevels.needsAttention;
    }
    if (score < RiskScoreLevelThresholds[$riskScoreLevels.onTrack]) {
      return $riskScoreLevels.onTrack;
    }
    if (score <= RiskScoreLevelThresholds[$riskScoreLevels.excelling]) {
      return $riskScoreLevels.excelling;
    }
    return null;
  }

  public static getLabelKey(level: $riskScoreLevels): string {
    if (SharedCommonUtility.isNullish(level)) {
      return 'no_status_yet';
    }
    return snakeCase(level);
  }
}
