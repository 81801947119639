<ng-template #tooltip let-tooltip="tooltip" let-icon="icon">
  <ng-container *ngIf="tooltip">
    <app-icon class="ms-2" [appTooltip]="tooltip | translate" [attr.aria-label]="tooltip | translate" [icon]="icon"></app-icon>
  </ng-container>
</ng-template>
<div class="table-responsive" [ngStyle]="config?.responsiveContainerStyles">
  <table class="table table-hover" [class.selected]="rowSelection" [ngStyle]="config?.styles">
    <caption *ngIf="isTableDataAvailable() && config.caption" class="visuallyhidden">
      {{
        config.caption
      }}
    </caption>
    <thead>
      <tr>
        <th *ngIf="config?.singleSelect?.selectionRadioButtons" class="radio">{{ 'select_row' | translate }}</th>
        <ng-container *ngFor="let column of columns">
          <th
            *ngIf="config?.columns[column]?.sortingEnabled; else sortingDisabled"
            scope="col"
            [appAriaSort]="sortingOrder[column]"
            #sort="appAriaSort"
            [ngStyle]="config?.columns[column]?.styles"
            [class.sortable]="true"
          >
            <ng-container *ngIf="config?.columns[column]?.selectAllEnabled">
              <input
                type="checkbox"
                [ngModel]="allSelected"
                (change)="allRowsSelected($event)"
                [attr.aria-label]="config.columns[column]?.translationKey | translate: config?.columns[column]?.translationExtra"
              />
            </ng-container>
            <button
              [appAriaLabelSort]="sort.ariaSort"
              [sortColumn]="config.columns[column]?.translationKey"
              [sortColumnExtra]="config.columns[column]?.translationExtra"
              type="button"
              class="btn btn-link d-flex align-items-center p-0 fw-bold text-decoration-none text-black text-start"
              (click)="sortingTriggered(column)"
            >
              <ng-container *ngIf="!config?.columns[column]?.hideLabel">
                {{ config?.columns[column]?.translationKey | translate: config?.columns[column]?.translationExtra }}
              </ng-container>
              <ng-template
                [ngTemplateOutlet]="tooltip"
                [ngTemplateOutletContext]="{ tooltip: config.columns[column]?.tooltip, icon: Icons.HelpIcon }"
              ></ng-template>
              <ng-container [ngSwitch]="sortingOrder[column]">
                <ng-container *ngSwitchCase="$sortingOrder.asc">
                  <svg>
                    <use xlink:href="#fs-sort-up"></use>
                  </svg>
                </ng-container>

                <ng-container *ngSwitchCase="$sortingOrder.desc">
                  <svg>
                    <use xlink:href="#fs-sort-down"></use>
                  </svg>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <svg>
                    <use xlink:href="#fs-sort"></use>
                  </svg>
                </ng-container>
              </ng-container>
            </button>
          </th>

          <ng-template #sortingDisabled>
            <th scope="col" [ngStyle]="config?.columns[column]?.styles" [class.sortable]="false">
              <ng-container *ngIf="config?.columns[column]?.selectAllEnabled">
                <input
                  type="checkbox"
                  [ngModel]="allSelected"
                  (change)="allRowsSelected($event)"
                  [attr.aria-label]="
                    config.columns[column]?.translationKey | translate: config?.columns[column]?.translationExtra
                  "
                />
              </ng-container>
              <ng-container *ngIf="!config?.columns[column]?.hideLabel">
                {{ config?.columns[column]?.translationKey | translate: config?.columns[column]?.translationExtra }}
              </ng-container>
              <ng-template
                [ngTemplateOutlet]="tooltip"
                [ngTemplateOutletContext]="{ tooltip: config.columns[column]?.tooltip, icon: Icons.HelpIcon }"
              ></ng-template>
            </th>
          </ng-template>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="isTableDataAvailable() && data.length > 0; else noDataState">
        <tr
          *ngFor="let row of data; let index = index; trackBy: trackByFn"
          [ngStyle]="row.styles"
          (click)="rowSelected(row, index)"
          [class.selected]="isRowSelected(index)"
          [class.highlighted]="row.isHighlighted"
          [class.disabled]="row.isDisabled"
          [class.inactive]="row.isInactive"
          
        >
          <td *ngIf="config?.singleSelect?.selectionRadioButtons">
            <input
              type="radio"
              [value]="index"
              [(ngModel)]="rowSelection.index"
              (ngModelChange)="rowSelected(row, index)"
              [disabled]="row.isDisabled"
              [attr.aria-labelledby]="'radio_label_' + index"
            />
          </td>
          <ng-container *ngFor="let column of columns">
            <td
              *ngIf="row.data[column]"
              [colSpan]="row.data[column]?.config?.colSpan"
              [ngStyle]="getCellStyles(row, column)"
              [attr.data-type]="row.data[column]?.type?.cellDataType"
            >
              <dcl-wrapper [type]="row.data[column]?.type" [config]="row.data[column]?.config"></dcl-wrapper>
            </td>
          </ng-container>
        </tr>
      </ng-container>
      <ng-template #noDataState>
        <ng-container *ngIf="isDataLoading; else emptyState">
          <tr class="non-hoverable" *ngIf="config.emptyState">
            <td [colSpan]="columns.length">
              <div class="row w-100">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                  <h3 class="mt-4 mb-2">{{ 'loading_spinner_text' | translate }}</h3>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template #emptyState>
        <tr class="non-hoverable" *ngIf="config.emptyState">
          <td [colSpan]="columns.length">
            <table-empty-state [config]="config.emptyState"></table-empty-state>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
