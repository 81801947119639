<h1>{{ 'table_of_contents' | translate }}</h1>

<ng-template #recursiveList let-tocRoot>
  <li *ngFor="let child of tocRoot">
    <a href="{{ baseUrl }}#{{ child.anchor }}">{{ child.name }}</a>

    <ul *ngIf="child.children.length > 0">
      <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: child.children }"></ng-container>
    </ul>
  </li>
</ng-template>

<ng-container *ngIf="tableOfContents !== null">
  <ul>
    <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: tableOfContents.children }"></ng-container>
  </ul>
</ng-container>

<div id="content">
  <h1>Header 1</h1>

  <p>
    Lorem ipsum dolor sit amet, aeterno deseruisse inciderint eam ne. No mei sumo nullam, mea et simul nobis. Eum essent oporteat
    cu, posse definitiones mel ei, unum fugit ius ex. Eos et idque causae.
  </p>

  <h2>Header 1a</h2>

  <p>
    Lorem ipsum dolor sit amet, civibus detracto suavitate ne qui, pro veri regione voluptatibus te. Sit autem vivendum ea,
    accusam detracto platonem sea at. Aperiri singulis cu eum, meliore adversarium te est. Eum id fastidii voluptua torquatos, te
    est affert mediocrem. Copiosae complectitur sea te.
  </p>
  <p>
    Ne nam tantas possim laoreet. Vidit dicat fastidii ex sed, eu option ponderum duo, in cum noster volutpat. Choro iriure
    aliquando in ius, in alia instructior quo. Eos erat dolor no. Eam iusto molestie id, omnes aliquip eruditi per ex.
  </p>

  <h2>Header 1b</h2>

  <p>
    Lorem ipsum dolor sit amet, quot option oportere ne qui, quaeque scripserit qui cu. Vidisse labores vix in. Usu ei harum
    offendit, ex debet legimus eloquentiam sit. Eum tale integre lobortis ei, iriure mentitum quo ea. Duo equidem theophrastus et.
  </p>

  <h3>Header 1b1</h3>

  <p>
    Lorem ipsum dolor sit amet, ad altera iriure disputationi duo. Cu autem justo dicant cum. An vis hinc habemus, detraxit
    deterruisset eu quo, nonumy repudiare voluptatibus qui ut. Omnis dolore mediocritatem ne per, vivendo mediocrem cu eum. Quo id
    ferri appareat.
  </p>

  <h3>Header 1b2</h3>

  <p>
    Lorem ipsum dolor sit amet, ad altera iriure disputationi duo. Cu autem justo dicant cum. An vis hinc habemus, detraxit
    deterruisset eu quo, nonumy repudiare voluptatibus qui ut. Omnis dolore mediocritatem ne per, vivendo mediocrem cu eum. Quo id
    ferri appareat.
  </p>

  <h1>Header 2</h1>

  <p>
    Lorem ipsum dolor sit amet, mel ex novum labitur utroque. Pri vitae epicuri volutpat ad, at duo atqui everti sensibus, ne sea
    euismod forensibus ullamcorper. Sit no summo prompta quaeque, facilisi rationibus signiferumque vis id. Ne soluta virtute per,
    sanctus fabellas vis ne, sit ad sale nonumy. Eam no primis deserunt. Et noluisse percipit eos, te nam alia possit abhorreant.
  </p>
  <p>
    Erat aliquip rationibus id cum, an sed tempor utroque adipisci. Everti insolens torquatos ius ad, ex laudem feugait
    suscipiantur pro. Nec erat dicam suavitate id, sonet partiendo ea nec. Nusquam facilisi pri in. Causae luptatum adversarium
    ius ad, mea te delenit erroribus explicari, viris noster possit ius te.
  </p>

  <h1>Header 3</h1>

  <p>
    Lorem ipsum dolor sit amet, affert detraxit te has, per commune molestiae maiestatis an. At his quodsi menandri, nam tollit
    vidisse deseruisse no. Omnium sanctus molestie an qui, ex primis timeam mei, ut elit euismod forensibus sit. Est mandamus
    interpretaris no, id autem simul aliquando his.
  </p>
  <p>
    Quo ei expetenda abhorreant. Quo ei populo ancillae, mei id suas reque vitae. Vix lorem delenit et. An qui eripuit delenit,
    vim iriure equidem repudiandae et. No sumo offendit constituto vel, odio molestie petentium te cum. Sea et prima partiendo.
  </p>
  <p>
    Putent meliore explicari eam eu, et cum tibique accusata repudiandae. Eu eos simul meliore mentitum, mel ne vidit platonem. No
    labore petentium torquatos duo, ad veniam aperiri mediocritatem eum, vix bonorum invidunt no. An has soluta labore. At nec
    decore nostro tractatos.
  </p>

  <h1>Header 4</h1>

  <h2>Header 4a</h2>

  <p>
    Lorem ipsum dolor sit amet, vix in posse minim. Te vel tale appareat, te quem vero sit, quo labitur docendi imperdiet eu. Nam
    nostrud disputando conclusionemque ut. Contentiones conclusionemque ei sed, labore voluptatum eam ei. In vis perpetua
    gloriatur, adhuc deleniti placerat ad mei. Aeterno alienum ea pri, prima salutatus ad sit.
  </p>
  <p>
    Eu ferri reque recusabo vix. Nobis legere ignota sed in. Per in quas sapientem, vix in nonumy utroque propriae. In odio nisl
    nam, fastidii voluptua disputando vim et. Te mel vivendo oportere expetenda. Ad sit paulo saperet, eam prompta dolorem
    convenire ne.
  </p>
  <p>
    Et his oblique epicurei intellegebat, quis molestiae id eos. Ludus utinam denique sed ex, et eum molestie eleifend. Stet
    theophrastus pro no, ad veniam audire omittantur mei. Ei populo impedit vis, suas adversarium sed ne, qui amet solet similique
    ea.
  </p>

  <h3>Header 4a1</h3>

  <p>
    Lorem ipsum dolor sit amet, eam ei unum accusam tractatos. Nam alii tale ad, duo oporteat pertinax ne. Nibh vivendum ius ei,
    inani graecis pri eu, vix facilis temporibus consequuntur ea. Hinc consetetur adipiscing pri ei, et vis omnis tamquam. Nam ut
    hinc graecis, copiosae mandamus eloquentiam ut has.
  </p>
  <p>
    Delectus intellegat an mel, ei mea mutat movet tibique, sea persius detracto in. Ut posse vivendo indoctum vim, est ad
    placerat referrentur. Rebum lucilius his ei, omnium appetere vulputate vis et. Ut eos debet elitr dolorem, an modo eligendi
    lucilius sea. Facilis salutandi pri et.
  </p>
  <p>
    Illum laudem discere vix eu, mei no purto eius laoreet. Scaevola luptatum adipisci per ei, scaevola singulis reprimique no
    nec. Putent perfecto phaedrum cu mei. Facilis omittam eos ad.
  </p>
  <p>
    In qui enim integre deleniti, eam id simul elitr. Mei ea mucius ridens definitionem, id verterem salutatus est, iusto tritani
    facilisis cu pro. Ut nobis consequat mel, has praesent laboramus temporibus eu. Admodum consetetur sed ex. Diceret percipit ad
    duo, facer iisque quaeque mei in, ut eam iuvaret fierent nominati.
  </p>
  <p>
    Erant soleat expetenda quo ei. Fabulas molestie definitionem ea eum, vix cu audiam habemus, nam quodsi feugiat id. Quot
    salutandi te eos, verear utamur sapientem no qui. Aperiri dignissim instructior no has, per ut autem sonet legendos.
  </p>
  <p>
    Id velit definitiones duo, mea omittam appetere ne. Vidit iuvaret at quo, dicant blandit verterem ex vim, autem consetetur quo
    an. Et vidisse dolorum maluisset usu, modus nusquam omittantur in est. Vel in tempor consequat, est te vide fugit. Nec in stet
    esse assueverit, eu pri primis fastidii, eam bonorum comprehensam eu.
  </p>
  <p>
    Accusam facilisis et cum. Dolore omnium persecuti sea te, eu mei recteque consequat. Ad has summo maluisset consectetuer. Ut
    quas possim saperet per. Audiam blandit appetere eum eu.
  </p>
  <p>
    Te corpora petentium mei. Eum ad putent regione discere. Pro ad tacimates convenire. Utamur maiorum salutandi sea ut, ne adhuc
    alterum docendi est, est id praesent facilisis. In vim quot deleniti neglegentur, duo ei quodsi apeirian. Vel eu aeque verear
    definiebas.
  </p>

  <h1>Header 5</h1>

  <p>
    Lorem ipsum dolor sit amet, nobis essent volumus eam ut, cu erat solum nominati quo. Mel justo apeirian ut, in porro
    adversarium his. Qui cetero iudicabit ne, quo no alterum inciderint. Quas verear virtute ei pri, expetendis concludaturque no
    eum, exerci commune convenire no ius.
  </p>
  <p>
    Populo aliquid facilis per ad, his forensibus sententiae te. Per option molestie reformidans ut, aeterno vocibus eu per. Ei
    nobis insolens mea. In cum dolore feugiat nusquam, te solum voluptua nominati vix. Quot latine eripuit his ne, iudico omittam
    at nam.
  </p>
  <p>
    Vix fabulas temporibus cu, aliquid facilis patrioque qui et, cum at clita quodsi. Ne mea altera regione. Sed ad verear
    ceteros, an usu bonorum epicurei vituperata. Ut munere meliore perpetua eos, his ne accusam praesent, ceteros commune
    partiendo in vel. Veritus vivendum ea sit, ea adhuc feugiat gubergren vix, lobortis platonem sapientem has in.
  </p>
</div>
