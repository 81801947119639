import { Component, OnInit, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { CommonUtility } from '../../../utility/common.utility';
import { StatusService } from '../../../services/status.service';
import { ZendeskUtility } from '../../../utility/zendesk.utility';
import { TranslateService } from '../../../translate/translate.service';
import { BrowserService } from '../../../services/browser.service';
import { FeaturesService } from '../../../services/features.service';
import { ZendeskStatus } from '../../../../../shared/constants/zendesk-status';
import { AppConfigService } from '../../../services/app-config.service';

@Component({
  selector: 'app-zendesk',
  templateUrl: './zendesk.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZendeskComponent implements OnInit {
  private readonly ZendeskWidgetUrl: string;
  private readonly ZendeskWidgetScriptId: string;
  private iframeSelector: string;
  private isApplicationReady$: Subscription;

  constructor(
    private browserService: BrowserService,
    private statusService: StatusService,
    private translateService: TranslateService,
    private featuresService: FeaturesService,
    private appConfigService: AppConfigService,
    private ngZone: NgZone,
  ) {
    this.ZendeskWidgetScriptId = 'ze-snippet';
    this.ZendeskWidgetUrl = this.appConfigService.getZendeskWidgetUrl();
    this.iframeSelector = 'iframe#launcher';
  }

  private isZendeskConfigured(): boolean {
    return typeof this.ZendeskWidgetUrl === 'string' && this.ZendeskWidgetUrl.length > 0;
  }

  private onFindingZendeskElementSuccess(): void {
    let intervalId: number;
    let counter: number = 0;

    const checkForButtonPresence = (): void => {
      const button: HTMLButtonElement = ZendeskUtility.getZendeskButton();

      counter += 1;

      if (button || counter === 100) {
        window.clearInterval(intervalId);
      }

      if (button === null) {
        return;
      }

      const textElement: HTMLSpanElement = button.querySelector('span:not(.Icon)');

      if (textElement === null) {
        console.error('[ZendeskComponent] Cannot rename Zendesk button. Maybe selector was changed?');
        return;
      }

      textElement.textContent = this.translateService.instant('label_zendesk_button');
    };

    this.ngZone.runOutsideAngular((): void => {
      intervalId = window.setInterval(checkForButtonPresence, 250);
      this.isApplicationReady$.unsubscribe();
      this.statusService.setZendeskStatus(ZendeskStatus.available);
    });
  }

  private onFindingZendeskElementError(error: any): void {
    console.error('[ZendeskComponent.onFindingZendeskElementError]', error);
    this.statusService.setZendeskStatus(ZendeskStatus.not_available);
  }

  private onZendeskLoadedSuccess(): void {
    console.debug('[ZendeskComponent.onZendeskLoadedSuccess]');

    this.browserService
      .waitForElement(this.iframeSelector)
      .then(this.onFindingZendeskElementSuccess.bind(this))
      .catch(this.onFindingZendeskElementError.bind(this));
  }

  private onZendeskLoadedError(error: any): void {
    console.error('[ZendeskComponent.onZendeskLoadedError]', error);
    this.statusService.setZendeskStatus(ZendeskStatus.not_available);
  }

  private onApplicationReady(isApplicationReady: boolean): void {
    if (isApplicationReady === false) {
      return;
    }

    const featuresConfig: any = this.featuresService.getFeaturesConfig();

    if (featuresConfig === null || featuresConfig['zendeskApplication'] === false) {
      return;
    }

    window.setTimeout((): void => {
      CommonUtility.requestIdleCallback((): void => {
        CommonUtility.getScript(
          this.ZendeskWidgetUrl,
          this.onZendeskLoadedSuccess.bind(this),
          this.onZendeskLoadedError.bind(this),
          this.ZendeskWidgetScriptId,
        );
      });
    }, 4000);
  }

  public ngOnInit(): void {
    if (this.isZendeskConfigured() === false) {
      return;
    }

    this.isApplicationReady$ = this.statusService.$isApplicationReady.pipe(first()).subscribe({
      next: this.onApplicationReady.bind(this),
    });
  }
}
