<app-modal-container [displayFooter]="true" xmlns="http://www.w3.org/1999/html">
  <span class="modal-header-content">
    {{ 'share_report_modal_title' | translate }}
  </span>
  <div class="modal-body-content">
    <div class="margin-bottom">
      <div class="margin-bottom-xs d-flex align-items-center justify-content-between">
        <span class="label">{{ 'audit_rule_property_nodesHTML' | translate }}:</span>
        <button
          type="button"
          class="btn btn-link p-0 fw-bold copy-to-clipboard"
          (click)="copyToClipboard(modalInputData.data.url)"
          appTooltip="{{ 'copy_to_clipboard' | translate }}"
        >
          <span class="me-1">{{ 'copy' | translate }}</span>
          <svg viewBox="0 0 512 512" aria-hidden="true" focusable="false">
            <use xlink:href="#copy-to-clipboard"></use>
          </svg>
        </button>
      </div>
      <div class="d-block p-2 issue-url">
        <pre>{{ modalInputData.data.url }}</pre>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between modal-footer-content">
    <button class="btn btn-outline-primary margin-left-md" (click)="close()">
      {{ 'label_close' | translate }}
    </button>
  </div>
</app-modal-container>
