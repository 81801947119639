<div class="progress-doughnut">
  <div class="progress-doughnut-value-label">
    {{ formattedValue }}
    <app-icon
      *ngIf="isWarningOn"
      appTooltip="{{ 'policy_violated' | translate }}"
      [attr.aria-label]="'policy_violated' | translate"
      [icon]="warningIcon"
      [style.color]="warningColor"
    ></app-icon>
  </div>
  <div #progressChart aria-hidden="true" class="progress-doughnut-chart"></div>
</div>
