import { Component, Input } from '@angular/core';
import { AuditToolNames } from '../../../../../../../shared/constants/audit-tool';
import { flawStatus } from '../../../../../../../shared/constants/flaw';
import { RuleAuditHistoryType } from '../../../../../../../shared/constants/rule-audit-history';
import { IFlaw } from '../../../../../../../shared/interfaces/flaw.interface';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IIssueStateLabelCellConfig extends ICellConfig {
  status: flawStatus;
  hasHistory: boolean;
  flaw?: IFlaw;
  url: string;
  selector: string;
  scanId: string;
  ruleId: string;
  toolName: AuditToolNames;
  tabIndex?: number;
}

@Component({
  selector: 'table-cell-issue-state',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  styles: [
    `
      div {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }

      button {
        padding: 3px 4px;
        border: 1px solid #757575;
        border-radius: 4px;
      }
    `,
  ],
  template: `
    <div>
      <ng-container *ngIf="config.status === flawStatus.dismissed; else notDismissed">
        <span class="dismissed-cell" tabindex="{{ config.tabIndex || 0 }}" #ref>{{ 'label_dismissed' | translate }}</span>
      </ng-container>
      <ng-template #notDismissed>
        <span tabindex="{{ config.tabIndex || 0 }}" #ref>{{ 'label_' + config.status | translate }}</span>
      </ng-template>
      <app-view-flaw-status-history *ngIf="config.hasHistory" [issueSource]="config.flaw._id"></app-view-flaw-status-history>
    </div>
  `,
})
export class IssueStateLabelCellComponent extends BaseCellComponent {
  @Input() public config: IIssueStateLabelCellConfig;
  public type: RuleAuditHistoryType = RuleAuditHistoryType.issue;
  public flawStatus: typeof flawStatus = flawStatus;
}
