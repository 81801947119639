import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { Icons } from '@levelaccess/design-system';
import { EapColors } from '../../../../../shared/eap-colors';
import { ITrendConfig } from '../../../../common-trend/common-trend.component';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IScoreCellConfig extends ICellConfig {
  score: number;
  isIncreaseImprovement?: boolean;
  previousValue?: number;
  showNoChange?: boolean;
}

@Component({
  selector: 'table-cell-score',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <span class="visuallyhidden">{{ 'n_of_t' | translate: [config.score, 100] }}</span>
    <span class="visible" aria-hidden="true">
      <span [class]="'badge ' + scoreClass" #ref aria-hidden="true">{{ config.score }}</span
      >&nbsp;/&nbsp;100</span
    >
    <ng-container *ngIf="hasPreviousData">
      <app-common-trend class="ms-1" [config]="trendConfig"></app-common-trend>
    </ng-container>
  `,
})
export class ScoreCellComponent extends BaseCellComponent implements OnInit {
  public Icons: typeof Icons;
  public EapColors: typeof EapColors;

  public trendConfig: ITrendConfig;

  @Input() public config: IScoreCellConfig;

  constructor(protected renderer: Renderer2) {
    super(renderer);
    this.Icons = Icons;
    this.EapColors = EapColors;
  }

  public get scoreClass(): string {
    if (typeof this.config?.score !== 'undefined') {
      if (this.config.score >= 0 && this.config.score <= 49) {
        return 'score-low';
      } else if (this.config.score >= 50 && this.config.score <= 79) {
        return 'score-mid';
      }
      return 'score-high';
    }

    return null;
  }

  public get hasPreviousData(): boolean {
    return typeof this.config.previousValue === 'number';
  }

  public ngOnInit(): void {
    if (this.hasPreviousData) {
      this.buildTrendConfig();
    }
  }

  private buildTrendConfig(): void {
    this.trendConfig = {
      currentValue: this.config.score,
      previousValue: this.config.previousValue,
      isIncreaseImprovement: true,
      noChangePrefix: '-',
    };
  }
}
