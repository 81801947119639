import { $auditRule } from './audit-rule';

enum $customAuditRuleAddition {
  tenantId = 'tenantId',
}

export const $customAuditRule: typeof $auditRule & typeof $customAuditRuleAddition = {
  ...$auditRule,
  ...$customAuditRuleAddition,
};

export enum $customAuditRuleSearch {
  ruleIdOrKeyword = 'ruleIdOrKeyword',
  ruleIdSubstring = 'ruleIdSubstring',
  category = 'category',
}

export enum $customAuditRulesSortKeys {
  ruleId = 'ruleId',
  description = 'description',
  severity = 'severity',
}
