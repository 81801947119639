import { ITaskCounts } from '../interfaces/task.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { ITaskWithTargetStates, ITaskWithTargetStatesAndFindings } from '../../../shared/interfaces/task.interface';
import { $task, TaskStatus } from '../../../shared/constants/task';

export class TaskUtility {
  public static getTotalTaskCounts(taskCounts: ITaskCounts): number {
    if (SharedCommonUtility.isNullish(taskCounts)) {
      return 0;
    }
    return taskCounts.doneCount + taskCounts.notStartedCount + taskCounts.inProgressCount;
  }

  /**
   * Return the total for non-started, done, and in-progress tasks, based on the task status
   * of each element in the given list. Return totals of 0 if input is null or undefined.
   *
   * @param taskWithTargetStatesAndFindings list of ITaskWithTargetStatesAndFindings
   * @returns ITaskCounts
   */
  public static reduceTargetStatesAndFindingsToTaskCounts(
    taskWithTargetStatesAndFindings: ITaskWithTargetStatesAndFindings[],
  ): ITaskCounts {
    const seed: ITaskCounts = { notStartedCount: 0, inProgressCount: 0, doneCount: 0 };
    if (SharedCommonUtility.isNullish(taskWithTargetStatesAndFindings)) {
      return seed;
    }

    const notStartedStates: Array<TaskStatus> = [TaskStatus.toDo, TaskStatus.backlog];
    const doneStates: Array<TaskStatus> = [TaskStatus.done];
    const inProgressStates: Array<TaskStatus> = Object.values(TaskStatus).filter(
      (status: TaskStatus): boolean => !(notStartedStates.includes(status) || doneStates.includes(status)),
    );

    const reducer = (red: ITaskCounts, task: ITaskWithTargetStates): ITaskCounts => ({
      notStartedCount: red.notStartedCount + (notStartedStates.includes(task[$task.status]) ? 1 : 0),
      inProgressCount: red.inProgressCount + (inProgressStates.includes(task[$task.status]) ? 1 : 0),
      doneCount: red.doneCount + (doneStates.includes(task[$task.status]) ? 1 : 0),
    });

    return taskWithTargetStatesAndFindings.reduce(reducer, seed);
  }
}
