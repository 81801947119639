import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DsButtonVariants } from '@levelaccess/design-system';
import { IPopulatedIssueIntegrationConnection } from '../../../../../../shared/interfaces/issue-integration-connection.interface';

@Component({
  templateUrl: './connection-error-modal.component.html',
  styleUrls: ['./connection-error-modal.component.scss'],
})
export class ConnectionErrorModalComponent {
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;

  @Input()
  public connection: IPopulatedIssueIntegrationConnection;

  @Output()
  public confirmed: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected modal: NgbActiveModal) {}

  protected cancelClicked(): void {
    this.modal.dismiss();
  }

  protected reauthorizeClicked(): void {
    this.confirmed.emit();
    this.modal.close();
  }
}
