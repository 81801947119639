<div class="domain-restrictions">
  <div *ngIf="hasDomainRestrictions">
    <div class="title" *ngIf="showTitle">
      {{ 'scan_scope_subscription_info_title' | translate }}
    </div>
    <div class="domains-list" aria-live="polite">
      <button
        type="button"
        class="btn btn-link btn-flat p-0"
        [ngClass]="{ 'fw-bold': boldButton }"
        #viewDomains
        (click)="viewDomains.value = viewDomains.value === 'true' ? 'false' : 'true'"
        [ngSwitch]="viewDomains.value"
        [attr.aria-label]="(viewDomains.value === 'true' ? 'label_hide_domains' : 'label_view_available_domains') | translate"
        [attr.aria-expanded]="viewDomains.value === 'true' ? 'true' : 'false'"
      >
        <ng-container *ngSwitchCase="'true'">
          <span>{{ 'label_hide_domains' | translate }}</span>
          <svg><use xlink:href="#chevron-top"></use></svg>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span>{{ 'label_view_available_domains' | translate }}</span>
          <svg><use xlink:href="#chevron-bottom"></use></svg>
        </ng-container>
      </button>
      <ng-container *ngIf="viewDomains.value === 'true'">
        <div>
          <ul class="list-unstyled">
            <li *ngFor="let domain of authorizedDomains">
              {{ domain }}
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
