import { Component, Input } from '@angular/core';
import { Icons, IconStyles, DsColors } from '@levelaccess/design-system';

import { BaseCellComponent, ICellConfig, ITagConfig } from '../base-cell/base-cell.component';

export interface IExternalLinkCellConfig extends ICellConfig, ITagConfig {
  showExternalLinkIcon?: boolean;
}

@Component({
  selector: 'table-cell-external-link',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <a class="text-underline" #ref [href]="config.text" [ngStyle]="config.styles" target="_blank" rel="noreferrer noopener">
      <span>{{ config.text }}</span>
      <ds-icon
        *ngIf="config.showExternalLinkIcon"
        class="margin-left-sm"
        [icon]="Icons.ArrowUpRightFromSquare"
        [iconStyle]="IconStyles.Regular"
        [style.color]="DsColors.Inactive"
      ></ds-icon>
    </a>
  `,
})
export class ExternalLinkCellComponent extends BaseCellComponent {
  protected readonly Icons: typeof Icons = Icons;
  protected readonly IconStyles: typeof IconStyles = IconStyles;
  protected readonly DsColors: typeof DsColors = DsColors;

  @Input() public config: IExternalLinkCellConfig;
}
