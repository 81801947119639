<div class="common-input" [ngClass]="view" [class.full-width-input]="fullWidthInput" [formGroup]="form">
  <div
    *ngIf="!isEdit"
    class="text"
    [class]="context.class"
    (click)="toggleEditInput(true)"
    [appTooltip]="context.tooltip ? (context.tooltip | translate) : ''"
    [attr.aria-label]="context.tooltip ? (context.tooltip | translate) : ''"
    placement="bottom"
    [attr.tabindex]="!context.omitTabindex ? 0 : null"
    (keydown.enter)="toggleEditInput(true)"
  >
    {{ form.get(context.field).value }}
  </div>
  <input
    #search
    [type]="isEdit ? 'type' : 'hidden'"
    id="{{ context.field }}"
    class="form-element"
    [required]="required"
    [attr.autocomplete]="context.autocomplete"
    [class.w-100]="fullWidthInput"
    [placeholder]="context.placeholder ? (context.placeholder | translate) : ''"
    [name]="context.field"
    [formControlName]="context.field"
    [attr.aria-describedby]="context.field + '_error'"
    [attr.aria-invalid]="form | fieldInvalid: context.field"
    [attr.disabled]="context.disabled ? true : null"
    [readOnly]="readonly"
    (focusout)="onFocusOut()"
    (keydown.enter)="toggleEditInput(false)"
  />
  <div [id]="context.field + '-error'" class="error-container">
    <app-form-field-error
      [formValidationRequest$]="formValidationRequest$"
      [field]="form.get(context.field)"
      [name]="context.field"
      [label]="context.label"
      [customMessage]="context.customMessage"
      [overrideErrors]="context.overrideErrors"
    ></app-form-field-error>
  </div>
</div>
<ng-container *ngIf="isEdit && context.saveButton">
  <button type="button" class="btn btn-primary margin-left save-button" (click)="toggleEditInput(false)">
    {{ 'label_save' | translate }}
  </button>
</ng-container>
