import { Input, Component, ChangeDetectionStrategy, forwardRef, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { IDatetimeLocalParameters } from './interfaces/params.interface';
import { $dateParts } from '../../../../../shared/constants/date';
import { $formFields } from './constants/form-fields';
import { CommonUtility } from '../../../utility/common.utility';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { DateFallbackFormUtils } from '../utils/form';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { SharedDateUtility } from '../../../../../shared/utils/date.utility';

@Component({
  selector: 'app-datetime-local',
  templateUrl: './datetime-local.component.html',
  styleUrls: ['./datetime-local.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatetimeLocalComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatetimeLocalComponent), multi: true },
  ],
})
export class DatetimeLocalComponent implements OnDestroy, ControlValueAccessor, Validator {
  private resultDate: Date;
  private subscription: Subscription;

  @Input() public datetimeLocalParameters: IDatetimeLocalParameters | undefined;
  @Input() public required: boolean = false;
  @Input() public errorFieldId: string;
  public $dateParts: typeof $dateParts;

  public $formFields: typeof $formFields;
  public form: UntypedFormGroup;
  public formId: string;
  public isNativeLocalDatetimeSupported: boolean;
  public availableYears: number[];
  public availableDays: number[];
  public availableHours: string[];
  public availableMinutes: string[];

  constructor(private formBuilder: UntypedFormBuilder) {
    if (typeof this.datetimeLocalParameters !== 'object') {
      this.datetimeLocalParameters = {
        max: null,
        min: null,
      };
    }
    if (typeof this.datetimeLocalParameters.nativeInputId !== 'string') {
      this.datetimeLocalParameters.nativeInputId = CommonUtility.createUniqueDOMId();
    }
    this.subscription = Subscription.EMPTY;
    this.$dateParts = $dateParts;
    this.$formFields = $formFields;

    this.form = null;
    this.formId = CommonUtility.createUniqueDOMId();
    this.isNativeLocalDatetimeSupported = CommonUtility.isInputTypeSupported('datetime-local');
    this.availableYears = [];
    this.availableDays = [];
    this.availableHours = [];
    this.availableMinutes = [];

    this.setupForm();
  }

  private onChange: Function = () => null;
  private onTouched: Function = () => null;

  private setUpFallbackForm(): void {
    this.form = this.formBuilder.group({
      [$dateParts.year]: new UntypedFormControl(null, {}),
      [$dateParts.month]: new UntypedFormControl(null, {}),
      [$dateParts.day]: new UntypedFormControl(null, {}),
      [$dateParts.hour]: new UntypedFormControl(null, {}),
      [$dateParts.minute]: new UntypedFormControl(null, {}),
    });
    this.populateFormValues();
    this.syncFormDate(this.resultDate);
  }

  private populateFormValues(): void {
    this.populateYears();
    this.populateHours();
    this.populateMinutes();
    this.form.get($dateParts.month).setValue(0);
    if (SharedCommonUtility.isDateValid(this.resultDate)) {
      this.populateDays(this.resultDate.getMonth());
    }
    this.form.get($dateParts.day).setValue(1);
  }

  private populateYears(): void {
    const formField: AbstractControl = this.form.get($dateParts.year);
    this.availableYears = DateFallbackFormUtils.populateYears(formField);
  }

  private populateDays(month: number): void {
    const formField: AbstractControl = this.form.get($dateParts.day);
    const days: number[] = DateFallbackFormUtils.populateDays(formField, month, this.resultDate);

    this.availableDays = days;
    if (days.length < this.resultDate.getDate()) {
      this.resultDate.setDate(days.length);
      this.form.get($dateParts.day).setValue(days.length);
    }
  }

  private populateHours(): void {
    const hours: string[] = [];
    for (let i: number = 0; i <= 23; i += 1) {
      hours.push(SharedCommonUtility.withLeadingZeros(i, 2));
    }
    this.availableHours = hours;
    this.form.get($dateParts.hour).setValue(this.availableHours[0]);
  }

  private populateMinutes(): void {
    const minutes: string[] = [];
    for (let i: number = 0; i <= 59; i += 1) {
      minutes.push(SharedCommonUtility.withLeadingZeros(i, 2));
    }
    this.availableMinutes = minutes;
    this.form.get($dateParts.minute).setValue(this.availableMinutes[0]);
  }

  private setResultDate(receivedDate: Date): void {
    if (this.isNativeLocalDatetimeSupported) {
      this.setNativeValue(receivedDate);
    } else {
      this.setFallbackResultDate(receivedDate);
    }
    this.emitChange(this.resultDate);
  }

  private setFallbackResultDate(receivedDate: Date): void {
    if (SharedCommonUtility.isDateValid(receivedDate)) {
      this.resultDate = DateFallbackFormUtils.setResultDate(this.datetimeLocalParameters, receivedDate);
      this.populateDays(this.resultDate.getMonth());
    } else {
      this.resultDate = null;
    }
    this.syncFormDate(this.resultDate);
  }

  private emitChange(date: Date): void {
    this.onChange(date);
    this.onTouched();
  }

  private setNativeValue(receivedDate: Date): void {
    const nativeInput: AbstractControl = this.form.get($formFields.nativeDatetimeLocal);

    this.resultDate = SharedCommonUtility.isDateValid(receivedDate)
      ? DateFallbackFormUtils.setResultDate(this.datetimeLocalParameters, receivedDate)
      : null;

    if (this.resultDate === null) {
      nativeInput.setValue('', { emitEvent: false });
    } else {
      const newValue: string = SharedDateUtility.dateToDatetimeLocalFormat(this.resultDate);
      nativeInput.setValue(newValue, { emitEvent: false });
    }
  }

  private setupNativeDatetime(): void {
    this.form = this.formBuilder.group({
      [$formFields.nativeDatetimeLocal]: new UntypedFormControl(null, {}),
    });
    this.subscription = this.form.get($formFields.nativeDatetimeLocal).valueChanges.subscribe((value: string) => {
      this.nativeDateChanged(value);
    });
  }

  private isFallbackFormFilledPartially(): boolean {
    const formValues: (string | null)[] = Object.values(this.form.getRawValue());

    const nullOnly = (item: string | null): boolean => {
      return item === null;
    };

    return formValues.some(nullOnly);
  }

  private getDateFromFallbackForm(): Date | null {
    if (this.isFallbackFormFilledPartially()) {
      return null;
    }
    const value: any = this.form.getRawValue();
    Object.entries(value).forEach(([key, val]: [string, string]) => (value[key] = Number(val)));

    const date: Date = new Date(
      value[$dateParts.year],
      value[$dateParts.month],
      value[$dateParts.day],
      value[$dateParts.hour],
      value[$dateParts.minute],
    );
    return SharedCommonUtility.isDateValid(date) ? date : null;
  }

  private setupForm(): void {
    this.subscription.unsubscribe();
    if (this.isNativeLocalDatetimeSupported) {
      this.setupNativeDatetime();
    } else {
      this.setUpFallbackForm();
    }
  }

  public syncFormDate(date: Date): void {
    let value: any = {
      [$dateParts.year]: null,
      [$dateParts.month]: null,
      [$dateParts.day]: null,
      [$dateParts.hour]: null,
      [$dateParts.minute]: null,
    };
    if (SharedCommonUtility.isDateValid(date)) {
      value = {
        [$dateParts.year]: date.getFullYear(),
        [$dateParts.month]: date.getMonth(),
        [$dateParts.day]: date.getDate(),
        [$dateParts.hour]: SharedCommonUtility.withLeadingZeros(date.getHours(), 2),
        [$dateParts.minute]: SharedCommonUtility.withLeadingZeros(date.getMinutes(), 2),
      };
    }
    this.form.setValue(value, { emitEvent: false });
  }

  public nativeDateChanged(date: string): void {
    const newDate: Date = typeof date === 'string' && date.trim().length > 0 ? new Date(date) : null;
    this.setResultDate(new Date(newDate));
  }

  public fallbackDateChanged(): void {
    const formDate: Date = this.getDateFromFallbackForm();
    this.setResultDate(formDate);
  }

  public nativeMinValue(): string {
    return SharedCommonUtility.getTypeOf(this.datetimeLocalParameters.min) === 'date'
      ? SharedDateUtility.dateToDatetimeLocalFormat(this.datetimeLocalParameters.min)
      : '';
  }

  public nativeMaxValue(): string {
    return SharedCommonUtility.getTypeOf(this.datetimeLocalParameters.max) === 'date'
      ? SharedDateUtility.dateToDatetimeLocalFormat(this.datetimeLocalParameters.max)
      : '';
  }

  public writeValue(obj: any): void {
    this.setResultDate(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return Validators.compose([
      CustomValidators.isValidDate,
      CustomValidators.minDate(this.datetimeLocalParameters.min),
      CustomValidators.maxDate(this.datetimeLocalParameters.max),
    ])(control);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
