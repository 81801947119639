<div class="full-width card">
  <div class="card-header">
    <ng-container *ngIf="isCustomRuleCreate; else pageHeaderWrapper">
      <div class="row mb-3">
        <div class="col-12">
          <div class="d-flex">
            <ds-link [rtLink]="backLink" [standalone]="true">
              {{ 'rule_libraries' | translate }}
            </ds-link>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-between">
        <ng-container *ngTemplateOutlet="pageHeader"></ng-container>
      </div>
    </ng-container>
    <ng-template #pageHeaderWrapper>
      <div class="row">
        <ng-container *ngTemplateOutlet="pageHeader"></ng-container>
      </div>
    </ng-template>
    <ng-template #pageHeader>
      <div class="col-12">
        <h1 class="d-inline-block">{{ headingTitleKey | translate }}</h1>
      </div>
    </ng-template>
  </div>
  <div class="card-body">
    <ng-container *ngIf="isCustomRuleCreate; else pageForm">
      <div class="row mt-4">
        <div class="col-12">
          <ng-container *ngTemplateOutlet="pageForm"></ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #pageForm>
      <app-upsert-audit-rule-form
        [action]="this.auditRuleAction"
        [auditRuleData]="this.auditRule"
        [isCustomAuditRule]="!this.isMasterRule"
        (save)="save($event)"
      ></app-upsert-audit-rule-form>
    </ng-template>
  </div>
</div>
