import { ISuccessCriteria, ISuccessCriteriaIndex } from './success-criteria.interface';
import { $successCriteria } from './constants';
import { WcagVersion, WcagLevel } from '../../../interfaces/audit-rule.interface';

const wcagCr: ISuccessCriteria[] = [
  {
    [$successCriteria.handle]: 'Conformance Level',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: '5.2.1',
    [$successCriteria.title]: '5.2.1 Conformance Level',
    [$successCriteria.versions]: [WcagVersion.v22, WcagVersion.v21],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Full pages',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: '5.2.2',
    [$successCriteria.title]: '5.2.2 Full pages',
    [$successCriteria.versions]: [WcagVersion.v22, WcagVersion.v21],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Complete processes',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: '5.2.3',
    [$successCriteria.title]: '5.2.3 Complete processes',
    [$successCriteria.versions]: [WcagVersion.v22, WcagVersion.v21],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: '5.2.4',
    [$successCriteria.title]: '5.2.4 Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.versions]: [WcagVersion.v22, WcagVersion.v21],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Non-Interference',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: '5.2.5',
    [$successCriteria.title]: '5.2.5 Non-Interference',
    [$successCriteria.versions]: [WcagVersion.v22, WcagVersion.v21],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 1 Conformance Level',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: 'CR 1 Conformance Level',
    [$successCriteria.title]: 'Conformance Requirement 1 Conformance Level',
    [$successCriteria.versions]: [WcagVersion.v20],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 2 Full pages',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: 'CR 2 Full pages',
    [$successCriteria.title]: 'Conformance Requirement 2 Full pages',
    [$successCriteria.versions]: [WcagVersion.v20],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 3 Complete processes',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: 'CR 3 Complete processes',
    [$successCriteria.title]: 'Conformance Requirement 3 Complete processes',
    [$successCriteria.versions]: [WcagVersion.v20],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 4 Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: 'CR 4 Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.title]: 'Conformance Requirement 4 Only Accessibility-Supported Ways of Using Technologies',
    [$successCriteria.versions]: [WcagVersion.v20],
    [$successCriteria.url]: '',
  },
  {
    [$successCriteria.handle]: 'Conformance Requirement 5 Non-Interference',
    [$successCriteria.level]: WcagLevel.CR,
    [$successCriteria.num]: 'CR 5 Non-Interference',
    [$successCriteria.title]: 'Conformance Requirement 5 Non-Interference',
    [$successCriteria.versions]: [WcagVersion.v20],
    [$successCriteria.url]: '',
  },
];

export class WcagCr implements ISuccessCriteriaIndex {
  public get(num: string): ISuccessCriteria {
    const wcagSuccessCriteria: ISuccessCriteria | undefined = wcagCr.find(
      (bestPracticeSuccessCriteria: ISuccessCriteria): boolean => bestPracticeSuccessCriteria.num === num,
    );
    if (typeof wcagSuccessCriteria === 'undefined') {
      return null;
    }
    return wcagSuccessCriteria;
  }

  public getAll(): ISuccessCriteria[] {
    return wcagCr;
  }
}
