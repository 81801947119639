<div class="row common-file-dropbox-component" [formGroup]="form">
  <div class="col-8 d-flex upload-window justify-content-center">
    <div class="dropzone w-100" appFileDrop (filesDropped)="filesDropped($event)" *ngIf="fileUploaded === undefined">
      <div class="row py-1">
        <div class="col">
          <svg viewBox="0 0 8 8" class="upload-icon">
            <use xlink:href="#cloud-upload-underline"></use>
          </svg>
        </div>
      </div>
      <div class="row py-1">
        <div class="col">
          <button class="btn btn-outline-primary" type="button" (click)="nativeInput.click()">
            {{ 'label_add_file' | translate }}
          </button>
          <input
            type="file"
            name="bulkUpload"
            (change)="filesChosen($event)"
            hidden
            #nativeInput
            formControlName="bulkUpload"
            [accept]="acceptedFileTypes.join(',')"
          />
        </div>
      </div>
      <div class="row py-1">
        <div class="col">
          {{ 'label_add_or_drop_file' | translate }}
        </div>
      </div>
      <div class="row" *ngIf="fileDropError !== undefined">
        <div class="col red-fill">
          {{ fileDropError | translate }}
        </div>
      </div>
      <div class="row pt-2" *ngIf="fileValid === false">
        <div class="col-12 text-start">
          <p>
            <b>{{ 'label_correct_fields_below' | translate }}</b>
          </p>
        </div>
      </div>
      <div class="row justify-content-center" *ngIf="fileValid === false">
        <div class="col-12 justify-content-center">
          <ng-container *ngTemplateOutlet="errorTable"></ng-container>
        </div>
      </div>
    </div>

    <div class="dropzone w-100" *ngIf="fileUploaded === false">
      <div class="row p-1 justify-content-center">
        <div class="col-auto">
          <app-common-name-tag (closeButtonClicked)="cancelFile()" [fileName]="file.name" [redBorder]="false">
          </app-common-name-tag>
        </div>
        <div class="col-auto">
          <button class="btn button-link-style" type="button" (click)="cancelFile()">{{ 'form_cancel' | translate }}</button>
        </div>
      </div>
      <div class="row p-2">
        <div class="col-12">
          <button class="btn btn-primary"  type="button" (click)="validate($event)">
            {{ 'upload_action_upload' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="dropzone w-100" *ngIf="fileUploaded === true && fileValid === true && file">
      <div class="row">
        <div class="col-12">
          <p>
            <b>{{ 'label_file_successfully_uploaded' | translate }}</b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>{{ fileUploadedMessage | translate }}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <app-common-name-tag (closeButtonClicked)="cancelFile()" [fileName]="file.name" [redBorder]="false">
            <svg statusIcon viewBox="0 0 8 8" class="name-tag-icon green-fill">
              <use xlink:href="#circle-check"></use>
            </svg>
          </app-common-name-tag>
        </div>
        <div class="col-auto">
          <button class="btn button-link-style" type="button" (click)="cancelFile()">{{ 'label_replace' | translate }}</button>
        </div>
      </div>
    </div>

    <div class="dropzone w-100" *ngIf="fileUploaded === true && fileValid === false">
      <div class="row">
        <div class="col-12">
          <p>
            <b>{{ 'label_invalid_missing_information' | translate }}</b>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="red-fill">{{ fileDataError | translate }}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <app-common-name-tag (closeButtonClicked)="cancelFile()" [fileName]="file.name" [redBorder]="true">
            <svg statusIcon viewBox="0 0 8 8" class="name-tag-icon red-fill">
              <use xlink:href="#exclamation-circle"></use>
            </svg>
          </app-common-name-tag>
        </div>
        <div class="col-auto">
          <button class="btn button-link-style" type="button" (click)="cancelFile()">{{ 'label_replace' | translate }}</button>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12 text-start">
          <p>
            <b>{{ 'label_correct_fields_below' | translate }}</b>
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <ng-container *ngTemplateOutlet="errorTable"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #errorTable>
  <ng-content select="[errorTable]"> </ng-content>
</ng-template>
