import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, mergeMap, take } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { ScanTagService } from '../../services/scan-tag.service';
import { IDigitalPropertyListItem } from '../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../../shared/constants/digital-properties';
import { $workspace } from '../../../../shared/constants/workspace';
import { IScanTag, IScanTagServerResponse } from '../../../../shared/interfaces/scan-tag.interface';
import { $scanTag, SystemTags } from '../../../../shared/constants/scan-tag';
import { LoadErrorHandler } from '../../services/load-error-handler';
import { ScanTagUtility } from '../../../../shared/utils/scan-tag.utility';
import { AutomatedScanAdvancedService } from '../../pages/public/automated-scan/advanced/automated-scan-advanced.service';
import { scanningType } from '../../../../shared/constants/scanning';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Component({
  selector: 'app-deprecated-scan-tag-selector',
  templateUrl: './deprecated-scan-tag-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeprecatedScanTagSelectorComponent),
      multi: true,
    },
  ],
})
export class DeprecatedScanTagSelectorComponent implements ControlValueAccessor, OnInit {
  @Input() public disabled: boolean;
  @Input() public disabledUnselectedValue: boolean;
  @Input() public unselectedValueLabel: string;
  @Input() public required: boolean;

  public onChange: Function = () => {};
  public onTouched: Function = () => {};

  public selectedTagId: string;
  public subscriptions: Subscription;
  public $scanTag: typeof $scanTag;

  private allTags: IScanTag[] = [];
  private userTags: IScanTag[] = [];
  private enableReservedSystemTags: boolean;

  constructor(
    private userService: UserService,
    private scanTagService: ScanTagService,
    private changeDetector: ChangeDetectorRef,
    private loadErrorHandler: LoadErrorHandler,
    private automatedScanAdvancedService: AutomatedScanAdvancedService,
  ) {
    this.selectedTagId = null;
    this.subscriptions = new Subscription();
    this.$scanTag = $scanTag;
    this.unselectedValueLabel = 'label_select_a_scan_tag';
    this.disabledUnselectedValue = true;
    this.onChange = (ignore: string): void => {};
    this.onTouched = (): void => {};
    this.disabled = false;
  }

  public get availableTags(): IScanTag[] {
    return this.enableReservedSystemTags ? this.allTags : this.userTags;
  }

  private onGetScanTagsSuccess(response: IScanTagServerResponse): void {
    this.allTags = response.tags;
    this.userTags = response.tags.filter((tag: IScanTag) => !ScanTagUtility.isReservedSystemTag(tag[$scanTag.tagName]));
    this.changeDetector.detectChanges();
  }

  private onGetScanTagsError(error: HttpErrorResponse): void {
    this.loadErrorHandler.handleError('error_getting_scan_tags', error, DeprecatedScanTagSelectorComponent.name);
  }

  private onDigitalPropertyChanged(digitalProperty: IDigitalPropertyListItem): void {
    const digitalPropertyId: string = digitalProperty[$digitalProperty._id];
    const workspaceId: string = digitalProperty[$digitalProperty.workspace][$workspace._id];
    this.subscriptions.add(
      this.scanTagService.getScanTagsFromDigitalProperty(workspaceId, digitalPropertyId).subscribe({
        next: this.onGetScanTagsSuccess.bind(this),
        error: this.onGetScanTagsError.bind(this),
      }),
    );
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.userService.currentDigitalProperty$
        .pipe(
          distinctUntilChanged(),
          mergeMap((): Observable<IDigitalPropertyListItem | null> => this.userService.currentDigitalProperty$.pipe(take(1))),
        )
        .subscribe(this.onDigitalPropertyChanged.bind(this)),
    );

    this.subscribeToScanningType();
  }

  private subscribeToScanningType(): void {
    this.subscriptions.add(
      this.automatedScanAdvancedService.getScanningType$().subscribe((type: scanningType) => {
        if (type === scanningType.automatedUserFlow) {
          this.disabled = true;
          this.enableReservedSystemTags = true;
          this.selectByName(SystemTags.AutomatedUserFlow);
        } else {
          this.disabled = false;
          this.enableReservedSystemTags = false;
          this.resetSelectedTagIfUnavailable();
        }
        this.changeDetector.detectChanges();
      }),
    );
  }

  private selectByName(tagName: string): void {
    const scanTag: IScanTag = this.availableTags.find((tag: IScanTag) => tag[$scanTag.tagName] === tagName);

    if (SharedCommonUtility.notNullish(scanTag)) {
      this.select(scanTag._id);
    }
  }

  private resetSelectedTagIfUnavailable(): void {
    if (this.availableTags.every((tag: IScanTag) => tag._id !== this.selectedTagId)) {
      this.select(null);
    }
  }

  public select(tagId: string): void {
    if (tagId === this.selectedTagId) {
      return;
    }
    this.selectedTagId = tagId;
    this.onTouched();
    this.onChange(tagId);
    this.changeDetector.detectChanges();
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetector.detectChanges();
  }

  public writeValue(obj: string): void {
    this.select(obj);
  }
}
