import { Component, Input } from '@angular/core';
import { IAutomatedScanIssue, IScanIssues } from '../../../../../../../shared/interfaces/scan-issues.interface';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IIssuesViewDetailsCellConfig extends ICellConfig {
  selectedIssue: IAutomatedScanIssue;
  issues: IScanIssues;
}

@Component({
  selector: 'table-cell-issues-view-details',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div class="row" #ref>
      <div class="col-12">
        <app-accordion-item>
          <div class="accordion-item-header-content ms-2">
            <p style="text-overflow: ellipsis; font-size: 1rem">{{ config.issues.title }}</p>
          </div>
          <div class="accordion-item-body-content mt-2 ms-4">
            <app-issues-view-details class="d-flex d-block" [issue]="config.selectedIssue" [ruleDetails]="config.issues">
            </app-issues-view-details>
          </div>
        </app-accordion-item>
      </div>
    </div>
  `,
})
export class IssuesViewDetailsCellComponent extends BaseCellComponent {
  @Input() public config: IIssuesViewDetailsCellConfig;
}
