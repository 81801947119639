import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { config } from '../../environments/config.shared';
import { BrowserLocalStorageService } from './browser-local-storage.service';
import { BrowserSessionStorageService } from './browser-session-storage.service';
import { RestService } from './rest.service';
import { IUserResponse } from '../../../shared/interfaces/user.interface';
import { IUserAuthenticationError } from '../../../shared/interfaces/error.interface';

@Injectable()
export class AuthService {
  private accessToken$: BehaviorSubject<string>;

  constructor(
    private browserLocalStorageService: BrowserLocalStorageService,
    private browserSessionStorageService: BrowserSessionStorageService,
    private restService: RestService,
  ) {
    this.accessToken$ = new BehaviorSubject<string>(null);
  }

  private getToken(tokenData: string | string[]): string | null {
    let token: string;
    let tokenParts: any[] | string[];

    if (typeof tokenData === 'string') {
      tokenParts = tokenData.split(' ');
    } else if (Array.isArray(tokenData)) {
      tokenParts = tokenData;
    }

    if (tokenParts === undefined) {
      return token;
    }

    if (tokenParts[0] === 'Bearer' && tokenParts[1] && tokenParts[1].length > 0) {
      token = tokenParts[1];
    } else {
      token = tokenParts[0];
    }

    return token;
  }

  public clearLocalData(): void {
    this.browserLocalStorageService.clear();
    this.browserSessionStorageService.clear();
    this.accessToken$.next(null);
  }

  public getNewAccessToken(): Observable<IUserAuthenticationError | HttpResponse<IUserResponse>> {
    const refreshToken: string | undefined = this.getRefreshTokenFromStorage();

    return this.restService.getNewAccessToken(refreshToken);
  }

  public getAccessTokenFromStorage(): string | undefined {
    return this.browserLocalStorageService.getItem(config.token.access.id);
  }

  public getAccessTokenObservable(): Observable<string> {
    return this.accessToken$;
  }

  public saveAccessTokenToStorage(tokenData: string): void {
    const token = this.getToken(tokenData);
    this.browserLocalStorageService.setItem(config.token.access.id, token);
    this.accessToken$.next(token);
  }

  public getRefreshTokenFromStorage(): string | undefined {
    return this.browserLocalStorageService.getItem(config.token.refresh.id);
  }

  public saveRefreshTokenInStorage(tokenData: string): void {
    const token = this.getToken(tokenData);
    this.browserLocalStorageService.setItem(config.token.refresh.id, token);
  }
}
