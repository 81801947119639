import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appCollapse]',
})
export class CollapseDirective {
  @HostBinding('class.hide')
  public isCollapsing: boolean;

  @Input()
  public set appCollapse(value: boolean) {
    if (value) {
      this.hide();
      return;
    }

    this.show();
  }
  constructor() {}

  public hide(): void {
    this.isCollapsing = true;
  }

  public show(): void {
    this.isCollapsing = false;
  }
}
