import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { identity, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, startWith, switchMap, take, tap } from 'rxjs/operators';

import { FormService } from '../../services/form.service';
import { CustomFormControl } from '../../interfaces/form.interface';
import { A11yService } from '../../services/a11y.service';

@Component({
  selector: 'app-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions: Subscription;

  public message: string;

  @Input() public field: CustomFormControl | AbstractControl;
  @Input() public name: string;
  @Input() public label: string;
  @Input() public customMessage: string;
  @Input() public formValidationRequest$: Observable<void>;
  @Input() public overrideErrors: Record<string, string>;
  @Input() public distinctChanges: boolean;
  @Input() public alwaysAnnounceError: boolean;

  // Note: formValidationRequest$ is used mostly in onSubmit form event when we want to revalidate and get all form errors

  constructor(
    private formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private a11yService: A11yService,
  ) {
    this.subscriptions = new Subscription();
    this.message = '';
    this.distinctChanges = true;
  }

  private processChanges(shouldAnnounceError: boolean = false): void {
    if (this.field.pristine && this.field.untouched) {
      this.message = '';
      this.detectChanges();
      return;
    }

    this.message = this.formService.getErrorMessageForField(
      this.field as CustomFormControl,
      this.name,
      this.customMessage,
      this.label,
      typeof this.overrideErrors !== 'undefined' ? this.overrideErrors : {},
    );

    if (shouldAnnounceError || this.alwaysAnnounceError) {
      this.a11yService.setAlertMessage(this.message);
    }

    this.detectChanges();
  }

  private detectChanges(): void {
    if ((this.changeDetectorRef as ViewRef).destroyed === false) {
      this.changeDetectorRef.detectChanges();
    }
  }

  public ngOnInit(): void {
    const fieldChangesSubscription: Subscription = this.field.valueChanges
      .pipe(
        this.distinctChanges ? distinctUntilChanged() : identity,
        switchMap((value: any) =>
          this.field.statusChanges.pipe(
            filter((status: string) => {
              return status !== 'PENDING';
            }),
            take(1),
          ),
        ),
      )
      .subscribe(this.processChanges.bind(this, false));

    this.subscriptions.add(fieldChangesSubscription);

    if (typeof this.formValidationRequest$ === 'undefined') {
      return;
    }

    const formValidationRequestSubscription: Subscription = this.formValidationRequest$
      .pipe(
        tap((): void => this.field.markAsTouched()),
        switchMap(() =>
          this.field.statusChanges.pipe(
            startWith(this.field.status),
            filter((status: string): boolean => status !== 'PENDING'),
            take(1),
          ),
        ),
        finalize(() => this.detectChanges()),
      )
      .subscribe(this.processChanges.bind(this, true));

    this.subscriptions.add(formValidationRequestSubscription);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.customMessage && changes.customMessage.previousValue !== changes.customMessage.currentValue) ||
      (changes.label && changes.label.previousValue !== changes.label.currentValue)
    ) {
      this.processChanges();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
