<div>
  <span class="mx-0 fw-bold" aria-live="polite" role="status">
    {{ getPaginationFrom() }}&ndash;{{ getPaginationTo() }}
    {{ 'of' | translate }}
    {{ entriesAmount }}
  </span>
  <button
    type="button"
    class="btn btn-link fw-bold prev-page-arrow pe-0"
    [attr.aria-hidden]="isFirstPage()"
    [disabled]="isFirstPage()"
    (click)="paginate(currentPage - 1)"
  >
    <span class="visuallyhidden">{{ 'pagination_go_to_previous_page' | translate }}</span>
  </button>
  <button
    type="button"
    [attr.aria-hidden]="isLastPage()"
    class="btn btn-link fw-bold next-page-arrow pe-0"
    [disabled]="isLastPage()"
    (click)="paginate(currentPage + 1)"
  >
    <span class="visuallyhidden">{{ 'pagination_go_to_next_page' | translate }}</span>
  </button>
</div>
