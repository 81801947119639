import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, HostBinding, Input, OnInit } from '@angular/core';

import { StepContentDirective } from './step-content.directive';
import { PANEL_ID_PREFIX, TAB_ID_PREFIX } from '../constants';

@Component({
  selector: 'div[appStep]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="isActive">
      <ng-template [ngTemplateOutlet]="lazyContent && lazyContent.content"></ng-template>
      <ng-content></ng-content>
    </ng-container>
  `,
})
export class StepComponent implements OnInit {
  private IS_ACTIVE: boolean = false;

  @Input() public label: string = '';

  public id: string = '';

  @HostBinding('attr.role') role: string = 'region';
  @HostBinding('attr.aria-labelledby') ariaLabelledBy: string;
  @HostBinding('id') domId: string;
  @HostBinding('style.display') display: string;
  @HostBinding('class.current-step') get isCurrentStep(): boolean {
    return this.isActive;
  }

  @ContentChild(StepContentDirective)
  public lazyContent: StepContentDirective;

  constructor(private cdr: ChangeDetectorRef) {}

  public setHostAttributes(): void {
    this.display = this.IS_ACTIVE ? 'block' : 'none';
    this.ariaLabelledBy = `${TAB_ID_PREFIX}${this.id}`;
    this.domId = `${PANEL_ID_PREFIX}${this.id}`;
  }

  public ngOnInit(): void {
    this.setHostAttributes();
  }

  @Input()
  set isActive(value: boolean) {
    if (this.IS_ACTIVE !== value) {
      this.IS_ACTIVE = value;
      this.display = this.IS_ACTIVE ? 'block' : 'none';

      this.cdr.markForCheck();
    }
  }

  get isActive(): boolean {
    return this.IS_ACTIVE;
  }
}
