import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appFileDrop]',
})
export class FileDropDirective {
  @Output() filesDropped: EventEmitter<File[]> = new EventEmitter<File[]>();

  constructor() {}

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const transfer: DataTransfer = event.dataTransfer;
    if (Array.from(transfer.files).length !== 0) {
      this.filesDropped.emit(Array.from(transfer.files));
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
