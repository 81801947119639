import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '../../translate/translate.service';

@Component({
  selector: 'app-common-comment',
  templateUrl: './common-comment.component.html',
  styleUrls: ['./common-comment.component.scss'],
})
export class CommonCommentComponent {
  @Input() public deletable: boolean = false;
  @Input() public header: string = this.translateService.instant('added_comment');
  @Input() public isCollapsed: boolean = false;
  @Input() public id: string;
  @Input() public author: string;
  @Input() public date: string;
  @Input() public time: string;

  @Output() public deleted: EventEmitter<string> = new EventEmitter();

  constructor(private translateService: TranslateService) {}
}
