import { AuditStandards } from './audit-standard';

export enum $auditStandardSuccessCriteria {
  _id = '_id',
  identifier = 'identifier',
  auditStandards = 'auditStandards',
  level = 'level',
}

export enum SuccessCriteriaIdentifier {
  EABP1 = 'EABP1', // aslint
  DEQUEBP4 = 'DEQUEBP4', // axe
  WAVEBP1 = 'WAVEBP1', // wave
  NOT_APPLICABLE = 'Not applicable',
}

export const SuccessCriteriaToStandardMapping: Record<string, AuditStandards> = {
  [SuccessCriteriaIdentifier.EABP1]: AuditStandards.essential,
  [SuccessCriteriaIdentifier.DEQUEBP4]: AuditStandards.axe,
  [SuccessCriteriaIdentifier.WAVEBP1]: AuditStandards.wave,
  [SuccessCriteriaIdentifier.NOT_APPLICABLE]: AuditStandards.notApplicable,
};
