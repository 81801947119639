import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonUtility } from '../../utility/common.utility';

const APP_ACCEPT_FILES_DEFAULT: string = `.xlsx,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileInputComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent implements OnInit, ControlValueAccessor {
  private onChange: Function = () => {};
  private onTouched: Function = () => {};
  public disabled: boolean;
  public id: string;
  public selectedFiles: File[];

  @Input()
  public accept: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.accept = APP_ACCEPT_FILES_DEFAULT;
  }

  public ngOnInit(): void {
    this.id = `file_input_${CommonUtility.createUniqueDOMId()}`;
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  @Input('disabled')
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  public writeValue(obj: File[]): void {
    this.selectedFiles = obj;
    this.changeDetectorRef.detectChanges();
  }

  public onFilesSelected(event: Event | InputEvent): void {
    const files: FileList = (event.target as HTMLInputElement).files;
    this.selectedFiles = Array.from(files);
    this.onTouched();
    this.onChange(this.selectedFiles);
    this.changeDetectorRef.detectChanges();
  }
}
