import { Component, Input } from '@angular/core';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { SharedObjectUtility } from '../../../../shared/utils/object.utility';
import { EapColors } from '../../shared/eap-colors';
import { Icons } from '../../shared/eap-icons.component';

export interface ITrendConfig {
  currentValue: number;
  previousValue: number;
  isIncreaseImprovement: boolean;
  showNoChange?: boolean;
  noChangePrefix?: string;
}

@Component({
  selector: 'app-common-trend',
  templateUrl: './common-trend.component.html',
})
export class CommonTrendComponent {
  public Icons: typeof Icons;
  public EapColors: typeof EapColors;

  @Input() public config: ITrendConfig;

  constructor() {
    this.Icons = Icons;
    this.EapColors = EapColors;
  }

  public get isPositiveTrend(): boolean {
    return this.variation > 0;
  }

  public get variation(): number {
    const _variation: number = this.config?.currentValue - this.config?.previousValue;
    if (SharedObjectUtility.isNumber(_variation)) {
      return _variation;
    }
    return 0;
  }

  public get absVariation(): number {
    return Math.abs(this.variation);
  }

  public get noChangePrefix(): string {
    if (SharedCommonUtility.isNullishOrEmpty(this.config?.noChangePrefix)) {
      return '';
    }
    return this.config.noChangePrefix;
  }
}
