import { SIZE_20_MB } from './uploads';

export const EVALUATORS_NOTES_DEFAULT_VALUE: string = 'N/A';

export enum $designReviewProject {
  _id = '_id',
  name = 'name',
  status = 'status',
  createdAt = 'createdAt',
  workspace = 'workspace',
  digitalPropertyId = 'digitalPropertyId',
  digitalProperty = 'digitalProperty',
  screens = 'screens',
  pages = 'pages',
  screenCount = 'screenCount',
  findingsCount = 'findingsCount',
  summary = 'summary',
  createdBy = 'createdBy',
  createdByUser = 'createdByUser',
}

export enum $designReviewProjectSortableFields {
  _id = '_id',
  name = 'name',
  status = 'status',
  digitalProperty = 'digitalProperty',
  screenCount = 'screenCount',
  findingsCount = 'findingsCount',
  createdAt = 'createdAt',
}

export enum $designReviewProjects {
  projects = 'projects',
  _total = '_total',
}

export enum $designReviewProjectSummary {
  screenCount = 'screenCount',
  findingsCount = 'findingsCount',
}

export enum $designReviewPage {
  _id = '_id',
  name = 'name',
  designReviewProjectId = 'designReviewProjectId',
  image = 'image',
  imageSrc = 'imageSrc',
  imageId = 'imageId',
  numberOfFindings = 'numberOfFindings',
  findings = 'findings',
  highlightedImageId = 'highlightedImageId',
}

export enum $designReviewPages {
  pages = 'pages',
  _total = '_total',
}

export enum $designReviewHeader {
  screenName = 'screenName',
  projectName = 'projectName',
}

export enum $designReviewDetails {
  project = 'project',
  pages = 'pages',
}

export enum $designReviewDashboard {
  project = 'project',
  findingsByPage = 'findingsByPage',
  findingsByCategory = 'findingsByCategory',
  findingsByType = 'findingsByType',
}

export enum $designReviewStats {
  numberOfFindings = 'numberOfFindings',
}

export enum $designReviewFindingsByPage {
  page = 'page',
  numberOfFindings = 'numberOfFindings',
}

export enum $designReviewFindingsByCategory {
  category = 'category',
  numberOfFindings = 'numberOfFindings',
}

export enum $designReviewFindingsByType {
  type = 'type',
  numberOfFindings = 'numberOfFindings',
}

export enum $designReviewFinding {
  _id = '_id',
  designReviewPageId = 'designReviewPageId',
  designReviewPage = 'designReviewPage',
  boundingBox = 'boundingBox',
  rule = 'rule',
  issueType = 'issueType',
  designReviewFindingId = 'designReviewFindingId',
  designReviewProjectId = 'designReviewProjectId',
  designReviewProject = 'designReviewProject',
  findingIndex = 'findingIndex',
  evaluatorsNotes = 'evaluatorsNotes',
  globalFinding = 'globalFinding',
  designReviewHighlightId = 'designReviewHighlightId',
  designReviewHighlight = 'designReviewHighlight',
  workspaceId = 'workspaceId',
  linkedTask = 'linkedTask',
  status = 'status',
}

export enum $designReviewFindingStatusHistory {
  reason = 'reason',
}

export enum $designReviewFindingComment {
  _id = '_id',
  designReviewFindingId = 'designReviewFindingId',
  designReviewPageId = 'designReviewPageId',
  designReviewProjectId = 'designReviewProjectId',
  createdByUser = 'createdByUser',
  createdAt = 'createdAt',
  message = 'message',
}

export enum $designReviewHighlight {
  _id = '_id',
  boundingBox = 'boundingBox',
  designReviewPageId = 'designReviewPageId',
  designReviewProjectId = 'designReviewProjectId',
  findings = 'findings',
}

export enum $designReviewFindings {
  findings = 'findings',
  _total = '_total',
}

export enum $designReviewFindingFilter {
  issueType = 'issueType',
  designReviewFindingId = 'designReviewFindingId',
  finding = 'finding',
  category = 'category',
  successCriteria = 'successCriteria',
  level = 'level',
  screen = 'screen',
}

export enum $designReviewCreateFinding {
  designReviewPageId = 'designReviewPageId',
  boundingBox = 'boundingBox',
  rule = 'rule',
  issueType = 'issueType',
  designReviewProjectId = 'designReviewProjectId',
  evaluatorsNotes = 'evaluatorsNotes',
  designReviewHighlightId = 'designReviewHighlightId',
  globalFinding = 'globalFinding',
  status = 'status',
}

export enum DesignReviewStatus {
  completed = 'completed',
  inProgress = 'in_progress',
  archived = 'archived',
  pendingReview = 'pending_review',
}

export enum DesignReviewFindingStatus {
  open = 'open',
  fixed = 'fixed',
  cannotBeFixed = 'cannot_be_fixed',
  dismissed = 'dismissed',
}

export enum DesignReviewFindingStatusModalOptions {
  restore = 'restore',
  dismiss = 'dismiss',
}

export enum $designReviewFindingHistoryChange {
  currentStatus = 'currentStatus',
  previousStatus = 'previousStatus',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  user = 'user',
  reason = 'reason',
  action = 'action',
  changedFields = 'changedFields',
}

export const DesignReviewStatusSelectable: DesignReviewStatus[] = [
  DesignReviewStatus.inProgress,
  DesignReviewStatus.pendingReview,
  DesignReviewStatus.completed,
];

export const DesignReviewStatusSortDescending: Array<DesignReviewStatus> = [
  DesignReviewStatus.completed,
  DesignReviewStatus.pendingReview,
  DesignReviewStatus.inProgress,
  DesignReviewStatus.archived,
];

export const DesignReviewStatusSortAscending: Array<DesignReviewStatus> = [
  DesignReviewStatus.inProgress,
  DesignReviewStatus.pendingReview,
  DesignReviewStatus.completed,
  DesignReviewStatus.archived,
];

export enum DesignReviewJobOperationType {
  highlightProjectScreens = 'highlightProjectScreens',
}

export enum $boundingBox {
  x = 'x',
  y = 'y',
  width = 'width',
  height = 'height',
}

export const PICTOGRAM_CHARS: RegExp = /\p{Extended_Pictographic}/gu;
export const DESIGN_REVIEW_FINDINGS_EVALUATORS_NOTES_MAX_LENGTH: number = 500;
export const DESIGN_REVIEW_NAME_MIN_LENGTH: number = 5;
export const DESIGN_REVIEW_NAME_MAX_LENGTH: number = 150;
export const DESIGN_REVIEW_NAME_EXCLUDED_CHARACTERS: string = ':\\/?*[]';
export const DESIGN_REVIEW_PAGE_NAME_MIN_LENGTH: number = 1;
export const DESIGN_REVIEW_PAGE_NAME_MAX_LENGTH: number = 155;
export const DESIGN_REVIEW_FINDINGS_MAX_NUMBER: number = 100;
export const DESIGN_REVIEW_SCREENS_MAX_NUMBER_OF_FILES: number = 60;
export const DESIGN_REVIEW_SCREENS_MAX_FILE_SIZE: number = SIZE_20_MB;
export const DESIGN_REVIEW_REPORT_PAGE_NAME_MAX_LENGTH: number = 31;
export const DESIGN_REVIEW_REPORT_TRUNCATED_PAGE_NAME_LENGTH: number = 28;
export const DESIGN_REVIEW_REPORT_NUM_MAX_IDENTIFIERS: number = 999;
export const DESIGN_REVIEW_REPORT_DISALLOWED_CHARACTER_REGEX: RegExp = /[:\/?\\\*\[\]]/g;
export const DESIGN_REVIEW_EMPTY_PAGE_NAME: string = 'untitled';
