import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../translate/translate.service';
import { A11yService } from '../../../services/a11y.service';
import { FormService } from '../../../services/form.service';
import { NotificationService } from '../../../services/notification.service';
import { AbstractChangePasswordComponent } from '../abstract/change-password-abstract.component';
import { PASSWORD_ERRORS } from '../password-errors.constants';

@Component({
  selector: 'app-change-password-from-signup',
  templateUrl: '../abstract/change-password-abstract.component.html',
  styleUrls: ['../abstract/change-password-abstract.component.scss'],
})
export class ChangePasswordFromSignupComponent extends AbstractChangePasswordComponent {
  public currentPasswordRequired: boolean = false;
  public independentForm: boolean = false;
  public syncErrorsToCheck: PASSWORD_ERRORS[] = [
    PASSWORD_ERRORS.minlength,
    PASSWORD_ERRORS.noLowercase,
    PASSWORD_ERRORS.noUppercase,
    PASSWORD_ERRORS.noNumberOrSpecial,
  ];
  public asyncErrorsToCheck: PASSWORD_ERRORS[] = [];
  public submitButtonLabel: string = 'sign_up';

  @Input() public emailToken: string;
  @Input() public formChangePassword: UntypedFormGroup;
  @Input() public formValidationRequest: Subject<void>;
  @Input() public formValidationRequest$: Observable<void>;

  constructor(
    protected userService: UserService,
    protected formBuilder: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected a11yService: A11yService,
    protected formService: FormService,
    protected notificationService: NotificationService,
  ) {
    super(userService, formBuilder, translateService, a11yService, formService, notificationService);

    this.formValidationRequest = new Subject();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();
  }
}
