import { Component, EventEmitter, Input, Output } from '@angular/core';
import { $sortingOrder } from '../../../../shared/constants/sort';

export interface ISortSelectorValue {
  sortingOrder: $sortingOrder;
  field: string;
}

export interface ISortSelectorItem {
  name: string;
  value: ISortSelectorValue;
}

@Component({
  selector: 'app-sort-selector',
  templateUrl: './sort-selector.component.html',
  styleUrls: ['./sort-selector.component.scss'],
})
export class SortSelectorComponent {
  @Input()
  public sortItems: ISortSelectorItem[];

  @Input()
  public sortValue: ISortSelectorValue;

  @Output()
  public onSort: EventEmitter<ISortSelectorValue>;

  constructor() {
    this.sortItems = [];
    this.sortValue = null;
    this.onSort = new EventEmitter<ISortSelectorValue>();
  }

  public formSubmitted(): void {
    this.onSort.emit(this.sortValue);
  }
}
