import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from './translate.service';

@Pipe({
  name: 'translateHtml',
})
export class TranslateHtmlPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: string, args?: string | string[] | number | number[]): any {
    return this.translateService.instantHtml(value, args);
  }
}
