<form [formGroup]="searchForm" (ngSubmit)="submitSearch()">
  <fieldset formArrayName="searchFields">
    <legend class="font-size-normal m-0 fw-bold">
      {{ 'label_advanced_search' | translate }}
    </legend>
    <ng-container *ngFor="let searchGroup of searchFields.controls; let index = index">
      <ng-template
        [ngTemplateOutlet]="searchRow"
        [ngTemplateOutletContext]="{ index: index, searchGroup: searchGroup }"
      ></ng-template>
    </ng-container>
    <div class="my-2 d-flex">
      <button
        ds-button
        type="reset"
        class="margin-right-sm px-1"
        (click)="$event.preventDefault(); resetSearch()"
        [variant]="DsButtonVariants.microAction"
        [microActionIcon]="Icons.ArrowsRotate"
      >
        {{ 'reset' | translate }}
      </button>
      <button ds-button [variant]="DsButtonVariants.secondary" type="submit">
        {{ 'apply_search' | translate }}
      </button>
    </div>
  </fieldset>
</form>

<ng-template #searchRow let-index="index" let-searchGroup="searchGroup">
  <div class="d-flex flex-wrap search-row margin-bottom-xs" [formGroup]="searchGroup">
    <div class="d-flex flex-nowrap margin-top-xs">
      <label class="margin-right-sm">
        <span class="visuallyhidden">{{ 'label_field_to_search' | translate }}</span>
        <select [formControlName]="$advancedSearchForm.fieldToSearch" [name]="$advancedSearchForm.fieldToSearch">
          <option *ngFor="let field of fieldsToSearch" [ngValue]="field">
            {{ 'label_advanced_search_field_' + field | translate }}
          </option>
        </select>
      </label>
      <label class="margin-right-sm">
        <span class="visuallyhidden">{{ 'label_operation' | translate }}</span>
        <select [formControlName]="$advancedSearchForm.operation" [name]="$advancedSearchForm.operation">
          <option *ngFor="let operation of operations" [ngValue]="operation">
            {{ 'label_advanced_search_operation_' + operation | translate }}
          </option>
        </select>
      </label>
    </div>

    <div class="d-flex flex-wrap margin-top-xs">
      <label class="margin-right-sm">
        <span class="visuallyhidden">{{ 'label_text_input' | translate }}</span>
        <span class="search-input-wrap">
          <svg aria-hidden="true"><use xlink:href="#search-input-prefix"></use></svg>
          <input
            class="form-element"
            [formControlName]="$advancedSearchForm.value"
            [name]="$advancedSearchForm.value"
            [placeholder]="'label_advanced_search_text_placeholder' | translate"
          />
        </span>
      </label>

      <div class="d-flex flex-nowrap">
        <button
          ds-button
          [variant]="DsButtonVariants.microAction"
          [microActionIcon]="Icons.Plus"
          class="margin-right-sm"
          type="button"
          (click)="addSearchRow()"
          *ngIf="exceededLimit === false"
        >
          {{ 'action_add' | translate }}
        </button>
        <button
          class="btn text-danger btn-link link-underline p-0"
          type="button"
          *ngIf="searchFields.length > 1"
          (click)="removeSearchRow(index)"
        >
          {{ 'action_remove' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
