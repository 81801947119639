import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { IMobileScanOperatingSystem } from '../../../shared/interfaces/scan.interface';
import { UserPropertyService } from './user-property.service';
import { ICurrentSelectedProperty } from './user.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class ScanMobileService {
  constructor(
    private restService: RestService,
    private userPropertyService: UserPropertyService,
  ) {}

  public getOperatingSystems(): Observable<IMobileScanOperatingSystem[]> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IMobileScanOperatingSystem[]> =>
            this.restService.getMobileScansOperatingSystems(workspaceId, digitalPropertyId),
        ),
      );
  }
}
