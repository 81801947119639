<div *ngIf="form" class="common-drag-and-drop" [ngClass]="view" [formGroup]="form">
  <label [for]="context.field" *ngIf="context.label" class="label margin-bottom-xs text-nowrap" [ngClass]="{ required }">
    {{ context.label | translate }}
  </label>
  <div *ngIf="context.description" class="margin-bottom-xs">
    {{ context.description | translate }}
  </div>
  <div
    class="upload-button"
    [ngClass]="{ 'on-drag-enter': isDragAreaActive === true && (disabled$ | async) === false }"
    (drop)="onDropFiles($event)"
    (dragenter)="onDragEnterOrOver($event)"
    (dragover)="onDragEnterOrOver($event)"
    (dragleave)="onDragLeave($event)"
  >
    <button type="button" (click)="nativeInput.click()" [disabled]="(disabled$ | async) ? true : null">
      <span class="d-block d-sm-none">
        {{ (multiple ? 'select_files' : 'select_file') | translate }}
      </span>
      <span class="d-none d-sm-block">
        {{ (multiple ? 'select_or_drag_and_drop_files' : 'select_or_drag_and_drop_file') | translate }}
      </span>
    </button>
    <div class="input-type-file" aria-hidden="true">
      <input
        type="file"
        [attr.multiple]="multiple ? 'multiple' : undefined"
        value=""
        class="form-element"
        tabindex="-1"
        #nativeInput
        [id]="context.field"
        [name]="context.field"
        [accept]="context.acceptedTypes"
        [attr.data-selected-files-length]="0"
        [required]="required"
        [formControlName]="context.field"
        (change)="uploadFilesEvent($event)"
      />
    </div>
  </div>
  <div class="upload-description mb-2 mt-1" *ngIf="uploadDescription">{{ uploadDescription | translate }}</div>

  <div *ngIf="displayTable && tableData.length > 0" class="row mt-3 mb-4">
    <div class="col-md-12">
      <label class="label margin-bottom-xs text-nowrap">{{ context.tableLabel | translate }}</label>
      <app-ngb-table [config]="tableConfig" [data]="tableData" (onSort)="onTableSort($event)" class="task-table"> </app-ngb-table>

      <div
        *ngIf="sizeToUploadExceedsLimits()"
        class="d-flex justify-content-between alert-danger padding-right-lg padding-left-lg"
      >
        <span class="py-2">
          {{ 'document_total_file_size' | translate }}:
          {{ getFormattedFileSize(getSelectedFilesTotalSize()) }}
        </span>
        <span class="py-2">
          {{ '> ' + getFormattedFileSize(maxUploadSize) }}
        </span>
      </div>
    </div>
  </div>

  <div [id]="context.field + '-error'" class="error-container">
    <app-form-field-error
      [formValidationRequest$]="formValidationRequest$"
      [field]="form.get(context.field)"
      [name]="context.field"
      [label]="context.label"
      [customMessage]="context.customMessage"
      [overrideErrors]="context.overrideErrors"
    ></app-form-field-error>
  </div>
</div>
