<div>
  <h4>{{ stepNumber + 1 }} - {{ 'email_alerts' | translate }}</h4>

  <div class="email-alerts-table">
    <div class="d-flex justify-content-end">
      <button *ngIf="!maxAlertsReached; else maxReached" class="btn btn-link" (click)="onAddRecipientClicked()">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 15 15" class="plus-icon">
          <use xlink:href="#plus"></use>
        </svg>
        <span>{{ 'add_recipient' | translate }}</span>
      </button>
      <ng-template #maxReached>
        <span >{{ 'max_alerts_recipient_info' | translate: [maxRecipientCount] }}</span>
      </ng-template>
    </div>
    <app-ngb-table [config]="tableConfig" [data]="tableData"></app-ngb-table>
  </div>

  <div *ngIf="isEditing" class="user-alerts-form">
    <app-monitoring-alerts-editor
      #alertsEditorComponent
      [workspace]="workspace"
      [userAlerts]="editedAlert"
      [allAlertEmails]="allAlertEmails"
      [formValidationRequest$]="formValidationRequest$"
      (onCancel)="onEditAlertsCancel()"
      (onSave)="onEditAlertsSave($event)"
    ></app-monitoring-alerts-editor>
  </div>

  <ng-template #alertActions let-alert="alert">
    <div class="d-flex">
      <button class="btn btn-link d-flex" (click)="editAlert(alert)">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 15 15" class="edit-icon">
          <use xlink:href="#pencil"></use>
        </svg>
        <span>{{ 'action_edit' | translate }}</span>
      </button>

      <button class="btn btn-link d-flex" (click)="removeAlert(alert)">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 8 8" class="delete-icon">
          <use xlink:href="#trash"></use>
        </svg>
        <span>{{ 'remove_action' | translate }}</span>
      </button>
    </div>
  </ng-template>
</div>
