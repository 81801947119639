<form [formGroup]="form">
  <div class="row">
    <div class="col-12 col-lg-8 col-lg-6">
      <div class="row">
        <div class="col-12">
          <h2>{{ 'rule_details' | translate }}</h2>
          {{ 'required_fields_label' | translate }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-common-input
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              disabled: action === AuditRuleAction.Update,
              label: 'issue_field_ruleId',
              field: $auditRule.ruleId,
            }"
          >
          </app-common-input>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_description',
              field: $auditRule.description,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-multiselect-typeahead
            label="issue_field_successCriteria"
            [formControlName]="$auditRule.successCriterias"
            [possibleValues]="successCriterias"
            [valueToDropdownLabelFunction]="successCriteriaToLabel"
            [valueToSelectedItemLabelFunction]="successCriteriaToSelectedItem"
            [valueToOutputValueFunction]="findSuccessCriteriaToSelectedItem"
            [required]="true"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
          ></app-multiselect-typeahead>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-lg-6">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="libraries$ | async"
            [context]="{
              field: $auditRule.ruleLibrary,
              label: 'library',
              valueKey: 'value',
              nameKey: 'name',
              disabled: isCustomAuditRule,
            }"
          ></app-common-select>
        </div>
        <div class="col-12 col-lg-6">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="categories$ | async"
            [context]="{
              field: $auditRule.category,
              label: 'category',
            }"
          ></app-common-select>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="severities"
            [context]="{
              field: $auditRule.severity,
              label: 'audit_rule_property_severity',
              translateName: true,
              valueKey: 'value',
              nameKey: 'name',
            }"
          ></app-common-select>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_stepsToReproduce',
              field: $auditRule.stepsToReproduce,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_actualResult',
              field: $auditRule.actualResult,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_recommendation',
              field: $auditRule.recommendation,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="functionalImpacts"
            [context]="{
              field: $auditRule.functionalImpact,
              label: 'issue_field_functionalImpact',
              valueKey: 'value',
              translateName: true,
              nameKey: 'name',
            }"
          ></app-common-select>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-checkbox-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="disabilities"
            [context]="{
              field: $auditRule.disabilitiesAffected,
              label: 'issue_field_disabilityAffected',
              valueKey: 'value',
              translateName: true,
              nameKey: 'name',
            }"
          ></app-common-checkbox-select>
        </div>
      </div>

      <ng-container *ngIf="showTestFields">
        <div class="row mt-4">
          <div class="col-12">
            <app-common-input
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'audit_rule_property_test_id',
                field: $auditRule.testId,
              }"
            >
            </app-common-input>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <app-common-input
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'audit_rule_property_test_name',
                field: $auditRule.testName,
              }"
            >
            </app-common-input>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <app-common-textarea
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'audit_rule_property_test_manual_steps',
                field: $auditRule.testManualSteps,
                placeholder: '',
                required: true,
              }"
            >
            </app-common-textarea>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedLibrary$ | async as selectedLibrary">
        <ng-container
          *ngIf="
            selectedLibrary.code === ruleLibraryCodeNames.web_la ||
            selectedLibrary.code === ruleLibraryCodeNames.organization_custom
          "
        >
          <div class="row mt-4">
            <div class="col-12">
              <app-common-textarea
                [form]="form"
                [formValidationRequest$]="formValidationRequest$"
                [context]="{
                  label: 'label_compliant_code_example',
                  field: $auditRule.compliantCodeExample,
                  placeholder: '',
                  required: false,
                }"
              >
              </app-common-textarea>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <app-common-textarea
                [form]="form"
                [formValidationRequest$]="formValidationRequest$"
                [context]="{
                  label: 'label_non-compliant_code_example',
                  field: $auditRule.nonCompliantCodeExample,
                  placeholder: '',
                  required: false,
                }"
              >
              </app-common-textarea>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <app-common-textarea
                [form]="form"
                [formValidationRequest$]="formValidationRequest$"
                [context]="{
                  label: 'audit_rule_property_techniques',
                  field: $auditRule.techniques,
                  placeholder: '',
                  required: false,
                }"
              >
              </app-common-textarea>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row mt-4">
        <div class="d-flex align-items-center">
          <button
            class="me-3"
            ds-button
            [variant]="DsButtonVariants.primary"
            
            (click)="onSave()"
          >
            {{ 'form_save' | translate }}
          </button>

          <a ds-button [variant]="DsButtonVariants.secondary" [routerLink]="cancelLink" queryParamsHandling="merge">
            {{ 'form_cancel' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
