import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../translate/translate.service';
import { A11yService } from '../../../services/a11y.service';
import { FormService } from '../../../services/form.service';
import { NotificationService } from '../../../services/notification.service';
import { $user } from '../../../../../shared/constants/user';
import { IUserPasswordSettings, IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { AbstractChangePasswordComponent } from '../abstract/change-password-abstract.component';
import { PASSWORD_ERRORS } from '../password-errors.constants';

@Component({
  selector: 'app-change-password',
  templateUrl: '../abstract/change-password-abstract.component.html',
  styleUrls: ['../abstract/change-password-abstract.component.scss'],
})
export class ChangePasswordComponent extends AbstractChangePasswordComponent implements OnInit {
  public currentPasswordRequired: boolean = true;
  public independentForm: boolean = true;
  public syncErrorsToCheck: PASSWORD_ERRORS[] = [
    PASSWORD_ERRORS.minlength,
    PASSWORD_ERRORS.noLowercase,
    PASSWORD_ERRORS.noUppercase,
    PASSWORD_ERRORS.noNumberOrSpecial,
  ];
  public asyncErrorsToCheck: PASSWORD_ERRORS[] = [PASSWORD_ERRORS.beenUsed];
  public submitButtonLabel: string = 'change_password';

  constructor(
    protected userService: UserService,
    protected formBuilder: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected a11yService: A11yService,
    protected formService: FormService,
    protected notificationService: NotificationService,
  ) {
    super(userService, formBuilder, translateService, a11yService, formService, notificationService);
    this.createForm();
    this.formChangePassword.disable();
  }

  private getFormData(): IUserPasswordSettings {
    return {
      [$user.currentPassword]: this.formChangePassword.get($user.currentPassword).value,
      [$user.newPassword]: this.formChangePassword.get($user.newPassword).value,
    };
  }

  protected submit(): void {
    const passwordSettings: IUserPasswordSettings = this.getFormData();

    this.subscriptions.add(
      this.userService.changePassword(passwordSettings).subscribe({
        next: this.onSaveUserSettingsSuccess.bind(this),
        error: this.onSaveUserSettingsError.bind(this),
      }),
    );
  }

  private onGetUserSettingsSuccess(user: IUserServerResponse): void {
    this.currentPasswordRequired = true;
    this.formChangePassword.enable();
  }

  private onGetUserSettingsError(response: HttpErrorResponse): void {
    this.currentPasswordRequired = false;
    console.error(response);
  }

  public ngOnInit(): void {
    const userDataSubscription: Subscription = this.userService.userDataChanged$.subscribe({
      next: this.onGetUserSettingsSuccess.bind(this),
      error: this.onGetUserSettingsError.bind(this),
    });

    this.subscriptions.add(userDataSubscription);

    super.ngOnInit();
  }
}
