import { Component, OnInit, OnDestroy, ElementRef, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

import { MessageBusService, MessageBusChannels } from '../../services/message-bus.service';
import { CommonUtility } from '../../utility/common.utility';

export const tooltipId: string = 'tooltipArea';

enum arrowPosition {
  top = 'top',
  bottom = 'bottom',
}
@Component({
  selector: 'app-tooltip-component',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  private readonly adjustingHorizontal: number;

  public text: string;
  public isVisibleByButton: boolean = false;
  public isVisibleByWindow: boolean = false;
  public top: number;
  public left: number;
  public arrowOffset: number;
  public arrowPositionVertical: string;

  public tooltipId: string = tooltipId;

  @Input() public zIndex: number;

  constructor(
    private messageBusService: MessageBusService,
    private router: Router,
    private elementRef: ElementRef,
  ) {
    this.subscriptions = new Subscription();
    this.text = '';
    this.arrowOffset = 0;
    this.arrowPositionVertical = arrowPosition.bottom;
    this.adjustingHorizontal = 9; // 7 border width of arrow + 2 border width of left and right
  }

  @HostListener('mouseover', ['$event'])
  public onMouseover(): void {
    if (SharedCommonUtility.isNullishOrEmpty(this.text)) {
      return;
    }
    this.isVisibleByWindow = true;
  }

  @HostListener('mouseout')
  public onMouseOut(): void {
    this.isVisibleByWindow = false;
    this.hideTooltip();
  }

  private adjustPositionToViewport(target: HTMLElement): void {
    const viewportWidth: number = document.documentElement.clientWidth || window.innerWidth;
    const viewportHeight: number = document.documentElement.clientHeight || window.innerHeight;

    const elementSizeAndPosition: ClientRect | DOMRect = target.getBoundingClientRect();

    const tooltipElement: HTMLElement = this.elementRef.nativeElement.querySelector('[role="tooltip"]');
    const tooltipSizeAndPosition: ClientRect | DOMRect = tooltipElement.getBoundingClientRect();
    const horizontalScroll: number = tooltipSizeAndPosition.left + tooltipSizeAndPosition.width - viewportWidth;

    if (tooltipSizeAndPosition.left < 0) {
      this.left = elementSizeAndPosition.left + target.clientWidth / 2 - this.adjustingHorizontal;
    } else if (tooltipSizeAndPosition.left + tooltipElement.clientWidth > viewportWidth) {
      this.left = tooltipSizeAndPosition.left - horizontalScroll - this.adjustingHorizontal * 2;
    }

    if (tooltipSizeAndPosition.top < 0) {
      this.top = elementSizeAndPosition.top + elementSizeAndPosition.height;
      this.arrowPositionVertical = arrowPosition.top;
    } else if (elementSizeAndPosition.top + elementSizeAndPosition.height > viewportHeight) {
      this.top = viewportHeight - tooltipSizeAndPosition.height;
    }

    this.arrowOffset = elementSizeAndPosition.left - this.left + elementSizeAndPosition.width / 2 - 6;
  }

  private setPositionBasedOnTargetElement(targetElement: EventTarget): void {
    const viewportWidth: number = document.documentElement.clientWidth || window.innerWidth;

    const target: HTMLElement = targetElement as HTMLElement;
    const targetSizeAndPosition: ClientRect | DOMRect = target.getBoundingClientRect();
    const tooltipElement: HTMLElement = this.elementRef.nativeElement.querySelector('[role="tooltip"]');
    const tooltipSizeAndPosition: ClientRect | DOMRect = tooltipElement.getBoundingClientRect();
    const pageScroll: { scrollX: number; scrollY: number } = CommonUtility.getPageScroll();

    let top: number = targetSizeAndPosition.top - tooltipSizeAndPosition.height;

    if (top < 0) {
      top = 0;
    }

    this.top = Math.round(top) + pageScroll.scrollY;

    let left: number = targetSizeAndPosition.left - tooltipSizeAndPosition.width / 2 + target.clientWidth / 2;
    if (left + tooltipSizeAndPosition.width > viewportWidth) {
      left = viewportWidth - tooltipSizeAndPosition.width;
    }

    this.left = Math.round(left) + pageScroll.scrollX;
  }

  private setPositions(target: EventTarget): void {
    this.setPositionBasedOnTargetElement(target);

    // Note: this is needed because we need to give a chance for browser to render a tooltip
    window.setTimeout(this.adjustPositionToViewport.bind(this, target));
  }

  private showTooltip(data: any): void {
    if (typeof data.text !== 'string' || data.text.length === 0) {
      return;
    }

    this.text = data.text;
    this.isVisibleByButton = true;
    this.arrowPositionVertical = 'bottom';

    // Note: this is needed because we need to give a chance for browser to render a tooltip
    window.setTimeout(this.setPositions.bind(this, data.event.target));
  }

  private hideTooltipByButton(): void {
    this.isVisibleByButton = false;
    this.hideTooltip();
  }

  private hideTooltip(): void {
    if (this.isVisibleByButton || this.isVisibleByWindow) {
      return;
    }
    this.text = '';
    this.top = 0;
    this.left = 0;
  }

  public ngOnInit(): void {
    const showTooltipSubscription: Subscription = this.messageBusService
      .from(MessageBusChannels.tooltipShow)
      .subscribe(this.showTooltip.bind(this));
    const hideTooltipSubscription: Subscription = this.messageBusService
      .from(MessageBusChannels.tooltipHide)
      .subscribe(this.hideTooltipByButton.bind(this));
    const routeChangedSubscription: Subscription = this.router.events.subscribe({
      next: this.hideTooltipByButton.bind(this),
    });

    this.subscriptions.add(showTooltipSubscription);
    this.subscriptions.add(hideTooltipSubscription);
    this.subscriptions.add(routeChangedSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
