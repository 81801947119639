<div
  *ngIf="signupState.value !== SignupState.TOKEN_INVALID; else tokenInvalid"
  class="row gx-0 pt-md-5 sign-up-container"
  
>
  <div class="col-md-6 p-5 sign-up-message full-height">
    <img class="sign-up-logo" src="/static/assets/images/LevelAccess_Logo_White-RGB.svg" alt="{{ 'level_access' | translate }}" />

    <ng-container *ngIf="signupState.value === SignupState.SIGNUP">
      <div class="mb-5">
        <h2 class="sign-up-message-header">{{ 'sign_up_welcome_header' | translate }}</h2>
        <p>{{ 'sign_up_welcome_message' | translate }}</p>
      </div>
      <div>
        <h2 class="sign-up-message-header">{{ 'sign_up_notes' | translate }}</h2>
        <ul>
          <li>{{ 'sign_up_notes_display_name' | translate }}</li>
          <li>{{ 'sign_up_notes_activation_link' | translate }}</li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="signupState.value === SignupState.PLATFORM_USAGE">
      <div class="sign-up-platform-usage">
        <img
          class="sign-up-platform-usage-illustration"
          src="/static/assets/images/Product-Platform-Usage-Illustration.svg"
          [alt]="'platform_usage_alt_text' | translate"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="signupState.value === SignupState.OVERVIEW">
      <div class="sign-up-platform-structure">
        <div class="sign-up-platform-structure-header">{{ 'sign_up_platform_structure_header' | translate }}</div>
        <img
          class="sign-up-platform-structure-image"
          src="/static/assets/images/platform-structure.svg"
          [alt]="'sign_up_platform_structure_alt_text' | translate"
        />
      </div>
    </ng-container>
  </div>

  <div class="col-md-6 full-height">
    <div class="sign-up-card p-4 p-lg-5 me-md-5">
      <div class="mb-4">
        <h1>
          {{ title }}
          <span *ngIf="showStep" class="font-weight-400">
            {{ 'sign_up_step_x_of_total' | translate: [currentSignupStep, totalSignupSteps] }}
          </span>
        </h1>
      </div>

      <ng-container [ngSwitch]="signupState.value">
        <app-signup-form
          *ngSwitchCase="SignupState.SIGNUP"
          [confirmEmailToken]="confirmEmailToken"
          [email]="email"
          (onSuccess)="onSignupSuccess($event)"
        ></app-signup-form>

        <app-signup-platform-usage
          *ngSwitchCase="SignupState.PLATFORM_USAGE"
          [email]="email"
          (onSuccess)="onPlatformUsageSubmitSuccess()"
        ></app-signup-platform-usage>

        <app-signup-overview
          *ngSwitchCase="SignupState.OVERVIEW"
          [tenantName]="tenantName"
          (onSuccess)="onOverviewSuccess()"
        ></app-signup-overview>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #tokenInvalid>
  <div class="row sign-up-container-invalid-link" >
    <div class="col-12 p-5">
      <div class="mb-4">
        <h1>{{ 'sign_up_invalid_link_header' | translate }}</h1>
      </div>
      <div>
        <p>{{ 'sign_up_invalid_link_message' | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>
