import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { DsButtonVariants, Icons } from '@levelaccess/design-system';
import { IE_REVEAL_EYE_SELECTOR, REDIRECT_TO_USERWAY_DASHBOARD_PARAM_VAL, redirectUrl } from '../../../shared/constants';
import { IHttpErrorResponse } from '../../../interfaces/error.interface';
import { RoutingService } from '../../../services/routing.service';
import { TranslateService } from '../../../translate/translate.service';
import { UserService } from '../../../services/user.service';
import { FormService } from '../../../services/form.service';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { CommonUtility } from '../../../utility/common.utility';
import { ErrorMessageService } from '../../../services/error-message.service';
import { A11yService } from '../../../services/a11y.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { alertType } from '../../../constants/alert.constants';
import { Api } from '../../../../../shared/constants/api';
import { AppConfigService } from '../../../services/app-config.service';
import { errorMessagesNames } from '../../../../../shared/constants/errors';
import { ModalService } from '../../../services/modal.service';
import { TenantRedirectModalComponent } from './tenant-redirect-modal/tenant-redirect-modal.component';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { BannerType } from '../../../components/users/edit-user/edit-permissions-banner/edit-permissions-banner.component';
import { TenantService } from '../../../services/tenant.service';
import { IUserWaySSOLink } from '../../../../../shared/interfaces/userway-integration.interface';
import { NotificationService } from '../../../services/notification.service';
import { NotificationPosition } from '../../../models/notification.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  private formValidationRequest: Subject<void>;

  public pageTitle: string;
  public isUserAuthenticated: boolean | null;
  public isMfaRequired: boolean | null;
  public passwordIsTooOldError: boolean = false;
  public userEmailUnverifiedError: boolean = false;
  public authErrorMessage: string;
  public form: UntypedFormGroup;
  public formValidationRequest$: Observable<void>;
  public alertType: typeof alertType;
  public isPasswordShown: boolean;
  public usePasswordRevealButton: boolean;
  public BannerType: typeof BannerType = BannerType;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public Icons: typeof Icons = Icons;

  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
    private routingService: RoutingService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private userService: UserService,
    private formService: FormService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private errorMessageService: ErrorMessageService,
    private a11yService: A11yService,
    private errorHandlerService: ErrorHandlerService,
    private tenantService: TenantService,
    private notificationService: NotificationService,
    private element: ElementRef<Element>,
  ) {
    this.pageTitle = routingService.getPageTitle();
    this.subscriptions = new Subscription();
    this.formValidationRequest = new Subject<any>();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();
    this.isUserAuthenticated = this.userService.isAuthenticated();
    this.isMfaRequired = this.userService.isMfaRequired();
    this.authErrorMessage = '';
    this.alertType = alertType;
    this.isPasswordShown = false;
    this.usePasswordRevealButton = true;

    this.subscribeToRouteChanges();

    const formConfig = {
      email: new UntypedFormControl(null, {
        validators: [CustomValidators.validateEmail, Validators.required],
        updateOn: 'change',
      }),
      password: new UntypedFormControl(null, {
        validators: [CustomValidators.validateWhiteSpaces, Validators.required],
        updateOn: 'change',
      }),
    };

    this.form = this.formBuilder.group(formConfig);
  }

  private onPageTitleChange(pageTitle: string): void {
    this.pageTitle = pageTitle;
  }

  private subscribeToRouteChanges(): void {
    const changePageTitleSubscription = this.routingService.pageTitle$.subscribe(this.onPageTitleChange.bind(this));

    this.subscriptions.add(changePageTitleSubscription);
  }

  private async redirectToUserWayDashboard(): Promise<void> {
    /**
     * TODO EAP-28501: Update TokenInterceptor to reject and handle rejection here
     * The call to generateUserWaySSOLink will result in a Forbidden response when the user does not have permission to userway_integration.create
     * The forbidden response does not appear to be accessible using either a piped catchError or subscribed error
     *
     * Using a promise here allows the call to be intercepted and rerouted as expected
     * The conversion to promise again does not reliably reject the error, but rather resolves to undefined
     * We must re-throw the error for handling within the catch
     */
    const userWaySsoLinkPromise: Promise<IUserWaySSOLink> = this.tenantService.generateUserWaySSOLink().toPromise();
    userWaySsoLinkPromise
      .then((res: IUserWaySSOLink): void => {
        if (SharedCommonUtility.isStringEmpty(res?.ssoLink)) {
          throw new Error('Forbidden');
        }

        window.open(res.ssoLink, '_self');
      })
      .catch((_: any): void => {
        this.notificationService.error(this.translateService.instant('userway_login_error'), NotificationPosition.Toast, true);
        this.redirectToApp();
      });
  }

  private async redirectToApp(): Promise<boolean> {
    if (
      typeof this.activatedRoute.snapshot.queryParams[redirectUrl.returnTo] === 'string' &&
      this.activatedRoute.snapshot.queryParams[redirectUrl.returnTo] !== REDIRECT_TO_USERWAY_DASHBOARD_PARAM_VAL
    ) {
      return this.router
        .navigateByUrl(this.activatedRoute.snapshot.queryParams[redirectUrl.returnTo])
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    }

    return this.router
      .navigate([`/${Api.home}`], { replaceUrl: true })
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  private async redirectToAppOrExternalLink(): Promise<void> {
    if (this.activatedRoute.snapshot.queryParams[redirectUrl.returnTo] === REDIRECT_TO_USERWAY_DASHBOARD_PARAM_VAL) {
      await this.redirectToUserWayDashboard();
      return;
    }

    await this.redirectToApp().catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  private shouldAutoRedirectToTenant(response: IHttpErrorResponse): boolean {
    return (
      response.error?.app?.name === errorMessagesNames.ErrorLoginToTenantlessForbidden && response.error?.app?.params?.length >= 1
    );
  }

  private generateRedirectUrlForDomain(domain: string, baseUrl: string): string {
    const query: string = SharedCommonUtility.notNullish(this.activatedRoute.snapshot.queryParams[redirectUrl.returnTo])
      ? decodeURIComponent(this.activatedRoute.snapshot.queryParams[redirectUrl.returnTo])
      : '';
    return `https://${domain}.${baseUrl}${query}`;
  }

  private async errorAuthentication(response: IHttpErrorResponse): Promise<void> {
    this.form.enable();

    if (response.url === null) {
      this.authErrorMessage = this.translateService.instant('login_unable_to_authenticate');
      return;
    }

    if (this.shouldAutoRedirectToTenant(response)) {
      await this.appConfigService.fetchConfig();
      const redirectModalComponent: TenantRedirectModalComponent = this.modalService.open(TenantRedirectModalComponent).instance;
      redirectModalComponent.redirectUrls = (response.error.app.params[0] as string).split(',').map((domain: string) => {
        const trimmedDomain: string = domain.trim();
        return {
          url: this.generateRedirectUrlForDomain(trimmedDomain, this.appConfigService.getTenantBaseUrl()),
          domain: trimmedDomain,
        };
      });
      this.subscriptions.add(
        redirectModalComponent.container.onClose$
          .pipe(withLatestFrom(redirectModalComponent.selectedUrl$))
          .subscribe(([_, targetUrl]: [void, string]) => {
            CommonUtility.navigateToExternalUrl(targetUrl);
          }),
      );

      return;
    }

    if (CommonUtility.extractHTTPErrorName(response) === errorMessagesNames.ErrorUserEmailUnverified) {
      this.userEmailUnverifiedError = true;
      this.a11yService.setMessage(this.translateService.instant('verify_email_to_login'));
      return;
    }

    if (CommonUtility.extractHTTPErrorName(response) === errorMessagesNames.PasswordIsTooOld) {
      this.passwordIsTooOldError = true;
      this.a11yService.setMessage(this.translateService.instant('you_need_to_reset_your_password'));
      return;
    }

    const errorMessage: string = this.errorMessageService.getGlobalErrorResponse(response);

    if (typeof errorMessage === 'string' && response.status > 0) {
      this.authErrorMessage = errorMessage;
    } else {
      this.authErrorMessage = this.translateService.instant('login_unable_to_authenticate');
    }

    this.a11yService.setMessage(this.authErrorMessage);
  }

  private async onAuthenticationChange(authenticated: boolean): Promise<void> {
    this.isUserAuthenticated = authenticated;

    if (authenticated) {
      await this.redirectToAppOrExternalLink();
    }
  }

  private async onMfaRequiredChange(isMfaRequired: boolean): Promise<void> {
    this.isMfaRequired = isMfaRequired;
  }

  public setPasswordVisibility(visible: boolean): void {
    this.isPasswordShown = visible;
  }

  public onUserUnverifiedEmailBannerClose(): void {
    this.userEmailUnverifiedError = false;
  }

  public onSubmit(): void {
    this.formValidationRequest.next();
    this.authErrorMessage = '';

    if (this.form.valid === false) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }

    this.form.disable();

    this.subscriptions.add(
      this.userService.logIn(this.form.value.email, this.form.value.password).subscribe({
        error: this.errorAuthentication.bind(this),
      }),
    );
  }

  public isFieldValid(field: string): boolean {
    return this.formService.isFieldValid(field, this.form);
  }

  public onResendActivationLink(): void {
    const onSuccess = (): void => {
      const extras: NavigationExtras = {
        state: {
          isResendDone: true,
          hasResendFailed: false,
        },
      };

      this.router
        .navigate([`/${Api.activation_link}`], extras)
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    };

    const onError = (response: HttpErrorResponse): void => {
      const extras: NavigationExtras = {
        state: {
          isResendDone: false,
          hasResendFailed: true,
          errorMessage: response.error.message,
        },
      };

      this.router
        .navigate([`/${Api.activation_link}`], extras)
        .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
    };

    this.subscriptions.add(this.userService.resendVisitorActivationLink(this.form.value.email).subscribe(onSuccess, onError));
  }

  public async ngOnInit(): Promise<void> {
    if (this.appConfigService.isUsingExternalIdp()) {
      await this.redirectToAppOrExternalLink();
    }

    await this.userService.getAuthStatus();

    this.isMfaRequired = this.userService.isMfaRequired();
    this.isUserAuthenticated = this.userService.isAuthenticated();

    this.usePasswordRevealButton = CommonUtility.isCSSselectorSupported(IE_REVEAL_EYE_SELECTOR) === false;

    const isAuthenticatedSubscription: Subscription = this.userService.isAuthenticated$.pipe(distinctUntilChanged()).subscribe({
      next: this.onAuthenticationChange.bind(this),
    });

    const isMfaRequiredSubscription: Subscription = this.userService.isMfaRequired$.pipe(distinctUntilChanged()).subscribe({
      next: this.onMfaRequiredChange.bind(this),
    });

    this.subscriptions.add(isAuthenticatedSubscription);
    this.subscriptions.add(isMfaRequiredSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
