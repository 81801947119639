<div class="custom-values" [formGroup]="viewportForm">
  <h4 class="label" [attr.aria-required]="true">{{ 'custom_dimensions_label' | translate }}</h4>
  <div class="mt-2 align-items-center">
    <label for="customWidth">{{ 'width_label' | translate }}</label>
    <input
      type="number"
      id="customWidth"
      class="form-element"
      [name]="$scanViewport.width"
      [formControlName]="$scanViewport.width"
      [placeholder]="'width_label' | translate"
    />
    <span>{{ 'pixel_label' | translate }}</span>
  </div>
  <div class="mt-2 align-items-center">
    <label for="customHeight">{{ 'height_label' | translate }}</label>
    <input
      type="number"
      id="customHeight"
      class="form-element"
      [name]="$scanViewport.height"
      [formControlName]="$scanViewport.height"
      [placeholder]="'height_label' | translate"
    />
    <span>{{ 'pixel_label' | translate }}</span>
  </div>
</div>
