import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import {
  Event,
  initialize,
  Options,
  Result as HarnessFeatureFlagClient,
  Target as HarnessClientTarget,
  VariationValue,
} from '@harnessio/ff-javascript-client-sdk';
import { map as lodashMap } from 'lodash';

import { $user } from '../../../../shared/constants/user';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { SharedTextUtility } from '../../../../shared/utils/text.utility';
import { IUserServerResponse } from '../../../../shared/interfaces/user.interface';
import { UserContextAttributes } from '../../../../shared/interfaces/feature-flag.interface';
import { AppConfigService } from '../app-config.service';
import { UserService } from '../user.service';
import { $applicationRole } from '../../../../shared/constants/application-role.constants';
import { $workspaceRole } from '../../../../shared/constants/workspace-role.constants';
import { $securityGroup } from '../../../../shared/constants/security-group';
import { TenantService } from '../tenant.service';
import { ITenantInfoWithPackageInfo } from '../../../../shared/interfaces/tenant.interface';
import { $tenant } from '../../../../shared/constants/tenant';
import { environment } from '../../../environments/environment';
import { EnvironmentType, GlobalFeatureFlagDefaults } from '../../shared/constants';
import { E2eFeatureFlagMock } from '../../../../shared/constants/e2e-feature-flag.mock';

type FeatureFlagClientTarget = HarnessClientTarget & UserContextAttributes;
const LEVEL_ACCESS_PLATFORM_CLIENT = 'level-access-platform-client';
const OPTION_CONFIG: Options = {
  // Setting to true to display information logs (ie event streams, flags) in the console
  debug: false,
  streamEnabled: true,
};
const MAX_RETRIES: number = 3;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private clientInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(void 0);
  private client: HarnessFeatureFlagClient;
  private _flagsChange$: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);
  private _attempts: number;

  constructor(
    private appConfigService: AppConfigService,
    private userService: UserService,
    private tenantService: TenantService,
  ) {
    const userIsEqual = (userA: IUserServerResponse, userB: IUserServerResponse): boolean =>
      userA?.[$user._id] === userB?.[$user._id];

    this.subscriptions.add(
      this.userService.userDataChanged$
        .pipe(
          distinctUntilChanged(userIsEqual),
          switchMap(
            (user: IUserServerResponse): Observable<(IUserServerResponse | ITenantInfoWithPackageInfo)[]> =>
              (SharedCommonUtility.notNullishOrEmpty(user) && user[$user.currentTenantId]
                ? this.tenantService.getTenantFromTenantedScope(user[$user.currentTenantId])
                : of(null)
              ).pipe(
                map((tenant: ITenantInfoWithPackageInfo | null) => [user, tenant]),
                catchError(() => of([user, null])),
              ),
          ),
        )
        .subscribe(this.identify.bind(this)),
    );
  }

  /**
   * This method is responsible for identifying and initializing the client target context information.
   * Once identified, the client can listen for the different events triggered by the SDK.
   * If client is null, the method will attempt to reinitialize it accordingly.
   *
   * @param array user {IUserServerResponse} data to be mapped as the target attributes during client initialization.
   *              tenant {ITenantInfoWithPackageInfo} tenant data to be mapped as the target attributes during client initialization.
   * Targets identifier must be unique and required.
   * @private
   */
  private identify([user, tenant]: [IUserServerResponse, ITenantInfoWithPackageInfo]): void {
    const target: FeatureFlagClientTarget = SharedCommonUtility.notNullish(user)
      ? {
          identifier: `userId-${user[$user._id]}`,
          name: SharedCommonUtility.notNullish(user[$user.displayName])
            ? SharedTextUtility.convertToHarnessAcceptableFormat(user[$user.displayName])
            : '',
          anonymous: false,
          attributes: {
            service: LEVEL_ACCESS_PLATFORM_CLIENT,
            userId: user[$user._id],
            applicationRoles: lodashMap(user[$user.applicationRoles] ?? [], $applicationRole.name).join(','),
            workspaceRoles: lodashMap(user[$user.workspaceRoles] ?? [], $workspaceRole.name).join(','),
            email: user[$user.email],
            groups: lodashMap(user[$user.groups] ?? [], $securityGroup.name).join(','),
            tenantName: tenant?.[$tenant.name],
            tenantId: tenant?.[$tenant._id],
            packageId: tenant?.packageId,
            packageName: tenant?.packageName,
          },
        }
      : {
          identifier: 'anonymous',
          name: 'anonymous',
          anonymous: false,
          attributes: {
            service: LEVEL_ACCESS_PLATFORM_CLIENT,
          },
        };

    this._attempts = 0;
    this.initialize(target);
  }

  private initialize(target: FeatureFlagClientTarget): void {
    this.client = initialize(this.appConfigService.getHarnessSDKClientKey(), target, OPTION_CONFIG);

    // Event happens when connection to server is established
    // flags contains all evaluations against SDK key
    this.client.on(Event.READY, () => {
      this.clientInitialized$.next(true);
      this._flagsChange$.next();
      this._attempts = 0;

      // Event happens when a changed event is pushed.
      // flag contains information about the updated feature flag
      // Listen to CHANGED events when READY happens since the event happens when initializing FF values as well.
      this.client.on(Event.CHANGED, () => {
        this._flagsChange$.next();
      });
    });

    this.client.on(Event.ERROR_AUTH, (err: unknown) => {
      if (this._attempts >= MAX_RETRIES) {
        this.clientInitialized$.next(false);
        console.warn(
          `[FeatureFlagService.initialize] Unable to initialize after ${this._attempts} attempts. Switching to default flag values.`,
        );
        return;
      }

      const errorMessage: string = (err as ErrorEvent)?.error?.message ?? (err as TypeError)?.message;
      setTimeout(() => {
        this._attempts++;
        console.warn(`[FeatureFlagService.initialize] Client initialize error '${errorMessage}' retry ${this._attempts}`, err);
        this.initialize(target);
      }, 1000);
    });
  }

  /**
   * An observable method that evaluates and returns the flag's variation value.
   *
   * @param flag - String representing the flag value
   * @param defaultValue - Boolean representing default value for the flag variation.
   * Note: During a network connection error, the default value will be respected.
   * @returns an Observable variation value of boolean type
   */
  public variation$(flag: string, defaultValue?: boolean): Observable<VariationValue> {
    // Mock the Feature Flag response when the client is running under the e2e environment
    if (environment.environmentType === EnvironmentType.QA) {
      return of(SharedCommonUtility.notNullish(E2eFeatureFlagMock[flag]) ? E2eFeatureFlagMock[flag] : defaultValue ?? false);
    }

    if (SharedCommonUtility.isNullish(this.client)) {
      console.warn(
        `[FeatureFlagService.variation$] Client is not initialized. Returning flag: ${flag} with a default value of ${defaultValue}`,
      );
    }

    // eslint-disable-next-line no-param-reassign
    defaultValue = GlobalFeatureFlagDefaults[flag] ?? defaultValue;

    return combineLatest([this.clientInitialized$, this._flagsChange$]).pipe(
      shareReplay(),
      map(([clientInitialized, _]: [boolean, void]): boolean => {
        return clientInitialized;
      }),
      filter(SharedCommonUtility.notNullish),
      map((clientInitialized: boolean) => {
        if (!clientInitialized) {
          return defaultValue;
        }
        return this.client.variation(flag, defaultValue);
      }),
    );
  }

  /**
   * Evaluates and returns the flag's variation value.
   *
   * @param flag - String representing the flag value
   * @param defaultValue - Boolean representing default value for the flag variation.
   * Note: During a network connection error, the default value will be respected.
   * @returns variation value of boolean type
   */
  public variation(flag: string, defaultValue?: boolean): boolean {
    // Mock the Feature Flag response when the client is running under the e2e environment
    if (environment.environmentType === EnvironmentType.QA) {
      return SharedCommonUtility.notNullish(E2eFeatureFlagMock[flag]) ? E2eFeatureFlagMock[flag] : defaultValue ?? false;
    }

    // eslint-disable-next-line no-param-reassign
    defaultValue = GlobalFeatureFlagDefaults[flag] ?? defaultValue;

    if (!this.clientInitialized$.value) {
      return defaultValue;
    }

    return this.client.variation(flag, defaultValue) as boolean;
  }

  /**
   * This method is used to listen to any flag events in the stream.
   */
  public get flagsChange$(): Observable<void> {
    return this._flagsChange$.asObservable();
  }

  public get isClientInitialized$(): Observable<boolean> {
    return this.clientInitialized$.asObservable();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.client.off(); // remove event listeners
    this.client.close(); // close application
  }
}
