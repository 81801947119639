<div class="row w-100" [class.my-5]="config.iconId">
  <div class="col-12 d-flex flex-column justify-content-center align-items-center">
    <ds-icon
      *ngIf="config.dsIcon as iconConfig"
      [class]="{ 'ds-icon-default': iconConfig.hasDefaultDsClass, 'mt-4': true }"
      [icon]="iconConfig.icon"
      [iconStyle]="iconConfig.style"
      [scale]="iconConfig.scale"
      [style.color]="iconConfig.color"
      
    ></ds-icon>
    <svg
      *ngIf="config.iconId"
      [attr.height]="config.iconHeight === undefined ? '60' : config.iconHeight"
      [attr.width]="config.iconWidth === undefined ? '96' : config.iconWidth"
      [attr.fill]="config.iconFill === undefined ? 'black' : config.iconFill"
      aria-hidden="true"
    >
      <use [attr.xlink:href]="'#' + config.iconId"></use>
    </svg>
    <h3 class="mt-4 mb-2" *ngIf="config.title">{{ config.title }}</h3>
    <h3 class="mt-4 mb-2" *ngIf="!config.title && config.titleHtml" [innerHTML]="config.titleHtml"></h3>
    <p class="mx-4 subtitle pre-line" *ngIf="config.subtitle">{{ config.subtitle }}</p>
    @if (config.dsButton) {
      <button
        ds-button
        [variant]="config.dsButton.variant"
        [microActionIcon]="config.dsButton.icon"
        
        (click)="config.dsButton.onClick($event)"
      >
        {{ config.dsButton.label }}
      </button>
    } @else if (config.button) {
      <a class="btn btn-outline-primary" [routerLink]="config.button.routerLink" >
        {{ config.button.label }}
      </a>
    }
  </div>
</div>
