import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DsButtonVariants } from '@levelaccess/design-system';

@Component({
  selector: 'app-ds-confirmation-modal',
  templateUrl: './ds-confirmation-modal.component.html',
  styleUrls: ['./ds-confirmation-modal.component.scss'],
})
export class DsConfirmationModalComponent {
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;

  @Input() public title: string;
  @Input() public message: string;
  @Input() public description: string;
  @Input() public confirmLabel: string;
  @Output() public confirm: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected modal: NgbActiveModal) {}

  protected onCancel(): void {
    this.modal.dismiss();
  }

  protected onConfirm(): void {
    this.confirm.emit();
    this.modal.close();
  }
}
