import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Directive({
  selector: 'div.card',
})
// TODO: EAP-26271 deprecate this directive
export class NewNavCardDirective implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  private updateView(): void {
    const h1Element: HTMLElement = this.elementRef.nativeElement.querySelector('div.card-header h1');
    if (SharedCommonUtility.notNullish(h1Element)) {
      this.renderer.addClass(this.elementRef.nativeElement, 'new-nav-card');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'new-nav-card');
    }
  }

  public ngAfterViewInit(): void {
    this.updateView();
  }
}
