import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '../loader.service';
import { ApiHeaderOption } from '../../../../shared/constants/api';

@Injectable({
  providedIn: 'root',
})
export class RequestLoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get(ApiHeaderOption.skipLoader)?.toLowerCase() === 'true') {
      return next.handle(request);
    }
    this.loaderService.setKeyLoading(true, request.url);
    return next.handle(request).pipe(
      finalize(() => {
        this.loaderService.setKeyLoading(false, request.url);
      }),
    );
  }
}
