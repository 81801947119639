import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(private ngZone: NgZone) {}

  private async waitForElementOutsideOfNgZone(selector: string, target: Node, timeout: number): Promise<Element> {
    const action = (resolve: Function, reject: Function): void => {
      const element: Element = document.querySelector(selector);
      let stopChecking: boolean = false;

      if (element) {
        resolve(element);
        return;
      }

      const stopCheckingForElement = (): void => {
        stopChecking = true;
        window.clearTimeout(timeoutId);
      };

      const timeoutId: number = window.setTimeout(stopCheckingForElement, timeout);

      const processMutations: MutationCallback = (mutations: MutationRecord[], _observer: MutationObserver): void => {
        if (stopChecking) {
          _observer.disconnect();
          resolve(null);
          return;
        }

        const HtmlElement: HTMLElement | null = document.querySelector(selector);

        if (HtmlElement === null) {
          return;
        }

        stopCheckingForElement();
        _observer.disconnect();
        resolve(HtmlElement);
      };

      const observer: MutationObserver = new MutationObserver(processMutations);

      const observerOptions: MutationObserverInit = {
        childList: true,
        subtree: true,
      };

      observer.observe(document.documentElement, observerOptions);
    };

    return new Promise(action);
  }

  private async waitForRenderedElementOutsideOfNgZone(selector: string, target: Node, timeout: number): Promise<Element> {
    const action = (resolve: Function, reject: Function): void => {
      const element: HTMLElement = document.querySelector(selector);
      let loopTimeoutId: number;
      let stopChecking: boolean = false;

      if (element && element.offsetWidth > 0) {
        resolve(element);
        return;
      }

      const stopCheckingForElement = (): void => {
        stopChecking = true;
        window.clearTimeout(timeoutId);
      };

      const timeoutId: number = window.setTimeout(stopCheckingForElement, timeout);

      const checkRenderderState = (): void => {
        if (stopChecking) {
          resolve(null);
          return;
        }

        const HtmlElement: HTMLElement | null = document.querySelector(selector);

        if (HtmlElement && HtmlElement.offsetWidth > 0) {
          stopCheckingForElement();
          resolve(HtmlElement);
          window.clearTimeout(loopTimeoutId);
          return;
        }

        loopTimeoutId = window.setTimeout(checkRenderderState, 100);
      };

      loopTimeoutId = window.setTimeout(checkRenderderState, 100);
    };

    return new Promise(action);
  }

  // Note: unwrapping MutationObserver from ngZone. See issue and workaround https://github.com/angular/angular/issues/31712#issuecomment-346521

  public async waitForElement(
    selector: string,
    target: Node = document.documentElement,
    timeout: number = 5000,
  ): Promise<Element> {
    return this.ngZone.runOutsideAngular(async (): Promise<Element> => {
      return this.waitForElementOutsideOfNgZone(selector, target, timeout);
    });
  }

  public async waitUntilRenderedElement(
    selector: string,
    target: Node = document.documentElement,
    timeout: number = 5000,
  ): Promise<Element> {
    return this.ngZone.runOutsideAngular(async (): Promise<Element> => {
      return this.waitForRenderedElementOutsideOfNgZone(selector, target, timeout);
    });
  }
}
