import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';

import { ModalContainerComponent } from '../modals/modal-container.component';
import { IModal } from '../modals/modal.interface';
import { CustomValidators } from '../../services/helpers/form-custom-validators';
import { TranslateService } from '../../translate/translate.service';
import { CommonConfirmationModalOptions } from '../../interfaces/modal-dialog.interface';

@Component({
  templateUrl: './common-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./common-confirmation.component.scss'],
})
export class CommonConfirmationComponent implements IModal {
  private header: string;
  private messages: string[];
  private description: string;
  private labelAction: string;
  private labelClose: string;
  private shouldTranslate: boolean;
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;
  public confirmed: boolean;
  public form: UntypedFormGroup;
  public formValidationRequest: Subject<void>;
  public confirmationLabel: string;
  public actionPlaceHolder: string;
  public isValidationNeeded: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.formValidationRequest = new Subject();
    this.form = this.formBuilder.group({
      confirmation: this.formBuilder.control(''),
    });
    this.confirmed = false;
  }

  private confirmAndClose(): void {
    this.confirmed = true;
    this.close();
  }

  private translateText(text: string): string {
    if (this.shouldTranslate) {
      return this.translateService.instant(text);
    }

    return text;
  }

  public onCreate({
    header,
    messages,
    confirmationLabel,
    actionPlaceholder,
    labelAction,
    labelClose,
    description,
    shouldTranslate,
  }: CommonConfirmationModalOptions): void {
    this.isValidationNeeded = Boolean(actionPlaceholder);

    this.header = header;
    this.messages = messages;
    this.description = description;
    this.confirmationLabel = confirmationLabel;
    this.actionPlaceHolder = actionPlaceholder;
    this.labelAction = labelAction;
    this.labelClose = labelClose || 'label_close';
    this.shouldTranslate = shouldTranslate ?? true;
    const wordToType: string = this.translateText(this.actionPlaceHolder);
    this.form
      .get('confirmation')
      .setValidators([CustomValidators.required, CustomValidators.validateIsEmpty, CustomValidators.in(new Set([wordToType]))]);
    this.changeDetectorRef.detectChanges();
  }

  public get confirmationHeader(): string {
    return this.translateText(this.header);
  }

  public get confirmationMessage(): string {
    return this.messages.map((message: string) => this.translateText(message)).join(' ');
  }

  public get confirmationDescription(): string {
    return this.translateText(this.description);
  }

  public get confirmationLabelAction(): string {
    return this.translateText(this.labelAction);
  }

  public get confirmationLabelClose(): string {
    return this.translateText(this.labelClose);
  }

  public close(): void {
    this.container.closeModal();
  }

  public onConfirm(): void {
    if (!this.isValidationNeeded) {
      this.confirmAndClose();
      return;
    }

    this.formValidationRequest.next();
    if (this.form.invalid) {
      return;
    }
    this.confirmAndClose();
  }
}
