<div class="alert alert-info-note margin-bottom d-flex" role="alert" aria-live="polite">
  <div class="me-2">
    <svg class="alert-info-icon" viewBox="0 0 16 16" aria-hidden="true">
      <use xlink:href="#info-circle-fill"></use>
    </svg>
  </div>
  <div class="ms-2">
    <div #ref><ng-content></ng-content></div>
    <div *ngIf="!ref.children.length" class="alert-info-note-text" [innerHTML]="noteText"></div>
  </div>
</div>
