import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized, Data, Event } from '@angular/router';
import { Subscription, Observable, of, ReplaySubject, Subject, ObservableInput } from 'rxjs';
import { filter, map, mergeMap, pairwise, skip, takeUntil } from 'rxjs/operators';

import { TranslateService } from '../translate/translate.service';
import { A11yService } from './a11y.service';
import { BusMessageChannels, BusMessageService } from './bus-message.service';
import { Api } from '../../../shared/constants/api';
import { TranslateArgs } from '../resolvers/feature-flag-translation.resolver';

@Injectable({ providedIn: 'root' })
export class RoutingService implements OnDestroy {
  private subscriptions: Subscription;
  private routeChanged$: Subject<void>;
  private pageTitleSnapshot: string;
  private pageDescriptionSnapshot: string;

  public readonly pageTitle$: ReplaySubject<string>;
  public previousRoutePath: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private a11yService: A11yService,
    private busMessageService: BusMessageService,
  ) {
    const changeRouterSubscription: Subscription = this.subscribeToRouterChange();
    const subscribeToTrackRouterPathSubscription: Subscription = this.subscribeToTrackRouterPath();

    this.subscriptions = new Subscription();
    this.subscriptions.add(changeRouterSubscription);
    this.subscriptions.add(subscribeToTrackRouterPathSubscription);

    this.routeChanged$ = new Subject<void>();
    this.pageTitle$ = new ReplaySubject<string>(1);
  }

  private notifyScreenReader(pageTitle: string): void {
    const message = this.translateService.instant('new_page_loaded', pageTitle);
    this.a11yService.setMessage(message);
  }

  private onRoutingChange = (routeData: Data): void => {
    this.routeChanged$.next();

    this.subscriptions.add(
      this.resolvePageTitle(routeData.title)
        .pipe(takeUntil(this.routeChanged$))
        .subscribe((pageTitle: string) => {
          this.pageTitleSnapshot = pageTitle;
          this.pageTitle$.next(pageTitle);
          this.notifyScreenReader(pageTitle);
        }),
    );

    this.subscriptions.add(
      this.resolvePageDescription(routeData.description)
        .pipe(takeUntil(this.routeChanged$))
        .subscribe((pageDescription: string) => {
          this.pageDescriptionSnapshot = pageDescription;
        }),
    );
  };

  private subscribeToRouterChange(): Subscription {
    const filterRoutes = (event: Event): event is NavigationEnd => {
      return event instanceof NavigationEnd;
    };

    const getActivatedRoute: any = (): ActivatedRoute => {
      return this.activatedRoute;
    };

    const findFirstChild: any = (route: ActivatedRoute): any => {
      let _route: ActivatedRoute = route;

      while (_route.firstChild) {
        _route = _route.firstChild;
      }

      return _route;
    };

    const getOnlyPrimary = (route: ActivatedRoute): boolean => {
      return route.outlet === 'primary';
    };

    const getRouteData = (route: ActivatedRoute): ObservableInput<Data> => {
      return route.data;
    };

    const subscription: Subscription = this.router.events
      .pipe(filter(filterRoutes), map(getActivatedRoute), map(findFirstChild), filter(getOnlyPrimary), mergeMap(getRouteData))
      .subscribe(this.onRoutingChange.bind(this));

    return subscription;
  }

  private subscribeToTrackRouterPath(): Subscription {
    return this.router.events
      .pipe(
        filter((e: Event): e is RoutesRecognized => {
          return e instanceof RoutesRecognized;
        }),
        pairwise(),
      )
      .subscribe((event: [RoutesRecognized, RoutesRecognized]) => {
        this.previousRoutePath = (event[0] as RoutesRecognized).urlAfterRedirects;
      });
  }

  public resolvePageTitle(args: TranslateArgs): Observable<string> {
    return of(this.translateService.instant.apply(this.translateService, args));
  }

  public resolvePageDescription(args: TranslateArgs): Observable<string> {
    return of(this.translateService.instant.apply(this.translateService, args));
  }

  public getPageTitle(): string {
    return this.pageTitleSnapshot ?? '';
  }

  public getPageDescription(): string {
    return this.pageDescriptionSnapshot ?? '';
  }

  public navigateOnPropertyChange(route: string[] = ['/', Api.dashboard]): Subscription {
    return this.busMessageService
      .from(BusMessageChannels.userSwitchedToDigitalProperty)
      .pipe(
        skip(1),
        mergeMap(async () => this.router.navigate(route)),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
