import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DsButtonVariants, DsModalRef, DsModalService, DsModalVariant, Icons, IconStyles } from '@levelaccess/design-system';

import { NavService } from '../../../services/navigation/nav.service';
import { INavMenuItem, NavigationItem } from '../../../services/navigation/nav-structure';
import { MobileSecondNavbarMenuModalComponent } from './mobile-second-navbar-menu-modal/mobile-second-navbar-menu-modal.component';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { AiChatComponent } from '../../ai-chat/ai-chat.component';
import { RequiredSecurities } from '../../../../../shared/constants/required-securities';
import { FeatureFlagCollection } from '../../../../../shared/interfaces/feature-flag.interface';

export interface NavLink {
  id?: NavigationItem;
  routerLink?: string;
  externalLink?: string;
  label: string;
  isActive: boolean;
  hasChildren: boolean;
  isHidden?: boolean;
  isHiddenOnCurrentScreenSize?: boolean;
  iconName?: Icons;
}

@Component({
  selector: 'compound-second-level-navbar',
  templateUrl: './compound-second-level-navbar.component.html',
  styleUrls: ['./compound-second-level-navbar.component.scss'],
})
export class CompoundSecondLevelNavbarComponent implements OnInit {
  public childrenRoutes$: Observable<NavLink[]>;
  public parentMenuItem$: Observable<INavMenuItem>;
  public DsButtonVariants: typeof DsButtonVariants;
  public Icons: typeof Icons;
  protected readonly IconStyles: typeof IconStyles = IconStyles;
  protected readonly RequiredSecurities: typeof RequiredSecurities = RequiredSecurities;
  protected readonly FeatureFlagCollection: typeof FeatureFlagCollection = FeatureFlagCollection;

  @Input() public showMobileNavVersion: boolean;

  constructor(
    private navService: NavService,
    private dsModalService: DsModalService,
  ) {
    this.DsButtonVariants = DsButtonVariants;
    this.Icons = Icons;
  }

  private handleActiveTopLevelMenuItemChanges(activeMenuItem?: INavMenuItem): NavLink[] {
    const children: INavMenuItem[] = activeMenuItem?.children ?? [];

    return children
      .filter((child: INavMenuItem): boolean => child.isAvailable)
      .map(
        (child: INavMenuItem): NavLink => ({
          routerLink: child.routerLink,
          externalLink: child.externalLink,
          label: child.label,
          isActive: child.isActive,
          hasChildren: child.children?.length > 0,
        }),
      );
  }

  public openNavModal(menuItem: INavMenuItem): void {
    const modalRef: DsModalRef | null = this.dsModalService.open(MobileSecondNavbarMenuModalComponent, DsModalVariant.fullScreen);

    if (SharedCommonUtility.notNullish(modalRef)) {
      const navModal: MobileSecondNavbarMenuModalComponent = modalRef.componentInstance;
      navModal.parentMenuItem = menuItem;
    }
  }

  public openAiChatModal(): void {
    if (this.dsModalService.modalCount > 0) {
      return;
    }
    this.dsModalService.open(AiChatComponent, DsModalVariant.slideOut);
  }

  ngOnInit(): void {
    this.parentMenuItem$ = this.navService.activeSecondLevelMenuItem$().pipe(
      map((activeMenuItem: INavMenuItem): INavMenuItem => {
        return activeMenuItem?.id !== NavigationItem.websites_and_apps ? activeMenuItem?.parent : activeMenuItem;
      }),
    );
    this.childrenRoutes$ = this.navService.activeTopLevelMenuItem$().pipe(map(this.handleActiveTopLevelMenuItemChanges));
  }
}
