import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IIssueStatusHistory } from '../../../shared/interfaces/issue-status-history.interface';
import { FlawService } from './flaw.service';
import { ManualAuditIssueService } from './manual-audit-issue.service';
import { IFlawStatusUserHistory } from '../../../shared/interfaces/flaw.interface';
import { issueStatusHistory } from '../../../shared/constants/issue-status-history';
import { IRuleAuditHistoryManualLineItem } from '../../../shared/interfaces/rule-audit-history.interface';
import { $ruleAuditHistory, $ruleAuditHistoryExtra } from '../../../shared/constants/rule-audit-history';
import { $user, accountStatus } from '../../../shared/constants/user';
import { IManualAuditHistoryRequest } from '../interfaces/manual-evaluation.interface';

@Injectable({
  providedIn: 'root',
})
export class IssueStatusHistoryService {
  constructor(
    private flawService: FlawService,
    private manualAuditIssueService: ManualAuditIssueService,
  ) {}

  private getHistoryFromFlaw(flawId: string): Observable<IIssueStatusHistory[]> {
    return this.flawService.getHistory(flawId).pipe(
      map((flawStatusUserHistories: IFlawStatusUserHistory[]): IIssueStatusHistory[] => {
        return flawStatusUserHistories.map((statusHistory: IFlawStatusUserHistory): IIssueStatusHistory => {
          return {
            comment: statusHistory.comment,
            date: statusHistory.date,
            status: issueStatusHistory[statusHistory.status],
            userId: statusHistory.user,
            userDisabled: statusHistory.userDisabled,
          };
        });
      }),
    );
  }

  private getHistoryFromRuleAuditHistory(manualAuditRequest: IManualAuditHistoryRequest): Observable<IIssueStatusHistory[]> {
    return this.manualAuditIssueService.getRuleAuditHistory(manualAuditRequest).pipe(
      map((ruleAuditHistory: IRuleAuditHistoryManualLineItem[]): IIssueStatusHistory[] => {
        return ruleAuditHistory.flatMap((history: IRuleAuditHistoryManualLineItem): IIssueStatusHistory => {
          return {
            comment: history[$ruleAuditHistory.reason],
            date: new Date(history[$ruleAuditHistory.createdAt]),
            status: issueStatusHistory[history[$ruleAuditHistory.extra][$ruleAuditHistoryExtra.to]],
            userId: history[$ruleAuditHistory.user][$user.displayName],
            userDisabled: history[$ruleAuditHistory.user][$user.accountStatus] !== accountStatus.active,
          };
        });
      }),
    );
  }

  public getStatusUpdateHistory(source: string | IManualAuditHistoryRequest): Observable<IIssueStatusHistory[]> {
    if (typeof source === 'string') {
      return this.getHistoryFromFlaw(source);
    }

    return this.getHistoryFromRuleAuditHistory(source);
  }
}
