import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthGuard as ExternalAuthGuard, AuthService as ExternalAuthService } from '@auth0/auth0-angular';
import { mergeMap } from 'rxjs/operators';

import { AppConfigService } from '../../app-config.service';
import { Api } from '../../../../../shared/constants/api';
import { UserService } from '../../user.service';
import { SUCCESS } from '../../../pages/public/logout/logout.constants';

@Injectable()
abstract class RedirectSsoTenantGuard {
  protected abstract redirect(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean | UrlTree>;

  public constructor(
    protected router: Router,
    protected appConfigService: AppConfigService,
    protected externalAuthService: ExternalAuthService,
    protected userService: UserService,
    protected externalAuthGuard: ExternalAuthGuard,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const ssoEnabled: boolean = this.appConfigService.isUsingExternalIdp();
    if (ssoEnabled) {
      return this.redirect(next, state);
    }
    return of(!ssoEnabled);
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(next, state);
  }
}

@Injectable()
export class RedirectSsoTenantToDashboardGuard extends RedirectSsoTenantGuard {
  constructor(
    protected router: Router,
    protected appConfigService: AppConfigService,
    protected externalAuthService: ExternalAuthService,
    protected userService: UserService,
    protected externalAuthGuard: ExternalAuthGuard,
  ) {
    super(router, appConfigService, externalAuthService, userService, externalAuthGuard);
  }

  protected redirect(): Observable<boolean | UrlTree> {
    return of(this.router.createUrlTree(['/', `${Api.dashboard}`]));
  }
}

@Injectable()
export class RedirectSsoTenantToAuth0LogoutGuard extends RedirectSsoTenantGuard {
  constructor(
    protected router: Router,
    protected appConfigService: AppConfigService,
    protected externalAuthService: ExternalAuthService,
    protected userService: UserService,
    protected externalAuthGuard: ExternalAuthGuard,
  ) {
    super(router, appConfigService, externalAuthService, userService, externalAuthGuard);
  }

  protected redirect(): Observable<boolean | UrlTree> {
    if (this.userService.isAuthenticated()) {
      this.externalAuthService.logout({
        logoutParams: { returnTo: `${window.location.origin}/${Api.logout}?${Api.success}=${SUCCESS}` },
      });
      this.userService.postLogOut();
      return of(false);
    }
    return of(true);
  }
}

@Injectable()
export class RedirectSsoTenantToSignupGuard extends RedirectSsoTenantGuard {
  constructor(
    protected router: Router,
    protected appConfigService: AppConfigService,
    protected externalAuthService: ExternalAuthService,
    protected userService: UserService,
    protected externalAuthGuard: ExternalAuthGuard,
  ) {
    super(router, appConfigService, externalAuthService, userService, externalAuthGuard);
  }

  protected redirect(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.externalAuthGuard.canActivate(next, state).pipe(
      mergeMap((canActivate: boolean): Observable<boolean> => {
        if (canActivate) {
          this.externalAuthService.getAccessTokenSilently({ cacheMode: 'on' });
          return of(true);
        }
        return of(false);
      }),
    );
  }
}
