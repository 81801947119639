<form [id]="domId" [formGroup]="form" (change)="onFormChange()">
  <div [attr.role]="isInMenu ? 'menu' : ''">
    <label [for]="domId" class="fw-bold margin-bottom-sm">
      {{ label | translate }}
    </label>
    <div class="row margin-bottom">
      <div class="col-md-12">
        <button
          class="btn btn-link text-underline fw-bold no-padding margin-right-md"
          [disabled]="form.disabled"
          type="button"
          [attr.role]="isInMenu ? 'menuitem' : ''"
          (click)="onFormChange($checkboxSelect.selectAll)"
        >
          {{ 'label_select_all' | translate }}
        </button>
        <button
          class="btn btn-link text-underline fw-bold no-padding"
          [disabled]="form.disabled"
          type="button"
          [attr.role]="isInMenu ? 'menuitem' : ''"
          (click)="onFormChange($checkboxSelect.unselectAll)"
        >
          {{ 'label_unselect_all' | translate }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex flex-column">
        <ul>
          <li *ngFor="let option of options; let i = index" [attr.role]="isInMenu ? 'menuitemcheckbox' : ''">
            <label class="option" [class.disabled]="form.disabled" formArrayName="options">
              <input type="checkbox" [formControlName]="i" />
              {{ option.translateLabel === false ? option.label : (option.label | translate) }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>
