import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { TranslatePipe } from '../translate/translate.pipe';

const pipes: Type<any>[] = [TranslatePipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
})
export class CommonPipesModule {}
