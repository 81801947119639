<app-modal-container [displayFooter]="false">
  <span class="modal-header-content">
    {{ 'colour_contrast_finder' | translate }}
  </span>

  <div class="modal-body-content">
    <p class="text-black">{{ 'color_contrast_description_label' | translate }}</p>

    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4">
        <h3 class="section-heading">{{ 'current_values' | translate }}</h3>
        <div class="d-flex">
          <span class="color-sample" [ngStyle]="{ 'background-color': contrastColor }"></span>
          <div>
            <h4 class="m-0">{{ 'foreground' | translate }}</h4>
            <p>{{ contrastColor }}</p>
          </div>
        </div>
        <div class="row m-0">
          <span class="color-sample border-black-rounded" [ngStyle]="{ 'background-color': contrastBackground }"></span>
          <div>
            <h4 class="m-0">{{ 'background' | translate }}</h4>
            <p>{{ contrastBackground }}</p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-8">
        <h3 class="section-heading">{{ 'sample' | translate }}</h3>
        <div class="p-4 sample-block border-black-rounded">
          <div [ngStyle]="{ color: contrastColor, 'background-color': contrastBackground }">
            <p class="header-sample">This is a header and <span class="fw-bold">bold text</span></p>
          </div>
          <div class="row m-0">
            <div class="col-6 p-0">
              <p>
                <span class="fw-bold text-black">{{ 'ratio' | translate }}:</span> {{ this.ratio }}:1
              </p>
            </div>
          </div>

          <div class="d-flex margin-top-only-md">
            <p [ngStyle]="{ color: contrastColor, 'background-color': contrastBackground }" class="paragraph-sample">
              This is a paragraph with <span class="fw-bold">some bold text</span> to test out what it will look like. This is a
              paragraph with <span class="fw-bold">some bold text</span> to test out what it would look like.
            </p>
          </div>
          <div class="row m-0">
            <div class="col-6 p-0">
              <p>
                <span class="fw-bold text-black">{{ 'ratio' | translate }}:</span> {{ this.ratio }}:1
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 margin-top-lg">
        <h3 class="section-heading">{{ 'would_you_like_other_colour_suggestions' | translate }}?</h3>
      </div>
      <div class="col-12">
        <p class="text-black">{{ 'change_colour_contrast_options_label' | translate }}</p>
      </div>
    </div>

    <div *ngIf="contrastBackground === contrastColor" class="row margin-bottom">
      <div class="col-md-12">
        <div class="alert alert-warning">
          {{ 'cannot_find_color_contrast_suggestions_both_colors_are_the_same' | translate }}
        </div>
      </div>
    </div>

    <div *ngIf="contrastBackground === 'transparent' || contrastColor === 'transparent'" class="row margin-bottom">
      <div class="col-md-12">
        <div class="alert alert-warning">
          {{ 'cannot_find_color_contrast_suggestions_one_color_is_transparent' | translate }}
        </div>
      </div>
    </div>

    <form [formGroup]="colorContrastForm" (ngSubmit)="findClosestColorsThatPassesContrastRatio()">
      <div class="row">
        <div class="col-xs-6 col-md-3 padding-top-sm">
          <h4 class="m-0">{{ 'contrast_ratio' | translate }}</h4>
          <label for="ratio" class="visuallyhidden">{{ 'ratio' | translate }}</label>
          <select class="full-width margin-top-xs" id="ratio" name="ratio" [formControlName]="componentEnum.ratio">
            <ng-container *ngFor="let ratio of ratioOptions">
              <option [ngValue]="ratio">{{ ratio }}</option>
            </ng-container>
          </select>
        </div>
        <div class="col-xs-6 col-md-3 padding-top-sm">
          <h4 class="m-0">{{ 'colour_to_edit' | translate }}</h4>
          <label [for]="componentEnum.changeTextType" class="visuallyhidden">{{ 'ratio' | translate }}</label>
          <select
            [id]="componentEnum.changeTextType"
            class="full-width margin-top-xs"
            name="colourToEdit"
            [formControlName]="componentEnum.changeTextType"
          >
            <option [ngValue]="componentEnum.foreground">{{ 'foreground' | translate }}</option>
            <option [ngValue]="componentEnum.background">{{ 'background' | translate }}</option>
          </select>
        </div>
        <div class="col-xs-12 col-md-6">
          <button type="submit" class="btn btn-primary apply-contrast-button">{{ 'apply' | translate }}</button>
        </div>
      </div>
    </form>

    <ng-container *ngIf="searchedAtLeastOnce">
      <div class="d-flex">
        <h3 class="margin-top-lg" role="alert" aria-live="polite">
          {{ 'new_contrast_options' | translate }}
          <ng-container *ngIf="results.length > 0">({{ results.length }})</ng-container>
        </h3>
      </div>

      <div class="row m-0 table-row">
        <table *ngIf="results.length > 0; else noResults" class="table-headed table-borders">
          <caption class="visuallyhidden">
            {{
              'color_contrast_rule_suggestions' | translate
            }}
          </caption>
          <thead>
            <tr>
              <th scope="col">{{ 'foreground' | translate }}</th>
              <th scope="col">{{ 'background' | translate }}</th>
              <th scope="col">{{ 'ratio' | translate }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of results">
              <td class="text-center">
                <span
                  class="color-sample sample-small"
                  [ngStyle]="{
                    'background-color': resultsGeneratedFor === componentEnum.foreground ? RGBtoHEX(result.color) : contrastColor,
                  }"
                ></span>
                <p class="m-0 inline">
                  {{ resultsGeneratedFor === componentEnum.foreground ? RGBtoHEX(result.color) : contrastColor }}
                </p>
              </td>
              <td class="text-center">
                <span
                  class="color-sample sample-small border-black-rounded"
                  [ngStyle]="{
                    'background-color':
                      resultsGeneratedFor === componentEnum.background ? RGBtoHEX(result.color) : contrastBackground,
                  }"
                ></span>
                <p class="m-0 inline">
                  {{ resultsGeneratedFor === componentEnum.background ? RGBtoHEX(result.color) : contrastBackground }}
                </p>
              </td>
              <td>{{ result.contrast }}</td>
              <td class="sample-column" [ngStyle]="tableSampleColumnStyles(result)">
                <h4 [ngStyle]="tableSampleColumnTextStyles(result)" class="big-heading">
                  This is a header and <span class="fw-bold">bold text.</span>
                </h4>
                <p [ngStyle]="tableSampleColumnTextStyles(result)">
                  This is a paragraph and <span class="fw-bold">bold text.</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-template #noResults>
        <p *ngIf="results.length === 0">{{ 'no_results_available' | translate }}</p>
      </ng-template>
    </ng-container>
  </div>
</app-modal-container>
