import { Injectable } from '@angular/core';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadingStylesService {
  constructor() {}

  public async loadAppStyles(): Promise<void> {
    const lazyLoadStyles = (resolve: Function): void => {
      const links: Element[] = Array.from(document.querySelectorAll('link[rel="stylesheet"]'));

      const initaliseLoadCss = (link: HTMLLinkElement): void => {
        if (typeof link.dataset.href === 'string') {
          const loadCss = (): void => {
            link.href = link.dataset.href;
          };

          if (SharedCommonUtility.isNativeMethod(window.requestAnimationFrame)) {
            window.requestAnimationFrame(loadCss);
          } else {
            loadCss();
          }
        }
      };

      links.forEach(initaliseLoadCss);
      resolve();
    };

    return new Promise(lazyLoadStyles);
  }
}
