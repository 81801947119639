import { Component, Input, ViewChild } from '@angular/core';

import { IModal } from '../modals/modal.interface';
import { ModalContainerComponent } from '../modals/modal-container.component';
import { ModalService } from '../../services/modal.service';
import { IModalDialogInputData } from '../../interfaces/modal-dialog.interface';
import { ClipboardUtilityService } from '../../services/clipboard-utility.service';

@Component({
  selector: 'app-share-issue-report-modal',
  templateUrl: './share-issue-report-modal.component.html',
  styleUrls: ['./share-issue-report-modal.component.scss'],
})
export class ShareIssueReportModalComponent implements IModal {
  @Input() public modalInputData: IModalDialogInputData<{ url: string }>;
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;

  constructor(
    private modalService: ModalService,
    private clipboardUtilityService: ClipboardUtilityService,
  ) {}

  public copyToClipboard(textToCopy: string): void {
    this.clipboardUtilityService.copyTextToClipboard(textToCopy);
  }

  public close(): void {
    this.modalService.closeModal();
  }
}
