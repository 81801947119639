import { Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { IDigitalPropertyListItem } from '../../../../shared/interfaces/digital-property.interface';
import { $digitalProperty } from '../../../../shared/constants/digital-properties';
import { UserService } from '../../services/user.service';
import { ScanTagService } from '../../services/scan-tag.service';
import { IScanTag, IScanTagServerResponse } from '../../../../shared/interfaces/scan-tag.interface';
import { $scanTag } from '../../../../shared/constants/scan-tag';
import { TranslateService } from '../../translate/translate.service';
import { LoadErrorHandler } from '../../services/load-error-handler';
import { ScanTagUtility } from '../../../../shared/utils/scan-tag.utility';

@Component({
  selector: 'app-scan-tag-filter',
  templateUrl: './scan-tag-filter.component.html',
  styleUrls: ['./scan-tag-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanTagFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription;

  @ViewChild('searchField')
  private searchField: ElementRef;

  public selectedScanTagId: string;
  public digitalPropertyScanTags: IScanTag[];
  public filteredValues: IScanTag[];
  public filterText: string;
  public $scanTag: typeof $scanTag;

  @Output()
  public onTagSelected: EventEmitter<IScanTag>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private scanTagService: ScanTagService,
    private translateService: TranslateService,
    private loadErrorHandler: LoadErrorHandler,
  ) {
    this.subscriptions = new Subscription();
    this.digitalPropertyScanTags = [];
    this.filteredValues = [];
    this.filterText = '';
    this.onTagSelected = new EventEmitter<IScanTag>();
  }

  private onGetDigitalPropertySuccess(digitalProperty: IDigitalPropertyListItem): void {
    this.subscriptions.add(
      this.scanTagService
        .getScanTagsFromDigitalProperty(digitalProperty[$digitalProperty.workspace]._id, digitalProperty[$digitalProperty._id])
        .subscribe({
          next: this.onGetScanTagsSuccess.bind(this),
          error: this.onGetScanTagsError.bind(this),
        }),
    );
  }

  private handleError(message: string, response: HttpErrorResponse, words?: string | string[] | number | number[]): void {
    this.loadErrorHandler.handleErrorWithCustomAppMessage(
      message,
      this.translateService.instant(message, words),
      response,
      ScanTagFilterComponent.name,
    );
  }

  private onGetScanTagsError(errorResponse: HttpErrorResponse): void {
    this.handleError('error_getting_scan_tags', errorResponse);
  }

  private onGetDigitalPropertyError(errorResponse: HttpErrorResponse): void {
    this.handleError('error_getting_digital_property', errorResponse);
  }

  private onGetScanTagsSuccess(scanTagResponse: IScanTagServerResponse): void {
    this.digitalPropertyScanTags = scanTagResponse.tags.filter(
      (scanTag: IScanTag): boolean => !ScanTagUtility.isReservedSystemTag(scanTag[$scanTag.tagName]),
    );

    this.applyFilter();
  }

  public select(scanTag: IScanTag): void {
    if (scanTag === null || scanTag[$scanTag._id] === this.selectedScanTagId) {
      return;
    }
    this.selectedScanTagId = scanTag[$scanTag._id];
    this.onTagSelected.emit(scanTag);
    this.changeDetectorRef.detectChanges();
  }

  public applyFilter(): void {
    this.filteredValues = [];

    const searchString: string = this.filterText.trim().toLowerCase();

    if (searchString.length === 0) {
      this.filteredValues = [...this.digitalPropertyScanTags];
      this.changeDetectorRef.detectChanges();
      return;
    }

    for (const scanTag of this.digitalPropertyScanTags) {
      if (scanTag[$scanTag.tagName].toLowerCase().includes(searchString)) {
        this.filteredValues.push(scanTag);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.userService.currentDigitalProperty$.subscribe({
        next: this.onGetDigitalPropertySuccess.bind(this),
        error: this.onGetDigitalPropertyError.bind(this),
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.searchField.nativeElement.focus();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
