import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';

export function getQueryParams(url: string): Record<string, string | string[]> {
  const [, queryString] = url.split('?');
  const httpParams: HttpParams = new HttpParams({ fromString: queryString });

  const paramsObject: Record<string, string | string[]> = {};
  for (const key of httpParams.keys()) {
    const allValues: string[] = httpParams.getAll(key);
    paramsObject[key] = allValues.length > 1 ? allValues : allValues[0];
  }
  return paramsObject;
}

/**
 * merges extra params into passed url
 *
 * @param url original url
 * @param extraParams query params to be merged into original url
 * @returns a tuple [urlWithoutParams, params]
 */
export function mergeUrlParams(url: string, extraParams: Params): [string, Record<string, string | string[]>, string] {
  let [urlWithoutParams] = url.split('?');

  let fragment: string = null;

  if (urlWithoutParams.indexOf('#') > 0) {
    [urlWithoutParams, fragment] = urlWithoutParams.split('#');
  }

  const paramsObject: Record<string, string | string[]> = getQueryParams(url);
  const queryParams: Record<string, string | string[]> = {
    ...paramsObject,
    ...extraParams,
  };

  return [urlWithoutParams, queryParams, fragment];
}
