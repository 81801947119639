import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../translate/translate.service';
import { Api } from '../../../../../shared/constants/api';
import { RoutingService } from '../../../services/routing.service';
import { AppConfigService } from '../../../services/app-config.service';
import { SUCCESS } from './logout.constants';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  public logoutMessage: string;
  public Api: typeof Api;
  public pageTitle: string;
  public isUserAuthenticated: boolean;

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private routingService: RoutingService,
    private appConfigService: AppConfigService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.subscriptions = new Subscription();
    this.logoutMessage = '';
    this.Api = Api;
    this.pageTitle = '';
  }

  private onLogoutSuccess(): void {
    this.isUserAuthenticated = this.userService.isAuthenticated();
    this.logoutMessage = this.translateService.instant('log_out_success');
  }

  private onLogoutError(httpErrorResponse: HttpErrorResponse): void {
    console.error('[LogoutComponent.onErrorLogout]', httpErrorResponse);

    this.logoutMessage = this.translateService.instant('log_out_cannot_be_completed', [
      String(httpErrorResponse.status),
      httpErrorResponse.message,
    ]);
  }

  private fakeSuccessMessageForAuth0Redirect(): void {
    const params: Params = this.activatedRoute.snapshot.queryParams;
    const success: boolean = params[Api.success] === SUCCESS;
    if (this.appConfigService.isUsingExternalIdp() && success) {
      this.logoutMessage = this.translateService.instant('log_out_success');
    }
  }

  public ngOnInit(): void {
    this.isUserAuthenticated = this.userService.isAuthenticated();

    if (this.isUserAuthenticated) {
      this.logoutMessage = this.translateService.instant('log_out_in_progress');

      const onLogOutSubscription = this.userService.logOut().subscribe({
        next: this.onLogoutSuccess.bind(this),
        error: this.onLogoutError.bind(this),
      });

      this.subscriptions.add(onLogOutSubscription);
    }
    this.pageTitle = this.routingService.getPageTitle();
    this.fakeSuccessMessageForAuth0Redirect();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
