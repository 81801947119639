<app-modal-container>
  <span class="modal-header-content">
    {{ confirmationHeader }}
  </span>

  <div class="modal-body-content d-flex flex-column" [formGroup]="form">
    <p class="body-content-heading">
      {{ confirmationMessage }}
    </p>

    <ng-container *ngIf="confirmationDescription">
      <span class="description">
        {{ confirmationDescription }}
      </span>
    </ng-container>

    <ng-container *ngIf="isValidationNeeded">
      <span class="margin-bottom-md">{{ 'required_fields_label' | translate }}</span>

      <app-common-input
        [context]="{ field: 'confirmation', label: confirmationLabel, placeholder: actionPlaceHolder }"
        [form]="form"
        [formValidationRequest$]="formValidationRequest"
      ></app-common-input>
    </ng-container>
  </div>

  <div class="modal-footer-content">
    <div class="d-flex flex-wrap justify-content-between">
      <button class="mb-1 btn btn-outline-primary" (click)="close()">
        {{ confirmationLabelClose }}
      </button>
      <button class="mb-1 btn btn-lg btn-primary" (click)="onConfirm()">
        {{ confirmationLabelAction }}
      </button>
    </div>
  </div>
</app-modal-container>
