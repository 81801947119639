import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() public headerKey?: string;
  @Input() public messageKey?: string;

  public isString(valueToCheck: any): boolean {
    return typeof valueToCheck === 'string';
  }
}
