import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { CustomValidators } from '../../../../../../services/helpers/form-custom-validators';
import { scanCreateRequest } from '../../../../../../../../shared/constants/scan-create-request';
import { AutomatedScanAdvancedService } from '../../automated-scan-advanced.service';
import { IScanOptions } from '../../../../../../../../shared/interfaces/scan-options.interface';
import { SharedCommonUtility } from '../../../../../../../../shared/utils/common.utility';
import { scanningType } from '../../../../../../../../shared/constants/scanning';

@Component({
  selector: 'app-advanced-scan-handle-popups',
  templateUrl: './handle-popups.component.html',
})
export class HandlePopupsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public popupsControlName: string = scanCreateRequest.handlePopupsSelector;
  public modifyHandlePopups: boolean = false;
  public disabled: boolean = false;

  constructor(
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private advancedService: AutomatedScanAdvancedService,
  ) {}

  private subscribeToScanningType(): void {
    this.subscription.add(
      this.advancedService.getScanningType$().subscribe((type: scanningType) => {
        if (type === scanningType.automatedUserFlow) {
          this.removeControl();
          this.modifyHandlePopups = false;
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  private removeControl(): void {
    if (SharedCommonUtility.notNullish(this.form.get(this.popupsControlName))) {
      this.form.removeControl(this.popupsControlName);
      this.changeDetectorRef.detectChanges();
    }
  }

  private registerControl(value: string = ''): void {
    const popupSelectorControl: AbstractControl = this.form.get(this.popupsControlName);
    if (SharedCommonUtility.notNullish(popupSelectorControl)) {
      popupSelectorControl.patchValue(value);
    } else {
      this.form.registerControl(
        this.popupsControlName,
        this.formBuilder.control(value, [CustomValidators.required, CustomValidators.validateIsEmpty]),
      );
    }
  }

  private onOptionsUpdated(opts: IScanOptions): void {
    this.modifyHandlePopups = SharedCommonUtility.notNullishOrEmpty(opts.handlePopupsSelector);

    if (this.modifyHandlePopups) {
      this.registerControl(opts.handlePopupsSelector);
    } else {
      this.removeControl();
    }

    this.changeDetectorRef.detectChanges();
  }

  public onModifyHandlePopups(value: boolean): void {
    if (!value) {
      this.removeControl();
      return;
    }

    if (!this.form.contains(this.popupsControlName)) {
      this.registerControl();
    }

    this.changeDetectorRef.detectChanges();
    this.el.nativeElement.querySelector('#close-popup-selector input').focus();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscription.add(this.advancedService.onScanOptionsChanged().subscribe(this.onOptionsUpdated.bind(this)));
    this.subscribeToScanningType();
  }
}
