<form [formGroup]="form">
  <div *ngIf="isNativeLocalDatetimeSupported">
    <input
      id="{{ datetimeLocalParameters.nativeInputId }}"
      [name]="$formFields.nativeDatetimeLocal"
      class="form-element"
      [formControlName]="$formFields.nativeDatetimeLocal"
      type="datetime-local"
      [max]="nativeMaxValue()"
      [min]="nativeMinValue()"
      [attr.required]="required ? '' : null"
      [attr.aria-describedby]="errorFieldId || null"
    />
  </div>
  <div *ngIf="!isNativeLocalDatetimeSupported">
    <div class="row custom-date-field">
      <div class="col-md-12 col-12">
        <label for="{{ formId }}_{{ $dateParts.day }}">{{ 'time_day' | translate }}:</label>
        <select id="{{ formId }}_{{ $dateParts.day }}" [name]="$dateParts.day" [formControlName]="$dateParts.day">
          <ng-container *ngFor="let availableDay of availableDays">
            <option [ngValue]="availableDay">{{ availableDay }}</option>
          </ng-container>
        </select>
        <label for="{{ formId }}_{{ $dateParts.month }}">{{ 'time_month' | translate }}:</label>
        <select id="{{ formId }}_{{ $dateParts.month }}" [name]="$dateParts.month" [formControlName]="$dateParts.month">
          <option [ngValue]="0">{{ 'month_january' | translate }}</option>
          <option [ngValue]="1">{{ 'month_february' | translate }}</option>
          <option [ngValue]="2">{{ 'month_march' | translate }}</option>
          <option [ngValue]="3">{{ 'month_april' | translate }}</option>
          <option [ngValue]="4">{{ 'month_may' | translate }}</option>
          <option [ngValue]="5">{{ 'month_june' | translate }}</option>
          <option [ngValue]="6">{{ 'month_july' | translate }}</option>
          <option [ngValue]="7">{{ 'month_august' | translate }}</option>
          <option [ngValue]="8">{{ 'month_september' | translate }}</option>
          <option [ngValue]="9">{{ 'month_october' | translate }}</option>
          <option [ngValue]="10">{{ 'month_november' | translate }}</option>
          <option [ngValue]="11">{{ 'month_december' | translate }}</option>
        </select>
        <label for="{{ formId }}_{{ $dateParts.year }}">{{ 'time_year' | translate }}:</label>
        <select id="{{ formId }}_{{ $dateParts.year }}" [name]="$dateParts.year" [formControlName]="$dateParts.year">
          <ng-container *ngFor="let availableYear of availableYears">
            <option [ngValue]="availableYear">{{ availableYear }}</option>
          </ng-container>
        </select>
      </div>
      <div class="col-md-12 col-12">
        <label for="{{ formId }}_{{ $dateParts.hour }}">{{ 'time_hour' | translate }}:</label>
        <select id="{{ formId }}_{{ $dateParts.hour }}" [name]="$dateParts.hour" [formControlName]="$dateParts.hour">
          <ng-container *ngFor="let availableHour of availableHours">
            <option [ngValue]="availableHour">{{ availableHour }}</option>
          </ng-container>
        </select>
        <label for="{{ formId }}_{{ $dateParts.minute }}">{{ 'time_minute' | translate }}:</label>
        <select id="{{ formId }}_{{ $dateParts.minute }}" [name]="$dateParts.minute" [formControlName]="$dateParts.minute">
          <ng-container *ngFor="let availableMinute of availableMinutes">
            <option value="{{ availableMinute }}">{{ availableMinute }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>
</form>
