import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserService } from '../../../services/user.service';
import { $user } from '../../../../../shared/constants/user';
import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { RoutingService } from '../../../services/routing.service';
import { Api } from '../../../../../shared/constants/api';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription;

  public userEmail: string;
  public isEmailVerified: boolean;
  public pageTitle: string;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private routingService: RoutingService,
    private router: Router,
  ) {
    this.subscriptions = new Subscription();
    this.userEmail = '';
    this.isEmailVerified = null;
    this.pageTitle = '';

    this.subscribeToRouteChanges();
  }

  private processRouteParams(params: Params): void {
    if (typeof params.token !== 'string') {
      return;
    }

    const onGetUserDataSuccess = (user: IUserServerResponse): void => {
      this.userEmail = user[$user.email];
      this.isEmailVerified = user[$user.emailVerified];
    };

    const onGetUserDataError = (): void => {
      this.isEmailVerified = false;
      this.router.navigate(['/', Api.not_found], { replaceUrl: true }).then();
    };

    this.subscriptions.add(
      this.userService.verifyEmail(params.token).subscribe({ next: onGetUserDataSuccess, error: onGetUserDataError }),
    );
  }

  private subscribeToRouteChanges(): void {
    this.subscriptions.add(this.route.params.subscribe(this.processRouteParams.bind(this)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
  }
}
