import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IComponentCanDeactivate } from '../../interfaces/component-can-deactivate.interface';
import { ErrorHandlerService } from '../../services/error-handler.service';

@Injectable()
export class PendingChangesGuard {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  private handleError(error: HttpErrorResponse): Observable<boolean> {
    console.error('[PendingChangesGuard] handleError', error);

    this.errorHandlerService.handleError(error);

    return of(false);
  }

  public canDeactivate(
    component: IComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    const deactivateResult = component.canDeactivate(currentRoute, currentState, nextState);

    if (deactivateResult instanceof Observable) {
      // Note: handle error manually to avoid error to be thrown by Router (wrapped into "Unhandled promise rejection")
      return deactivateResult.pipe(catchError<boolean, Observable<boolean>>(this.handleError.bind(this)));
    }

    return deactivateResult;
  }
}
