import { IAccessibilityMarkLine, IAccessibilityMarkLinePoint } from '../interfaces/scan-chart.interface';
import { IAnalyticsResultItem } from '../../../shared/interfaces/analytics.interface';
import { TranslateService } from '../translate/translate.service';

export class ScanChartUtility {
  public static markLineFormatter(
    translate: TranslateService,
    chartData: IAnalyticsResultItem[],
    a: { data: IAccessibilityMarkLine },
  ): string | HTMLElement[] {
    const formatStrings: string[] = [];
    if (a.data.accessibilityPoint.updatedTool) {
      const currentVersion: string = chartData[a.data.xAxis].toolVersion;
      const previousVersion: string = chartData[(a.data.xAxis as number) - 1].toolVersion;
      const toolTooltip: string = translate.instant('label_tool_version_updated_tooltip', [previousVersion, currentVersion]);
      formatStrings.push(toolTooltip);
    }

    if (a.data.accessibilityPoint.updatedStandard) {
      const currentStandard: string = chartData[a.data.xAxis as number].includeStandard;
      const previousStandard: string = chartData[(a.data.xAxis as number) - 1].includeStandard;
      const toolTooltip: string = translate.instant('label_standard_updated_tooltip', [
        translate.instant(previousStandard),
        translate.instant(currentStandard),
      ]);
      formatStrings.push(toolTooltip);
    }

    if (a.data.accessibilityPoint.updatedTestingTool) {
      const currentTool: string = chartData[a.data.xAxis as number].toolName;
      const previousTool: string = chartData[(a.data.xAxis as number) - 1].toolName;
      const toolTooltip: string = translate.instant('label_testing_tool_updated_tooltip', [
        translate.instant(`scan_results_tool_option_${previousTool}`),
        translate.instant(`scan_results_tool_option_${currentTool}`),
      ]);
      formatStrings.push(toolTooltip);
    }

    return formatStrings.join('<br/><br/>').replace(/\n/g, '<br/>');
  }

  public static getMarkLinePoints(chartData: IAnalyticsResultItem[]): IAccessibilityMarkLinePoint[] {
    const markLinePoints: IAccessibilityMarkLinePoint[] = [];

    for (let i = 1; i < chartData.length; i++) {
      const point: IAccessibilityMarkLinePoint = {
        index: i,
        updatedTool: false,
        updatedStandard: false,
        updatedTestingTool: false,
      };
      let pushIndex: boolean = false;

      if (chartData[i].toolVersion !== chartData[i - 1].toolVersion) {
        point.updatedTool = true;
        pushIndex = true;
      }

      if (chartData[i].includeStandard !== chartData[i - 1].includeStandard) {
        point.updatedStandard = true;
        pushIndex = true;
      }

      if (chartData[i].toolName !== chartData[i - 1].toolName) {
        point.updatedTestingTool = true;
        pushIndex = true;
      }

      if (pushIndex) {
        markLinePoints.push(point);
      }
    }
    return markLinePoints;
  }
}
