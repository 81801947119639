<ng-container *ngIf="menuItems$ | async as menuItems">
  <ds-dropdown
    [ds-tooltip]="'profile' | translate"
    [tooltipPlacement]="DsTooltipPosition.bottom"
    
    [selectedValue]="(activeMenuItem$ | async)?.id"
  >
    <ds-dropdown-toggle [icon]="Icons.User" [variant]="DsButtonVariants.microAction" [ariaLabel]="'profile' | translate">
    </ds-dropdown-toggle>
    <div ds-dropdown-menu>
      <ds-dropdown-item
        *ngFor="let menuItem of menuItems"
        [label]="menuItem.label | translate"
        [icon]="menuItem.iconName"
        [href]="menuItem.routerLink"
        [value]="menuItem.id"
      >
      </ds-dropdown-item>
    </div>
  </ds-dropdown>
</ng-container>
