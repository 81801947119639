import { Injectable, NgZone } from '@angular/core';

import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class WalkMeService {
  private readonly scriptSrc: string;

  constructor(
    private ngZone: NgZone,
    private appConfigService: AppConfigService,
  ) {
    this.scriptSrc = this.appConfigService.getWalkMeScriptUrl();
  }

  private isWalkMeConfigured(): boolean {
    return typeof this.scriptSrc === 'string' && this.scriptSrc.length > 0;
  }

  private onLoadWalkMeError(error: any): void {
    console.error('[WalkMeService].onLoadWalkMeError', error);
  }

  private async loadWalkMeWidget(): Promise<void> {
    const action = (resolve: Function, reject: Function): void => {
      const walkMeScript: HTMLScriptElement = document.createElement('script');

      walkMeScript.id = 'walkMeScript';
      walkMeScript.type = 'text/javascript';
      walkMeScript.async = true;
      walkMeScript.src = this.scriptSrc;

      const onWalkMeLoaded = (): void => {
        resolve();
      };

      const onWalkMeLoadError = (error: ErrorEvent): void => {
        reject(error);
      };

      walkMeScript.addEventListener('load', onWalkMeLoaded);
      walkMeScript.addEventListener('error', onWalkMeLoadError);

      document.head.insertBefore(walkMeScript, document.head.firstChild);

      window['_walkmeConfig'] = {
        smartLoad: true,
      };
    };

    return new Promise(action);
  }

  public setGlobalVariable(currentSessionData: Record<string, any>): void {
    window['walkme_variable'] = currentSessionData;
  }

  public initialiseWalkMe(): void {
    if (this.isWalkMeConfigured() === false) {
      return;
    }

    this.ngZone.runOutsideAngular((): void => {
      this.loadWalkMeWidget().catch(this.onLoadWalkMeError.bind(this));
    });
  }
}
