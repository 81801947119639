import { CallbackDataParams, EChartsOption, EChartsType } from 'echarts/types/dist/shared';
import * as echarts from 'echarts/core';
import { GridComponent, MarkLineComponent, TooltipComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { SVGRenderer } from 'echarts/renderers';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { IAnalyticsResultItem } from '../../../../shared/interfaces/analytics.interface';
import { scanStatus } from '../../../../shared/constants/scanning';
import { TranslateService } from '../../translate/translate.service';
import { IAccessibilityMarkLine, IAccessibilityMarkLinePoint } from '../../interfaces/scan-chart.interface';
import { ScanChartUtility } from '../../utility/scan-chart.utility';
import { EapColors } from '../../shared/eap-colors';
import { DateUtility } from '../../utility/date.utility';
import { $analyticsResultItem } from '../../../../shared/constants/analytics';

@Component({
  selector: 'app-scan-analytics-health-graph',
  templateUrl: './scan-analytics-health-graph.component.html',
  styleUrls: ['./scan-analytics-health-graph.component.scss'],
})
export class ScanAnalyticsHealthGraphComponent implements OnDestroy {
  @Input() set healthData(healthData: IAnalyticsResultItem[]) {
    if (healthData) {
      this.onGetHealthDataSuccess(healthData);
    }
  }

  @Input() private scanHistoryLimit: number;
  private subscriptions: Subscription;

  private LINE_COLOR: string = '#002F57';
  private GRIDLINE_COLOR: string = EapColors.GreyStrokeInactive;

  public chartData: IAnalyticsResultItem[];
  public chart: EChartsType;

  constructor(private translate: TranslateService) {
    this.subscriptions = new Subscription();
    this.chartData = [];
  }

  private getHealthValuesAndLabels(): [string[], number[]] {
    const labels: string[] = [];
    const healthValues: number[] = [];

    const constructData = (scanAnalytics: IAnalyticsResultItem): void => {
      const date: Date = new Date(scanAnalytics.scanDate);
      const month: string = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      const day: string = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

      labels.push(`${month} ${day}`);

      if (scanAnalytics.scanStatus === scanStatus.completed_with_errors && scanAnalytics.scanHealth === null) {
        healthValues.push(0);
      } else {
        healthValues.push(Math.floor(scanAnalytics.scanHealth));
      }
    };

    this.chartData = this.chartData
      .sort((a: IAnalyticsResultItem, b: IAnalyticsResultItem): number => {
        return new Date(a.scanDate).getTime() - new Date(b.scanDate).getTime();
      })
      .slice(-this.scanHistoryLimit);
    this.chartData.forEach(constructData);

    const blankEntries: number = this.scanHistoryLimit - labels.length;

    for (let _ = 0; _ < blankEntries; _++) {
      labels.push('');
    }

    return [labels, healthValues];
  }

  private drawHealthGraph(): void {
    if (Object.keys(this.chartData).length === 0) {
      return;
    }

    const [labels, healthValues]: [string[], number[]] = this.getHealthValuesAndLabels();
    const markLinePoints: IAccessibilityMarkLinePoint[] = ScanChartUtility.getMarkLinePoints(this.chartData);
    echarts.use([GridComponent, TooltipComponent, LineChart, MarkLineComponent, SVGRenderer]);
    this.chart = echarts.init(document.getElementById('echarts'));
    document.querySelector('#echarts svg').setAttribute('aria-hidden', 'true');

    const xAxisTitle: string = this.translate.instant('graph_axis_date');
    const yAxisTitle: string = this.translate.instant('accessibility_health_score');
    const seriesTitle: string = yAxisTitle;

    const option: EChartsOption = {
      grid: {
        containLabel: true,
        top: '5%',
        bottom: '15%',
        left: '10%',
        right: '5%',
      },
      animation: false,
      tooltip: {
        backgroundColor: 'rgb(0,0,0)',
        confine: true,
        textStyle: {
          color: 'rgb(255,255,255)',
        },
        formatter: (params: CallbackDataParams): string => {
          const formattedDate: string = DateUtility.prettifyDate(
            new Date(this.chartData[params.dataIndex][$analyticsResultItem.scanDate]),
            {
              year: 'numeric',
              month: 'long',
            },
          );
          return `${formattedDate}<br />${params.seriesName}: ${params.data}<br />${this.translate.instant(
            this.chartData[params.dataIndex][$analyticsResultItem.includeStandard],
          )}`;
        },
      },
      xAxis: {
        boundaryGap: false,
        name: xAxisTitle,
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
          fontSize: 16,
          lineHeight: 24,
          color: 'black',
          fontFamily: 'Arial',
        },
        nameGap: 32,
        data: labels,
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        name: yAxisTitle,
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
          fontSize: 16,
          lineHeight: 24,
          color: 'black',
          fontFamily: 'Arial',
        },
        min: 0,
        max: 100,
        nameGap: 32,
        splitLine: {
          lineStyle: {
            color: this.GRIDLINE_COLOR,
          },
        },
      },
      series: [
        {
          name: seriesTitle,
          type: 'line',
          symbol: 'circle',
          symbolSize: 10,
          data: healthValues,
          zlevel: 2,
          itemStyle: {
            color: this.LINE_COLOR,
          },
          lineStyle: {
            color: this.LINE_COLOR,
          },
          markLine: {
            name: 'Scanner version changed',
            zlevel: 1,
            tooltip: {
              formatter: ScanChartUtility.markLineFormatter.bind(this, this.translate, this.chartData),
            },
            symbol: 'none',
            data: markLinePoints.map(
              (accessibilityPoint: IAccessibilityMarkLinePoint): IAccessibilityMarkLine => ({
                label: {
                  show: false,
                },
                xAxis: accessibilityPoint.index,
                accessibilityPoint: accessibilityPoint,
              }),
            ),
          },
        },
      ],
    };

    this.chart.setOption(option);
  }

  private onGetHealthDataSuccess(healthData: IAnalyticsResultItem[]): void {
    this.chartData = healthData;
    this.drawHealthGraph();
  }

  public onWindowResize(): void {
    this.chart?.resize();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
