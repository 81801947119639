<div>
  <div class="mt-3 mb-3 d-flex justify-content-end">
    <app-responsive-pagination
      [(page)]="page"
      [(pageSize)]="pageSize"
      [collectionSize]="workspaceCount$ | async"
      [entryLimits]="pageSizeLimits"
    ></app-responsive-pagination>
  </div>

  <div class="row" *ngIf="tableData$ | async as tableData">
    <div class="col-12">
      <app-ngb-table [config]="tableConfig" [data]="tableData" (onSort)="onSort$.next($event)"> </app-ngb-table>
    </div>
  </div>

  <div class="mt-3 d-flex justify-content-end">
    <app-responsive-pagination
      idSuffix="Bottom"
      [(page)]="page"
      [(pageSize)]="pageSize"
      [collectionSize]="workspaceCount$ | async"
      [entryLimits]="pageSizeLimits"
    ></app-responsive-pagination>
  </div>
</div>
