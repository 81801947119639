import { Pipe, PipeTransform } from '@angular/core';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

@Pipe({
  name: 'isNullish',
})
export class IsNullishPipe implements PipeTransform {
  public transform(value: any): boolean {
    return SharedCommonUtility.isNullish(value);
  }
}
