import { cloneDeep } from 'lodash';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ReplaySubject, Subject, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  $digitalPropertyMonitoringAlertType,
  $digitalPropertyMonitoringAlerts,
  $maxAlertsRecipientCount,
} from '../../../../../../../shared/constants/digital-properties';
import {
  IDigitalPropertyMonitoringUserAlertsWithEmail,
  IDigitalPropertyWorkspace,
} from '../../../../../../../shared/interfaces/digital-property.interface';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';
import { TranslateService } from '../../../../../translate/translate.service';
import { ITableColumn, ITableConfig, ITableRow } from '../../../../table/ngb-table/utilities/ngb-table.interface';
import { NgbTableUtilities } from '../../../../table/ngb-table/utilities/ngb-table.utilities';
import { MonitoringAlertsEditorComponent } from './monitoring-alerts-editor/monitoring-alerts-editor.component';

enum $tableFields {
  user = 'user',
  alerts = 'alerts',
  action = 'action',
}

@Component({
  selector: 'app-monitoring-alerts-step',
  templateUrl: './monitoring-alerts-step.component.html',
  styleUrls: ['monitoring-alerts-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringAlertsStepComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription;
  private alerts$: ReplaySubject<IDigitalPropertyMonitoringUserAlertsWithEmail[]>;
  @Input()
  public set alerts(value: IDigitalPropertyMonitoringUserAlertsWithEmail[]) {
    this.alerts$.next(cloneDeep(value));
  }

  @Input()
  public stepNumber: number;

  @Input()
  public workspace: IDigitalPropertyWorkspace;

  @Input()
  public formValidationRequest$: Subject<void>;

  @ViewChild('alertActions', { read: TemplateRef })
  public alertActionTemplate: TemplateRef<{ alert: IDigitalPropertyMonitoringUserAlertsWithEmail }>;

  @ViewChild('alertsEditorComponent')
  public alertEditorComponent: MonitoringAlertsEditorComponent;

  public updatedAlerts: Map<string, IDigitalPropertyMonitoringUserAlertsWithEmail>;

  public isEditing: boolean;
  public editedAlert: IDigitalPropertyMonitoringUserAlertsWithEmail;

  public $digitalPropertyMonitoringAlertType: typeof $digitalPropertyMonitoringAlertType;

  public tableConfig: ITableConfig;
  public tableData: ITableRow[];
  public maxRecipientCount: number;
  private viewInitialzed$: ReplaySubject<boolean>;

  constructor(
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.subscriptions = new Subscription();
    this.$digitalPropertyMonitoringAlertType = $digitalPropertyMonitoringAlertType;
    this.alerts$ = new ReplaySubject<IDigitalPropertyMonitoringUserAlertsWithEmail[]>(1);
    this.updatedAlerts = new Map<string, IDigitalPropertyMonitoringUserAlertsWithEmail>();
    this.isEditing = false;
    this.maxRecipientCount = $maxAlertsRecipientCount;
    this.viewInitialzed$ = new ReplaySubject<boolean>(1);
    this.setTableConfig();
  }

  private getSelectedAlertsText(alert: IDigitalPropertyMonitoringUserAlertsWithEmail): string {
    return Object.keys($digitalPropertyMonitoringAlertType)
      .filter(
        (alertType: $digitalPropertyMonitoringAlertType): boolean =>
          alert[$digitalPropertyMonitoringAlerts.alerts][alertType]?.set,
      )
      .map((alertType: $digitalPropertyMonitoringAlertType): string => this.translateService.instant(alertType))
      .join(', ');
  }

  private getTableData(): void {
    this.tableData = [];
    this.updatedAlerts.forEach((value: IDigitalPropertyMonitoringUserAlertsWithEmail) => {
      this.tableData.push({
        data: {
          [$tableFields.user]: NgbTableUtilities.htmlCell({
            text: value[$digitalPropertyMonitoringAlerts.email],
          }),
          [$tableFields.alerts]: NgbTableUtilities.htmlCell({ text: this.getSelectedAlertsText(value) }),
          [$tableFields.action]: NgbTableUtilities.templateCell({
            template: this.alertActionTemplate,
            templateContext: { alert: value },
          }),
        },
      });
    });
    this.changeDetectorRef.detectChanges();
  }

  private initAlerts(alerts: IDigitalPropertyMonitoringUserAlertsWithEmail[]): void {
    this.updatedAlerts.clear();

    alerts?.forEach((value: IDigitalPropertyMonitoringUserAlertsWithEmail) => {
      this.updatedAlerts.set(value[$digitalPropertyMonitoringAlerts.email], value);
    });

    this.getTableData();
  }

  private updateAlert(alert: IDigitalPropertyMonitoringUserAlertsWithEmail): void {
    this.updatedAlerts.delete(this.editedAlert[$digitalPropertyMonitoringAlerts.email]);
    this.updatedAlerts.set(alert[$digitalPropertyMonitoringAlerts.email], alert);
    this.getTableData();
  }

  private setTableConfig(): void {
    const columns: Record<$tableFields, ITableColumn> = {
      [$tableFields.user]: {
        translationKey: 'recipient_s_email',
        sortingEnabled: false,
      },
      [$tableFields.alerts]: {
        translationKey: 'label_monitoring_alerts',
        sortingEnabled: false,
      },
      [$tableFields.action]: {
        translationKey: 'column_header_action',
        sortingEnabled: false,
      },
    };

    this.tableConfig = {
      columns,
      caption: this.translateService.instant('current_alerts'),
      emptyState: {
        title: this.translateService.instant('no_email_alerts_setup'),
        subtitle: this.translateService.instant('no_email_alerts_setup_description'),
      },
    };
  }

  public isValid(): boolean {
    return SharedCommonUtility.isNullish(this.alertEditorComponent);
  }

  public get allAlertEmails(): string[] {
    return Array.from(this.updatedAlerts.keys());
  }

  public get maxAlertsReached(): boolean {
    return this.updatedAlerts.size >= $maxAlertsRecipientCount;
  }

  public onEditAlertsCancel(): void {
    this.isEditing = false;
  }

  public onEditAlertsSave(alert: IDigitalPropertyMonitoringUserAlertsWithEmail): void {
    this.isEditing = false;
    this.updateAlert(alert);
  }

  public editAlert(alert: IDigitalPropertyMonitoringUserAlertsWithEmail): void {
    this.editedAlert = alert;
    this.isEditing = true;
  }
  public removeAlert(alert: IDigitalPropertyMonitoringUserAlertsWithEmail): void {
    this.updatedAlerts.delete(alert[$digitalPropertyMonitoringAlerts.email]);
    this.getTableData();
  }

  public getAlerts(): IDigitalPropertyMonitoringUserAlertsWithEmail[] {
    return Array.from(this.updatedAlerts.values());
  }

  public onAddRecipientClicked(): void {
    this.editAlert({
      [$digitalPropertyMonitoringAlerts.userId]: '',
      [$digitalPropertyMonitoringAlerts.email]: '',
      [$digitalPropertyMonitoringAlerts.alerts]: {},
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.alerts$, this.viewInitialzed$])
        .pipe(
          map(([alerts, _]: [IDigitalPropertyMonitoringUserAlertsWithEmail[], boolean]) => {
            this.initAlerts(alerts);
          }),
        )
        .subscribe(),
    );
  }

  ngAfterViewInit(): void {
    this.viewInitialzed$.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
