<div class="row">
  <div class="col-12">{{ 'delete_rule_are_you_sure' | translate }}</div>
</div>
<div class="row">
  <div class="col-12 margin-top form-wrapper">
    <div class="d-flex flex-wrap flex-column flex-sm-row gap-3">
      <div class="col-sm-4">
        <div class="label">{{ 'audit_rule_property_rule_id' | translate }}</div>
        <div class="content">{{ rule.ruleId }}</div>
      </div>
      <div class="col-sm-6">
        <div class="label">{{ 'audit_rule_property_description' | translate }}</div>
        <div class="content">{{ rule.description }}</div>
      </div>
      <div class="col-sm-4">
        <div class="label">{{ 'version' | translate }}</div>
        <div *ngFor="let standard of rule.standards">
          <div class="content">{{ standard.WCAG?.versions | arraytostring }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="label">{{ 'label_wcag_success_critera' | translate }}</div>
        <div *ngFor="let standard of rule.standards">
          <div class="content">{{ displayWCAGOrNACriteria(standard) }}</div>
        </div>
      </div>
      <div *ngIf="rule.libraryName" class="col-sm-4">
        <div class="label">{{ 'library' | translate }}</div>
        <div class="content">
          {{ rule.libraryName }}
        </div>
      </div>
      <div *ngIf="rule.category" class="col-sm-4">
        <div class="label">{{ 'audit_rule_property_category' | translate }}</div>
        <div class="content">{{ rule.category }}</div>
      </div>
      <div class="col-sm-4">
        <div class="label">{{ 'audit_rule_property_severity' | translate }}</div>
        <app-rule-severity [severity]="rule.severity"></app-rule-severity>
      </div>
    </div>
  </div>
</div>
