import { Component, AfterViewInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

export class TocElement {
  public name: string;
  public anchor: string;
  public children: Array<TocElement>;

  constructor(name: string) {
    this.name = name;
    this.anchor = this.createHeaderAnchorName(name);
    this.children = [];
  }

  private createHeaderAnchorName(headerText: string): string {
    return headerText.toLowerCase().replace(/\W/g, '_');
  }
}

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
})
export class HelpCenterComponent implements AfterViewInit {
  public tableOfContents: TocElement;
  public baseUrl: string;

  constructor(
    private router: Router,
    private renderer: Renderer2,
  ) {
    this.tableOfContents = null;
    this.baseUrl = this.router.url;
  }

  private getTableOfContents(): TocElement {
    const tableOfContents = new TocElement('Table of Contents');
    const tocPath: Array<TocElement> = [tableOfContents];
    const headers: NodeListOf<Element> = document.getElementById('content').querySelectorAll('h1, h2, h3, h4, h5, h6');

    let insertionPoint: Array<TocElement> = tableOfContents.children;
    let currentDepth: number = 0;

    const parseContentHeader = (header: HTMLElement): void => {
      const depthOfHeader: number = parseInt(header.tagName[1], 10);
      const newTocElement = new TocElement(header.innerText);
      const headerRelativeLevel = currentDepth - depthOfHeader;

      if (headerRelativeLevel > 0) {
        // We have to go up
        tocPath.splice(-(headerRelativeLevel + 1));
      }

      if (headerRelativeLevel === 0) {
        // Last one is over
        tocPath.pop();
      }

      insertionPoint = tocPath[tocPath.length - 1].children;
      tocPath.push(newTocElement);

      currentDepth = depthOfHeader;

      this.renderer.setAttribute(header, 'id', newTocElement.anchor);
      insertionPoint.push(newTocElement);
    };

    Array.from(headers).forEach(parseContentHeader);

    return tableOfContents;
  }

  public ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.tableOfContents = this.getTableOfContents();
    });
  }
}
