<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 d-flex flex-column">
      <app-header></app-header>
      <div class="flex-1 d-flex justify-content-center align-items-center">
        <div class="card">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <img src="/static/assets/images/restricted-access-graphics.svg" alt="Page maintenance graphic" />
            <h1 id="maintenance-header" class="mt-4">Restricted access</h1>
            <p id="maintenance-text">
              The page you are trying to access is restricted. Please check that the URL is correct and try again, or contact your
              administrator.
            </p>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
