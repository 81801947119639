import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Icons, DsButtonVariants, DsTooltipPosition } from '@levelaccess/design-system';

import { NavService } from '../../../../services/navigation/nav.service';
import { INavMenuItem, NavigationItem } from '../../../../services/navigation/nav-structure';

@Component({
  selector: 'top-navbar-profile-dropdown',
  templateUrl: './top-navbar-profile-dropdown.component.html',
})
export class TopNavbarProfileDropdownComponent implements OnInit {
  public readonly Icons: typeof Icons = Icons;
  public readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public readonly DsTooltipPosition: typeof DsTooltipPosition = DsTooltipPosition;

  public menuItems$: Observable<INavMenuItem[]>;
  public activeMenuItem$: Observable<INavMenuItem>;

  constructor(private navService: NavService) {}

  ngOnInit(): void {
    this.menuItems$ = this.navService.getTopLevelMenuItems$([NavigationItem.profile, NavigationItem.logout]);
    this.activeMenuItem$ = this.navService.activeTopLevelMenuItem$();
  }
}
