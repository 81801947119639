import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

import { IUserAvatar } from '../../../interfaces/user.interface';
import { CommonUtility } from '../../../utility/common.utility';
import { TranslateService } from '../../../translate/translate.service';
import { UserService } from '../../../services/user.service';
import { SupportedImageMimeType } from '../../../../../shared/constants/mime-type';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';

@Component({
  selector: 'app-avatar-input',
  templateUrl: './avatar-input.component.html',
  styleUrls: ['./avatar-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AvatarInputComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarInputComponent implements ControlValueAccessor {
  private avatarInputElement: HTMLInputElement;
  private formValidationRequest: Subject<void>;

  @Input() public label: string;

  public avatarImg: HTMLElement;
  public value: IUserAvatar;
  public avatarResized: Blob;
  public hasAvatarError: boolean;
  public avatarSelectedFile: EventEmitter<File>;
  public avatarFileList: FileList;
  public supportedAvatarFileTypes: string;
  public form: UntypedFormGroup;
  public formValidationRequest$: Observable<void>;

  public onChange: Function;
  public onTouched: Function;
  public isDisabled: boolean;

  public uniqueId: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.uniqueId = CommonUtility.createUniqueDOMId();
    this.hasAvatarError = false;
    this.avatarSelectedFile = new EventEmitter();
    this.avatarFileList = null;
    this.setDefaultAvatar();
    this.supportedAvatarFileTypes = SupportedImageMimeType.join(',');
    this.formValidationRequest = new Subject<any>();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();
    this.form = this.createForm();
  }

  private createForm(): UntypedFormGroup {
    const formConfig: Record<string, UntypedFormControl> = {
      avatar: new UntypedFormControl(null, {
        validators: [CustomValidators.validateSingleFileIsImage],
        updateOn: 'change',
      }),
    };
    return this.formBuilder.group(formConfig);
  }

  private setDefaultAvatar(): void {
    const avatar: IUserAvatar = {
      alt: this.translateService.instant('default_avatar_alt'),
      src: this.domSanitizer.bypassSecurityTrustUrl(this.userService.getDefaultAvatarURL()),
    };

    this.value = avatar;
  }

  public triggerFileUploadDialog(): void {
    CommonUtility.triggerClickEventOnElement(this.uniqueId);
  }

  public onSelectedAvatar(event: Event): void {
    if (event === null) {
      return;
    }
    this.avatarInputElement = event.target as HTMLInputElement;

    if (typeof this.avatarInputElement.files === 'object' && this.avatarInputElement.files.length === 0) {
      this.avatarInputElement.files = this.avatarFileList;
      return;
    }

    this.avatarFileList = this.avatarInputElement.files;
    this.avatarSelectedFile.emit(this.avatarFileList?.item(0) || null);
  }

  public onCroppedFile(blob: Blob): void {
    const reader: FileReader = new FileReader();

    const updateImageSrc = (evt: Event): void => {
      const imageAsBase64: string = (evt.target as any).result;

      this.value.src = this.domSanitizer.bypassSecurityTrustUrl(imageAsBase64);
      this.value.alt = this.translateService.instant('edit_profile_user_avatar_alt_text');

      this.changeDetectorRef.detectChanges();
      this.onChange(this.value);
      reader.removeEventListener('load', updateImageSrc);
    };

    this.avatarResized = blob;
    this.value.blob = blob;

    reader.addEventListener('load', updateImageSrc);
    reader.readAsDataURL(blob);
  }

  public avatarOnError(event: Event): void {
    const target: HTMLImageElement = event.target as HTMLImageElement;

    console.warn('[AvatarInputComponent.avatarOnError]', event);

    this.hasAvatarError = target.clientWidth === 0;
    this.setDefaultAvatar();
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  public writeValue(obj: IUserAvatar): void {
    if (obj === null) {
      return;
    }
    this.value = obj;
    this.changeDetectorRef.detectChanges();
  }
}
