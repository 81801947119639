import { Router } from '@angular/router';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';
import { throwError, Observable, EMPTY } from 'rxjs';

import { Api } from '../../../shared/constants/api';
import { NotificationService } from './notification.service';
import { NotificationPosition } from '../models/notification.model';
import { TranslateService } from '../translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    super();
  }

  public handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        this.notificationService.error(
          this.translateService.instant('temporary_connection_error', [
            `\n${this.translateService.instant('you_are_offline_now')}`,
          ]),
          NotificationPosition.Toast,
        );
        return;
      }
      if (error.status === StatusCodes.FORBIDDEN) {
        this.router.navigate(['/', Api.forbidden]).then();
        return;
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      this.notificationService.error(
        this.translateService.instant('temporary_application_error', [`\n${error.message}`]),
        NotificationPosition.Toast,
      );
      console.error(error); // Note that in production build console.error() will send a /tracking/console beacon
    }
    return;
  }

  public handleRoutingError(error: any): void {
    if (/ChunkLoadError:/i.test(error)) {
      /**
       * Chunk load errors can be common during the deployment of new versions of the app
       * because the chunk files associated with the previous version are no longer available on the server.
       *
       * This error can only happen in a very short window of time, which is after the deployment finishes, and before
       * users receive the "traditional blocking prompt" informing that they must reload the page to get the new version.
       */
      this.notificationService.error(
        this.translateService.instant('temporary_application_loading_error'),
        NotificationPosition.Toast,
      );
      return;
    }

    // Log unexpected routing errors that may need investigation.
    console.error('[ErrorHandlerService.handleRoutingError]', `[url:${this.router.url}]`, error);

    // Note: unhandled routing errors did not show any toast in the past, so we're preserving that behaviour for now.
    // TODO EAP-21877: gather logs and handle new errors if needed, and consider showing error toasts for other unhandled errors.
  }

  public handleNotFound(): (error: Error | HttpErrorResponse) => Observable<any> {
    return (error: HttpErrorResponse): Observable<any> => {
      if (error.status === StatusCodes.NOT_FOUND) {
        this.router.navigate(['/', `${Api.not_found}`], { replaceUrl: true }).then();
        return EMPTY;
      }
      return throwError(error as Error);
    };
  }
}
