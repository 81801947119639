<div>
  <ng-container *ngIf="data?.isAvailable; then monitoringIsAvailable; else monitoringIsNotAvailable"></ng-container>

  <ng-template #monitoringIsAvailable>
    <ng-container *ngIf="data.isArchived; else availableAndNotArchived">
      <span> {{ 'restore_to_setup_monitoring' | translate }}</span>
    </ng-container>
  </ng-template>

  <ng-template #availableAndNotArchived>
    <ng-container
      *ngIf="!hasMonitoring; then monitoringIsAvailableAndDisabled; else monitoringIsAvailableAndEnabled"
    ></ng-container>
  </ng-template>

  <ng-template #monitoringIsAvailableAndDisabled>
    <a *ngIf="hasAccess" [routerLink]="['/', Api.monitoring, Api.monitoringSettings]" [queryParams]="queryParams">
      {{ 'set_up_monitoring' | translate }}
    </a>
  </ng-template>

  <ng-template #monitoringIsAvailableAndEnabled>
    <div class="monitoring-container">
      <button
        type="button"
        class="monitoring-label btn btn-link px-2 py-0 text-decoration-none"
        [triggers]="'click'"
        [ngbTooltip]="tooltip"
        [container]="'body'"
      >
        {{ 'label_monitoring' | translate }}
      </button>
      <app-monitoring-alert-icon class="margin-left" *ngIf="alerts" [alert]="alerts"></app-monitoring-alert-icon>
    </div>
  </ng-template>

  <ng-template #monitoringIsNotAvailable>
    <ng-template *ngIf="data.isArchived === false">
      {{ 'not_supported' | translate }}
    </ng-template>
  </ng-template>
</div>

<ng-template #tooltip>
  <div class="px-3 py-1 small">
    <strong  class="d-block">
      {{ getMonitoringOccurrenceLabel(data.monitoring) | translate }}
    </strong>
    <ng-container *ngIf="hasNextSchedule">
      <span >{{ 'next_scan' | translate }}: </span>
      <span >{{ nextSchedule | dateTime }}</span>
    </ng-container>
    <ng-container *ngIf="hasNextSchedule === false">
      <span >{{ 'next_scan_to_be_scheduled' | translate }}</span>
    </ng-container>
  </div>
</ng-template>
