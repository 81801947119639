export enum $workspace {
  _id = '_id',
  id = 'id',
  createdAt = 'createdAt',
  currentlyInUse = 'currentlyInUse',
  description = 'description',
  digitalProperties = 'digitalProperties',
  name = 'name',
  status = 'status',
  tenantId = 'tenantId',
  tenant = 'tenant',
  type = 'type',
  role = 'role',
  updatedAt = 'updatedAt',
  users = 'users',
  userToAdd = 'userToAdd',
  userPermissions = 'userPermissions',
  settings = 'settings',
  digitalPropertiesCount = 'digitalPropertiesCount',
}

export enum WorkspaceTypes {
  /**
   * @deprecated
   */
  private = 'private',
  shared = 'shared',
}

export enum workspaceStatus {
  active = 'active',
  closed = 'closed',
}

export enum WorkspaceAclRoles {
  workspaceAdmin = 'workspaceAdmin',
  workspaceStaff = 'workspaceStaff',
  workspaceUser = 'workspaceUser',
}

export enum $workspaceSettings {
  activeScansLimit = 'activeScansLimit',
}

export const parallelScansMax: number = 10000;
export const parallelScansMin: number = 1;
export const CHAR_LIMITS: Readonly<Partial<Record<$workspace, number>>> = Object.freeze({
  [$workspace.name]: 60,
  [$workspace.description]: 255,
});

export enum $migrateRequest {
  tenantId = 'tenantId',
  workspacesIds = 'workspacesIds',
}

export enum $workspaceSummary {
  workspace = 'workspace',
  totalDigitalProperties = 'totalDigitalProperties',
  totalScans = 'totalScans',
  totalManualEvaluations = 'totalManualEvaluations',
}
