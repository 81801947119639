import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-eap-icons',
  templateUrl: '../../assets/icons/eap-icons.html',
})
export class EapIconsComponent {
  @HostBinding('style.display') public none: string = 'none';
}

export enum Icons {
  ActivateUserIcon = 'activate-user-icon',
  AlertIcon = 'alert-icon',
  AssignUserIcon = 'assign-user-icon',
  AttachmentIcon = 'attachment-icon',
  CalendarIcon = 'calendar-icon',
  ChatIcon = 'chat-icon',
  CheckboxIcon = 'checkbox-icon',
  CheckboxPartialIcon = 'checkbox-partial-icon',
  CheckboxSelectedIcon = 'checkbox-selected-icon',
  CheckmarkIcon = 'checkmark-icon',
  CircleCheckmarkIcon = 'circle-checkmark-icon',
  CircleXIcon = 'circle-x-icon',
  ChevronBottomIcon = 'chevron-bottom-icon',
  ChevronDownIcon = 'chevron-down-icon',
  ChevronTopIcon = 'chevron-top-icon',
  ChevronUpIcon = 'chevron-up-icon',
  ClockIcon = 'clock-icon',
  CloseIcon = 'close-icon',
  CursorTextIcon = 'cursor-text-icon',
  DeactivateUserIcon = 'deactivate-user-icon',
  DeleteIcon = 'delete-icon',
  DocumentIcon = 'document-icon',
  DownloadIcon = 'download-icon',
  DropdownIcon = 'dropdown-icon',
  EditIcon = 'edit-icon',
  EmailIcon = 'email-icon',
  ExternalLinkIcon = 'external-link-icon',
  FavoriteIcon = 'favorite-icon',
  FilterIcon = 'filter-icon',
  FlagIcon = 'flag-icon',
  FolderIcon = 'folder-icon',
  HelpIcon = 'help-icon',
  HomeIcon = 'home-icon',
  HorizontalEllipsisIcon = 'horizontal-ellipsis-icon',
  ImageIcon = 'image-icon',
  InfoIcon = 'info-icon',
  LinkIcon = 'link-icon',
  LockIcon = 'lock-icon',
  MapIcon = 'map-icon',
  MediaIcon = 'media-icon',
  MinusIcon = 'minus-icon',
  NotVisibleIcon = 'not-visible-icon',
  NotificationIcon = 'notification-icon',
  PlusIcon = 'plus-icon',
  PointerIcon = 'pointer-icon',
  ProfileIcon = 'profile-icon',
  QuestionMarkTooltipIcon = 'question-mark-tooltip',
  RadioButtonIcon = 'radio-button-icon',
  RadioButtonSelectedIcon = 'radio-button-selected-icon',
  RequestIcon = 'request-icon',
  RevokeIcon = 'revoke-icon',
  SearchIcon = 'search-icon',
  SettingsIcon = 'settings-icon',
  SeverityCriticalIcon = 'severity-critical-icon',
  SeverityHighIcon = 'severity-high-icon',
  SeverityLowIcon = 'severity-low-icon',
  ShareIcon = 'share-icon',
  SortIcon = 'sort-icon',
  SyncIcon = 'sync-icon',
  TrendDownIcon = 'trend-down-icon',
  TrendUpIcon = 'trend-up-icon',
  UnlockIcon = 'unlock-icon',
  UploadIcon = 'upload-icon',
  VerticalEllipsisIcon = 'vertical-ellipsis-icon',
  VisibleIcon = 'visible-icon',
  WaitIcon = 'wait-icon',
  WarningCircleIcon = 'warning-circle-icon',
  WarningTriangleIcon = 'warning-triangle-icon',
  ZoomInIcon = 'zoom-in-icon',
  ZoomOutIcon = 'zoom-out-icon',
}
