<app-modal-container [displayFooter]="true" cardBodyClass="px-4 py-2" (onClose$)="closeModal()">
  <div class="modal-header-content">
    {{ 'change_status' | translate }}
  </div>

  <div class="modal-body-content">
    <p *ngIf="!isBulkOperation(this.modalInputData?.data?.ruleId)" class="text-black fw-bold">
      {{
        (this.modalInputData?.data?.enabled
          ? 'ask_if_want_to_include_rule_for_future_scans'
          : 'ask_if_want_to_exclude_rule_for_future_scans'
        ) | translate
      }}
    </p>
    <p *ngIf="isBulkOperation(this.modalInputData?.data?.ruleId)" class="text-black fw-bold">
      {{
        (this.modalInputData?.data?.enabled
          ? 'ask_if_want_to_include_num_rules_for_future_scans'
          : 'ask_if_want_to_exclude_num_rules_for_future_scans'
        ) | translate: [this.modalInputData?.data?.ruleId.length]
      }}
    </p>
    <p *ngIf="this.modalInputData?.data?.title" class="text-black">
      <strong>{{ 'title' | translate }}:</strong>
      {{ this.modalInputData?.data?.title }}
    </p>
    <form id="change-rule-status-form" [formGroup]="form" (ngSubmit)="changeRuleStatus()">
      <div class="row">
        <div class="col-12">
          <label for="comment" class="fw-bold" [attr.aria-required]="true">
            {{ 'reason' | translate }}
            ({{ 'required' | translate | lowercase }})
          </label>
          <textarea
            id="comment"
            name="comment"
            rows="4"
            class="form-element w-100"
            placeholder=""
            formControlName="comment"
            aria-describedby="comment-error"
          ></textarea>

          <div id="comment-error">
            <app-form-field-error
              *ngIf="form.disabled === false"
              [formValidationRequest$]="formValidationRequest$"
              [field]="form.get('comment')"
              name="comment"
              [label]="'comment' | translate"
            ></app-form-field-error>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer-content">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button class="btn btn-outline-primary" (click)="closeModal()">
          {{ 'label_close' | translate }}
        </button>
        <button
          *ngIf="!isBulkOperation(this.modalInputData?.data?.ruleId)"
          form="change-rule-status-form"
          class="btn btn-primary ms-auto"
        >
          {{ (this.modalInputData?.data?.enabled ? 'label_include_rule' : 'label_exclude_rule') | translate }}
        </button>
        <button
          *ngIf="isBulkOperation(this.modalInputData?.data?.ruleId)"
          form="change-rule-status-form"
          class="btn btn-primary ms-auto"
        >
          {{
            (this.modalInputData?.data?.enabled ? 'label_include_num_rules' : 'label_exclude_num_rules')
              | translate: [this.modalInputData?.data?.ruleId.length]
          }}
        </button>
      </div>
    </div>
  </div>
</app-modal-container>
