import { Component, ComponentRef, Input } from '@angular/core';
import { ShareIssueReportModalComponent } from '../share-issue-report-modal/share-issue-report-modal.component';
import { ModalService } from '../../services/modal.service';

export type UrlFactory = string | (() => Promise<string>);

@Component({
  selector: 'app-share-issue-report-url',
  templateUrl: './share-issue-report-url.component.html',
  styleUrls: ['./share-issue-report-url.component.scss'],
})
export class ShareIssueReportUrlComponent {
  @Input() public url: UrlFactory;

  constructor(private modalService: ModalService) {}

  public async openShareIssueModal(): Promise<void> {
    let issueLink: string;
    switch (typeof this.url) {
      case 'string':
        issueLink = this.url;
        break;
      case 'function':
        issueLink = await this.url().catch(() => window.location.href);
        break;
      default:
        issueLink = window.location.href;
        break;
    }

    const modalComponentRef: ComponentRef<ShareIssueReportModalComponent> =
      this.modalService.open(ShareIssueReportModalComponent);

    modalComponentRef.instance.modalInputData = {
      data: {
        url: issueLink,
      },
    };
  }
}
