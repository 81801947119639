import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, Data, ResolveFn, Route, Event } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { concat, distinct, filter, first, mergeMap, toArray, startWith } from 'rxjs/operators';

import { IBreadcrumb, IBreadcrumbsResolver, wrapIntoObservable } from './breadcrumbs.shared';
import { BreadcrumbsConfig, IPostProcessBreadcrumb } from '../../resolvers/breadcrumbs/breadcrumbs.config';
import { DefaultBreadcrumbsResolver } from '../../resolvers/breadcrumbs/default-breadcrumbs.resolver';
import { ActivatedRouteData } from '../../shared/constants';

@Injectable()
export class BreadcrumbsService implements OnDestroy {
  private subscription: Subscription;
  private readonly _breadcrumbs: BehaviorSubject<IBreadcrumb[]>;

  constructor(
    private _router: Router,
    private _config: BreadcrumbsConfig,
    private _injector: Injector,
    private _defaultResolver: DefaultBreadcrumbsResolver,
  ) {
    this.subscription = new Subscription();
    this._breadcrumbs = new BehaviorSubject<IBreadcrumb[]>([]);

    this.subscribeNavigationEnd();
  }

  get crumbs$(): Observable<IBreadcrumb[]> {
    return this._breadcrumbs.asObservable();
  }

  private subscribeNavigationEnd(): void {
    this.subscription.add(
      this._router.events
        .pipe(
          filter((routerEvent: Event) => routerEvent instanceof NavigationEnd),
          startWith({}),
        )
        .subscribe(this.onNavigationEnd.bind(this)),
    );
  }

  private onNavigationEnd(): void {
    const currentRoot: ActivatedRouteSnapshot = this._router.routerState.snapshot.root;

    this.subscription.add(
      this._resolveCrumbs(currentRoot)
        .pipe(
          mergeMap((breadcrumbs: IBreadcrumb[]): IBreadcrumb[] => breadcrumbs),
          distinct((breadcrumb: IBreadcrumb): string => breadcrumb.path),
          toArray(),
          mergeMap((breadcrumbs: IBreadcrumb[]): Observable<IBreadcrumb[]> => {
            if (this._config.postProcess) {
              const processedBreadcrumbs: IPostProcessBreadcrumb = this._config.postProcess(breadcrumbs);
              return wrapIntoObservable<IBreadcrumb[]>(processedBreadcrumbs).pipe(first());
            }
            return of(breadcrumbs);
          }),
        )
        .subscribe((breadcrumbs: IBreadcrumb[]): void => this._breadcrumbs.next(breadcrumbs)),
    );
  }

  private _resolveCrumbs(route: ActivatedRouteSnapshot): Observable<IBreadcrumb[]> {
    let crumbs$: Observable<IBreadcrumb[]>;

    const routeConfig: Route | undefined = route?.routeConfig;
    const data: Data | undefined = routeConfig?.data;
    const title: [string] | ResolveFn<unknown> | undefined =
      data?.[ActivatedRouteData.title] || routeConfig?.resolve?.[ActivatedRouteData.title];

    if (data && data.breadcrumbs && title) {
      let resolver: IBreadcrumbsResolver;

      if (typeof data.breadcrumbs.resolver === 'function') {
        resolver = this._injector.get(data.breadcrumbs.resolver);
      } else {
        resolver = this._defaultResolver;
      }

      const result: IPostProcessBreadcrumb = resolver.resolve(route, this._router.routerState.snapshot);
      crumbs$ = wrapIntoObservable<IBreadcrumb[]>(result).pipe(first());
    } else {
      crumbs$ = of([]);
    }

    if (route?.firstChild) {
      crumbs$ = crumbs$.pipe(concat(this._resolveCrumbs(route.firstChild)));
    }

    return crumbs$;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
