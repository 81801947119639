<app-modal-container>
  <span class="modal-header-content">
    {{ 'redirect_label' | translate }}
  </span>

  <div class="modal-body-content">
    <div *ngIf="uniqueRedirect; else nonUnique">
      {{ 'login_unique_redirect_prompt' | translate: countdownSeconds }}
    </div>

    <ng-template #nonUnique>
      <div class="mb-4">{{ 'login_non_unique_redirect_prompt' | translate }}</div>
      <app-common-select
        [form]="form"
        [values]="redirectUrls"
        [context]="{
          field: $tenant.domain,
          required: false,
          label: 'label_available_domains',
          valueKey: 'url',
          nameKey: 'domain',
        }"
      >
      </app-common-select>
    </ng-template>
  </div>
</app-modal-container>
