import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestBuilder } from '../helpers/rest.builder';
import {
  ICreateUsersRequest,
  ISecurityUserWithGroupsResponse,
  ISecurityGroupsWithSecurityGroupUserLastUpdatedInTenant,
  IAssignUsersRequest,
} from '../../../../shared/interfaces/security-group.interface';
import {
  IAssignUsersResponse,
  IGetOrCreateUserResponse,
  IUsersServerResponse,
} from '../../../../shared/interfaces/user.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { ApiQueryOption } from '../../../../shared/constants/api';
import { IBulkAddToStaffGroupsResponse } from '../../../../shared/interfaces/security.interface';
import { $securityEntity } from '../../../../shared/constants/security-group';

@Injectable({
  providedIn: 'root',
})
export class SecurityUsersApi {
  private restBuilder: RestBuilder;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /admin/security/users
  public findUsersWithSecurityGroupsByTenantId(
    limit: number,
    skip: number,
    searchTerm?: string,
    workspaceId?: string,
    digitalPropertyId?: string,
  ): Observable<ISecurityUserWithGroupsResponse> {
    const url: string = this.restBuilder.create().admin().security().users().getApiUrl();

    let params: HttpParams = this.restBuilder.paginationParams(skip, limit);

    if (SharedCommonUtility.notNullishOrEmpty(searchTerm)) {
      params = params.set(ApiQueryOption.term, searchTerm);
    }

    if (SharedCommonUtility.notNullishOrEmpty(workspaceId)) {
      params = params.set($securityEntity.workspaceId, workspaceId);
    }

    if (SharedCommonUtility.notNullishOrEmpty(digitalPropertyId)) {
      params = params.set($securityEntity.digitalPropertyId, digitalPropertyId);
    }

    return this.httpClient.get<ISecurityUserWithGroupsResponse>(url, {
      params,
    });
  }

  // GET /admin/security/users/unassigned-members
  public getUnassignedUsers(limit: number, skip: number, searchTerm?: string): Observable<IUsersServerResponse> {
    const url: string = this.restBuilder.create().admin().security().users().unassignedMembers().getApiUrl();

    let params: HttpParams = this.restBuilder.paginationParams(skip, limit);

    if (SharedCommonUtility.notNullishOrEmpty(searchTerm)) {
      params = params.set(ApiQueryOption.term, searchTerm);
    }

    return this.httpClient.get<IUsersServerResponse>(url, { params });
  }

  // PUT /admin/security/users/assign-users
  public assignSecurityUsers(request: IAssignUsersRequest): Observable<IAssignUsersResponse> {
    const url: string = this.restBuilder.create().admin().security().users().assignUsers().getApiUrl();
    return this.httpClient.put<IAssignUsersResponse>(url, request);
  }

  // POST /admin/security/users
  public createSecurityUsers(request: ICreateUsersRequest): Observable<IGetOrCreateUserResponse> {
    const url: string = this.restBuilder.create().admin().security().users().getApiUrl();
    return this.httpClient.post<IGetOrCreateUserResponse>(url, request);
  }

  // DELETE /admin/security/users/:userId
  public removeUser(userId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().users(userId).getApiUrl();
    return this.httpClient.delete<void>(url);
  }

  // GET /admin/security/users/:userId/tenants-membership
  public getSecurityUserTenantsMembership(userId: string): Observable<ISecurityGroupsWithSecurityGroupUserLastUpdatedInTenant[]> {
    const url: string = this.restBuilder.create().admin().security().users(userId).tenantsMembership().getApiUrl();
    return this.httpClient.get<ISecurityGroupsWithSecurityGroupUserLastUpdatedInTenant[]>(url);
  }

  // POST /admin/security/users/:userId/groups
  public addUserToSecurityGroups(userId: string, groupIds: string[]): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().users(userId).groups().getApiUrl();
    return this.httpClient.post<void>(url, { groupIds });
  }

  // POST /admin/security/users/:userId/staffGroups
  public bulkAddToStaffSecurityGroups(
    userId: string,
    staffSecurityName: string,
    tenantIds: string[],
  ): Observable<IBulkAddToStaffGroupsResponse> {
    const url: string = this.restBuilder.create().admin().security().users(userId).staffGroups().getApiUrl();
    return this.httpClient.post<IBulkAddToStaffGroupsResponse>(url, { staffSecurityName, tenantIds });
  }

  // PUT /admin/security/users/:userId
  public updateUserProfile(userProfile: FormData, userId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().users(userId).getApiUrl();
    return this.httpClient.put<void>(url, userProfile);
  }

  // PUT /admin/security/users/:userId/groups
  public updateUserSecurityGroups(userId: string, groupIds: { groupIds: string[] }): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().users(userId).groups().getApiUrl();
    return this.httpClient.put<void>(url, groupIds);
  }

  // DELETE /admin/security/users/:userId/groups
  public removeUserFromSecurityGroups(userId: string, groupIds: string[]): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().users(userId).groups().getApiUrl();
    return this.httpClient.delete<void>(url, { body: { groupIds } });
  }

  // POST /admin/security/users/:userId/resend-invite
  public resendInvite(userId: string): Observable<void> {
    const url: string = this.restBuilder.create().admin().security().users(userId).resendInvite().getApiUrl();
    return this.httpClient.post<void>(url, null);
  }
}
