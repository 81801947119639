import { Injectable } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { HttpBackend, HttpClient } from '@angular/common/http';

import { RestBuilder } from './helpers/rest.builder';
import { IAppConfig, IIdpConfig } from '../../../shared/interfaces/app-config.interface';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: IAppConfig;
  private restBuilder: RestBuilder;

  constructor(
    private httpHandler: HttpBackend,
    private externalAuthConfig: AuthClientConfig,
  ) {
    this.appConfig = null;
    this.restBuilder = new RestBuilder();
  }

  public async fetchConfig(): Promise<IAppConfig> {
    if (this.appConfig === null) {
      const url: string = this.restBuilder.create().config().getApiUrl();
      // build new http client here so angular interceptor will not be triggered
      // this is to avoid trigger external auth interceptor before external idp client is configured
      // https://github.com/auth0/auth0-angular/issues/70#issuecomment-715225756
      const httpClient: HttpClient = new HttpClient(this.httpHandler);
      this.appConfig = await httpClient.get<IAppConfig>(url).toPromise();
      const idpConfig: IIdpConfig = this.appConfig?.idpConfig;
      if (idpConfig?.external) {
        this.externalAuthConfig.set({
          domain: idpConfig.domain,
          clientId: idpConfig.clientId,
          authorizationParams: {
            audience: idpConfig.audience,
            redirect_uri: location.origin,
          },
          useRefreshTokens: true,
          httpInterceptor: {
            allowedList: [
              {
                uri: '/api/*',
                allowAnonymous: true,
              },
            ],
          },
        });
      }
    }
    return this.appConfig;
  }

  public isUsingExternalIdp(): boolean {
    return this.appConfig?.idpConfig?.external === true;
  }

  public useCookiesForAuth(): boolean {
    return this.appConfig?.authConfig?.useCookiesForAuth === true;
  }

  public getZendeskWidgetUrl(): string {
    return this.appConfig?.externalAppConfig?.zendeskWidgetUrl || '';
  }

  public getZendeskUrl(): string {
    return this.appConfig?.externalAppConfig?.zendeskUrl || '';
  }

  public getZendeskEmailConsultationFormId(): string {
    return this.appConfig?.externalAppConfig?.zendeskEmailConsultationFormId || '';
  }

  public getZendeskManualEvaluationServiceRequestFormId(): string {
    return this.appConfig?.externalAppConfig?.zendeskManualEvaluationServiceRequestFormId || '';
  }

  public getZendeskProductSupportFormId(): string {
    return this.appConfig?.externalAppConfig?.zendeskProductSupportFormId || '';
  }

  public getWalkMeScriptUrl(): string {
    return this.appConfig?.externalAppConfig?.walkMeScriptUrl || '';
  }

  public getGoogleTagManagerId(): string {
    return this.appConfig?.externalAppConfig?.gtmId || '';
  }

  public getAmplitudeKey(): string {
    return this.appConfig?.externalAppConfig?.amplitudeKey || '';
  }

  public getDownloadToolsUrl(): string {
    return this.appConfig?.externalAppConfig?.downloadToolsUrl || '';
  }

  public getDownloadToolsIndexPath(): string {
    return this.appConfig?.externalAppConfig?.downloadToolsIndexPath || '';
  }

  public getDownloadToolsDcaPath(): string {
    return this.appConfig?.externalAppConfig?.downloadToolsDcaPath || '';
  }

  public isTenantEnabled(): boolean {
    return this.appConfig?.tenantConfig?.isTenantEnabled || false;
  }

  public getTenantBaseUrl(): string {
    return this.appConfig?.tenantConfig?.baseUrl;
  }

  public getMaximumDownloadablePagesPerScan(): number {
    return this.appConfig?.scanConfig?.maximumDownloadablePagesPerScan;
  }

  public getDefaultPdfPagesTestLimit(): number {
    return this.appConfig?.pdfPagesTestConfig?.defaultPdfPagesTestLimit;
  }

  public getDefaultMonitoringPagesPerDPLimit(): number {
    return this.appConfig?.monitoringConfig?.defaultMonitoringPagesPerDPLimit;
  }

  public getDefaultAcademySeatLimit(): number {
    return this.appConfig?.academyConfig?.defaultSeatLimit;
  }

  public getMinAcademySeatLimit(): number {
    return this.appConfig?.academyConfig?.minSeatLimit;
  }

  public getMaxAcademySeatLimit(): number {
    return this.appConfig?.academyConfig?.maxSeatLimit;
  }

  public getAbsolutePdfPagesTestLimit(): number {
    return this.appConfig?.pdfPagesTestConfig?.absolutePdfPagesTestLimit;
  }

  public getAbsoluteMonitoringPagesPerDPLimit(): number {
    return this.appConfig?.monitoringConfig?.absoluteMonitoringPagesPerDPLimit;
  }

  public getAbsoluteMonitoringPagesPerDPLimitWithFF(): number {
    return this.appConfig?.monitoringConfig?.absoluteMonitoringPagesPerDPLimitWithFF;
  }

  public isNewSecurityEnabled(): boolean {
    return this.appConfig?.isNewSecurityEnabled;
  }

  public getMaxNumberOfUserApiKeys(): number {
    return this.appConfig?.apiConfig?.maxNumberOfUserApiKeys;
  }

  public getMaxActionsOrScanPoints(): number {
    return this.appConfig?.automatedUserFlowConfig?.maxActionsOrScanPoints;
  }

  public getAcademyDefaultContentTag(): string {
    return this.appConfig?.academyConfig?.defaultContentTag;
  }

  public getHarnessSDKClientKey(): string {
    return this.appConfig?.externalAppConfig?.harnessClientSDKKey;
  }

  public getPendoApiKey(): string {
    return this.appConfig?.externalAppConfig?.pendoKey;
  }

  public getUserWayAccount(): string {
    return this.appConfig?.externalAppConfig?.userWayAccount;
  }
}
