import { difference } from 'lodash';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DsInputStates } from '@levelaccess/design-system';

import { AccessibilityAuditToolNames } from '../../../../shared/constants/audit-tool';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

@Component({
  selector: 'app-testing-tool-filter',
  templateUrl: './testing-tool-filter.component.html',
})
export class TestingToolFilterComponent {
  @Input() public set currentSelection(selection: AccessibilityAuditToolNames) {
    if (SharedCommonUtility.notNullish(selection)) {
      this.currentValue = selection;
    }
  }
  @Input() public toolsToExclude: AccessibilityAuditToolNames[];
  @Output() public onToolSelected: EventEmitter<AccessibilityAuditToolNames>;

  public DsInputStates: typeof DsInputStates;
  public auditToolNames: typeof AccessibilityAuditToolNames;
  public currentValue: AccessibilityAuditToolNames;

  constructor() {
    this.DsInputStates = DsInputStates;
    this.auditToolNames = AccessibilityAuditToolNames;
    this.onToolSelected = new EventEmitter<AccessibilityAuditToolNames>();
    this.currentValue = AccessibilityAuditToolNames.ibm;
  }

  public get toolsToList(): AccessibilityAuditToolNames[] {
    const auditToolNamesKeys: AccessibilityAuditToolNames[] = Object.values(this.auditToolNames);
    let filteredAuditTools: AccessibilityAuditToolNames[] = auditToolNamesKeys;
    if (SharedCommonUtility.notNullishOrEmpty(this.toolsToExclude)) {
      filteredAuditTools = difference(auditToolNamesKeys, this.toolsToExclude);
    }

    return filteredAuditTools;
  }

  public onToolSelect(toolName: AccessibilityAuditToolNames): void {
    this.onToolSelected.emit(toolName);
    this.currentValue = toolName;
  }
}
