import { Component, Input } from '@angular/core';
import { IOrderedListItemData } from '../ordered-list-cell/ordered-list-cell.component';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface ILinksOrderedListItemData extends IOrderedListItemData {
  routerLink: string;
}

export interface ILinksOrderedListCellConfig extends ICellConfig {
  items: ILinksOrderedListItemData[];
}

@Component({
  selector: 'table-cell-links-ol',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <ol #ref>
      <li *ngFor="let item of config.items">
        <a [routerLink]="item.routerLink">{{ item.text }}</a>
      </li>
    </ol>
  `,
})
export class LinksOrderedListCellComponent extends BaseCellComponent {
  @Input() public config: ILinksOrderedListCellConfig;
}
