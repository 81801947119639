import { HttpResponse } from '@angular/common/http';

import { ResourceData } from '../interfaces/resource-data';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';

interface ResourceDownloadOptions {
  defaultExtension?: string;
  insertDateInFileName?: boolean;
  customDate?: Date;
}

export class ResourceUtility {
  private static insertDateInFileName(fileName: string, defaultExtension?: string, customDate?: Date): string {
    const date: Date = customDate ?? new Date();
    const dateAsString: string = `${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;

    const index: number = fileName.lastIndexOf('.');
    const fileBaseName: string = index >= 0 ? fileName.substring(0, index) : fileName;
    const fileExtension: string = index >= 0 ? fileName.substring(index) : defaultExtension || '';

    return `${fileBaseName}_${dateAsString}.${fileExtension}`;
  }

  private static maybeInsertDateInFileName(filename: string, options?: ResourceDownloadOptions): string {
    if (SharedCommonUtility.isNullish(options) || options.insertDateInFileName !== true) {
      return filename;
    }
    return ResourceUtility.insertDateInFileName(filename, options.defaultExtension, options.customDate);
  }

  public static fromResponse(response: HttpResponse<ArrayBuffer>): ResourceData {
    const resource = new ResourceData();

    if (response.headers) {
      resource.mimeType = response.headers.get('Content-Type');
      const contentDisposition = response.headers.get('Content-Disposition');

      if (!SharedCommonUtility.isNullish(contentDisposition)) {
        const matchArr: RegExpMatchArray = contentDisposition.match(/filename="(.+)"/);
        if (matchArr.length >= 2) {
          // Set filename if it's supplied in Content-Disposition
          resource.filename = matchArr[1];
        }
      }
    }

    if (SharedCommonUtility.isNullishOrEmpty(resource.filename)) {
      resource.filename = new URL(response.url).pathname.split('/').pop();
    }

    resource.httpStatus = response.status;
    resource.data = response.body;

    return resource;
  }

  public static downloadFile(filename: string, blob: Blob): void {
    /*
    if (typeof window.navigator.msSaveOrOpenBlob === 'function') {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }
     */

    const windowURL: any = window.URL || (window as any).webkitURL;
    const save: HTMLAnchorElement = document.createElement('a');
    const file = windowURL.createObjectURL(blob);

    save.href = file;
    save.download = filename;
    save.style.cssText = 'display: none;';

    document.body.appendChild(save);

    const eventProperties: MouseEventInit = {
      bubbles: false,
      cancelable: true,
      view: window,
    };

    const customEvent = new MouseEvent('click', eventProperties);

    save.dispatchEvent(customEvent);
    windowURL.revokeObjectURL(file);
    document.body.removeChild(save);
  }

  public static downloadText(text: string, filename: string, options?: ResourceDownloadOptions): void {
    const blob: Blob = new Blob([text]);
    ResourceUtility.downloadFile(ResourceUtility.maybeInsertDateInFileName(filename, options), blob);
  }

  public static downloadResponse(response: HttpResponse<ArrayBuffer>): void {
    const resource: ResourceData = ResourceUtility.fromResponse(response);
    const blob: Blob = new Blob([resource.data], { type: resource.mimeType });
    ResourceUtility.downloadFile(resource.filename, blob);
  }

  public static triggerDownload(resource: ResourceData, defaultExtension?: string): void {
    const blob: Blob = new Blob([resource.data], { type: resource.mimeType });
    ResourceUtility.downloadFile(ResourceUtility.insertDateInFileName(resource.filename, defaultExtension), blob);
  }
}
