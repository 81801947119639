<ng-template #switchButtonTemplate>
  <div class="d-flex justify-content-between align-items-center switch-button-container">
    <button
      type="button"
      role="checkbox"
      [name]="name"
      [disabled]="disabled"
      [id]="name"
      [attr.aria-checked]="value"
      [attr.aria-labelledby]="name + 'Label'"
      class="switch-button switch round"
      (click)="onValueChanged()"
    >
      <span class="slider round" [attr.aria-hidden]="true"></span>
    </button>
    <span class="status">{{ status | translate }}</span>
  </div>
</ng-template>

<ng-template #defaultLabelTemplate let-name let-nonClickableLabel="nonClickableLabel">
  <label [for]="!nonClickableLabel ? name : ''" [id]="name + 'Label'">
    <ng-content select=".switch-label"></ng-content>
  </label>
</ng-template>

<ng-template #defaultSwitchButtonContainerTemplate let-switchButtonTemplate>
  <ng-template [ngTemplateOutlet]="switchButtonTemplate"> </ng-template>
</ng-template>

<div class="switch-container" [ngClass]="switchContainerClass">
  <ng-template
    [ngTemplateOutlet]="labelTemplate ?? defaultLabelTemplate"
    [ngTemplateOutletContext]="{ $implicit: name, nonClickableLabel: this.nonClickableLabel }"
  >
  </ng-template>

  <ng-template
    [ngTemplateOutlet]="switchButtonContainerTemplate ?? defaultSwitchButtonContainerTemplate"
    [ngTemplateOutletContext]="{ $implicit: switchButtonTemplate }"
  >
  </ng-template>
</div>
