<app-modal-container>
  <div class="modal-header-content">
    {{ options.headerKey | translate }}
  </div>
  <div class="modal-body-content">
    <ng-container *ngIf="options.image; else textOnly">
      <div class="row align-items-center">
        <div class="col-sm">
          <img [src]="options.image" [alt]="options.imageAltKey | translate" class="info-image" />
        </div>
        <div class="col-sm">
          <p class="m-auto body-text body-content-heading">
            {{ options.bodyHeader | translate }}
          </p>
        </div>
      </div>
    </ng-container>
    <ng-template #textOnly>
      <div class="row" *ngIf="options.bodyHeader">
        <p class="col-auto body-text body-content-heading">
          {{ options.bodyHeader | translate }}
        </p>
      </div>
      <div class="row" *ngIf="options.bodyText">
        <p class="col-auto text-black body-text">
          {{ options.bodyText | translate }}
        </p>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer-content">
    <div class="row">
      <div class="col-auto">
        <button class="btn btn-outline-primary font-weight-500" (click)="container.closeModal()">
          {{ options.buttonKey | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal-container>
