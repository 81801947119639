import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DsButtonVariants } from '@levelaccess/design-system';
import { IFailedTask } from '../../../../../../shared/interfaces/task.interface';

@Component({
  templateUrl: './disconnected-tasks-modal.component.html',
  styleUrls: ['disconnected-tasks-modal.component.scss'],
})
export class DisconnectedTasksModalComponent {
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;

  @Input()
  public failedTasks: IFailedTask[];

  @Output()
  public confirmed: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected modal: NgbActiveModal) {}

  protected cancelClicked(): void {
    this.modal.dismiss();
  }

  protected confirmClicked(): void {
    this.confirmed.emit();
    this.modal.close();
  }
}
