<div class="row card">
  <div class="col-12 card-body">
    <div class="page-loading-wrapper d-flex flex-column align-items-center justify-content-center">
      <ng-container [ngSwitch]="loadingState">
        <ng-container *ngSwitchCase="pageLoadingState.loading">
          <svg height="80" aria-hidden="true">
            <use xlink:href="#page-loading"></use>
          </svg>
          <h3 class="margin-top-lg mb-3">{{ 'page_loading' | translate }}</h3>
          <p>{{ 'page_loading_description' | translate }}</p>
          <p></p
        ></ng-container>

        <ng-container *ngSwitchCase="pageLoadingState.error">
          <svg height="80" aria-hidden="true">
            <use xlink:href="#page-loading-error"></use>
          </svg>
          <h3 class="margin-top-lg mb-2">{{ 'page_loading_error' | translate }}</h3>
          <p class="text-center" [innerHTML]="'page_loading_error_description' | translateHtml"></p>
          <p>
            <a [routerLink]="['/', Api.portfolio]">
              <button class="btn btn-primary" type="button" (click)="reloadPage()">{{ 'reload_this_page' | translate }}</button>
            </a>
          </p></ng-container
        >
      </ng-container>
    </div>
  </div>
</div>
