import { TranslateService } from '../../translate/translate.service';
import { DesignRuleLibrary } from '../../../../shared/constants/design-rule';

export class MasterLibraryHelper {
  public static getLibraryName(libraryName: string, translateService: TranslateService): string {
    if (Object.values<string>(DesignRuleLibrary).includes(libraryName)) {
      return translateService.instant(`library_${libraryName}`);
    }

    return libraryName;
  }
}
