import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MaintenanceModule } from './app/maintenance/maintenance.module';

if (environment.production) {
  enableProdMode();
}

function success(): void {
  console.debug(environment.APP.NAME + ' bootstrap success');
}

function error(err: any): void {
  console.error(environment.APP.NAME + ' bootstrap error', err);
  MaintenanceModule.caughtError = err;
  platformBrowserDynamic()
    .bootstrapModule(MaintenanceModule)
    .then(() => console.debug(environment.APP.NAME + ' is under maintenance'));
}

platformBrowserDynamic().bootstrapModule(AppModule).then(success).catch(error);
