import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { catchError, map, startWith, take, tap } from 'rxjs/operators';

import { ITableConfig, ITableRow, SortEvent } from '../../../../components/table/ngb-table/utilities/ngb-table.interface';
import { $workspace, workspaceStatus } from '../../../../../../shared/constants/workspace';
import { TranslateService } from '../../../../translate/translate.service';
import { NgbTableUtilities } from '../../../../components/table/ngb-table/utilities/ngb-table.utilities';
import { DEFAULT_ENTRY_LIMITS } from '../../../../constants/form.constants';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { $sortingOrder } from '../../../../../../shared/constants/sort';
import { IWorkspaceWithDpCount } from '../../../../../../shared/interfaces/workspace.interface';
import { Api } from '../../../../../../shared/constants/api';
import { IUserServerResponse, IUser } from '../../../../../../shared/interfaces/user.interface';
import { UserService } from '../../../../services/user.service';
import { $user } from '../../../../../../shared/constants/user';
import { NotificationPosition } from '../../../../models/notification.model';
import { NotificationService } from '../../../../services/notification.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { BusMessageChannels, BusMessageService } from '../../../../services/bus-message.service';
import { WorkspaceService } from '../../../../services/workspace.service';
import { CheckboxConfirmationModalComponent } from '../../../../components/modals/checkbox-confirmation-modal/checkbox-confirmation-modal.component';
import { ModalService } from '../../../../services/modal.service';
import { AlertModalComponent } from '../../../../components/modals/alert-modal/alert-modal.component';
import { AlertModalOptions } from '../../../../interfaces/modal-dialog.interface';
import { LoadErrorHandler } from '../../../../services/load-error-handler';
import { AclSecurityAdapter } from '../../../../services/acl.service';
import { RequiredSecurities } from '../../../../../../shared/constants/required-securities';
import { WorkspaceUserSearchFields } from '../../../../../../shared/constants/workspace-user.constants';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../../shared/utils/linked-property.utility';
import { IDropdownItem } from '../../../../components/table/ngb-table/cells/dropdown-cell/dropdown-cell.component';
import { UserAclService } from '../../../../services/user-acl.service';

@Component({
  selector: 'app-tenant-workspaces-table',
  templateUrl: 'tenant-workspaces-table.component.html',
})
export class TenantWorkspacesTableComponent implements OnInit, OnDestroy {
  @Input() private workspaces$: Observable<IWorkspaceWithDpCount[]>;
  @Input() private reloadWorkspaces$: BehaviorSubject<void>;
  @Input() private isFiltered: boolean = false;
  private subscriptions: Subscription;
  private slicedWorkspaces$: Observable<IWorkspaceWithDpCount[]>;
  private activeWorkspacesCount: number;
  private selectedWorkspace: string;

  public tableData$: Observable<ITableRow[]>;
  public workspaceCount$: Observable<number>;
  public page$: BehaviorSubject<number>;

  public get page(): number {
    return this.page$.value;
  }

  public set page(value: number) {
    this.page$.next(value);
  }

  public pageSize$: BehaviorSubject<number>;

  public get pageSize(): number {
    return this.pageSize$.value;
  }

  public set pageSize(value: number) {
    this.pageSize$.next(value);
  }

  public userId: string;
  public errorResponseMessages: Array<string>;
  public errorMessage: string;

  public pageSizeLimits: readonly number[];
  public onSort$: BehaviorSubject<SortEvent>;

  public constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private errorHandlerService: ErrorHandlerService,
    private busMessageService: BusMessageService,
    private workspaceService: WorkspaceService,
    private modalService: ModalService,
    private loadErrorHandler: LoadErrorHandler,
    private userAclService: UserAclService,
  ) {
    this.userId = '';
    this.errorResponseMessages = [];
    this.errorMessage = '';
    this.selectedWorkspace = '';
    this.subscriptions = new Subscription();
    this.pageSizeLimits = DEFAULT_ENTRY_LIMITS;
    this.page$ = new BehaviorSubject<number>(1);
    this.pageSize$ = new BehaviorSubject<number>(DEFAULT_ENTRY_LIMITS[DEFAULT_ENTRY_LIMITS.length - 1]);
    this.onSort$ = new BehaviorSubject<SortEvent>(null);
    this.activeWorkspacesCount = 0;
  }

  private toTableData(entries: IWorkspaceWithDpCount[], hasEditPermissions: boolean, hasDeletePermissions: boolean): ITableRow[] {
    const closedWorkspaceDescription = this.translateService.instant('closed_workspace_description');
    return entries.map((entry: IWorkspaceWithDpCount) => {
      if (entry.status === workspaceStatus.active) {
        const editWorkspaceItem: IDropdownItem = {
          label: this.translateService.instant(`edit_action`),
          clickListener: (): void => this.editSelectedWorkspace(entry),
          disabled: false,
        };
        const closeWorkspaceItem: IDropdownItem = {
          label: this.translateService.instant(`action_close`),
          clickListener: async (): Promise<void> => await this.handleCloseWorkspace(entry),
          disabled: this.isWorkspaceUsedNow(entry) || this.activeWorkspacesCount <= 1,
        };

        const dropdownItems: IDropdownItem[] = [
          ...(hasEditPermissions ? [editWorkspaceItem] : []),
          ...(hasDeletePermissions ? [closeWorkspaceItem] : []),
        ];

        if (dropdownItems.length === 0) {
          dropdownItems.push({
            disabled: true,
            label: this.translateService.instant('no_actions_available'),
            clickListener: () => {},
          });
        }

        return {
          data: {
            [$workspace.name]: NgbTableUtilities.linkCell({
              text: entry[$workspace.name],
              attributes: {
                routerLink: `./${Api.workspaces}/${entry[$workspace._id]}`,
              },
            }),
            [$workspace.description]: NgbTableUtilities.textCell({
              text: entry[$workspace.description],
            }),
            [$workspace.digitalPropertiesCount]: NgbTableUtilities.textCell({
              text: entry[$workspace.digitalPropertiesCount]?.toString(),
            }),
            actions: NgbTableUtilities.dropdownCell({
              ellipsisIcon: true,
              classes: ['btn-link', 'px-0'],
              dropdownItems: dropdownItems,
            }),
          },
        };
      }

      return {
        data: {
          [$workspace.name]: NgbTableUtilities.textCell({
            text: entry[$workspace.name],
          }),
          [$workspace.description]: NgbTableUtilities.textCell({
            text: closedWorkspaceDescription,
          }),
          [$workspace.digitalPropertiesCount]: NgbTableUtilities.textCell({
            text: '',
          }),
          actions: NgbTableUtilities.textCell({
            text: '',
          }),
        },
        styles: {
          backgroundColor: '#e9ecef',
        },
      };
    });
  }

  private loadTable(): void {
    const hasEditPermissions$: Observable<boolean> = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter.useEntityLevel().useFunctionalActions(RequiredSecurities.Workspaces_Update.functionalActions).check(),
        ),
      );

    const hasDeletePermissions$: Observable<boolean> = this.userAclService
      .createCheckAccessForCurrentUser()
      .pipe(
        map((adapter: AclSecurityAdapter): boolean =>
          adapter.useEntityLevel().useFunctionalActions(RequiredSecurities.Workspaces_Delete.functionalActions).check(),
        ),
      );

    this.tableData$ = combineLatest([this.slicedWorkspaces$, hasEditPermissions$, hasDeletePermissions$]).pipe(
      map(
        ([slicedWorkspaces, hasEditPermissions, hasDeletePermissions]: [
          IWorkspaceWithDpCount[],
          boolean,
          boolean,
        ]): ITableRow[] => {
          return this.toTableData(slicedWorkspaces, hasEditPermissions, hasDeletePermissions);
        },
      ),
    );
  }

  private isWorkspaceUsedNow(workspace: IWorkspaceWithDpCount): boolean {
    return this.selectedWorkspace === '' ? false : workspace._id === this.selectedWorkspace;
  }

  private onRemoveWorkspaceSuccess(workspaceClient: IWorkspaceWithDpCount): void {
    this.reloadWorkspaces$.next();
    const message: string = this.translateService.instant('removed_selected_workspace_success', [workspaceClient.name]);

    this.errorMessage = '';
    this.errorResponseMessages = [];
    this.notificationService.success(message, NotificationPosition.Toast);

    this.busMessageService.to(BusMessageChannels.workspaceDeleted).next();
    this.loadTable();
  }

  private onRemoveWorkspaceError(error: HttpErrorResponse): void {
    this.loadErrorHandler.handleError(
      this.translateService.instant('error_while_removing_workspace'),
      error,
      TenantWorkspacesTableComponent.name,
    );
  }

  private isOtherUsersPresent(users: IUser[]): boolean {
    // if we don't have a userId, assume that there are other users in the workspaces besides
    // the current user
    if (SharedCommonUtility.isNullishOrEmpty(this.userId)) {
      return true;
    }

    return users.some((user: IUser): boolean => user[$user._id] !== this.userId);
  }

  private displayUsersPresentAlert(): void {
    const modalOptions: AlertModalOptions = {
      headerKey: 'close_workspace_users_present_header',
      bodyKey: 'close_workspace_users_present_subtitle',
      bodyHighlightKey: 'close_workspace_users_present_warning',
      closeBtnKey: 'label_close',
    };
    const alertModal: AlertModalComponent = this.modalService.open(AlertModalComponent).instance;
    alertModal.init(modalOptions);
  }

  private async removeWorkspace(workspaceClient: IWorkspaceWithDpCount): Promise<void> {
    const confirmModal: CheckboxConfirmationModalComponent = this.modalService.open(CheckboxConfirmationModalComponent).instance;
    confirmModal.init(
      'label_close_workspace',
      'close_workspace_subtitle',
      'close_workspace_warning',
      'close_workspace_confirmation',
      'label_close_workspace',
    );

    await confirmModal.container.onClose$.pipe(take(1)).toPromise();

    if (confirmModal.confirmed && !confirmModal.cancelled) {
      this.subscriptions.add(
        this.workspaceService.removeWorkspace(workspaceClient._id).subscribe({
          next: this.onRemoveWorkspaceSuccess.bind(this, workspaceClient),
          error: this.onRemoveWorkspaceError.bind(this),
        }),
      );
    }
  }

  private async handleCloseWorkspace(workspace: IWorkspaceWithDpCount): Promise<void> {
    const onGetWorkspaceUsersSuccess = (users: IUser[]): void => {
      if (this.isOtherUsersPresent(users)) {
        this.displayUsersPresentAlert();
      } else {
        this.removeWorkspace(workspace);
      }
    };

    const onGetWorkspaceUsersError = (response: HttpErrorResponse): void => {
      this.loadErrorHandler.handleError(
        this.translateService.instant('unable_to_get_all_workspace_users'),
        response,
        TenantWorkspacesTableComponent.name,
      );
    };

    this.subscriptions.add(
      this.workspaceService.getUsers(workspace._id, { [WorkspaceUserSearchFields.includeStaffUsers]: true }).subscribe({
        next: onGetWorkspaceUsersSuccess.bind(this),
        error: onGetWorkspaceUsersError.bind(this),
      }),
    );
  }

  private editSelectedWorkspace(workspaceClient: IWorkspaceWithDpCount): void {
    this.router
      .navigate([Api.workspaces, workspaceClient._id, Api.edit], { relativeTo: this.activatedRoute })
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  public get tableConfig(): ITableConfig {
    return {
      columns: {
        [$workspace.name]: {
          translationKey: 'label_workspace_name',
          sortingEnabled: true,
          styles: {
            width: '20%',
          },
        },
        [$workspace.description]: {
          translationKey: 'label_description',
          sortingEnabled: false,
        },
        [$workspace.digitalPropertiesCount]: {
          translationKey: 'digital_properties',
          sortingEnabled: false,
        },
        actions: {
          translationKey: 'table_column_actions',
          styles: {
            width: '15%',
          },
        },
      },
      caption: this.translateService.instant('table_column_workspaces'),
      emptyState: {
        title: this.translateService.instant(
          this.isFiltered ? 'no_workspaces_found_with_that_name_url' : 'no_workspaces_for_tenant',
        ),
        subtitle: this.translateService.instant(
          this.isFiltered ? 'no_digital_properties_found_description' : 'no_workspaces_for_tenant_hint',
        ),
      },
    };
  }

  public ngOnInit(): void {
    this.slicedWorkspaces$ = combineLatest([this.workspaces$, this.page$, this.pageSize$, this.onSort$]).pipe(
      map(([workspaces, page, pageSize, onSort]: [IWorkspaceWithDpCount[], number, number, SortEvent]) => {
        const sortedWorkspaces: IWorkspaceWithDpCount[] = [...workspaces];
        this.activeWorkspacesCount = sortedWorkspaces.filter(
          (workspace: IWorkspaceWithDpCount) => workspace.status === workspaceStatus.active,
        ).length;
        if (!SharedCommonUtility.isNullish(onSort) && onSort.direction !== $sortingOrder.all) {
          sortedWorkspaces.sort((a: IWorkspaceWithDpCount, b: IWorkspaceWithDpCount) => {
            return a[$workspace.name].localeCompare(b[$workspace.name]) * (onSort.direction === $sortingOrder.desc ? -1 : 1);
          });
        }
        return sortedWorkspaces.slice((page - 1) * pageSize, page * pageSize);
      }),
    );
    this.loadTable();
    this.workspaceCount$ = this.workspaces$.pipe(
      map((workspaces: IWorkspaceWithDpCount[]) => workspaces.length),
      startWith(0),
    );

    const onGetUserSuccess = (user: IUserServerResponse): void => {
      this.userId = user?.[$user._id] ?? '';
    };

    const onGetUserError = (error: HttpErrorResponse): void => {
      this.loadErrorHandler.handleError('error_getting_me_profile', error, TenantWorkspacesTableComponent.name);
    };

    this.subscriptions.add(
      this.userService.getAuthenticatedUserProfile().subscribe({
        next: onGetUserSuccess.bind(this),
        error: onGetUserError.bind(this),
      }),
    );

    const onGetWorkspacesError = (error: HttpErrorResponse): void => {
      this.loadErrorHandler.handleError(
        this.translateService.instant('error_while_getting_workspace_data'),
        error,
        TenantWorkspacesTableComponent.name,
      );
    };

    this.subscriptions.add(
      this.activatedRoute.queryParams
        .pipe(
          map(LinkedPropertyUtility.fromLinkedPropertyQueryParam),
          tap((linkedPropertyData: ILinkedPropertyData): void => {
            this.selectedWorkspace = linkedPropertyData[Api.workspaceId];
          }),
          catchError(onGetWorkspacesError.bind(this)),
        )
        .subscribe(),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
