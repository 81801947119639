import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { IModal } from '../../../../components/modals/modal.interface';
import { ModalContainerComponent } from '../../../../components/modals/modal-container.component';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { $tenant } from '../../../../../../shared/constants/tenant';

export interface ITenantRedirectUrl {
  url: string;
  domain: string;
}

@Component({
  selector: 'app-tenant-redirect-modal',
  templateUrl: './tenant-redirect-modal.component.html',
})
export class TenantRedirectModalComponent implements IModal, OnInit, OnDestroy {
  private subscription: Subscription;
  private intervalId: number;

  @Input()
  public redirectUrls: ITenantRedirectUrl[];
  @ViewChild(ModalContainerComponent, { static: true })
  public container: ModalContainerComponent;
  @Input()
  public countdownSeconds: number = 5;
  public uniqueRedirect: boolean;
  public form: UntypedFormGroup;
  public $tenant: typeof $tenant;
  public selectedUrl$: ReplaySubject<string>;

  public constructor(private formBuilder: UntypedFormBuilder) {
    this.subscription = new Subscription();
    this.uniqueRedirect = true;
    this.$tenant = $tenant;
    this.selectedUrl$ = new ReplaySubject<string>();
  }

  public ngOnInit(): void {
    if (this.redirectUrls.length === 1) {
      this.intervalId = window.setInterval(() => {
        this.countdownSeconds--;
        if (this.countdownSeconds <= 0) {
          this.container.closeModal();
        }
      }, 1000);
      this.selectedUrl$.next(this.redirectUrls[0].url);
    } else {
      this.uniqueRedirect = false;
      this.form = this.formBuilder.group({
        [$tenant.domain]: this.formBuilder.control(this.redirectUrls[0].url),
      });
      this.subscription.add(
        this.form.get($tenant.domain).valueChanges.pipe(startWith(this.redirectUrls[0].url)).subscribe(this.selectedUrl$),
      );
    }
    this.container.closeLabel = 'label_ok';
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (!SharedCommonUtility.isNullish(this.intervalId)) {
      window.clearInterval(this.intervalId);
    }
  }
}
