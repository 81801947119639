import { isEqual } from 'lodash';

import { SharedCommonUtility } from './common.utility';

type ObjectLiteral = {
  [P in string]: ObjectLiteral;
};

export class SharedObjectUtility {
  private static recursivelyFlattenWithLeafProperties<T extends string>(
    source: ObjectLiteral,
    target: Record<T, T[]>,
    throwOnRepeatingProperties: boolean = false,
  ): T[] {
    const result: T[] = [];
    for (const property of Object.getOwnPropertyNames(source)) {
      const leafProperties: T[] = this.recursivelyFlattenWithLeafProperties(source[property], target, throwOnRepeatingProperties);

      if (throwOnRepeatingProperties && SharedCommonUtility.notNullish(target[property])) {
        throw new Error(
          `[SharedObjectUtility.flattenWithLeafProperties] has repeating nested property "${property}" in source object`,
        );
      }

      if (leafProperties.length === 0) {
        target[property] = [];
        result.push(property as T);
      } else {
        target[property] = leafProperties;
        result.push(...leafProperties);
      }
    }

    return result;
  }

  public static getTypeOf(obj?: any): string {
    const defaultType: string = 'unknown';

    if (arguments.length !== 1) {
      return defaultType;
    }

    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
  }

  public static isNumber(value: any): boolean {
    if (SharedObjectUtility.getTypeOf(value) === 'number') {
      return Number.isFinite(value);
    }

    if (SharedObjectUtility.getTypeOf(value) === 'string') {
      return value.trim().length > 0 && Number.isFinite(Number(value));
    }

    return false;
  }

  public static getProperty(objectItem: any, keyPath: string): any {
    const properties: string[] = keyPath.split('.');
    const len: number = properties.length;
    let obj: any = objectItem;

    for (let i: number = 0; i < len; i += 1) {
      if (!obj || !obj.hasOwnProperty(properties[i])) {
        return undefined;
      }
      obj = obj[properties[i]];
    }

    return obj;
  }

  public static encodeKey(key: string): string {
    return key.replace(/\\/g, '\\\\').replace(/\$/g, '\\u0024').replace(/\./g, '\\u002e');
  }

  public static decodeKey(key: string): string {
    return key
      .replace(/\\u002e/g, '.')
      .replace(/\\u0024/g, '$')
      .replace(/\\\\/g, '\\');
  }

  /**
   * This function flattens object's nested properties into a single level property object
   * with corresponding nested leaf properties listed. E.g. the object below is considered
   * to have `bar`, `zoo` and `jar` leaf properties, because they don't have nested properties
   * on their own.
   * ```
   * {
   *    root: {
   *      foo: {
   *        bar: {},
   *        zoo: {},
   *      },
   *      jar: {},
   *    },
   * }
   * ```
   * This function will flatten an object above like it is shown below.
   * ```
   * {
   *    root: ['bar', 'zoo', 'jar'],
   *    foo: ['bar', 'zoo'],
   *    bar: [],
   *    zoo: [],
   *    jar: [],
   * }
   * ```
   * As it is seen above all nested properties of the source object are now flattened and
   * defined as the top level properties. Each property has a value with the list of
   * corresponding leaf properties from the source object
   *
   * @param source any object literal with nested object properties
   * @param throwOnRepeatingProperties
   */
  public static flattenWithLeafProperties<T extends string>(
    source: ObjectLiteral,
    throwOnRepeatingProperties: boolean = false,
  ): Record<T, T[]> {
    const target: Record<T, T[]> = Object.create({});
    this.recursivelyFlattenWithLeafProperties(source, target, throwOnRepeatingProperties);
    return target;
  }

  public static isShallowEqual(value: unknown, compareWith: unknown): boolean {
    if (typeof value !== 'object' || typeof compareWith !== 'object' || value === null || compareWith === null) {
      return false;
    }

    return Object.keys(value)
      .filter((key: string): boolean => compareWith.hasOwnProperty(key))
      .every((key: string): boolean => isEqual(value[key], compareWith[key]));
  }
}
