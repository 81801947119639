import { $severity } from '../constants/accessibility';
import { ApiQueryOption } from '../constants/api';
import { $auditFinding, AuditFindingQAStatus } from '../constants/audit-finding';
import { AccessibilityAuditToolNames } from '../constants/audit-tool';
import { $digitalProperty } from '../constants/digital-properties';
import { $findingCounts } from '../constants/finding-counts';
import { $workspaceFindingCount } from '../constants/workspace-finding-count';
import {
  $countByActiveDigitalProperty,
  $digitalPropertyFindingCount,
  $flawCounts,
  $getWorkspaceDashboardResponse,
  $manualFindingSummary,
  $mostViolatedCounts,
  $remediationProgressCount,
} from '../constants/ws-dashboard.constants';
import { IAccessibilityPolicyThresholds } from './accessibility-policy.interface';
import { ICountBySeverity } from './count-by-severity.interface';
import { IFlawCountByDpToolRuleStatus } from './flaw.interface';
import { IPreviousData } from './workspace-finding-count.interface';

export interface IRemediationProgressCount {
  [$remediationProgressCount.resolved]: number;
  [$remediationProgressCount.unresolved]: number;
}

export interface IWorkspaceRemediationProgress {
  [$findingCounts.auto]: IRemediationProgressCount;
  [$findingCounts.manual]: IRemediationProgressCount;
}

export interface IPreviousWorkspaceRemediationProgress extends IWorkspaceRemediationProgress {
  [$workspaceFindingCount.computedDate]: Date;
}

export interface IFlawCounts {
  [$flawCounts.flawCountByDpToolRuleStatuses]: IFlawCountByDpToolRuleStatus[];
  [$flawCounts.defaultPolicyFlawCountBySeverityTime]: ICountBySeverity;
  [$flawCounts.userPolicyFlawCountBySeverityTime]: ICountBySeverity;
}

export interface IUnresolvedIssuesCount {
  [$findingCounts.auto]: ICountBySeverity;
  [$findingCounts.manual]: ICountBySeverity;
}

export interface IWorkspaceDashboardData {
  [$workspaceFindingCount.remediationProgress]: IWorkspaceRemediationProgress;
  [$workspaceFindingCount.previousData]?: IPreviousData;
  [$workspaceFindingCount.mostViolatedSuccessCriteria]: {
    [$findingCounts.manual]: IMostViolatedSuccessCriteriaCounts[];
    [$findingCounts.auto]: IMostViolatedSuccessCriteriaCounts[];
  };
  [$workspaceFindingCount.countByActiveDigitalProperty]: ICountByActiveDigitalProperty;
  [$workspaceFindingCount.mostCompromisedDigitalProperties]: IMostCompromisedDigitalPropertyCounts[];
  [$workspaceFindingCount.agingUnresolvedIssues]: IUnresolvedIssuesCount;
  [$workspaceFindingCount.a11yPolicyThresholds]: IAccessibilityPolicyThresholds;
}

export interface IDigitalPropertyProgressData {
  [$digitalPropertyFindingCount.remediationProgress]: IWorkspaceRemediationProgress;
  [$digitalPropertyFindingCount.agingUnresolvedIssuesBySeverity]: IUnresolvedIssuesCount;
  [$digitalPropertyFindingCount.userPolicyAgingUnresolvedIssuesBySeverity]: IUnresolvedIssuesCount;
  [$digitalPropertyFindingCount.totalUnresolvedIssuesBySeverity]: IUnresolvedIssuesCount;
}

export interface IWorkspaceDashboardDataOptions {
  mostCompromisedDigitalPropertiesLimit?: number;
}

export interface IGetWorkspaceDashboardResponse {
  [$getWorkspaceDashboardResponse.dashboardData]?: IWorkspaceDashboardData;
  [$getWorkspaceDashboardResponse.updatedAt]?: number;
  [$getWorkspaceDashboardResponse.updateRequestedAt]?: number;
}

export interface IManualFindingSummary {
  [$manualFindingSummary.digitalPropertyId]: string;
  [$manualFindingSummary.digitalPropertyName]: string;
  [$manualFindingSummary.successCriteria]: string[];
  [$manualFindingSummary.isResolved]: boolean;
  [$manualFindingSummary.category]?: string;
  [$auditFinding.QAStatus]: AuditFindingQAStatus;
  [$auditFinding.severity]: $severity;
  [$auditFinding.createdAt]: Date;
}

export interface IMostCompromisedDigitalPropertyCounts {
  [$digitalProperty._id]: string;
  [$digitalProperty.name]: string;
  [$digitalPropertyFindingCount.severityCount]: ICountBySeverity;
  [$digitalPropertyFindingCount.rankDelta]?: number;
  [$digitalPropertyFindingCount.weeksInRank]?: number;
}

export interface IMostViolatedSuccessCriteriaCounts {
  [$mostViolatedCounts.successCriteria]: string;
  [$mostViolatedCounts.severityCount]: ICountBySeverity;
}

export interface ISeverityRuleIds {
  [$severity.critical]: string[];
  [$severity.high]: string[];
  [$severity.low]: string[];
}

export interface ICountByActiveDigitalProperty {
  [$countByActiveDigitalProperty.webPropertiesWithMonitoring]: number;
  [$countByActiveDigitalProperty.totalWebProperties]: number;
  [$countByActiveDigitalProperty.propertiesWithActiveManualEvaluations]: number;
  [$countByActiveDigitalProperty.totalProperties]: number;
}

export interface IGetWSDashboardQuery {
  [ApiQueryOption.toolName]: AccessibilityAuditToolNames;
}

export enum DashboardDataFrequency {
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
}
