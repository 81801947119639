<form [formGroup]="form">
  <div class="column-selector-content">
    <div class="d-flex justify-content-between">
      <div class="d-block">
        <h3>{{ 'issue_table_column_selection_title' | translate }}</h3>
        <span class="my-auto">{{ 'issue_table_column_selection_description' | translate: maxSelected }}</span>
      </div>
      <div class="d-block ms-3">
        <span class="d-inline-flex info-box border-primary">
          <span class="info-box-title d-block text-primary"
            >{{ currentSelectedCount }} / <span class="fw-normal"> {{ maxSelected }}</span></span
          >
          <span class="d-block"> {{ 'label_columns' | translate }}</span>
        </span>
      </div>
    </div>

    <div class="d-flex">
      <div *ngFor="let columnGroup of columnGroups" class="margin-top" [ngClass]="columnClass">
        <h4>{{ columnGroup.name | translate }}</h4>
        <div *ngFor="let column of columnGroup.columns" class="ms-0">
          <label class="label d-flex align-items-center margin-bottom-xs">
            <input type="checkbox" (change)="onSelectionChange(column.field)" [formControlName]="column.field" />
            <span class="checkbox-label">{{ column.translationKey | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-start mt-3">
      <button class="btn btn-primary" type="submit" (click)="onApply()">
        {{ 'apply' | translate }}
      </button>
      <button class="btn btn-outline-primary ms-3" (click)="onClose()">
        {{ 'form_cancel' | translate }}
      </button>
    </div>
  </div>
</form>
