export enum $clientLog {
  data = 'data',
  date = 'date',
  referer = 'referer',
  type = 'type',
  tenantId = 'tenantId',
  workspaceId = 'workspaceId',
  digitalPropertyId = 'digitalPropertyId',
  userId = 'userId',
  userName = 'userName',
  stack = 'stack',
  url = 'url',
}

export interface ITrackingConsoleRequest {
  [$clientLog.data]: string;
  [$clientLog.type]: string;
  [$clientLog.userId]: string;
  [$clientLog.url]: string;
  [$clientLog.tenantId]?: string;
  [$clientLog.workspaceId]?: string;
  [$clientLog.digitalPropertyId]?: string;
  [$clientLog.stack]?: string;
}
