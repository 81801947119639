import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SelectFieldContext } from '../../../../common-select/common-select.component';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface ISelectCellConfig extends ICellConfig {
  data$: Observable<Record<string, any>[]>;
  form: UntypedFormGroup;
  formField: string;
  nameKey: string;
  valueKey: string;
  placeholderLabel?: string;
}

@Component({
  selector: 'table-cell-select',
  template: `
    <app-common-select #ref [form]="config.form" [context]="context" [values]="config.data$ | async"></app-common-select>
  `,
})
export class SelectCellComponent extends BaseCellComponent implements OnInit {
  @Input() public config: ISelectCellConfig;

  public context: SelectFieldContext;

  public ngOnInit(): void {
    this.context = {
      field: this.config.formField,
      nameKey: this.config.nameKey,
      valueKey: this.config.valueKey,
      placeholderValue: this.config.placeholderLabel,
    };
  }
}
