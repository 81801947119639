export enum $securityGroup {
  _id = '_id',
  description = 'description',
  entities = 'entities',
  name = 'name',
  roleIds = 'roleIds',
  roles = 'roles',
  userIds = 'userIds',
  users = 'users',
  isStaffGroup = 'isStaffGroup',
  isServiceGroup = 'isServiceGroup',
  origin = 'origin',
  userUpdatedAt = 'userUpdatedAt',
}

export enum $securityGroups {
  groups = 'groups',
  _total = '_total',
}

export enum $securityEntity {
  _id = '_id',
  digitalPropertyId = 'digitalPropertyId',
  level = 'level',
  tenantId = 'tenantId',
  workspaceId = 'workspaceId',
}

export enum $securityGroupUser {
  _id = '_id',
  securityGroupId = 'securityGroupId',
  userId = 'userId',
  invitationStatus = 'invitationStatus',
  updatedAt = 'updatedAt',
}

export enum $securityGroupUsers {
  users = 'users',
  _total = '_total',
}

export enum $upsertSecurityGroup {
  userEmails = 'userEmails',
}

export enum SecurityEntityLevel {
  admin = 'admin',
  digitalProperty = 'digitalProperty',
  tenant = 'tenant',
  workspace = 'workspace',
}

/**
 * @param level
 * @returns returns a security entity id selector for the given level or null if such selector doesn't exist
 */
export function securityEntityLevelToSecurityEntity(
  level: SecurityEntityLevel,
): $securityEntity.digitalPropertyId | $securityEntity.workspaceId | $securityEntity.tenantId | null {
  switch (level) {
    case SecurityEntityLevel.tenant:
      return $securityEntity.tenantId;
    case SecurityEntityLevel.workspace:
      return $securityEntity.workspaceId;
    case SecurityEntityLevel.digitalProperty:
      return $securityEntity.digitalPropertyId;
    default:
      return null;
  }
}

export enum $createUsersRequest {
  userEmails = 'userEmails',
  groupIds = 'groupIds',
}

export enum $assignUsersRequest {
  userEmails = 'userEmails',
  groupIds = 'groupIds',
}

export enum SecurityGroupType {
  client = 'client',
  staff = 'staff',
  service = 'service',
  serviceStaff = 'serviceStaff',
}

export enum SecurityGroupOrigin {
  custom = 'custom',
  predefined = 'predefined',
}

export const CREATE_USERS_MAX_EMAILS_LIMIT: number = 200;

/**
 * Caution: Since template names are used for references when upgrade/downgrade packages.
 *          They should not be changed after they are defined.
 */
export enum SecurityGroupTemplateNames {
  csm = 'CSM',
  onboarding_manager = 'Onboarding manager',
  technical_lead = 'Technical lead',
  support = 'Support',
  qa_tester = 'QA tester',
  organization_administrator = 'Organization administrator',
  workspace_administrator = 'Workspace administrator',
  workspace_user = 'Workspace user',
  website_app_user = 'Website/app user',
  product = 'Product',
  amp_tenant_service_group = 'AMP tenant service group',
  accounts_provisioned_via_scim = 'Accounts provisioned via SCIM',
  scim_service_group = 'SCIM service group',
}
