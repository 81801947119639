import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Api } from '../../../../../shared/constants/api';
import { ILinkedPropertyData, LinkedPropertyUtility } from '../../../../../shared/utils/linked-property.utility';
import { TenantService } from '../../tenant.service';
import { WorkspaceService } from '../../workspace.service';
import { IWorkspace } from '../../../../../shared/interfaces/workspace.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { ITenantDigitalPropertyLimitState } from '../../../../../shared/interfaces/tenant.interface';
import { UserService } from '../../user.service';
import { IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { $workspace } from '../../../../../shared/constants/workspace';
import { $user } from '../../../../../shared/constants/user';
import { NotificationService } from '../../notification.service';
import { NotificationPosition } from '../../../models/notification.model';
import { TranslateService } from '../../../translate/translate.service';
import { ErrorHandlerService } from '../../error-handler.service';

@Injectable()
export class DigitalPropertyLimitGuard {
  constructor(
    private userService: UserService,
    private tenantService: TenantService,
    private workspaceService: WorkspaceService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const tenantId: string = this.getCurrentTenantId();
    if (SharedCommonUtility.notNullishOrEmpty(tenantId)) {
      return this.checkTenantDigitalPropertyUsage(tenantId);
    }

    if (LinkedPropertyUtility.hasLinkedPropertyData(next.queryParams) === false) {
      return of(false);
    }

    const propertyData: ILinkedPropertyData = LinkedPropertyUtility.fromLinkedPropertyQueryParam(next.queryParams);

    return this.checkTenantDigitalPropertyUsageForAdminPortal(propertyData[Api.workspaceId]);
  }

  private getCurrentTenantId(): string | null {
    const user: IUserServerResponse = this.userService.retrieveAuthenticatedUserProfile();

    return user[$user.currentTenantId];
  }

  private checkTenantDigitalPropertyUsage(tenantId: string): Observable<boolean> {
    return this.tenantService.getDigitalPropertyLimitState(tenantId).pipe(
      map((limitState: ITenantDigitalPropertyLimitState) => {
        if (SharedCommonUtility.isNullish(limitState) || limitState.digitalPropertyCount < limitState.digitalPropertyLimit) {
          return true;
        }

        this.displayErrorToast(limitState);
        return false;
      }),
      catchError((error: any) => {
        this.errorHandlerService.handleError(error);
        return of(false);
      }),
    );
  }

  private displayErrorToast(limitState: ITenantDigitalPropertyLimitState): void {
    const message: string = this.translateService.instant('digital_property_limit_reached', [
      limitState.tenantName,
      limitState.packageName,
      String(limitState.digitalPropertyLimit),
    ]);

    this.notificationService.error(message, NotificationPosition.Toast, true);
  }

  private checkTenantDigitalPropertyUsageForAdminPortal(workspaceId: string): Observable<boolean> {
    return this.workspaceService.getWorkspace(workspaceId).pipe(
      switchMap((workspace: IWorkspace) => {
        if (SharedCommonUtility.isNullish(workspace[$workspace.tenantId])) {
          return of(true);
        }

        return this.checkTenantDigitalPropertyUsage(workspace[$workspace.tenantId]);
      }),
      catchError((error: any) => {
        this.errorHandlerService.handleError(error);
        return of(false);
      }),
    );
  }
}
