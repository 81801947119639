<div class="row">
  <div class="col-12 d-flex align-items-center">
    <button class="btn btn-flat" id="collapser" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed">
      <ng-container *ngIf="isCollapsed; else open">
        <svg class="chevron-icon" aria-hidden="true">
          <use xlink:href="#chevron-right-thin"></use>
        </svg>
      </ng-container>
      <ng-template #open>
        <svg class="chevron-icon" role="img" aria-hidden="true">
          <use xlink:href="#chevron-bottom-thin"></use>
        </svg>
      </ng-template>
    </button>
    <span class="fw-bold text-black ms-1" id="header">
      {{ header }}
    </span>
    <button *ngIf="deletable" (click)="deleted.emit(id)" id="delete-button" class="btn btn-flat btn-link text-underline fw-bold">
      {{ 'action_delete' | translate }}
    </button>
  </div>
</div>

<div class="row">
  <div class="col-12 ps-5 text-black">
    {{ 'label_comment_user_timestamp' | translate: [date, time, author] }}
  </div>
</div>

<div class="row mt-2">
  <div class="col-12 ps-5">
    <div id="content" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="text-black">
      <ng-content></ng-content>
    </div>
  </div>
</div>
