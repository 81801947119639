import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

import { CommonControlsViewMode } from '../../constants/common-controls-view-mode';
import { FormService } from '../../services/form.service';

export interface SelectFieldContext {
  customMessage?: string;
  label?: string;
  field: string;
  valueKey?: string;
  nameKey?: string | ((value: any) => string);
  translateName?: boolean;
  required?: boolean;
  description?: string;
  fullWidthInput?: boolean;
  disabled?: boolean;
  tooltip?: string;
  placeholderValue?: string;
}

@Component({
  selector: 'app-common-select',
  templateUrl: './common-select.component.html',
  styleUrls: ['./common-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonSelectComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public context: SelectFieldContext;
  @Input()
  public values: string[] | Record<string, any>[];
  @Input()
  public formValidationRequest$: Observable<void>;
  @Input()
  public view: CommonControlsViewMode;

  @Output()
  public valueChanged$: EventEmitter<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formService: FormService,
  ) {
    this.view = 'col-view';
    this.valueChanged$ = new EventEmitter<any>();
  }

  public get disabled(): boolean {
    return typeof this.context.disabled === 'boolean' ? this.context.disabled : this.form.get(this.context.field).disabled;
  }

  public get required(): boolean {
    return this.formService.isFieldRequired(this.form, this.context);
  }

  public get fullWidthInput(): boolean {
    return typeof this.context.fullWidthInput === 'boolean' ? this.context.fullWidthInput : true;
  }

  public get selectValues(): string[] | Record<string, any>[] {
    return Array.isArray(this.values) ? this.values : [];
  }

  public getOptionName(value: string | Record<string, any>): any {
    if (typeof this.context.nameKey === 'function') {
      return this.context.nameKey(value);
    }

    if (typeof this.context.nameKey === 'string') {
      return value[this.context.nameKey];
    }

    return value;
  }

  public comparator(): (a: any, b: any) => boolean {
    return (a: any, b: any): boolean => {
      if (a !== null && b !== null && typeof a === 'object' && typeof b === 'object') {
        return this.getOptionName(a) === this.getOptionName(b);
      }

      return a === b;
    };
  }

  public onSelectChange(): void {
    this.valueChanged$.emit(this.form.get(this.context.field).value);
  }

  public ngOnInit(): void {
    if (this.formValidationRequest$) {
      this.subscription = this.formValidationRequest$.subscribe(() => {
        this.form.get(this.context.field).markAsDirty();
        this.form.get(this.context.field).markAsTouched();
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
