import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts/core';
import { GaugeChart } from 'echarts/charts';
import { SVGRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts/types/dist/shared';
import { TranslateService } from '../../translate/translate.service';
import { Icons } from '../../shared/eap-icons.component';
import { EapColors } from '../../shared/eap-colors';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';

export enum ShowWarningWhen {
  above = 'above',
  below = 'below',
}

@Component({
  selector: 'app-progress-doughnut',
  templateUrl: './progress-doughnut.component.html',
  styleUrls: ['./progress-doughnut.component.scss'],
})
export class ProgressDoughnutComponent implements AfterViewInit, OnChanges {
  @ViewChild('progressChart') private progressChart: ElementRef<HTMLDivElement>;

  @Input()
  public threshold: number;

  @Input()
  public warning: ShowWarningWhen;

  @Input()
  public progress: number;

  @Input()
  public maxValue: number;

  @Input()
  public showPercent: boolean;

  public get formattedValue(): string {
    return this.showPercent ? this.translateService.instant('percent_formatter', this.progress) : String(this.progress);
  }

  public get isWarningOn(): boolean {
    return this.warning === 'above' ? this.progress > this.threshold : this.progress < this.threshold;
  }

  private chart: echarts.EChartsType;
  public warningIcon: Icons;
  public warningColor: EapColors;
  public okColor: EapColors;

  constructor(private translateService: TranslateService) {
    this.warning = ShowWarningWhen.below;
    this.threshold = 80;
    this.showPercent = true;
    this.maxValue = 100;
    this.warningIcon = Icons.WarningTriangleIcon;
    this.warningColor = EapColors.RedError;
    this.okColor = EapColors.BlueInfo;

    echarts.use([GaugeChart, SVGRenderer]);
  }

  private renderChart(): void {
    if (this.chart) {
      this.chart.dispose();
    }

    if (SharedCommonUtility.isNullish(this.progressChart)) {
      return;
    }

    const option: EChartsOption = {
      series: [
        {
          name: 'Progress',
          type: 'gauge',
          progress: {
            show: true,
            width: 15,
            itemStyle: {
              color: this.isWarningOn ? this.warningColor : this.okColor,
            },
          },
          radius: '190%',
          center: ['50%', '98%'],
          detail: {
            show: false,
          },
          startAngle: 180,
          endAngle: 0,
          splitNumber: 0,
          max: this.maxValue,
          pointer: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 15,
              shadowColor: 'black',
              shadowBlur: 1,
              color: [[1, EapColors.GreyPageBackground]],
            },
          },
          splitLine: {
            show: false,
          },
          title: {
            show: false,
          },
          data: [
            {
              value: this.progress,
            },
          ],
        },
      ],
    };

    this.chart = echarts.init(this.progressChart.nativeElement, null, { width: 140, height: 70 });
    this.chart.setOption(option);
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  public ngOnChanges(): void {
    this.renderChart();
  }
}
