import { Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface IOrderedListItemData {
  text: string;
}

export interface IOrderedListCellConfig extends ICellConfig {
  items: IOrderedListItemData[];
}

@Component({
  selector: 'table-cell-ol',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <ol #ref>
      <li *ngFor="let item of config.items">
        {{ item.text }}
      </li>
    </ol>
  `,
})
export class OrderedListCellComponent extends BaseCellComponent {
  @Input() public config: IOrderedListCellConfig;
}
