import { Component, OnInit, OnDestroy } from '@angular/core';
import { map as mapRx, tap } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { Observable, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'lodash';

import { SecurityGroupService } from '../../../../services/security-group.service';
import { SecurityEntityLevel, $securityGroup } from '../../../../../../shared/constants/security-group';
import { ITableColumn, ITableRow, SelectAllEvent } from '../../../table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../../../../translate/translate.service';
import {
  ISecurityGroupWithEntityNames,
  ISecurityGroupsResponse,
} from '../../../../../../shared/interfaces/security-group.interface';
import { NgbTableUtilities } from '../../../table/ngb-table/utilities/ngb-table.utilities';
import { PermissionsTableComponent } from '../permissions-table.component';
import { AngularUtility } from '../../../../utility/angular.utility';

enum GroupsTableColumns {
  checkbox = 'checkbox',
  group = 'group',
}

@Component({
  selector: 'app-admin-portal-permissions-table',
  templateUrl: './admin-portal-permissions-table.component.html',
  styleUrls: ['./admin-portal-permissions-table.component.scss'],
})
export class AdminPortalPermissionsTableComponent extends PermissionsTableComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  protected allGroups: ISecurityGroupWithEntityNames[] = [];
  protected allSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    formBuilder: FormBuilder,
    private translateService: TranslateService,
    private securityGroupService: SecurityGroupService,
  ) {
    super(formBuilder);
    this.buildTableConfig();
  }

  private buildTableConfig(): void {
    const columns: Record<string, ITableColumn> = {
      [GroupsTableColumns.checkbox]: {
        translationKey: 'select_all_the_groups',
        selectAllEnabled: true,
        hideLabel: true,
        styles: {
          maxWidth: '3rem',
          width: '2.8rem',
          paddingRight: '0',
          paddingLeft: '0.95rem',
        },
      },
      [GroupsTableColumns.group]: {
        translationKey: 'label_security_group_name',
      },
    };

    this.tableConfig = {
      columns,
      caption: this.translateService.instant('security_groups_table'),
      emptyState: {
        title: this.translateService.instant('label_no_security_groups'),
      },
    };
  }

  private updateAllCheckedIfNecessary(): void {
    const selectedGroups: Set<string> = new Set<string>(this.formGroupIds.value);
    const allSelected: boolean = this.allGroups.every((group: ISecurityGroupWithEntityNames): boolean =>
      selectedGroups.has(group[$securityGroup._id]),
    );

    this.allSelected$.next(allSelected);
  }

  protected buildObservables(): void {
    super.buildObservables();

    const securityGroupsResponse$: Observable<ISecurityGroupsResponse<ISecurityGroupWithEntityNames>> = this.securityGroupService
      .groupsWithEntityNames({
        skip: 0,
        limit: 9999,
        includeStaffGroups: false,
        skipOrganizationAdmin: true,
        entityLevel: SecurityEntityLevel.admin,
      })
      .pipe(
        tap((securityGroupsResponse: ISecurityGroupsResponse<ISecurityGroupWithEntityNames>): void => {
          this.allGroups = securityGroupsResponse.groups;
          this.onPermissionsLoaded.emit(securityGroupsResponse.groups);
        }),
        AngularUtility.shareRef(),
      );

    this.total$ = securityGroupsResponse$.pipe(
      mapRx(
        (securityGroupsResponse: ISecurityGroupsResponse<ISecurityGroupWithEntityNames>): number => securityGroupsResponse._total,
      ),
    );

    this.tableData$ = combineLatest([securityGroupsResponse$, this.page$]).pipe(
      mapRx(([securityGroupsResponse, page]: [ISecurityGroupsResponse<ISecurityGroupWithEntityNames>, number]): ITableRow[] => {
        const groups: ISecurityGroupWithEntityNames[] = securityGroupsResponse.groups.slice(
          (page - 1) * this.pageSize,
          page * this.pageSize,
        );

        return groups.map((group: ISecurityGroupWithEntityNames): ITableRow => {
          const groupId: string = group[$securityGroup._id];
          return {
            originalData: group,
            data: {
              [GroupsTableColumns.checkbox]: NgbTableUtilities.checkboxCell({
                checked: () => this.formGroupIds.value.includes(groupId),
                attributes: {
                  'aria-label': this.translateService.instant('select_label', group[$securityGroup.name]),
                },
                styles: {
                  marginTop: '5px',
                },
                listeners: {
                  click: ($event: MouseEvent): void => {
                    $event.stopPropagation();
                    if (($event.target as HTMLInputElement).checked) {
                      this.updateFormGroupIds([...this.formGroupIds.value, groupId]);
                    } else {
                      this.updateFormGroupIds(this.formGroupIds.value.filter((id: string): boolean => groupId !== id));
                    }

                    this.updateAllCheckedIfNecessary();
                  },
                },
              }),
              [GroupsTableColumns.group]: NgbTableUtilities.htmlCell({
                text: group[$securityGroup.name],
              }),
            },
          };
        });
      }),
    );
  }

  protected onSelectAll(event: SelectAllEvent): void {
    this.allSelected$.next(event.selected);

    if (event.selected) {
      this.updateFormGroupIds(map(this.allGroups, $securityGroup._id));
    } else {
      this.updateFormGroupIds([]);
    }
  }

  public ngOnInit(): void {
    this.buildObservables();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
