<div [formGroup]="form">
  <h4 class="title font-normal" *ngIf="showTitle">{{ 'pre_scan_advanced_scan_title' | translate }}</h4>
  <label for="preScanFlowId">{{ 'pre_scan_automated_flow_title' | translate }}</label>
  <div class="description">
    {{ 'pre_scan_advanced_scan_description' | translate }}
    <a [routerLink]="createFlowLink" target="_blank">{{ 'create_new_flow' | translate }}</a>
    {{ 'or_choose_one_from_dropdown' | translate }}
  </div>

  <div class="row w-100">
    <div class="col-sm-12 col-md-6">
      <select class="w-100 margin-top-xs" id="preScanFlowId" [formControlName]="scanCreateRequest.preScanFlowId">
        <option [ngValue]="null">{{ 'select_pre_scan_automated_flow' | translate }}</option>
        <ng-container *ngFor="let flow of preScanFlows$ | async">
          <option [ngValue]="flow._id">
            {{ flow.name }}
          </option>
        </ng-container>
      </select>
    </div>
  </div>
</div>
