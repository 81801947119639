<ds-modal [modal]="modal" [title]="'risk_management_status' | translate">
  <div >
    <h3 class="ds-visually-hidden">{{ 'available_levels' | translate }}</h3>
    <ol class="available-levels">
      <li class="text-nowrap me-2" *ngFor="let levelText of availableLevels; index as i">
        <ds-icon *ngIf="i > 0" [icon]="Icons.ChevronRight" class="me-2"></ds-icon>
        {{ levelText }}
      </li>
    </ol>
  </div>
  <div>
    <h3 class="my-4" >{{ currentLevelText }}</h3>
    <p >
      {{ levelExplanation }}
    </p>
    <p  *ngIf="additionalExplanation">
      {{ additionalExplanation | translate }}
    </p>
  </div>
  <div  *ngIf="findingsData">
    <span>{{ 'findings_breakdown_label' | translate: [formattedComputedDate] }}</span>
    <ul class="mt-2">
      <li *ngFor="let value of findingsBreakdown">
        {{ value }}
      </li>
    </ul>
  </div>
</ds-modal>
