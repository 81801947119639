import { Component, Input } from '@angular/core';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface ICheckboxCellConfig extends ICellConfig {
  indeterminate?: boolean;
  /**
   * Accepts a boolean value that represents the checked status of the checkbox input.
   *
   * Accepts a function that returns a boolean representing the checked status of the checkbox input.
   * The function is re evaluated on each change detection of the cell
   */
  checked?: boolean | (() => boolean);
  disabled?: boolean | (() => boolean);
  label?: string;
  id?: string;
}

@Component({
  selector: 'table-cell-checkbox',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div class="d-flex align-items-center fw-normal">
      <input
        #ref
        [ngStyle]="config.styles"
        type="checkbox"
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="checked"
        [indeterminate]="config.indeterminate"
        [disabled]="disabled"
        [attr.id]="config.id ?? undefined"
      />
      <span *ngIf="config.label" class="margin-left-xs">
        {{ config.label | translate }}
      </span>
    </div>
  `,
})
export class CheckboxCellComponent extends BaseCellComponent {
  @Input() public config: ICheckboxCellConfig;

  get checked(): boolean {
    return typeof this.config?.checked === 'function' ? this.config.checked() : this.config?.checked;
  }

  get disabled(): boolean {
    return typeof this.config?.disabled === 'function' ? this.config.disabled() : this.config?.disabled;
  }
}
