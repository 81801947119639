import { marked, Renderer } from 'marked';
import DOMPurify from 'isomorphic-dompurify';
import TurndownService from 'turndown';

import { SharedTextUtility } from './text.utility';
import { SharedCommonUtility } from './common.utility';

enum TokenType {
  HEADING = 'heading',
  HR = 'hr',
  LINK = 'link',
  LIST = 'list',
  TABLE = 'table',
  TEXT = 'text',
}

export enum RenderType {
  DEFAULT = 'DEFAULT',
  NOTIFICATION = 'NOTIFICATION',
  AICHAT = 'AICHAT',
}

const RenderTypeSanitizeConfig: Record<RenderType, any> = {
  DEFAULT: {},
  NOTIFICATION: {},
  AICHAT: { ADD_ATTR: ['target'] },
};

interface Token {
  type: TokenType;
  raw: string;
  text?: string;
}

interface MarkedOptions {
  renderer: Renderer;
  walkTokens?: ((token: Token) => void) | undefined;
  breaks?: boolean | undefined;
}

export class MarkdownUtility {
  private static readonly markedOptions: Map<RenderType, MarkedOptions> = new Map();
  private static readonly tokenTypesToChangeForNotification: Set<string> = new Set([
    TokenType.HEADING,
    TokenType.HR,
    TokenType.LINK,
    TokenType.LIST,
    TokenType.TABLE,
  ]);

  private static readonly turndownService: TurndownService = new TurndownService({
    headingStyle: 'atx',
  });

  /**
   * Special symbols combination that is replaced with <br> tag by the marked library
   */
  public static readonly br: string = '  \n';

  private static getMarkedOptions(type: RenderType): any {
    if (!this.markedOptions.has(type)) {
      this.markedOptions.set(type, this.buildMarkedOptions(type));
    }

    return this.markedOptions.get(type);
  }

  private static buildMarkedOptions(type: RenderType): any {
    switch (type) {
      case RenderType.NOTIFICATION:
        return this.buildMarkedOptionsForNotification();
      case RenderType.AICHAT:
        return this.buildMarkedOptionsForAIChat();
      default:
        return this.buildDefaultMarkedOptions();
    }
  }

  private static buildMarkedOptionsForNotification(): any {
    const renderer: Renderer = new Renderer();

    renderer.code = function (text: string): string {
      return `<code>${text}</code>`;
    };

    renderer.codespan = function (text: string): string {
      return `<code>${SharedTextUtility.unescapeAmpersands(text)}</code>`;
    };

    renderer.paragraph = renderer.text;

    return {
      renderer: renderer,
      walkTokens: this.changeTokenTypeToText,
    };
  }

  private static changeTokenTypeToText = (token: Token): void => {
    if (MarkdownUtility.tokenTypesToChangeForNotification.has(token.type)) {
      token.type = TokenType.TEXT;

      if (SharedCommonUtility.isNullish(token['text'])) {
        token['text'] = token.raw;
      }
    }
  };

  private static buildMarkedOptionsForAIChat(): MarkedOptions {
    const renderer: Renderer = new Renderer();

    renderer.code = function (text: string): string {
      return `<pre><code>${text}</code></pre>`;
    };

    renderer.codespan = function (text: string): string {
      return `<code>${SharedTextUtility.unescapeAmpersands(text)}</code>`;
    };

    renderer.hr = function (): string {
      return `<hr role="presentation" aria-label="citations">`;
    };

    renderer.listitem = function (text: string): string {
      const linesString: string | null = text.match(/[0-9]+(-[0-9]+)/)?.[0];
      if (linesString) {
        const lines: string[] = linesString.split('-');
        const updatedText: string = text
          .replace(linesString, `<span aria-hidden="true">${linesString}</span>`)
          .concat(`<span class="visuallyhidden">lines ${lines[0]} to ${lines[1]}</span>`);
        return `<li>${updatedText}</li>`;
      }

      const pageString: string | null = text.match(/(p[0-9]+)/)?.[0];
      if (pageString) {
        const pageNumber: string = pageString.substring(1);
        const updatedText: string = text
          .replace(pageString, `<span aria-hidden="true">${pageString}</span>`)
          .concat(`<span class="visuallyhidden">page ${pageNumber}</span>`);
        return `<li>${updatedText}</li>`;
      }

      return `<li>${text}</li>`;
    };

    renderer.link = function (href: string, title: string | null, text: string): string {
      return `<a href="${href}" target="_blank">${text}</a>`;
    };

    return {
      breaks: true,
      renderer: renderer,
    };
  }

  private static buildDefaultMarkedOptions(): MarkedOptions {
    const renderer: Renderer = new Renderer();

    renderer.code = function (text: string): string {
      return `<pre><code>${text}</code></pre>`;
    };

    renderer.codespan = function (text: string): string {
      return `<code>${SharedTextUtility.unescapeAmpersands(text)}</code>`;
    };

    return {
      breaks: true,
      renderer: renderer,
    };
  }

  public static render(value: string, type: RenderType = RenderType.DEFAULT): string {
    const escapedValue: string = SharedTextUtility.escapeHtmlTags(value);
    const compiledHtml: string = marked(escapedValue, this.getMarkedOptions(type));

    const trustedHtml: TrustedHTML = DOMPurify.sanitize(compiledHtml, RenderTypeSanitizeConfig[type]);
    return trustedHtml.toString();
  }

  public static sanitize(value: string, options: Record<string, any> = {}): string {
    const workingValue: string = SharedTextUtility.escapeHtmlTags(value);
    return DOMPurify.sanitize(workingValue, {
      USE_PROFILES: { html: false },
      ...options,
    });
  }

  /**
   * Converts html content to markdown.
   *
   * @param html html content to convert to markdown.
   * @param options options for the conversion.
   * @param {boolean} [options.keepCodeTagsContent=true] Whether to preserve the content inside code tags. If false, the content inside code tags will also be converted to its html representation.
   *
   * @returns The markdown representation of the provided html.
   */
  public static htmlToMarkdown(html: string, options: { keepCodeTagsContent: boolean } = { keepCodeTagsContent: true }): string {
    if (options.keepCodeTagsContent) {
      const regex: RegExp = new RegExp(/<code>(.*?)<\/code>/g);
      const escapedHtml: string = html.replace(regex, (_: string, innerContent: string): string => {
        return `<code>${SharedTextUtility.escapeHtmlTags(innerContent)}</code>`;
      });

      return this.turndownService.turndown(escapedHtml);
    }
    return this.turndownService.turndown(html);
  }

  /**
   * Finds the highest heading level present in a markdown string.
   * @returns A number between 1 and 6, 1 representing the highest heading which is a h1.
   */
  public static findHighestHeadingLevel(markdownContent: string): number {
    const headingRegex: RegExp = new RegExp(/^(#{1,6})\s+/gm);
    let highestLevel: number = 7; // Higher than the maximum heading level (6)

    let match: RegExpExecArray | null;
    while ((match = headingRegex.exec(markdownContent)) !== null) {
      const level: number = match[1].length; // The number of `#` characters indicates the heading level
      if (level < highestLevel) {
        highestLevel = level;
      }
    }

    // If no heading is found, return null
    return highestLevel === 7 ? null : highestLevel;
  }
}
