<div [ngClass]="['banner', bannerType]" role="alert" aria-live="polite">
  <div class="me-3" [ngSwitch]="bannerType">
    <ds-icon
      *ngSwitchCase="BannerType.info"
      [icon]="Icons.CircleInfo"
      [iconStyle]="IconStyles.Light"
      [style.color]="EapColors.BlueInfo"
    >
    </ds-icon>
    <ds-icon
      *ngSwitchCase="BannerType.error"
      [icon]="Icons.TriangleExclamation"
      [iconStyle]="IconStyles.Light"
      [style.color]="EapColors.RedSeverityCritical"
    >
    </ds-icon>
    <ds-icon
      *ngSwitchCase="BannerType.check"
      [icon]="Icons.Check"
      [iconStyle]="IconStyles.Light"
      [style.color]="EapColors.GreenSeverityLow"
    >
    </ds-icon>
  </div>
  <div class="d-flex flex-column lh-lg">
    <ng-content></ng-content>
  </div>
  <div class="ms-auto">
    <button
      ds-button
      (click)="onClose.emit()"
      [attr.aria-label]="'label_close' | translate"
      [variant]="DsButtonVariants.microAction"
      [microActionIcon]="Icons.XMark"
    ></button>
  </div>
</div>
