<app-modal-container>
  <div class="modal-header-content">
    {{ options.headerKey | translate }}
  </div>
  <div class="modal-body-content">
    <div *ngIf="options.bodyHighlightKey" class="row">
      <div class="col-auto">
        <p class="body-content-heading">
          {{ options.bodyHighlightKey | translate }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-black" >
        <svg *ngIf="options.showIcon" aria-hidden="true" focusable="false" class="warning-symbol me-2">
          <use xlink:href="#exclamation-square"></use>
        </svg>
        {{ options.bodyKey | translate }}
      </div>
    </div>
  </div>
  <div class="modal-footer-content">
    <div class="row">
      <div class="col-auto">
        <button class="btn btn-outline-primary btn-lg" (click)="container.closeModal()">
          {{ options.closeBtnKey | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal-container>
