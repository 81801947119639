import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveOffcanvas, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DsButtonVariants, DsModalColorVariant, DsModalService } from '@levelaccess/design-system';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { IModal } from '../../../../components/modals/modal.interface';
import { ModalContainerComponent } from '../../../../components/modals/modal-container.component';
import { IUser } from '../../../../../../shared/interfaces/user.interface';
import { WorkspaceService } from '../../../../services/workspace.service';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { CustomValidators } from '../../../../services/helpers/form-custom-validators';
import { ProjectService } from '../../../../services/project.service';
import { IProjectCreateRequest } from '../../../../../../shared/interfaces/project.requests.interface';
import { $project, $createProjectPagePath, CHAR_LIMITS, ProjectStatus } from '../../../../../../shared/constants/project';
import { SharedDateUtility } from '../../../../../../shared/utils/date.utility';
import { $user } from '../../../../../../shared/constants/user';
import { IProject } from '../../../../../../shared/interfaces/project.interface';
import { DEBOUNCE_TIME } from '../../../../shared/constants';
import { TranslateService } from '../../../../translate/translate.service';
import { BannerComponent, BannerType } from '../../../../components/banner/banner.component';
import { ErrorMessageService } from '../../../../services/error-message.service';

enum FormFields {
  name = 'name',
  key = 'key',
  owner = 'owner',
}

@Component({
  selector: 'app-create-project-slideout',
  templateUrl: './create-project-slideout.component.html',
  styleUrls: ['./create-project-sliedout.component.scss'],
})
export class CreateProjectSlideoutComponent implements IModal, OnDestroy, OnInit {
  @ViewChild(ModalContainerComponent, { static: false })
  public container: ModalContainerComponent;

  @Input() public set workspaceId(value: string) {
    this.workspaceId$.next(value);
  }

  public get workspaceId(): string {
    return this.workspaceId$.value;
  }

  @Output() public onProjectCreated: EventEmitter<IProject>;

  protected form: UntypedFormGroup;

  protected readonly FormFields: typeof FormFields = FormFields;
  protected readonly CHAR_LIMITS: typeof CHAR_LIMITS = CHAR_LIMITS;
  protected readonly $project: typeof $project = $project;
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  protected readonly DsModalColorVariant: typeof DsModalColorVariant = DsModalColorVariant;
  protected readonly BannerType: typeof BannerType = BannerType;

  private readonly workspaceUsers$: BehaviorSubject<IUser[]>;
  private workspaceId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  protected searchFunction: (text$: Observable<string>) => Observable<IUser[]>;
  private subscriptions: Subscription;
  protected formFieldErrors: Record<string, string> = {};
  protected selectedUser: IUser;
  protected errorMessage: string;

  @ViewChild('errorBanner')
  private errorBanner: BannerComponent;

  constructor(
    protected modal: NgbActiveOffcanvas,
    protected modalService: DsModalService,
    private formBuilder: UntypedFormBuilder,
    private workspaceService: WorkspaceService,
    private projectService: ProjectService,
    private translateService: TranslateService,
    private errorMessageService: ErrorMessageService,
    private element: ElementRef<Element>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.subscriptions = new Subscription();
    this.workspaceUsers$ = new BehaviorSubject<IUser[]>([]);
    this.onProjectCreated = new EventEmitter<IProject>();
    this.searchFunction = this.search.bind(this);
    this.setupForm();
    this.setupObservables();
  }

  private setupObservables(): void {
    this.subscriptions = this.workspaceId$
      .pipe(
        filter((value: string) => SharedCommonUtility.notNullishOrEmpty(value)),
        switchMap((workspaceId: string) => this.workspaceService.getUsers(workspaceId, { includeStaffUsers: true })),
      )
      .subscribe(this.workspaceUsers$);
  }

  private setupForm(): void {
    this.form = this.formBuilder.group({
      [FormFields.name]: this.formBuilder.control('', [
        CustomValidators.required,
        Validators.maxLength(CHAR_LIMITS[$project.name]),
      ]),
      [FormFields.key]: this.formBuilder.control(
        '',
        [
          CustomValidators.required,
          Validators.maxLength(CHAR_LIMITS[$project.key]),
          Validators.minLength(CHAR_LIMITS.keyMin),
          CustomValidators.charactersOnly,
        ],
        [this.validateProjectKey.bind(this)],
      ),
      [FormFields.owner]: this.formBuilder.control('', [CustomValidators.required, this.validateSelectedOwner.bind(this)]),
    });
  }

  private search(text$: Observable<string>): Observable<IUser[]> {
    return text$.pipe(
      withLatestFrom(this.workspaceUsers$),
      map(([searchText, users]: [string, IUser[]]) =>
        users.filter((user: IUser) => user.displayName.toLowerCase().includes(searchText.toLowerCase())),
      ),
    );
  }

  private checkFormFields(): void {
    this.formFieldErrors = {};
    if (this.form.valid) {
      return;
    }

    if (this.form.get(FormFields.name).errors) {
      const errors: ValidationErrors = this.form.get(FormFields.name).errors;
      if (errors.required) {
        this.formFieldErrors[FormFields.name] = this.translateService.instant('enter_project_name');
      } else {
        this.formFieldErrors[FormFields.name] = this.translateService.instant('invalid_project_name');
      }
    }

    if (this.form.get(FormFields.key).errors) {
      const errors: ValidationErrors = this.form.get(FormFields.key).errors;
      if (errors.required) {
        this.formFieldErrors[FormFields.key] = this.translateService.instant('enter_project_key');
      } else if (errors.propertyKeyExists) {
        this.formFieldErrors[FormFields.key] = this.translateService.instant('existing_project_key');
      } else if (errors.minlength || errors.maxlength) {
        this.formFieldErrors[FormFields.key] = this.translateService.instant('invalid_project_key');
      } else {
        this.formFieldErrors[FormFields.key] = this.translateService.instant('invalid_project_key_english');
      }
    }

    if (this.form.get(FormFields.owner).errors) {
      this.formFieldErrors[FormFields.owner] = this.translateService.instant('select_project_owner');
    }
  }

  private validateProjectKey(input: UntypedFormControl): Observable<ValidationErrors | null> {
    return of(input.value).pipe(
      delay(DEBOUNCE_TIME),
      distinctUntilChanged(),
      switchMap((projectKey: string) => this.projectService.projectKeyExists(this.workspaceId, projectKey)),
      map((projectKeyExists: boolean) => {
        if (projectKeyExists) {
          return { propertyKeyExists: true };
        }

        return null;
      }),
    );
  }

  private validateSelectedOwner(input: UntypedFormControl): ValidationErrors | null {
    if (typeof input.value !== 'object' || input.value._id !== this.selectedUser?._id) {
      return { mustBeSelected: true };
    }

    return null;
  }

  private getCreateProjectRequest(): IProjectCreateRequest {
    return {
      [$project.name]: this.form.get(FormFields.name).value,
      [$project.ownerId]: this.selectedUser[$user._id],
      [$project.key]: this.form.get(FormFields.key).value,
      [$project.startDate]: SharedDateUtility.getDateFromLocalInputDate(new Date().toISOString()),
      [$project.endDate]: null,
      [$project.status]: ProjectStatus.active,
      [$createProjectPagePath.pagePath]: `/${this.activatedRoute.snapshot.firstChild.routeConfig.path}`,
    };
  }

  public formatUser(user: IUser): string {
    return user.displayName;
  }

  public onSelectUser(event: NgbTypeaheadSelectItemEvent<IUser>): void {
    this.selectedUser = event.item;
  }

  public onBlurOwner(): void {
    const owner: string | IUser = this.form.get(FormFields.owner).value;
    if (typeof owner === 'string') {
      const lowerOwner: string = owner.toLowerCase();
      const user: IUser = this.workspaceUsers$.value.find(
        (value: IUser): boolean => value[$user.displayName].toLowerCase() === lowerOwner,
      );
      if (user) {
        this.form.get(FormFields.owner).setValue(user);
        this.selectedUser = user;
      }
    }
  }

  private onCreateProjectSuccess(project: IProject): void {
    this.onProjectCreated.emit(project);
    this.modalService.previousModal();
  }

  private onCreateProjectError(error: any): void {
    this.errorMessage = this.errorMessageService.getGlobalErrorResponse(error);
    this.errorBanner.show();
  }

  public submit(): void {
    this.errorMessage = undefined;
    this.checkFormFields();
    if (!this.form.valid) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }

    this.subscriptions.add(
      this.projectService.createProject(this.workspaceId, this.getCreateProjectRequest()).subscribe({
        next: this.onCreateProjectSuccess.bind(this),
        error: this.onCreateProjectError.bind(this),
      }),
    );
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.form.statusChanges.subscribe(this.checkFormFields.bind(this)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
