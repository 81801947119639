import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { RestBuilder } from '../helpers/rest.builder';

@Injectable({
  providedIn: 'root',
})
export class SecureResourceApi {
  private restBuilder: RestBuilder;

  public constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
  }

  // GET /secure_resources/:resourceId
  public getSecureResourceBytes(resourceId: string): Observable<Blob> {
    const url: string = this.getSecureResourceUrl(resourceId);

    const headers: HttpHeaders = new HttpHeaders({
      'Cache-Control': 'private',
    });

    return this.httpClient.get(url, {
      responseType: 'blob',
      headers: headers,
    });
  }

  public getSecureResourceUrl(resourceId: string): string {
    return this.restBuilder.create().secureResources(resourceId).getApiUrl();
  }
}
