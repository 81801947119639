import { DsColors, Icons, IconStyles } from '@levelaccess/design-system';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCellComponent, ICellConfig, IIconConfig } from '../base-cell/base-cell.component';

export interface IButtonCellConfig extends ICellConfig {
  icon?: IIconConfig;
  isDisabled$?: Observable<boolean>;
  isExternalLink?: boolean;
}

@Component({
  selector: 'table-cell-button',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <button
      class="btn"
      [ngClass]="{ 'btn-link btn-external-link p-0': config.isExternalLink }"
      [attr.role]="config.isExternalLink ? 'link' : 'button'"
      [disabled]="config?.isDisabled$ | async"
      #ref
    >
      <ng-container *ngIf="config.icon">
        <svg
          aria-hidden="true"
          [attr.width]="config.icon.iconWidth"
          [attr.height]="config.icon.iconHeight"
          [ngStyle]="config.icon.iconStyles"
        >
          <use [attr.xlink:href]="'#' + config.icon.iconName"></use>
        </svg>
      </ng-container>
      {{ config.text }}
      @if (config.isExternalLink) {
        <ds-icon
          class="margin-left-sm"
          [icon]="Icons.ArrowUpRightFromSquare"
          [iconStyle]="IconStyles.Regular"
          [style.color]="DsColors.Inactive"
        ></ds-icon>
      }
    </button>
  `,
})
export class ButtonCellComponent extends BaseCellComponent {
  protected readonly Icons: typeof Icons = Icons;
  protected readonly DsColors: typeof DsColors = DsColors;
  protected readonly IconStyles: typeof IconStyles = IconStyles;

  @Input() public config: IButtonCellConfig;
}
