import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestService } from './rest.service';
import { ISupportRequest } from '../../../shared/interfaces/support-request.interface';

@Injectable()
export class SupportService {
  constructor(private restService: RestService) {}

  public sendSupportRequest(supportRequest: ISupportRequest): Observable<ISupportRequest> {
    return this.restService.sendSupportRequest(supportRequest);
  }
}
