import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ISelectedSingleData, ITableConfig, ITableRow, SelectAllEvent, SortEvent } from './utilities/ngb-table.interface';
import { $sortingOrder } from '../../../../../shared/constants/sort';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { Icons } from '../../../shared/eap-icons.component';
import { DclWrapperComponent } from './utilities/dcl-wrapper.component';
import { CheckboxCellComponent } from './cells/checkbox-cell/checkbox-cell.component';

const NO_ROW_SELECTED = { row: null, index: -1 };

@Component({
  selector: 'app-ngb-table',
  templateUrl: './ngb-table.component.html',
  styleUrls: ['./ngb-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgbTableComponent implements OnInit, OnChanges {
  @Input() public _config: ITableConfig;
  @Input() public data: ITableRow[];
  @Input() public allSelected: boolean;
  @Input() public sortingOrder: Record<string, $sortingOrder>;

  @Output() public onSelectAll: EventEmitter<SelectAllEvent>;
  @Output() public onSort: EventEmitter<SortEvent>;
  @Output() public rowSelectionChange: EventEmitter<ISelectedSingleData>;

  @ViewChildren(DclWrapperComponent) private cellWrappers: QueryList<DclWrapperComponent>;

  public columns: string[];
  public rotateDir: Record<$sortingOrder, $sortingOrder>;
  public $sortingOrder: typeof $sortingOrder;
  public rowSelection: ISelectedSingleData;
  public Icons: typeof Icons;

  @Input() public set config(config: ITableConfig) {
    this._config = config;
    this.columns = this.getColumns();
    this.initSortingState();
  }

  public get config(): ITableConfig {
    return this._config;
  }

  @Input()
  public set rowSelectionIndex(index: number) {
    if (index >= 0 && index < this.data?.length) {
      this.rowSelected(this.data[index], index);
    } else {
      this.rowSelected(NO_ROW_SELECTED.row, NO_ROW_SELECTED.index);
    }
    this.changeDetector.detectChanges();
  }

  public constructor(private changeDetector: ChangeDetectorRef) {
    this.config = null;
    this.data = null;
    this.columns = [];
    this.sortingOrder = {};
    this.rowSelection = { row: null, index: -1 };
    this.rotateDir = {
      [$sortingOrder.asc]: $sortingOrder.desc,
      [$sortingOrder.desc]: $sortingOrder.all,
      [$sortingOrder.all]: $sortingOrder.asc,
    };
    this.onSelectAll = new EventEmitter();
    this.onSort = new EventEmitter<SortEvent>();
    this.rowSelectionChange = new EventEmitter();
    this.$sortingOrder = $sortingOrder;
    this.Icons = Icons;
  }

  private initSortingState(): void {
    for (const col of this.columns) {
      if (this.config.columns[col].sortingEnabled && SharedCommonUtility.isNullish(this.sortingOrder[col])) {
        this.sortingOrder[col] = $sortingOrder.all;
      }
    }
  }

  public getColumns(): string[] {
    if (this.config) {
      return Object.keys(this.config.columns);
    }
    return [];
  }

  public isTableDataAvailable(): boolean {
    if (SharedCommonUtility.getTypeOf(this.config) !== 'object' || Array.isArray(this.data) === false) {
      return false;
    } else if (Object.keys(this.config.columns).length === 0) {
      return false;
    }
    return true;
  }

  public get isDataLoading(): boolean {
    return Array.isArray(this.data) === false;
  }

  public sortingTriggered(column: string): void {
    if (this.config.columns[column]?.sortingEnabled) {
      Object.keys(this.config.columns).forEach((col: string) => {
        if (this.config.columns[col].sortingEnabled && (col !== column || !(col in this.sortingOrder))) {
          this.sortingOrder[col] = $sortingOrder.all;
        }
      });

      const currSortOrder: $sortingOrder = this.sortingOrder[column];
      this.sortingOrder[column] = currSortOrder ? this.rotateDir[currSortOrder] : $sortingOrder.all;
      this.rowSelection = NO_ROW_SELECTED;
      this.onSort.emit({
        column: column,
        direction: this.sortingOrder[column],
      });
    }
  }

  public reloadWithNewConfig(config: ITableConfig): void {
    this.config = config;
    this.ngOnInit();
  }

  /**
   * @deprecated should provide updated @Input data: ITableRow[] instead
   */
  public reloadWithNewData(data: ITableRow[]): void {
    this.data = data;
    if (!SharedCommonUtility.isNullish(this.rowSelection)) {
      this.rowSelection.row = data[this.rowSelection.index];
    }
    this.ngOnInit();
  }

  public markForCheck(filter?: (cell: DclWrapperComponent) => boolean): void {
    this.cellWrappers.filter(filter ?? ((): boolean => true)).forEach((cell: DclWrapperComponent) => cell.markForCheck());
  }

  public allRowsSelected(event: Event): void {
    const checkbox: HTMLInputElement = event.target as HTMLInputElement;
    const selected: boolean = checkbox.checked;
    this.onSelectAll.emit({ selected });
    this.markForCheck((cell: DclWrapperComponent): boolean => cell.type === CheckboxCellComponent);
  }

  public rowSelected(row: ITableRow, index: number): void {
    if (!this.config.singleSelect?.selectable || this.rowSelection?.row === row || row?.isDisabled) {
      return;
    }

    if (row === null) {
      this.rowSelection = NO_ROW_SELECTED;
      this.rowSelectionChange.emit(null);
    } else {
      this.rowSelection = { row, index };
      this.rowSelectionChange.emit(this.rowSelection);
    }
  }

  public isRowSelected(index: number): boolean {
    return this.rowSelection.index === index;
  }

  public getCellStyles(row: ITableRow, column: string): Partial<Record<keyof CSSStyleDeclaration, string>> {
    return { ...this.config.columns[column].styles, ...row.data[column]?.config?.styles };
  }

  public trackByFn(_: number, item: ITableRow): ITableRow | string {
    return item.key ?? item;
  }

  public ngOnInit(): void {
    this.columns = this.getColumns();
    this.initSortingState();

    this.changeDetector.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const data: ITableRow[] | undefined = changes.data?.currentValue;
    if (
      this.config?.singleSelect?.selectFirstRow &&
      Array.isArray(data) &&
      data.length > 0 &&
      (SharedCommonUtility.isNullish(changes.rowSelectionIndex) || changes?.rowSelectionIndex?.firstChange) &&
      !data[0]?.isDisabled
    ) {
      this.rowSelected(data[0], 0);
    }
  }
}
