import { Component, Input } from '@angular/core';
import { BaseCellComponent, ICellConfig } from '../base-cell/base-cell.component';

export interface IUserAvatarCellConfig extends ICellConfig {
  text: string;
  src: string;
  alt: string;
}

@Component({
  selector: 'table-cell-user-avatar',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div #ref class="d-flex">
      <div class="column">
        <span class="table-user-avatar">
          <img [src]="config.src" [alt]="config.alt" />
        </span>
      </div>
      <div class="column table-user-name">
        {{ config.text }}
      </div>
    </div>
  `,
})
export class UserAvatarCellComponent extends BaseCellComponent {
  @Input() public config: IUserAvatarCellConfig;
}
