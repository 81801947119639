<ds-tabs *ngIf="showOrgAdminCheckbox$ | async; else userDetails" [tablistLabelledBy]="titleId">
  <ds-tab [label]="'label_details' | translate" [heading]="'update_user_details' | translate">
    <ng-container [ngTemplateOutlet]="userDetails"></ng-container>
  </ds-tab>
  <ds-tab [label]="'label_permissions' | translate" [heading]="'update_user_permissions' | translate">
    <form [formGroup]="permissionsForm" (ngSubmit)="onPermissionsSubmit($event)">
      <ng-container [ngTemplateOutlet]="permissionsButtons" [ngTemplateOutletContext]="{ $implicit: 'end' }"> </ng-container>

      <ng-container *ngIf="permissionsBannerRef" [ngTemplateOutlet]="permissionsBannerRef"></ng-container>

      <app-organization-permissions-table
        class="d-block mb-5"
        [form]="permissionsForm"
        (onPermissionsLoaded)="onOrgAdminPermissionsLoaded($event)"
      >
      </app-organization-permissions-table>

      <app-workspace-permissions-table
        class="d-block mb-5"
        [form]="permissionsForm"
        (onPermissionsLoaded)="onWorkspacePermissionsLoaded($event)"
      >
      </app-workspace-permissions-table>

      <app-digital-property-permissions-table
        class="d-block mb-5"
        [form]="permissionsForm"
        (onPermissionsLoaded)="onDigitalPropertyPermissionsLoaded($event)"
      >
      </app-digital-property-permissions-table>

      <ng-container [ngTemplateOutlet]="permissionsButtons"></ng-container>
    </form>
  </ds-tab>
</ds-tabs>

<ng-template #permissionsButtons let-align>
  <span class="d-flex gap-3" [ngClass]="'justify-content-' + (align || 'start')">
    <button ds-button type="submit" >{{ 'update_permissions' | translate }}</button>
    <button ds-button type="button" (click)="navigateToParentRoute()" [variant]="DsButtonVariants.secondary">
      {{ 'manage_users_cancel_label' | translate }}
    </button>
  </span>
</ng-template>

<ng-template #workspaceAdminInfo>
  <app-edit-permissions-banner class="d-block mt-2" [bannerType]="BannerType.info" (onClose)="onPermissionsBannerClose()">
    {{ 'label_workspace_admin_info' | translate }}
    <ds-link href="https://client.levelaccess.com/hc/en-us/articles/12507620329111-User-groups">
      {{ 'learn_more' | translate }}
    </ds-link>
  </app-edit-permissions-banner>
</ng-template>

<ng-template #noGroupsSelectedError>
  <app-edit-permissions-banner class="d-block mt-2" [bannerType]="BannerType.error" (onClose)="onPermissionsBannerClose()">
    {{ 'label_select_group_in_tenant' | translate }}
  </app-edit-permissions-banner>
</ng-template>

<ng-template #userDetails>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-lg-8">
        <span>{{ 'required_fields_label' | translate }}</span>
        <form [formGroup]="form" (ngSubmit)="onSubmit($event)" class="d-flex flex-column" id="user-profile-form">
          <div class="mt-3">
            <ds-input
              
              [label]="'manage_users_email_label' | translate"
              [required]="true"
              [state]="DsInputStates.inactive"
            >
              <input ds-input-field type="text" [formControlName]="$user.email" />
            </ds-input>
          </div>

          <div class="mt-3">
            <ds-input
              
              [label]="'manage_users_display_name_label' | translate"
              [errorMessage]="displayNameError$ | async"
              [errorState]="(displayNameError$ | async)?.length > 0"
              [required]="true"
            >
              <input ds-input-field id="displayName" type="text" [formControlName]="$user.displayName" />
            </ds-input>
          </div>

          <fieldset *ngIf="(showOrgAdminCheckbox$ | async) === false" class="fieldset-form margin-bottom margin-top">
            <div class="row mb-4" *appHasPermissions="orgAdminRequiredSecurity">
              <div class="col-12 col-lg-10 col-xl-8" [formGroup]="groupsSelectionFormGroup">
                <app-common-checkbox
                  [context]="{
                    id: 'organization-administrator-checkbox',
                  }"
                  [formControlName]="$user.organizationAdministrator"
                >
                  <span class="checkbox-label">{{ 'label_organization_administrator_checkbox' | translate }}</span>
                </app-common-checkbox>
                <span>{{ 'label_organization_administrator_checkbox_description' | translate }}</span>
              </div>
            </div>
            <ng-container *ngIf="tableData$ | async as tableData">
              <div *ngIf="groupsFormFields[$user.organizationAdministrator].value !== true">
                <div class="pagination-entries justify-content-end">
                  <app-responsive-pagination
                    [page]="page$ | async"
                    [pageSize]="pageSize$ | async"
                    [collectionSize]="totalRows$ | async"
                    [entryLimits]="DEFAULT_ENTRY_LIMITS"
                    (pageChange)="onPageChange($event)"
                    (pageSizeChange)="onPageSizeChange($event)"
                  ></app-responsive-pagination>
                </div>
                <app-ngb-table
                  class="security-groups-table"
                  [config]="tableConfig"
                  [sortingOrder]="{}"
                  [data]="tableData"
                  [allSelected]="allPageSelected$ | async"
                  (onSelectAll)="onSelectAll($event)"
                ></app-ngb-table>
                <div class="pagination-entries mb-0 justify-content-end">
                  <app-responsive-pagination
                    idSuffix="Bottom"
                    [page]="pageNumber"
                    [pageSize]="pageSize"
                    [collectionSize]="totalRows$ | async"
                    [entryLimits]="DEFAULT_ENTRY_LIMITS"
                    (pageChange)="onPageChange($event)"
                    (pageSizeChange)="onPageSizeChange($event)"
                  ></app-responsive-pagination>
                </div>
              </div>
            </ng-container>
          </fieldset>

          <div class="row mt-3">
            <div class="col">
              <app-avatar-input
                [formControlName]="$user.avatar"
                [label]="'manage_users_avatar_label' | translate"
              ></app-avatar-input>
              <app-form-field-error
                *ngIf="form.disabled === false"
                [formValidationRequest$]="formValidationRequest$"
                [field]="form.get($user.avatar)"
                [name]="$user.avatar"
                [label]="'manage_users_avatar_label' | translate"
              ></app-form-field-error>
            </div>
          </div>

          <app-common-input
            class="mt-3"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'manage_users_first_name_label',
              field: $user.firstName,
              required: false,
              autocomplete: 'off',
            }"
          >
          </app-common-input>

          <app-common-input
            class="mt-3"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'manage_users_last_name_label',
              field: $user.lastName,
              required: false,
              autocomplete: 'off',
            }"
          >
          </app-common-input>

          <div class="mt-3">
            <ds-input
              [label]="'manage_users_role_label' | translate"
              [errorMessage]="roleError"
              [errorState]="roleError?.length > 0"
              [required]="true"
              
            >
              <select ds-input-field [formControlName]="$user.roleId">
                <option value="" disabled selected>{{ 'select_a_role' | translate }}</option>
                <option *ngFor="let userRole of userRoles$ | async" [value]="userRole._id">
                  {{ userRole.translationKey | translate }}
                </option>
              </select>
            </ds-input>
          </div>

          <app-user-mfa
            class="mt-3"
            disableMfaConfirmationMessage="mfa_disable_for_user_confirmation_message"
            [mfaStatusResponse$]="mfaStatusResponse$"
            (disableMfa)="onDisableMfa()"
          >
          </app-user-mfa>

          <div class="d-flex justify-content-start mt-3 gap-3">
            <button ds-button type="submit" id="save-button" [variant]="DsButtonVariants.primary">
              {{ 'update_details' | translate }}
            </button>
            <button
              ds-button
              type="button"
              id="cancel-button-link"
              [variant]="DsButtonVariants.secondary"
              (click)="navigateToParentRoute()"
            >
              {{ 'manage_users_cancel_label' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
