import { Component, Input } from '@angular/core';
import { ScanViewportType } from '../../../../shared/constants/scan-viewport';
import { IScanViewport } from '../../../../shared/interfaces/scan-viewport.interface';

@Component({
  selector: 'app-viewport-inline',
  templateUrl: './viewport-inline.component.html',
})
export class ViewportInlineComponent {
  @Input() public scanViewport: IScanViewport;
  public viewportType: typeof ScanViewportType;

  constructor() {
    this.viewportType = ScanViewportType;
  }

  public getViewportLabel(): string {
    return `(${this.scanViewport?.width ?? '??'} x ${this.scanViewport?.height ?? '??'}px)`;
  }
}
