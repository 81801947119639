import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { snakeCase } from 'lodash';
import { Icons } from '@levelaccess/design-system';
import { TranslateService } from '../../../../translate/translate.service';
import { $riskScoreLevels } from '../../../../../../shared/constants/risk-metrics';
import { DateUtility } from '../../../../utility/date.utility';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';

export interface IFindingsBreakdownData {
  resolvedCount: number;
  criticalOverdueCount: number;
  highOverdueCount: number;
  lowOverdueCount: number;
  computedDate: Date;
}

@Component({
  selector: 'app-risk-score-explanation-modal',
  templateUrl: './risk-score-explanation-modal.component.html',
  styleUrls: ['./risk-score-explanation-modal.component.scss'],
})
export class RiskScoreExplanationModalComponent {
  @Input() public level: $riskScoreLevels;
  @Input() public additionalExplanation: string;
  @Input() public findingsData: IFindingsBreakdownData;
  @Input() public levelToExplanation: Record<$riskScoreLevels, string>;

  public Icons: typeof Icons;

  constructor(
    public modal: NgbActiveModal,
    private translateService: TranslateService,
  ) {
    this.Icons = Icons;
  }

  public get availableLevels(): string[] {
    return Object.values($riskScoreLevels).map(this.translateLevel.bind(this));
  }

  public get currentLevelText(): string {
    return this.translateLevel(this.level);
  }

  public get levelExplanation(): string {
    const localization: string = SharedCommonUtility.isNullish(this.levelToExplanation)
      ? this.defaultLevelExplanationPerLevel[this.level]
      : this.levelToExplanation[this.level];
    return this.translateService.instant(localization);
  }

  public get formattedComputedDate(): string {
    return DateUtility.prettifyDate(this.findingsData.computedDate, {
      month: 'long',
      day: 'numeric',
    });
  }

  public get findingsBreakdown(): string[] {
    return [
      this.translateService.instant('findings_breakdown_resolved', this.findingsData.resolvedCount.toLocaleString()),
      this.translateService.instant(
        'findings_breakdown_critical_overdue',
        this.findingsData.criticalOverdueCount.toLocaleString(),
      ),
      this.translateService.instant('findings_breakdown_high_overdue', this.findingsData.highOverdueCount.toLocaleString()),
      this.translateService.instant('findings_breakdown_low_overdue', this.findingsData.lowOverdueCount.toLocaleString()),
    ];
  }

  private translateLevel(level: $riskScoreLevels): string {
    return this.translateService.instant(snakeCase(level));
  }

  private get defaultLevelExplanationPerLevel(): Record<$riskScoreLevels, string> {
    return {
      [$riskScoreLevels.behind]: 'risk_score_level_explanation_for_behind',
      [$riskScoreLevels.needsAttention]: 'risk_score_level_explanation_for_needs_attention',
      [$riskScoreLevels.onTrack]: 'risk_score_level_explanation_for_on_track',
      [$riskScoreLevels.excelling]: 'risk_score_level_explanation_for_excelling',
    };
  }
}
