import { Component, OnInit } from '@angular/core';

import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
})
export class CookiePolicyComponent implements OnInit {
  public pageTitle: string;

  constructor(private routingService: RoutingService) {
    this.pageTitle = '';
  }

  public ngOnInit(): void {
    this.pageTitle = this.routingService.getPageTitle();
  }
}
