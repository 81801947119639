import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { MaintenanceComponent } from './maintenance.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { GeneralErrorComponent } from './general-error/general-error.component';
import { NoAccessToTenantComponent } from './no-access-to-tenant/no-access-to-tenant.component';
import { FOUND_ERROR_TOKEN } from './constants';

@NgModule({
  declarations: [MaintenanceComponent, HeaderComponent, FooterComponent, GeneralErrorComponent, NoAccessToTenantComponent],
  exports: [MaintenanceComponent],
  imports: [CommonModule, BrowserModule, RouterModule.forRoot([{ path: '', component: MaintenanceComponent }], {})],
  providers: [{ provide: FOUND_ERROR_TOKEN, useFactory: (): any => MaintenanceModule.caughtError }],
  bootstrap: [MaintenanceComponent],
})
export class MaintenanceModule {
  static caughtError: any;
}
