<app-modal-container [displayFooter]="true" xmlns="http://www.w3.org/1999/html">
  <span class="modal-header-content">
    {{ 'share_report_modal_title' | translate }}
  </span>
  <div class="modal-body-content">
    <p class="body-content-heading">{{ 'share_report_modal_subtitle' | translate }}</p>
    <p>{{ 'share_report_modal_content' | translate }}</p>
    <div class="email-selector" [formGroup]="form">
      <app-multiselect-typeahead
        label="share_report_modal_input_label"
        selectedLabel="share_report_modal_input_selected_label"
        formControlName="email"
        [form]="form"
        [possibleValues]="possibleEmails"
      ></app-multiselect-typeahead>
    </div>
  </div>

  <div class="d-flex justify-content-between modal-footer-content">
    <button class="btn btn-outline-primary margin-left-md" (click)="close()">
      {{ 'label_close' | translate }}
    </button>
    <button class="btn btn-primary margin-left-md" [disabled]="selectedEmails.length === 0" (click)="onShareReport()">
      {{ 'share_report' | translate }}
    </button>
  </div>
</app-modal-container>
